import { camelCase } from 'root/src/shared/util/stringCase'
import appConfig from 'root/appConfig'

// eslint-disable-next-line import/no-mutable-exports
const stage = process.env.STAGE || 'dev'

const name = camelCase(`${appConfig.name} ${stage}`)

export default name
export { stage }
