export default function (quantity, description, contentId) {
	/* eslint-disable no-undef */
	if (!TIKTOK_PIXEL_ID) return
	if (typeof ttq !== 'undefined') {
		/* eslint-disable no-undef */
		ttq.track('ViewContent', {
			quantity,
			description,
			content_id: contentId,
		})
	}
}
