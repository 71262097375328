import {
	AUTH_API_PROXY,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

export default {
	[AUTH_API_PROXY]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		payloadSchema: {
			type: 'object',
			properties: {
				url: { type: 'string' },
				body: { type: 'object' },
				query: { type: 'object' },
				method: { type: 'string' },
			},
			required: ['url', 'method'],
			additionalProperties: false,
		},
	},
}
