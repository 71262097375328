import { GET_DROP_SHORT_LINKS_MAPPING_REPORT } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import getDaresReportPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDaresReportPayloadSchema';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
const payloadSchema = getDaresReportPayloadSchema;
export const getDropShortLinksMappingReport = {
    [GET_DROP_SHORT_LINKS_MAPPING_REPORT]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brandUser,
        endpointType: otherEndpointType,
        payloadSchema,
    },
};
