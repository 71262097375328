import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
const isGiveawayActionDoneEndpointPayloadSchema = {
    type: 'object',
    properties: {
        giveawayId: {
            type: 'string',
        },
        actionId: {
            type: 'string',
        },
    },
    required: ['giveawayId', 'actionId'],
};
export const isGiveawayActionDoneEndpoint = {
    [giveawayEndpointIds.IS_GIVEAWAY_ACTION_DONE]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.viewer,
        endpointType: otherEndpointType,
        payloadSchema: isGiveawayActionDoneEndpointPayloadSchema,
    },
};
