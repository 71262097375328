import { UPDATE_EVENT_DARE } from 'root/src/client/logic/dropEvent/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import findIndex from 'ramda/src/findIndex'
import propEq from 'ramda/src/propEq'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'

const { viewDropEventChild } = apiStoreLenses

const updateEventDare = (state, payload) => {
	const recordId = currentRouteParamsRecordId(state)

	if (!recordId) {
		return state
	}

	const { dropDareId } = payload

	const dareIndex = pipe(
		prop('dares'),
		findIndex(propEq('id', dropDareId)),
	)(viewDropEventChild(recordId, state))
	const dareLens = lensPath(['api', 'records', 'dropEvent', recordId, 'dares', dareIndex])
	return pipe(
		over(dareLens, dare => ({
			...dare,
			...payload,
		})),
	)(state)
}

export default {
	[UPDATE_EVENT_DARE]: updateEventDare,
}
