import pipe from 'ramda/src/pipe'
import pathOr from 'ramda/src/pathOr'
import head from 'ramda/src/head'
import propEq from 'ramda/src/propEq'
import find from 'ramda/src/find'

/**
 * Returns preferred or first profile from tokens stored in redux
 * @param state
 * @return {unknown}
 */
export default (state) => {
	const preferredProfile = pipe(pathOr([], ['app', 'userData', 'tokens']), find(propEq('isPreferred', true)))(state)

	if (preferredProfile) {
		return preferredProfile
	}

	return pipe(pathOr([], ['app', 'userData', 'tokens']), head)(state)
}
