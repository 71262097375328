import { STATISTICS_SECTION_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { calendlyUrl } from 'root/src/shared/constants/pageData'
import Publishers4Blob from 'root/src/client/public/backgroundBlobs/for-publishers-4.svg'
import { ltGtMediaQuery, ltMdMediaQuery } from 'root/src/client/web/commonStyles'
import { ourSalesEmail } from 'root/src/shared/constants/mail'

export default {
	[STATISTICS_SECTION_MODULE_ID]: {
		moduleType: 'static',
		backgroundData: [
			{ position: 'right',
				image: Publishers4Blob,
				style: {
					transform: 'translate(0, -29%)',
					[ltMdMediaQuery]: {
						transform: 'translate(0%, -22%)',
					},
					[ltGtMediaQuery]: {
						transform: 'translate(0%, -14%)',
					},
				},
			},
		],
		staticPageType: 'Statistics',
		pageContent: '',
		statsItems: [
			{
				number: '20,000+ ',
				text: 'active creators.',
			},
			{
				number: '250,000,000+ ',
				text: 'audience reach.',
			},
		],
		buttons: [
			{
				text: 'Add Your Game',
				type: 'primary',
				// TEMPORARY FIX UNTIL BRAND ONBOARDING MVP
				href: `mailto:${ourSalesEmail}`,
				// routeId: SIGN_UP_ROUTE_ID,
			},
			{
				text: 'Schedule a Demo',
				type: 'secondary',
				href: calendlyUrl,
			},
		],
	},
}
