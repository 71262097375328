import { GET_DROP_WATCH_TIME_PREDICTION } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const getDropWatchTimePredictionEndpoint = {
    [GET_DROP_WATCH_TIME_PREDICTION]: {
        endpointType: otherEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
            },
            required: ['dropId'],
        },
    },
};
