import outputs from 'root/cfOutput'

const { domainName } = outputs

const getFileUrl = fileName => `https://${domainName}/defaultFiles/${fileName}.mp3`

export const fixedDareSounds = {
	fight: {
		audioSrc: getFileUrl('fight'),
		name: 'Fight',
		id: 'fight',
	},
	applause: {
		audioSrc: getFileUrl('applause'),
		name: 'Applause',
		id: 'applause',
	},
	letsRock: {
		audioSrc: getFileUrl('letsRock'),
		name: 'Lets Rock',
		id: 'letsRock',
	},
	yeeeet: {
		audioSrc: getFileUrl('yeeeet'),
		name: 'Yeeeet',
		id: 'yeeeet',
	},
}

export const fixedPledgeSounds = {
	chicken: {
		audioSrc: getFileUrl('chicken'),
		name: 'Chicken',
		id: 'chicken',
	},
	karateChop: {
		audioSrc: getFileUrl('karateChop'),
		name: 'Karate Chop',
		id: 'karateChop',
	},
	rimShot: {
		audioSrc: getFileUrl('rimShot'),
		name: 'Rim Shot',
		id: 'rimShot',
	},
	ah: {
		audioSrc: getFileUrl('ah'),
		name: 'Ah',
		id: 'ah',
	},
}
