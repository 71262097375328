import { POINTS_MARKETPLACE_PURCHASE_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[POINTS_MARKETPLACE_PURCHASE_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'Confirm Purchase',
		modalContent: {
			confirmationText: itemName => `Confirm to redeem the ${itemName}`,
			noticeText: pointsAfterPurchase => (pointsAfterPurchase >= 0
				? 'Points will be deducted form you total upon confirmation'
				: 'You currently do not have enough points for this item. Complete more dares and earn more points!'),
			buttons: [
				{
					buttonText: 'CONFIRM',
					buttonHide: pointsAfterPurchase => pointsAfterPurchase < 0,
					onClickFn: async ({ displayModal }) => {
						// TODO introduce purchase endpoint
						displayModal(undefined)
					},
				},
				{
					buttonText: 'CANCEL',
					type: 'cancel',
					onClickFn: ({ displayModal }) => {
						displayModal(undefined)
					},
				},
			],
		},
	},
}
