import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { dropCode } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { saveRewardCodesPayloadSchema } from 'root/src/shared/rewards/schemas/saveRewardCodes.payload.schema'
import { saveRewardCodesResponseSchema } from 'root/src/shared/rewards/schemas/saveRewardCodes.response.schema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const saveCodeRewardsEndpoint = {
	authentication: authenticationTypes.authenticated,
	authenticationRole: userGroups.brandUser,
	endpointType: listEndpointType,
	recordType: dropCode,
	payloadSchema: saveRewardCodesPayloadSchema,
	responseSchema: saveRewardCodesResponseSchema,
	middlewares: [VALIDATE_TOKEN_ACCOUNT],
}
