import {
	UPDATE_PROJECT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'

import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

import updateProjectSchema from 'root/src/shared/descriptions/endpoints/schemas/updateProjectSchema'
import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/projectResponseSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = updateProjectSchema
export const responseSchema = projectResponseSchema

export default {
	[UPDATE_PROJECT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: recordEndpointType,
		recordType: project,
		updateProjectSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
