export const AUTH_DETERMINED = 'app/AUTH_DETERMINED'

export const SET_MEDIA_BREAKPOINTS = 'app/SET_MEDIA_BREAKPOINTS'

export const SET_VISIBLE_SUCCESS_MESSAGE = 'app/SET_VISIBLE_SUCCESS_MESSAGE'

export const INIT_APP = '@@INIT'

export const DISSOC_PATH_UPLOAD_PROGREES = 'app/DISSOC_PATH_UPLOAD_PROGREES'

export const SET_STEPS_COMPLETED = 'app/SET_STEPS_COMPLETED'

export const CLEAN_STATE = 'app/CLEAN_STATE'

export const SET_CLIENT_METADATA = 'app/SET_CLIENT_METADATA'

export const SET_DEVICE_ID = 'app/SET_DEVICE_ID'

export const SET_EXPERIMENT_VARIANT = 'app/SET_EXPERIMENT_VARIANT'

export const SET_SLIDEOUT_TRAY_VISIBLE = 'app/SET_SLIDEOUT_TRAY_VISIBLE'
