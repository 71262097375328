// https://docs.ipdata.co/docs/proxy-tor-and-threat-detection#data-fields
export const threatsDescriptions = {
	is_tor:	'is true if the IP address is associated with a node on the Tor network',
	is_vpn:	'true for VPN IP addresses. There are approx. 2.6M IP addresses updated daily. This is available to Business and Enterprise users only.',
	is_icloud_relay:	'true for IP addresses belonging to Apple\'s iCloud relay service',
	is_proxy:	'is true if the IP address is a known proxy, includes HTTP/HTTPS/SSL/SOCKS/CONNECT and transparent proxies',
	is_datacenter:	'true for any IP addresses that belong to a datacenter including all cloud providers. Can be useful for detecting automated/bot traffic.',
	is_anonymous:	'is set true if either one of is_tor or is_proxy is true',
	is_known_attacker:	'is true if an IP address is a known source of malicious activity, i.e. attacks, malware, botnet activity etc',
	is_known_abuser:	'is true if the IP address is a known source of abuse i.e. spam, harvesters, registration bots and other nuisance bots etc',
	is_threat:	'is true if either one of is_known_abuser or is_known_attacker is true',
	is_bogon:	'true for if an IP address is a bogon.',
	blocklists:	'An array of blocklists an IP address has been reported to. It includes the name, website and list type.',
	scores:	'A map of IP reputation scores.',
	// Custom prop
	multi_account: 'is true if the user likely has multiple accounts',
	bad_followers_ratio: 'is true if the ratio of followers / ccv is <= 1',
	too_many_streams_added_in_one_hour: 'is true when half of the streams added in one hour',
	is_young_with_high_ccv: 'is true when the user is younger than two months and has more than 10 ccv',
	is_young_with_first_stream_high_ccv: 'is true when the user is younger than one year and the first stream has more than 10 ccv',
	half_of_streams_ended_in_less_than_one_hour: 'is true when half of the streams ended in less than one hour',
	half_of_videos_published_in_one_day: 'is true when half of the videos published in one day',
	is_first_time_dropper: 'is true when the user has never completed campaign dare',
}

export const renderHtmlThreats = (threats) => {
	const filteredThreats = Object.entries(threats)
		.filter(([, isThreat]) => isThreat)
		.map(([threatName]) => threatName)
	return `<ul>${filteredThreats.map(threat => `<li><abbr title="${threatsDescriptions[threat]}">${threat}</abbr></li>`).join('')}</ul>`
}
