import { UPDATE_CREATOR_CAMPAIGN_POINTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import updateCreatorCampaignPointsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/updateCreatorCampaignPointsPayloadSchema'
import updateCreatorCampaignPointsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/updateCreatorCampaignPointsResponseSchema'
import { userGroups, authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[UPDATE_CREATOR_CAMPAIGN_POINTS]: {
		payloadSchema: updateCreatorCampaignPointsPayloadSchema,
		responseSchema: updateCreatorCampaignPointsResponseSchema,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
