import { AMBASSADOR_PROGRAM_HEAD_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import ambassadorProgramHeaderBadge from 'root/src/client/public/ambassador-program-header-badge.png'

export default {
	[AMBASSADOR_PROGRAM_HEAD_SECTION]: {
		moduleType: 'static',
		staticPageType: 'ambassadorProgram',
		pageContent: {
			sectionType: 'headSection',
			table: {
				headerText: 'DARE DROP AMBASSADOR PROGRAM',
				headerBadge: ambassadorProgramHeaderBadge,
				bodyText: 'Do dares & drops to earn points & level up your stream. Earn your way into drops, getting paid, and special opportunities with our partners!',
			},
		},
	},
}
