import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	CREATOR_PROJECTS_LIST_MODULE_ID,
	CREATOR_PROJECTS_LIST_BANNER_HEADER_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VIEW_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { cardListType } from 'root/src/shared/constants/moduleTypes'

export default {
	[CREATOR_PROJECTS_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: cardListType,
		listPayload: {
			currentPage: 1,
			pageItemLength: 8,
		},
		recordType: project,
		endpointId: [GET_PROJECTS],
		listRouteId: VIEW_PROJECT_ROUTE_ID,
		sortFilterModule: CREATOR_PROJECTS_LIST_BANNER_HEADER_MODULE_ID,
		goTopButton: true,
	},
}
