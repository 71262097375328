import { formStoreLenses } from 'root/src/client/logic/form/lenses'
import { SET_LOADING_BLOCK_FORM } from 'root/src/client/logic/form/actionIds'

const { setLoadingBlockVisible } = formStoreLenses

export default {
	[SET_LOADING_BLOCK_FORM]: (
		state, { moduleId, isVisible },
	) => setLoadingBlockVisible(moduleId, isVisible, state),
}
