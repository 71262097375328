import { AUDIT_COMMENT_LIKES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import payloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/auditCommentLikesPayloadSchema'
import responseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/commentResponseSchema'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { comment } from 'root/src/shared/descriptions/endpoints/recordTypes'

import { authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[AUDIT_COMMENT_LIKES]: {
		authentication: authenticationTypes.authenticated,
		endpointType: recordEndpointType,
		recordType: comment,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
