import pathOr from 'ramda/src/pathOr'
import map from 'ramda/src/map'
import addIndex from 'ramda/src/addIndex'
import memoizeWith from 'ramda/src/memoizeWith'

import moduleDescriptions from 'root/src/shared/descriptions/modules'

export default memoizeWith(
	(state, { moduleId }) => moduleId,
	(state, { moduleId }) => {
		const fieldsPath = [moduleId, 'embeddedContent', 'fields']
		const fields = pathOr([], fieldsPath, moduleDescriptions)
		return addIndex(map)((field, idx) => [...fieldsPath, idx], fields)
	},
)
