import { GET_USER_CAMPAIGN_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import getDropEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDropEventPayloadSchema';
import getUserCampaignDataResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getUserCampaignDataResponseSchema';
import { userCampaignData } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const getUserCampaignData = {
    [GET_USER_CAMPAIGN_DATA]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.creator,
        endpointType: recordEndpointType,
        payloadSchema: getDropEventPayloadSchema,
        responseSchema: getUserCampaignDataResponseSchema,
        recordType: userCampaignData,
    },
};
