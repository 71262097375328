import {	PAYOUT_PROJECT_TO_CAPTURE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[PAYOUT_PROJECT_TO_CAPTURE]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: recordEndpointType,
		recordType: project,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
