import { DELETE_DROP_EVENT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import deleteDropEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/deleteDropEventPayloadSchema'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[DELETE_DROP_EVENT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		payloadSchema: deleteDropEventPayloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
