import { JOIN_GIVEAWAY } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import addTokenPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addTokenPayloadSchema'

export default {
	[JOIN_GIVEAWAY]: {
		endpointType: gatewayEndpointType,
		payloadSchema: addTokenPayloadSchema,
	},
}
