export default {
	type: 'object',
	properties: {
		id: { type: 'string' },
		soundType: { type: 'string' },
		audioSrc: { type: 'string' },
		name: { type: 'string' },
	},
	required: ['soundType', 'name'],
	aditionalProperties: false,
}
