import { CREATORS_FEATURES_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers'
import { faBellOn } from '@fortawesome/pro-light-svg-icons/faBellOn'
import { faObjectGroup } from '@fortawesome/pro-light-svg-icons/faObjectGroup'
import { colorsNew } from 'root/src/client/web/commonStyles'
import { loginWith } from 'root/src/client/v2/auth/helpers/loginWith'
import providers from 'root/src/shared/constants/providers'
import currentRouteObjSelector from 'root/src/client/logic/route/selectors/currentRouteObj'
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok'

export default {
	[CREATORS_FEATURES_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'Features',
		pageContent: {
			title: 'Enhancements for Dare Drop Pros',
			subtitle: 'Find out for yourself, we triple dog dare ya.',
		},
		featureItems: [
			{
				header: 'Accept Dares From Fans',
				text: 'Encourage your fans to dare you too!',
				icons: [faUsers],
				color: colorsNew.primary,
			},
			{
				header: 'Overlay',
				text: 'OBS Integrations',
				icons: [faObjectGroup],
				color: colorsNew.teal,
			},
			{
				header: 'Twitch Panel',
				text: 'Get your viewers involved right from your channel.',
				icons: [faTwitch],
				color: colorsNew.twitch,
			},
			{
				header: 'Audio Alerts',
				text: 'Play fun sounds from Dares during your streams.',
				icons: [faBellOn],
				color: colorsNew.pink,
			},
		],
		buttons: state => [
			{
				text: 'Sign up with Twitch',
				type: 'secondary',
				icon: { icon: faTwitch, size: 20 },
				onClick: loginWith(providers.twitch)(currentRouteObjSelector(state)),
			},
			{
				text: 'Sign up with Google',
				type: 'secondary',
				icon: { icon: faGoogle, size: 20 },
				onClick: loginWith(providers.google)(currentRouteObjSelector(state)),
			},
			{
				text: 'Sign up with TikTok',
				type: 'secondary',
				icon: { icon: faTiktok, size: 20 },
				onClick: loginWith(providers.tiktok)(currentRouteObjSelector(state)),
			},
		],
	},
}
