import { QueryClient } from '@tanstack/react-query';
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            // additionally we want to invalidate data on page changes, so
            // add queryClient.invalidateQueries() to link onclick
            refetchOnMount: false,
        },
    },
});
// Quesy client for storing globally available data
export const globalQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: 1000 * 60 * 60 * 6, // 6h
        },
    },
});
