import { DROP_ADMIN_LIST_SEARCH_FORM } from 'root/src/shared/descriptions/modules/moduleIds'
import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { adminKey } from 'root/src/shared/constants/keys'

export default {
	[DROP_ADMIN_LIST_SEARCH_FORM]: {
		moduleType: 'form',
		title: 'Search',
		formOutput: true,
		fields: [
			{
				fieldId: 'value',
				inputType: 'text',
				labelFieldText: [{	text: 'Search value' }],
			},
		],
		listPayload: {
			section: adminKey,
			pageItemLength: 20,
		},
		submits: [
			{
				label: 'Search',
				endpointId: GET_DROP_EVENTS,
				buttonType: 'primaryButton',
			},
		],
	},
}
