var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { useMutation } from '@tanstack/react-query';
import { useDareDropApi } from 'root/src/client/v2/common/hooks/useDareDropApi';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { downloadFile } from 'root/src/client/v2/common/helpers/downloadFile';
import { dropReportsEndpointIds } from 'root/src/shared/dropReports/dropReports.constants';
export function useDownloadCampaignReportFormProvider(dropId, params) {
    const { hide } = useModal();
    const downloadCampaignReport = useDownloadCampaignReportMutation();
    const form = useDownloadCampaignReportForm({
        defaultValues: {
            isReportByGame: false,
            to: params === null || params === void 0 ? void 0 : params.to,
            from: params === null || params === void 0 ? void 0 : params.from,
        },
    });
    const onFormSubmit = form.handleSubmit((data) => __awaiter(this, void 0, void 0, function* () {
        try {
            const reportFiles = yield downloadCampaignReport.mutateAsync(Object.assign(Object.assign({}, data), { dropId, excludeWhitelistedCreators: params === null || params === void 0 ? void 0 : params.excludeWhitelistedCreators, onlyEarnedPointsCreators: params === null || params === void 0 ? void 0 : params.onlyEarnedPointsCreators }));
            reportFiles.map(fileUrl => downloadFile(fileUrl));
            hide();
        }
        catch (err) {
            console.error(err);
        }
    }));
    return Object.assign(Object.assign({}, form), { handleSubmit: onFormSubmit });
}
const downloadCampaignFormSchema = z.object({
    isReportByGame: z.boolean().optional(),
    to: z.string().optional(),
    from: z.string().optional(),
});
function useDownloadCampaignReportForm(params) {
    return useForm(Object.assign(Object.assign({}, params), { resolver: zodResolver(downloadCampaignFormSchema) }));
}
function useDownloadCampaignReportMutation() {
    const { fetcher } = useDareDropApi();
    return useMutation({
        mutationKey: [],
        mutationFn: (params) => __awaiter(this, void 0, void 0, function* () {
            return fetcher({
                endpointId: dropReportsEndpointIds.GENERATE_DROP_REPORT,
                payload: {
                    dropId: params.dropId,
                    isReportByGame: params.isReportByGame,
                    to: params.to,
                    from: params.from,
                    onlyEarnedPointsCreators: params.onlyEarnedPointsCreators,
                    excludeWhitelistedCreators: params.excludeWhitelistedCreators,
                },
            });
        }),
    });
}
