import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'
import prepend from 'ramda/src/prepend'
import indexOf from 'ramda/src/indexOf'
import path from 'ramda/src/path'
import remove from 'ramda/src/remove'
import { MANAGE_LIST_ITEM } from 'root/src/client/logic/api/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'

const { viewLists } = apiStoreLenses

export default {
	[MANAGE_LIST_ITEM]: (state, { listStoreKey, item, deleteItem }) => {
		if (deleteItem) {
			const itemIndex = indexOf(
				item,
				path([listStoreKey, 'items'], viewLists(state)),
			)
			return over(
				lensPath(['api', 'lists', listStoreKey, 'items']),
				remove(itemIndex, 1),
				state,
			)
		}
		return over(
			lensPath(['api', 'lists', listStoreKey, 'items']),
			prepend(item),
			state,
		)
	},
}
