import displayModal from 'root/src/client/logic/modal/actions/displayModal'
import dropEventBase from 'root/src/client/logic/dropEvent/util/dropEventBase'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import updateEvent from 'root/src/client/logic/dropEvent/actions/updateEvent'
import saveDropEvent from 'root/src/client/logic/dropEvent/thunks/saveDropEvent'
import { DROP_EVENT_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import {
	dropEventSubscriptionStatusEnum,
} from 'root/src/shared/constants/dropEvent'
import {
	BRAND_PAYMENT_MODAL_MODULE_ID,
	NO_PUBLISHER_SETTINGS_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import includes from 'ramda/src/includes'
import mixpanel from 'root/src/client/logic/analytics/mixpanel'
import { mixpanelEvents } from 'root/src/shared/constants/mixpanel'
import moment from 'moment'
import changeInput from 'root/src/client/logic/form/actions/changeInput'

export default (formData, { moduleKey, moduleId }) => async (dispatch, getState) => {
	const state = getState()
	if (formData.isUserActivated === true) {
		return pushRouteClient({
			routeId: DROP_EVENT_PAGE_ROUTE_ID,
			routeParams: { recordId: formData.mnemonicId },
		})
	}
	const { endpointId, dropEventPayload } = await dropEventBase({
		formData,
		dispatch,
		getState,
		moduleKey,
		moduleId,
	})

	const { listStoreKey, ...payload } = dropEventPayload
	const {
		subscriptionStatus,
		mnemonicId: recordId,
	} = await saveDropEvent(formData, { moduleKey, moduleId })(dispatch, getState)

	const notSubscribed = includes(
		subscriptionStatus,
		[
			dropEventSubscriptionStatusEnum.PENDING,
			dropEventSubscriptionStatusEnum.EXPIRED,
		],
	)

	if (!notSubscribed) {
		return pushRouteClient({ routeId: DROP_EVENT_PAGE_ROUTE_ID, routeParams: { recordId } })
	}
	const { body, error } = await invokeApiRequest(
		endpointId,
		{
			dropEventPayload: payload,
			publish: true,
		},
		state,
	)
	if (error) {
		if (error.code === 'PayloadSchemaError') {
			throw { ...error, message: `${error.message}.\n Click Edit to fill missing data.`, ...error.props }
		}
		if (error.code === 'BadRequest') {
			dispatch(displayModal(NO_PUBLISHER_SETTINGS_MODAL_MODULE_ID))
		}
		throw error
	}
	dispatch(updateEvent({ ...body, listStoreKey }))
	const { assets } = body
	dispatch(changeInput(moduleKey, ['assets'], assets))
	mixpanel.track(mixpanelEvents.campaignCreated)
	mixpanel.people.set_once({ addedCampaign: moment().format() })
	return dispatch(displayModal(BRAND_PAYMENT_MODAL_MODULE_ID, body))
}
