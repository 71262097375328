import {
	REVIEW_DELIVERY,
} from 'root/src/shared/descriptions/endpoints/endpointIds'

import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

import reviewDeliveryPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/reviewDeliveryPayloadSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = reviewDeliveryPayloadSchema

export default {
	[REVIEW_DELIVERY]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: recordEndpointType,
		recordType: project,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
