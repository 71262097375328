import test from 'ramda/src/test'
import {
	LOGIN_ROUTE_ID,
	SIGN_UP_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { SET_REDIRECT_URL } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { emailRe } from 'root/src/shared/util/regexes'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import { authApi } from 'root/src/shared/util/authApi'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import { events } from 'root/src/shared/constants/analytics'
import analyticsSendItem from 'root/src/client/logic/analytics/util/analyticsSendItem'
import { POST_SIGN_UP_ENDPOINT_URL, userGroups } from 'root/src/shared/constants/auth'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default ({
	email,
	password,
	userGroup,
	redirectRoute,
	newsletterSub,
}) => async (dispatch, getState) => {
	if (!test(emailRe, email)) {
		throw { email: 'Please input correct email address.' }
	}
	const state = getState()
	const routeObj = currentRouteObj(state)
	const { error } = await authApi.signUp({
		email,
		password,
		group: userGroup,
		redirectUrl: POST_SIGN_UP_ENDPOINT_URL,
		data: {
			newsletterSub,
		},
	})
	if (error) {
		throw error
	} else if (redirectRoute) {
		await invokeApiRequest(SET_REDIRECT_URL, { email, redirectRoute })
	} else if (userGroup === userGroups.viewer && ![SIGN_UP_ROUTE_ID, LOGIN_ROUTE_ID].includes(routeObj.routeId)) {
		await invokeApiRequest(SET_REDIRECT_URL, { email, redirectRoute: routeObj	})
	}
	analyticsSendItem(events.signUpWithEmail, email)
	return pushRouteClient({ href: routeUrls.verifyAccount() })
}
