import { GET_COMMENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'

import { comment } from 'root/src/shared/descriptions/endpoints/recordTypes'

import payloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getCommentsPayloadSchema'
import responseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/commentsResponseSchema'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[GET_COMMENTS]: {
		endpointType: listEndpointType,
		recordType: comment,
		payloadSchema,
		responseSchema,
	},
}
