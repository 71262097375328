import paymentTypes from 'root/src/shared/constants/paymentTypes'

export default {
	type: 'object',
	properties: {
		title: { type: 'string' },
		description: { type: 'string' },
		assignees: {
			type: 'array',
			minItems: 1,
			uniqueItems: true,
			items: {
				type: 'object',
				properties: {
					id: { type: 'string' },
					platform: { type: 'string' },
				},
				required: ['id', 'platform'],
			},
		},
		paymentInfo: {
			type: 'object',
			properties: {
				paymentType: {
					type: 'string',
					enum: [paymentTypes.brandProduct],
				},
				brandProductId: { type: 'string' },
				productType: { type: 'string' },
				amount: {
					type: 'number',
					minimum: 1,
					maximum: 29,
				},
			},
			required: ['paymentType', 'brandProductId', 'productType', 'amount'],
			additionalProperties: false,
		},
		campaign: {
			type: 'object',
			properties: {
				campaignId: { type: 'string' },
			},
			required: ['campaignId'],
			additionalProperties: true,
		},
		games: {
			type: 'array',
			minItems: 1,
			maxItems: 1,
			uniqueItems: true,
			items: {
				type: 'object',
				properties: {
					id: { type: ['integer', 'string'] },
				},
				required: ['id'],
			},
		},
	},
	required: ['title', 'description', 'assignees', 'paymentInfo', 'campaign'],
	additionalProperties: false,
}
