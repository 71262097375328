import { SET_PANEL_SETTINGS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import setPanelSettingsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setPanelSettingsPayloadSchema'

export default {
	[SET_PANEL_SETTINGS]: {
		authentication: authenticationTypes.optional,
		endpointType: otherEndpointType,
		payloadSchema: setPanelSettingsPayloadSchema,
	},
}
