/* eslint-disable import/no-named-as-default */
import createStore from 'root/src/shared/util/createStore'
import { createWrapper } from 'next-redux-wrapper'
import hydrate from 'root/src/client/logic/app/reducers/hydrate'

import cleanState from 'root/src/client/logic/app/reducers/cleanState'
import setClientMetadata from 'root/src/client/logic/app/reducers/setClientMetadata'
import setDeviceId from 'root/src/client/logic/app/reducers/setDeviceId'
import setExperimentVariant from 'root/src/client/logic/app/reducers/setExperimentVariant'
// reducers
import initAppReducer from 'root/src/client/logic/app/reducers/initAppReducer'
import authDetermined from 'root/src/client/logic/app/reducers/authDetermined'
import setMediaBreakpoints from 'root/src/client/logic/app/reducers/setMediaBreakpoints'
import changeRoute from 'root/src/client/logic/route/reducers/changeRoute'
import setRedirectRoute from 'root/src/client/logic/route/reducers/setRedirectRoute'
import changeVariantRouteIndex from 'root/src/client/logic/route/reducers/changeVariantRouteIndex'
import setStepsCompleted from 'root/src/client/logic/app/reducers/setStepsCompleted'
import clearModal from 'root/src/client/logic/static/reducers/clearModal'
import setSlideoutTrayVisible from 'root/src/client/logic/app/reducers/setSlideoutTrayVisible'

// form
import changeInput from 'root/src/client/logic/form/reducers/changeInput'
import clearFormErrors from 'root/src/client/logic/form/reducers/clearFormErrors'
import clearForm from 'root/src/client/logic/form/reducers/clearForm'
import setFormErrors from 'root/src/client/logic/form/reducers/setFormErrors'
import setFormInfos from 'root/src/client/logic/form/reducers/setFormInfos'
import submitForm from 'root/src/client/logic/form/reducers/submitForm'
import submitFormError from 'root/src/client/logic/form/reducers/submitFormError'
import submitFormComplete from 'root/src/client/logic/form/reducers/submitFormComplete'
import pushStepId from 'root/src/client/logic/form/reducers/pushStepId'
import partialFormDbSaveSuccess from 'root/src/client/logic/form/reducers/partialFormDbSaveSuccess'
import clearPartialFormKeys from 'root/src/client/logic/form/reducers/clearPartialFormKeys'
import clearAllForms from 'root/src/client/logic/form/reducers/clearAllForms'
import setButtonErrors from 'root/src/client/logic/form/reducers/setButtonErrors'
import clearButtonErrors from 'root/src/client/logic/form/reducers/clearButtonErrors'
import setLoadingBlockForm from 'root/src/client/logic/form/reducers/setLoadingBlockForm'
import popStepId from 'root/src/client/logic/form/reducers/popStepId'
import setSubmitsBlock from 'root/src/client/logic/form/reducers/setSubmitsBlock'
import setSubmitsLoading from 'root/src/client/logic/form/reducers/setSubmitsLoading'
import setActiveTab from 'root/src/client/logic/tab/reducers/setActiveTab'
import setDropdownAsyncOptions from 'root/src/client/logic/form/reducers/setDropdownAsyncOptions'

// api
import apiListRequestError from 'root/src/client/logic/api/reducers/apiListRequestError'
import apiListRequestSuccess from 'root/src/client/logic/api/reducers/apiListRequestSuccess'
import apiRecordRequestError from 'root/src/client/logic/api/reducers/apiRecordRequestError'
import apiRecordRequestSuccess from 'root/src/client/logic/api/reducers/apiRecordRequestSuccess'
import initApiListRequest from 'root/src/client/logic/api/reducers/initApiListRequest'
import generalRecordModification from 'root/src/client/logic/api/reducers/generalRecordModification'
import currentListPayload from 'root/src/client/logic/api/reducers/currentListPayload'
import clearList from 'root/src/client/logic/api/reducers/clearList'
import clearListProcessing from 'root/src/client/logic/api/reducers/clearListProcessing'
import setModuleDataFilter from 'root/src/client/logic/api/reducers/setModuleDataFilter'

// project
import favoritesProcessingStart from 'root/src/client/logic/project/reducers/favoritesProcessingStart'
import favoritesProcessingEnd from 'root/src/client/logic/project/reducers/favoritesProcessingEnd'
import setPromotionCodeAmount from 'root/src/client/logic/project/reducers/setPromotionCodeAmount'
import apiRecordClickActionRequestError from 'root/src/client/logic/api/reducers/apiRecordClickActionRequestError'
import apiRecordClickActionRequestSuccess from 'root/src/client/logic/api/reducers/apiRecordClickActionRequestSuccess'
import initApiRecordClickActionRequest from 'root/src/client/logic/api/reducers/initApiRecordClickActionRequest'
import addPayoutMethodOnSuccess from 'root/src/client/logic/payoutMethod/reducers/addPayoutMethodOnSuccess'
import updateProjectRecord from 'root/src/client/logic/api/reducers/updateProjectRecord'
import setLiveAssignees from 'root/src/client/logic/project/reducers/setLiveAssignees'
import setAssigneesRequests from 'root/src/client/logic/project/reducers/setAssigneesRequests'
// list
import setFirstPage from 'root/src/client/logic/list/reducers/setFirstPage'
import currentPage from 'root/src/client/logic/list/reducers/currentPage'
import hasMore from 'root/src/client/logic/list/reducers/hasMore'
import setVisibleLoadingBlockList from 'root/src/client/logic/list/reducers/setVisibleLoadingBlockList'
import setAllPage from 'root/src/client/logic/list/reducers/setAllPage'
import dropUploadKeysOnSuccess from 'root/src/client/logic/tableItem/reducers/dropUploadKeysOnSuccess'
import dropUploadCodesOnSuccess from 'root/src/client/logic/tableItem/reducers/dropUploadCodesOnSuccess'
import manageListItem from 'root/src/client/logic/api/reducers/manageListItem'
import modifyWhitelistedCreatorsOnSuccess from 'root/src/client/logic/tableItem/reducers/modifyWhitelistedCreatorsOnSuccess'
import setListDataLength from 'root/src/client/logic/list/reducers/setListDataLength'
import setSelectedPage from 'root/src/client/logic/list/reducers/setSelectedPage'
// headers
import addSortFilterParams from 'root/src/client/logic/header/reducers/addSortFilterParams'
import cleareProjectArray from 'root/src/client/logic/header/reducers/clearProjectArray'

import apiFetchUserDataSuccess from 'root/src/client/logic/api/reducers/apiFetchUserDataSuccess'
import setVisibleSuccessMessage from 'root/src/client/logic/app/reducers/setVisibleSuccessMessage'

// listeners
import windowSizeListener from 'root/src/client/logic/app/listeners/windowSizeListener'

// modal
import displayModal from 'root/src/client/logic/modal/reducers/displayModal'

// embedded
import changeEmbeddedFieldData from 'root/src/client/logic/embedded/reducers/changeEmbeddedFieldData'
import clearEmbeddedData from 'root/src/client/logic/embedded/reducers/clearEmbeddedData'

// creator data
import followProcessingStart from 'root/src/client/logic/creatorData/reducers/followProcessingStart'
import followProcessingEnd from 'root/src/client/logic/creatorData/reducers/followProcessingEnd'
import updateApiProfile from 'root/src/client/logic/creatorData/reducers/updateApiProfile'

// brand
import addBrandProductOnSuccess from 'root/src/client/logic/tableItem/reducers/addBrandProductOnSuccess'
import submitFormDefaultOnSuccess	from 'root/src/client/logic/form/reducers/submitFormDefaultOnSuccess'

// coupon
import addCouponOnSuccess from 'root/src/client/logic/coupon/reducers/addCouponOnSuccess'
import updateCouponOnSuccess from 'root/src/client/logic/coupon/reducers/updateCouponOnSuccess'
import deleteCouponOnSuccess from 'root/src/client/logic/coupon/reducers/deleteCouponOnSuccess'
import tableRowOnClick from 'root/src/client/logic/coupon/reducers/tableRowOnClick'

// comment
import updateCommentLikes from 'root/src/client/logic/comment/reducers/updateCommentLikes'
import deleteComment from 'root/src/client/logic/comment/reducers/deleteComment'
import addComment from 'root/src/client/logic/comment/reducers/addComment'
import addCommentReply from 'root/src/client/logic/comment/reducers/addCommentReply'
import addCommentReplies from 'root/src/client/logic/comment/reducers/addCommentReplies'

import updateProfile from 'root/src/client/logic/profile/reducers/updateProfile'
import prePopulateForm from 'root/src/client/logic/form/reducers/prePopulateForm'

import setShareUrl from 'root/src/client/logic/static/reducers/setShareUrl'
import dissocShareUrl from 'root/src/client/logic/static/reducers/dissocShareUrl'

// drop event
import updateEvent from 'root/src/client/logic/dropEvent/reducers/updateEvent'
import updateEventDare from 'root/src/client/logic/dropEvent/reducers/updateEventDare'
import setFieldLoading from 'root/src/client/logic/form/reducers/setFieldLoading'
import setPreviousRoute from 'root/src/client/logic/route/reducers/setPreviousRoute'
import updateTermsAccepted from 'root/src/client/logic/dropEvent/reducers/updateTermsAccepted'

// agency
import updatePublisherRecord from 'root/src/client/logic/agency/reducers/updatePublisherRecord'

// user data
import updatePreferredPlatform
	from 'root/src/client/logic/userData/reducers/updatePreferredPlatform'

export const store = createStore(
	{
		...initAppReducer,
		...authDetermined,
		...setMediaBreakpoints,
		...changeRoute,
		...changeInput,
		...clearFormErrors,
		...clearForm,
		...clearAllForms,
		...setFormErrors,
		...setFormInfos,
		...setButtonErrors,
		...clearButtonErrors,
		...submitForm,
		...submitFormError,
		...submitFormComplete,
		...apiListRequestError,
		...apiListRequestSuccess,
		...apiRecordRequestError,
		...apiRecordRequestSuccess,
		...initApiListRequest,
		...apiRecordClickActionRequestError,
		...apiRecordClickActionRequestSuccess,
		...initApiRecordClickActionRequest,
		...generalRecordModification,
		...apiFetchUserDataSuccess,
		...partialFormDbSaveSuccess,
		...clearPartialFormKeys,
		...partialFormDbSaveSuccess,
		...clearPartialFormKeys,
		...currentListPayload,
		...currentPage,
		...hasMore,
		...setFirstPage,
		...favoritesProcessingStart,
		...favoritesProcessingEnd,
		...displayModal,
		...addPayoutMethodOnSuccess,
		...addSortFilterParams,
		...cleareProjectArray,
		...clearList,
		...clearListProcessing,
		...changeEmbeddedFieldData,
		...setVisibleLoadingBlockList,
		...setVisibleSuccessMessage,
		...followProcessingStart,
		...followProcessingEnd,
		...setAllPage,
		...setLoadingBlockForm,
		...pushStepId,
		...popStepId,
		...setActiveTab,
		...setPromotionCodeAmount,
		...addBrandProductOnSuccess,
		...addCouponOnSuccess,
		...updateCouponOnSuccess,
		...deleteCouponOnSuccess,
		...tableRowOnClick,
		...updateCommentLikes,
		...deleteComment,
		...addComment,
		...addCommentReply,
		...addCommentReplies,
		...updateProfile,
		...updateProjectRecord,
		...setLiveAssignees,
		...setAssigneesRequests,
		...prePopulateForm,
		...setDropdownAsyncOptions,
		...setShareUrl,
		...dissocShareUrl,
		...dropUploadKeysOnSuccess,
		...updateEvent,
		...submitFormDefaultOnSuccess,
		...updateEventDare,
		...changeVariantRouteIndex,
		...updateApiProfile,
		...setStepsCompleted,
		...dropUploadCodesOnSuccess,
		...cleanState,
		...setClientMetadata,
		...setRedirectRoute,
		...setSubmitsBlock,
		...setSubmitsLoading,
		...setFieldLoading,
		...hydrate,
		...setPreviousRoute,
		...clearModal,
		...setDeviceId,
		...setExperimentVariant,
		...updatePublisherRecord,
		...updatePreferredPlatform,
		...manageListItem,
		...modifyWhitelistedCreatorsOnSuccess,
		...updateTermsAccepted,
		...setListDataLength,
		...setSelectedPage,
		...clearEmbeddedData,
		...setModuleDataFilter,
		...setSlideoutTrayVisible,
	}, // reducer object
	// [], // sagas
	[
		windowSizeListener,
	], // listeners
	{}, // initial state
	[], // initial actions
)

const wrapper = createWrapper(() => store, {
	serializeState: state => JSON.stringify(state),
	deserializeState: state => (typeof state === 'string' ? JSON.parse(state) : state),
})

export default wrapper
