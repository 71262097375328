import includes from 'ramda/src/includes'
import userGroupsSelector from 'root/src/client/logic/auth/selectors/userGroupsSelector'
import authValueSelector from 'root/src/client/logic/auth/selectors/authValueSelector'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import equals from 'ramda/src/equals'

export default (state, {
	reqAuth = authenticationTypes.optional,
	reqAuthRoles = userGroups.user, // can also be array
}) => {
	const roles = userGroupsSelector(state)
	const auth = authValueSelector(state)

	const fn = reqAuthRole => (!equals(reqAuth, authenticationTypes.optional)
		? includes(reqAuthRole, roles) && equals(reqAuth, auth)
		: includes(reqAuthRole, roles))

	return Array.isArray(reqAuthRoles) ? reqAuthRoles.find(fn) : fn(reqAuthRoles)
}
