import { isProdEnv } from 'root/src/shared/util/envSelect'
import stackConfig from 'root/config/stackConfig'

const domainName = stackConfig.AWS_STACK_DOMAIN ?? process.env.AWS_STACK_DOMAIN

const stage = process.env.STAGE ?? 'dev'

export const authApiStage = 'v1'
export const authApiId = isProdEnv ? 'zf84oqf45d' : stackConfig.awsAuthApiId || 'qyvi4marn6'
export const authApiDomain = isProdEnv ? 'authorization.daredrop.com' : `authorization.${stage}.${domainName}`
