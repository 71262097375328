import any from 'ramda/src/any'
import assoc from 'ramda/src/assoc'
import assocPath from 'ramda/src/assocPath'
import concat from 'ramda/src/concat'
import contains from 'ramda/src/contains'
import filter from 'ramda/src/filter'
import find from 'ramda/src/find'
import head from 'ramda/src/head'
import includes from 'ramda/src/includes'
import isEmpty from 'ramda/src/isEmpty'
import join from 'ramda/src/join'
import keys from 'ramda/src/keys'
import last from 'ramda/src/last'
import lensPath from 'ramda/src/lensPath'
import map from 'ramda/src/map'
import over from 'ramda/src/over'
import path from 'ramda/src/path'
import pathEq from 'ramda/src/pathEq'
import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import propEq from 'ramda/src/propEq'
import reject from 'ramda/src/reject'
import split from 'ramda/src/split'
import toLower from 'ramda/src/toLower'
import trim from 'ramda/src/trim'
import outputs from 'root/cfOutput'
import saveDropEvent from 'root/src/client/logic/dropEvent/thunks/saveDropEvent'
import formTogglesSelector from 'root/src/client/logic/form/selectors/formTogglesSelector'
import gameDataFormPopulate from 'root/src/client/logic/form/thunks/gameDataFormPopulate'
import moduleKeySelector from 'root/src/client/logic/module/selectors/moduleKeySelector'
import { colorsNew, newColors } from 'root/src/client/web/commonStyles'
import { dropEventBudgetTypes, monthlyBudgetValues } from 'root/src/shared/constants/dropEvent'
import { GAME_DESCRIPTION_MAX_LENGTH } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/dropAddEventPayloadSchema'
import dropEditEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/dropEditEventPayloadSchema'
import {
	budgetOptions,
	urlPattern,
	youtubeUrlPattern,
} from 'root/src/shared/descriptions/formSchemas/addDropEventSchema'
import { ADD_DROP_EVENT_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import {
	BRAND_PRODUCTS_AND_KEYS_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { RewardEndpoint } from 'root/src/shared/rewards/constants'
import cleanSpecialCharacters from 'root/src/shared/util/cleanSpecialCharacters'
import { addHttpsProtocol } from 'root/src/shared/util/protocolUtil'
import { ImageValidator } from 'root/src/client/v2/file/helpers/fileValidator'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'
import brandLogoSelector from '../../../client/logic/form/selectors/brandLogoSelector'

const { domainName } = outputs

const addDropEventFormSchema = pipe(
	assoc('required', ['campaignName']),
)(dropEditEventPayloadSchema.properties.dropEventPayload)

export const budgetTypes = {
	[dropEventBudgetTypes.CAMPAIGN]: {
		optionName: dropEventBudgetTypes.CAMPAIGN,
		title: 'Campaign Budget',
		description: 'We deliver a minimum of 1 watch hour per $2.00 of budget (assuming dares have been created, rewards added, etc.). Contact us for volume discounts starting at budgets as low as $2,000.',
	},
	[dropEventBudgetTypes.MONTHLY]: {
		optionName: dropEventBudgetTypes.MONTHLY,
		title: 'Monthly Budget',
		description: 'We deliver a minimum of 1 watch hour per $2.00 of budget (assuming dares have been created, rewards added, etc.). Contact us for volume discounts starting at budgets as low as $2,000. When you set a monthly budget, we will deliver that value every month until you pause or cancel the subscription.',
		values: monthlyBudgetValues,
	},
}

export const formatMnemonicId = pipe(
	cleanSpecialCharacters,
	trim,
	split(' '),
	join('-'),
	toLower,
)
const currentFieldValue = (state, fieldId) => {
	const moduleKey = moduleKeySelector(state, {
		moduleId: ADD_DROP_EVENT_FORM_MODULE_ID,
		// it accessible from any route
		routeId: routeUrls.addCampaign(),
	})
	const moduleKeyMod = pipe(prop('form'), keys, find(key => includes(moduleKey, key)))(state)
	const fieldvalues = path(['form', moduleKeyMod, 'formInputs'], state)
	return prop(fieldId, fieldvalues)
}

// TODO remove that module
export default {
	[ADD_DROP_EVENT_FORM_MODULE_ID]: {
		moduleType: 'form',
		title: 'Add Campaign',
		subTitle: [{
			text: 'This form will help you create a Drop Campaign like the ones at daredrop.com/drops.\n You\'ll be able to preview it before publishing.',
			linkLabel: 'daredrop.com/drops',
			href: `https://${domainName}/drops`,
		}],
		submitsRow: true,
		hotjarRecording: true,
		headerType: 'newDesign',
		schema: addDropEventFormSchema,
		submitsFullWidth: true,
		doubleColumn: true,
		dropdownAsyncOptions: [{ endpointId: RewardEndpoint.GET_BRAND_REWARDS }],
		onInputFn: saveDropEvent, // // important - to use functions with onExit,
		fieldTheme: newColors,
		keepAfterSubmit: true,
		prePopulateForm: brandLogoSelector,
		prePopulateFormOn: 'brandLogoUrl',
		// these need to have multiple invocations prevented. check example in
		// src/client/logic/dropEvent/thunks/saveDropEvent.js
		fieldSections: ['GAME', 'DARES', 'CREATOR DETAILS', 'SET BUDGET'],
		fieldSectionsHeaders: {
			DARES: [
				'Looking for some inspiration for your Dares?',
				{
					text: 'Check out some dope ones at daredrop.com/drops.',
					link: `${addHttpsProtocol(`${domainName}/drops`)}`,
				},
			],
		},
		fieldSectionsToggles: {
			GAME: [
				{
					id: 'isMultiGame',
					topLabel: 'Single or Multi-Game Drop?',
					leftOption: {
						label: 'SINGLE',
					},
					rightOption: {
						label: 'MULTI',
					},
					clearFormInputs: ['gameObj', 'campaignProduct', 'dares'],
					prePopulateValue: (state, moduleKey) => {
						const isMultiGameFormState = currentFieldValue(state, 'isMultiGame')
						const {
							isMultiGame: isMultiGameToggleState = false,
						} = formTogglesSelector(state, { moduleKey })
						return { isMultiGame: isMultiGameFormState || isMultiGameToggleState }
					},
				},
				{
					id: 'isPrivate',
					topLabel: 'Private Drop or Public Drop?',
					leftOption: {
						label: 'PUBLIC',
					},
					rightOption: {
						label: 'PRIVATE',
						tooltip: 'These are only shared with Creators that are added below.',
					},
					prePopulateValue: (state, moduleKey) => {
						const isPrivateFormState = currentFieldValue(state, 'isPrivate')
						const {
							isPrivate: isPrivateToggleState = false,
						} = formTogglesSelector(state, { moduleKey })
						return { isPrivate: isPrivateFormState || isPrivateToggleState }
					},
				},
			],
		},
		fields: [
			[
				(state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return !isMultiGame ? {
						fieldId: 'dropTitle',
						debounceInput: true,
						placeholder: 'Game',
						inputType: 'text',
						labelFieldText: [
							{
								text: 'Drop Title',
							},
						],
						column: 1,
						required: true,

					} : {
						inputType: null,
					}
				},
				(state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return isMultiGame
						? {
							fieldId: 'campaignProduct',
							inputType: 'text',
							placeholder: "Enter campaign's product name",
							debounceInput: true,
							labelFieldText: [
								{
									text: 'Campaign Name',
								},
							],
							column: 1,
							required: true,
						}
						: {
							fieldId: 'gameObj',
							maxItems: 1,
							inputType: 'autoComplete',
							optionsPromiseType: 'twitchGames',
							placeholder: 'Select',
							fieldActionButton: {
								label: '+ GET GAME DATA',
								action: gameDataFormPopulate,
							},
							uniqueItems: true,
							subFieldText: [
								{
									text: 'Not on twitch.tv? You can add your game there.',
									href: 'https://help.twitch.tv/s/article/adding-a-game-and-box-art-to-the-directory?language=en_US#Boxart',
								},
							],
							labelFieldText: [
								{
									text: 'Game',
								},
							],
							column: 2,
							required: true,
							isMulti: false,
						}
				},
				{
					fieldId: 'liveDate',
					inputType: 'date',
					subFieldText: 'UTC',
					labelFieldText: [
						{
							text: 'Drop Go-Live Date & Time',
						},
					],
					column: 2,
					required: true,
				},
				{
					fieldId: 'campaignName',
					placeholder: 'The name of what you\'re promoting',
					inputType: 'text',
					debounceInput: true,
					column: 1,
					labelFieldText: [
						{
							text: 'Campaign URL',
						},
					],
					subFieldText: (state, { moduleId, routeId }) => {
						const formImputsPath = ['form', `${routeId}-${moduleId}`, 'formInputs']
						const campaignName = pathOr('', [...formImputsPath, 'campaignName'], state)
						const mnemonicId = path([...formImputsPath, 'mnemonicId'], state)
						const campaignId = path([...formImputsPath, 'id'], state)

						const recordId = path(['api', 'records', 'dropEvent', campaignId, 'mnemonicId'], state)

						return mnemonicId && mnemonicId === recordId
							? `Campaign URL will be: daredrop.com/e/${mnemonicId}`
							: campaignName.length
								? `Campaign URL will be: daredrop.com/e/${formatMnemonicId(campaignName)}`
								: 'Your game\'s name is a good title.'
					},
				},
				(state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return {
						fieldId: 'similarGames',
						maxItems: 10,
						inputType: 'autoComplete',
						optionsPromiseType: 'twitchGames',
						placeholder: `People who play "x" would enjoy my ${isMultiGame ? 'product' : 'game'}...`,
						uniqueItems: true,
						subFieldText: 'Select up to 10',
						labelFieldText: [
							{
								text: isMultiGame ? 'Relevant Games' : 'Similar Games',
							},
						],
						required: true,
						isMulti: true,
						inputStrength: [
							{ value: 2, color: colorsNew.red },
							{ value: 4, color: colorsNew.orange },
							{ value: 8, color: colorsNew.yellow },
							{ value: 10, color: colorsNew.green },
						],
						column: 2,
					}
				},
				(state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return {
						fieldId: 'gameDescriptionBody',
						debounceInput: true,
						placeholder: 'Describe your game in detail',
						inputType: 'textarea',
						minRows: 2,
						maxRows: 6,
						subFieldText: `${GAME_DESCRIPTION_MAX_LENGTH} character max`,
						labelFieldText: [
							{
								text: `${isMultiGame ? 'Product' : 'Game'} Description Body`,
							},
						],
						column: 1,
						required: true,
					}
				},
				{
					fieldId: 'cta',
					debounceInput: true,
					inputType: 'text',
					placeholder: 'Button text',
					labelFieldText: [
						{
							text: 'Call to Action',
						},
					],
					subFieldText: 'Button text inside dare, e.g. "Visit on Steam"',
					column: 1,
					required: true,
				},
				{
					fieldId: 'youtubeURL',
					debounceInput: true,
					placeholder: 'Game trailer from YouTube',
					inputType: 'text',
					labelFieldText: [
						{
							text: 'YouTube Video URL',
						},
					],
					subFieldText: 'Your teaser video',
					column: 2,
				},
				{
					fieldId: 'url',
					debounceInput: true,
					inputType: 'textWithToggle',
					placeholder: 'Your game\'s landing page',
					labelFieldText: [
						{
							text: 'Landing Page URL',
						},
					],
					subFieldText: 'Where viewers land when they click, e.g. your Steam page',
					column: 1,
					required: true,
					fieldToggle: {
						label: 'Custom URL',
						propName: 'custom',
						subFieldText: 'Variables: $campaignID, $channel, $platform $confirmed',
					},
				},
				{
					fieldId: 'assets',
					assetsAdditionalField: ['youtubeURL'],
					inputType: 'assetsInput',
					attachmentText: 'Upload assets',
					maxItems: 10,
					attachmentFormats: ['jpg', 'jpeg', 'png', 'svg', 'mp4'],
					subFieldText: 'Up to 10 assets. Right click to remove. \n Max file size 100MB',
					labelFieldText: [
						{
							text: 'Assets',
						},
					],
					column: 2,
				},
				(state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return isMultiGame ? {
						fieldId: 'campaignThumbnail',
						inputType: 'imageInput',
						labelFieldText: [
							{
								text: 'Campaign Thumbnail',
							},
						],
						column: 2,
						attachmentFormats: ['jpg', 'jpeg', 'png', 'svg'],
						subFieldText: 'Add a 300 x 137 image to be used for the game cover art',
						crop: {
							aspect: 2.18,
							adjustDisplay: true,
							buttonText: 'Crop Image',
							cropValidationFn: (coordinates) => {
								const { width } = coordinates
								return !(width < 300)
							},
							cropErrorMessage: 'Minimum dimensions: 300x137',
						},
						required: true,
					} : { inputType: null }
				},
				{
					fieldId: 'brandLogoUrl',
					inputType: 'imageInput',
					labelFieldText: [
						{
							text: 'Brand Logo',
						},
					],
					attachmentText: 'Upload Photo +',
					attachmentFormats: ['jpg', 'jpeg', 'png', 'svg'],
					subFieldText: 'Minimum image size is 130x130',
					required: true,
					crop: {
						aspect: 1,
						adjustDisplay: true,
						buttonText: 'Crop Image',
						cropValidationFn: (coordinates) => {
							const { width } = coordinates
							return !(width < 130)
						},
						cropErrorMessage: 'Minimum dimensions: 130x130',
					},
					imageConstraints: [
						ImageValidator.minImageDimension({ minWidth: 130, minHeight: 130 }),
					],
					column: 1,
				},
			],
			// Dares
			[
				(state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return {
						isMultiGame,
						fieldId: 'dares',
						inputType: 'multiDaresInput',
						over: true,
						required: true,
						asyncOptionsStoreKey: RewardEndpoint.GET_BRAND_REWARDS,
						customFieldProps: {
							maxItems: 1,
							inputType: 'autoComplete',
							optionsPromiseType: 'twitchGames',
							placeholder: 'Select',
							uniqueItems: true,
							subFieldText: [
								{
									text: 'Not on twitch.tv? You can add your game there.',
									href: 'https://help.twitch.tv/s/article/adding-a-game-and-box-art-to-the-directory?language=en_US#Boxart',
								},
							],
							labelFieldText: [
								{
									text: 'Game',
								},
							],
						},
					}
				},
			],
			// Creator details
			[
				{
					fieldId: 'isGameFree',
					inputType: 'checkbox',
					column: 1,
					labelFieldText: [
						{
							text: 'Game Status',
						},
					],
					subFieldText: 'If the game is free we will display your landing page url as a site for game download',
					label: 'FREE',
					placeholder: 'PAID',
					value: false,
				},
				{
					fieldId: 'initialProduct',
					inputType: 'initialProduct',
					placeholder: 'Choose Product or Key',
					asyncOptionsStoreKey: RewardEndpoint.GET_BRAND_REWARDS,
					uniqueItems: true,
					amountField: true,
					hideInput: (state, { moduleId, routeId }) => {
						const isGameFree = pathOr(
							false,
							['form', `${routeId}-${moduleId}`, 'formInputs'],
							state,
						)?.isGameFree || false
						return isGameFree
					},
					labelFieldText: [
						{
							text: 'Key/Product Creator Needs to Begin',
						},
					],
					subFieldText: (state, { moduleId }) => {
						const options = pathOr(
							[],
							['form', moduleId, 'dropdownAsyncOptions'],
							state,
						)

						return options.length
							? 'Optional, creators receive this when they first accept a dare. If you add multiple, creators will choose between options (region & platform options).'
							: [
								{
									text: 'Add keys or products first in order to select them here',
									routeId: BRAND_PRODUCTS_AND_KEYS_ROUTE_ID,
								},
							]
					},
					column: 1,
					required: false,
					downdropOptionsFilterFn: (options, chosen) => {
						const chosenType = pipe(head, prop('type'))(chosen)
						const chosenGame = pipe(head, path(['game', 'id']))(chosen)
						const chosenKeyType = pipe(head, prop('keyType'))(chosen)
						const chosenIds = map(product => product.id, chosen)
						return pipe(
							filter(propEq('type', chosenType)),
							filter(propEq('keyType', chosenKeyType)),
							filter(pathEq(['game', 'id'], chosenGame)),
							reject(option => contains(prop('id', option), chosenIds)),
						)(options)
					},
					fieldAppendCondition: ({
						fieldValue,
						downdropOptionsFilter,
						dropdownAsyncOptions,
					}) => !any(propEq('type', 'dropCode'), fieldValue)
						&& !isEmpty(downdropOptionsFilter(dropdownAsyncOptions, fieldValue)),
					fieldsResetCondition: (fieldValue) => {
						const headGame = pipe(head, path(['game', 'id']))(fieldValue)
						const lastGame = pipe(last, path(['game', 'id']))(fieldValue)
						return headGame !== lastGame
					},
					hideDowndropArrow: true,
				},
				{
					fieldId: 'followersBreakpoints',
					inputType: 'followersBreakpoints',
					subFieldText: 'Set the maximum amount of Dares a Creator can complete\n given their YouTube or TikTok followers',
					labelFieldText: [
						{
							text: 'Dare Limit by Followers',
						},
					],
					column: 2,
					required: true,
					default: [
						{ platform: 'youtube', followerCount: 10000, maxDares: 0 },
						{ platform: 'tiktok', followerCount: 10000, maxDares: 0 },
					],
				},
				{
					fieldId: 'messageToCreator',
					inputType: 'text',
					debounceInput: true,
					multiline: true,
					multilineRows: '3',
					placeholder: 'Optional, will be included in instructional email to creators',
					labelFieldText: [
						{
							text: 'Game Information for Creators',
						},
					],
					column: 1,
					required: false,
				},
				{
					fieldId: 'ccvBreakpoints',
					inputType: 'breakpointInput',
					subFieldText: 'Creators with less than or equal to X CCV can complete Y dares',
					labelFieldText: [
						{
							text: 'Dare Limit by CCV',
						},
					],
					column: 2,
					required: true,
					default: [
						{ ccv: 5, maxDares: 0 },
					],
					breakpointItems: [
						{ name: 'ccv', label: 'CCV' },
						{ name: 'maxDares', label: 'Max Dares' },
					],
				},
			],
			// Set budget
			[
				{
					fieldId: 'budget',
					inputType: 'budgetInput',
					budgetTypes,
					fieldMinValue: budgetOptions.min,
					default: budgetOptions.min,
					fieldMaxValue: budgetOptions.max,
					required: true,
				},
			],
		],
		submits: [
			{
				label: 'Save',
				schema: (state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return assoc(
						'required',
						['campaignName', isMultiGame ? 'campaignProduct' : 'gameObj'],
						dropEditEventPayloadSchema.properties.dropEventPayload,
					)
				},
				successMessage: 'Saved',
			},
			{
				label: 'Preview',
				schema: (state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return assoc(
						'required',
						['campaignName', isMultiGame ? 'campaignProduct' : 'gameObj'],
						dropEditEventPayloadSchema.properties.dropEventPayload,
					)
				},
			},
			{
				label: 'Ready\nfor Live',
				schema: (state, props) => {
					const { isMultiGame } = formTogglesSelector(state, props)
					return pipe(
						assocPath(
							['properties', 'youtubeURL', 'pattern'],
							youtubeUrlPattern,
						),
						assocPath(['properties', 'url', 'pattern'], urlPattern),
						over(
							lensPath(['required']),
							concat(isMultiGame ? ['campaignProduct', 'campaignThumbnail'] : ['gameObj', 'dropTitle']),
						),
					)(dropEditEventPayloadSchema.properties.dropEventPayload)
				},
			},
			{
				label: 'New Campaign',
			},
		],
	},
}
