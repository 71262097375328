import { GET_TWITCH_OVERLAY_CONFIG } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { twitchOverlayConfig } from 'root/src/shared/descriptions/endpoints/recordTypes'
import twitchOverlayConfigSchema from 'root/src/shared/descriptions/endpoints/schemas/twitchOverlayConfigSchema'
import getTwitchOverlayConfigPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getTwitchOverlayConfigPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[GET_TWITCH_OVERLAY_CONFIG]: {
		authentication: authenticationTypes.optional,
		endpointType: recordEndpointType,
		recordType: twitchOverlayConfig,
		responseSchema: twitchOverlayConfigSchema,
		payloadSchema: getTwitchOverlayConfigPayloadSchema,
	},
}
