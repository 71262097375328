import { GET_USER_COUPON_BY_NAME } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { coupon } from 'root/src/shared/descriptions/endpoints/recordTypes'

import getCouponPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/getCouponPayloadSchema'
import getUserCouponResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/getUserCouponResponseSchema'

export const payloadSchema = getCouponPayloadSchema
export const responseSchema = getUserCouponResponseSchema

export default {
	[GET_USER_COUPON_BY_NAME]: {
		authentication: authenticationTypes.authenticated,
		endpointType: recordEndpointType,
		recordType: coupon,
		payloadSchema,
		responseSchema,
	},
}
