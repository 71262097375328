import { GET_IS_ALERTS_ACTIVE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import getIsAlertsActiveResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getIsAlertsActiveResponseSchema'
import getIsAlertsActivePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getIsAlertsActivePayloadSchema'

export default {
	[GET_IS_ALERTS_ACTIVE]: {
		payloadSchema: getIsAlertsActivePayloadSchema,
		responseSchema: getIsAlertsActiveResponseSchema,
	},
}
