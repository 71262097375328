import moment from 'moment-mini'
import dissocPath from 'ramda/src/dissocPath'
import pipe from 'ramda/src/pipe'
import over from 'ramda/src/over'
import prepend from 'ramda/src/prepend'
import compose from 'ramda/src/compose'
import assoc from 'ramda/src/assoc'
import lensProp from 'ramda/src/lensProp'
import without from 'ramda/src/without'
import view from 'ramda/src/view'
import { daysToExpire as expDays } from 'root/src/shared/constants/timeConstants'
import { outlinedButton } from 'root/src/client/web/componentTypes'
import { CLAIM_PROJECT_FORM_MODULE_ID, ACCEPT_DARE_SUCCESS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import {
	VIEW_CREATOR_DATA_ROUTE_ID, VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { ACCEPT_PROJECT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import claimProjectSchemaSelector from 'root/src/shared/util/claimProjectSchemaSelector'
import { deliveryDareCommon } from 'root/src/shared/descriptions/modules/deliveryDareForm'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import myProfileOnSuccessRedirectSelector from 'root/src/client/logic/profile/selectors/myProfileOnSuccessRedirectSelector'

const { viewProjectChild } = apiStoreLenses

const subFieldTextSelector = (state, props) => {
	const project = viewProjectChild(currentRouteParamsRecordId(state, props), state)
	if (!project) {
		return ''
	}
	const { isTwitchPanelDare, created, pledgeAmount } = project
	if (isTwitchPanelDare) {
		return 'Optional. 0 = infinity'
	}
	if (pledgeAmount) {
		const daysToExpire = expDays - moment.utc().diff(created, 'days')
		return `Max ${daysToExpire}`
	}
	return `Max ${expDays}`
}

export const claimProjectFormStepKeys = {
	root: 'root',
	deliver: 'deliver',
}

export const acceptProjectCommon = {
	fields: [
		{
			fieldId: 'amountRequested',
			inputType: 'amountNumber',
			label: 'Enter amount',
			labelFieldText: [
				{
					text: 'Funding Goal (you can deliver any time, this is just a target)',
					required: false,
				},
			],
			subFieldTopText: 'This is the amount your fans need to pledge in order for you to deliver. Only about 90% of charges are generally successful, take that into account when setting your goal.',
		},
		{
			fieldId: 'daysToExpire',
			default: expDays,
			inputType: 'nonNegativeNumber',
			label: 'Set days',
			subFieldText: subFieldTextSelector,
			labelFieldText: [
				{
					text: 'Days To Expiration',
					required: false,
				},
			],
		},
	],
}

const multiWayField = {
	inputType: 'multiWay',
	variantOptions: [
		{
			id: claimProjectFormStepKeys.root,
			stepId: claimProjectFormStepKeys.root,
			label: 'Set Goal',
		},
		{
			id: claimProjectFormStepKeys.deliver,
			stepId: claimProjectFormStepKeys.deliver,
			label: 'Deliver',
		},
	],
}

export default {
	[CLAIM_PROJECT_FORM_MODULE_ID]: {
		moduleType: 'stepForm',
		noBackButton: true,
		steps: {
			[claimProjectFormStepKeys.root]: {
				isSingleStep: true,
				title: 'Accept the Dare',
				schema: compose(
					dissocPath(['properties', 'projectUrl']),
					dissocPath(['additionalProperties']),
					assoc('required',
						without(
							['projectUrl'],
							view(lensProp('required'), claimProjectSchemaSelector(ACCEPT_PROJECT)),
						)),
				)(claimProjectSchemaSelector(ACCEPT_PROJECT)),
				...over(lensProp('fields'), prepend(multiWayField), acceptProjectCommon),
				submits: [
					{
						label: 'Set Goal',
						endpointId: ACCEPT_PROJECT,
						buttonType: outlinedButton,
						onSuccessRedirectSelector: (state) => {
							const routeMap = {
								twitch: {
									routeId: VIEW_CREATOR_DATA_ROUTE_ID,
									query: { modal: ACCEPT_DARE_SUCCESS_MODULE_ID },
								},
								youtube: {
									routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
									query: { modal: ACCEPT_DARE_SUCCESS_MODULE_ID },
								},
								tiktok: {
									routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
									query: { modal: ACCEPT_DARE_SUCCESS_MODULE_ID },
								},
								facebook: {
									routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
									query: { modal: ACCEPT_DARE_SUCCESS_MODULE_ID },
								},
							}
							return myProfileOnSuccessRedirectSelector(state, routeMap)
						},
					},
				],
			},
			[claimProjectFormStepKeys.deliver]: pipe(
				over(
					lensProp('fields'),
					prepend(multiWayField),
				),
				assoc('isSingleStep', true),
			)(deliveryDareCommon),
		},
	},
}
