export default {
	type: 'array',
	items: {
		properties: {
			gameId: { type: 'string' },
			creatorId: { type: 'string' },
			dateTo: { type: 'string' },
			dateFrom: { type: 'string' },
		},
		required: ['gameId', 'creatorId', 'dateTo', 'dateFrom'],
		additionalProperties: false,
	},
}
