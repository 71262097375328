export const primaryButton = 'primaryButton'
export const secondaryButton = 'secondaryButton'
export const outlinedButton = 'outlinedButton'
export const universalForm = 'universalForm'
export const primarySquareButton = 'primarySquareButton'
export const squareButton = 'squareButton'
export const squareButtonOutlined = 'squareButtonOutlined'
export const noBackgroundButton = 'noBackgroundButton'
export const expDate = 'expDate'
export const securityCode = 'securityCode'
export const email = 'email'
export const password = 'password'
export const newPassword = 'newPassword'
export const confirmPassword = 'confirmPassword'
export const commentButton = 'commentButton'
export const tobiiButton = 'tobiiButton'
export const loadMoreButton = 'loadMoreButton'
export const neonGlow = 'neonGlow'
