import propOr from 'ramda/src/propOr'
import uniq from 'ramda/src/uniq'
import pipe from 'ramda/src/pipe'
import append from 'ramda/src/append'
import reduce from 'ramda/src/reduce'
import concat from 'ramda/src/concat'
import curry from 'ramda/src/curry'

// this function takes tree, tree node name, and gives us how important this node is
// (useful for comparing to other roles and picking the most important one)
export const getPrecedenceLevel = curry((precedence, nodeName) => {
	const inner = (keys) => {
		const keysMod = Array.isArray(keys) ? keys : [keys]
		return reduce((acc, key) => {
			const res = precedence[key]
			if (!res.length) {
				return 1
			}
			return acc + 1 + inner(res)
		}, 0, keysMod)
	}
	return inner(nodeName)
})

// this function works in a way, that it takes some parent node,
// and returns it + its all descendants
// useful for auth, where e.g. for 'admin', where it
// returns all other group permissions
export default (precedence) => {
	const getPrecInner = precedented => reduce((acc, item) => concat(
		acc,
		append(
			item,
			getPrecInner(
				propOr([], item, precedence),
			),
		),
	), [], precedented)
	return pipe(getPrecInner, uniq)
}
