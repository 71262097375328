export const SUBMIT_FORM = 'actionId/SUBMIT_FORM'
export const SUBMIT_FORM_COMPLETE = 'actionId/SUBMIT_FORM_COMPLETE'
export const SUBMIT_FORM_ERROR = 'actionId/SUBMIT_FORM_ERROR'
export const CLEAR_FORM = 'actionId/CLEAR_FORM'
export const CLEAR_ALL_FORMS = 'actionId/CLEAR_ALL_FORMS'
export const SET_FORM_ERRORS = 'actionId/SET_FORM_ERRORS'
export const SET_FORM_INFOS = 'actionId/SET_FORM_INFOS'
export const CLEAR_FORM_ERRORS = 'actionId/CLEAR_FORM_ERRORS'
export const SET_BUTTON_ERRORS = 'actionId/SET_BUTTON_ERRORS'
export const CLEAR_BUTTON_ERRORS = 'actionId/CLEAR_BUTTON_ERRORS'
export const HANDLE_FORM = 'actionId/HANDLE_FORM'
export const CHANGE_INPUT = 'actionId/CHANGE_INPUT'

export const SET_SUBMITS_BLOCK = 'actionId/SET_SUBMITS_BLOCK'
export const SET_SUBMITS_LOADING = 'actionId/SET_SUBMITS_LOADING'

export const PUSH_STEP_ID = 'actionId/PUSH_STEP_ID'
export const POP_STEP_ID = 'actionId/POP_STEP_ID'
export const SAVE_PARTIAL_FORM = 'actionId/SAVE_PARTIAL_FORM'
export const PARTIAL_FORM_DB_SAVE_SUCCESS = 'actionId/PARTIAL_FORM_DB_SAVE_SUCCESS'
export const CLEAR_PARTIAL_FORM_KEYS = 'actionId/CLEAR_PARTIAL_FORM_KEYS'

export const SET_LOADING_BLOCK_FORM = 'actionId/SET_LOADING_BLOCK_FORM'
export const PRE_POPULATE_FORM = 'actionId/PRE_POPULATE_FORM'
export const SET_FIELD_LOADING = 'actionId/SET_FIELD_LOADING'

export const SET_DROPDOWN_ASYNC_OPTIONS = 'actionId/SET_DROPDOWN_ASYNC_OPTIONS'
export const SUBMIT_FORM_DEFAULT_ON_SUCCESS = 'actionId/SUBMIT_FORM_DEFAULT_ON_SUCCESS'
