import { SEARCH_TWITCH_GAMES_ADMIN } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { searchTwitchAdminResponseSchema, } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/searchTwitchAdminResponseSchema';
import { searchTwitchAdminPayloadSchema, } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/searchTwitchAdminPayloadSchema';
export const searchTwitchGamesAdmin = {
    [SEARCH_TWITCH_GAMES_ADMIN]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: otherEndpointType,
        payloadSchema: searchTwitchAdminPayloadSchema,
        responseSchema: searchTwitchAdminResponseSchema,
    },
};
