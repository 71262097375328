import { SET_VERIFICATION_STATE } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { setVerificationStatePayloadSchema } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setVerificationStatePayloadSchema';
const payloadSchema = setVerificationStatePayloadSchema;
export const setVerificationStateEndpoint = {
    [SET_VERIFICATION_STATE]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: otherEndpointType,
        payloadSchema,
    },
};
