import { whitelistPlatforms, whitelistTypes } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addWhitelistedCreatorPayloadSchema'

export default {
	type: 'object',
	properties: {
		notValidIdCount: { type: 'number' },
		items: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					whitelistType: {
						type: 'string',
						enum: whitelistTypes,
					},
					displayName: { type: 'string' },
					platform: {
						type: 'string',
						enum: whitelistPlatforms,
					},
					platformId: { type: 'string' },
					campaignId: { type: 'string' },
					campaignName: { type: 'string' },
				},
				required: ['whitelistType', 'platform', 'platformId', 'displayName'],
				additionalProperties: false,
			},
		},
	},
	required: ['items'],
	additionalProperties: false,
}
