import { GET_OBS_TOP_DARES_CONFIG } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { obsTopDaresConfig } from 'root/src/shared/descriptions/endpoints/recordTypes'
import obsTopDaresConfigSchema from 'root/src/shared/descriptions/endpoints/schemas/obsTopDaresConfigSchema'
import getObsTopDaresConfigPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getObsTopDaresConfigPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { obsTopDaresConfigKey } from 'root/src/shared/constants/keys'

export default {
	[GET_OBS_TOP_DARES_CONFIG]: {
		authentication: authenticationTypes.optional,
		endpointType: userDataEndpointType,
		recordType: obsTopDaresConfig,
		responseSchema: obsTopDaresConfigSchema,
		payloadSchema: getObsTopDaresConfigPayloadSchema,
		storeKey: obsTopDaresConfigKey,
	},
}
