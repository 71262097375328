import { GET_USER_NOTIFICATION_SETTINGS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import getUserNotificationSettingsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getUserNotificationSettingsResponseSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { notificationSettings } from 'root/src/shared/descriptions/endpoints/recordTypes'

export const responseSchema = getUserNotificationSettingsResponseSchema

export default {
	[GET_USER_NOTIFICATION_SETTINGS]: {
		endpointType: userDataEndpointType,
		recordType: notificationSettings,
		payloadSchema: {},
		responseSchema,
		authentication: authenticationTypes.authenticated,
	},
}
