import React from 'react'

export default ({ gradient: Gradient }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
		<Gradient />
		<path
			fill={Gradient ? 'url(#hover-gradient)' : '#fff'}
			fillRule="evenodd"
			d="M8 0c2.173 0 2.445.01 3.298.048.852.039 1.433.174 1.942.372.526.204.973.478 1.417.923.445.444.719.89.923 1.417.198.509.333 1.09.372 1.942C15.99 5.555 16 5.827 16 8s-.01 2.445-.048 3.298c-.039.852-.174 1.433-.372 1.942a3.922 3.922 0 0 1-.923 1.417c-.444.445-.89.719-1.417.923-.509.198-1.09.333-1.942.372C10.445 15.99 10.173 16 8 16s-2.445-.01-3.298-.048c-.852-.039-1.433-.174-1.942-.372a3.922 3.922 0 0 1-1.417-.923A3.921 3.921 0 0 1 .42 13.24c-.198-.509-.333-1.09-.372-1.942C.01 10.445 0 10.173 0 8s.01-2.445.048-3.298C.087 3.85.222 3.269.42 2.76c.204-.526.478-.973.923-1.417.444-.445.89-.719 1.417-.923C3.269.222 3.85.087 4.702.048 5.555.01 5.827 0 8 0zm3.233 1.488c-.844-.038-1.097-.047-3.233-.047s-2.39.009-3.233.047c-.78.036-1.203.166-1.485.276-.374.145-.64.318-.92.598-.28.28-.453.546-.598.92-.11.282-.24.705-.276 1.485-.038.844-.047 1.097-.047 3.233s.009 2.39.047 3.233c.036.78.166 1.203.276 1.485.145.374.318.64.598.92.28.28.546.453.92.598.282.11.705.24 1.485.276.844.038 1.097.047 3.233.047s2.39-.009 3.233-.047c.78-.036 1.203-.166 1.485-.276.374-.145.64-.318.92-.598.28-.28.453-.546.598-.92.11-.282.24-.705.276-1.485.038-.844.047-1.097.047-3.233s-.009-2.39-.047-3.233c-.036-.78-.166-1.203-.276-1.485a2.478 2.478 0 0 0-.598-.92 2.478 2.478 0 0 0-.92-.598c-.282-.11-.705-.24-1.485-.276zM8 3.892a4.108 4.108 0 1 1 0 8.216 4.108 4.108 0 0 1 0-8.216zm0 6.775a2.667 2.667 0 1 1 0-5.334 2.667 2.667 0 0 1 0 5.334zm5.23-6.937a.96.96 0 1 1-1.92 0 .96.96 0 0 1 1.92 0z"
		/>
	</svg>
)
