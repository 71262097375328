/* eslint-disable max-len */
/* eslint-disable max-len */
import curry from 'ramda/src/curry'
import propOr from 'ramda/src/propOr'
import type from 'ramda/src/type'
import __ from 'ramda/src/__'
import includes from 'ramda/src/includes'
import compose from 'ramda/src/compose'
import isEmpty from 'ramda/src/isEmpty'
import without from 'ramda/src/without'
import toUpper from 'ramda/src/toUpper'
import head from 'ramda/src/head'
import tail from 'ramda/src/tail'
import concat from 'ramda/src/concat'
import converge from 'ramda/src/converge'
import split from 'ramda/src/split'
import length from 'ramda/src/length'
import reduce from 'ramda/src/reduce'
import init from 'ramda/src/init'
import reject from 'ramda/src/reject'
import pipe from 'ramda/src/pipe'
import takeLast from 'ramda/src/takeLast'
import mergeRight from 'ramda/src/mergeRight'
import mergeLeft from 'ramda/src/mergeLeft'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import last from 'ramda/src/last'
import equals from 'ramda/src/equals'
import prop from 'ramda/src/prop'
import join from 'ramda/src/join'
import values from 'ramda/src/values'
import not from 'ramda/src/not'
import assoc from 'ramda/src/assoc'
import keys from 'ramda/src/keys'
import ifElse from 'ramda/src/ifElse'
import identity from 'ramda/src/identity'
import always from 'ramda/src/always'
import reverse from 'ramda/src/reverse'
import when from 'ramda/src/when'
import useWith from 'ramda/src/useWith'
import flip from 'ramda/src/flip'
import assocPath from 'ramda/src/assocPath'
import unapply from 'ramda/src/unapply'
import call from 'ramda/src/call'
import apply from 'ramda/src/apply'
import path from 'ramda/src/path'
import take from 'ramda/src/take'
import append from 'ramda/src/append'
import gt from 'ramda/src/gt'
import propSatisfies from 'ramda/src/propSatisfies'
import anyPass from 'ramda/src/anyPass'
import splitEvery from 'ramda/src/splitEvery'
import flatten from 'ramda/src/flatten'
import addIndex from 'ramda/src/addIndex'
import sum from 'ramda/src/sum'

export const ternary = curry((bool, truth, faulty) => (bool ? truth : faulty))

export const orNull = ternary(__, __, null)

export const isSubset = compose(isEmpty, without)

export const capitalize = converge(concat, [compose(toUpper, head), tail])

export const stringLength = compose(length, split(''))

export const omitEmpty = reject(isEmpty)
export const omitNil = reject(isNil)

export const splitAndGetLast = curry((character, item) => compose(last, split(character))(item))

export const splitAndGetLastXTail = curry((n, character, item) => compose(
	join(character),
	takeLast(n),
	split(character),
)(item))

export const splitAndGetTail = curry((character, item) => compose(join(character), tail, split(character))(item))

export const splitAndGetHead = curry((character, item) => compose(head, split(character))(item))

export const splitAndGetN = curry((n, character, item) => compose(take(n), split(character))(item))

export const splitAndGetInit = curry((character, item) => compose(join(character), init, split(character))(item))

export const compareObjectProp = (obj1, obj2, propName) => equals(prop(propName, obj1), prop(propName, obj2))

export const compareObjectProps = (obj1, obj2, propNames) => map(propName => compareObjectProp(obj1, obj2, propName), propNames)

export const orUndefined = ifElse(identity, __, always(undefined))
export const ifElseUndefined = ifElse(__, __, always(undefined))

export const notNil = compose(not, isNil)
export const isNotEmpty = compose(not, isEmpty)
export const isNilOrEmpty = anyPass([isNil, isEmpty])
export const isNotNilOrEmpty = pipe(isNilOrEmpty, not)

export const whenTruthy = when(identity)
export const whenFalsy = when(isNil)

export const logger = label => (item) => {
	console.info(label, item)
	return item
}

export const loggerWrapper = fn => (...args) => {
	const result = fn(...args)
	console.info(JSON.stringify({	args,	result }, null, 2))
	return result
}

export const pipeE = (...args) => async obj => reduce(
	async (accPromise, fn) => accPromise.then(fn),
	Promise.resolve(obj), args,
)

export const composeE = (...args) => async obj => pipeE(...reverse(args))(obj)

export const pipeNotNil = (...args) => obj => reduce(
	(acc, fn) => ifElse(notNil, fn, always(undefined))(acc), obj, args,
)

export const pipeNotNilE = (...args) => async obj => reduce(
	async (accPromise, fn) => accPromise.then(
		ifElse(notNil, fn, always(undefined)),
	), Promise.resolve(obj), args,
)

export const composeNotNil = (...args) => pipeNotNil(...reverse(args))
export const composeNotNilE = (...args) => pipeNotNilE(...reverse(args))

export const renameKeys = curry((keysMap, obj) => reduce(
	(acc, key) => (includes(key, values(keysMap))
		? acc
		: assoc(keysMap[key] || key, obj[key], acc)),
	{}, keys(obj),
))

export const isObj = pipe(type, equals('Object'))
export const isPrimitive = data => (
	typeof data === 'string'
	|| typeof data === 'number'
	|| typeof data === 'boolean'
)

export const includesOrEquals = (search, data) => {
	if (typeof data === 'string') {
		return data.includes(search)
	}
	return data === search
}

export const replaceOrSet = (search, replace, data) => {
	if (typeof data === 'string') {
		return data.replace(search, replace)
	}
	return replace
}

export const mapP = curry((a, b) => Promise.all(addIndex(map)(a, b)))

export const convergeP = curry(
	(f, argFs, input) => mapP(argF => argF(input), argFs)
		.then(args => f(...args)),
)

export const tapP = asyncFn => convergeP(
	identity,
	[
		identity,
		asyncFn,
	],
)

export const tryCatchP = (tryer, catcher) => async (data) => {
	try {
		return await tryer(data)
	} catch (err) {
		return catcher(err)
	}
}

export const mergeByKey = (key, arr, mergeFn) => {
	const anonArr = []
	const idObj = {}
	map((item) => {
		const identifier = item[key]
		if (!identifier) {
			anonArr.push(item)
			return
		}
		const presentKey = idObj[identifier]
		if (!presentKey) {
			idObj[identifier] = item
			return
		}
		const newObj = mergeFn(presentKey, item)
		idObj[identifier] = newObj
	}, arr)
	return [...anonArr, ...values(idObj)]
}

export const mergeByImportance = (arr, importanceProp, importanceMap) => reduce(
	(acc, item) => {
		const accImportance = propOr(1, prop(importanceProp, acc), importanceMap)
		const itemImportance = propOr(1, prop(importanceProp, item), importanceMap)
		const mergeFn = accImportance > itemImportance ? mergeLeft : mergeRight
		return mergeFn(item, acc)
	}, {}, arr,
)

export const pickPaths = curry((paths, obj) => compose(
	reduce(useWith(flip(call), [
		identity,
		apply(assocPath),
	]), {}),
	map(converge(unapply(identity), [
		identity,
		path(__, obj),
	])),
)(paths))

export const reduceP = curry((fn, initI, items) => reduce(async (acc, item) => {
	const accP = await acc
	return fn(accP, item)
}, Promise.resolve(initI), items))

export const truncate = curry((amt, replacer, item) => when(
	propSatisfies(gt(__, amt), 'length'),
	pipe(take(amt), append(replacer), join('')),
)(item))

export const batchMapP = curry((fn, chunkSize, items) => pipeE(
	splitEvery(chunkSize),
	mapP(fn),
	flatten,
)(items))

export const batchReduceP = curry((fn, initI, chunkSize, items) => pipeE(
	splitEvery(chunkSize),
	reduceP(fn, initI),
	flatten,
)(items))

export const hasDefined = propName => pipe(prop(propName), isNil, not)

export const isFunction = x => typeof x === 'function'

export const sumWith = curry((fn, list) => pipe(map(fn), sum)(list))
