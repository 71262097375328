import { SMITE_GIVEAWAY_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import {
	SMITE_GIVEAWAY_MODULE_ID, SMITE_GIVEAWAY_BANNER_HEADER_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[SMITE_GIVEAWAY_ROUTE_ID]: {
		url: '/smite-giveaway',
		modules: [
			SMITE_GIVEAWAY_BANNER_HEADER_MODULE_ID,
			SMITE_GIVEAWAY_MODULE_ID,
		],
	},
}
