import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { viewerReward } from 'root/src/shared/descriptions/endpoints/recordTypes';
export const getRewardsForViewersEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.creator,
    endpointType: listEndpointType,
    recordType: viewerReward,
    payloadSchema: {
        type: 'object',
        properties: {
            isAll: { type: 'boolean' },
            recordId: { type: 'string' },
            creatorUserId: { type: 'string' },
        },
    },
};
