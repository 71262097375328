import { brandEndpointIds } from 'root/src/shared/brand/brand.constants';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
export const deleteBrandUserEndpoint = {
    [brandEndpointIds.DELETE_BRAND_USER]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: [AuthGroup.Brand, AuthGroup.BrandAdmin],
        payloadSchema: {
            type: 'object',
            properties: {
                targetUserId: { type: 'string' },
            },
            required: ['targetUserId'],
        },
        endpointType: otherEndpointType,
        useRequestUserId: true,
    },
};
