import React, { memo } from 'react'
import classNames from 'classnames'
import Link from 'root/src/client/web/base/Link'
import withModuleContext from 'root/src/client/util/withModuleContext'
import newFooterConnector from 'root/src/client/logic/app/connectors/newFooterConnector'
import prop from 'ramda/src/prop'
import path from 'ramda/src/path'
import map from 'ramda/src/map'
import splitEvery from 'ramda/src/splitEvery'
import HorizontalLineNew from 'root/src/client/web/base/HorizontalLineNew'
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography'
import { colorsNew, linkHoverColor, ltGtMediaQuery, gtSmMediaQuery } from 'root/src/client/web/commonStyles'
import MaxWidthContainer from 'root/src/client/web/base/MaxWidthContainer'
import Icon, { iconParentStyle } from 'root/src/client/web/base/Icon'
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord'
import ButtonNew from 'root/src/client/web/base/ButtonNew'
import LanguageSwitcher from 'root/src/client/web/misc/LanguageSwitcher'
import NextLink from 'next/link'
import { useLanguage } from 'root/src/client/v2/browser/hooks/useLanguage'

const styles = {
	root: {
		width: '100%',
		margin: [[100, 'auto', 50]],
		display: 'block',
		zIndex: 3,
		[ltGtMediaQuery]: {
			margin: [[50, 'auto']],
		},
	},
	logo: {
		[ltGtMediaQuery]: {
			marginBottom: 35,
		},
	},
	logoImg: {
		maxWidth: 200,
	},
	linksContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'start',
		justifyContent: 'space-between',
		margin: [[50, 0]],
		[ltGtMediaQuery]: {
			flexDirection: 'column',
			alignItems: 'center',
			minHeight: '100vh',
		},
	},
	links: {
		width: '60%',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr 1fr',
		justifyItems: 'center',
		alignItems: 'start',
		[ltGtMediaQuery]: {
			gridTemplateColumns: '1fr',
			textAlign: 'center',
			gap: 15,
			marginBottom: 35,
		},
	},
	linksColumn: {
		display: 'grid',
		gap: 10,
		[ltGtMediaQuery]: {
			gap: 15,
		},
	},
	socialsContainer: {
		display: 'flex',
		justifyContent: 'start',
		flexDirection: 'column',
		alignItems: 'start',
		[ltGtMediaQuery]: {
			alignItems: 'center',
			margin: 0,
		},
		[gtSmMediaQuery]: {
			margin: [[0, 70]],
		},
	},
	iconsContainer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 1fr)',
		gap: 20,
		marginBottom: 20,
		[ltGtMediaQuery]: {
			margin: 0,
		},
	},
	socialIconContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		...iconParentStyle,
	},
	linkText: {
		'&:hover': {
			color: linkHoverColor,
		},
		[ltGtMediaQuery]: {
			fontSize: 16,
		},
	},
	copyrightText: {
		display: 'block',
		textAlign: 'center',
		color: colorsNew.light3,
		[ltGtMediaQuery]: {
			marginTop: 20,
		},
	},
	buttonContainer: {
		[ltGtMediaQuery]: {
			marginTop: 30,
		},
	},
}

const LinkItem = ({
	classes,
	link: { text, routeId, emailAddress, downloadPath, href },
}) => (emailAddress ? (
	<Link href={`mailto:${emailAddress}`}>
		<Typography
			className={classes.linkText}
			variant={typographyVariants.small}
			color={colorsNew.light3}
		>
			{text}
		</Typography>
	</Link>
) : downloadPath ? (
	<a
		href={downloadPath}
		download
		style={{ textDecoration: 'none' }}
		target="_blank"
		rel="noreferrer"
	>
		<Typography
			className={classes.linkText}
			variant={typographyVariants.small}
			color={colorsNew.light3}
		>
			{text}
		</Typography>
	</a>
) : href ? (
	<Link href={href} nextJs={false}>
		<Typography
			className={classes.linkText}
			variant={typographyVariants.small}
			color={colorsNew.light3}
		>
			{text}
		</Typography>
	</Link>
) : (
	<Link routeId={routeId}>
		<Typography
			className={classes.linkText}
			variant={typographyVariants.small}
			color={colorsNew.light3}
		>
			{text}
		</Typography>
	</Link>
))

export const FooterUnconnected = memo(({
	classes, currentYear, footerData: { logo, links, socialLinks, discord }, isMobileRes,
}) => {
	const { languageCode } = useLanguage()
	const [col1Items, col2Items, col3Items] = splitEvery(4, links)
	return (
		<MaxWidthContainer className={classes.root}>
			<HorizontalLineNew />
			<div className={classes.linksContainer}>
				<div className={classes.logo}>
					<Link href="/" nextJs={false}>
						<img
							src={path(['image', 'src'], logo)}
							className={classes.logoImg}
							alt={prop('alt', logo)}
							height="50"
							width="200"
						/>
					</Link>
				</div>
				<div className={classNames(classes.links)}>
					<div className={classes.linksColumn}>
						{map(link => (
							<LinkItem
								key={link.text + link.routeId}
								{...{ link, classes }}
							/>
						), col1Items)}
					</div>
					<div className={classes.linksColumn}>
						{map(link => (
							<LinkItem
								key={link.text + link.routeId}
								{...{ link, classes }}
							/>
						), col2Items)}
					</div>
					<div className={classes.linksColumn}>
						{map(link => (
							<LinkItem
								key={link.text + link.routeId}
								{...{ link, classes }}
							/>
						), col3Items)}
					</div>
				</div>
				{isMobileRes ? (
					<LanguageSwitcher isMobileRes={isMobileRes} languageCode={languageCode} />
				) : null}
				<div className={classes.socialsContainer}>
					<div className={classes.iconsContainer}>
						{map(({ href, icon }) => (
							<div key={href} className={classes.socialIconContainer}>
								<div className={classes.socialIconContainer}>
									<NextLink href={href}>
										<Icon
											icon={icon.src}
											color={colorsNew.light3}
											size={20}
											primary={false}
											glow={colorsNew.primary}
										/>
									</NextLink>
								</div>
							</div>
						), socialLinks)}
					</div>
					<div className={classes.buttonContainer}>
						<ButtonNew
							onClick={() => window.open(prop('href', discord), 'discord')}
							icon={faDiscord}
							type="tertiary"
							iconPosition="left"
						>
							Join us on Discord
						</ButtonNew>
					</div>
				</div>
			</div>
			<span className={classes.copyrightText}>
				&copy; {currentYear} Dare Drop. All right reserved.
			</span>
		</MaxWidthContainer>
	)
})

export default withModuleContext(newFooterConnector(FooterUnconnected, styles, 'MuiFooter'))
