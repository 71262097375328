import {
	UPDATE_PAYOUT_METHOD,
} from 'root/src/shared/descriptions/endpoints/endpointIds'

import { authenticationTypes } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { payoutMethod } from 'root/src/shared/descriptions/endpoints/recordTypes'
import addPayoutMethodPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addPayoutMethodPayloadSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = addPayoutMethodPayloadSchema

export default {
	[UPDATE_PAYOUT_METHOD]: {
		authentication: authenticationTypes.authenticated,
		endpointType: recordEndpointType,
		recordType: payoutMethod,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
