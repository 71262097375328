import moduleDescriptions from 'root/src/shared/descriptions/modules'
import {	moduleDescriptionLenses } from 'root/src/client/logic/route/lenses'

const { viewHasSidebarSelector } = moduleDescriptionLenses

const renderSidebarSelectorHof = moduleDescriptionObj => (state, { moduleId }) => {
	const hasSidebarSelector = viewHasSidebarSelector(moduleId, moduleDescriptionObj)
	if (hasSidebarSelector) {
		const hasSidebar = hasSidebarSelector(state, { moduleId })
		return hasSidebar
	}

	return undefined
}

export default renderSidebarSelectorHof(moduleDescriptions)
