import resourcePrefix from 'root/src/aws/util/resourcePrefix'

export const GATEWAY_LAMBDA_PERMISSION = `${resourcePrefix}GatewayLambdaPermission`
export const GATEWAY_GET_METHOD = `${resourcePrefix}GatewayMethod`
export const GATEWAY_OPTIONS_METHOD = `${resourcePrefix}GatewayOptionsMethod`
export const GATEWAY_POST_METHOD = `${resourcePrefix}GatewayPostMethod`
export const GATEWAY_TRPC_RESOURCE = `${resourcePrefix}TRPCGatewayResource`
export const GATEWAY_TRPC_PROXY_RESOURCE = `${resourcePrefix}TRPCGatewayProxyResource`
export const GATEWAY_TRPC_OPTIONS_METHOD = `${resourcePrefix}TRPCGatewayOptionsMethod`
export const GATEWAY_TRPC_PROXY_OPTIONS_METHOD = `${resourcePrefix}TRPCGatewayProxyOptionsMethod`
export const GATEWAY_TRPC_PROXY_POST_METHOD = `${resourcePrefix}TRPCGatewayProxyPostMethod`
export const GATEWAY_INTERNAL_POST_METHOD = `${resourcePrefix}GatewayInternalPostMethod`
export const GATEWAY_INTERNAL_RESOURCE = `${resourcePrefix}GatewayInternalResource`
export const GATEWAY_REST_API = `${resourcePrefix}GatewayRestApi`
export const GATEWAY_REST_API_ID_SSM_PARAM = `${resourcePrefix}GatewayRestApiIdSsmParam`
export const GATEWAY_REST_API_ROOT_RESOURCE_ID_SSM_PARAM = `${resourcePrefix}GatewayRestApiRootResourceIdSsmParam`
export const GATEWAY_REST_API_STAGE_SSM_PARAM = `${resourcePrefix}GatewayRestApiStageSsmParam`
export const GATEWAY_REST_API_STAGE_ENDPOINT_SSM_PARAM = `${resourcePrefix}GatewayRestApiStageEndpointSsmParam`
export const GATEWAY_LAMBDA = `${resourcePrefix}GatewayLambda`
export const GATEWAY_LAMBDA_EXECUTION_ROLE = `${resourcePrefix}GatewayLambdaExecutionRole`
export const GATEWAY_DEPLOYMENT = `${resourcePrefix}GatewayDeployment`
export const GATEWAY_RECORD_SET = `${resourcePrefix}GatewayRecordSet`
export const GATEWAY_DOMAIN_NAME = `${resourcePrefix}GatewayDomainName`
export const GATEWAY_DOMAIN_NAME_BASE_PATH_MAPPING = `${resourcePrefix}GatewayDomainNameBasePathMapping`

export const GATEWAY_WEBSOCKETS_API = `${resourcePrefix}GatewayWebsocketsApi`
export const GATEWAY_WEBSOCKETS_DEPLOYMENT = `${resourcePrefix}GatewayWebsocketsDeployment`
export const GATEWAY_WEBSOCKETS_STAGE = `${resourcePrefix}GatewayWebsocketsStage`
export const GATEWAY_WEBSOCKETS_CONNECT_ROUTE = `${resourcePrefix}GatewayWebsocketsConnectRoute`
export const GATEWAY_WEBSOCKETS_DISCONNECT_ROUTE = `${resourcePrefix}GatewayWebsocketsDisconnectRoute`
export const GATEWAY_WEBSOCKETS_DEFAULT_ROUTE = `${resourcePrefix}GatewayWebsocketsDefaultRoute`
export const GATEWAY_WEBSOCKETS_CONNECT_INTEGRATION = `${resourcePrefix}GatewayWebsocketsConnectIntegration`
export const GATEWAY_WEBSOCKETS_DISCONNECT_INTEGRATION = `${resourcePrefix}GatewayWebsocketsDisconnectIntegration`
export const GATEWAY_WEBSOCKETS_DEFAULT_INTEGRATION = `${resourcePrefix}GatewayWebsocketsDefaultIntegration`

export const GATEWAY_SENTRY_POST_METHOD = `${resourcePrefix}GatewaySentryPostMethod`
export const GATEWAY_SENTRY_REST_API = `${resourcePrefix}GatewaySentryRestApi`
export const GATEWAY_SENTRY_RESOURCE = `${resourcePrefix}GatewaySentryResource`
export const GATEWAY_SENTRY_DEPLOYMENT = `${resourcePrefix}GatewaySentryDeployment`
export const MIXPANEL_PROXY_REST_API = `${resourcePrefix}MixpanelProxyRestApi`
