import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { otherEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
const redeemRewardPayloadSchema = {
    type: 'object',
    properties: {
        voucherId: {
            type: 'string',
        },
        rewardId: {
            type: 'string',
        },
    },
    required: ['voucherId', 'rewardId'],
};
export const redeemRewardEndpoint = {
    [giveawayEndpointIds.REDEEM_REWARD]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.viewer,
        endpointType: otherEndpointType,
        payloadSchema: redeemRewardPayloadSchema,
    },
};
