import { SET_EMAIL_SUBSCRIPTION_DROP } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
const payloadSchema = {
    type: 'object',
    properties: {
        id: { type: 'string' },
        status: { type: 'boolean' },
    },
    required: ['id', 'status'],
    additionalProperties: false,
};
export const setEmailSubscriptionDropEndpoint = {
    [SET_EMAIL_SUBSCRIPTION_DROP]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.creator,
        endpointType: otherEndpointType,
        payloadSchema,
        middlewares: [VALIDATE_TOKEN_ACCOUNT],
    },
};
