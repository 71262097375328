import { CREATE_PROJECT_DROP } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	authenticationTypes,
	userGroups,
} from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import createProjectDropPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/createProjectDropPayloadSchema'
import getProjectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getProjectResponseSchema'
import {
	VALIDATE_IP_ADDRESS,
	VALIDATE_TOKEN_ACCOUNT,
} from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[CREATE_PROJECT_DROP]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.creator,
		endpointType: recordEndpointType,
		recordType: project,
		payloadSchema: createProjectDropPayloadSchema,
		responseSchema: getProjectResponseSchema,
		middlewares: [
			VALIDATE_TOKEN_ACCOUNT,
			VALIDATE_IP_ADDRESS,
		],
	},
}
