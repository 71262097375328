import { WHAT_IS_DARE_DROP_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import drop1 from 'root/src/client/public/bg-bubble-copy-3.png'
import drop2 from 'root/src/client/public/bg-bubble-copy.png'
import drop3 from 'root/src/client/public/bg-bubble-copy-2.png'
import drop4 from 'root/src/client/public/bg-bubble-copy-4.png'
import twitchLogo from 'root/src/client/public/ic-twitch-lp.png'
import icTarget from 'root/src/client/public/ic-target.png'
import icIncrease from 'root/src/client/public/ic-increase.png'
import icCollaborate from 'root/src/client/public/ic-collaborate.png'
import { ourEmail } from 'root/src/shared/constants/mail'

export default {
	[WHAT_IS_DARE_DROP_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'about',
		pageContent: {
			title: 'What Is Dare Drop?',
			items: [
				{
					drop: drop1,
					icon: twitchLogo,
					title: 'Do it all from Twitch',
					subtitle: 'Keep your fans engaged on stream where they can quickly create Dares from our Twitch panel.',
					list: [
						'Install in seconds',
						'Donations with Twitch Bits',
						'See most liked Dares from your viewers',
					],
				},
				{
					drop: drop2,
					icon: icTarget,
					title: 'Recognize your supporters',
					subtitle: 'Recognize your supporters for being a part of the stream with the overlay.',
					list: [
						'Install in seconds',
						'Recognize fans for contributing a Dare',
						'Customizable',
					],
				},
				{
					drop: drop3,
					icon: icIncrease,
					title: 'Crowdfund and increase donations',
					subtitle: 'Build hype for a Dare and up the ante by setting a monetary goal for completing the challenge.',
					list: [
						'Name your price',
						'Build hype for an upcoming stream with Dares',
					],
				},
				{
					drop: drop4,
					icon: icCollaborate,
					title: 'Collaborate with other creators',
					subtitle: 'Complete Dares with up to 10 creators and split the donations evenly. ',
					list: [
						'Challenge other creators',
						'Partner with other creators to grow your fanbase',
					],
				},
			],
			contactsSection: {
				title: 'Have Questions?',
				str: {
					text1: 'Read our ',
					anchor1: {
						label: 'FAQs',
						href: 'https://daredrop.com/FAQ#Drops',
					},
					text2: ' or email us at ',
					anchor2: {
						label: ourEmail,
						href: `mailto:${ourEmail}`,
					},
					text3: ' or message us on ',
					anchor3: {
						label: 'Discord',
						href: 'https://discord.gg/xmECwRu',
					},
				},

			},
		},
	},
}
