import {
	ACCEPT_PROJECT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import acceptProjectPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/acceptProjectPayloadSchema'
import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getProjectResponseSchema'
import {
	VALIDATE_IP_ADDRESS,
	VALIDATE_TOKEN_ACCOUNT,
} from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = acceptProjectPayloadSchema
export const responseSchema = projectResponseSchema

export default {
	[ACCEPT_PROJECT]: {
		endpointType: recordEndpointType,
		recordType: project,
		authentication: authenticationTypes.authenticated,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT, VALIDATE_IP_ADDRESS],
	},
}
