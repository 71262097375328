import compose from 'ramda/src/compose'
import over from 'ramda/src/over'
import lensPath from 'ramda/src/lensPath'
import assoc from 'ramda/src/assoc'
import { UPDATE_COMMENT_LIKES } from 'root/src/client/logic/comment/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import propEq from 'ramda/src/propEq'
import { commentKey, messageIdKey, likesKey, dislikesKey } from 'root/src/shared/constants/keys'
import getIdFromKey from 'root/src/shared/util/getIdFromKey'

const {
	setLikes, setDislikes, viewCommentChild,
} = apiStoreLenses

export const updateCommentLikes = (
	state, { messageId, likes, dislikes, messageType, parentId },
) => {
	if (messageType === commentKey) {
		return compose(
			setLikes(messageId, likes),
			setDislikes(messageId, dislikes),
		)(state)
	}
	const commentId = getIdFromKey(parentId)
	const { replies } = viewCommentChild(commentId, state)
	const replyIndex = replies.findIndex(propEq(messageIdKey, messageId))
	const path = ['api', 'records', commentKey, commentId, 'replies', replyIndex]

	return compose(
		over(lensPath(path), assoc(likesKey, likes)),
		over(lensPath(path), assoc(dislikesKey, dislikes)),
	)(state)
}

export default {
	[UPDATE_COMMENT_LIKES]: updateCommentLikes,
}
