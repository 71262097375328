import isOidcAccountSelector from 'root/src/client/logic/auth/selectors/isOidcAccountSelector'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import ccvSelector from 'root/src/client/logic/app/selectors/ccvSelector'
import creatorPoints from 'root/src/client/logic/userData/selectors/creatorPointsSelector'

import {
	LOGIN_ROUTE_ID,
	ACCOUNT_SETTINGS_ROUTE_ID,
	CHANGE_PASSWORD_FORM_ROUTE_ID,
	CONNECTED_CHANNELS_ROUTE_ID,
	MANAGE_PAYOUT_ROUTE_ID,
	CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID,
	SET_EMAIL_FORM_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

import {
	ACCOUNT_SETTINGS_MODULE_ID,
	ACCOUNT_SETTINGS_BANNER_HEADER_MODULE_ID,
	CHANGE_PASSWORD_FORM_MODULE_ID,
	CONNECTED_CHANNELS_USER_DATA_MODULE_ID,
	MANAGE_PAYOUT_METHOD_MODULE_ID, ALERTS_AUDIO_PLAYER_MODULE_ID,
	SET_EMAIL_FORM_MODULE_ID, HELPER_WIDGET_MODULE_ID,
	CASH_OUT_MODULE_ID, CREATOR_ACTIVITY_DETAILS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { pointsMinCcv } from 'root/src/shared/constants/points'

const payoutMethodPointsSelector = (state, props) => {
	const ccv = ccvSelector(state, props)
	const points = creatorPoints(state, props)
	return ccv >= pointsMinCcv || points > 0
}

export default {
	[ACCOUNT_SETTINGS_ROUTE_ID]: {
		url: '/account-settings',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		chatWidget: true,
		discordWidget: true,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [
			HELPER_WIDGET_MODULE_ID,
			ACCOUNT_SETTINGS_BANNER_HEADER_MODULE_ID,
			ACCOUNT_SETTINGS_MODULE_ID,
		],
	},
	[CHANGE_PASSWORD_FORM_ROUTE_ID]: {
		url: '/change-password',
		noSitemap: true,
		authentication: authenticationTypes.optional,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [
			CHANGE_PASSWORD_FORM_MODULE_ID,
		],
	},
	[CONNECTED_CHANNELS_ROUTE_ID]: {
		url: '/connected-channels',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [
			CONNECTED_CHANNELS_USER_DATA_MODULE_ID,
		],
	},
	[MANAGE_PAYOUT_ROUTE_ID]: {
		url: '/payout-method',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [
			MANAGE_PAYOUT_METHOD_MODULE_ID,
			{
				moduleId: CASH_OUT_MODULE_ID,
				condition: payoutMethodPointsSelector,
			},
			{
				moduleId: CREATOR_ACTIVITY_DETAILS_MODULE_ID,
				condition: payoutMethodPointsSelector,
			},
		],
	},
	[CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID]: {
		url: '/alerts',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		modules: [
			ALERTS_AUDIO_PLAYER_MODULE_ID,
		],
	},
	[SET_EMAIL_FORM_ROUTE_ID]: {
		url: '/change-email',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		routeSelector: isOidcAccountSelector,
		modules: [
			SET_EMAIL_FORM_MODULE_ID,
		],
	},
}
