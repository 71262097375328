import { DEMO_CAMPAIGN_CLIPS_ROUTE_ID, DEMO_CAMPAIGN_CREATORS_ROUTE_ID, DEMO_CAMPAIGN_DASHBOARD_ROUTE_ID, LOGIN_ROUTE_ID, } from 'root/src/shared/descriptions/routes/routeIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { DEMO_GRAPHS_DASHBOARD_MODULE_ID, DEMO_CAMPAIGN_CREATORS_MODULE_ID, DEMO_CAMPAIGN_CLIPS_LIST_MODULE, } from 'root/src/shared/descriptions/modules/moduleIds';
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj';
import { demoDashboardEndpointIds } from 'root/src/shared/demoDashboard/demoDashboard.constants';
import { demoDashboardRoutes } from 'root/src/shared/demoDashboard/demoDashboard.routes';
const demoGetDropEventPayload = {
    endpointId: demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DROP_EVENT,
    payload: (state) => {
        const { routeParams: { recordId }, } = currentRouteObj(state);
        return { recordId };
    },
};
export const dashboardDemo = {
    [DEMO_CAMPAIGN_DASHBOARD_ROUTE_ID]: {
        url: demoDashboardRoutes.campaign(),
        makeRequestsOnClient: true,
        noSitemap: true,
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brand,
        defaultRouteId: LOGIN_ROUTE_ID,
        serverSideRequests: [
            demoGetDropEventPayload,
        ],
        modules: [
            DEMO_GRAPHS_DASHBOARD_MODULE_ID,
        ],
    },
    [DEMO_CAMPAIGN_CREATORS_ROUTE_ID]: {
        url: demoDashboardRoutes.creators(),
        makeRequestsOnClient: true,
        noSitemap: true,
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brand,
        defaultRouteId: LOGIN_ROUTE_ID,
        serverSideRequests: [
            demoGetDropEventPayload,
        ],
        modules: [
            DEMO_CAMPAIGN_CREATORS_MODULE_ID,
        ],
    },
    [DEMO_CAMPAIGN_CLIPS_ROUTE_ID]: {
        url: demoDashboardRoutes.clips(),
        makeRequestsOnClient: true,
        noSitemap: true,
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brand,
        defaultRouteId: LOGIN_ROUTE_ID,
        serverSideRequests: [
            demoGetDropEventPayload,
        ],
        modules: [
            DEMO_CAMPAIGN_CLIPS_LIST_MODULE,
        ],
    },
};
