import { CLEAR_FORM } from 'root/src/client/logic/form/actionIds'
import dissocPath from 'ramda/src/dissocPath'
import moduleDescriptions from 'root/src/shared/descriptions/modules'

export default {
	[CLEAR_FORM]: (state, { moduleKey, moduleId }) => {
		const { keepAfterSubmit } = moduleDescriptions[moduleId]
		return keepAfterSubmit ? state : dissocPath(['form', moduleKey], state)
	},
}
