import compose from 'ramda/src/compose'

import { API_RECORD_REQUEST_ERROR } from 'root/src/client/logic/api/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'

const { setRecordErrorsChild, setRecordProcessingChild } = apiStoreLenses

export const apiRecordRequestError = (
	state,
	{ recordId, error },
) => compose(
	setRecordErrorsChild(recordId, error),
	setRecordProcessingChild(recordId, false),
)(state)

export default {
	[API_RECORD_REQUEST_ERROR]: apiRecordRequestError,
}
