import { GET_SELF_PROFILE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { profile } from 'root/src/shared/descriptions/endpoints/recordTypes'
import profilePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/profilePayloadSchema'
import profileResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/profileResponseSchema'

export default {
	[GET_SELF_PROFILE]: {
		endpointType: userDataEndpointType,
		recordType: profile,
		payloadSchema: profilePayloadSchema,
		responseSchema: profileResponseSchema,
		authentication: authenticationTypes.authenticated,
	},
}
