import providers from 'root/src/shared/constants/providers'
import {
	generalWhitelistKey,
	campaignWhitelistKey,
	privateCampaignWhitelistKey,
} from 'root/src/shared/constants/keys'

export const whitelistPlatforms = [providers.twitch, providers.youtube, providers.tiktok]
export const whitelistTypes = [
	generalWhitelistKey,
	campaignWhitelistKey,
	privateCampaignWhitelistKey,
]

export default {
	type: 'object',
	properties: {
		filename: { type: 'string' },
		platform: {
			type: 'string',
			enum: whitelistPlatforms,
		},
		campaignId: { type: 'string' },
		whitelistType: {
			type: 'string',
			enum: whitelistTypes,
		},
	},
	required: ['filename', 'platform', 'whitelistType'],
	additionalProperties: false,
}
