import { routeDescriptionLenses } from 'root/src/client/logic/route/lenses'
import pipe from 'ramda/src/pipe'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import __ from 'ramda/src/__'
import routeDescriptions from 'root/src/shared/descriptions/routes'

const { viewTiktokViewEvent } = routeDescriptionLenses

export default pipe(
	currentRouteId,
	viewTiktokViewEvent(__, routeDescriptions),
)
