import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const getUserGiveawayStatisticsEndpoint = {
    [giveawayEndpointIds.GET_USER_GIVEAWAY_STATISTICS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.creator,
        endpointType: otherEndpointType,
        isBeta: true,
    },
};
