import path from 'ramda/src/path'
import { routeStoreLenses } from 'root/src/client/logic/route/lenses'

const { viewRouteId } = routeStoreLenses

export default (state, { routeId }) => {
	const routeIdMod = routeId || viewRouteId(state)
	return path(
		['app', routeIdMod, 'modalModuleId'], state,
	)
}
