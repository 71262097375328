import React, { memo } from 'react'
import classNames from 'classnames'

import { ternary } from 'root/src/shared/util/ramdaPlus'
import VariableIcon from 'root/src/client/web/base/VariableIcon'
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography'
import ButtonNew from 'root/src/client/web/base/ButtonNew'

export default memo(({ label, icon, withBorder, classes }) => ternary(
	icon,
	<VariableIcon icon={icon} />,
	(!withBorder
		? 			(
			<Typography
				variant={typographyVariants.smallBold}
				className={classNames(classes, { [classes?.withBorder]: withBorder })}
			>
				{label}
			</Typography>
		)

		: 			(
			<ButtonNew
				type="secondary"
			>
				{label}
			</ButtonNew>
		)
	),
))
