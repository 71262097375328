export default {
	type: 'object',
	properties: {
		ip: {
			type: 'string',
			pattern: '^(?:[0-9]{1,3}\\.){3}[0-9]{1,3}$',
			errorMessage: {
				pattern: 'Not valid IP address',
			},
		},
	},
	required: ['ip'],
	additionalProperties: false,
}
