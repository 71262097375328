import pathOr from 'ramda/src/pathOr'
import reduce from 'ramda/src/reduce'
import assoc from 'ramda/src/assoc'
import head from 'ramda/src/head'
import memoizeWith from 'ramda/src/memoizeWith'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import fieldsPathDescriptionsSelectorEmbedded from 'root/src/client/logic/embedded/selectors/fieldsPathDescriptionsSelector'
import fieldsPathDescriptionsSelectorTabs from 'root/src/client/logic/tab/selectors/fieldsPathDescriptionsSelector'
import tabsSelectors from 'root/src/client/logic/tab/selectors/tabsSelector'

const memoizeFn = (state, props) => {
	const embeddedFieldPaths = fieldsPathDescriptionsSelectorEmbedded(state, props)
	const bannerTabPaths = fieldsPathDescriptionsSelectorTabs(state, props)
	return JSON.stringify([...embeddedFieldPaths, ...bannerTabPaths])
}

export default memoizeWith(
	memoizeFn,
	(state, props) => {
		const fieldsDefault = reduce((result, path) => assoc(
			pathOr('none', [...path, 'fieldId'], moduleDescriptions),
			pathOr('', [...path, 'options', 0], moduleDescriptions),
			result,
		), {}, fieldsPathDescriptionsSelectorEmbedded(state, props))
		const tabs = tabsSelectors(state, props)
		return {
			...fieldsDefault,
			...(tabs ? { tab: head(tabs) } : {}),
		}
	},
)
