import { userGroups } from 'root/src/shared/constants/auth'

export default {
	type: 'object',
	properties: {
		email: {
			type: 'string',
			format: 'email',
		},
		password: {
			type: 'string',
			minLength: 8,
			pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@{}<>()/:;_=+,.$%^&*-]).{8,}$',
			errorMessage: {
				pattern: 'Password must have a lower & uppercase letter, number and symbol',
				minLength: 'Password must be at least 8 characters',
			},
		},
		confirmPassword: {
			type: 'string',
			const: {
				$data: '1/password',
			},
		},
		userGroup: {
			type: 'string',
			enum: [
				userGroups.viewer,
				userGroups.creator,
				userGroups.brand,
			],
		},
		newsletterSub: {
			type: 'boolean',
		},
	},
	required: ['email', 'password', 'confirmPassword', 'userGroup'],
	additionalProperties: true,
}
