import assoc from 'ramda/src/assoc'
import assocPath from 'ramda/src/assocPath'
import pipe from 'ramda/src/pipe'
import { DROP_UPLOAD_CODES_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { saveRewardCodesPayloadSchema } from 'root/src/shared/rewards/schemas/saveRewardCodes.payload.schema'
import { ImageValidator } from 'root/src/client/v2/file/helpers/fileValidator'

export const dropUploadCodesFields = [
	{
		fieldId: 'name',
		inputType: 'text',
		placeholder: 'Super Cable Boy Soundtrack',
		labelFieldText: [
			{
				text: 'Display Name',
				required: true,
			},
		],
		required: true,
	},
	{
		fieldId: 'csvCodes',
		inputType: 'attachmentInput',
		attachmentFormats: ['csv', 'txt'],
		subFieldText: 'Coupon/code .csv or .txt upload (one column, no header)',
		required: true,
		labelFieldText: [
			{
				text: 'Codes',
				required: true,
			},
		],
	},
	{
		fieldId: 'image',
		inputType: 'imageInput',
		attachmentText: 'Upload Image+',
		attachmentFormats: ['jpg', 'jpeg', 'png', 'svg'],
		imageConstraints: [
			ImageValidator.minImageDimension({ minWidth: 280, minHeight: 210 }),
		],
		crop: {
			viewerHeight: 210,
			viewerWidth: 280,
			aspect: 280 / 210,
			buttonText: 'Crop Image',
			adjustDisplay: true,
			cropValidationFn: (coordinates) => {
				const { width } = coordinates
				return !(width < 280)
			},
			cropErrorMessage: 'Minimum dimensions: 280x210',
		},
		required: false,
		subFieldText: 'Recommended ratio: 280x210',
		labelFieldText: [
			{
				text: 'Codes Image',
			},
		],
	},
	{
		labelFieldText: [
			{
				text: 'Restrictions',
			},
		],
		fieldId: 'restrictions',
		inputType: 'text',
		inputMaxLength: 80,
		required: false,
	},
]

export default {
	[DROP_UPLOAD_CODES_FORM_MODULE_ID]: {
		moduleType: 'form',
		title: 'Upload Codes',
		schema: pipe(
			assoc('required', ['name']),
			assocPath(['properties', 'csvCodes'], {}),
			assocPath(['properties', 'image'], {}),
		)(saveRewardCodesPayloadSchema),
		fields: dropUploadCodesFields,
		submits: [
			{
				label: 'Upload',
				buttonType: 'primaryButton',
			},
		],
		links: [
			{
				routeId: ACCOUNT_SETTINGS_ROUTE_ID,
				label: 'Go Back',
				dynamicPreviousRoute: true,
			},
		],
	},
}
