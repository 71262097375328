import {
	API_RECORD_CLICK_ACTION_REQUEST_ERROR,
} from 'root/src/client/logic/api/actionIds'
import compose from 'ramda/src/compose'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import { splitAndGetTail } from 'root/src/shared/util/ramdaPlus'
import path from 'ramda/src/path'
import pipe from 'ramda/src/pipe'
import keys from 'ramda/src/keys'
import includes from 'ramda/src/includes'
import filter from 'ramda/src/filter'
import reduce from 'ramda/src/reduce'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import not from 'ramda/src/not'
import always from 'ramda/src/always'

const { setRecordClickActionProcessingChild, setRecordClickActionErrorsChild } = apiStoreLenses

export const apiRecordClickActionRequestError = (
	state,
	{ recordClickActionStoreKey, errors },
) => {
	const recordId = splitAndGetTail('-', recordClickActionStoreKey)

	return pipe(
		path(['api', 'recordClickActionErrors']),
		keys,
		filter(includes(recordId)),
		ifElse(
			pipe(isEmpty, not),
			reduce((acc, storeKey) => (
				setRecordClickActionErrorsChild(storeKey, {}, state)
			), {}),
			always(state),
		),
		compose(
			setRecordClickActionErrorsChild(recordClickActionStoreKey, errors),
			setRecordClickActionProcessingChild(recordClickActionStoreKey, false),
		),
	)(state)
}

export default {
	[API_RECORD_CLICK_ACTION_REQUEST_ERROR]: apiRecordClickActionRequestError,
}
