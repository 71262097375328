import { createContext, useEffect, useState, useRef, useContext } from 'react';
import { SlideoutTray } from 'root/src/client/v2/common/components/SlideoutTray';
const slideoutTrayContext = createContext(null);
function useOutsideClick(ref, callback) {
    const handleClickOutside = (event) => {
        var _a;
        if (ref && ((_a = ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
            return;
        }
        callback();
    };
    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
function useSlideoutTrayProvider() {
    const [isVisible, setVisibility] = useState(false);
    const [content, setContent] = useState(null);
    const slideoutTrayRef = useRef(null);
    useOutsideClick(slideoutTrayRef, () => {
        setVisibility(false);
    });
    const show = () => {
        setVisibility(true);
    };
    const hide = () => {
        setVisibility(false);
    };
    return {
        slideoutTrayRef,
        isVisible,
        setVisibility,
        content,
        setContent,
        show,
        hide,
    };
}
export function SlideoutTrayContextProvider({ children }) {
    const provider = useSlideoutTrayProvider();
    return (<slideoutTrayContext.Provider value={provider}>
			<SlideoutTray />
			{children}
		</slideoutTrayContext.Provider>);
}
export function useSlideoutTrayContext() {
    const context = useContext(slideoutTrayContext);
    if (!context) {
        throw new Error('useSlideoutTrayContext must be used within a SlideoutTrayContextProvider');
    }
    return context;
}
export function useSlideoutTray(content) {
    const { setContent, show, hide, isVisible } = useSlideoutTrayContext();
    useEffect(() => {
        if (content) {
            setContent(content);
        }
    }, []);
    return {
        isVisible,
        show,
        hide,
    };
}
