export var WindowBreakpoint;
(function (WindowBreakpoint) {
    WindowBreakpoint["Xs"] = "xs";
    WindowBreakpoint["GtXs"] = "gtXs";
    WindowBreakpoint["Sm"] = "sm";
    WindowBreakpoint["GtSm"] = "gtSm";
    WindowBreakpoint["Md"] = "md";
    WindowBreakpoint["GtMd"] = "gtMd";
    WindowBreakpoint["Lg"] = "lg";
    WindowBreakpoint["GtLg"] = "gtLg";
    WindowBreakpoint["Xl"] = "xl";
})(WindowBreakpoint || (WindowBreakpoint = {}));
