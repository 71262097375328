import React, { memo } from 'react'

import { fontFamily, ltGtMediaQuery } from 'root/src/client/web/commonStyles'
import withStyles from '@material-ui/core/styles/withStyles'

import classNames from 'classnames'

const styles = {
	fontStyle: {
		fontFamily,
		marginTop: 14,
		fontSize: 24,
		lineHeight: 1.25,
		maxWidth: 405,
		[ltGtMediaQuery]: {
			fontSize: 20,
		},
	},
	noMaxWidth: {
		maxWidth: 'unset',
	},
	dark: {
		color: ' #000',
	},
	noMargin: {
		marginTop: 0,
	},
}

export const TitleUnstyled = memo((({
	children, classes, className, color, noMargin, noMaxWidth,
}) => (
	<div className={classNames(
		classes.fontStyle,
		className,
		{ [classes.dark]: color === 'dark' },
		{ [classes.noMaxWidth]: noMaxWidth },
		noMargin && classes.noMargin,
	)}
	>
		{children}
	</div>
)))

export default withStyles(styles, { name: 'MuiSubTitle' })(TitleUnstyled)
