export const project = 'project'
export const projectList = 'projectList' // subset of all project fields
export const projectAccepted = 'projectAccepted'
export const projectPledged = 'projectPledged'
export const creatorData = 'creatorData'
export const giveaway = 'giveaway'
export const giveawayTerms = 'giveawayTerms'
export const giveawayReward = 'giveawayReward'
export const giveawayVouchers = 'giveawayVouchers'
export const giveawayScheduler = 'giveawayScheduler'
export const creatorAlertsAudioPanel = 'creatorAlertsAudioPanel'
export const topContributors = 'topContributors'
export const profile = 'profile'
export const accountData = 'accountData'

export const coupon = 'coupon'
export const couponList = 'couponList'

export const comment = 'comment'

export const userData = 'userData'
export const paymentMethod = 'paymentMethod'
export const payoutMethod = 'payoutMethod'
export const payoutInfo = 'payoutInfo'
export const notificationList = 'notificationList'
export const gateway = 'gateway'

export const viewProject = 'viewProject'
export const claimProject = 'claimProject'
export const reviewProject = 'reviewProject'

export const alertsConfig = 'alertsConfig'

export const liveAssignees = 'liveAssignees'
export const liveAssigneesRequests = 'liveAssigneesRequests'

export const brandProduct = 'brandProduct'
export const brandProductList = 'brandProductList'
export const pendingBrandUser = 'pendingBrandUser'

export const customSounds = 'customSounds'
export const alertsUrl = 'alertsUrl'
export const creator = 'creator'
export const bitsOptions = 'bitsOptions'
export const twitchOverlayConfig = 'twitchOverlayConfig'
export const obsTopDaresConfig = 'obsTopDaresConfig'
export const dropKey = 'dropKey'
export const dropCode = 'dropCode'
// TODO: rename globally
export const initialProduct = 'initialProduct'
export const dropEvent = 'dropEvent'
export const dropPreviewEvent = 'dropPreviewEvent'
export const shortUrlAnalytics = 'shortUrlAnalytics'
export const publisher = 'publisher'
export const agencyAccountManager = 'agencyAccountManager'
export const notificationSettings = 'notificationSettings'
export const creatorActivityDetails = 'creatorActivityDetails'
export const marketplaceItem = 'marketplaceItem'
export const userCampaignData = 'userCampaignData'
export const whitelist = 'whitelist'
export const publisherBan = 'publisherBan'
export const graphsData = 'graphsData'
export const clip = 'clip'
export const dropDare = 'dropDare'
export const viewerReward = 'viewerReward'
export const creatorReward = 'creatorReward'
