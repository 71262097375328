import { PUBLISHER_NOTIFICATION_SETTINGS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import {
	realTimeKey,
	dailyKey,
	weeklyKey,
} from 'root/src/shared/constants/keys'
import notificationSettingsSelector from 'root/src/client/logic/userData/selectors/notificationSettingsSelector'
import { GET_USER_NOTIFICATION_SETTINGS } from 'root/src/shared/descriptions/endpoints/endpointIds'

export default {
	[PUBLISHER_NOTIFICATION_SETTINGS_MODULE_ID]: {
		moduleType: 'form',
		title: 'Notification Settings',
		endpointId: GET_USER_NOTIFICATION_SETTINGS,
		prePopulateForm: notificationSettingsSelector,
		prePopulateFormOn: 'notificationSettings',
		fields: [
			{
				fieldId: 'emailAlerts',
				inputType: 'radio',
				labelFieldText: [
					{
						text: 'Clip delivered alert frequency',
					},
				],
				options: [
					{
						label: 'Real-time',
						value: realTimeKey,
					},
					{
						label: 'Daily',
						value: dailyKey,
					},
					{
						label: 'Weekly',
						value: weeklyKey,
					},
				],
			},
		],
		submits: [
			{
				label: 'Save',
				buttonType: 'primaryButton',
			},
		],
		links: [
			{
				routeId: ACCOUNT_SETTINGS_ROUTE_ID,
				label: 'Go Back',
			},
		],
	},
}
