import { GET_USER_DROP_EVENT_LANDING_SHORT_URL } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import {
	authenticationTypes,
	userGroups,
} from 'root/src/shared/constants/auth'

export const getUserDropEventLandingShortUrl = {
	[GET_USER_DROP_EVENT_LANDING_SHORT_URL]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.creator,
		endpointType: otherEndpointType,
		payloadSchema: {
			type: 'object',
			properties: {
				dropEventId: { type: 'string' },
			},
			required: ['dropEventId'],
		},
		responseSchema: {
			type: 'string',
		},
	},
}
