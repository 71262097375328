import { giveawayEndpointIds, MAX_REPEAT_TIMES, MIN_DURATION_TIME, MIN_REPEAT_TIMES, } from 'root/src/shared/giveaways/giveaways.constants';
import { otherEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
export const giveawaySchedulePayloadSchema = {
    type: 'object',
    properties: {
        durationTime: {
            type: 'integer',
            minimum: MIN_DURATION_TIME,
            errorMessage: {
                minimum: `Duration must be at least ${MIN_DURATION_TIME / 1000 / 60} minutes`,
            },
        },
        repeatTimes: {
            type: ['integer', 'null'],
            minimum: MIN_REPEAT_TIMES,
            maximum: MAX_REPEAT_TIMES,
            errorMessage: {
                minimum: `Repeat times must be at least ${MIN_REPEAT_TIMES}`,
                maximum: `Repeat times must be at most ${MAX_REPEAT_TIMES}`,
            },
        },
    },
    required: ['durationTime'],
};
export const createGiveawayEndpoint = {
    [giveawayEndpointIds.CREATE_GIVEAWAY]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.creator,
        endpointType: otherEndpointType,
        payloadSchema: giveawaySchedulePayloadSchema,
        isBeta: true,
    },
};
