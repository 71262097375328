import { GET_CREATOR_POINTS_TRANSACTIONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import getCreatorPointsTransactionsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getCreatorPointsTransactionsPayloadSchema'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { creatorActivityDetails } from 'root/src/shared/descriptions/endpoints/recordTypes'

export default {
	[GET_CREATOR_POINTS_TRANSACTIONS]: {
		endpointType: listEndpointType,
		authentication: authenticationTypes.authenticated,
		payloadSchema: getCreatorPointsTransactionsPayloadSchema,
		recordType: creatorActivityDetails,
	},
}
