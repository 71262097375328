import { GET_DROP_GAME_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import getDropGameDataPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDropGameDataPayloadSchema'

export default {
	[GET_DROP_GAME_DATA]: {
		authentication: authenticationTypes.optional,
		endpointType: otherEndpointType,
		requestSchema: getDropGameDataPayloadSchema,
	},
}
