export default {
	type: 'object',
	properties: {
		username: { type: 'string' },
		userGroup: { type: 'string' },
		email: { type: 'string' },
		token: { type: 'string' },
	},
	required: ['username', 'userGroup', 'email', 'token'],
	additionalProperties: false,
}
