import { CAMPAIGN_CLIPS_LIST_MODULE, DEMO_CAMPAIGN_CLIPS_LIST_MODULE } from 'root/src/shared/descriptions/modules/moduleIds';
import campaignClipsList from 'root/src/shared/descriptions/modules/campaignClipsList';
import { DEMO_DASHBOARD_RECORD_ID, demoDashboardEndpointIds } from 'root/src/shared/demoDashboard/demoDashboard.constants';
import setModuleDataFilter from 'root/src/client/logic/api/actions/setModuleDataFilter';
import { payloadFiltersMock } from 'root/src/shared/demoDashboard/demoDashboard.mocks';
import changeRoute from 'root/src/client/logic/route/actions/changeRoute';
import { DEMO_CAMPAIGN_CLIPS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds';
const campaignClipsListModule = campaignClipsList[CAMPAIGN_CLIPS_LIST_MODULE];
export const demoCampaignClips = {
    [DEMO_CAMPAIGN_CLIPS_LIST_MODULE]: Object.assign(Object.assign({}, campaignClipsListModule), { endpointId: [
            demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DELIVERIES,
        ], modifyPayload: ({ payload, state }) => {
            return campaignClipsListModule.modifyPayload({ payload, state, moduleId: DEMO_CAMPAIGN_CLIPS_LIST_MODULE });
        }, listPayload: () => {
            return {
                from: payloadFiltersMock.from,
                to: payloadFiltersMock.to,
                limit: 9,
                currentPage: 1,
            };
        }, onEnterActions: () => [
            {
                action: changeRoute,
                payloads: [
                    {
                        routeId: DEMO_CAMPAIGN_CLIPS_ROUTE_ID,
                        routeParams: { recordId: DEMO_DASHBOARD_RECORD_ID },
                    },
                ],
            },
            {
                action: setModuleDataFilter,
                payloads: [
                    {
                        moduleId: DEMO_CAMPAIGN_CLIPS_LIST_MODULE,
                        filter: 'payloadFilter',
                        id: 'from',
                        value: payloadFiltersMock.from,
                    },
                    {
                        moduleId: DEMO_CAMPAIGN_CLIPS_LIST_MODULE,
                        filter: 'payloadFilter',
                        id: 'to',
                        value: payloadFiltersMock.to,
                    },
                ],
            },
            {
                action: setModuleDataFilter,
                payloads: [
                    {
                        moduleId: CAMPAIGN_CLIPS_LIST_MODULE,
                        filter: 'payloadFilter',
                        id: 'from',
                        value: payloadFiltersMock.from,
                    },
                    {
                        moduleId: CAMPAIGN_CLIPS_LIST_MODULE,
                        filter: 'payloadFilter',
                        id: 'to',
                        value: payloadFiltersMock.to,
                    },
                ],
            },
        ], embeddedContent: Object.assign(Object.assign({}, campaignClipsListModule.embeddedContent), { fields: [
                Object.assign(Object.assign({}, campaignClipsListModule.embeddedContent.fields[0]), { endpointId: demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DELIVERIES }),
            ] }) }),
};
