import { TIKTOK_SET_EMAIL_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[TIKTOK_SET_EMAIL_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'TikTok account',
		modalContent: {
			header: 'Please give us an email to send notifications (and rewards) to [required]',
		},
	},
}
