import {
	COUPON_ADMIN_ROUTE_ID,
	FEATURED_DARES_ADMIN_ROUTE_ID,
	PAYOUT_ADMIN_LIST_ROUTE_ID,
	PENDING_BRANDS_USERS_ADMIN_ROUTE_ID,
	DROP_EVENTS_ADMIN_PANEL_ROUTE_ID,
	SHORT_URL_REPORT_ROUTE_ID,
	BRANDS_REPORTS_ROUTE_ID,
	ADMIN_BAN_IP_FORM_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import {
	COUPON_FORM_ADMIN_MODULE_ID,
	COUPON_LIST_ADMIN_MODULE_ID,
	FEATURED_DARES_ADMIN_FORM_MODULE_ID,
	FEATURED_DARES_ADMIN_LIST_MODULE_ID,
	PAYOUT_ADMIN_LIST_MODULE_ID,
	PENDING_BRAND_USERS_LIST_MODULE_ID,
	DROP_ADMIN_PAYMENT_GENERATOR_FORM,
	DROP_ADMIN_LIST_MODULE_ID,
	DROP_ADMIN_LIST_SEARCH_FORM,
	SHORT_URL_REPORT_MODULE_ID,
	BRANDS_REPORTS_MODULE_ID,
	ADMIN_BAN_IP_FORM_MODULE_ID,
	ADMIN_BANNED_ACCOUNTS_LIST_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

export default {
	[PAYOUT_ADMIN_LIST_ROUTE_ID]: {
		url: '/payout-admin-list',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			PAYOUT_ADMIN_LIST_MODULE_ID,
		],
	},
	[COUPON_ADMIN_ROUTE_ID]: {
		url: '/coupon-admin',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			COUPON_FORM_ADMIN_MODULE_ID,
			COUPON_LIST_ADMIN_MODULE_ID,
		],
	},
	[FEATURED_DARES_ADMIN_ROUTE_ID]: {
		url: '/featured-dares',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			FEATURED_DARES_ADMIN_LIST_MODULE_ID,
			FEATURED_DARES_ADMIN_FORM_MODULE_ID,
		],
	},
	[PENDING_BRANDS_USERS_ADMIN_ROUTE_ID]: {
		url: '/pending-brand-users',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			PENDING_BRAND_USERS_LIST_MODULE_ID,
		],
	},
	[DROP_EVENTS_ADMIN_PANEL_ROUTE_ID]: {
		url: '/drop-admin',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			DROP_ADMIN_PAYMENT_GENERATOR_FORM,
			DROP_ADMIN_LIST_SEARCH_FORM,
			DROP_ADMIN_LIST_MODULE_ID,
		],
	},
	[SHORT_URL_REPORT_ROUTE_ID]: {
		url: '/short-url-analytics',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			SHORT_URL_REPORT_MODULE_ID,
		],
	},
	[BRANDS_REPORTS_ROUTE_ID]: {
		url: '/brands-reports',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			BRANDS_REPORTS_MODULE_ID,
		],
	},
	[ADMIN_BAN_IP_FORM_ROUTE_ID]: {
		url: '/ban-admin',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			ADMIN_BAN_IP_FORM_MODULE_ID,
			ADMIN_BANNED_ACCOUNTS_LIST_MODULE_ID,
		],
	},
}
