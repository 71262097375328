import {
	PUBLISHER_SETTINGS_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { brandSettingsFields } from 'root/src/shared/descriptions/modules/brandSettingsForm'
import setBrandSettingsSchema from 'root/src/shared/descriptions/formSchemas/setBrandSettingsSchema'
import setBrandSettings from 'root/src/client/logic/brandSettings/thunks/setBrandSettings'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import getLogoutUrl from 'root/src/client/util/getLogoutUrl'

export default {
	[PUBLISHER_SETTINGS_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		schema: setBrandSettingsSchema,
		modalContent: {
			title: 'Publisher settings',
			fields: brandSettingsFields,
			submits: [
				{
					label: 'Save',
					buttonType: 'primaryButton',
					onClick: setBrandSettings,
					htmlType: 'submit',
				},
			],
		},
		closeButtonAction: () => pushRouteClient({ href: getLogoutUrl() }),
	},
}
