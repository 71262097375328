import softwerkerImage from 'root/src/client/public/softwerker_62x62.png'
import nickPCurve from 'root/src/client/public/nick-p-curve.png'
import { HOMEPAGE_TESTIMONIALS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { colorsNew, ltGtMediaQuery, ltMdMediaQuery } from 'root/src/client/web/commonStyles'
import Home4Blob from 'root/src/client/public/backgroundBlobs/home-4.svg'

export default {
	[HOMEPAGE_TESTIMONIALS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'Testimonials',
		backgroundData: [
			{ position: 'left',
				image: Home4Blob,
				style: {
					transform: 'translate(0%, -28%)',
					[ltMdMediaQuery]: {
						transform: 'translate(0%, -25%)',
					},
					[ltGtMediaQuery]: {
						transform: 'translate(0%, -12%)',
					},
				},
			},
		],
		pageContent: {
			testimonialItems: [
				{
					description: 'It was great working with Dare Drop. Getting our campaign running with their portal was easy, and streamlined the process of mustering engagement with creators.',
					name: 'Nick P.',
					title: 'Product Manager @ Curve Games',
					image: nickPCurve,
					color: colorsNew.blue,
				},
				{
					description: 'I LOVE the idea of winning keys via Dares. It’s more engaging and creates a special bond with my community. When I win they win too.',
					name: 'Softwerker',
					title: 'Creator',
					image: softwerkerImage,
					color: colorsNew.primary,
				},
			],
		},
	},
}
