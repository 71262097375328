/* eslint-disable consistent-return */
import isEmpty from 'ramda/src/isEmpty'
import sortBy from 'ramda/src/sortBy'
import prop from 'ramda/src/prop'
import pipe from 'ramda/src/pipe'
import last from 'ramda/src/last'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { viewDropEvents } = appStoreLenses

export default (state) => {
	const campaigns = viewDropEvents(state)
	if (isEmpty(campaigns) || !campaigns) return
	return pipe(sortBy(prop('created')), last, prop('mnemonicId'))(campaigns)
}
