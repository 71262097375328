import { creatorDataRouteMap } from 'root/src/shared/descriptions/modules/viewCreatorData'

export default (assignee) => {
	const { platform } = assignee

	if (!platform) {
		return undefined
	}

	const { recordIdProp, routeId } = creatorDataRouteMap[platform]

	return {
		routeId,
		recordId: recordIdProp(assignee),
	}
}
