import outputs from 'root/cfOutput'
import { isProdEnv } from 'root/src/shared/util/envSelect'
import toLower from 'ramda/src/toLower'
import resourcePrefix from 'root/src/aws/util/resourcePrefix'
import { mixpanelProxyStageName } from 'root/src/aws/gateway/resources/common/proxyRestApi'

const { domainName, mixpanelProxyApiId } = outputs

export const apiIdentifier = 'api'
export const region = 'us-east-1'
export const apiGatewayEndpoint = `https://${apiIdentifier}.${domainName}?endpointId=`
export const authDomainCfTemplate = isProdEnv ? `auth.${domainName}` : `auth-${toLower(resourcePrefix)}`
export const authDomain = isProdEnv ? `auth.${domainName}` : `${authDomainCfTemplate}.auth.us-east-1.amazoncognito.com`
export const devOrganizationAccessArn = 'arn:aws:iam::918224642237:role/DevOrganizationAccess'

export const mixpanelProxyUrl = `https://${mixpanelProxyApiId}.execute-api.${region}.amazonaws.com/${mixpanelProxyStageName}`
export const devWattDomain = 'dev.watt.tv'

// @STAGING
export const stagingVariables = {
	dataBucketArn: 'arn:aws:s3:::daremeddstagingstaging-daremeddstagingstagingdatab-2uuoxwo72ct2',
	rootAccountArn: 'arn:aws:iam::427581285010:user/Deployment',
}

// @STAGING
export const productionVariables = {
	accountId: '685756249129',
	dataBucketArn: 'arn:aws:s3:::daremeproduction-daremeproductiondatabucket-18avdglefbli5',
	usersTableArn: 'arn:aws:dynamodb:us-east-1:685756249129:table/AuthApiProduction-UsersTable9725E9C8-1QHYYFFM429LD',
	connectedAccountsTableArn: 'arn:aws:dynamodb:us-east-1:685756249129:table/AuthApiProduction-ConnectedAccountsTable8C017390-A50YVJC4PJEO',
	rootAccountArn: 'arn:aws:iam::685756249129:root',
}
