import { BRAND_DARE_DELIVERY_DETAIL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { GET_PROJECT_BRAND } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	APPROVE_DELIVERY_BRAND,
	REJECT_DELIVERY_BRAND,
} from 'root/src/shared/descriptions/recordClickActions/recordClickActionIds'

export default {
	[BRAND_DARE_DELIVERY_DETAIL_MODULE_ID]: {
		moduleType: 'record',
		recordPageType: 'reviewProject',
		endpointId: GET_PROJECT_BRAND,
		recordPayloadMap: [
			['projectUrl', { $sub: ['recordId'] }],
		],
		recordClickActions: [APPROVE_DELIVERY_BRAND, REJECT_DELIVERY_BRAND],
	},
}
