const paramsMod = params => (typeof params === 'string' ? { message: params } : params)

export class CustomError extends Error {
	constructor({ message, statusCode, details, name, stack, noLog, props }) {
		super()
		this.name = name
		this.message = message
		this.statusCode = statusCode
		this.details = details
		this.noLog = noLog
		this.props = props
		if (stack) this.stack = stack
		Object.setPrototypeOf(this, CustomError.prototype)
	}
}

export class BadRequest extends CustomError {
	constructor(params) {
		super({
			statusCode: 400,
			name: 'BadRequest',
			...paramsMod(params),
		})
		Object.setPrototypeOf(this, BadRequest.prototype)
	}
}

/**
 * @typedef FormErrorConstructorParams
 * @type {object}
 * @property {string} [name]
 * @property {string} [message]
 * @property {unknown} [props]
 * @property {boolean} [noLog]
 */

export class FormError extends CustomError {
	/**
	 * @param {FormErrorConstructorParams} params
	 */
	constructor({ name, message, props, noLog = true }) {
		super({
			name,
			message,
			statusCode: 400,
			props,
			noLog,
		})
		Object.setPrototypeOf(this, FormError.prototype)
	}
}

export class NotFound extends CustomError {
	constructor(params) {
		super({
			name: 'NotFound',
			statusCode: 404,
			details: null,
			noLog: false,
			...paramsMod(params),
		})
		Object.setPrototypeOf(this, NotFound.prototype)
	}
}

export class Forbidden extends CustomError {
	constructor(params) {
		super({
			name: 'Forbidden',
			statusCode: 403,
			details: null,
			noLog: false,
			...paramsMod(params),
		})
		Object.setPrototypeOf(this, Forbidden.prototype)
	}
}

export class Unauthorized extends CustomError {
	constructor(params) {
		super({
			name: 'Unauthorized',
			statusCode: 401,
			details: null,
			noLog: false,
			...paramsMod(params),
		})
		Object.setPrototypeOf(this, Unauthorized.prototype)
	}
}

export class InternalServerError extends CustomError {
	constructor(params) {
		super({
			name: 'InternalServerError',
			statusCode: 500,
			details: null,
			noLog: false,
			...paramsMod(params),
		})
		Object.setPrototypeOf(this, InternalServerError.prototype)
	}
}

export class NotImplemented extends CustomError {
	constructor(params) {
		super({
			name: 'NotImplemented',
			statusCode: 501,
			details: null,
			noLog: false,
			...paramsMod(params),
		})
		Object.setPrototypeOf(this, NotImplemented.prototype)
	}
}

export class PayloadSchemaError extends CustomError {
	constructor(params) {
		super({
			name: 'PayloadSchemaError',
			statusCode: 400,
			details: null,
			noLog: false,
			...paramsMod(params),
		})
		Object.setPrototypeOf(this, PayloadSchemaError.prototype)
	}
}

export class ResponseSchemaError extends CustomError {
	constructor(params) {
		super({
			name: 'ResponseSchemaError',
			statusCode: 500,
			details: null,
			noLog: false,
			...paramsMod(params),
		})
		Object.setPrototypeOf(this, ResponseSchemaError.prototype)
	}
}

export class NetworkError extends CustomError {
	constructor() {
		super({
			name: 'NetworkError',
			statusCode: 500,
			details: null,
			noLog: false,
			message: 'Network Error',
		})
		Object.setPrototypeOf(this, NetworkError.prototype)
	}
}

export class RateLimitsError extends CustomError {
	constructor({ rateLimitReset = null, rateLimitRemaining = 0 } = {}) {
		super({
			name: 'RateLimitsError',
			statusCode: 429,
			details: { rateLimitReset, rateLimitRemaining },
			noLog: false,
			message: 'Too Many Requests',
		})
		Object.setPrototypeOf(this, RateLimitsError.prototype)
	}
}
