import prop from 'ramda/src/prop'
import pipe from 'ramda/src/pipe'
import intersection from 'ramda/src/intersection'
import map from 'ramda/src/map'
import reduce from 'ramda/src/reduce'
import { getPrecedenceLevel } from 'root/src/shared/util/getPrecedence'
import { groupsPrecedence, userRoles } from 'root/src/shared/constants/auth'
import userGroupsSelector from 'root/src/client/logic/auth/selectors/userGroupsSelector'

export default pipe(
	userGroupsSelector,
	intersection(userRoles),
	map(i => [getPrecedenceLevel(groupsPrecedence, i), i]),
	reduce((acc, item) => (item[0] > acc[0] ? item : acc), [0, undefined]),
	prop(1),
)
