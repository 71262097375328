const windowHof = fn => typeof window !== 'undefined' && fn()

const RouteGuard = ({
	routeRestriction, isInitRequestsDone, restrictionRedirect, NProgress, children,
}) => {
	if (routeRestriction) {
		if (!isInitRequestsDone) {
			windowHof(NProgress.start)
			return null
		}
		const condition = restrictionRedirect(routeRestriction)
		if (!condition) {
			return null
		}
	}

	windowHof(NProgress.done)
	return (
		<>{children}</>
	)
}

export default RouteGuard
