import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'
import prepend from 'ramda/src/prepend'
import { DROP_UPLOAD_KEYS_ON_SUCCESS } from 'root/src/client/logic/tableItem/actionIds'
import { BRAND_PRODUCTS_AND_KEYS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { DROP_KEYS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'
import { RewardEndpoint } from 'root/src/shared/rewards/constants'

export default {
	[DROP_UPLOAD_KEYS_ON_SUCCESS]: (state, payload) => {
		const [item] = payload
		const { id } = item
		const listStoreKey = createListStoreKey(
			{ routeId: BRAND_PRODUCTS_AND_KEYS_ROUTE_ID },
			RewardEndpoint.GET_DROP_KEY_REWARDS,
			DROP_KEYS_LIST_MODULE_ID,
		)

		return over(lensPath(['api', 'lists', listStoreKey, 'items']), prepend(id), state)
	},
}
