import {
	GET_PROFILE,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import profilePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/profilePayloadSchema'
import profileResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/profileResponseSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import { profile } from 'root/src/shared/descriptions/endpoints/recordTypes'

export const payloadSchema = profilePayloadSchema
export const responseSchema = profileResponseSchema

export default {
	[GET_PROFILE]: {
		endpointType: recordEndpointType,
		recordType: profile,
		payloadSchema,
		responseSchema,
		authentication: authenticationTypes.optional,
	},
}
