import { memo, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
export const VimeoVideo = memo((props) => {
    const { video, start } = props;
    const [isLoaded, setIsLoaded] = useState(false);
    return (<div className="flex w-full h-full relative flex-initial aspect-video self-center justify-self-center justify-center items-center">
			{!isLoaded && <VideoSkeleton />}
			<Vimeo className="flex flex-initial w-full h-full *:w-full *:h-full" responsive onLoaded={() => setIsLoaded(true)} onPlay={() => {
            if (!isLoaded) {
                setIsLoaded(true);
            }
        }} onReady={() => {
            if (!isLoaded) {
                setIsLoaded(true);
            }
        }} video={video} start={start}/>
		</div>);
});
const VideoSkeleton = memo(() => {
    return (<div className="flex flex-col absolute w-full px-3 pt-2 pb-1 h-full backdrop-blur-2xl backdrop-brightness-90 rounded-xl">
			<VideoSkeletonScreen>
				<LoadingCircles width={80} height={80}/>
			</VideoSkeletonScreen>
			<VideoSkeletonControls>
				<VideoSkeletonPlay />
				<VideoSkeletonTime />
				<VideoSkeletonVolume />
			</VideoSkeletonControls>
		</div>);
});
const VideoSkeletonPlay = memo(() => {
    return (<div className="flex flex-initial bg-light-4 rounded-lg w-8 h-8"/>);
});
const VideoSkeletonTime = memo(() => {
    return (<div className="flex bg-light-4 rounded-xl h-4"/>);
});
const VideoSkeletonVolume = memo(() => {
    return (<div className="flex flex-initial bg-light-4 rounded-xl w-4 h-4"/>);
});
const VideoSkeletonScreen = memo(({ children }) => {
    return (<div className="flex justify-center items-center animate-dark-skeleton-loading rounded-xl">
			{children}
		</div>);
});
const VideoSkeletonControls = memo(({ children }) => {
    return (<div className="flex flex-initial gap-4 items-center w-full p-2">
			{children}
		</div>);
});
