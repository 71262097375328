export const searchTwitchAdminResponseSchema = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
            },
        },
    },
    required: [],
    additionalProperties: true,
};
