import withStyles from '@material-ui/core/styles/withStyles'
import ddLogo from 'root/src/client/public/DDlogoWhiteBigTransparent.png'
import moment from 'moment'
import React from 'react'

const style = {
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	text: {
		padding: 10,
		textAlign: 'center',
		fontSize: 20,
	},
	spacer: {
		height: 150,
	},
}

const TechIssueComponent = ({ classes, timestamp }) => (
	<div className={classes.root}>
		<img src={ddLogo.src} alt="ddLogo" />
		<p className={classes.text}>
			We're experiencing technical difficulties as of {moment(timestamp).format('h:mmA')}, please check back soon.
		</p>
		<div className={classes.spacer} />
	</div>
)

export default withStyles(style, { name: 'MuiTechIssueComponent' })(TechIssueComponent)
