import { Weglot } from 'root/src/shared/constants/weglot';
import { languageCodes } from 'root/src/shared/constants/languageCodes';
import domainName from 'root/src/aws/util/domainName';
export class LanguageService {
    static getCodeFromHost(host) {
        if (!host) {
            return languageCodes.en;
        }
        const languageCode = host.split('.').at(0);
        return this.getValidCode(languageCode);
    }
    static getDomainPrefixCode(domain) {
        if (!domain) {
            return languageCodes.en;
        }
        const languageCode = domain.split('.').at(0);
        const validCode = this.getValidCode(languageCode);
        // Because en.daredrop and daredrop is the same
        if (validCode === languageCodes.en) {
            return null;
        }
        return validCode;
    }
    static getValidCode(languageCode, defaultLanguageCode = languageCodes.en) {
        var _a;
        return (_a = Weglot.languageCodes.find(code => languageCode === code)) !== null && _a !== void 0 ? _a : defaultLanguageCode;
    }
    static getRedirectUrl(path, languageCode) {
        return `https://${languageCode}.${domainName}${path}`;
    }
}
