import { REMOVE_WHITELISTED_CREATOR } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import removeWhitelistedCreator from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/removeWhitelistedCreator'
import whitelistedCreatorsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/whitelistedCreatorsResponseSchema'
import { whitelist } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[REMOVE_WHITELISTED_CREATOR]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: listEndpointType,
		payloadSchema: removeWhitelistedCreator,
		responseSchema: whitelistedCreatorsResponseSchema,
		recordType: whitelist,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
