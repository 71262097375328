import {
	SELF_DARE_CREATE_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import {
	FAQ_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[SELF_DARE_CREATE_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Daring!',
			paragraphs: [
				'Your new dare is live. Share with your viewers to get pledges. Your viewers won\'t be charged unless the dare has been completed.',
				'Once you complete it, come back with a clip to deliver & get pledged rewards.',
				[
					{
						text: 'Please visit our FAQ',
						linkLabel: 'FAQ',
						routeId: FAQ_ROUTE_ID,
					},
					{
						text: ' and join our Discord if you have more questions!',
						linkLabel: 'Discord',
						href: 'https://discord.gg/xmECwRu',
					},
				],
			],
			socialSharing: true,
		},
	},
}
