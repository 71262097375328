export const YOUTUBE_BASE_URL = 'https://www.youtube.com'
export const youtubeApiV3Url = 'https://www.googleapis.com/youtube/v3/'

export const youtubeScopes = [
	'https://www.googleapis.com/auth/youtube.readonly',
	'https://www.googleapis.com/auth/userinfo.email',
	'openid',
	// TODO check if I need it
	'email',
]
export const youtubeSearchDebounce = 500
