export default {
	type: 'object',
	properties: {
		message: {
			type: 'string',
			maxLength: 1000,
			errorMessage: {
				maxLength: 'Comment should not exceed 1000 characters',
			},
		},
	},
	required: ['message'],
	additionalProperties: false,
}
