import {
	GET_USER_DATA,
	SET_PREFERRED_PLATFORM,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import updatePreferredPlatform from 'root/src/client/logic/userData/actions/updatePreferredPlatform'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'

export default platform => async (dispatch, getState) => {
	const state = getState()
	const { statusCode } = await invokeApiRequest(SET_PREFERRED_PLATFORM, { platform }, state)
	const success = statusCode === 200

	if (!success) return

	dispatch(updatePreferredPlatform({ platform }))
	await dispatch(apiRequest(GET_USER_DATA))
}
