import path from 'ramda/src/path'
import uploadFile from 'root/src/client/util/uploadFile'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import { ADD_WHITELISTED_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { WHITELIST_CREATORS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import clearEmbeddedData from 'root/src/client/logic/embedded/actions/clearEmbeddedData'
import modifyWhitelistedCreatorsOnSuccess from 'root/src/client/logic/tableItem/actions/modifyWhitelistedCreatorsOnSuccess'

export default formData => async (dispatch) => {
	const handleDataOnSuccess = (body) => {
		dispatch(clearEmbeddedData(WHITELIST_CREATORS_LIST_MODULE_ID))
		dispatch(modifyWhitelistedCreatorsOnSuccess(body))
	}
	const platformIdsFile = path(['platformIdsList', 'file'], formData)
	if (!platformIdsFile) {
		throw { platformIdsList: 'IDs file is required' }
	}
	const filename = await uploadFile(platformIdsFile, dispatch, 60 * 24 * 3)
	const whitelistType = path(['whitelistType', 'value'], formData)
	const campaignId = path(['campaignId', 'value'], formData)
	const platform = path(['platform', 'value'], formData)

	const { body, error } = await dispatch(
		apiRequest(ADD_WHITELISTED_CREATORS, {
			filename,
			platform,
			whitelistType,
			campaignId,
		}),
	)
	if (error) {
		throw { platformIdsList: error.message }
	}
	if (body.notValidIdCount > 0) {
		handleDataOnSuccess(body)
		throw {
			platformIdsList: `Found ${body.notValidIdCount} not valid name${
				body.notValidIdCount > 1 ? 's' : ''
			}`,
		}
	}
	handleDataOnSuccess(body)
	return body
}
