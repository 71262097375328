import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import prePopulateForm from 'root/src/client/logic/form/actions/prePopulateForm'
import setFormErrors from 'root/src/client/logic/form/actions/setFormErrors'
import { GET_DROP_GAME_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import path from 'ramda/src/path'
import pipe from 'ramda/src/pipe'
import { mapP, splitAndGetLast } from 'root/src/shared/util/ramdaPlus'
import { baseUrl } from 'root/src/shared/constants/steam'
import ajax from 'root/src/shared/util/ajax'
import mediaMimeTypes from 'root/src/shared/constants/mediaMimeTypes'
import { formStoreLenses } from 'root/src/client/logic/form/lenses'
import isNil from 'ramda/src/isNil'
import not from 'ramda/src/not'
import filter from 'ramda/src/filter'
import { getFileExtension } from 'root/src/client/util/isVideoType'
import clearFormErrors from 'root/src/client/logic/form/actions/clearFormErrors'

const { pathOrFormInputs } = formStoreLenses

const getFileFromUrl = async ({ url, type, name }) => {
	const fileBlob = await ajax({
		url,
		responseType: 'blob',
	})
	return new File([fileBlob], name, { type })
}

const generateAssets = async (url, fileName) => {
	if (!url) return undefined

	const ext = getFileExtension(url)
	const type = mediaMimeTypes[ext]
	const name = mediaMimeTypes[splitAndGetLast('.', fileName)] ? fileName : `${fileName}.${ext}`
	const file = await getFileFromUrl({ url, type, name })
	return { file, name, url }
}

export default ({ moduleKey, value }) => async (dispatch, getState) => {
	const state = getState()
	const gameId = path(['id'], value)

	if (!gameId) {
		dispatch(setFormErrors(moduleKey, { gameObj: 'Select the game' }))
		throw { message: 'Select the game' }
	}

	const { body } = await dispatch(apiRequest(GET_DROP_GAME_DATA, { gameId }))

	if (!body) {
		dispatch(setFormErrors(moduleKey, { gameObj: 'Game not found' }))
		throw { message: 'Game not found' }
	}

	const { trailer: youtubeURL } = body

	const generateScreenshots = async assets => mapP(
		async screenshot => generateAssets(screenshot, splitAndGetLast('/', screenshot)),
		assets,
	)

	const {
		detailed_description: gameDescriptionBody,
		heroImage,
		steam_appid: steamAppId,
		actionVideo,
		name,
		landing_page: landingPage,
		screenshots,
	} = body

	const formData = pathOrFormInputs(
		moduleKey, {}, state,
	)

	dispatch(clearFormErrors(moduleKey))

	const assets = [
		await generateAssets(actionVideo, name),
		await generateAssets(heroImage, name),
		...await generateScreenshots(screenshots),
	]

	return dispatch(prePopulateForm({
		moduleKey,
		formData: {
			...formData,
			cta: steamAppId ? 'Visit on Steam' : 'Visit the website',
			youtubeURL,
			url: { custom: false, value: steamAppId ? `${baseUrl}app/${steamAppId}` : landingPage },
			assets: filter(pipe(isNil, not), assets),
			gameDescriptionBody,
			campaignName: name,
			dropTitle: name,
		},
	}))
}
