export default {
	type: 'object',
	properties: {
		code: { type: 'string' },
		routeId: { type: 'string' },
		recordId: { type: 'string' },
		username: { type: 'string' },
	},
	required: ['code', 'username'],
	additionalProperties: false,
}
