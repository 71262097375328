import { SPONSORSHIP_DETAILS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds';
import { sponsorshipRegularTerms } from 'root/src/shared/descriptions/modules/howToEarnPointsModal';
export const sponsorshipModal = {
    [SPONSORSHIP_DETAILS_MODULE_ID]: {
        moduleType: 'modal',
        modalContent: {
            regularTerms: sponsorshipRegularTerms,
        },
    },
};
