import pipe from 'ramda/src/pipe'
import __ from 'ramda/src/__'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import { routeDescriptionLenses } from 'root/src/client/logic/route/lenses'
import routeDescriptions from 'root/src/shared/descriptions/routes'

const { viewDiscordWidget } = routeDescriptionLenses

export default pipe(
	currentRouteId,
	viewDiscordWidget(__, routeDescriptions),
)
