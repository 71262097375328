import { httpsWwwRe } from 'root/src/shared/util/regexes';
export const removeProtocolAndWww = (url) => {
    try {
        return url.replace(httpsWwwRe, '');
    }
    catch (_) {
        return undefined;
    }
};
export const addHttpsProtocol = (url) => {
    try {
        return url.replace(httpsWwwRe, 'https://');
    }
    catch (_) {
        return undefined;
    }
};
export const addProtocolAndEscape = (url) => {
    try {
        return `https%3A%2F%2F${url.replace(/\//g, '%2F')}`;
    }
    catch (_) {
        return undefined;
    }
};
