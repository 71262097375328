import {
	JOIN_GIVEAWAY_ALERT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[JOIN_GIVEAWAY_ALERT]: {
		endpointType: gatewayEndpointType,
	},
}
