export default {
	type: 'object',
	properties: {
		image: {
			type: 'object',
			properties: {
				url: { type: 'string' },
				x: { type: 'number' },
				y: { type: 'number' },
				xPixels: { type: 'number' },
				yPixels: { type: 'number' },
				width: { type: 'number' },
				height: { type: 'number' },
				zoom: { type: 'number' },
			},
		},
		description: {	type: 'string' },
		youtube: {	type: 'string' },
		facebook: {	type: 'string' },
		instagram: {	type: 'string' },
		twitter: {	type: 'string' },
		discord: {	type: 'string' },
		discordServerName: { type: 'string' },
		vkontakte: {	type: 'string' },
		tiktok: {	type: 'string' },
		other: {	type: 'string' },
		completedWidget: { type: 'boolean' },
		hasCompletedProject: { type: 'boolean' },
		hasCompletedDropProject: { type: 'boolean' },
		introProjectCreated: { type: 'boolean' },
		obsExtensionAdded: { type: 'boolean' },
		extensions: { type: 'array' },
		mnemonicId: {	type: 'string' },
		username: {	type: 'string' },
		followerCount: {	type: 'integer', minimum: 0 },
		displayName: { type: 'string' },
		platform: { type: 'string' },
		deliveryRatioDenominator: { type: 'number' },
		deliveryRatioNumerator: { type: 'number' },
	},
	required: [],
	additionalProperties: false,
}
