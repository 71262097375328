import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import orEmpty from 'root/src/shared/util/orEmpty';
import { colorsNew, dropShadow } from 'root/src/client/web/commonStyles';
import { withStylesHof } from 'root/src/client/util/withStylesHof';
const iconClassnames = {
    glowFrontIcon: 'glowFrontIcon',
    glowBackIcon: 'glowBackIcon',
    noGlowFrontIcon: 'noGlowFrontIcon',
    noGlowBackIcon: 'noGlowBackIcon',
};
export const iconParentStyle = {
    [`& .${iconClassnames.glowFrontIcon}`]: {
        opacity: 1,
    },
    [`& .${iconClassnames.glowBackIcon}`]: {
        opacity: 1,
    },
    [`& .${iconClassnames.noGlowFrontIcon}`]: {
        opacity: 0,
    },
    [`& .${iconClassnames.noGlowBackIcon}`]: {
        opacity: 1,
    },
    '&:hover': {
        [`& .${iconClassnames.glowFrontIcon}`]: {
            opacity: 0,
        },
        [`& .${iconClassnames.glowBackIcon}`]: {
            opacity: 1,
        },
        [`& .${iconClassnames.noGlowFrontIcon}`]: {
            opacity: 1,
        },
        [`& .${iconClassnames.noGlowBackIcon}`]: {
            opacity: 1,
        },
    },
};
const style = {
    // this kind of style combination lets us to position
    // item one behind another without position: absolute
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    primaryIcon: {
        gridColumnStart: 1,
        gridRowStart: 1,
    },
    secondaryIcon: {
        gridColumnStart: 1,
        gridRowStart: 1,
    },
};
// this component returns icon based on prop svg or fs
// svg is svg react component with style prop passed
// fa is fontAwesome icon from @fortawesome/fontawesome
// to support hover we must set
const Icon = forwardRef(({ color = colorsNew.white, icon: Icn, size = 16, classes, primary = true, customStyle = {}, glow, className, onClick, fillColor, textColor, }, ref) => {
    const sizeStyle = {
        fontSize: size,
        maxHeight: size,
        maxWidth: size,
        height: size,
        width: size,
        fillColor,
        textColor,
        color,
    };
    const flexStyle = Object.assign(Object.assign({}, sizeStyle), { display: 'flex' });
    const primaryIconStyle = Object.assign(Object.assign(Object.assign({}, sizeStyle), { fontSize: '1em' }), orEmpty(glow, {
        WebkitFilter: dropShadow(glow),
        filter: dropShadow(glow),
    }));
    if (typeof Icn === 'object') {
        return (<div ref={ref} onClick={onClick} className={classNames(classes.root, className)} style={sizeStyle}>
				{glow && (<div className={classNames(classes.iconContainer, classes.primaryIcon, primary ? iconClassnames.glowFrontIcon : iconClassnames.noGlowFrontIcon)} style={flexStyle}>
						<FontAwesomeIcon icon={Icn} size="xs" style={Object.assign({ fontSize: '1em' }, primaryIconStyle)}/>
					</div>)}
				<div className={classNames(classes.iconContainer, classes.secondaryIcon, !primary ? iconClassnames.noGlowBackIcon : iconClassnames.glowBackIcon)} style={flexStyle}>
					<FontAwesomeIcon icon={Icn} size="lg" style={Object.assign({ fontSize: '1em' }, sizeStyle)}/>
				</div>
			</div>);
    }
    if (typeof Icn === 'function') {
        // must have prop style passed
        return (<div ref={ref} onClick={onClick} className={classNames(classes.root)} style={sizeStyle}>
				{glow && (<div className={classNames(classes.iconContainer, classes.primaryIcon, primary ? iconClassnames.glowFrontIcon : iconClassnames.noGlowFrontIcon)} style={sizeStyle}>
						<Icn style={Object.assign(Object.assign({}, primaryIconStyle), { fill: color })}/>
					</div>)}
				<div className={classNames(classes.iconContainer, classes.secondaryIcon, !primary ? iconClassnames.noGlowBackIcon : iconClassnames.glowBackIcon)} style={sizeStyle}>
					<Icn style={Object.assign(Object.assign(Object.assign({}, sizeStyle), { fill: color }), customStyle)}/>
				</div>
			</div>);
    }
    return null;
});
export default withStylesHof(style, { name: 'MuiIcon' }, Icon);
