import mixpanel from 'mixpanel-browser'
import { mixpanelProxyUrl } from 'root/src/shared/constants/aws'

export const mixpanelInit = (setDeviceId) => {
	// eslint-disable-next-line no-undef
	mixpanel.init(MIXPANEL_TOKEN, {
		api_host: mixpanelProxyUrl,
		loaded: () => {
			const deviceId = mixpanel.get_property('$device_id')
			setDeviceId(deviceId)
		},
	})
}

const actions = {
	identify: id => mixpanel.identify(id),
	alias: id => mixpanel.alias(id),
	track: (name, props) => mixpanel.track(name, props),
	time_event: name => mixpanel.time_event(name),
	people: {
		set: props => mixpanel.people.set(props),
		set_once: props => mixpanel.people.set_once(props),
	},
	reset: () => mixpanel.reset(),
}

export default actions
