import { BRANDS_SLIDER_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import focusLogo from 'root/src/client/public/brandsLogos/focus.png'
import avalancheLogo from 'root/src/client/public/brandsLogos/avalanche.png'
import dotemuLogo from 'root/src/client/public/brandsLogos/dotemu.png'
import daedalicLogo from 'root/src/client/public/brandsLogos/daedalic.png'
import fireshineLogo from 'root/src/client/public/brandsLogos/fireshine.png'
import elevenbitLogo from 'root/src/client/public/brandsLogos/11bit.png'
import funcomLogo from 'root/src/client/public/brandsLogos/funcom.png'
import humbleLogo from 'root/src/client/public/brandsLogos/humble.png'
import naconLogo from 'root/src/client/public/brandsLogos/nacon.png'
import team17Logo from 'root/src/client/public/brandsLogos/team17.png'
import thrustmasterLogo from 'root/src/client/public/brandsLogos/thrustmaster.png'
import thunderfulLogo from 'root/src/client/public/brandsLogos/thunderful.png'

const scrollItemBreakpoints = (width) => {
	if (width < 720) return 2
	if (width < 1080) return 3
	if (width < 1440) return 5
	if (width < 2082) return 7
	return 4
}

const sliderWidth = (width) => {
	if (width < 1650) return 85
	if (width < 1880) return 80
	if (width < 2150) return 75
	if (width < 2300) return 70
	if (width < 2600) return 65
	return 55
}

export default {
	[BRANDS_SLIDER_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'brandsSlider',
		pageContent: {
			list: [
				{ brandName: 'Focus', brandLogo: focusLogo },
				{ brandName: 'Daedalic', brandLogo: daedalicLogo },
				{ brandName: 'Nacon', brandLogo: naconLogo },
				{ brandName: 'Funcom', brandLogo: funcomLogo },
				{ brandName: 'Team 17', brandLogo: team17Logo },
				{ brandName: 'Humble', brandLogo: humbleLogo },
				{ brandName: '11 Bit', brandLogo: elevenbitLogo },
				{ brandName: 'Avalanche', brandLogo: avalancheLogo },
				{ brandName: 'Dotemu', brandLogo: dotemuLogo },
				{ brandName: 'Thrustmaster', brandLogo: thrustmasterLogo },
				{ brandName: 'Thunderful', brandLogo: thunderfulLogo },
				{ brandName: 'Fireshine', brandLogo: fireshineLogo },
			],
		},
		sliderWidth,
		scrollItemBreakpoints,
	},
}
