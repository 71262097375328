import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId';
import currentRouteParams from 'root/src/client/logic/route/selectors/currentRouteParams';
import querySelector from 'root/src/client/logic/route/selectors/querySelector';
import languageCodeSelector from 'root/src/client/logic/route/selectors/languageCodeSelector';
export default (state) => {
    var _a, _b;
    return ({
        routeId: currentRouteId(state),
        routeParams: currentRouteParams(state),
        query: querySelector(state),
        languageCode: languageCodeSelector(state),
        href: (_b = (_a = global === null || global === void 0 ? void 0 : global.window) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.href,
    });
};
