import { CHECK_USER_HAS_GAME } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import checkUserHasGamePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/checkUserHasGamePayloadSchema'

export const payloadSchema = checkUserHasGamePayloadSchema

export default {
	[CHECK_USER_HAS_GAME]: {
		authentication: authenticationTypes.authenticated,
		endpointType: otherEndpointType,
		payloadSchema,
	},
}
