import sakiSakuraImage from 'root/src/client/public/sakisakura_62x62.png'
import { CREATORS_TESTIMONIALS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { colorsNew, ltGtMediaQuery, ltMdMediaQuery } from 'root/src/client/web/commonStyles'
import Creators3Blob from 'root/src/client/public/backgroundBlobs/for-creators-3.svg'
import softwerkerImage from 'root/src/client/public/softwerker_62x62.png'

export default {
	[CREATORS_TESTIMONIALS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'Testimonials',
		backgroundData: [
			{
				position: 'left',
				image: Creators3Blob,
				style: {
					transform: 'translate(0, -35%)',
					[ltMdMediaQuery]: {
						transform: 'translate(0%, -15%)',
					},
					[ltGtMediaQuery]: {
						transform: 'translate(0%, 0%)',
					},
				},
			},
		],
		pageContent: {
			testimonialItems: [
				{
					description: 'I LOVE the idea of winning keys via Dares. It’s more engaging and creates a special bond with my community. When I win they win too.',
					name: 'Softwerker',
					title: 'Twitch',
					image: softwerkerImage,
					color: colorsNew.teal,
				},
				{
					description: 'DareDrop has not only been an awesome way for my chat to challenge me, but putting bounties and creating challenges during FGC tournaments have never been easier.',
					name: 'SakiSakuraTV',
					title: 'Twitch',
					image: sakiSakuraImage,
					color: colorsNew.primary,
				},
			],
		},
	},
}
