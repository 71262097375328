var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getOidcUrl } from 'root/src/client/v2/auth/helpers/getOidcUrl';
import wait from 'root/src/shared/util/wait';
// TODO rewrite this so it's not triple nested function
export const loginWith = (provider) => (state) => (token) => __awaiter(void 0, void 0, void 0, function* () {
    window.location.replace(getOidcUrl(provider, token, state));
    yield wait(20000);
});
