import forEach from 'ramda/src/forEach'
import toPairs from 'ramda/src/toPairs'
import stringify from 'qs/lib/stringify'
import replaceObjectKeysRecursively from 'root/src/shared/util/replaceObjectKeysRecursively'

/**
 * @param {AjaxOptions} params
 * @return {Promise<unknown>}
 */
export default params => new Promise((resolve, reject) => {
	const {
		url, method, body, queryParams, headers = {}, file, responseType = '',
	} = params
	const options = { url, method, body, queryParams, headers, file, responseType }
	const queryString = queryParams
		? `?${stringify(queryParams, { indices: false })}` : ''
	const xhr = new XMLHttpRequest()
	xhr.responseType = responseType
	const jsonBody = body ? JSON.stringify(body) : undefined
	const defaultMethod = method || 'GET'
	const defaultHeaders = {
		...headers,
		'Content-Type': headers['Content-Type'] || 'application/json',
	}

	xhr.open(defaultMethod, `${url}${queryString}`)
	forEach(
		([key, value]) => {
			xhr.setRequestHeader(key, value)
		},
		toPairs(defaultHeaders),
	)
	xhr.onload = () => {
		let parsed = xhr.response
		try {
			parsed = JSON.parse(parsed)
		} catch (e) {
			// response not json, leave parsed as text and continue
		}
		if (xhr.status >= 200 && xhr.status < 300) {
			resolve(parsed)
		} else {
			reject(parsed)
		}
	}
	xhr.onerror = () => {
		console.error(JSON.stringify(replaceObjectKeysRecursively(['password'], '***********', options), null, 2))
		reject(new Error(`Error while request ${url} with ${defaultMethod} method.`))
	}
	if (file) {
		xhr.send(file)
	} else {
		xhr.send(jsonBody)
	}
})

/**
 * @typedef AjaxOptions
 * @property {string} url
 * @property {string} [method]
 * @property {*} [body]
 * @property {Record<string, *>} [queryParams]
 * @property {Record<string, *>} [headers]
 * @property {File} [file]
 * @property {string} [responseType]
 */
