export const SORT_BY_BOUNTY = 'SORT_BY_BOUNTY'
export const SORT_BY_TIME_LEFT = 'SORT_BY_TIME_LEFT'
export const SORT_BY_NEWEST = 'SORT_BY_NEWEST'
export const SORT_BY_CREATED_ASC = 'SORT_BY_CREATED_ASC'
export const SORT_BY_ACCEPTED = 'SORT_BY_ACCEPTED'
export const SORT_BY_CREATED_DESC = 'SORT_BY_CREATED_DESC'
export const SORT_BY_FEATURED = 'SORT_BY_FEATURED'
export const SORT_DARES_TWITCH = 'SORT_DARES_TWITCH'
export const SORT_HOMEPAGE = 'SORT_HOMEPAGE'
export const SORT_OVERLAY_DARES = 'SORT_OVERLAY_DARES'
export const SORT_BY_DELIVERED = 'SORT_BY_DELIVERED'
export const SORT_BY_UPLOADED_AT_ASC = 'SORT_BY_UPLOADED_AT_ASC'
