import {
	VIEWERS_SHOWCASE_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import viewers4 from 'root/src/client/public/backgroundBlobs/viewers4.svg'

export default {
	[VIEWERS_SHOWCASE_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'showcase',
		pageContent: {
			title: 'Dare Creators and Get Rewards',
			description: 'Dare any creator. Watch the result. Get rewards from game developers & publishers.',
			videoUrl: 'https://www.youtube.com/embed/wBWC2DdXS80',
			textPosition: 'top',
		},
		backgroundData: [
			{
				position: 'left',
				image: viewers4,
				style: {
					transform: 'translate(0%, 30%)',
				},
			},
		],
	},
}
