import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { DROPS_SLIDER_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { dropKey } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { dropsSliderListType } from 'root/src/shared/constants/moduleTypes'
import { liveEventsKey } from 'root/src/shared/constants/keys'

const scrollItemBreakpoints = (width) => {
	if (width < 720) return 1
	if (width < 1080) return 2
	if (width < 1440) return 3
	if (width < 2082) return 4
	return 4
}

const sliderWidth = (width) => {
	if (width < 1650) return 85
	if (width < 1880) return 80
	if (width < 2150) return 75
	if (width < 2300) return 70
	if (width < 2600) return 65
	return 55
}

export default {
	[DROPS_SLIDER_MODULE_ID]: {
		moduleType: 'list',
		listType: dropsSliderListType,
		endpointId: [GET_DROP_EVENTS],
		recordType: dropKey,
		listPayload: {
			section: liveEventsKey,
			pageItemLength: 8,
		},
		scrollItemBreakpoints,
		sliderWidth,
	},
}
