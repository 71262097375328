'use client';
import { useState, useEffect } from 'react';
export function useLocation() {
    var _a, _b;
    const [pathname, setPathname] = useState((_a = global.window) === null || _a === void 0 ? void 0 : _a.location.pathname);
    const [search, setSearch] = useState((_b = global.window) === null || _b === void 0 ? void 0 : _b.location.search);
    const listenToPopstate = () => {
        setPathname(window.location.pathname);
        setSearch(window.location.search);
    };
    useEffect(() => {
        window.addEventListener('popstate', listenToPopstate);
        return () => {
            window.removeEventListener('popstate', listenToPopstate);
        };
    }, []);
    return {
        pathname,
        search,
    };
}
