import { CREATE_BRAND_PROJECT_FORM_MODULE_ID, DARE_CREATE_SUCCESS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { assigneesKey } from 'root/src/shared/constants/keys'
import createProjectProductPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/createProjectProductPayloadSchema'
import { CREATE_PROJECT_PRODUCT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { primaryButton } from 'root/src/client/web/componentTypes'
import currentRouteParamsRecordId	from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import { BRAND_DARES_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { youtubeSearchDebounce } from 'root/src/shared/constants/youtube'

export default {
	[CREATE_BRAND_PROJECT_FORM_MODULE_ID]: {
		moduleType: 'form',
		schema: createProjectProductPayloadSchema,
		title: 'Dare a Creator',
		hasNextStep: true,
		fields: [
			{
				inputType: 'variantField',
				optionsPromiseType: 'twitchChannels',
				fieldId: assigneesKey,
				maxItems: 10,
				placeholder: 'Select',
				uniqueItems: true,
				labelFieldText: [
					{
						text: 'Select Creators',
					},
				],
				required: true,
				isMulti: true,
				variantOptions: [
					{
						name: 'Twitch',
						id: 'twitch',
						inputType: 'autoComplete',
						optionsPromiseType: 'twitchChannels',
					},
					{
						name: 'YouTube',
						id: 'youtube',
						debounceDelay: youtubeSearchDebounce,
						inputType: 'autoComplete',
						optionsPromiseType: 'youtubeChannels',
					},
					{
						name: 'TikTok',
						id: 'tiktok',
						debounceDelay: youtubeSearchDebounce,
						inputType: 'autoComplete',
						optionsPromiseType: 'tiktokCreators',
					},
				],
			},
			{
				fieldId: 'title',
				inputType: 'text',
				placeholder: 'Deagle Monster',
				labelFieldText: [
					{
						text: 'Dare Title',
					},
				],
				required: true,
				inputMaxLength: 60,
				locked: true,
			},
			{
				fieldId: 'description',
				inputType: 'text',
				placeholder: 'Get 7 headshot kills with one deagle clip.\n- with 7 shots, get 7 headshot kills with a desert eagle\n- no reloading between kills',
				multiline: true,
				multilineRows: 4,
				subFieldText: 'Make sure you describe what you want to see in detail so the creator can deliver what you want.',
				labelFieldText: [
					{
						text: 'Description',
					},
				],
				required: true,
				locked: true,
			},
			{
				fieldId: 'campaign',
				inputType: 'dropdown',
				placeholder: 'Choose Campaign',
				uniqueItems: true,
				labelFieldText: [
					{
						text: 'Select campaign',
					},
				],
				maxItems: 1,
				required: true,
				isMulti: false,
				locked: true,
			},
		],
		submits: [
			{
				label: 'Create Dare',
				endpointId: CREATE_PROJECT_PRODUCT,
				buttonType: primaryButton,
				onSuccessRedirectSelector: (state, { previousRecordId }) => ({
					routeId: BRAND_DARES_ROUTE_ID,
					routeParams: {
						recordId: previousRecordId || currentRouteParamsRecordId(state),
					},
					query: { modal: DARE_CREATE_SUCCESS_MODULE_ID },
				}),
			},
		],
	},
}
