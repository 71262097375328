import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { RewardType } from 'root/src/shared/rewards/constants';
export const createBundleEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.brandUser,
    endpointType: recordEndpointType,
    middlewares: [VALIDATE_TOKEN_ACCOUNT],
    payloadSchema: {
        type: 'object',
        properties: {
            amount: { type: 'integer', minimum: 1 },
            name: {
                type: 'string',
                maxLength: 300,
            },
            image: {
                type: 'string',
            },
            rewards: {
                type: 'array',
                minItems: 1,
                items: {
                    type: 'object',
                    properties: {
                        id: { type: 'string' },
                        amount: { type: 'integer', minimum: 1 },
                        type: {
                            type: 'string',
                            enum: [
                                RewardType.KEY,
                                RewardType.CODE,
                                RewardType.CUSTOM,
                            ],
                        },
                    },
                    required: ['id', 'amount', 'type'],
                },
            },
        },
        required: ['amount', 'name', 'rewards'],
    },
};
