/* eslint-disable prefer-destructuring */
import set from 'ramda/src/set'
import view from 'ramda/src/view'
import pathOr from 'ramda/src/pathOr'
import curry from 'ramda/src/curry'

import { variableSchemaKey } from 'root/src/shared/constants/keys'
import { capitalize } from 'root/src/shared/util/stringCase'
import {
	insertedArgFn, insertedArgFnLens, insertedArgFnLensNoDiff,
	insertedArgFnNoDiff,	argSubstitute, filterSubstitute,
} from './lensesFromSchemaUtils'
import { reduce, reduceObj, prop, spreadObj, toPairs, push } from './nativeFns'

const pathOrArranged = curry((pathArr, fn, obj) => pathOr(fn, pathArr, obj))

const createEachLensTypeWithInserts = (baseLensFnKey, schemaPath) => {
	const diffLength = schemaPath.length - filterSubstitute(schemaPath).length
	const fnLens = diffLength === 0 ? insertedArgFnLensNoDiff : insertedArgFnLens
	const fnNoLens = diffLength === 0 ? insertedArgFnNoDiff : insertedArgFn
	return ({
		[`view${baseLensFnKey}`]: fnLens(view, schemaPath, 1, diffLength),
		[`set${baseLensFnKey}`]: fnLens(set, schemaPath, 2, diffLength),
		[`pathOr${baseLensFnKey}`]: fnNoLens(pathOrArranged, schemaPath, 2, diffLength),
	})
}

const createSchemaLenses = (schema, options = {}, parentPath = []) => reduce(
	(result, [schemaKey, property]) => {
		const schemaPath = push(parentPath, schemaKey)
		const baseLensFnKey = capitalize(schemaKey)
		const propertyType = property.type

		let extras = []

		if (propertyType === 'object') {
			if (schemaKey === variableSchemaKey) {
				const variableSchemaPath = push(parentPath, argSubstitute)
				const prevSchemaKey = parentPath[parentPath.length - 1]
				const childLenses = prevSchemaKey
					? createEachLensTypeWithInserts(
						`${capitalize(prevSchemaKey)}Child`,
						variableSchemaPath,
					) : {}
				extras = [
					childLenses,
					createSchemaLenses(property, options, variableSchemaPath),
				]
			} else {
				extras = [
					createSchemaLenses(property, options, schemaPath),
				]
			}
		}

		if (propertyType === 'array') {
			const variableSchemaPath = push(schemaPath, argSubstitute)
			extras = [
				createEachLensTypeWithInserts(`${baseLensFnKey}Item`,	variableSchemaPath),
				createSchemaLenses(property.items, options, variableSchemaPath),
			]
		}

		const schemaEndpoints = variableSchemaKey !== schemaKey
			? createEachLensTypeWithInserts(baseLensFnKey, schemaPath) : {}

		return spreadObj(
			[schemaEndpoints, reduceObj((acc, item) => (spreadObj([acc, item])), {}, extras), result],
		)
	},
	{},
	toPairs(prop('properties', schema) ||	prop('patternProperties', schema)),
)
export default createSchemaLenses
