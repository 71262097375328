import { languageCodes } from 'root/src/shared/constants/languageCodes';
import { LanguageService } from 'root/src/client/v2/common/helpers/language.service';
import { useRouter } from 'next/router';
import { setCookie } from 'root/src/client/v2/browser/helpers/cookies';
import domainName from 'root/src/aws/util/domainName';
export const useLanguage = () => {
    const router = useRouter();
    const languageSwitchHandler = (languageCode) => {
        const { protocol } = new URL(window.location.href);
        setCookie('languageCode', languageCode);
        if (languageCode === languageCodes.en) {
            return router.push(`${protocol}//${domainName}${router.asPath}`);
        }
        return router.push(`${protocol}//${languageCode}.${domainName}${router.asPath}`);
    };
    const languageCode = useLanguageCode();
    return {
        languageCode,
        languageSwitchHandler,
    };
};
const useLanguageCode = () => {
    if (typeof window === 'undefined') {
        return languageCodes.en;
    }
    return LanguageService.getCodeFromHost(window.location.host);
};
