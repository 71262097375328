import { ADMIN_GET_BANNED_ACCOUNTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { userGroups, authenticationTypes } from 'root/src/shared/constants/auth'
import { creatorData } from 'root/src/shared/descriptions/endpoints/recordTypes'

import responseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getBannedAccountsResponseSchema'

export default {
	[ADMIN_GET_BANNED_ACCOUNTS]: {
		endpointType: listEndpointType,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		recordType: creatorData,
		responseSchema,
	},
}
