import loginForm from 'root/src/shared/descriptions/modules/loginForm'
import signUpForm from 'root/src/shared/descriptions/modules/signUpForm'
import verifyAccountPage from 'root/src/shared/descriptions/modules/verifyAccountPage'
import forgotPassword from 'root/src/shared/descriptions/modules/forgotPassword'
import resetPassword from 'root/src/shared/descriptions/modules/resetPassword'
import createProjectForm from 'root/src/shared/descriptions/modules/createProjectForm'
import createBrandProjectForm from 'root/src/shared/descriptions/modules/createBrandProjectForm'
import viewProject from 'root/src/shared/descriptions/modules/viewProject'
import activeProjectsList from 'root/src/shared/descriptions/modules/activeProjectsList'
import myProjectsList from 'root/src/shared/descriptions/modules/myProjectsList'
import creatorsPage from 'root/src/shared/descriptions/modules/creatorsPage'
import pledgeProjectForm from 'root/src/shared/descriptions/modules/pledgeProjectForm'
import pledgeSuccessPage from 'root/src/shared/descriptions/modules/pledgeSuccessPage'
import FAQ from 'root/src/shared/descriptions/modules/FAQ'
import termsOfService from 'root/src/shared/descriptions/modules/termsOfService'
import privacyPolicy from 'root/src/shared/descriptions/modules/privacyPolicy'
import cookiePolicy from 'root/src/shared/descriptions/modules/cookiePolicy'
import copyrightPolicy from 'root/src/shared/descriptions/modules/copyrightPolicy'
import rulesOfUse from 'root/src/shared/descriptions/modules/rulesOfUse'
import accountSettings from 'root/src/shared/descriptions/modules/accountSettings'
import stepperHeader from 'root/src/shared/descriptions/modules/stepperHeader'
import twitchExtensionsInfo from 'root/src/shared/descriptions/modules/twitchExtensionsInfo'
import myProjectsBannerHeader from 'root/src/shared/descriptions/modules/myProjectsBannerHeader'
import FAQBannerHeader from 'root/src/shared/descriptions/modules/FAQBannerHeader'
import privacyPolicyBannerHeader from 'root/src/shared/descriptions/modules/privacyPolicyBannerHeader'
import cookiePolicyBannerHeader from 'root/src/shared/descriptions/modules/cookiePolicyBannerHeader'
import copyrightPolicyBannerHeader from 'root/src/shared/descriptions/modules/copyrightPolicyBannerHeader'
import termsOfServiceBannerHeader from 'root/src/shared/descriptions/modules/termsOfServiceBannerHeader'
import rulesOfUseBannerHeader from 'root/src/shared/descriptions/modules/rulesOfUseBannerHeader'
import accountSettingsBannerHeader from 'root/src/shared/descriptions/modules/accountSettingsBannerHeader'
import dareCreateSuccess from 'root/src/shared/descriptions/modules/dareCreateSuccess'
import selfDareCreateSuccess from 'root/src/shared/descriptions/modules/selfDareCreateSuccess'
import dareRejectSuccess from 'root/src/shared/descriptions/modules/dareRejectSuccess'
import dareAcceptSuccess from 'root/src/shared/descriptions/modules/dareAcceptSuccess'
import oAuthFailure from 'root/src/shared/descriptions/modules/oAuthFailure'
import changePasswordForm from 'root/src/shared/descriptions/modules/changePasswordForm'
import changePasswordSuccess from 'root/src/shared/descriptions/modules/changePasswordSuccess'
import titleHeaderMarketplace from 'root/src/shared/descriptions/modules/titleHeaderMarketplace'
import claimProjectForm from 'root/src/shared/descriptions/modules/claimProjectForm'
import rejectProjectModal from 'root/src/shared/descriptions/modules/rejectProjectModal'
import deliverDareSuccessPage from 'root/src/shared/descriptions/modules/deliverDareSuccessPage'
import pendingDeliveriesList from 'root/src/shared/descriptions/modules/pendingDeliveriesList'
import titleHeaderPendingDeliveries from 'root/src/shared/descriptions/modules/titleHeaderPendingDeliveries'
import deliveryDareForm from 'root/src/shared/descriptions/modules/deliveryDareForm'
import dareDeliveryDetail from 'root/src/shared/descriptions/modules/dareDeliveryDetail'
import payoutMethodForm from 'root/src/shared/descriptions/modules/payoutMethod'
import payoutAdminList from 'root/src/shared/descriptions/modules/payoutAdminList'
import confirmForgetPasswordPage from 'root/src/shared/descriptions/modules/confirmForgetPasswordPage'
import verificationFailurePage from 'root/src/shared/descriptions/modules/verificationFailurePage'
import verificationSuccessPage from 'root/src/shared/descriptions/modules/verificationSuccessPage'
import verificationFinishedPage from 'root/src/shared/descriptions/modules/verificationFinishedPage'
import viewCreatorData from 'root/src/shared/descriptions/modules/viewCreatorData'
import creatorProjectsList from 'root/src/shared/descriptions/modules/creatorProjectsList'
import creatorProjectsListBannerHeader from 'root/src/shared/descriptions/modules/creatorProjectsListBannerHeader'
import followCreatorModal from 'root/src/shared/descriptions/modules/followCreatorModal'
import couponForm from 'root/src/shared/descriptions/modules/couponForm'
import couponList from 'root/src/shared/descriptions/modules/couponList'
import deleteCouponModal from 'root/src/shared/descriptions/modules/deleteCouponModal'
import footer from 'root/src/shared/descriptions/modules/footer'
import filterDaresForm from 'root/src/shared/descriptions/modules/filterDaresForm'
import featuredDaresAdminList from 'root/src/shared/descriptions/modules/featuredDaresAdminList'
import featuredDaresAdminForm from 'root/src/shared/descriptions/modules/featuredDaresAdminForm'
import featuredDaresList from 'root/src/shared/descriptions/modules/featuredDaresList'
import addProductForm from 'root/src/shared/descriptions/modules/addBrandProductForm'
import brandSettingsForm from 'root/src/shared/descriptions/modules/brandSettingsForm'
import connectedChannelsUserData from 'root/src/shared/descriptions/modules/connectedChannelsUserData'
import alertsAudioPlayer from 'root/src/shared/descriptions/modules/alertsAudioPlayer'
import editProfileForm from 'root/src/shared/descriptions/modules/editProfileForm'
import projectCommentsList from 'root/src/shared/descriptions/modules/projectCommentsList'
import projectCommentsForm from 'root/src/shared/descriptions/modules/projectCommentsForm'
import completedDaresList from 'root/src/shared/descriptions/modules/completedDaresList'
import createDareSkipPledgeSuccess from 'root/src/shared/descriptions/modules/createDareSkipPledgeSuccess'
import completeWidgetSuccessPage from 'root/src/shared/descriptions/modules/completeWidgetSuccessPage'
import unAuthPledgeSuccessPage from 'root/src/shared/descriptions/modules/unAuthPledgeSuccessPage'
import unsubscribe from 'root/src/shared/descriptions/modules/unsubscribe'
import pendingBrandUsersList from 'root/src/shared/descriptions/modules/pendingBrandUsersList'
import creatorsAutocompleteForm from 'root/src/shared/descriptions/modules/creatorsAutocompleteForm'
import creatorsSection from 'root/src/shared/descriptions/modules/creatorsSection'
import creatorsTestimonials from 'root/src/shared/descriptions/modules/creatorsTestimonials'
import brandsTestimonials from 'root/src/shared/descriptions/modules/brandsTestimonials'
import creatorsFeatures from 'root/src/shared/descriptions/modules/creatorsFeatures'
import brandsFeatures from 'root/src/shared/descriptions/modules/brandsFeatures'
import statistics from 'root/src/shared/descriptions/modules/statistics'
import whatIsDareDrop from 'root/src/shared/descriptions/modules/whatIsDareDrop'
import brandsContactsSection from 'root/src/shared/descriptions/modules/brandsContactsSection'
import dropUploadKeysForm from 'root/src/shared/descriptions/modules/dropUploadKeysForm'
import dropKeysList from 'root/src/shared/descriptions/modules/dropKeysList'
import { dropsBannerHeader } from 'root/src/shared/descriptions/modules/dropsBannerHeader'
import dropAddMoreModal from 'root/src/shared/descriptions/modules/dropAddMoreModal'
import addDropEventForm from 'root/src/shared/descriptions/modules/addDropEventForm'
import dropEventPage from 'root/src/shared/descriptions/modules/dropEventPage'
import dropEventsList from 'root/src/shared/descriptions/modules/dropEventsList'
import brandPaymentModal from 'root/src/shared/descriptions/modules/brandPaymentModal'
import dropAdminPaymentGeneratorForm from 'root/src/shared/descriptions/modules/dropAdminPaymentGeneratorForm'
import dropAdminList from 'root/src/shared/descriptions/modules/dropAdminList'
import brandAlternatePayment from 'root/src/shared/descriptions/modules/brandAlternatePayment'
import dropAdminListSearchForm from 'root/src/shared/descriptions/modules/dropAdminListSearchForm'
import dropPreviewEventPage from 'root/src/shared/descriptions/modules/dropPreviewEventPage'
import notEnoughFollowersPage from 'root/src/shared/descriptions/modules/notEnoughFollowersPage'
import noCampaignModal from 'root/src/shared/descriptions/modules/noCampaignModal'
import cropImageModal from 'root/src/shared/descriptions/modules/cropImageModal'
import keysAndCodesForCreatorList from 'root/src/shared/descriptions/modules/keysAndCodesForCreatorList'
import keysAndCodesForViewersList from 'root/src/shared/descriptions/modules/keysAndCodesForViewersList'
import myInitialProductsList from 'root/src/shared/descriptions/modules/myInitialProductsList'
import viewersShowcase from 'root/src/shared/descriptions/modules/viewersShowcase'
import creatorsAutocompleteMisc from 'root/src/shared/descriptions/modules/creatorsAutocompleteMisc'
import shortUrlAnalyticsSearchForm from 'root/src/shared/descriptions/modules/shortUrlReportDownloadForm'
import brandProjectsList from 'root/src/shared/descriptions/modules/brandProjectsList'
import brandProjectsListBannerHeader from 'root/src/shared/descriptions/modules/brandProjectsListBannerHeader'
import setEmailForm from 'root/src/shared/descriptions/modules/setEmailForm'
import verifyNewEmail from 'root/src/shared/descriptions/modules/verifyNewEmail'
import newEmailVerificationSuccessPage from 'root/src/shared/descriptions/modules/newEmailVerificationSuccessPage'
import newEmailVerificationFailurePage from 'root/src/shared/descriptions/modules/newEmailVerificationFailurePage'
import setEmailFailure from 'root/src/shared/descriptions/modules/setEmailFailure'
import createIntroProjectSuccess from 'root/src/shared/descriptions/modules/createIntroProjectSuccess'
import helperWidgetModule from 'root/src/shared/descriptions/modules/helperWidgetModule'
import creatorsPageSections from 'root/src/shared/descriptions/modules/creatorsPageSections'
import newFooter from 'root/src/shared/descriptions/modules/newFooter'
import dropUploadCodesForm from 'root/src/shared/descriptions/modules/dropUploadCodesForm'
import brandCodeList from 'root/src/shared/descriptions/modules/brandCodeList'
import brandCustomProductList from 'root/src/shared/descriptions/modules/brandCustomProductList'
import dropsSlider from 'root/src/shared/descriptions/modules/dropsSlider'
import brandsSlider from 'root/src/shared/descriptions/modules/brandsSlider'
import homepageRolesSection from 'root/src/shared/descriptions/modules/homepageRolesSection'
import homepagePublishersSection from 'root/src/shared/descriptions/modules/homepagePublishersSection'
import homepageCreatorsSection from 'root/src/shared/descriptions/modules/homepageCreatorsSection'
import brandsDareCreatorsSection from 'root/src/shared/descriptions/modules/brandsDareCreatorsSection'
import brandsPartnershipsSection from 'root/src/shared/descriptions/modules/brandsPartnershipsSection'
import brandsCreatorsSection from 'root/src/shared/descriptions/modules/brandsCreatorsSection'
import socialIcons from 'root/src/shared/descriptions/modules/socialIcons'
import creatorsPageHeadSection from 'root/src/shared/descriptions/modules/creatorsPageHeadSection'
import homepageTestimonials from 'root/src/shared/descriptions/modules/homepageTestimonials'
import brandsReports from 'root/src/shared/descriptions/modules/brandsReports'
import brandPendingDeliveriesList from 'root/src/shared/descriptions/modules/brandPendingDeliveriesList'
import brandDareDeliveryDetail from 'root/src/shared/descriptions/modules/brandDareDeliveryDetail'
import noActiveCampaignPage from 'root/src/shared/descriptions/modules/noActiveCampaignPage'
import adminBanIpForm from 'root/src/shared/descriptions/modules/adminBanIpForm'
import dropIsOverModal from 'root/src/shared/descriptions/modules/dropIsOverModal'
import noPublisherSettingsModal from 'root/src/shared/descriptions/modules/noPublisherSettingsModal'
import successModal from 'root/src/shared/descriptions/modules/successModal'
import failureModal from 'root/src/shared/descriptions/modules/failureModal'
import getStarted from 'root/src/shared/descriptions/modules/getStarted'
import signUpModal from 'root/src/shared/descriptions/modules/signUpModal'
import signInModal from 'root/src/shared/descriptions/modules/signInModal'
import invoiceSentCheckEmail from 'root/src/shared/descriptions/modules/invoiceSentCheckEmail'
import requestInvoiceForm from 'root/src/shared/descriptions/modules/requestInvoiceForm'
import ambassadorProgramHeadSection from 'root/src/shared/descriptions/modules/ambassadorProgramHeadSection'
import ambassadorProgramBadgesSection from 'root/src/shared/descriptions/modules/ambassadorProgramBadgesSection'
import ambassadorProgramHowToSection from 'root/src/shared/descriptions/modules/ambassadorProgramHowToSection'
import paypalDropEventOrder from 'root/src/shared/descriptions/modules/paypalDropEventOrder'
import welcomeToDareDrop from 'root/src/shared/descriptions/modules/welcomeToDareDrop'
import agencyPublishersList from 'root/src/shared/descriptions/modules/agencyPublishersList'
import addAgencyPublisherForm from 'root/src/shared/descriptions/modules/addAgencyPublisherForm'
import agencyPublishersSelectMisc from 'root/src/shared/descriptions/modules/agencyPublishersSelectMisc'
import addAgencyAccountManagerForm from 'root/src/shared/descriptions/modules/addAgencyAccountManagerForm'
import signUpTokenForm from 'root/src/shared/descriptions/modules/signUpTokenForm'
import agencyAccountManagersList from 'root/src/shared/descriptions/modules/agencyAccountManagersList'
import deleteAccountManagerModal from 'root/src/shared/descriptions/modules/deleteAccountManagerModal'
import campaignListBannerHeader from 'root/src/shared/descriptions/modules/campaignListBannerHeader'
import campaignReportsList from 'root/src/shared/descriptions/modules/campaignReportsList'
import submitEmailForm from 'root/src/shared/descriptions/modules/submitEmailForm'
import selectPreferredPlatform from 'root/src/shared/descriptions/modules/selectPreferredPlatform'
import gamingPlatformsSelectionModal from 'root/src/shared/descriptions/modules/gamingPlatformsSelectionModal'
import reviewDeliveryOptionsModal from 'root/src/shared/descriptions/modules/reviewDeliveryOptionsModal'
import publisherNotificationSettingsForm from 'root/src/shared/descriptions/modules/publisherNotificationSettingsForm'
import cashOut from 'root/src/shared/descriptions/modules/cashOut'
import creatorActivityDetailsList from 'root/src/shared/descriptions/modules/creatorActivityDetailsList'
import pointsMarketplace from 'root/src/shared/descriptions/modules/pointsMarketplace'
import pointsMarketplacePurchaseModal from 'root/src/shared/descriptions/modules/pointsMarketplacePurchaseModal'
import pointsCashOutModal from 'root/src/shared/descriptions/modules/pointsCashOutModal'
import transferAccount from 'root/src/shared/descriptions/modules/transferAccount'
import tiktokSetEmailModal from 'root/src/shared/descriptions/modules/tiktokSetEmailModal'
import initialProductDisplayModal from 'root/src/shared/descriptions/modules/initialProductDisplayModal'
import notificationsModal from 'root/src/shared/descriptions/modules/notificationsModal'
import whitelistedCreatorsList from 'root/src/shared/descriptions/modules/whitelistedCreatorsList'
import whitelistFormModal from 'root/src/shared/descriptions/modules/whitelistFormModal'
import graphsDashboard from 'root/src/shared/descriptions/modules/graphsDashboard'
import campaignCreators from 'root/src/shared/descriptions/modules/campaignCreators'
import videoPlayerModal from 'root/src/shared/descriptions/modules/videoPlayerModal'
import campaignClipsList from 'root/src/shared/descriptions/modules/campaignClipsList'
import campaignDares from 'root/src/shared/descriptions/modules/campaignDares'
import creatorCampaignDares from 'root/src/shared/descriptions/modules/creatorCampaignDares'
import adminBannedAccountsList from 'root/src/shared/descriptions/modules/adminBannedAccountsList'
import languageSwitcherMisc from 'root/src/shared/descriptions/modules/languageSwitcherMisc'
import smiteGiveaway from 'root/src/shared/descriptions/modules/smiteGiveaway'
import smiteGiveawayBannerHeader from 'root/src/shared/descriptions/modules/smiteGiveawayBannerHeader'
import dareSubmissionModal from 'root/src/shared/descriptions/modules/dareSubmissionModal'
import publisherSettingsModal from 'root/src/shared/descriptions/modules/publisherSettingsModal'
import renfieldRules from 'root/src/shared/descriptions/modules/renfieldRules'
import editBrandKeysModal from 'root/src/shared/descriptions/modules/editBrandKeysModal'
import editBrandCodesModal from 'root/src/shared/descriptions/modules/editBrandCodesModal'
import previewBrandRewardsModal from 'root/src/shared/descriptions/modules/previewBrandRewardsModal'
import { getVerifiedModal } from 'root/src/shared/descriptions/modules/getVerifiedModal'
import { giveawayModules } from 'root/src/client/web/giveaway/descriptions/modules/giveawayModules'
import { sponsorshipModal } from 'root/src/shared/descriptions/modules/sponsorshipDetailsModal'
import { authModule } from 'root/src/client/web/auth/descriptions/modules/auth.module'
import { howToEarnPointsModal } from 'root/src/shared/descriptions/modules/howToEarnPointsModal'
import { publisherBanListFormModal } from 'root/src/shared/descriptions/modules/publisherBanListFormModal'
import publisherBanlistCreatorList from 'root/src/shared/descriptions/modules/publisherBanlistCreatorList'
import { demoGraphDescriptions } from 'root/src/shared/descriptions/modules/demoGraphsDashboard'
import { demoCampaignCreators } from 'root/src/shared/descriptions/modules/demoCampaignCreators'
import { demoCampaignClips } from 'root/src/shared/descriptions/modules/demoCampaignClips'
import { noMoreRewardModal } from 'root/src/shared/descriptions/modules/noMoreRewardModal'
import { rewardsBulkUploadForm } from 'root/src/shared/descriptions/modules/rewardsBulkUploadForm'
import { rewardsBulkUploadModal } from 'root/src/shared/descriptions/modules/rewardsBulkUploadModal'

export default {
	...loginForm,
	...signUpForm,
	...forgotPassword,
	...resetPassword,
	...verifyAccountPage,
	...createProjectForm,
	...createBrandProjectForm,
	...viewProject,
	...activeProjectsList,
	...creatorsPage,
	...pledgeProjectForm,
	...pledgeSuccessPage,
	...FAQ,
	...termsOfService,
	...stepperHeader,
	...twitchExtensionsInfo,
	...myProjectsBannerHeader,
	...privacyPolicy,
	...cookiePolicy,
	...copyrightPolicy,
	...rulesOfUse,
	...FAQBannerHeader,
	...cookiePolicyBannerHeader,
	...copyrightPolicyBannerHeader,
	...privacyPolicyBannerHeader,
	...termsOfServiceBannerHeader,
	...rulesOfUseBannerHeader,
	...dareCreateSuccess,
	...selfDareCreateSuccess,
	...dareAcceptSuccess,
	...dareRejectSuccess,
	...accountSettings,
	...accountSettingsBannerHeader,
	...oAuthFailure,
	...changePasswordForm,
	...changePasswordSuccess,
	...titleHeaderMarketplace,
	...claimProjectForm,
	...rejectProjectModal,
	...deliverDareSuccessPage,
	...pendingDeliveriesList,
	...deliveryDareForm,
	...titleHeaderPendingDeliveries,
	...dareDeliveryDetail,
	...payoutMethodForm,
	...payoutAdminList,
	...confirmForgetPasswordPage,
	...verificationFailurePage,
	...verificationSuccessPage,
	...verificationFinishedPage,
	...viewCreatorData,
	...creatorProjectsListBannerHeader,
	...creatorProjectsList,
	...followCreatorModal,
	...couponForm,
	...couponList,
	...deleteCouponModal,
	...footer,
	...filterDaresForm,
	...featuredDaresAdminList,
	...featuredDaresAdminForm,
	...featuredDaresList,
	...addProductForm,
	...brandSettingsForm,
	...brandProjectsList,
	...brandProjectsListBannerHeader,
	...connectedChannelsUserData,
	...alertsAudioPlayer,
	...editProfileForm,
	...projectCommentsList,
	...projectCommentsForm,
	...completedDaresList,
	...createDareSkipPledgeSuccess,
	...completeWidgetSuccessPage,
	...unAuthPledgeSuccessPage,
	...unsubscribe,
	...myProjectsList,
	...pendingBrandUsersList,
	...creatorsAutocompleteForm,
	...creatorsSection,
	...creatorsTestimonials,
	...brandsTestimonials,
	...creatorsFeatures,
	...brandsFeatures,
	...statistics,
	...whatIsDareDrop,
	...brandsContactsSection,
	...dropUploadKeysForm,
	...dropKeysList,
	...dropsBannerHeader,
	...dropAddMoreModal,
	...addDropEventForm,
	...dropEventPage,
	...dropEventsList,
	...brandPaymentModal,
	...dropAdminPaymentGeneratorForm,
	...dropAdminList,
	...brandAlternatePayment,
	...dropAdminListSearchForm,
	...dropPreviewEventPage,
	...notEnoughFollowersPage,
	...shortUrlAnalyticsSearchForm,
	...noCampaignModal,
	...keysAndCodesForCreatorList,
	...keysAndCodesForViewersList,
	...myInitialProductsList,
	...viewersShowcase,
	...creatorsAutocompleteMisc,
	...setEmailForm,
	...verifyNewEmail,
	...newEmailVerificationSuccessPage,
	...newEmailVerificationFailurePage,
	...setEmailFailure,
	...createIntroProjectSuccess,
	...helperWidgetModule,
	...newFooter,
	...dropUploadCodesForm,
	...brandCodeList,
	...brandCustomProductList,
	...creatorsPageSections,
	...cropImageModal,
	...dropsSlider,
	...brandsSlider,
	...socialIcons,
	...homepageRolesSection,
	...homepagePublishersSection,
	...homepageCreatorsSection,
	...brandsDareCreatorsSection,
	...brandsPartnershipsSection,
	...brandsCreatorsSection,
	...creatorsPageHeadSection,
	...homepageTestimonials,
	...brandsReports,
	...brandPendingDeliveriesList,
	...brandDareDeliveryDetail,
	...noActiveCampaignPage,
	...adminBanIpForm,
	...dropIsOverModal,
	...noPublisherSettingsModal,
	...successModal,
	...failureModal,
	...getStarted,
	...signUpModal,
	...signInModal,
	...invoiceSentCheckEmail,
	...requestInvoiceForm,
	...ambassadorProgramHeadSection,
	...ambassadorProgramBadgesSection,
	...ambassadorProgramHowToSection,
	...paypalDropEventOrder,
	...welcomeToDareDrop,
	...agencyPublishersList,
	...addAgencyPublisherForm,
	...agencyPublishersSelectMisc,
	...addAgencyAccountManagerForm,
	...signUpTokenForm,
	...agencyAccountManagersList,
	...deleteAccountManagerModal,
	...campaignReportsList,
	...campaignListBannerHeader,
	...submitEmailForm,
	...selectPreferredPlatform,
	...gamingPlatformsSelectionModal,
	...reviewDeliveryOptionsModal,
	...publisherNotificationSettingsForm,
	...notificationsModal,
	...initialProductDisplayModal,
	...transferAccount,
	...tiktokSetEmailModal,
	...cashOut,
	...creatorActivityDetailsList,
	...howToEarnPointsModal,
	...pointsMarketplace,
	...pointsMarketplacePurchaseModal,
	...pointsCashOutModal,
	...whitelistedCreatorsList,
	...whitelistFormModal,
	...graphsDashboard,
	...campaignCreators,
	...videoPlayerModal,
	...campaignClipsList,
	...campaignDares,
	...creatorCampaignDares,
	...adminBannedAccountsList,
	...languageSwitcherMisc,
	...smiteGiveaway,
	...smiteGiveawayBannerHeader,
	...dareSubmissionModal,
	...publisherSettingsModal,
	...renfieldRules,
	...editBrandKeysModal,
	...editBrandCodesModal,
	...previewBrandRewardsModal,
	...getVerifiedModal,
	...giveawayModules,
	...sponsorshipModal,
	...authModule,
	...publisherBanlistCreatorList,
	...publisherBanListFormModal,
	...demoGraphDescriptions,
	...demoCampaignCreators,
	...demoCampaignClips,
	...noMoreRewardModal,
	...rewardsBulkUploadForm,
	...rewardsBulkUploadModal,
}
