export default {
	type: 'object',
	properties: {
		items: {
			type: 'array',
		},
	},
	required: ['items'],

}
