import paymentTypes from 'root/src/shared/constants/paymentTypes'

export default {
	type: 'object',
	properties: {
		creatorUrl: { type: 'string' },
		title: {
			type: 'string',
			maxLength: 60,
			errorMessage: {
				maxLength: 'Title must be at most 60 characters',
			},
		},
		description: { type: 'string' },
		games: {
			type: 'array',
			minItems: 1,
			maxItems: 1,
			uniqueItems: true,
			items: {
				type: 'object',
				properties: {
					id: { type: 'integer' },
				},
				required: ['id'],
			},
		},
		assignees: {
			type: 'array',
			minItems: 1,
			maxItems: 10,
			uniqueItems: true,
			items: {
				type: 'object',
				properties: {
					id: {
						// TODO fallback. can uncomment later
						// type: 'string',
					},
					platform: {
						type: 'string',
					},
				},
				required: ['id'/* TODO same 'platform' */],
			},
			errorMessage: {
				maxItems: 'You may only add 10 creators.',
			},
		},
		paymentInfo: {
			type: 'object',
			properties: {
				paymentType: {
					type: 'string',
					enum: [paymentTypes.paypalAuthorize, paymentTypes.coupon],
				},
				paymentId: { type: 'string' },
				paymentAmount: { type: 'number' },
				paymentData: { type: 'object' },
				withCoupon: { type: 'boolean' },
				couponAmount: { type: 'integer' },
			},
			required: ['paymentType'],
			additionalProperties: false,
		},
		externalProviderInfo: {
			type: 'object',
			properties: {
				sku: { type: 'string' },
				transactionId: { type: 'string' },
				userId: { type: 'string' },
				username: { type: 'string' },
			},
		},
		pledgeAmount: {
			type: 'integer',
			minimum: 0,
			maximum: 999999,
		},
		amountRequested: {
			type: 'integer',
			minimum: 0,
			maximum: 999999,
		},
		daysToExpire: {
			type: ['integer', 'null'],
			minimum: 1,
			maximum: 25,
		},
		promotionAmount: { type: 'integer' },
		promotionCode: { type: 'string' },
		favoritesAmount: { type: 'integer' },
		partialFormId: { type: 'string' },
		paymentType: { type: 'string' },
		isIntroDare: { type: 'boolean' },
	},
	required: ['title', 'games', 'assignees', 'description'],
	errorMessage: {
		properties: {
			assignees: 'Creator is required',
		},
	},
	additionalProperties: false,
}
