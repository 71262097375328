import {
	VERIFICATION_FINISHED_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import {
	LOGIN_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[VERIFICATION_FINISHED_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'verificationResult',
		pageContent: {
			title: 'Account Verified!',
			paragraphs: [
				['Your account has been verified. You can login now.'],
			],
			link: LOGIN_ROUTE_ID,
			linkLabel: 'Sign in to continue',
		},

	},
}
