/* eslint-disable no-undef */
import TagManager from 'react-gtm-module'

export default () => {
	if (!TAG_MANAGER) return
	const tagManagerArgs = {
		gtmId: TAG_MANAGER,
	}
	TagManager.initialize(tagManagerArgs)
}
