import {
	marketplaceKey,
	creatorProjectsKey,
	myDaresKey,
	activeKey,
	completedKey,
	allKey,
	daresForMeKey,
	myFavoritesKey,
	myPledgesKey,
	assigneeKey,
	gameKey,
	featuredDaresKey,
	projectUrlKey,
	assigneesKey,
	gamesKey,
	completedProjectsLikeThisKey,
	mnemonicIdKey,
	iCreatedKey,
	yourInterestsKey,
	overlayKey,
	brandProjectsKey,
	projectDeliveryPendingKey,
	privateKey, myDeliveredDropDaresKey, myDeliveredViewerDaresKey,
} from 'root/src/shared/constants/keys'

export default {
	type: 'object',
	properties: {
		section: {
			type: 'string',
			enum: [
				featuredDaresKey, marketplaceKey, creatorProjectsKey,
				myDaresKey, completedProjectsLikeThisKey,
				overlayKey, brandProjectsKey,
			],
		},
		subSection: {
			type: 'string',
			enum: [
				activeKey, completedKey, projectDeliveryPendingKey, allKey, daresForMeKey,
				yourInterestsKey, myFavoritesKey, myPledgesKey, iCreatedKey, privateKey,
				myDeliveredDropDaresKey, myDeliveredViewerDaresKey,
			],
		},
		filter: {
			type: 'array',
			items: {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						param: {
							type: 'string',
							enum: [
								`${assigneesKey}:${projectUrlKey}`, `${gamesKey}:${projectUrlKey}`,
								assigneeKey, gameKey, mnemonicIdKey,
							],
						},
						value: { type: 'string' },
					},
				},
			},
		},
		currentPage: { type: 'number' },
		pageItemLength: { type: 'integer', maximum: 8 },
		sortType: { type: 'string' },
	},
	required: ['section'],
	additionalProperties: true,
}
