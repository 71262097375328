export default {
	type: 'object',
	properties: {
		error: { type: 'string' },
		error_description: { type: 'string' },
		code: { type: 'string' },
		authuser: { type: 'string' },
		prompt: { type: 'string' },
		scope: { type: 'string' },
		state: { type: 'string' },
	},
	required: ['code'],
	additionalProperties: false,
}
