import {
	BRAND_SETTINGS_ROUTE_ID,
	BRAND_ALTERNATE_PAYMENT_ROUTE_ID,
	BRAND_PRODUCTS_AND_KEYS_ROUTE_ID,
	BRAND_DARES_ROUTE_ID,
	CAMPAIGNS_PAGE_ROUTE_ID,
	LOGIN_ROUTE_ID,
	AGENCY_PUBLISHERS_LIST_ROUTE_ID,
	AGENCY_ACCOUNT_MANAGERS_LIST_ROUTE_ID,
	PUBLISHER_NOTIFICATION_SETTINGS_ROUTE_ID,
	WHITELISTED_CREATORS_ROUTE_ID,
	CAMPAIGNS_DASHBOARD_ROUTE_ID,
	CAMPAIGN_CREATORS_ROUTE_ID,
	CAMPAIGN_CLIPS_ROUTE_ID,
	CAMPAIGN_DARES_ROUTE_ID,
	CREATOR_CAMPAIGN_DARES_ROUTE_ID,
	CAMPAIGN_DASHBOARD_ROUTE_ID,
	CAMPAIGNS_CREATORS_ROUTE_ID,
	CAMPAIGNS_CLIPS_ROUTE_ID, PUBLISHER_BAN_LIST_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import {
	ADD_BRAND_PRODUCT_FORM_MODULE_ID,
	BRAND_SETTINGS_FORM_MODULE_ID,
	DROP_KEYS_LIST_MODULE_ID,
	DROP_UPLOAD_KEYS_FORM_MODULE_ID,
	BRAND_ALTERNATE_PAYMENT_FORM_MODULE_ID,
	BRAND_PROJECTS_LIST_BANNER_HEADER_MODULE_ID,
	BRAND_PROJECTS_LIST_MODULE_ID,
	HELPER_WIDGET_MODULE_ID,
	DROP_UPLOAD_CODES_FORM_MODULE_ID,
	BRAND_CODE_LIST_MODULE_ID,
	BRAND_CUSTOM_PRODUCTS_MODULE_ID,
	AGENCY_PUBLISHERS_LIST_MODULE_ID,
	ADD_AGENCY_PUBLISHER_FORM_MODULE_ID,
	ADD_AGENCY_ACCOUNT_MANAGER_FORM_MODULE_ID,
	AGENCY_ACCOUNT_MANAGERS_LIST_MODULE_ID,
	CAMPAIGN_LIST_BANNER_HEADER_MODULE_ID,
	CAMPAIGN_REPORT_LIST_MODULE_ID,
	PUBLISHER_NOTIFICATION_SETTINGS_MODULE_ID,
	WHITELIST_CREATORS_LIST_MODULE_ID,
	GRAPHS_DASHBOARD_MODULE_ID,
	CAMPAIGN_CLIPS_LIST_MODULE,
	CAMPAIGN_DARES_LIST_MODULE,
	CREATOR_CAMPAIGN_DARES_LIST_MODULE,
	PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID,
	REWARDS_BULK_UPLOAD_FORM_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { DROP_GET_EVENT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'

export const publisherDaresModules = [
	HELPER_WIDGET_MODULE_ID,
	BRAND_PROJECTS_LIST_BANNER_HEADER_MODULE_ID,
	BRAND_PROJECTS_LIST_MODULE_ID,
]

const getDropEventPayload = {
	endpointId: DROP_GET_EVENT,
	payload: (state) => {
		const {
			routeParams: { recordId },
		} = currentRouteObj(state)
		return { recordId }
	},
}

export default {
	[CAMPAIGNS_PAGE_ROUTE_ID]: {
		url: '/campaigns',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		chatWidget: true,
		discordWidget: true,
		modules: [
			HELPER_WIDGET_MODULE_ID,
			CAMPAIGN_LIST_BANNER_HEADER_MODULE_ID,
			CAMPAIGN_REPORT_LIST_MODULE_ID,
		],
	},
	[BRAND_PRODUCTS_AND_KEYS_ROUTE_ID]: {
		url: '/keys-and-products',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		chatWidget: true,
		discordWidget: true,
		defaultRouteId: LOGIN_ROUTE_ID,
		variantModules: [
			{ label: 'Bulk Upload', modules: [HELPER_WIDGET_MODULE_ID, REWARDS_BULK_UPLOAD_FORM_MODULE_ID] },
			{ label: 'Keys', modules: [HELPER_WIDGET_MODULE_ID, DROP_UPLOAD_KEYS_FORM_MODULE_ID, DROP_KEYS_LIST_MODULE_ID] },
			{ label: 'Coupons/Codes', modules: [HELPER_WIDGET_MODULE_ID, DROP_UPLOAD_CODES_FORM_MODULE_ID, BRAND_CODE_LIST_MODULE_ID] },
			{ label: 'Asset', modules: [HELPER_WIDGET_MODULE_ID, ADD_BRAND_PRODUCT_FORM_MODULE_ID, BRAND_CUSTOM_PRODUCTS_MODULE_ID] },
		],
	},
	[BRAND_SETTINGS_ROUTE_ID]: {
		url: '/publisher-settings',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		chatWidget: true,
		discordWidget: true,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [
			HELPER_WIDGET_MODULE_ID,
			BRAND_SETTINGS_FORM_MODULE_ID,
		],
	},
	[BRAND_ALTERNATE_PAYMENT_ROUTE_ID]: {
		url: '/brand-payment',
		noSitemap: true,
		modules: [
			HELPER_WIDGET_MODULE_ID,
			BRAND_ALTERNATE_PAYMENT_FORM_MODULE_ID,
		],
	},
	[BRAND_DARES_ROUTE_ID]: {
		url: '/publisher-dares',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		modules: publisherDaresModules,
		mainModule: BRAND_PROJECTS_LIST_MODULE_ID,
	},
	[AGENCY_PUBLISHERS_LIST_ROUTE_ID]: {
		url: '/publishers-list',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.agencyAccountManager,
		variantModules: [
			{ label: 'Publishers', modules: [AGENCY_PUBLISHERS_LIST_MODULE_ID] },
			{
				label: 'Add publisher',
				modules: [
					ADD_AGENCY_PUBLISHER_FORM_MODULE_ID,
				],
			},
		],
	},
	[AGENCY_ACCOUNT_MANAGERS_LIST_ROUTE_ID]: {
		url: '/account-managers',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.agency,
		variantModules: [
			{
				label: 'Account managers',
				modules: [
					AGENCY_ACCOUNT_MANAGERS_LIST_MODULE_ID,
				],
			},
			{ label: 'Add account manager', modules: [ADD_AGENCY_ACCOUNT_MANAGER_FORM_MODULE_ID] },
		],
	},
	[PUBLISHER_NOTIFICATION_SETTINGS_ROUTE_ID]: {
		url: '/publisher-notification-settings',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		chatWidget: true,
		discordWidget: true,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [
			PUBLISHER_NOTIFICATION_SETTINGS_MODULE_ID,
		],
	},
	[WHITELISTED_CREATORS_ROUTE_ID]: {
		url: '/whitelisted-creators',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		chatWidget: true,
		discordWidget: true,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [
			WHITELIST_CREATORS_LIST_MODULE_ID,
		],
	},
	[CAMPAIGN_DASHBOARD_ROUTE_ID]: {
		url: '/campaign-dashboard/:recordId',
		makeRequestsOnClient: true,
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		defaultRouteId: LOGIN_ROUTE_ID,
		serverSideRequests: [
			getDropEventPayload,
		],
		modules: [
			GRAPHS_DASHBOARD_MODULE_ID,
		],
	},
	[CAMPAIGNS_DASHBOARD_ROUTE_ID]: {
		url: '/campaign-dashboard',
		makeRequestsOnClient: true,
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [
			GRAPHS_DASHBOARD_MODULE_ID,
		],
	},
	[CAMPAIGN_CREATORS_ROUTE_ID]: {
		url: '/campaign-creators/:recordId',
		makeRequestsOnClient: true,
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		defaultRouteId: LOGIN_ROUTE_ID,
		serverSideRequests: [
			getDropEventPayload,
		],
		modules: [],
	},
	[CAMPAIGNS_CREATORS_ROUTE_ID]: {
		url: '/campaign-creators',
		makeRequestsOnClient: true,
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [],
	},
	[CAMPAIGN_CLIPS_ROUTE_ID]: {
		url: '/campaign-clips/:recordId',
		makeRequestsOnClient: true,
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		defaultRouteId: LOGIN_ROUTE_ID,
		serverSideRequests: [
			getDropEventPayload,
		],
		modules: [CAMPAIGN_CLIPS_LIST_MODULE],
	},
	[CAMPAIGNS_CLIPS_ROUTE_ID]: {
		url: '/campaign-clips',
		makeRequestsOnClient: true,
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [CAMPAIGN_CLIPS_LIST_MODULE],
	},
	[CAMPAIGN_DARES_ROUTE_ID]: {
		url: '/campaign-dares/:recordId',
		makeRequestsOnClient: true,
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [CAMPAIGN_DARES_LIST_MODULE],
	},
	[CREATOR_CAMPAIGN_DARES_ROUTE_ID]: {
		url: '/creator-dares/:recordId',
		makeRequestsOnClient: true,
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		defaultRouteId: LOGIN_ROUTE_ID,
		modules: [CREATOR_CAMPAIGN_DARES_LIST_MODULE],
	},
	[PUBLISHER_BAN_LIST_ROUTE_ID]: {
		url: '/publisher-banned-creators',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brand,
		chatWidget: true,
		discordWidget: true,
		modules: [
			PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID,
		],
	},
}
