import { UNSUBSCRIBE_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import image from 'root/src/shared/descriptions/modules/oAuthFailure'

export default {
	[UNSUBSCRIBE_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Unsubscribed',
			image,
			paragraphs: [
				['You\'ve been unsubscribed.'],
			],
			withoutSocialIcons: true,
		},
	},
}
