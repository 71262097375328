import path from 'ramda/src/path'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import sortFilterModuleSelector from 'root/src/client/logic/api/selectors/sortFilterModuleSelector'

export default (state, { moduleId }) => {
	const sortFilterModule = sortFilterModuleSelector(moduleId)
	if (!sortFilterModule) {
		return path([moduleId, 'fixedFilter'], moduleDescriptions)
	}
	return path([sortFilterModule, 'fixedFilter'], moduleDescriptions)
}
