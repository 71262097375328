import always from 'ramda/src/always'
import tryCatch from 'ramda/src/tryCatch'
import pick from 'ramda/src/pick'
import filter from 'ramda/src/filter'
import pipe from 'ramda/src/pipe'
import uniqBy from 'ramda/src/uniqBy'
import prop from 'ramda/src/prop'
import values from 'ramda/src/values'
import map from 'ramda/src/map'
import path from 'ramda/src/path'
import {
	WHITELIST_CREATORS_LIST_MODULE_ID,
	WHITELIST_FORM_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import {
	REMOVE_WHITELISTED_CREATOR,
	GET_WHITELISTED_CREATORS,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { whitelist } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { camelCaseSpace } from 'root/src/shared/util/stringCase'
import modifyWhitelistedCreatorsOnSuccess from 'root/src/client/logic/tableItem/actions/modifyWhitelistedCreatorsOnSuccess'
import {
	sectionKey,
	generalWhitelistKey,
	privateCampaignWhitelistKey,
} from 'root/src/shared/constants/keys'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import { campaignWhitelistTypes } from 'root/src/shared/constants/whitelist'

const { viewWhitelist } = apiStoreLenses

export default {
	[WHITELIST_CREATORS_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		listModuleId: WHITELIST_CREATORS_LIST_MODULE_ID,
		isClientPaginated: true,
		defaultListDataLength: 25,
		listDataLengthOptions: [10, 25, 50],
		constantListStoreKey: true,
		tableColumns: [
			['Name', 'displayName'],
			['Platform', 'platform', tryCatch(camelCaseSpace, always(undefined))],
			[
				'Whitelisted For',
				'campaignName',
				(campaignName, record) => {
					const isPrivateCampaignType = record?.whitelistType === privateCampaignWhitelistKey

					if (isPrivateCampaignType) {
						return `${campaignName} (Private)`
					}

					return `${campaignName} (${isPrivateCampaignType})`
				},
				'Your community',
			],
		],
		tableItemActions: [
			{
				name: 'Remove',
				fn:
					(item, { apiRequest }) => async (dispatch, getState) => {
						const embeddedFilterPayload = path(
							[
								'embedded',
								WHITELIST_CREATORS_LIST_MODULE_ID,
								'fieldData',
								'section',
								'payload',
							],
							getState(),
						)

						const res = await dispatch(
							apiRequest(REMOVE_WHITELISTED_CREATOR, {
								...pick(
									['whitelistType', 'campaignId', 'platform', 'platformId'],
									item,
								),
								returnDataPaylaod: embeddedFilterPayload,
							}),
						)
						dispatch(modifyWhitelistedCreatorsOnSuccess(res.body))
					},
			},
		],
		listTitle: 'Whitelisted Creators',
		recordType: whitelist,
		endpointId: [GET_WHITELISTED_CREATORS],
		embeddedContent: {
			moduleType: 'embeddedForm',
			noCurrentPageData: true,
			fields: [
				{
					fieldId: sectionKey,
					fieldCaption: 'Select whitelist type',
					fixedLargeWidth: true,
					inputType: 'dropdownEmbedded',
					options: (state) => {
						const campaignWhitelists = pipe(
							filter(user => campaignWhitelistTypes.includes(user.whitelistType)),
							values,
							uniqBy(prop('campaignId')),
						)(viewWhitelist(state) || [])

						return [
							{
								label: 'All',
							},
							{
								label: 'Community',
								payload: {
									whitelistType: generalWhitelistKey,
								},
							},
							...map(whitelistCampaign => (
								{
									label: `${whitelistCampaign.campaignName}${whitelistCampaign.whitelistType === privateCampaignWhitelistKey ? ' (Private)' : ''}`,
									payload: {
										whitelistType: whitelistCampaign.whitelistType,
										campaignId: whitelistCampaign.campaignId,
									},
								}), campaignWhitelists),
						]
					},
					endpointId: GET_WHITELISTED_CREATORS,
				},
			],
		},
		listActionButton: {
			label: 'Add whitelist',
			onClickFn: () => (dispatch) => {
				dispatch(displayModal(WHITELIST_FORM_MODAL_MODULE_ID, { dispatch }))
			},
		},
	},
}
