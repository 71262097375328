import { region } from 'root/src/shared/constants/aws'
import outputs from 'root/cfOutput'

const { gatewaySentryTunnelApiId } = outputs

export const SENTRY_TUNNEL_STAGE = 'v1'
export const SERVER_SENTRY_DSN = 'https://8067360b859a45ceb829d1ff6e801432@sentry.io/1485844'
export const SERVER_MONITORING_DSN = 'https://c7f99798ec0c4b3a99d3c4cf57412a8e@o272575.ingest.sentry.io/5685236'
export const NEXT_SENTRY_DSN = 'https://d350bfe5887a47bb96ddcd3f52aa5b31@o272575.ingest.sentry.io/5966808'
export const SENTRY_SERVER_PROJECT = 'daredropserver'
export const SENTRY_CLIENT_PROJECT = 'daredropclient'
export const SENTRY_RELEASE_VERSION = '0.2.3'
export const SENTRY_ORG = 'dare-drop'
export const SENTRY_ENDPOINT = 'https://sentry.io/api'
export const SENTRY_CLIENT_PROJECT_ID = '1527297'
export const SENTRY_TUNNEL_ROUTE = 'sentry'
export const SENTRY_TUNNEL_URL = `https://${gatewaySentryTunnelApiId}.execute-api.${region}.amazonaws.com/${SENTRY_TUNNEL_STAGE}/${SENTRY_TUNNEL_ROUTE}`
