import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { recordEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { giveawayVouchers } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { VoucherStatus } from '@daredrop-com/rewards-api-contract';
export const getUserVouchersEndpoint = {
    [giveawayEndpointIds.GET_USER_VOUCHERS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.viewer,
        payloadSchema: {
            type: 'object',
            properties: {
                userId: { type: 'string' },
                mnemonicId: { type: 'string' },
                status: { type: 'string', enum: Object.values(VoucherStatus) },
            },
        },
        endpointType: recordEndpointType,
        recordType: giveawayVouchers,
    },
};
