import { SET_POINTS_APPLICATION_STATE } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { Platform } from 'root/src/shared/@types/platforms';
import { PointsApplicationState } from 'root/src/server/points/types/types';
export const setPointsApplicationStateEndpoint = {
    [SET_POINTS_APPLICATION_STATE]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
                platform: { type: 'string', enum: [Platform.Twitch] },
                platformId: { type: 'string' },
                state: { type: 'string', enum: [PointsApplicationState.APPROVED, PointsApplicationState.REJECTED] },
            },
            required: ['dropId', 'platform', 'platformId', 'state'],
            additionalProperties: false,
        },
    },
};
