import { TRANSFER_ACCOUNT_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import transferAccountSchema from 'root/src/shared/descriptions/formSchemas/transferAccountSchema'

export default {
	[TRANSFER_ACCOUNT_MODULE_ID]: {
		moduleType: 'form',
		formType: 'universalForm',
		schema: transferAccountSchema,
		title: 'Due to security reasons,\nplease change the password',
		subTitle: 'Enter your new password to continue',
		fields: [
			{
				fieldId: 'password',
				inputType: 'password',
				placeholder: 'New Password',
			},
			{
				fieldId: 'confirmPassword',
				inputType: 'password',
				placeholder: 'Confirm Password',
			},
		],
		submits: [
			{
				label: 'Continue',
				buttonType: 'primaryButton',
			},
		],
	},
}
