import { CONNECTED_CHANNELS_USER_DATA_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[CONNECTED_CHANNELS_USER_DATA_MODULE_ID]: {
		moduleType: 'userData',
		userDataType: 'connectedChannels',
		pageContent: {
			userDataTitle: 'Connected Channels',
			subHeader:
				'These are the channels you can accept Dares with and deliver content through. Connecting Google accounts without a YouTube channel will not work here.',
			links: [
				{
					label: 'Go back',
					routeId: ACCOUNT_SETTINGS_ROUTE_ID,
				},
			],
		},
	},
}
