var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { authApiBase } from 'root/src/shared/constants/auth';
import nextAjax from 'root/src/client/util/nextAjax';
import { getCookie } from 'root/src/client/v2/browser/helpers/cookies';
export function isAuthApiError(error) {
    return typeof error === 'object' && error !== null && 'error' in error && typeof error.error === 'string';
}
const authApiRequest = (path, { queryParams, body, token, method } = {}) => {
    const tokenMod = token || getCookie('accessToken');
    return nextAjax({
        url: `${authApiBase}${path}`,
        method,
        body,
        queryParams,
        headers: Object.assign({}, (tokenMod ? { Authorization: `Bearer ${tokenMod}` } : {})),
    });
};
export const authApi = {
    resetPassword: ({ password, code, }) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/reset-password', {
            method: 'POST',
            body: {
                password,
                code,
            },
        });
    }),
    forgotPassword: ({ email, redirectUrl, }) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/forgot-password', {
            method: 'POST',
            body: {
                email,
                redirectUrl,
            },
        });
    }),
    changePassword: ({ password, newPassword, }) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/change-password', {
            method: 'PATCH',
            body: {
                password,
                newPassword,
            },
        });
    }),
    changeEmail: ({ email, redirectUrl, token, }) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/change-email', {
            method: 'PATCH',
            body: {
                email,
                redirectUrl,
            },
            token,
        });
    }),
    refreshToken: (token) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/refresh-token', {
            method: 'POST',
            body: {
                refreshToken: token,
            },
        });
    }),
    signIn: ({ email, password, }) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/sign-in', {
            method: 'POST',
            body: {
                email,
                password,
            },
        });
    }),
    signUp: ({ email, password, group, redirectUrl, data, }) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/sign-up', {
            method: 'POST',
            body: {
                email,
                password,
                group,
                redirectUrl,
                data,
            },
        });
    }),
    unlinkAccount: ({ platform, platformId }) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/unlink-account', {
            method: 'POST',
            body: {
                platform,
                platformId,
            },
        });
    }),
    setAccountCredentials: ({ token, password }) => __awaiter(void 0, void 0, void 0, function* () {
        return authApiRequest('/set-account-credentials', {
            body: { token, password },
            method: 'POST',
        });
    }),
    createCredentials: ({ code, email, password, redirectUrl, }) => authApiRequest('/credentials', {
        method: 'POST',
        body: {
            code,
            email,
            password,
            redirectUrl,
        },
        queryParams: {
            code,
        },
    }),
};
