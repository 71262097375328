import {
	SAVE_PARTIAL_DARE_FORM,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

import savePartialDarePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/savePartialDarePayloadSchema'
import savePartialDareFormPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/savePartialDareFormResponseSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = savePartialDarePayloadSchema
export const responseSchema = savePartialDareFormPayloadSchema

export default {
	[SAVE_PARTIAL_DARE_FORM]: {
		authentication: authenticationTypes.authenticated,
		recordType: project,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
