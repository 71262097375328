export default {
	type: 'array',
	items: {
		type: 'object',
		properties: {
			displayName: { type: 'string' },
			userId: { type: 'string' },
			dropEventId: { type: 'string' },
			pointsAwarded: { type: 'number' },
			viewerHoursTotal: { type: 'number' },
			viewerHoursAwarded: { type: 'number' },
			clicks: { type: 'number' },
			value: { type: 'number' },
			paypalEmail: { type: 'string' },
			paidOffPlatform: { type: 'boolean' },
			mnemonicId: { type: 'string' },
			platform: { type: 'string' },
		},
		required: [
			'displayName',
			'userId',
			'dropEventId',
			'pointsAwarded',
			'viewerHoursTotal',
			'viewerHoursAwarded',
			'clicks',
			'value',
			'mnemonicId',
			'platform',
		],
		additionalProperties: false,
	},
}
