import { ADD_TOKEN, SET_EMAIL, JOIN_GIVEAWAY } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { LOGIN_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import appConfig from 'root/appConfig'
import { apiGatewayEndpoint } from 'root/src/shared/constants/aws'
import { WEBSOCKETS_STAGENAME } from 'root/src/aws/gateway/constants'
import outputs from 'root/cfOutput'
import routes from 'root/src/shared/descriptions/routes'
import { isProdEnv } from 'root/src/shared/util/envSelect'
import stackConfig from 'root/config/stackConfig'

const { gatewayWebsocketApiId, domainName } = outputs

export const EMAIL = 'support@DareDrop.com'
export const tokenRedirectUri = `${apiGatewayEndpoint}${ADD_TOKEN}`
export const setEmailRedirectUri = `${apiGatewayEndpoint}${SET_EMAIL}`
export const joinGiveawayRedirectUri = `${apiGatewayEndpoint}${JOIN_GIVEAWAY}`
export const logoutUri = `https://${domainName}${routes[LOGIN_ROUTE_ID].url}?logout=true`
export const websocketsEndpoint = `${gatewayWebsocketApiId}.execute-api.${appConfig.awsRegion}.amazonaws.com/${WEBSOCKETS_STAGENAME}`
export const websocketsUrl = `wss://${websocketsEndpoint}`
export const calendlyUrl = 'mailto:inboundsales@daredrop.com'
export const shortenerPrefix = `https://${isProdEnv ? 'dared.io' : `${stackConfig.shortenerPrefix || 's'}.${domainName}`}`
