import { CONFIRM_FORGET_PASSWORD_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { FORGOT_PASSWORD_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[CONFIRM_FORGET_PASSWORD_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'verificationResult',
		pageContent: {
			title: 'Forget your password?',
			paragraphs: [
				['We sent you an email with instructions to reset your password. You may need to check your spam folder.'],
			],
			link: FORGOT_PASSWORD_ROUTE_ID,
			linkLabel: "If you didn't receive it we'll send it again",
		},
	},
}
