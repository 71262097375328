import { REVIEW_DELIVERY_OPTIONS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[REVIEW_DELIVERY_OPTIONS_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'Choose Action',
		modalContent: {
			moreButtonText: 'More are expected',
			noMoreButtonText: 'No more expected',
			header: 'We are out of ',
		},
	},
}
