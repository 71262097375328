import { getOidcUrl } from 'root/src/client/v2/auth/helpers/getOidcUrl'
import { SIGN_UP_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import mixpanel from 'root/src/client/logic/analytics/mixpanel'
import { mixpanelEvents } from 'root/src/shared/constants/mixpanel'
import pipe from 'ramda/src/pipe'
import authenticationSelector from 'root/src/client/logic/auth/selectors/authenticationSelector'
import prop from 'ramda/src/prop'
import currentRouteObjSelector from 'root/src/client/logic/route/selectors/currentRouteObj'

export default (platform = 'twitch', isAuthenticated = true) => (dispatch, getState) => async (_, setLoading) => {
	const state = getState()
	const accessToken = pipe(
		authenticationSelector,
		prop('accessToken'),
	)(state)

	if (!isAuthenticated || !accessToken) return pushRouteClient({ routeId: SIGN_UP_ROUTE_ID })
	if (setLoading) { setLoading(true) }
	mixpanel.track(mixpanelEvents.channelConnection, { platform })
	return window.location.replace(getOidcUrl(platform, accessToken, currentRouteObjSelector(state)))
}
