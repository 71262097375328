import { AUTH_DETERMINED } from 'root/src/client/logic/app/actionIds'

import { appStoreLenses } from 'root/src/client/logic/app/lenses'
import pick from 'ramda/src/pick'
import isEmpty from 'ramda/src/isEmpty'
import evolve from 'ramda/src/evolve'
import when from 'ramda/src/when'
import tryCatch from 'ramda/src/tryCatch'
import always from 'ramda/src/always'

const { setAuthentication } = appStoreLenses

const parseGroups = evolve({
	// groups from refreshToken is type of array
	groups: when(
		groups => typeof groups === 'string' && groups.length,
		tryCatch(JSON.parse, always(undefined)),
	),
})

export default {
	[AUTH_DETERMINED]: (state, { authenticated = {} }) => {
		const authData = pick(
			['accessToken', 'email', 'refreshToken', 'userId', 'groups', 'parentUserId'],
			authenticated,
		)

		return setAuthentication(
			isEmpty(authData) ? null : parseGroups(authData),
			state,
		)
	},
}
