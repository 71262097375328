import is from 'ramda/src/is'
import head from 'ramda/src/head'
import includes from 'ramda/src/includes'
import find from 'ramda/src/find'
import splitEvery from 'ramda/src/splitEvery'
import keys from 'ramda/src/keys'
import compose from 'ramda/src/compose'
import identity from 'ramda/src/identity'
import { SET_SELECTED_PAGE } from 'root/src/client/logic/list/actionIds'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import moduleDescriptions from 'root/src/shared/descriptions/modules'

import { listStoreLenses } from 'root/src/client/logic/list/lenses'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'

const {
	viewListIds,
	viewCurrentPage,
	viewListDataLength,
	setPagesCount,
	setCurrentPage,
} = listStoreLenses
const { setItems, viewLists } = apiStoreLenses

const setSelectedPageFn = (state, { listEndpointId, moduleId }) => {
	const fullList = viewListIds(moduleId, state) || []
	const listDataLength = viewListDataLength(moduleId, state)

	const paginatedList = splitEvery(listDataLength, fullList)
	const newPagesCount = paginatedList.length

	const currentLists = viewLists(state)
	const endpointIdMod = is(Array, listEndpointId)
		? head(listEndpointId)
		: listEndpointId
	const constantListStoreKeyValue = moduleDescriptions?.[moduleId]?.constantListStoreKeyValue

	const { routeId } = currentRouteObj(state)
	const listStoreKey = find(
		key => includes(moduleId, key)
			&& (includes(endpointIdMod, key) || includes(constantListStoreKeyValue, key))
			&& includes(routeId, key),
		keys(currentLists),
	)

	const currentPage = viewCurrentPage(moduleId, state)
	const isPageValid = currentPage <= newPagesCount

	const newList = paginatedList[isPageValid ? currentPage - 1 : 0]

	return compose(
		isPageValid ? identity : setCurrentPage(moduleId, 1),
		setItems(listStoreKey, newList),
		setPagesCount(moduleId, newPagesCount),
	)(state)
}

export default {
	[SET_SELECTED_PAGE]: setSelectedPageFn,
}
