import reduce from 'ramda/src/reduce'
import assoc from 'ramda/src/assoc'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'

export default (selectors = [], actions = []) => (component, styles, stylesName = 'MuiStyledComponent') => {
	const styledComponent = styles ? withStyles(styles, { name: stylesName })(component) : component
	return connect(
		(state, props) => reduce((results, [functionName, fn]) => assoc(
			functionName, fn(state, props), results,
		), {}, selectors),
		reduce((results, [functionName, fn]) => assoc(
			functionName, fn, results,
		), {}, actions),
	)(styledComponent)
}
