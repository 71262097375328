import {
	NO_PUBLISHER_SETTINGS_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { BRAND_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[NO_PUBLISHER_SETTINGS_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'Check your settings',
		modalContent: {
			routeId: BRAND_SETTINGS_ROUTE_ID,
		},
	},
}
