import { PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID, PUBLISHER_BAN_LIST_FORM_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import {
	GET_PUBLISHER_BANNED_CREATORS,
	REMOVE_PUBLISHER_BANNED_CREATOR,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { sectionKey } from 'root/src/shared/constants/keys'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import { publisherBanListSelector } from 'root/src/client/logic/form/selectors/publisherBanListSelector'
import { publisherBan } from 'root/src/shared/descriptions/endpoints/recordTypes'

import { PublisherBanListType } from 'root/src/shared/@types/publisherBanList'

export default {
	[PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID]: {
		moduleType: 'list',
		listTitle: 'Banned Creators',
		recordType: publisherBan,
		listType: tableListType,
		listModuleId: PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID,
		constantListStoreKeyValue: publisherBan,
		isClientPaginated: true,
		defaultListDataLength: 25,
		listDataLengthOptions: [10, 25, 50],
		constantListStoreKey: true,
		tableColumns: [
			['Name', 'displayName'],
			['Platform', 'platform'],
			[
				'Banned from',
				'campaignName',
				campaignName => campaignName,
				'All campaigns',
			],
		],
		tableItemActions: [
			{
				name: 'Remove',
				fn:
					(item, { apiRequest }) => async (dispatch) => {
						await dispatch(
							apiRequest(REMOVE_PUBLISHER_BANNED_CREATOR, {
								banListType: item?.banListType,
								dropEventId: item?.dropEventId,
								platform: item?.platform,
								platformId: item?.platformId,
							}, undefined, PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID, {
								[PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID]: {
									constantListStoreKeyValue: publisherBan,
									constantListStoreKey: true,
								},
							}),
						)
					},
			},
		],
		endpointId: [GET_PUBLISHER_BANNED_CREATORS],
		embeddedContent: {
			moduleType: 'embeddedForm',
			noCurrentPageData: true,
			fields: [
				{
					fieldId: sectionKey,
					fieldCaption: 'Select ban list type',
					fixedLargeWidth: true,
					inputType: 'dropdownEmbedded',
					options: (state) => {
						const banLists = Object.values(publisherBanListSelector(state) ?? {})
							.filter(({ banListType }) => banListType !== PublisherBanListType.Publisher)
						const dropFilters = new Map(banLists.map(({ dropEventId, campaignName }) => (
							[dropEventId, {
								label: campaignName,
								payload: {
									banListType: PublisherBanListType.Drop,
									dropEventId,
								},
							}]
						)))
						return [
							{
								label: 'All',
							},
							{
								label: 'Community',
								payload: {
									banListType: PublisherBanListType.Publisher,
								},
							},
							{
								label: 'Drop',
								payload: {
									banListType: PublisherBanListType.Drop,
								},
							},
							...Array.from(dropFilters.values()),
						]
					},
				},
			],
		},
		listActionButton: {
			label: 'Ban users',
			onClickFn: () => (dispatch) => {
				dispatch(displayModal(
					PUBLISHER_BAN_LIST_FORM_MODAL_MODULE_ID,
					{ dispatch },
				))
			},
		},
	},
}
