export const obsCloudfrontDistributionArn = 'E1BXYHMNJ0U9O6'
export const mixpanelProxyApiId = 'h0oafy3365'
export const apiLambdaLowSpecArn = 'arn:aws:lambda:us-east-1:261130029928:function:dareMeddtestDev-dareMeddtestDevCloudwatchLowSpecFu-10wlgPTfw3Gt'
export const cloudWatchLogsReportErrorGroupName = 'dareMeddtestDev-dareMeddtestDevReportErrorGroup-sdAkIy24C97L'
export const nextOriginFunctionName = 'dareMeddtestDev-dareMeddtestDevNextOriginRequestHa-wxUg08MUZV1W'
export const performanceTestDynamoDBTableArn = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevPerformanceTestDynamoDbTable-Q8N65WYC1VIF'
export const lambdaSqsFifoQueueDlqUrl = 'https://sqs.us-east-1.amazonaws.com/261130029928/dareMeddtestDev-dareMeddtestDevLambdaSqsFifoQueueDlq-dp1UAxpw6o7k.fifo'
export const deliveryUploadsQueueUrl = 'https://sqs.us-east-1.amazonaws.com/261130029928/PythonTasksStackDev-DeliveryUloadsQueue02BA1BE6-4HQ9nRGEfQwO'
export const cloudWatchLogsInfoGroupName = 'dareMeddtestDev-dareMeddtestDevInfoLogGroup-jdXs9kwVCRKz'
export const apiCloudwatchFunctionArn = 'arn:aws:lambda:us-east-1:261130029928:function:dareMeddtestDev-dareMeddtestDevApiLambdaCloudWatch-E9iRIwPMeMxW'
export const dataBucket = 'daremeddtestdev-daremeddtestdevdatabucket-1a3rvpwcakv0v'
export const dynamoLockTableName = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevLockTable-1KIFMG9RZEMPU'
export const staticBucket = 'daremeddtestdev-daremeddtestdevstaticbucket-wi5ecqx6up73'
export const cloudfrontDistributionArn = 'E3MB65PCU1C00Z'
export const performanceTestDynamoDBDataTableArn = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevPerformanceTestDynamoDbDataTable-1JN46ZEZYARYT'
export const cloudWatchLogsEsGroupName = 'dareMeddtestDev-dareMeddtestDevEsLogGroup-xqKOy5MM9HTC'
export const clientId = '2uqs6s1bbpr6cbj5ogpgc33qng'
export const elasticsearchDomainEndpoint = 'search-daremed-dareme-oxsmsqwm9k7d-ntl7buzhsl3orhjao5xk6qibda.us-east-1.es.amazonaws.com'
export const apiDynamoWebsocketsConnectionsTable = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevApiDynamoDbWebsocketConnectionsTable-1OWN9EOKT2EOX'
export const cloudwatchLogsOutput = 'daremeddtestdev-daremeddtestdevcloudwatchlogsbuck-1bs4dtrnf0gtx'
export const apiDynamoArchivalTableName = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevApiDynamoDbArchivalTable-1B4KEONLEMEVO'
export const gatewayWebsocketApiId = '2xsgidx5d1'
export const performanceTestArchivalDynamoDBDataTableArn = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevPerformanceTestArchivalDynamoDbDataTable-5UAG2JF7RVI9'
export const apiDynamoScanTableName = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevApiDynamoDbScanTable-1J8JS8BDDTAZC'
export const lambdaSqsStandardQueueUrl = 'https://sqs.us-east-1.amazonaws.com/261130029928/dareMeddtestDev-dareMeddtestDevLambdaSqsStandardQueue-szUjNQqvDZBh'
export const lambdaSqsStandardQueueDlqUrl = 'https://sqs.us-east-1.amazonaws.com/261130029928/dareMeddtestDev-dareMeddtestDevLambdaSqsStandardQueueDlq-iGIFoEdxLANZ'
export const sesConfigurationSetEventBasedEmailsArn = 'dareMeddtestDevSESConfigurationSetEventBasedEmails-xVFOlw0PsJYq'
export const apiDynamoTableName = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevApiDynamoDbTable-1DE6CQ32RO50T'
export const gatewaySentryTunnelApiId = 'p0zxebu4f2'
export const cloudWatchLogsEmailGroupName = 'dareMeddtestDev-dareMeddtestDevEmailLogGroup-tlSq2IJ0UTEi'
export const apiFunctionArn = 'arn:aws:lambda:us-east-1:261130029928:function:dareMeddtestDev-dareMeddtestDevTriggerLambdaFuncti-78zNiKT7WNuY'
export const fileUploadBucket = 'daremeddtestdev-daremeddtestdevfileuploadbucket-8zqo3x60ee09'
export const domainName = 'dev.ddtest.link'
export const seedDbBucket = 'daremeddtestdev-daremeddtestdevseeddbbucket-ukygw4ys6fmr'
export const kmsCustomerMasterKeyArn = 'arn:aws:kms:us-east-1:261130029928:key/5c16ac72-8752-4fb1-b79b-b19bde0bb397'
export const apiDynamoStatisticsTableName = 'arn:aws:dynamodb:us-east-1:261130029928:table/dareMeddtestDev-dareMeddtestDevApiDynamoDbStatisticsTable-44ZSMKN60D4Q'
export const gatewayLambdaArn = 'arn:aws:lambda:us-east-1:261130029928:function:dareMeddtestDev-dareMeddtestDevGatewayLambda-ZGHtqR6p04zv'
export const lambdaSqsFifoQueueUrl = 'https://sqs.us-east-1.amazonaws.com/261130029928/dareMeddtestDev-dareMeddtestDevLambdaSqsFifoQueue-NGlr3Wu5sO0B.fifo'
export const userPoolId = 'us-east-1_7nkueGPtm'
export const identityPoolId = 'us-east-1:924727ce-204c-4c8d-b77b-1cb2b2290480'
export const cloudWatchEventsIamRole = 'arn:aws:iam::261130029928:role/dareMeddtestDev-dareMeddtestDevCloudwatchEventsRol-1RF7DUCG2H1AZ'
