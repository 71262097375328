import {
	CHANGE_ROUTE,
} from 'root/src/client/logic/route/actionIds'

const changeRoute = ({ routeId, routeParams, query, languageCode }) => ({
	type: CHANGE_ROUTE,
	payload: { routeId, routeParams, query, languageCode },
})

export default changeRoute
