import { BRANDS_DARE_CREATORS_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { ltGtMediaQuery } from 'root/src/client/web/commonStyles'
import Publishers1Blob from 'root/src/client/public/backgroundBlobs/for-publishers-1.svg'
import { ourSalesEmail } from 'root/src/shared/constants/mail'

export default {
	[BRANDS_DARE_CREATORS_SECTION]: {
		moduleType: 'static',
		staticPageType: 'section',
		backgroundData: [
			{
				position: 'left',
				image: Publishers1Blob,
				style: {
					transform: 'translate(0, -120px)',
					[ltGtMediaQuery]: {
						transform: 'translate(0%, -45%)',
					},
				},
			},
		],
		pageContent: {
			heading: 'Dare Creators, Reward their fans',
			text: 'Challenge creators to show off the best parts of your game, reward their fans with keys, swag, and in-game content when they win.',
			position: 'right',
			buttons: [
				{
					label: 'Add Your Game to Dare Drop',
					// TEMPORARY FIX UNTIL BRAND ONBOARDING MVP
					href: `mailto:${ourSalesEmail}`,
					// routeId: (state) => {
					// 	const isAuthenticated = path(['app', 'authentication'], state)
					// 	return isAuthenticated ? CAMPAIGNS_PAGE_ROUTE_ID : SIGN_UP_ROUTE_ID
					// },
					icon: faChevronRight,
				},
			],
		},
	},
}
