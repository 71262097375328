import { DROP_ADMIN_PAYMENT_GENERATOR_FORM } from 'root/src/shared/descriptions/modules/moduleIds'
import adminGeneratePaymentLinkPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/adminGeneratePaymentLinkPayloadSchema'
import { ADMIN_GENERATE_PAYMENT_LINK } from 'root/src/shared/descriptions/endpoints/endpointIds'

export default {
	[DROP_ADMIN_PAYMENT_GENERATOR_FORM]: {
		moduleType: 'form',
		title: 'Generate payment link',
		schema: adminGeneratePaymentLinkPayloadSchema,
		formOutput: true,
		fields: [
			{
				fieldId: 'planId',
				inputType: 'text',
				labelFieldText: [
					{
						text: 'Plan id',
						required: true,
					},
				],
			},
			{
				fieldId: 'dropEventId',
				inputType: 'text',
				labelFieldText: [
					{
						text: 'Drop event id',
						required: true,
					},
				],
			},
			{
				fieldId: 'subscriptionInfo',
				inputType: 'text',
				multiline: true,
				multilineRows: 4,
				labelFieldText: [
					{
						text: 'Subscription Info',
					},
				],
			},
		],
		submits: [
			{
				label: 'Generate payment link',
				endpointId: ADMIN_GENERATE_PAYMENT_LINK,
				buttonType: 'primaryButton',
			},
		],
	},
}
