import path from 'ramda/src/path'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import recordTypeSelector from 'root/src/client/logic/api/selectors/recordTypeSelector'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import getRecordIdMod from 'root/src/client/logic/route/util/getRecordIdMod'

export default (state, props = {}) => {
	const { moduleId, recordId, routeId } = props
	const records = path(['api', 'records'], state)
	const endpointId = moduleEndpointIdSelector(state, { moduleId })
	let recordType
	if (typeof endpointId === 'string') {
		recordType = recordTypeSelector(endpointId)
	} else if (Array.isArray(endpointId)) {
		recordType = recordTypeSelector(endpointId[0])
	}

	const recordIdMod = getRecordIdMod({
		recordId: recordId || currentRouteParamsRecordId(state),
		routeId,
	})

	return recordIdMod ? path([recordType, recordIdMod], records) : path([recordType], records)
}
