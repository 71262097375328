/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import isNil from 'ramda/src/isNil'
import { createPortal } from 'react-dom'
import { colorsNew } from 'root/src/client/web/commonStyles'
import classnames from 'classnames'
import Overlay from './Overlay'

// TODO @next verify if it still works
const Modal = ({ children }) => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)

		return () => setMounted(false)
	}, [])

	return mounted
		? createPortal(children,
			document.getElementById('modal-root'))
		: null
}

const style = {
	list: {
		paddingTop: 2,
		paddingBottom: 8,
		margin: 0,
		padding: 0,
		position: 'relative',
		listStyle: 'none',
		background: 'white',
		borderRadius: 4,
		backgroundColor: colorsNew.dark2,
		maxHeight: 368,
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			height: 13,
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: 3,
			background: colorsNew.secondary,
		},
	},
	modal: {
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 1300,
		position: 'absolute',
	},
	menuBlock: {
		opacity: 1,
		width: 200,
		position: 'absolute',
		display: 'block',
		boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
		borderRadius: 4,
	},
	menu: {
		position: 'absolute',
		top: 0,
		zIndex: 1501,
	},
	menuOpened: {
		display: 'block',
	},
	menuClosed: {
		display: 'none',
	},
	onTop: {
		zIndex: 214748364700,
	},
}

// TODO: there are few magic numbers used for position calc, fix this
const Menu = memo(({
	children, open, classes, componentRef, isNav, setOpenFn, onClose, width, isWidget,
}) => {
	const [menuStyle, setMenuStyle] = useState({})
	const domRec = !isNil(componentRef) && componentRef.getBoundingClientRect
		? componentRef.getBoundingClientRect() : {}
	const getLeftPosition = () => {
		if (isNav) {
			const dis = window.innerWidth - 217
			if (dis < 1471) {
				return dis
			}
			return 1471 + (dis - 1471) / 2
		}
		if (isWidget) {
			return domRec.left - 165
		}
		return window.innerWidth - domRec.left > 200 ? domRec.left : domRec.left - 160
	}
	const getTopPosition = () => {
		const scrollTop = window.scrollY

		if (isNav) {
			return domRec.top + 80
		}
		if (isWidget) {
			return domRec.top - 204
		}
		if (window.innerHeight - domRec.top < 270) {
			return scrollTop + window.innerHeight - 300
		}
		return scrollTop + domRec.top
	}

	useEffect(() => {
		setMenuStyle({
			top: `${getTopPosition()}px`,
			left: `${getLeftPosition()}px`,
			position: isWidget ? 'fixed' : 'absolute',
		})
	}, [domRec.x, domRec.y, domRec.top])

	return (
		<Modal>
			<div
				className={classnames(
					classes.menu,
					classes.onTop,
					{ [classes.menuOpened]: open },
					{ [classes.menuClosed]: !open },
				)}
			>
				<div
					style={menuStyle}
					onClick={(e) => {
						e.preventDefault()
						setOpenFn(false)
						if (!isNil(onClose)) {
							onClose()
						}
					}}
					className={classes.modal}
				>
					<div
						className={classes.menuBlock}
						style={{ width }}
					>
						<ul className={classes.list}>
							{children}
						</ul>
					</div>
				</div>
			</div>
			<Overlay open={open} setOpenFn={setOpenFn} onClose={isNil(onClose) ? () => { } : onClose} />
		</Modal>
	)
})

export default withStyles(style, { name: 'MuiMenu' })(Menu)
