import { CREATORS_AUTOCOMPLETE_MISC_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[CREATORS_AUTOCOMPLETE_MISC_MODULE_ID]: {
		moduleType: 'misc',
		miscModuleType: 'creatorsAutocompleteModule',
		fields: [
			{
				fieldId: 'creators',
				maxItems: 0,
				inputType: 'autoComplete',
				optionsPromiseType: 'creators',
				placeholder: 'Search Creators',
				uniqueItems: true,
				required: true,
				isMulti: true,
			},
		],
	},
}
