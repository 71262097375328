import { GET_TOP_ACTIVE_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { projectList } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'
import getTopActiveCreatorsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getTopActiveCreatorsResponseSchema'

const payloadSchema = emptyPayloadSchema
const responseSchema = getTopActiveCreatorsResponseSchema

export default {
	[GET_TOP_ACTIVE_CREATORS]: {
		authentication: authenticationTypes.optional,
		endpointType: listEndpointType,
		payloadSchema,
		responseSchema,
		recordType: projectList,
	},
}
