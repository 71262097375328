import { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Weglot } from 'root/src/shared/constants/weglot'
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography'
import { colorsNew } from 'root/src/client/web/commonStyles'
import arrow from 'root/src/client/public/icons/ic-dropdown.svg'
import { reversedLanguageCodes } from 'root/src/shared/constants/languageCodes'
import { useLanguage } from 'root/src/client/v2/browser/hooks/useLanguage'
import { withStyles } from '@material-ui/styles/index'

const languages = Weglot.languageCodes.map((code, idx) => ({
	code,
	origin: Weglot.origins[idx],
	FlagComponent: Weglot.flagComponents[code],
	name: Weglot.languageNames[code],
}))

const flagWidth = 20
const style = {
	container: {
		fontSize: 15,
		width: '100%',
		height: '100%',
		display: 'grid',
		placeItems: 'center',
		userSelect: 'none',
		'-webkit-tap-highlight-color': 'transparent',
	},
	currentLanguage: {
		display: 'flex',
		backgroundColor: 'transparent',
		border: 'none',
		outline: 'none',
		padding: 0,
		alignItems: 'center',
		height: 'fit-content',
		cursor: 'pointer',
		'& p': {
			fontSize: 'inherit',
			marginLeft: 3,
		},
	},
	languageList: {
		cursor: 'pointer',
		position: 'absolute',
		top: '130%',
		left: '50%',
		transform: 'translateX(-50%)',
		backgroundColor: colorsNew.dark2,
		padding: 0,
		margin: 0,
		listStyle: 'none',
		zIndex: 1,
		'& li': {
			'&:hover': {
				backgroundColor: colorsNew.dark3,
			},
			'& button': {
				fontSize: 'inherit',
				backgroundColor: 'transparent',
				border: 'none',
				cursor: 'pointer',
				textDecoration: 'none',
				display: 'flex',
				alignItems: 'center',
				gap: 5,
				padding: [[5, 10]],
				'& p': {
					fontSize: 'inherit',
				},

			},
		},
	},
}

const LanguageSwitcher = ({ classes, isMobileRes }) => {
	const { languageCode, languageSwitchHandler } = useLanguage()
	const [isListOpen, setIsListOpen] = useState(false)
	const currentLanguage = languages.find(({ code }) => code === languageCode)

	return (
		<div
			className={classes.container}
			style={isMobileRes ? { minHeight: 150 } : {}}
		>
			<OutsideClickHandler
				onOutsideClick={() => setIsListOpen(false)}
			>
				<div
					style={{
						position: 'relative',
						marginBottom: isMobileRes ? 100 : 0,
					}}
				>
					<button
						type="button"
						className={classes.currentLanguage}
						onClick={() => setIsListOpen(prev => !prev)}
					>
						<currentLanguage.FlagComponent width={flagWidth} style={{ marginRight: 3 }} />
						<Typography variant={typographyVariants.smallBold}>
							{reversedLanguageCodes[currentLanguage.code].toUpperCase()}
						</Typography>
						<img
							style={!isListOpen ? { transform: 'rotate(-90deg)' } : {}}
							src={arrow.src}
							alt="arrow-down"
						/>
					</button>
					<ul
						style={{ display: isListOpen ? 'block' : 'none' }}
						className={classes.languageList}
					>
						{languages.map(({ code, name, FlagComponent }) => (code !== languageCode ? (
							<li key={code}>
								<button
									type="button"
									onClick={() => languageSwitchHandler(code)}
								>
									<FlagComponent width={flagWidth} />
									<Typography variant={typographyVariants.small}>
										{name}
									</Typography>
								</button>
							</li>
						) : null))}
					</ul>
				</div>
			</OutsideClickHandler>
		</div>
	)
}

export default withStyles(style)(LanguageSwitcher)
