import {
	DROP_EDIT_EVENT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import dropEditEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/dropEditEventPayloadSchema'
import dropEventResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/dropEventResponseSchema'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[DROP_EDIT_EVENT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: recordEndpointType,
		recordType: dropEvent,
		payloadSchema: dropEditEventPayloadSchema,
		responseSchema: dropEventResponseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
