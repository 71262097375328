import { CHECK_FOR_NOTIFICATIONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import checkForNotificationsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/checkForNotificationsResponseSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { notificationSettings } from 'root/src/shared/descriptions/endpoints/recordTypes'

const responseSchema = checkForNotificationsResponseSchema

export default {
	[CHECK_FOR_NOTIFICATIONS]: {
		endpointType: otherEndpointType,
		recordType: notificationSettings,
		responseSchema,
		authentication: authenticationTypes.authenticated,
	},
}
