import { useGetStartedButton, } from 'root/src/client/v2/creatorOnboarding/features/CreatorOnboardingButtons/hooks/useGetStartedButton';
import { useGetFreeKeysButton, } from 'root/src/client/v2/creatorOnboarding/features/CreatorOnboardingButtons/hooks/useGetFreeKeysButton';
import { useEngageViewersButton, } from 'root/src/client/v2/creatorOnboarding/features/CreatorOnboardingButtons/hooks/useEngageViewersButton';
import { useIsMobileResolution } from 'root/src/client/v2/browser/hooks/useIsMobileResolution';
import { ButtonNew } from 'root/src/client/v2/common/components/form/ButtonNew';
export function CreatorOnboardingButtons() {
    const { isMobileResolution } = useIsMobileResolution();
    return (<div style={{
            right: isMobileResolution ? 30 : 100,
        }} className="fixed top-[100px] z-[20] flex flex-col gap-4 items-end">
			<GetStartedButton />
			<GetFreeKeysButton />
			<EngageViewersButton />
		</div>);
}
function GetStartedButton() {
    const { showSignInWithProvidersModal, displayGetStartedButton, } = useGetStartedButton();
    if (!displayGetStartedButton) {
        return null;
    }
    return (<ButtonNew className="w-[200px] h-[50px] hover:w-[440px] group" onClick={showSignInWithProvidersModal}>
			<span className="flex justify-center group-hover:hidden">Get Started!</span>
			<span className="hidden justify-center group-hover:flex whitespace-nowrap overflow-x-hidden">Connect Twitch, YouTube, or TikTok to get started!</span>
		</ButtonNew>);
}
function GetFreeKeysButton() {
    const { displayGetFreeKeysButton, showGetFreeKeysModal } = useGetFreeKeysButton();
    if (!displayGetFreeKeysButton) {
        return null;
    }
    return (<ButtonNew className="w-[200px] h-[50px]" onClick={showGetFreeKeysModal}>
			Get Free Keys
		</ButtonNew>);
}
function EngageViewersButton() {
    const { displayEngageViewersButton, showEngageViewersModal } = useEngageViewersButton();
    if (!displayEngageViewersButton) {
        return null;
    }
    return (<ButtonNew className="w-[200px] h-[50px]" onClick={showEngageViewersModal}>
			Engage Viewers
		</ButtonNew>);
}
