export default {
	type: 'object',
	properties: {
		termsAcceptedCampaigns: {
			type: 'array',
			items: {
				type: 'string',
			},
		},
	},
	required: [],
	additionalProperties: false,
}
