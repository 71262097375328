import includes from 'ramda/src/includes'
import pipe from 'ramda/src/pipe'
import dissocPath from 'ramda/src/dissocPath'
import __ from 'ramda/src/__'
import over from 'ramda/src/over'
import lensPath from 'ramda/src/lensPath'
import omit from 'ramda/src/omit'
import { CLEAR_PROJECT_ARRAY } from 'root/src/client/logic/header/actionsIds'
import projectListModules from 'root/src/shared/constants/projectListModules'
import { isNilOrEmpty } from 'root/src/shared/util/ramdaPlus'

const dissocProjectLists = (listsObj) => {
	if (isNilOrEmpty(listsObj)) {
		return listsObj
	}

	// finds list keys containing one of projectListModulules ids
	const listKeysToReject = Object.keys(listsObj).filter(
		key => projectListModules.find(includes(__, key)),
	)
	return omit(listKeysToReject, listsObj)
}

export default {
	[CLEAR_PROJECT_ARRAY]: state => pipe(
		over(lensPath(['api', 'lists']), dissocProjectLists),
		dissocPath(['api', 'project']),
	)(state),
}
