import { FEATURED_DARES_ADMIN_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { featuredDaresKey } from 'root/src/shared/constants/keys'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import { VIEW_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[FEATURED_DARES_ADMIN_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		tableColumns: [
			['Shortened URL', 'shareUrl'],
			['Campaign ID', 'campaignId'],
			['Publisher name', ['sponsor', 'brandName']],
		],
		tableItemActions: [
			{
				header: 'Link',
				name: 'View Dare',
				fn: item => () => pushRouteClient(
					{ routeId: VIEW_PROJECT_ROUTE_ID, routeParams: { recordId: item.projectUrl } },
				),
			},
		],
		listPayload: {
			section: featuredDaresKey,
		},
		listTitle: 'Featured dares',
		recordType: project,
		endpointId: [GET_PROJECTS],
	},
}
