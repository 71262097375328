import { API_FETCH_USER_DATA_SUCCESS } from 'root/src/client/logic/api/actionIds'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { setUserData, viewUserData } = appStoreLenses

export const apiFetchUserDataSuccess = (state, { body, recordType }) => {
	const userData = viewUserData(state)
	if (!recordType) {
		return setUserData({ ...userData, ...body }, state)
	}
	return setUserData({ ...userData, [recordType]: body }, state)
}

export default {
	[API_FETCH_USER_DATA_SUCCESS]: apiFetchUserDataSuccess,
}
