import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import linkHrefSelector from 'root/src/client/logic/app/selectors/linkHrefSelector'
import { setCookie, getCookie } from 'root/src/client/v2/browser/helpers/cookies'
import moment from 'moment'
import changeRouteCommon from 'root/src/client/logic/brandSettings/util/changeRouteCommon'
import { groupDefaultRouteIdMap } from 'root/src/shared/util/groupDefaultRouteIdMap'
import { userGroups } from 'root/src/shared/constants/auth'
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants'

const agencyRoute = groupDefaultRouteIdMap[userGroups.agency]
const brandRoute = groupDefaultRouteIdMap[userGroups.brand]

export default item => async (dispatch, getState) => {
	const { id, value } = item
	if (!(id || value)) {
		const backToken = getCookie('backToken')
		const href = linkHrefSelector({}, { routeId: agencyRoute, hash: { backToken } })
		setCookie('backToken', '', -1)
		changeRouteCommon(getState, href, agencyRoute)
	} else {
		const token = getCookie('accessToken')
		const { body } = await dispatch(
			apiRequest(agencyEndpointIds.GET_PUBLISHER_TOKEN, { id: id || value, token }),
		)
		const { publisherToken: { accessToken }, backToken } = body
		const href = linkHrefSelector({}, { routeId: brandRoute, hash: { accessToken } })
		if (backToken) {
			setCookie('backToken', backToken, moment().add(1, 'years').unix())
		}
		changeRouteCommon(getState, href, brandRoute)
	}
}
