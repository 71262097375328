import { faHighlighter } from '@fortawesome/pro-light-svg-icons/faHighlighter'
import { faFilm } from '@fortawesome/pro-light-svg-icons/faFilm'
import { faGamepadAlt } from '@fortawesome/pro-light-svg-icons/faGamepadAlt'
import { faTheaterMasks } from '@fortawesome/pro-light-svg-icons/faTheaterMasks'
import { faSeedling } from '@fortawesome/pro-light-svg-icons/faSeedling'
import { faEgg } from '@fortawesome/pro-light-svg-icons/faEgg'
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook'
import { faRabbitFast } from '@fortawesome/pro-light-svg-icons/faRabbitFast'
import { dropDareTypeEnum } from 'root/src/shared/constants/dropEvent'

export const fontFamily = 'Roboto'

export const primaryGradient = 'linear-gradient(261deg, #7840b6, #5840b6)'
export const secondaryGradient = 'linear-gradient(253deg, #401d66, #3e2c84)'
export const linkGradient = 'linear-gradient(261deg, #4ec292, #18B071)'
export const primaryColor = '#1d2734'
export const secondaryColor = '#233448'
export const tertiaryColor = '#6d7a87'
export const purple = '#ab00f2'
export const lightPurple = '#c3b4dc'

export const testimonialColor = '#1F2C3C'

export const errorColor = '#e02020'

export const lightGrey = '#9f9f9f'

export const lineColor = '#7F7F7F'
export const linkColor = '#5FC49A'
export const linkHoverColor = '#657380'
export const doveGray = '#707070'

export const black = '#000'

export const newColors = 'newColors'
export const defaultColors = 'defaultColors'

// later we'll remove old ones and set these as main
export const colorsNew = {
	white: '#FFFFFF',
	background: '#04172D',
	discord: '#5865f2',
	facebook: '#3b5998',
	twitter: '#1DA1F2',
	youtube: '#FF0000',
	instagram: '#F77737',
	twitch: '#6441A5',
	primary: '#7A3AED',
	secondary: '#5D12E4',
	primaryGradient: 'linear-gradient(180deg, #7A3AED 0%, #5D12E4 100%)',
	secondaryGradient: 'linear-gradient(177.76deg, #5E13E3 8.26%, #8039FF 105.14%)',
	placeholder: '#ABABCB',
	red: '#E62D28',
	yellow: '#FFEA00',
	yellowDark: '#E1D016',
	teal: '#00FFED',
	tealDark: '#00C8BA',
	orange: '#F58529',
	blue: '#08A0E9',
	pink: '#FF007A',
	green: '#3DFC69',
	link: '#BE28A9',
	pinkDark: '#D00064',
	light1: '#FBFAFF',
	light2: '#ECE7F4',
	light3: '#9CB5D1',
	light4: '#7295BC',
	light5: '#5276A0',
	dark1: '#274364',
	googleDark: '#131314',
	googleBorder: '#8E918F',
	googleText: '#E3E3E3',
	dark2: '#132D4A',
	dark3: '#04172D',
	dark3Rgba: 'rgba(4, 23, 45, 0.4)',
	gray3: '#828282',
	gray4: '#525252',
	pinkGradient: 'linear-gradient(268.73deg, #FF007A 29.26%, #D00064 97.88%)',
	tealGradient: 'linear-gradient(275.91deg, #00C8BA 4.85%, #00FFED 93.76%)',
	creative: '#00968B',
	gameplay: '#2B40FF',
	review: '#CB0025',
	starter: '#7A3AED',
	speed: '#CF03AE',
	highlights: '#D58000',
	easterEgg: '#5C5C5C',
	guide: '#00D33B',
	tiktok: '#00f2ea',
	purple1: '#5E13E3',
	purple2: '#8039FF',
}

export const formFieldsThemes = {
	[newColors]: {
		formTitle: {
			fontFamily: 'Roboto Mono',
			fontWeight: 'bold',
			letterSpacing: '2%',
			fontSize: 38,
			margin: 5,
		},
		formSubTitle: {
			fontSize: 16,
			fontFamily: 'Roboto Mono',
			color: colorsNew.light3,
			fontWeight: 'bold',
		},
		backgroundColor: colorsNew.light5,
		textColor: colorsNew.white,
		placeholderColor: colorsNew.placeholder,
		subTextLabel: {
			color: colorsNew.dark3,
			'& > a': {
				textDecoration: 'underline',
				color: `${colorsNew.dark3} !important`,
			},
		},
		dropdownOptionsColor: colorsNew.dark2,
		chipColor: colorsNew.dark1,
		fieldLinkStyle: {
			color: colorsNew.dark3,
			textDecoration: 'underline',
		},
	},
	[defaultColors]: {
		backgroundColor: colorsNew.dark2,
		textColor: colorsNew.white,
		placeholderColor: 'inherit',
		dropdownOptionsColor: colorsNew.dark2,
		subTextColor: colorsNew.white,
		chipColor: colorsNew.dark1,
	},
}

export const categoriesMap = {
	[dropDareTypeEnum.creative]: { color: colorsNew.creative, icon: faTheaterMasks },
	[dropDareTypeEnum.gameplay]: { color: colorsNew.gameplay, icon: faGamepadAlt },
	[dropDareTypeEnum.highlights]: { color: colorsNew.highlights, icon: faHighlighter },
	[dropDareTypeEnum.starter]: { color: colorsNew.starter, icon: faSeedling },
	[dropDareTypeEnum.guide]: { color: colorsNew.guide, icon: faBook },
	[dropDareTypeEnum.easterEgg]: { color: colorsNew.easterEgg, icon: faEgg },
	[dropDareTypeEnum.speed]: { color: colorsNew.speed, icon: faRabbitFast },
	[dropDareTypeEnum.review]: { color: colorsNew.review, icon: faFilm },
}

export const navLinkStyle = {
	fontSize: 16,
	fontWeight: 300,
	fontFamily: 'Arial',
	textDecoration: 'none',
	color: 'white',
	border: 0,
	backgroundColor: 'inherit',
	cursor: 'pointer',
	padding: 0,
	'&:focus': {
		outline: 0,
	},
	'&:visited': {
		color: 'white',
	},
}

export const primaryColorBorder = '1px solid'
export const blackColorBorder = '1px solid #000000'

export const textShadow = (color) => {
	const expression1 = `0px 0px 0.6em ${color}`
	const expression2 = `0px 0px 0.4em ${color}`

	return `
		${expression1},
		${expression1},
		${expression2},
		${expression2}
	`
}

export const boxShadow = (color) => {
	const expression1 = `0px 0px 0.85em 0.15em ${color}`
	const expression2 = `0px 0px 0.5em 0.15em ${color}`

	return `
		${expression1},
		inset ${expression1},
		${expression2},
		inset ${expression2}
	`
}

export const dropShadow = (color) => {
	const expression = `drop-shadow(0px 0px 0.2em ${color})`
	return `${expression} ${expression}`
}

export const xsMediaQuery = '@media (max-width: 599px)'
export const gtXsMediaQuery = '@media (min-width: 600px)'
export const ltSmMediaQuery = '@media (max-width: 599px)'
export const smMediaQuery = '@media (min-width: 600px) and (max-width: 959px)'
export const gtSmMediaQuery = '@media (min-width: 960px)'
export const ltGtMediaQuery = '@media (max-width: 959px)'
export const mdMediaQuery = '@media (min-width: 960px) and (max-width: 1279px)'
export const gtMdMediaQuery = '@media (min-width: 1280px)'
export const ltMdMediaQuery = '@media (max-width: 1280px)'
export const lgMediaQuery = '@media (min-width: 1280px) and (max-width: 1919px)'
export const gtLgMediaQuery = '@media (min-width: 1920px)'
export const xlMediaQuery = '@media (min-width: 1920px)'
export const gtTabletMediaQuery = '@media (min-width: 768px)'

export const playerStyles = {
	iframeContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
	},
	iframe: {
		top: 0,
		left: 0,
		width: '100%',
		border: 'none',
	},
	completedDareIframeContainer: {
		maxWidth: 730,
		borderRadius: [[0, 8, 8, 0]],
		margin: 0,
		'@media(max-width: 1530px)': {
			maxWidth: 560,
		},
		'@media(max-width: 1280px)': {
			maxWidth: 518,
		},
		'@media(max-width: 1160px)': {
			maxWidth: 400,
		},
		[ltGtMediaQuery]: {
			maxWidth: '100vw',
			borderRadius: 0,
		},
	},
	// Tiktok video aspect ratio - 9x16
	tiktokIframe: {
		height: 600,
		border: 'none',
	},
	twitterCommon: {
		width: 550, // tweet's max width
		alignSelf: 'center',
		margin: [[15, 25, 0, 20]],
		height: '100%',
		[ltGtMediaQuery]: {
			width: '100%',
			margin: 0,
		},
	},
	twitterViewProject: {
		'@media(max-width: 1160px)': {
			width: '50%',
		},
		[ltGtMediaQuery]: {
			width: '100%',
			margin: 0,
		},
	},
}

export const dashboardStyles = {
	purple: '#ab00f2',
	darkGray: '#1e1e1e',
}
