/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import all from 'ramda/src/all'
import equals from 'ramda/src/equals'
import map from 'ramda/src/map'
import prop from 'ramda/src/prop'
import isNil from 'ramda/src/isNil'
import { compareObjectProps } from 'root/src/shared/util/ramdaPlus'

export const debugProps = (prevState, nextState, propNames, comparePropsLocal) => {
	const isTrue = equals(true)
	console.info('-----------------------------------------------------')
	console.info('%cPrev State:', 'color:red', prevState)
	console.info('%cNext State:', 'color:red', nextState)
	const checkEquals = compareObjectProps(prevState, nextState, propNames)
	map((propName) => {
		console.info(`%c${propName} `, 'color:green', equals(prop(propName, prevState), prop(propName, nextState)))
		console.info('prevState:', prop(propName, prevState))
		console.info('nextState:', prop(propName, nextState))
		return 1
	}, propNames)
	console.info('%c Local compare', 'color:green', (isNil(comparePropsLocal) || comparePropsLocal(prevState, nextState)))
	console.info('%cResult', 'color:red', all(isTrue, checkEquals))
	console.info('-----------------------------------------------------')
}
