import { ButtonNew, ButtonNewVariant } from 'root/src/client/v2/common/components/form/ButtonNew';
import { Provider } from 'root/src/shared/@types/providers';
import { useSignInWithProvider } from 'root/src/client/web/auth/hooks/useSignInWithProvider';
import TikTokLogo from 'root/src/client/public/icons/tiktok-logo.svg';
import TwitchLogo from 'root/src/client/public/icons/twitch-logo.svg';
import YouTubeIcon from 'root/src/client/public/youtube_social_icon_red.png';
import Image from 'next/image';
const iconMap = {
    [Provider.Twitch]: TwitchLogo,
    [Provider.Google]: YouTubeIcon,
    [Provider.TikTok]: TikTokLogo,
};
export function SignInWithProviderButton(props) {
    const { provider, providerLabel, onSignIn } = useSignInWithProvider({
        provider: props.provider,
        isLinking: props.isLinking,
    });
    return (<ButtonNew onClick={onSignIn} className="bg-dark-google border-dark-googleBorder border-[1px] rounded p-2 flex justify-center" variant={ButtonNewVariant.PrimaryNoHover} disabled={props.disabled} type="button">
			<ProviderIcon provider={provider}/>
			<span className="mx-2 text-white font-medium text-sm">Sign in with {providerLabel}</span>
		</ButtonNew>);
}
function ProviderIcon(props) {
    const icon = iconMap[props.provider];
    return (<Image className="object-contain" src={icon.src} unoptimized height={20} width={20}/>);
}
