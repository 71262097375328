export const addBrandCustomRewardResponseSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: { type: 'string' },
		cta: { type: 'string' },
		url: { type: 'string' },
		created: { type: 'string' },
		type: { type: 'string' },
		amount: { type: 'number' },
		restrictions: { type: 'string' },
	},
	required: ['id', 'name', 'cta', 'url', 'created'],
	additionalProperties: false,
}
