import { SET_REDIRECT_URL } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import setRedirectUrlSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setRedirectUrlSchema'

export default {
	[SET_REDIRECT_URL]: {
		endpointType: recordEndpointType,
		payloadSchema: setRedirectUrlSchema,
	},
}
