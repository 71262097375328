import toLower from 'ramda/src/toLower'
import {
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
	POINTS_MARKETPLACE_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import {
	VIEW_CREATOR_DATA_MODULE_ID,
	CREATOR_PROJECTS_LIST_MODULE_ID,
	CREATOR_PROJECTS_LIST_BANNER_HEADER_MODULE_ID,
	POINTS_MARKETPLACE_LIST_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export const creatorDataModules = [
	VIEW_CREATOR_DATA_MODULE_ID,
	CREATOR_PROJECTS_LIST_BANNER_HEADER_MODULE_ID,
	CREATOR_PROJECTS_LIST_MODULE_ID,
]

export default {
	[VIEW_CREATOR_DATA_ROUTE_ID]: {
		url: '/creators/:recordId',
		modules: creatorDataModules,
		mainModule: CREATOR_PROJECTS_LIST_MODULE_ID,
		recordIdParse: toLower,
		makeRequestsOnClient: true,
		redirects: [
			{ source: '/streamers/:recordId' },
		],
	},
	[VIEW_YT_CREATOR_DATA_ROUTE_ID]: {
		url: '/creators/yt/:recordId',
		modules: creatorDataModules,
		mainModule: CREATOR_PROJECTS_LIST_MODULE_ID,
		makeRequestsOnClient: true,
		redirects: [
			{ source: '/streamers/yt/:recordId' },
		],
	},
	[POINTS_MARKETPLACE_ROUTE_ID]: {
		url: '/points-marketplace',
		authentication: authenticationTypes.authenticated,
		authenticationRole: [
			userGroups.creator,
			userGroups.viewer,
			userGroups.admin,
		],
		modules: [
			POINTS_MARKETPLACE_LIST_MODULE_ID,
		],
	},
	[VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID]: {
		url: '/creators/tiktok/:recordId',
		modules: creatorDataModules,
		mainModule: CREATOR_PROJECTS_LIST_MODULE_ID,
		makeRequestsOnClient: true,
		redirects: [
			{ source: '/streamers/tiktok/:recordId' },
		],
	},
	[VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID]: {
		url: '/creators/facebook/:recordId',
		modules: creatorDataModules,
		mainModule: CREATOR_PROJECTS_LIST_MODULE_ID,
		redirects: [
			{ source: '/streamers/facebook/:recordId' },
		],
	},
}
