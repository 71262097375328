export default {
	type: 'object',
	properties: {
		data: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					id: { type: 'string' },
					title: { type: 'string' },
					mockData: { type: 'integer' },
				},
			},
		},
		totals: {
			total: {
				type: 'integer',
			},
		},
	},
	required: ['data', 'totals'],
}
