import { SET_EMAIL_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import setEmailSchema from 'root/src/shared/descriptions/formSchemas/setEmailSchema'
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[SET_EMAIL_FORM_MODULE_ID]: {
		moduleType: 'form',
		schema: setEmailSchema,
		title: 'Change Email',
		subTitle: 'Enter your new email',
		fields: [
			{
				fieldId: 'email',
				inputType: 'text',
				placeholder: 'New email address',
			},
			{
				fieldId: 'confirmEmail',
				inputType: 'text',
				placeholder: 'Confirm new email address',
			},
		],
		submits: [
			{
				label: 'Confirm',
				buttonType: 'primaryButton',
			},
		],
		links: [
			{
				routeId: ACCOUNT_SETTINGS_ROUTE_ID,
				label: 'Go Back',
			},
		],
	},
}
