import { ADD_PUBLISHER_BANNED_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { publisherBan } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { Platform } from 'root/src/shared/@types/platforms';
import { PublisherBanListType } from 'root/src/shared/@types/publisherBanList';
export const addPublisherBannedCreatorsEndpoint = {
    [ADD_PUBLISHER_BANNED_CREATORS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brand,
        endpointType: listEndpointType,
        recordType: publisherBan,
        payloadSchema: {
            type: 'object',
            properties: {
                mnemonicIds: {
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
                platform: {
                    type: 'string',
                    enum: Object.values(Platform),
                },
                banListType: {
                    type: 'string',
                    enum: Object.values(PublisherBanListType),
                },
                dropEventId: {
                    type: 'string',
                },
            },
            required: ['mnemonicIds', 'platform', 'banListType'],
        },
    },
};
