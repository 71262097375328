import { GET_COMMENT_REPLIES } from 'root/src/shared/descriptions/endpoints/endpointIds'

import { comment } from 'root/src/shared/descriptions/endpoints/recordTypes'

import payloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getCommentRepliesPayloadSchema'
import responseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/commentsResponseSchema'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[GET_COMMENT_REPLIES]: {
		endpointType: listEndpointType,
		recordType: comment,
		payloadSchema,
		responseSchema,
	},
}
