import {
	CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Daring!',
			paragraphs: [
				'Your new dare is live. Get Twitch chat involved to get it done!',
			],
			socialSharing: true,
		},
	},
}
