var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest';
import { GET_DROP_SHORT_LINKS_MAPPING_REPORT, } from 'root/src/shared/descriptions/endpoints/endpointIds';
export const downloadLinkMappingReport = (item) => (_, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const payload = {
        id: item.id,
    };
    const { body, error } = yield invokeApiRequest(GET_DROP_SHORT_LINKS_MAPPING_REPORT, payload, state);
    if (error) {
        // eslint-disable-next-line no-alert
        alert(error.message);
        return;
    }
    window.open(body.fileUrl, '_parent');
});
