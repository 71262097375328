import { formStoreLenses } from 'root/src/client/logic/form/lenses'
import { SET_SUBMITS_BLOCK } from 'root/src/client/logic/form/actionIds'

const { setSubmitsBlock } = formStoreLenses

export default {
	[SET_SUBMITS_BLOCK]: (
		state, { moduleId, isVisible },
	) => setSubmitsBlock(moduleId, isVisible, state),
}
