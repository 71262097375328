import reduxConnector from 'root/src/shared/util/reduxConnector'
import metaDataSelector from 'root/src/client/logic/route/selectors/metaDataSelector'
import routeRestrictionSelector from 'root/src/client/logic/app/selectors/routeRestrictionSelector'
import testMediaBreakpoints from 'root/src/client/logic/app/thunks/testMediaBreakpoints'
import determineAuth from 'root/src/client/logic/auth/thunks/determineAuth'
import restrictionRedirect from 'root/src/client/logic/app/thunks/restrictionRedirect'
import runModuleMountClient from 'root/src/client/logic/api/thunks/runModuleMountClient'

export default reduxConnector(
	[
		['metaData', metaDataSelector],
		['routeRestriction', routeRestrictionSelector],
	],
	[
		['testMediaBreakpoints', testMediaBreakpoints],
		['determineAuth', determineAuth],
		['restrictionRedirect', restrictionRedirect],
		['runModuleMountClient', runModuleMountClient],
	],
)
