import {
	CREATOR_PROJECTS_LIST_MODULE_ID,
	CREATOR_PROJECTS_LIST_BANNER_HEADER_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { CREATE_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import {
	creatorProjectsKey,
	allKey,
	completedKey,
	activeKey,
	privateKey,
} from 'root/src/shared/constants/keys'

export default {
	[CREATOR_PROJECTS_LIST_BANNER_HEADER_MODULE_ID]: {
		moduleType: 'bannerHeader',
		listModuleId: CREATOR_PROJECTS_LIST_MODULE_ID,
		link: {
			routeId: CREATE_PROJECT_ROUTE_ID,
			label: 'Create a new Dare +',
		},
		createNewDareActive: true,
		fixedFilter: [['mnemonicId']],
		oneRow: true,
		bannerTabs: [
			{
				id: 0,
				tabLabel: 'All',
				endpointId: [GET_PROJECTS],
				fixedPayload: {
					section: creatorProjectsKey,
					subSection: allKey,
				},
				needsTwitchAuth: false,
			},
			{
				id: 1,
				tabLabel: 'Active',
				endpointId: [GET_PROJECTS],
				fixedPayload: {
					section: creatorProjectsKey,
					subSection: activeKey,
				},
				needsTwitchAuth: false,
			},
			{
				id: 2,
				tabLabel: 'Completed',
				endpointId: [GET_PROJECTS],
				fixedPayload: {
					section: creatorProjectsKey,
					subSection: completedKey,
				},
				hideFilters: true,
				needsTwitchAuth: false,
			},
			{
				id: 3,
				tabLabel: 'Private',
				endpointId: [GET_PROJECTS],
				fixedPayload: {
					section: creatorProjectsKey,
					subSection: privateKey,
				},
			},
		],
	},
}
