import { GET_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { creator } from 'root/src/shared/descriptions/endpoints/recordTypes'
import getCreatorsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getCreatorsPayloadSchema'
import getCreatorsResponseSchema	from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getCreatorsResponseSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[GET_CREATORS]: {
		authentication: authenticationTypes.optional,
		endpointType: listEndpointType,
		recordType: creator,
		payloadSchema: getCreatorsPayloadSchema,
		responseSchema: getCreatorsResponseSchema,
	},
}
