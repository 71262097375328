export default {
	type: 'array',
	items: {
		properties: {
			pledged: { type: 'number' },
			image: { type: 'string' },
			name: { type: 'string' },
		},
	},
}
