import { NEW_EMAIL_VERIFICATION_FAILURE_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[NEW_EMAIL_VERIFICATION_FAILURE_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'verificationResult',
		pageContent: {
			title: 'Please use correct link to verify account!',
			paragraphs: [
				['There was an issue verifying your new email. Please contact support@daredrop.com.'],
			],
		},
	},
}
