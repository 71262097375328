import { globalQueryClient, queryClient } from 'root/src/client/v2/common/clients/queryClient';
import { useQueryParam } from 'use-query-params';
import { StringQueryParam } from 'root/src/client/v2/queryParams/helpers/params.helper';
export function GlobalDataProvider(props) {
    // TODO move it to more suitable place
    const [logoutParam, setLogoutParam] = useQueryParam('logout', StringQueryParam());
    const isLogout = logoutParam === 'true';
    if (isLogout) {
        void queryClient.resetQueries();
        void globalQueryClient.resetQueries();
        setLogoutParam(undefined);
    }
    return <>{props.children}</>;
}
