import {
	RESET_CAMPAIGN_DARES,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import payloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/resetCampaignDaresPayloadSchema'

export default {
	[RESET_CAMPAIGN_DARES]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		payloadSchema,
	},
}
