import { REWARDS_BULK_UPLOAD_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds';
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { BULK_UPLOAD_TEMPLATE_FILE_URL } from 'root/src/shared/rewards/constants';
export const rewardsBulkUploadForm = {
    [REWARDS_BULK_UPLOAD_FORM_MODULE_ID]: {
        moduleType: 'form',
        subTitle: [{
                text: 'Upload your keys and codes all at once by copying this template file.',
                linkLabel: 'template file',
                linkStyles: {
                    color: colorsNew.blue,
                    textDecoration: 'underline',
                },
                href: BULK_UPLOAD_TEMPLATE_FILE_URL,
            }],
        fields: [
            {
                labelFieldText: [
                    {
                        text: 'Keys and Codes',
                        required: true,
                    },
                ],
                fieldId: 'templateFile',
                inputType: 'attachmentInput',
                attachmentFormats: ['csv'],
                subFieldText: 'Filled template file',
                required: true,
            },
        ],
        submits: [
            {
                label: 'Upload',
                buttonType: 'primaryButton',
            },
        ],
        links: [
            {
                routeId: ACCOUNT_SETTINGS_ROUTE_ID,
                label: 'Go Back',
                dynamicPreviousRoute: true,
            },
        ],
    },
};
