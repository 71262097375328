import { SIGN_UP_MODAL_MODULE_ID, GET_STARTED_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import DareDropIcon from 'root/src/client/public/icons/DareDropIcon'
import backArrow from 'root/src/client/public/icons/ic-back-arrow.svg'
import { RULES_OF_USE_ROUTE_ID, PRIVACY_POLICY_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import signUpSchema from 'root/src/shared/descriptions/formSchemas/signUpSchema'
import signUp from 'root/src/client/logic/auth/thunks/signUp'

export const registerTerms = [
	{ text: 'By signing up, you agree to Dare Drop\'s\n' },
	{ text: 'Terms of Use', routeId: RULES_OF_USE_ROUTE_ID },
	{ text: ' and ' },
	{ text: 'Privacy Policy', routeId: PRIVACY_POLICY_ROUTE_ID },
]

export default {
	[SIGN_UP_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		backButtonAction: displayModal => displayModal(GET_STARTED_MODULE_ID),
		schema: signUpSchema,
		modalContent: {
			title: 'Create your account',
			subTitle: 'THE REALM OF DARES AWAITS YOU!',
			backButton: {
				icon: backArrow,
				moduleId: GET_STARTED_MODULE_ID,
			},
			buttons: [
				{
					label: 'Get Daring!',
					icon: DareDropIcon,
					type: 'primary',
					onClick: signUp,
				},
			],
			passwordHint: 'Must have a lower & uppercase letter, number and symbol',
			successModalProps: {
				header: 'Please verify your account!',
				infoText: 'We sent you an email with instructions, It may take a moment to arrive.',
				buttonLabel: 'Sign In',
			},
			registerTerms,
		},
	},
}
