export default {
	type: 'object',
	properties: {
		data: {
			type: 'array',
			items: {
				type: 'object',
			},
			pagination: {
				type: 'object',
				properties: {
					cursor: { type: 'string' },
				},
			},
		},
	},
	required: [],
	additionalProperties: true,
}
