import { GET_CREATOR_CAMPAIGN_POINTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import getCreatorCampaignPointsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getCreatorCampaignPointsPayloadSchema'
import getCreatorCampaignPointsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getCreatorCampaignPointsResponseSchema'
import { userGroups, authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[GET_CREATOR_CAMPAIGN_POINTS]: {
		payloadSchema: getCreatorCampaignPointsPayloadSchema,
		responseSchema: getCreatorCampaignPointsResponseSchema,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
	},
}
