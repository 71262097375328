import prop from 'ramda/src/prop'
import propEq from 'ramda/src/propEq'
import includes from 'ramda/src/includes'
import head from 'ramda/src/head'
import pipe from 'ramda/src/pipe'
import is from 'ramda/src/is'
import when from 'ramda/src/when'
import path from 'ramda/src/path'
import toLower from 'ramda/src/toLower'
import previousRouteSelector from 'root/src/client/logic/project/selectors/previousRouteSelector'
import {
	VIEW_CREATOR_DATA_ROUTE_ID, VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { getTwitchChannels } from 'root/src/client/util/twitchClientApi'
import { getYoutubeChannels } from 'root/src/client/util/youtubeClientApi'
import changeInput from 'root/src/client/logic/form/actions/changeInput'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { addHttpsProtocol } from 'root/src/shared/util/protocolUtil'
import { creatorProjectsKey, allKey, mnemonicIdKey } from 'root/src/shared/constants/keys'
import { SORT_BY_NEWEST } from 'root/src/shared/constants/sortTypes'

const searchCreatorMap = {
	twitch: {
		getCreators: getTwitchChannels,
		satisfyFn: creatorMnemonicId => propEq('value', toLower(creatorMnemonicId)),
	},
	youtube: {
		getCreators: getYoutubeChannels,
		satisfyFn: creatorMnemonicId => propEq('value', creatorMnemonicId),
	},
}

const getGameFromRes = pipe(
	path(['body', 'items']),
	when(
		is(Array),
		pipe(
			head,
			prop('games'),
			when(
				is(Array),
				head,
			),
		),
	),
)

const getSuggestedGame = async (mnemonicId) => {
	const payload = {
		pageItemLength: 1,
		subSection: allKey,
		section: creatorProjectsKey,
		sortType: SORT_BY_NEWEST,
		filter: [
			[
				{
					param: mnemonicIdKey,
					value: mnemonicId,
				},
			],
		],
	}
	const res = await invokeApiRequest(GET_PROJECTS, payload)
	return getGameFromRes(res)
}

const autoPopulateDare = ({ moduleKey }) => async (dispatch, getState) => {
	const state = getState()
	const { routeId, routeParams } = previousRouteSelector(state)

	if (includes(routeId, [
		VIEW_CREATOR_DATA_ROUTE_ID,
		VIEW_YT_CREATOR_DATA_ROUTE_ID,
		VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
		VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	])) {
		const creatorMnemonicId = prop('recordId', routeParams)
		if (!creatorMnemonicId) {
			return
		}

		const platform = routeId === VIEW_CREATOR_DATA_ROUTE_ID ? 'twitch' : 'youtube'
		const { getCreators, satisfyFn } = searchCreatorMap[platform]
		const creators = await getCreators(creatorMnemonicId)
		const matchingCreator = creators.find(satisfyFn(creatorMnemonicId))
		if (!matchingCreator) {
			return
		}
		dispatch(changeInput(moduleKey, ['assignees'], [matchingCreator]))

		const game = await getSuggestedGame(creatorMnemonicId)
		if (!game) {
			return
		}
		const formGamePayload = {
			label: game.name,
			id: Number(game.id),
			value: Number(game.id),
			image: addHttpsProtocol(game.boxArtTemplateUrl),
		}
		dispatch(changeInput(moduleKey, ['games'], [formGamePayload]))
	}
}

export default autoPopulateDare
