import { SET_PROMOTION_CODE_AMOUNT } from 'root/src/client/logic/project/actionIds'
import {
	formStoreLenses,
} from 'root/src/client/logic/form/lenses'

const { setFormInputsChild } = formStoreLenses

export default {
	[SET_PROMOTION_CODE_AMOUNT]: (state, { moduleKey, amount }) => setFormInputsChild(
		moduleKey,
		'promotionAmount',
		amount,
		state,
	),
}
