import { ADMIN_CAMPAIGN_CREATORS_REPORT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import adminCampaignCreatorsReportPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/adminCampaignCreatorsReportPayloadSchema'
import adminCampaignCreatorsReportResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/adminCampaignCreatorsReportResponseSchema'
import { userGroups, authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[ADMIN_CAMPAIGN_CREATORS_REPORT]: {
		payloadSchema: adminCampaignCreatorsReportPayloadSchema,
		responseSchema: adminCampaignCreatorsReportResponseSchema,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
	},
}
