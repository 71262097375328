import React from 'react'

export default ({ style: { textColor } }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 1074.92 540.25"
	>
		<path
			fill={textColor || '#FFF'}
			d="M 777.48027,538.51974 C 734.41348,533.98333 692.40723,518.608 654.76201,493.60168 631.50663,478.15407 597.77592,444.42333 582.3283,421.16796 c -20.87413,-31.4246 -35.98084,-68.66776 -42.15689,-103.9312 -2.38245,-13.60332 -2.63379,-4.26498 -2.78636,103.53011 l -0.16747,118.4821 -17.27976,-1.30441 C 423.00324,530.62689 339.22272,475.1319 296.5238,389.95837 c -12.30716,-24.54986 -20.75794,-50.65671 -25.65743,-79.2631 -2.0828,-12.16067 -2.42617,2.04483 -2.59241,107.24105 l -0.19146,121.25849 -17.2882,-1.32823 C 203.17134,534.20768 160.46984,519.98188 122.56899,495.1489 59.88818,454.07984 18.710738,392.97267 4.2722223,319.59707 -1.0461058,292.56959 -1.457378,249.07211 3.3664179,223.787 19.796683,137.6629 71.784285,67.909551 148.10055,29.592912 179.2991,13.928808 214.66518,4.3043625 251.72884,1.3917254 L 268.08256,0.10660717 268.33674,121.29178 c 0.13987,66.65187 0.58686,119.08255 0.9934,116.51269 C 281.68127,159.6982 317.10562,98.955275 376.16615,54.61053 417.63572,23.473614 465.67763,5.7808837 520.86381,1.3215376 L 537.21751,0 l 0.16747,119.36948 c 0.15248,108.55645 0.4052,118.01506 2.78637,104.41752 C 549.67833,169.50474 575.18063,121.11991 615.5984,80.681066 680.65718,15.588377 768.81138,-11.503427 857.74984,6.2623985 1021.9956,39.07126 1117.01,211.75659 1056.6304,367.72166 c -14.3125,36.97053 -34.7053,67.37218 -64.60616,96.31538 -44.50461,43.07898 -99.42514,68.51957 -161.10555,74.62783 -21.72056,2.15106 -31.90211,2.1234 -53.43845,-0.14499 z"
		/>
	</svg>
)
