export default {
	type: 'array',
	items: {
		properties: {
			brandName: { type: 'string' },
			brandWebsite: { type: 'string' },
			brandTwitter: { type: 'string' },
			brandLogo: {
				type: 'object',
				properties: {
					small: { type: 'string' },
					big: { type: 'string' },
				},
			},
			id: { type: 'string' },
			email: { type: 'string' },
		},
		additionalProperties: false,
		required: ['brandName', 'brandWebsite'],
	},
}
