/* eslint-disable react/destructuring-assignment */
import { Component } from 'react'

class ErrorBoundary extends Component {
	state = { hasError: false }

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		// Example "componentStack":
		//   in ComponentThatThrows (created by App)
		//   in ErrorBoundary (created by App)
		//   in div (created by App)
		//   in App
		console.error(error, info.componentStack)
	}

	render() {
		const { FallbackComponent, children } = this.props
		if (this.state.hasError) {
			return <FallbackComponent />
		}
		return children
	}
}

export default ErrorBoundary
