import pathOr from 'ramda/src/pathOr'
import assocPath from 'ramda/src/assocPath'
import __ from 'ramda/src/__'
import propEq from 'ramda/src/propEq'
import lt from 'ramda/src/lt'
import path from 'ramda/src/path'
import map from 'ramda/src/map'
import filter from 'ramda/src/filter'
import reject from 'ramda/src/reject'
import length from 'ramda/src/length'
import pipe from 'ramda/src/pipe'
import tokensSelector from 'root/src/client/logic/api/selectors/tokensSelector'

import moduleDescriptions from 'root/src/shared/descriptions/modules'
import fieldsPathDescriptionsSelector from 'root/src/client/logic/tab/selectors/fieldsPathDescriptionsSelector'
import pageItemLengthSelector from 'root/src/client/logic/list/selectors/pageItemLengthSelector'
import listModuleIdSelector from 'root/src/client/logic/header/selectors/listModuleIdSelector'

export const marketPlaceTabsSelector = () => path(['bannerTabs'], moduleDescriptions.TITLE_HEADER_MARKETPLACE_MODULE_ID)

export default (state, props) => {
	const listModuleId = listModuleIdSelector(state, props)
	const pageItemLength = pageItemLengthSelector(state, { moduleId: listModuleId })

	const tabs = pipe(
		() => fieldsPathDescriptionsSelector(state, props),
		map(pathOr('', __, moduleDescriptions)),
		map(assocPath(['fixedPayload', 'pageItemLength'], pageItemLength)),
	)()

	const tokens = tokensSelector(state)

	const isTwitchAuth = pipe(
		filter(propEq('platform', 'twitch')),
		length,
		lt(0),
	)(tokens)

	return isTwitchAuth ? tabs : reject(propEq('needsTwitchAuth', true), tabs)
}
