var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Provider } from 'root/src/shared/@types/providers';
import { loginWith } from 'root/src/client/v2/auth/helpers/loginWith';
import { getReadableProviderName } from 'root/src/client/v2/auth/helpers/providerHelpers';
import { useAuthentication } from 'root/src/client/v2/auth/hooks/useAuthentication';
import { useLocation } from 'root/src/client/v2/browser/hooks/useLocation';
import { ScopeSet } from 'root/src/shared/@types/auth';
import { useIsViewer } from 'root/src/client/v2/auth/hooks/useIsViewer';
import { useIsCreator } from 'root/src/client/v2/auth/hooks/useIsCreator';
import { useQueryParam } from 'use-query-params';
import { StringQueryParam } from 'root/src/client/v2/queryParams/helpers/params.helper';
import { z } from 'zod';
export const providerSearchValidatorSchema = z.union([
    z.literal(Provider.Twitch),
    z.literal(Provider.Google),
    z.literal(Provider.TikTok),
]);
// TODO centralize oauth to this fn
export const useSignInWithProvider = (params) => {
    const [providerSearchParam] = useQueryParam('provider', StringQueryParam({
        validator: providerSearchValidatorSchema,
    }));
    const { authentication } = useAuthentication();
    const { isCreator } = useIsCreator();
    const { isViewer } = useIsViewer();
    const isViewerScopeSet = isViewer && !isCreator;
    const scopeSet = params.scopeSet || isViewerScopeSet ? ScopeSet.viewer : ScopeSet.default;
    const provider = params.provider || providerSearchParam;
    if (!provider) {
        throw new Error('Provider is required');
    }
    const providerLabel = getReadableProviderName(provider);
    const { pathname } = useLocation();
    const state = {
        pathname,
        scopeSet,
    };
    const onSignIn = () => __awaiter(void 0, void 0, void 0, function* () {
        if (params.isLinking) {
            const token = authentication === null || authentication === void 0 ? void 0 : authentication.accessToken;
            if (!token) {
                throw new Error('Make sure you are logged in');
            }
            yield loginWith(provider)(state)(token);
            return;
        }
        yield loginWith(provider)(state)();
    });
    return {
        providerLabel,
        onSignIn,
        provider,
    };
};
