import { SUBMIT_FORM_DEFAULT_ON_SUCCESS } from 'root/src/client/logic/form/actionIds'
import { formStoreLenses } from 'root/src/client/logic/form/lenses'

const { setFormOutput } = formStoreLenses

export default {
	[SUBMIT_FORM_DEFAULT_ON_SUCCESS]: (state, { moduleKey, payload }) => setFormOutput(
		moduleKey, payload, state,
	),
}
