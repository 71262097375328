import { transactionTypes } from 'root/src/shared/constants/points'

export default {
	type: 'object',
	properties: {
		recipient: {
			type: 'string',
		},
		transactionTypes: {
			type: 'array',
			items: {
				type: 'string',
				enum: transactionTypes,
			},
		},
	},
}
