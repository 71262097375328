import { UPDATE_API_PROFILE } from 'root/src/client/logic/creatorData/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'

const { setProfileChild } = apiStoreLenses

export default {
	[UPDATE_API_PROFILE]: (state, payload) => {
		const recordId = currentRouteParamsRecordId(state)
		return setProfileChild(recordId, payload, state)
	},
}
