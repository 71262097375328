import {
	SOCIAL_ICONS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

import { CREATORS_PAGE_ROUTE_ID, PUBLISHERS_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok'
import socialMedia from 'root/src/shared/constants/socialMedia'

export default {
	[SOCIAL_ICONS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'socialIcons',
		pageContent: {
			icons: [
				{
					icon: faDiscord,
					href: socialMedia.discord,
					alt: 'discord',

				},
				{
					icon: faTwitter,
					href: socialMedia.twitter,
					alt: 'twitter',

				},
				{
					icon: faInstagram,
					href: socialMedia.instagram,
					alt: 'instagram',

				},
				{
					icon: faYoutube,
					href: socialMedia.youtube,
					alt: 'youtube',

				},
				{
					icon: faTwitch,
					href: socialMedia.twitch,
					alt: 'twitch',
				},
				{
					icon: faFacebook,
					href: socialMedia.facebook,
					alt: 'facebook',

				},
				{
					icon: faTiktok,
					href: socialMedia.tiktok,
					alt: 'tiktok',

				},
			],
			buttons: [{
				label: 'Dare Drop for Creators',
				routeId: CREATORS_PAGE_ROUTE_ID,
				type: 'primary',
			}, {
				label: 'Dare Drop for Publishers',
				routeId: PUBLISHERS_PAGE_ROUTE_ID,
				type: 'secondary',
			}],
		},
	},
}
