import pipe from 'ramda/src/pipe'
import pathOr from 'ramda/src/pathOr'
import identity from 'ramda/src/identity'
import applyTo from 'ramda/src/applyTo'
import routeDescriptionsObj from 'root/src/shared/descriptions/routes'

export default ({ routeId, recordId }) => pipe(
	pathOr(identity, [routeId, 'recordIdParse']),
	applyTo(recordId),
)(routeDescriptionsObj)
