import FAQ from 'root/src/shared/descriptions/routes/FAQ'
import termsOfService from 'root/src/shared/descriptions/routes/termsOfService'
import privacyPolicy from 'root/src/shared/descriptions/routes/privacyPolicy'
import cookiePolicy from 'root/src/shared/descriptions/routes/cookiePolicy'
import copyrightPolicy from 'root/src/shared/descriptions/routes/copyrightPolicy'
import rulesOfUse from 'root/src/shared/descriptions/routes/rulesOfUse'
import auth from 'root/src/shared/descriptions/routes/auth'
import projects from 'root/src/shared/descriptions/routes/projects'
import accountSettings from 'root/src/shared/descriptions/routes/accountSettings'
import creators from 'root/src/shared/descriptions/routes/creators'
import filterDares from 'root/src/shared/descriptions/routes/filterDares'
import admin from 'root/src/shared/descriptions/routes/admin'
import brand from 'root/src/shared/descriptions/routes/brand'
import smiteGiveaway from 'root/src/shared/descriptions/routes/smiteGiveaway'
import renfieldRules from 'root/src/shared/descriptions/routes/renfieldRules'
import { newRoutes } from 'root/src/shared/descriptions/routes/new-routes'
import { dashboardDemo } from 'root/src/shared/descriptions/routes/demoDashboard'

const routeDescriptions = {
	...FAQ,
	...auth,
	...projects,
	...termsOfService,
	...privacyPolicy,
	...cookiePolicy,
	...copyrightPolicy,
	...rulesOfUse,
	...accountSettings,
	...creators,
	...filterDares,
	...admin,
	...brand,
	...smiteGiveaway,
	...renfieldRules,
	...newRoutes,
	...dashboardDemo,
}

export default routeDescriptions
