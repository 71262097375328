export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		projectId: { type: 'string' },
		isTwitchPanel: { type: 'boolean' },
	},
	required: [],
	additionalProperties: false,
}
