var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef } from 'react';
import { fetcher } from 'root/src/client/v2/common/helpers/fetcher';
/**
 * This hook is used to control fetcher function
 * and abort fetch request on component unmount
 */
export const useFetch = () => {
    const signal = useRef(new AbortController());
    const controlledFetcher = useCallback((config) => __awaiter(void 0, void 0, void 0, function* () {
        return fetcher(config);
    }), []);
    // Abort fetch on component unmount
    useEffect(() => {
        return () => {
            signal.current.abort();
        };
    }, [signal]);
    return {
        fetcher: controlledFetcher,
    };
};
