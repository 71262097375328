import {
	PLEDGE_PROJECT_EXTERNAL,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

import pledgeProjectExternalPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/pledgeProjectExternalPayloadSchema'
import pledgeProjectExternalResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/pledgeProjectExternalResponseSchema'

export default {
	[PLEDGE_PROJECT_EXTERNAL]: {
		endpointType: recordEndpointType,
		authentication: authenticationTypes.optional,
		recordType: project,
		payloadSchema: pledgeProjectExternalPayloadSchema,
		responseSchema: pledgeProjectExternalResponseSchema,
	},
}
