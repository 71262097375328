import {
	NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Success!',
			paragraphs: [
				'Thank you for your contribution!',
				`Remember this is just a pledge. You’ll only be charged if the creator delivers.
				If they don’t deliver, you won’t pay a thing!`,
			],
			socialSharing: true,
		},
	},
}
