import { RewardEndpoint } from 'root/src/shared/rewards/constants'
import { addBrandCustomRewardEndpoint } from 'root/src/shared/rewards/endpoints/addBrandCustomReward.endpoint'
import { editBrandRewardsEndpoint } from 'root/src/shared/rewards/endpoints/editBrandRewards.endpoint'
import { getBrandCustomRewardsEndpoint } from 'root/src/shared/rewards/endpoints/getBrandCustomRewards.endpoint'
import { getBrandRewardsEndpoint } from 'root/src/shared/rewards/endpoints/getBrandRewards.endpoint'
import { getCreatorInitialRewardsEndpoint } from 'root/src/shared/rewards/endpoints/getCreatorInitialRewards'
import { getDropKeyRewardsEndpoint } from 'root/src/shared/rewards/endpoints/getDropKeyRewards.endpoint'
import { getRewardEndpoint } from 'root/src/shared/rewards/endpoints/getReward.endpoint'
import { getRewardsEndpoint } from 'root/src/shared/rewards/endpoints/getRewards.endpoint'
import { getRewardsForCreatorEndpoint } from 'root/src/shared/rewards/endpoints/getCreatorRewards.endpoint'
import { saveCodeRewardsEndpoint } from 'root/src/shared/rewards/endpoints/saveCodeRewards.endpoint'
import { saveKeyRewardsEndpoint } from 'root/src/shared/rewards/endpoints/saveKeyRewards.endpoint'
import { useRewardsEndpoint } from 'root/src/shared/rewards/endpoints/useRewards.endpoint'
import { setDropDeliveredRewardsUsedEndpoint } from 'root/src/shared/rewards/endpoints/setDropDeliveredRewardsUsed.endpoint'
import { getRewardsForViewersEndpoint } from 'root/src/shared/rewards/endpoints/getViewersRewards.endpoint'
import { getDecryptedRewardEndpoint } from 'root/src/shared/rewards/endpoints/getDecryptedReward.endpoint'
import { transferRewardsEndpoint } from 'root/src/shared/rewards/endpoints/transferRewards.endpoint'
import { saveRewardsBulkEndpoint } from 'root/src/shared/rewards/endpoints/saveRewardsBulkEndpoint'
import { createBundleEndpoint } from 'root/src/shared/rewards/endpoints/createBundle.endpoint'
import { getBrandBundlesEndpoint } from 'root/src/shared/rewards/endpoints/getBrandBundles.endpoint'

export const rewardEndpoints = {
	// Fetch single reward by rewardId & brandId
	[RewardEndpoint.GET_REWARD]: getRewardEndpoint,
	// Fetch all rewards from all brands
	[RewardEndpoint.GET_REWARDS]: getRewardsEndpoint,
	// Fetch rewards by Brand
	[RewardEndpoint.GET_BRAND_REWARDS]: getBrandRewardsEndpoint,
	// Fetch custom reward by Brand
	[RewardEndpoint.GET_BRAND_CUSTOM_REWARDS]: getBrandCustomRewardsEndpoint,
	// Add Brand reward
	[RewardEndpoint.ADD_BRAND_CUSTOM_REWARD]: addBrandCustomRewardEndpoint,
	// Edit Brand rewards
	[RewardEndpoint.EDIT_BRAND_REWARDS]: editBrandRewardsEndpoint,
	// Fetch Creator rewards
	[RewardEndpoint.GET_REWARDS_FOR_CREATOR]: getRewardsForCreatorEndpoint,
	// Fetch viewers rewards
	[RewardEndpoint.GET_REWARDS_FOR_VIEWERS]: getRewardsForViewersEndpoint,
	// Fetch Key Rewards of a given Drop
	[RewardEndpoint.GET_DROP_KEY_REWARDS]: getDropKeyRewardsEndpoint,
	// Use certain amount of rewards
	[RewardEndpoint.USE_REWARDS]: useRewardsEndpoint,
	// Process codes from uploaded file
	[RewardEndpoint.SAVE_CODE_REWARDS]: saveCodeRewardsEndpoint,
	// Process keys from uploaded file
	[RewardEndpoint.SAVE_KEY_REWARDS]: saveKeyRewardsEndpoint,
	// Fetch all initial rewards for creator
	[RewardEndpoint.GET_CREATOR_INITIAL_REWARDS]: getCreatorInitialRewardsEndpoint,
	// Edit Creator rewards
	[RewardEndpoint.SET_DROP_DELIVERED_REWARDS_USED]: setDropDeliveredRewardsUsedEndpoint,
	// Fetch reward (returns decrypted data)
	[RewardEndpoint.GET_DECRYPTED_REWARD]: getDecryptedRewardEndpoint,
	[RewardEndpoint.TRANSFER_REWARDS]: transferRewardsEndpoint,
	[RewardEndpoint.SAVE_REWARDS_BULK]: saveRewardsBulkEndpoint,
	[RewardEndpoint.CREATE_BUNDLE]: createBundleEndpoint,
	[RewardEndpoint.GET_BRAND_REWARDS]: getBrandBundlesEndpoint,
}
