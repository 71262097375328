import { ModalTitle } from 'root/src/client/v2/common/components/modal/ModalTitle';
import { BulletItem } from 'root/src/client/v2/common/components/list/BulletItem';
import { BulletList } from 'root/src/client/v2/common/components/list/BulletList';
import { BottomText } from 'root/src/client/v2/creatorOnboarding/ModalBottomText';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls';
import Link from 'root/src/client/web/base/Link';
import { GET_STARTED_VIDEO } from 'root/src/client/v2/creatorOnboarding/constants';
import { ModalLayout } from 'root/src/client/v2/common/layouts/ModalLayout';
import { VimeoVideo } from 'root/src/client/v2/video/components/VimeoVideo';
export function GetFreeKeysModal() {
    return (<ModalLayout className="sm:w-[700px]">
			<ModalTitle>Do Drops to Get Keys</ModalTitle>
			<Video />
			<Instructions />
			<BottomText />
		</ModalLayout>);
}
function Video() {
    return (<VimeoVideo video={GET_STARTED_VIDEO}/>);
}
function Instructions() {
    return (<BulletList>
			<BulletItem color={colorsNew.teal}>
				To earn keys for your audience, go to the <DropsLink /> page (link in nav bar) and select a game you want to play.
				Accept one of the dares from that Drop’s page.
			</BulletItem>
			<BulletItem color={colorsNew.primary}>
				Make content for your community on your connected channel (Twitch, YouTube, or TikTok).
			</BulletItem>
			<BulletItem color={colorsNew.yellow}>
				On your Dare Drop profile Deliver a clip or video of you completing the dare to get the rewards listed.
			</BulletItem>
		</BulletList>);
}
function DropsLink() {
    const { hide } = useModal();
    return (<Link onClick={hide} href={routeUrls.drops()}>Drops</Link>);
}
