var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest';
import { ADD_PUBLISHER_BANNED_CREATORS, } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID, } from 'root/src/shared/descriptions/modules/moduleIds';
import toast from 'react-hot-toast';
import { colorsNew } from 'root/src/client/web/commonStyles';
import displayModal from 'root/src/client/logic/modal/actions/displayModal';
import { publisherBan } from 'root/src/shared/descriptions/endpoints/recordTypes';
export const publisherAddCreatorToBanListSubmitHandler = (formData) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    const { banListType, dropEvent, mnemonicIds, platform, } = formData;
    if (!mnemonicIds || !mnemonicIds.length) {
        throw { mnemonicIds: 'Please specify at least one name' };
    }
    const formattedMnemonicIds = mnemonicIds
        .split(/[;,\n]+/g)
        .filter(x => Boolean(x.trim())) || [];
    const { body, error } = yield dispatch(apiRequest(ADD_PUBLISHER_BANNED_CREATORS, {
        mnemonicIds: formattedMnemonicIds,
        platform: platform.value,
        banListType: banListType.value,
        dropEventId: dropEvent === null || dropEvent === void 0 ? void 0 : dropEvent.id,
    }, undefined, PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID, {
        [PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID]: {
            constantListStoreKeyValue: publisherBan,
            constantListStoreKey: true,
        },
    }));
    if (error) {
        throw { mnemonicIds: error.message };
    }
    if (body.valid) {
        toast.success(`Added ${body.valid} creators to the ban list.`, {
            position: 'bottom-left',
            style: {
                background: colorsNew.primary,
                color: colorsNew.white,
            },
        });
    }
    if (body.duplicates) {
        toast.error(`Found ${body.duplicates} duplicates.`, {
            position: 'bottom-left',
            style: {
                background: colorsNew.primary,
                color: colorsNew.white,
            },
        });
    }
    if (body.notExists) {
        toast.error(`We couldn't find ${body.notExists} users.`, {
            position: 'bottom-left',
            style: {
                background: colorsNew.primary,
                color: colorsNew.white,
            },
        });
    }
    dispatch(displayModal(undefined));
    return body;
});
