import {
	PLEDGE_SUCCESS_PAGE_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[PLEDGE_SUCCESS_PAGE_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Success!',
			paragraphs: [
				'Thank you for your contribution! We\'ll email you when the video is live.',
				`Remember this is just a pledge. You’ll only be charged if the creator delivers.
				If they don’t deliver, you won’t pay a thing!`,
			],
			socialSharing: true,
		},
	},
}
