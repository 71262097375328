import { CLEAR_MODAL } from 'root/src/client/logic/static/actionIds'
import { routeStoreLenses } from 'root/src/client/logic/route/lenses'
import dissocPath from 'ramda/src/dissocPath'

const { setRoute, viewRoute } = routeStoreLenses

export default {
	[CLEAR_MODAL]: (state) => {
		const currentRoute = viewRoute(state)
		return setRoute(dissocPath(['query', 'modal'], currentRoute), state)
	},
}
