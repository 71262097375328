var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useFetch } from 'root/src/client/v2/common/hooks/useFetch';
import outputs from 'root/cfOutput';
import { HTTPMethod } from 'root/src/client/v2/common/helpers/fetcher';
import { getCookie } from 'root/src/client/v2/browser/helpers/cookies';
import toast from 'react-hot-toast';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { useCallback } from 'react';
import { DareDropApiError } from 'root/src/client/v2/common/api/apiError';
export const useDareDropApi = () => {
    const { fetcher } = useFetch();
    const controlledFetcher = useCallback((config, disableToast = false) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield fetcher({
            url: `https://api.${outputs.domainName}`,
            body: JSON.stringify({
                payload: config.payload,
                endpointId: config.endpointId,
                authentication: getCookie('accessToken'),
            }),
            method: HTTPMethod.POST,
        });
        const { error, statusCode, body } = res;
        if (statusCode < 200 || statusCode >= 300) {
            const errorMessage = getErrorMessage(error);
            if (!disableToast) {
                toast.error(errorMessage, {
                    style: {
                        color: '#ddd',
                        backgroundColor: colorsNew.red,
                    },
                });
            }
            console.error(statusCode, config.endpointId, config.payload);
            throw new DareDropApiError({ errorMessage, cause: error, statusCode });
        }
        return body;
    }), [fetcher]);
    return {
        fetcher: controlledFetcher,
    };
};
export function getErrorMessage(error) {
    if (error instanceof Error) {
        return error.message;
    }
    if (typeof error === 'string') {
        return error;
    }
    const isErrorObject = typeof error === 'object' && error !== null && 'message' in error;
    if (isErrorObject && typeof error.message === 'string') {
        return error.message;
    }
    if (isErrorObject && typeof error.message === 'object' && error.message !== null && Object.values(error.message).length > 0) {
        return Object.values(error.message)[0];
    }
    return 'Something went wrong, please try again later';
}
