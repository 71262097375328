import {
	DROP_IS_OVER_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[DROP_IS_OVER_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'Drop is over',
		modalContent: {
			routeId: routeUrls.drops(),
		},
	},
}
