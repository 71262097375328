export default (template, input) => {
	if (input) {
		return {
			'Fn::Sub': [template, input],
		}
	}
	return {
		'Fn::Sub': template,
	}
}
