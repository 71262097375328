export default {
	type: 'object',
	properties: {
		items: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					id: { type: 'string' },
					name: { type: 'string' },
					amount: { type: 'number' },
					daysValid: { type: 'number' },
					expDate: { type: 'string' },
					created: { type: 'string' },
					modified: { type: 'string' },
				},
			},
		},
	},
}
