import {	GET_SHORT_TERM_AUTH_TOKEN } from 'root/src/shared/descriptions/endpoints/endpointIds'
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export const payloadSchema = emptyPayloadSchema

export default {
	[GET_SHORT_TERM_AUTH_TOKEN]: {
		endpointType: userDataEndpointType,
		authentication: authenticationTypes.authenticated,
	},
}
