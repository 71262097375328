import { TWITCH_EXTENSIONS_INFO_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
// import EmotesImage from 'root/src/client/public/ilu-emotes.png'
import EmotesImageDesktop from 'root/src/client/public/ilu-emotes-desktop1.png'
import EmotesImageMobile from 'root/src/client/public/ilu-emotes-mobile1.png'
import HelmOverlayDesktop from 'root/src/client/public/Helm-Overlay-desktop.jpg'
import HelmOverlayMobile from 'root/src/client/public/Helm-Overlay-mobile.jpg'
import PanelSSDesktop from 'root/src/client/public/panel-ss-2-desktop.jpg'
import PanelSSMobile from 'root/src/client/public/panel-ss-2-mobile.jpg'
import AudioVideoMobile from 'root/src/client/public/audio-video-mobile.mp4'
import AudioVideoDesktop from 'root/src/client/public/audio-video-desktop.mp4'
import { CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import { panelVersion } from 'root/extensions/twitchPanel/extConstants'

export default {
	[TWITCH_EXTENSIONS_INFO_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'infoItemsModule',
		pageContent: {
			title: 'Show Your Fans Some Love',
			subtitle: 'Add the Dare Drop Extension to Twitch to build viewer engagement and recognize viewers who pledge and Dare you.',
			headerImg: {
				desktop: EmotesImageDesktop,
				mobile: EmotesImageMobile,
			},
			headerButton: {
				text: 'Get Started',
				href: 'https://dashboard.twitch.tv/extensions/search?q=Dare%20Drop',
			},
		},
		infoItems: [
			{
				image: {
					desktop: PanelSSDesktop,
					mobile: PanelSSMobile,
				},
				textPosition: 'right',
				heading: 'Do It All Right On Twitch',
				subHeading: 'Share your active dares, gather pledges, and build hype with the Top Pledger Leaderboard.',
				button: {
					text: 'Add to Twitch',
					href: `https://dashboard.twitch.tv/extensions/bajpzyo9aekibuv6pr443am4vr10l9-${panelVersion}`,
					icon: faTwitch,
				},
			},
			{
				image: {
					desktop: AudioVideoDesktop,
					mobile: AudioVideoMobile,
				},
				textPosition: 'left',
				heading: 'Add Audio Alerts For Your Memes',
				subHeading: 'Add custom alerts to play on your stream when viewers dare you or contribute to dares. You decide what audio to play and how much viewers pay. Configurable with any braodcast tool, e.g. OBS.',
				button: {
					text: 'Configure Audio',
					routeId: CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID,
				},
			},
			{
				image: {
					desktop: HelmOverlayDesktop,
					mobile: HelmOverlayMobile,
				},
				textPosition: 'right',
				heading: 'Onscreen Fan Recognition',
				subHeading: 'Recognize your supporters with ticker-style banners that display over your stream when you receive contributions & dares on Dare Drop. Easily setup on OBS at the same time you configure audio alerts.',
				button: {
					text: 'OBS Integrations',
					routeId: CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID,
				},
			},
		],
	},
}
