import { GET_DASHBOARD_CREATOR_DARES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	authenticationTypes,
	userGroups,
} from 'root/src/shared/constants/auth'
import getDashboardTablesPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDashboardCreatorDaresPayloadSchema'
import getDashboardCreatorsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getDashboardCreatorDaresResponseSchema'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

const payloadSchema = getDashboardTablesPayloadSchema
const responseSchema = getDashboardCreatorsResponseSchema

export default {
	[GET_DASHBOARD_CREATOR_DARES]: {
		endpointType: listEndpointType,
		recordType: project,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		payloadSchema,
		responseSchema,
	},
}
