import reduce from 'ramda/src/reduce'
import propOr from 'ramda/src/propOr'
import assoc from 'ramda/src/assoc'
import pipe from 'ramda/src/pipe'
import {
	VIEW_CREATOR_DATA_ROUTE_ID,
	// VIEW_PROJECT_ROUTE_ID,
	FAQ_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import canonicalSelector from 'root/src/client/logic/route/selectors/canonicalSelector'
import displayNameSelector from 'root/src/client/logic/creatorData/selectors/displayNameSelector'
// import projectTitleSelector from 'root/src/client/logic/project/selectors/projectTitleSelector'
// import projectDescriptionSelector from
// 'root/src/client/logic/project/selectors/projectDescriptionSelector'

const defaultMeta = {
	title: 'Dare Drop',
	description: 'Dare your favorite creator to do something awesome.',
}

const routesMap = {
	[VIEW_CREATOR_DATA_ROUTE_ID]: {
		payloadProps: [['displayName', displayNameSelector]],
		templateFn: ({ displayName }) => ({
			title: `Dare Drop: ${displayName || 'creator'}'s Profile`,
			description: `Watch ${displayName || 'creator'}'s Completed Dares`,
		}),
	},

	// TODO @next it doesn't works in current shape
	//    if we want to have project, we need to have moduleId in props
	// [VIEW_PROJECT_ROUTE_ID]: {
	// 	payloadProps: [
	// 		['projectTitle', projectTitleSelector],
	// 		['projectDescription', projectDescriptionSelector],
	// 	],
	// 	templateFn: ({ projectTitle, projectDescription }) => ({
	// 		title: projectTitle,
	// 		description: projectDescription,
	// 	}),
	// },
	[FAQ_ROUTE_ID]: {
		templateFn: () => ({
			title: 'Frequently Asked Questions',
			description: 'Find answers to frequently asked questions about how to Dare creators to meet challenges, and how to deliver on your Dare Drop Dares.',
		}),
	},
}

export default (state) => {
	const { payloadProps, templateFn } = routesMap[currentRouteId(state)] || {}
	const data = templateFn ? pipe(
		reduce((acc, [prop, fn]) => assoc(prop, fn(state), acc), {}),
		templateFn,
	)(payloadProps || []) : {}

	return {
		title: propOr(defaultMeta.title, 'title', data),
		description: propOr(defaultMeta.description, 'description', data),
		canonical: canonicalSelector(state),
	}
}
