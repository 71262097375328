import { GET_ALERTS_CONFIG } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { alertsConfigKey } from 'root/src/shared/constants/keys'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import alertsConfigResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/alertsConfigResponseSchema'
import { alertsConfig } from './recordTypes'

export const payloadSchema = {
	type: 'object',
	properties: {
		token: {
			type: 'string',
		},
	},
	required: [],
	additionalProperties: false,
}
export const responseSchema = alertsConfigResponseSchema

export default {
	[GET_ALERTS_CONFIG]: {
		endpointType: userDataEndpointType,
		recordType: alertsConfig,
		payloadSchema,
		authentication: authenticationTypes.authenticated,
		storeKey: alertsConfigKey,
		responseSchema,
	},
}
