import { ADD_TOKEN } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import addTokenPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addTokenPayloadSchema'

const payloadSchema = addTokenPayloadSchema

export default {
	[ADD_TOKEN]: {
		payloadSchema,
		endpointType: gatewayEndpointType,
	},
}
