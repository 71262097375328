import { GET_SIGNED_UP_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import getSignedUpCreatorsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getSignedUpCreatorsResponseSchema'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import getSignedUpCreatorsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getSignedUpCreatorsPayloadSchema'

export default {
	[GET_SIGNED_UP_CREATORS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		responseSchema: getSignedUpCreatorsResponseSchema,
		requestSchema: getSignedUpCreatorsPayloadSchema,
	},
}
