import isPastToday from 'root/src/shared/util/isPastToday';
import { dropEventStatusEnum } from 'root/src/shared/constants/dropEvent';
export const isDropComingSoon = (drop) => {
    if (isUserDataDrop(drop)) {
        return !isPastToday(drop.liveDate) && drop.status === dropEventStatusEnum.PENDING_GO_LIVE_DATE;
    }
    return !isPastToday(drop.liveDate)
        && drop.approved
        && drop.isUserActivated
        && drop.subscriptionStatus === dropEventStatusEnum.ACTIVE;
};
const isUserDataDrop = (drop) => {
    return !('approved' in drop && 'isUserActivated' in drop && 'subscriptionStatus' in drop);
};
