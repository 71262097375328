import { COUPON_FORM_ADMIN_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import addCouponPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/addCouponPayloadSchema'

export default {
	[COUPON_FORM_ADMIN_MODULE_ID]: {
		moduleType: 'form',
		formType: 'coupon',
		schema: addCouponPayloadSchema,
		title: 'Add Coupon',
		fields: [
			{
				fieldId: 'name',
				inputType: 'text',
				labelFieldText: [
					{
						text: 'Name',
						required: true,
					},
				],
			},
			{
				fieldId: 'amount',
				inputType: 'amountNumber',
				labelFieldText: [
					{
						text: 'Amount',
						required: true,
					},
				],
			},
			{
				fieldId: 'daysValid',
				inputType: 'number',
				labelFieldText: [
					{
						text: 'Days Valid',
						required: true,
					},
				],
			},

		],
		submits: [
			{
				label: 'Add Coupon',
				buttonType: 'primaryButton',
			},
		],
	},
}
