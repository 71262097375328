import {
	UNBAN_USER_IP,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import payloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/adminBanUserIpPayloadSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[UNBAN_USER_IP]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
