import contains from 'ramda/src/contains';
import { VIEW_PROJECT_ROUTE_ID, VIEW_CREATOR_DATA_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds';
export function addTrack(event, data = null) {
    // eslint-disable-next-line no-undef
    // @ts-ignore
    if (!FACEBOOK_PIXEL_ID)
        return;
    window.fbq('track', event, data);
}
export default function facebookPixel(routeId) {
    if (contains('ROUTE_MOCK_', routeId))
        return;
    switch (routeId) {
        case VIEW_PROJECT_ROUTE_ID:
            addTrack('ViewContent');
            break;
        case VIEW_CREATOR_DATA_ROUTE_ID:
            addTrack('ViewContent');
            break;
        default:
    }
}
