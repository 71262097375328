import { VERIFY_ACCOUNT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import verifyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/verifyPayloadSchema'

const payloadSchema = verifyPayloadSchema

export default {
	[VERIFY_ACCOUNT]: {
		payloadSchema,
		endpointType: gatewayEndpointType,
	},
}
