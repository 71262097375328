import { setCookie } from 'root/src/client/v2/browser/helpers/cookies'

export default ({
	accessToken, refreshToken, email, userId, accessTokenExp, refreshTokenExp, groups, parentUserId,
}) => {
	if (email) {
		setCookie('email', email, accessTokenExp)
	}
	if (refreshToken) {
		setCookie('refreshToken', refreshToken, refreshTokenExp)
	}
	setCookie('userId', userId, accessTokenExp)
	if (parentUserId) {
		setCookie('parentUserId', parentUserId, accessTokenExp)
	}
	setCookie('accessToken', accessToken, accessTokenExp)
	setCookie('groups', groups, accessTokenExp)
}
