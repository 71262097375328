import compose from 'ramda/src/compose'

import { UPDATE_COUPON_ON_SUCCESS } from 'root/src/client/logic/coupon/actionIds'
import { apiStoreLenses, idProp } from 'root/src/client/logic/api/lenses'
import { coupon } from 'root/src/shared/descriptions/endpoints/recordTypes'
import getLensFromType from 'root/src/client/logic/api/util/getLensFromType'

export default {
	[UPDATE_COUPON_ON_SUCCESS]: (state, { body }) => {
		const recordId = idProp(body)
		const setRecordsChild = getLensFromType('set', 'child', coupon, apiStoreLenses)

		return compose(
			setRecordsChild(recordId, body),
		)(state)
	},
}
