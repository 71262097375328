import { BRANDS_CREATORS_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import Publishers3Blob from 'root/src/client/public/backgroundBlobs/for-publishers-3.svg'
import { ltGtMediaQuery, ltMdMediaQuery, ltSmMediaQuery } from 'root/src/client/web/commonStyles'
import { ourSalesEmail } from 'root/src/shared/constants/mail'

export default {
	[BRANDS_CREATORS_SECTION]: {
		moduleType: 'static',
		staticPageType: 'section',
		backgroundData: [
			{
				position: 'left',
				image: Publishers3Blob,
				style: {
					transform: 'translate(0, -35%)',
					[ltMdMediaQuery]: {
						transform: 'translate(0%, -25%)',
					},
					[ltGtMediaQuery]: {
						transform: 'translate(-50px, -12%)',
					},
				},
				hoverLinks: [
					{
						text: 'Softwerker',
						link: 'https://daredrop.com/creators/softwerker',
						styles: {
							top: 536.5,
							left: 487,
							width: 194,
							height: 194,
							[ltMdMediaQuery]: {
								top: 372,
								left: 338.5,
								width: 134,
								height: 134,
							},
							[ltGtMediaQuery]: {
								top: 260.5,
								left: 235.5,
								width: 95,
								height: 95,
							},
							[ltSmMediaQuery]: {
								display: 'none',
							},
						},
					},
					{
						text: 'canceldota',
						link: 'https://daredrop.com/creators/canceldota',
						styles: {
							top: 678,
							left: 170,
							width: 103,
							height: 103,
							[ltMdMediaQuery]: {
								top: 471,
								left: 118,
								width: 72,
								height: 72,
							},
							[ltGtMediaQuery]: {
								top: 329.5,
								left: 80,
								width: 51,
								height: 51,
							},
							[ltSmMediaQuery]: {
								display: 'none',
							},
						},
					},
					{
						text: 'SakiSakuraTV',
						link: 'https://daredrop.com/creators/sakisakuraTV',
						styles: {
							top: 880,
							left: 382,
							width: 61,
							height: 61,
							[ltMdMediaQuery]: {
								top: 610.5,
								left: 265,
								width: 41,
								height: 41,
							},
							[ltGtMediaQuery]: {
								top: 427.5,
								left: 185,
								width: 27.5,
								height: 27.5,
							},
							[ltSmMediaQuery]: {
								display: 'none',
							},
						},
					},
				],
			},
		],
		pageContent: {
			heading: 'You’ve never worked with',
			subHeading: 'Creators like this',
			text: 'Dare Drop creators have highly engaged fans, and they love a challenge. No fraud or key resellers, just genuine passion and a keyed-in audience.',
			position: 'right',
			buttons: [
				{
					label: 'Add Your Game to Dare Drop',
					// TEMPORARY FIX UNTIL BRAND ONBOARDING MVP
					href: `mailto:${ourSalesEmail}`,
					// routeId: (state) => {
					// 	const isAuthenticated = path(['app', 'authentication'], state)
					// 	return isAuthenticated ? CAMPAIGNS_PAGE_ROUTE_ID : SIGN_UP_ROUTE_ID
					// },
					icon: faChevronRight,
				},
			],
		},
	},
}
