export default {
	type: 'object',
	properties: {
		id: { type: 'string' },
		keyType: { type: 'object' },
		platform: { type: 'object' },
		region: { type: 'object' },
		game: {
			type: 'object',
			errorMessage: {
				type: 'Game is required',
				required: 'Game is required',
			},
		},
	},
	required: ['id', 'keyType', 'platform', 'region', 'game'],
	additionalProperties: false,
}
