export default {
	type: 'object',
	properties: {
		error: { type: 'string' },
		error_description: { type: 'string' },
		code: { type: 'string' },
		authuser: { type: 'string' },
		prompt: { type: 'string' },
		scope: { type: 'string' },
		state: { type: ['object', 'string'] },
		accessToken: { type: 'string' },
		refreshToken: { type: 'string' },
		idToken: { type: 'string' },
		platform: { type: 'string' },
		platformId: { type: 'string' },
		isRegistered: { type: 'string' },
	},
	additionalProperties: true,
}
