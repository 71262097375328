import endpointDescriptions from 'root/src/shared/descriptions/endpoints'

import {	endpointDescriptionLenses } from 'root/src/shared/descriptions/endpoints/lenses'
import hasProperAuthSelector from 'root/src/client/logic/auth/selectors/hasProperAuthSelector'

const { viewAuthentication, viewAuthenticationRole } = endpointDescriptionLenses

export default (endpointId, state) => {
	const reqAuth = viewAuthentication(endpointId, endpointDescriptions)
	const reqAuthRoles = viewAuthenticationRole(endpointId, endpointDescriptions)
	return hasProperAuthSelector(state, { reqAuth, reqAuthRoles })
}
