import isAuthenticated from 'root/src/client/logic/auth/selectors/isAuthenticated'
import {
	LOGIN_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import myProfileSelector from 'root/src/client/logic/route/selectors/myProfileSelector'

export default state => (
	isAuthenticated(state)
		? myProfileSelector(state)
		: { routeId: LOGIN_ROUTE_ID, routeParams: {} }
)
