import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { SignInWithProvidersModal } from 'root/src/client/v2/auth/features/SignInWithProvidersModal';
import { useHasTokens } from 'root/src/client/v2/userData/hooks/useHasTokens';
import { useIsMobileResolution } from 'root/src/client/v2/browser/hooks/useIsMobileResolution';
import { useAuthentication } from 'root/src/client/v2/auth/hooks/useAuthentication';
import { useUserData } from 'root/src/client/v2/userData/hooks/useUserData';
import { useIsCreator } from 'root/src/client/v2/auth/hooks/useIsCreator';
import { useIsViewer } from 'root/src/client/v2/auth/hooks/useIsViewer';
export function useGetStartedButton() {
    const modal = useModal();
    const showSignInWithProvidersModal = () => modal.show(<SignInWithProvidersModal />);
    const { authentication } = useAuthentication();
    const { isCreator } = useIsCreator();
    const { isViewer } = useIsViewer();
    const { isLoading } = useUserData();
    const { hasTokens } = useHasTokens();
    const { isMobileResolution } = useIsMobileResolution();
    const isTargetGroup = isCreator || isViewer;
    const displayGetStartedButton = authentication
        && !isLoading
        && !isMobileResolution
        && !hasTokens
        && isTargetGroup;
    return {
        showSignInWithProvidersModal,
        displayGetStartedButton,
    };
}
