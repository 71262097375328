import {
	PAYPAL_DROP_EVENT_ORDER,
} from 'root/src/shared/descriptions/modules/moduleIds'
import ddLogo from 'root/src/client/public/DDlogoWhiteBigTransparent.png'

export default {
	[PAYPAL_DROP_EVENT_ORDER]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			image: ddLogo,
			paragraphs: [
				'Thank you, the campaign will be active after approval by the administrator',
			],
		},
	},
}
