import compose from 'ramda/src/compose'
import set from 'ramda/src/set'
import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'
import reduce from 'ramda/src/reduce'
import dissoc from 'ramda/src/dissoc'

import { CHANGE_INPUT } from 'root/src/client/logic/form/actionIds'

export default {
	[CHANGE_INPUT]: (
		state,
		{ moduleKey, fieldPath, value, customPath, clearFormInputs },
	) => {
		const path = lensPath(fieldPath)
		const dirty = value !== ''
		let stateMod = state
		if (clearFormInputs) {
			stateMod = reduce(
				(acc, prop) => over(lensPath(['form', moduleKey, 'formInputs']), dissoc(prop), acc),
				state,
				clearFormInputs,
			)
		}
		if (customPath) {
			return over(lensPath(customPath), set(path, value))(stateMod)
		}
		return compose(
			over(lensPath(['form', moduleKey, 'formInputs']), set(path, value)),
			over(
				lensPath(['form', moduleKey, 'fieldData']),
				set(lensPath([...fieldPath, 'dirty']), dirty),
			),
		)(stateMod)
	},
}
