export default {
	type: 'object',
	properties: {
		daresCompleted: { type: 'number' },
		daresAccepted: { type: 'number' },
		uniqCreators: { type: 'number' },
		totalAvgCcv: { type: 'number' },
		totalWatchTime: { type: 'number' },
		totalStreamTime: { type: 'number' },
	},
}
