import { memo, Fragment, useState, useRef } from 'react'
import { navLinkStyle, gtSmMediaQuery, colorsNew, boxShadow } from 'root/src/client/web/commonStyles'
import map from 'ramda/src/map'
import addIndex from 'ramda/src/addIndex'
import Menu from 'root/src/client/web/base/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Link from 'root/src/client/web/base/Link'
import LabelOrIcon from 'root/src/client/web/base/LabelOrIcon'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { identity } from 'remeda'

const styles = {
	root: {
		padding: 0,
		height: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start',
		alignItems: 'start',
		backgroundColor: colorsNew.dark2,
	},
	arrow: {
		transition: '.3s',
		display: 'none',
		[gtSmMediaQuery]: {
			display: 'inline-block',
		},
	},
	arrowOpen: {
		transform: 'rotateX(180deg)',
	},
	centeredButton: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
	},
	menu: {
		position: 'absolute',
		top: 80,
		right: 0,
	},
	line: {
		position: 'relative',
		height: 1,
		opacity: '40%',
		width: '80%',
		left: '10%',
		alignSelf: 'center',
		backgroundColor: colorsNew.light3,
	},
	avatar: {
		border: '3px solid #FFE8F3',
		boxShadow: boxShadow('#562157'),
		marginRight: 10,
		boxSizing: 'border-box',
		padding: 3,
		borderRadius: 120,
		width: 40,
		height: 40,
		objectFit: 'cover',
	},
	navLinkStyle,
	navLabel: {
		padding: [[12, 16]],
	},
	navItemLink: {
		width: '100%',
	},
}

export const NavMenuUnstyled = memo(({
	classes, menuLabel, menuIcon, menuItems, avatar,
}) => {
	const [open, setOpen] = useState(false)
	const anchorEl = useRef()
	return (
		<>
			<button
				type="button"
				className={classNames(classes.navLinkStyle, classes.centeredButton)}
				ref={anchorEl}
				onClick={() => setOpen(!open)}
			>
				{avatar && (
					<img src={avatar} alt={menuLabel} className={classes.avatar} />
				)}
				<LabelOrIcon label={menuLabel} icon={menuIcon} />
				<ExpandMore className={classNames(
					classes.arrow, { [classes.arrowOpen]: open },
				)}
				/>
			</button>
			<Menu
				rootStyle={classes.menu}
				open={open}
				componentRef={anchorEl.current}
				setOpenFn={setOpen}
				isNav
			>
				{addIndex(map)(({ label, icon, routeId,
					routeParams, hasProfile, href, target, onClick }, i) => (
					(routeId || href || onClick)
						&& (
							<Fragment key={label}>
								{menuItems.length - 1 === i && (
									<div className={classes.line} />
								)}
								<MenuItem
									disableGutters
									classes={{ root: classes.root }}
									onClick={() => {
										setOpen(false)
									}}
								>
									<Link
										className={classes.navItemLink}
										target={target}
										navMenuStyle
										hasProfile={hasProfile}
										routeId={routeId}
										routeParams={routeParams}
										href={href}
										onClick={() => (onClick ? onClick({ href, target }) : identity)}
									>
										{/* eslint-disable jsx-a11y/anchor-is-valid */}
										<LabelOrIcon classes={classes.navLabel} label={label} icon={icon} />
									</Link>
								</MenuItem>
							</Fragment>
						)
				), menuItems)}
			</Menu>
		</>
	)
})

export default withStyles(styles, { name: 'MuiNavMenu' })(NavMenuUnstyled)
