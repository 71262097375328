import not from 'ramda/src/not'
import compose from 'ramda/src/compose'

import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { viewGtSm } = appStoreLenses

export default compose(
	not,
	viewGtSm,
)
