import { CREATE_PROJECT_PRODUCT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	authenticationTypes,
	userGroups,
} from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import createProjectProductPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/createProjectProductPayloadSchema'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import getProjectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getProjectResponseSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[CREATE_PROJECT_PRODUCT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		payloadSchema: createProjectProductPayloadSchema,
		endpointType: recordEndpointType,
		recordType: project,
		responseSchema: getProjectResponseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
