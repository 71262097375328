import { memo } from 'react'
import classNames from 'classnames'
import MaxWidthContainer from 'root/src/client/web/base/MaxWidthContainer'
import AltNavigation from 'root/src/client/web/base/AltNavigation'
import DareDropLogo from 'root/src/client/public/logo-daredrop0.png'
import Link from 'root/src/client/web/base/Link'
import LabelOrIcon from 'root/src/client/web/base/LabelOrIcon'
import NavMenu from 'root/src/client/web/base/NavMenu'
import RenderModule from 'root/src/client/web/base/RenderModule'
import navigationConnector from 'root/src/client/logic/app/connectors/navigationConnector'
import { prop } from 'root/src/shared/util/nativeFns'
import { colorsNew } from 'root/src/client/web/commonStyles'

const styles = {
	root: {
		color: 'white',
		zIndex: 10,
		backgroundColor: colorsNew.dark3Rgba,
		height: 120,
	},
	logo: {
		fontSize: 25,
		fontFamily: 'Impact',
	},
	navContainer: {
		margin: 0,
	},
	navigationLinks: {
		display: 'flex',
		gap: 48,
	},
	logoImg: {
		height: 54,
	},
	withBorder: {
		border: '1px solid white',
		padding: [[7, 14]],
		borderRadius: 4,
	},
	altNavContainer: {
		position: 'relative',
		width: '100%',
		height: '100%',
	},
	altNavTitleContainer: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		width: 'auto',
		height: 25,
		transform: 'translate(-50%, -50%)',
	},
	altNavBackButtonContainer: {
		display: 'flex',
		position: 'absolute',
		top: '50%',
		left: 24,
		transform: 'translateY(-50%)',
		cursor: 'pointer',
	},
	visibilityArrow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 40,
		width: 50,
		height: 50,
		background: '#FFF',
		top: 45,
		left: 'calc(50% - 600px)',
		'@media(max-width: 1700px)': {
			left: 250,
		},
		borderRadius: 25,
		cursor: 'pointer',
		opacity: '30%',
		'&:hover': {
			opacity: '100%',
		},
	},
}

const NavigationLinks = memo(({
	navigationLinks, classes,
}) => (
	<div className={classNames('flex layout-row layout-align-end', classes.navigationLinks)}>
		{navigationLinks.map(
			({
				label,
				icon,
				routeId,
				routeParams,
				menuItems,
				withBorder,
				href,
				moduleId,
				avatar,
				target,
			}) => (menuItems
				? (
					<NavMenu
						key={label || icon || menuItems.length}
						menuLabel={label}
						menuIcon={icon}
						menuItems={menuItems}
						avatar={avatar}
					/>
				)
				: moduleId
					? (
						<RenderModule
							moduleId={moduleId}
							key={moduleId}
							omitRouteId
						/>
					) : (
						<Link
							navStyle
							key={routeId || href}
							nextJs={!href}
							routeId={routeId}
							routeParams={routeParams}
							href={href}
							target={target}
						>
							<LabelOrIcon
								classes={classes}
								withBorder={withBorder}
								label={label}
								icon={icon}
							/>
						</Link>
					)),
		)}
	</div>
))

// eslint-disable-next-line max-len
export const NavigationUnstyled = memo(({ classes, navigation }) => (
	<div
		className={classNames(
			'layout-row layout-align-center-stretch',
			classes.root,
		)}
	>
		<MaxWidthContainer isNavigation>
			{prop('altNavigation', navigation)
				? <AltNavigation {...navigation} classes={classes} />
				: (
					<>
						<Link href="/" navStyle nextJs={false}>
							<img
								height="54"
								width="197"
								src={DareDropLogo.src}
								className={classes.logoImg}
								alt="Dare Drop Logo"
							/>
						</Link>
						<NavigationLinks classes={classes} navigationLinks={navigation} />
					</>
				)
			}
		</MaxWidthContainer>
	</div>
))

export default navigationConnector(NavigationUnstyled, styles, 'MuiNavigation')
