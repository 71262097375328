import prop from 'ramda/src/prop'
import {
	assigneesKey, pledgeAmountKey, projectDescriptionKey, platformIdKey, projectShareUrlKey,
	projectUrlKey, projectTitleKey, projectApprovedKey, usernameKey, assigneePlatformKey,
	amountRequestedKey, assigneeDisplayNameKey, createdKey, idKey, mnemonicIdKey, displayNameKey,
	expirationDateKey,
} from 'root/src/shared/constants/keys'

export const payloadProp = prop('payload')
export const assigneesProp = prop(assigneesKey)
export const pledgeAmountProp = prop(pledgeAmountKey)
export const amountRequestedProp = prop(amountRequestedKey)
export const projectDescriptionProp = prop(projectDescriptionKey)
export const assigneePlatformIdProp = prop(platformIdKey)
export const assigneePlatformProp = prop(assigneePlatformKey)
export const assigneeDisplayNameProp = prop(assigneeDisplayNameKey)
export const projectTitleProp = prop(projectTitleKey)
export const projectShareUrlProp = prop(projectShareUrlKey)
export const projectUrlProp = prop(projectUrlKey)
export const projectApprovedProp = prop(projectApprovedKey)
export const usernameProp = prop(usernameKey)
export const displayNameProp = prop(displayNameKey)
export const createdProp = prop(createdKey)
export const idProp = prop(idKey)
export const mnemonicIdProp = prop(mnemonicIdKey)
export const expirationDateProp = prop(expirationDateKey)
