export default {
	type: 'object',
	properties: {
		alerts: {
			type: 'object',
			properties: {
				active: { type: 'boolean' },
				aligned: {
					type: 'string',
					enum: ['left', 'right'],
				},
				offsetY: { type: 'number' },
			},
		},
		topDares: {
			type: 'object',
			properties: {
				active: { type: 'boolean' },
				aligned: {
					type: 'string',
					enum: ['left', 'right'],
				},
				offsetY: { type: 'number' },
				flairColor: { type: 'string' },
				overlayText: { type: 'string' },
			},
		},
		twitchOverlay: { type: 'boolean' },
	},
	required: ['alerts', 'topDares'],
	additionalProperties: false,
}
