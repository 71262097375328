import { STEPPER_HEADER_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import DareCreatorImg from 'root/src/client/public/ilu-dare-creator.svg'
import PledgeBountyImg from 'root/src/client/public/ilu-pledge-bounty.svg'
import WatchResultsImg from 'root/src/client/public/ilu-watch-results.svg'
import { CREATE_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import viewersBlob3 from 'root/src/client/public/backgroundBlobs/viewers3.svg'
import { ltMdMediaQuery, ltSmMediaQuery } from 'root/src/client/web/commonStyles'

export const ACTIVE_PROJECTS_REF_ID = 'activeProjects'
export const DELIVERED_PROJECTS_REF_ID = 'deliveredProjects'
export const COMMON_REF_ID = 'Projects'

export default {
	[STEPPER_HEADER_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'StepperHeaderModule',
		sliderWidth: 300,
		backgroundData: [
			{
				position: 'right',
				image: viewersBlob3,
				style: {
					transform: 'translate(50%, 60%)',
					[ltMdMediaQuery]: {
						transform: 'translate(55%, 50%)',
					},
					[ltSmMediaQuery]: {
						transform: 'translate(0%, 0%)',
					},
					'@media (min-width: 1281px) and (max-width: 1600px)': {
						transform: 'translate(65%, 50%)',
					},
				},
			},
		],
		stepperHeaderItems: [
			{
				image: DareCreatorImg,
				heading: 'Dare a creator',
				subHeading: 'Dares can be anything from a difficult trickshot to an irl request.',
				routeId: CREATE_PROJECT_ROUTE_ID,
			},
			{
				image: PledgeBountyImg,
				heading: 'Pledge toward a Dare',
				subHeading: 'You won’t pay until the creator completes your Dare.',
			},
			{
				image: WatchResultsImg,
				heading: 'Watch the results',
				subHeading: 'Your creator will submit video proof, we’ll verify it, and save it for you.',
			},
		],
		buttonWithHover: {
			icon: faTwitch,
			mainTitle: 'Get Started!',
			hoverTitle: 'Connect Twitch to Get Started!',
			platform: 'twitch',
		},
		buttons: [{
			text: '+ DARE A CREATOR',
			routeId: CREATE_PROJECT_ROUTE_ID,
		}],
	},
}
