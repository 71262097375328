export default {
	type: 'object',
	properties: {
		brandName: { type: 'string' },
		brandWebsite: { type: 'string' },
		brandTwitter: { type: 'string' },
		id: { type: 'string' },
		email: { type: 'string' },
	},
	required: ['brandName', 'brandWebsite', 'id'],
	additionalProperties: false,
}
