import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'

import { variableSchemaKey } from 'root/src/shared/constants/keys'

const listSchema = {
	type: 'object',
	properties: {
		list: {
			type: 'object',
			// properties: {
			// 	// loadingBlockVisible: { type: 'integer' },
			// 	currentPage: { type: 'integer' },
			// 	allPage: { type: 'integer' },
			// 	hasMore: { type: 'boolean' },
			// 	lastKey: { type: 'string' },
			// },
			patternProperties: {
				[variableSchemaKey]: {
					type: 'object',
					properties: {
						loadingBlockVisible: { type: 'integer' },
						currentPage: { type: 'integer' },
						allPage: { type: 'integer' },
						hasMore: { type: 'boolean' },
						lastKey: { type: 'string' },
						listDataLength: { type: 'integer' },
						pagesCount: { type: 'integer' },
						listIds: { type: 'array' },
					},
				},
			},
		},
	},
}

export const listStoreLenses = lensesFromSchema(listSchema)

export const listModuleSchema = {
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: {
			type: 'object',
			properties: {
				listType: { type: 'string', enum: ['cardList', 'paymentMethodList', 'payoutList', 'couponList', 'brandProductList'] },
				cardListType: { type: 'string' },
				tableColumns: { type: 'array', items: { type: 'string' } },
				tableItemActions: { type: 'array',
					items: {
						type: 'object',
						properties: {
							name: { type: 'string' },
							fn: { type: 'string' },
						},
					},
				},
				scrollItemBreakpoints: { type: 'function' },
				listTitle: { type: 'string' },
				listSubtitle: { type: 'string' },
				listInfoText: { type: 'string' },
				listControls: { type: 'array' },
				listSubmits: { type: 'array' },
				listRouteId: { type: 'string' },
				sortFilterModule: { type: 'string' },
				listPayload: {
					type: 'object',
					properties: {
						section: { type: 'string' },
						pageItemLength: { type: 'integer' },
						all: { type: 'boolean' },
					},
				},
				goTopButton: { type: 'boolean' },
				desktopLayoutRow: { type: 'boolean' },
				toggleVisibilityProp: { type: 'array' },
				buttons: { type: 'array', items: { type: 'object' } },
				actionButtons: { type: 'array', items: { type: 'object' } },
				accordionOnExpand: { type: 'function' },
				listActionButton: { type: 'object' },
				isClientPaginated: { type: 'boolean' },
				defaultListDataLength: { type: 'integer' },
				listDataLengthOptions: { type: 'array' },
				constantListStoreKey: { type: 'boolean' },
				pageItemsCount: { type: 'integer' },
				recordType: { type: 'string' },
			},
		},
	},
}
export const listModuleLenses = lensesFromSchema(listModuleSchema)
