import lensPath from 'ramda/src/lensPath'
import lensProp from 'ramda/src/lensProp'
import compose from 'ramda/src/compose'
import prepend from 'ramda/src/prepend'
import __ from 'ramda/src/__'
import prop from 'ramda/src/prop'
import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'
import { variableSchemaKey } from 'root/src/shared/constants/keys'

export const currentRouteIndex = 0

export const routeIdKey = 'routeId'
export const routeParamsKey = 'routeParams'
export const routeQueryKey = 'query'
export const routeHashKey = 'hash'

export const authKey = 'authentication'

// path regexes
export const regexKey = 'regex'
export const regexKeysKey = 'regexKeys'
export const toPathKey = 'toPath'

export const regexLens = lensProp(regexKey)
export const regexKeysLens = lensProp(regexKeysKey)
export const toPathLens = lensProp(toPathKey)
export const routeIdToPathLens = compose(lensPath, prepend(__, [toPathKey]))
export const recordIdProp = prop('recordId')

// route fixtures
export const routeUrlKey = 'url'
export const mainRouteIdKey = 'mainRouteId'

export const routeUrlLens = lensProp(routeUrlKey)
export const mainRouteIdLens = lensProp(mainRouteIdKey)
export const routeIdUrlLens = compose(lensPath, prepend([routeUrlKey]))

export const routeStoreLenses = lensesFromSchema({
	type: 'object',
	properties: {
		route: {
			type: 'object',
			properties: {
				variantRouteIndex: { type: 'number' },
				routeId: { type: 'string' },
				query: { type: 'object' },
				languageCode: { type: 'string' },
				routeParams: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
					},
				},
				routeQuery: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
					},
				},
			},
		},
		redirectRoute: {
			type: 'object',
			properties: {
				variantRouteIndex: { type: 'number' },
				routeId: { type: 'string' },
				query: { type: 'object' },
				routeParams: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
					},
				},
				routeQuery: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
					},
				},
			},
		},
		previousRoute: {
			type: 'object',
			properties: {
				variantRouteIndex: { type: 'number' },
				routeId: { type: 'string' },
				query: { type: 'object' },
				routeParams: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
					},
				},
				routeQuery: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
					},
				},
			},
		},
	},
})

export const routeDescriptionLenses = lensesFromSchema({
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: {
			type: 'object',
			properties: {
				url: { type: 'string' },
				title: { type: 'string' },
				authentication: { type: 'string' },
				authenticationRole: { type: 'string' },
				routeSelector: { type: 'function' },
				modules: {
					type: 'array',
					items: { type: 'string' },
				},
				defaultRouteId: { type: 'string' },
				variantModules: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							label: { type: 'string' },
							modules: {
								type: 'array',
								items: {
									type: 'string',
								},
							},
						},
					},
				},
				experimentVariants: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							value: { type: 'string' },
							modules: {
								type: 'array',
								items: {
									type: 'string',
								},
							},
						},
					},
				},
				noLoginRedirect: { type: 'boolean' },
				chatWidget: { type: 'boolean' },
				discordWidget: { type: 'boolean' },
				tiktokViewEvent: { type: 'boolean' },
			},
		},
	},
})

export const moduleIdProp = prop('moduleId')
export const moduleIndexProp = prop('moduleIndex')

export const moduleDescriptionLenses = lensesFromSchema({
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: {
			type: 'object',
			properties: {
				moduleType: { type: 'string' },
				hasSidebarSelector: { type: 'function' },
				noMobileDisplay: { type: 'boolean' },
				onEnterActions: { type: 'array' },
				onExitActions: { type: 'array' },
				backgroundData: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							image: { type: 'string' },
							position: { type: 'string' },
						},
					},
				},
			},
		},
	},
})
