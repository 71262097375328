import { GET_VERIFICATION } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { Platform } from 'root/src/shared/@types/platforms';
export const getVerificationEndpoint = {
    [GET_VERIFICATION]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: otherEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                platform: {
                    type: 'string',
                    enum: Object.values(Platform),
                },
                platformId: { type: 'string' },
            },
            required: ['platform', 'platformId'],
        },
    },
};
