import https from 'https'
import { parse } from 'url'
import stringify from 'qs/lib/stringify'
import { NetworkError } from 'root/src/server/errors'

const tryParseJSON = (response) => {
	try {
		return JSON.parse(response)
	} catch (_) {
		return response
	}
}

const fn = (config, log = true) => new Promise((resolve, reject) => {
	const {
		url,
		method,
		body,
		queryParams, headers = {}, isBuffer, data, noRedirect, returnAll,
		agent,
	} = config
	const queryString = queryParams
		? `?${stringify(queryParams, { indices: false })}`
		: ''
	const bodyParsed = typeof body === 'string'
		? body
		: body
			? JSON.stringify(body)
			: undefined

	const parsedUrl = parse(url)
	const options = {
		hostname: parsedUrl.host,
		path: `${parsedUrl.path}${queryString}`,
		method: method || 'GET',
		headers: {
			...headers,
			'Content-Type': headers['Content-Type'] || (
				typeof body === 'string' ? 'text/plain' : 'application/json'
			),
		},
		agent,
	}

	// eslint-disable-next-line consistent-return
	const req = https.request(options, (res) => {
		const chunks = []
		const status = res.statusCode

		if (status === 301 || status === 302) {
			return noRedirect
				? resolve(res.headers.location)
				: resolve(fn({ ...config, url: res.headers.location }, log))
		}

		// eslint-disable-next-line consistent-return
		res.on('data', (response) => {
			const parsed = tryParseJSON(response)

			if (Buffer.isBuffer(parsed)) {
				chunks.push(parsed)
			} else {
				if (status >= 200 && status < 300) {
					if (returnAll) {
						return resolve({ ...res, data: parsed, url })
					}
					return resolve(parsed)
				}

				if (returnAll) {
					// eslint-disable-next-line prefer-promise-reject-errors
					return reject({ ...res, data: parsed, url })
				}
				console.error(parsed)
				return reject(parsed)
			}
		})
		res.on('end', () => {
			if (chunks.length) {
				const stringBuffer = isBuffer ? Buffer.concat(chunks) : Buffer.concat(chunks).toString('utf8')
				const parsedResponse = tryParseJSON(stringBuffer)

				if (returnAll) {
					return resolve({ ...res, data: parsedResponse, url })
				}

				return resolve(parsedResponse)
			}
			return resolve(returnAll ? { ...res, url } : undefined)
		})
	})

	req.on('error', (e) => {
		console.warn(e)
		reject(new NetworkError())
	})

	if (bodyParsed) {
		req.write(bodyParsed)
	}

	if (data) {
		req.write(stringify(data))
	}
	req.end()
})

export default fn
