import { z } from 'zod';
export const BATTLE_PASS_DISPLAY_NAME_SCHEMA = z.string()
    .max(80, {
    message: 'Battle Pass Name should not exceed 80 character(s)',
})
    .min(3, {
    message: 'Battle Pass Name should be at least 3 character(s)',
});
export const BATTLE_PASS_DESCRIPTION_SCHEMA = z.string()
    .max(300, {
    message: 'Battle Pass Description should not exceed 300 character(s)',
})
    .min(10, {
    message: 'Battle Pass Description should be at least 10 character(s)',
});
export const BATTLE_PASS_IMAGE_SCHEMA = z.string()
    .min(5, {
    message: 'Battle Pass Image url should be at least 5 character(s)',
})
    .max(500, {
    message: 'Battle Pass Image url should not exceed 500 character(s)',
});
export const BATTLE_PASS_MIN_TIER = 1;
export const BATTLE_PASS_MAX_TIER = 1000;
