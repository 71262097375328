import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'

import { variableSchemaKey } from 'root/src/shared/constants/keys'

export const bannerHeaderModuleDescriptionSchema = {
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: {
			type: 'object',
			properties: {
				notified: { type: 'boolean' },
				bannerImage: { type: 'string' },
				bannerSubText: { type: 'string' },
				listModuleId: { type: 'string' },
				bannerTabs: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							tabLabel: { type: 'string' },
							endpointId: { type: 'string' },
							fixedFilter: {
								type: 'array',
								items: {
									type: 'object',
									properties: {
										param: { type: 'string' },
										value: { type: 'string' },
									},
								},
							},
							fixedPayload: {
								type: 'object',
							},
						},
					},
				},
				bannerImageText: { type: 'string' },
				bannerBoxImages: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
				bannerNewStyle: { type: 'boolean' },
				fullWidth: { type: 'boolean' },
				bannerImageSubText: { type: 'string' },
				textWithBg: { type: 'boolean' },
				createNewDareActive: { type: 'boolean' },
				oneRow: { type: 'boolean' },
				link: {
					type: 'object',
					properties: {
						routeId: { type: 'string' },
						label: { type: 'string' },
					},
				},
				giveawayLink: { type: 'object' },
				embeddedContent: {
					type: 'object',
					properties: {
						filtersRoute: { type: 'string' },
					},
				},
			},
		},
	},
}
export const bannerHeaderModuleDescriptionLenses = lensesFromSchema(
	bannerHeaderModuleDescriptionSchema, undefined, undefined, true,
)
