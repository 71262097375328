import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import map from 'ramda/src/map'
import { WHITELIST_FORM_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import {
	userEventsKey,
	generalWhitelistKey,
	campaignWhitelistKey,
	privateCampaignWhitelistKey,
} from 'root/src/shared/constants/keys'
import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import providers from 'root/src/shared/constants/providers'
import addWhitelistedCreators from 'root/src/client/logic/form/thunks/addWhitelistedCreators'
import setFormErrors from 'root/src/client/logic/form/actions/setFormErrors'
import { campaignWhitelistTypes } from 'root/src/shared/constants/whitelist'

export default {
	[WHITELIST_FORM_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		dropdownAsyncOptions: [
			{
				endpointId: GET_DROP_EVENTS,
				payload: {
					section: userEventsKey,
					pageItemLength: 100,
				},
				modifyFn: pipe(
					prop('items'),
					map((campaign) => {
						const { id, campaignName, dropTitle } = campaign
						return { ...campaign, label: dropTitle || campaignName, value: id }
					}),
				),
			},
		],
		fields: [
			{
				labelFieldText: [
					{
						text: 'Upload platform IDs list',
					},
				],
				fieldId: 'platformIdsList',
				inputType: 'attachmentInput',
				attachmentFormats: ['csv', 'txt'],
				subFieldText:
					'Platform user names list in .csv or .txt (one column, no header)',
				required: true,
			},
			{
				fieldId: 'platform',
				inputType: 'dropdown',
				labelFieldText: [
					{
						text: "Select users' platform",
					},
				],
				options: [
					{ label: 'Twitch', value: providers.twitch },
					{ label: 'Youtube', value: providers.youtube },
					{ label: 'TikTok', value: providers.tiktok },
				],
				required: true,
			},
			{
				fieldId: 'whitelistType',
				inputType: 'dropdown',
				labelFieldText: [
					{
						text: 'Select whitelist type',
					},
				],
				options: [
					{ label: 'All Campaigns', value: generalWhitelistKey },
					{ label: 'A Campaign', value: campaignWhitelistKey },
					{ label: 'A Private Campaign', value: privateCampaignWhitelistKey },
				],
				required: true,
			},
			{
				modifyOptions: (items, { formData }) => {
					const isPrivate = formData?.whitelistType?.value === privateCampaignWhitelistKey

					if (!isPrivate) {
						return items
					}

					return items.filter(dropEvent => dropEvent.isPrivate)
				},
				fieldId: 'campaignId',
				inputType: 'dropdown',
				uniqueItems: true,
				labelFieldText: [
					{
						text: 'Select campaign',
					},
				],
				hideInput: (state, { moduleId, routeId }) => {
					const whitelistType = pathOr(
						generalWhitelistKey,
						[
							'form',
							`${routeId}-${moduleId}`,
							'formInputs',
							'whitelistType',
							'value',
						],
						state,
					)
					return !campaignWhitelistTypes.includes(whitelistType)
				},
				asyncOptionsStoreKey: GET_DROP_EVENTS,
				required: false,
			},
		],
		modalContent: {
			submits: [
				{
					label: 'Submit',
					onClickFn: async ({
						dispatch,
						formData,
						displayModal,
						moduleKey,
						moduleId,
						clearForm,
					}) => {
						try {
							await dispatch(addWhitelistedCreators(formData))
							displayModal(undefined)
							clearForm(
								{
									/* state */
								},
								{ moduleKey, moduleId },
							)
						} catch (errors) {
							dispatch(setFormErrors(moduleKey, errors))
							console.error(errors)
						}
					},
				},
			],
		},
	},
}
