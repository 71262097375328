import reduce from 'ramda/src/reduce'
import append from 'ramda/src/append'
import compose from 'ramda/src/compose'
import assoc from 'ramda/src/assoc'
import isNil from 'ramda/src/isNil'
import splitEvery from 'ramda/src/splitEvery'
import take from 'ramda/src/take'
import { ternary } from 'root/src/shared/util/ramdaPlus'
import { API_LIST_REQUEST_SUCCESS } from 'root/src/client/logic/api/actionIds'
import {
	apiStoreLenses, nextKeyProp, idProp, itemsProp,
	projectIdProp, dataProp, mnemonicIdProp, projectUrlProp,
	messageIdProp, nameProp, linkProp, rewardDataProp, platformIdProp,
	campaignIdProp, whitelistTypeProp, totalsProp,
} from 'root/src/client/logic/api/lenses'
import getLensFromType from 'root/src/client/logic/api/util/getLensFromType'
import { userIdProp } from 'root/src/shared/descriptions/apiLenses'
import listDataLengthSelector from 'root/src/client/logic/list/selectors/listDataLengthSelector'
import { listStoreLenses } from 'root/src/client/logic/list/lenses'
import dissocPath from 'ramda/src/dissocPath'

const {
	setNext, setItems, setListProcessingChild, setLastKey, viewListProcessingChild, setTotals,
} = apiStoreLenses
const { setPagesCount, setListIds } = listStoreLenses

// eslint-disable-next-line max-len
const handleListIds = (listIds, listDataLength) => (listDataLength ? take(listDataLength, listIds) : listIds)

// eslint-disable-next-line max-len
const handlePagesCount = (listIds, listDataLength) => (listDataLength ? splitEvery(listDataLength, listIds).length : undefined)

const handleClientPagination = (moduleId, listIds, listDataLength) => compose(
	setPagesCount(moduleId, handlePagesCount(listIds, listDataLength)),
	setListIds(moduleId, listIds),
)

export const apiListRequestSuccess = (
	state,
	{ listStoreKey, recordType, list, moduleId, isClientPaginated, clearBefore },
) => {
	const listDataLength = listDataLengthSelector(state, { moduleId })
	const isListProcessing = viewListProcessingChild(listStoreKey, state)
	if (!isListProcessing) {
		return state
	}
	const stateMod = clearBefore ? dissocPath(['api', 'records', recordType], state) : state
	let listIds = []
	const listItems = itemsProp(list) || dataProp(list) || list
	const totals = totalsProp(list)
	const { lastKey } = list
	const recordsSet = reduce((result, record) => {
		const recordId = messageIdProp(record)
		|| idProp(record)
		|| rewardDataProp(record)
		|| projectUrlProp(record)
		|| projectIdProp(record)
		|| mnemonicIdProp(record)
		|| nameProp(record)
		|| userIdProp(record)
		|| linkProp(record)
		|| `${platformIdProp(record)}-${campaignIdProp(record) || whitelistTypeProp(record)}`
		listIds = append(recordId, listIds)
		const viewRecordsChild = getLensFromType('view', 'child', recordType, apiStoreLenses)
		const setRecordsChild = getLensFromType('set', 'child', recordType, apiStoreLenses)
		let lastRecord = viewRecordsChild(recordId, result)
		lastRecord = ternary(isNil(lastRecord), {}, lastRecord)
		return setRecordsChild(recordId, {
			...lastRecord,
			...assoc('listStoreKey', listStoreKey, record),
		}, result)
	}, stateMod, listItems)
	return compose(
		setNext(listStoreKey, nextKeyProp(list)),
		setLastKey(listStoreKey, lastKey),
		setItems(listStoreKey, handleListIds(listIds, listDataLength)),
		setTotals(listStoreKey, totals),
		setListProcessingChild(listStoreKey, false),
		(currentList) => {
			if (isClientPaginated) {
				return handleClientPagination(moduleId, listIds, listDataLength)(currentList)
			}
			return currentList
		},
	)(recordsSet)
}

export default {
	[API_LIST_REQUEST_SUCCESS]: apiListRequestSuccess,
}
