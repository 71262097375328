import always from 'ramda/src/always'
import tryCatch from 'ramda/src/tryCatch'

export const capitalize = string => string[0].toUpperCase() + string.slice(1)
export const capitalizeMaybeEmpty = string => (string[0] ? string[0].toUpperCase() + string.slice(1) : '')

export const camelCase = str => str.replace(
	/[-_ ]([a-z])/g,
	m => m[1].toUpperCase(),
)

export const kebabCase = str => str.replace(
	/[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g,
	m => `-${m.toLowerCase()}`,
)

export const camelCaseSpace = tryCatch(string => string
	.replace(/([A-Z\/])/g, ' $1')
	.replace(/^./, str => str.toUpperCase()), always(undefined))

export const camelCaseHyphen = tryCatch(
	string => string.replace(/-([a-z])/g, g => g[1].toUpperCase()),
	always(undefined),
)
