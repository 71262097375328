var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class FileValidator {
    static fileExtension(extensions, message) {
        return {
            message: message !== null && message !== void 0 ? message : 'Wrong format file',
            validator: (file) => {
                const fileExtension = getFileExtension(file);
                return extensions.length === 0 || extensions.includes(fileExtension);
            },
        };
    }
    static maxBytesFileSize(maxBytesSize, message) {
        return {
            message: message !== null && message !== void 0 ? message : `Max file size is ${humanFileSize(maxBytesSize)}`,
            validator: (file) => {
                return file.size <= maxBytesSize;
            },
        };
    }
}
export class ImageValidator extends FileValidator {
    static minImageDimension({ minWidth, minHeight, message }) {
        return {
            message: message !== null && message !== void 0 ? message : getImageDimensionErrorMessage(minWidth, minHeight, true),
            validator: (file) => __awaiter(this, void 0, void 0, function* () {
                const { width, height } = yield getWidthAndHeightImageFromFile(file);
                if (minWidth && width <= minWidth) {
                    return false;
                }
                if (minHeight && height <= minHeight) {
                    return false;
                }
                return true;
            }),
        };
    }
    static maxImageDimension({ maxHeight, maxWidth, message }) {
        return {
            message: message !== null && message !== void 0 ? message : getImageDimensionErrorMessage(maxWidth, maxHeight),
            validator: (file) => __awaiter(this, void 0, void 0, function* () {
                const { width, height } = yield getWidthAndHeightImageFromFile(file);
                if (maxWidth && width >= maxWidth) {
                    return false;
                }
                if (maxHeight && height >= maxHeight) {
                    return false;
                }
                return true;
            }),
        };
    }
}
function getFileExtension(file) {
    var _a;
    return (_a = file.name.split('.').at(-1)) === null || _a === void 0 ? void 0 : _a.toLowerCase();
}
function getWidthAndHeightImageFromFile(file) {
    return new Promise((resolve) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.addEventListener('load', () => {
            resolve({
                width: image.width,
                height: image.height,
            });
        });
    });
}
function getImageDimensionErrorMessage(width, height, isMinimalRatio = false) {
    const minMaxMessage = isMinimalRatio ? 'minimal' : 'maximum';
    if (width && height) {
        return `Image should have ${minMaxMessage} ratio ${width}x${height}`;
    }
    if (width) {
        return `Image should have ${minMaxMessage} width ${width}`;
    }
    if (height) {
        return `Image should have ${minMaxMessage} height ${height}`;
    }
    return '';
}
function humanFileSize(bytes) {
    const thresh = 1024;
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unit = 0;
    // Loop until bytes are less than the threshold or until the last unit is reached
    while (bytes - thresh >= 0 && unit < units.length - 1) {
        bytes /= thresh;
        unit += 1;
    }
    return `${bytes.toFixed(1)} ${units[unit]}`;
}
