import React, { memo } from 'react'
import SubTitle from 'root/src/client/web/typography/SubTitle'
import backArrow from 'root/src/client/public/icons/ic-back-arrow.svg'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'

export default memo(({ classes, backRoute, title }) => (
	<div className={classes.altNavContainer}>
		<div
			onClick={() => pushRouteClient({ routeId: backRoute })}
			className={classes.altNavBackButtonContainer}
		>
			<img src={backArrow.src} className={classes.altNavBackButton} alt="back" />
		</div>
		<div className={classes.altNavTitleContainer}>
			<SubTitle noMargin>{title}</SubTitle>
		</div>
	</div>
))
