export default {
	type: 'object',
	properties: {
		firstname: { type: 'string' },
		lastname: { type: 'string' },
		email: { type: 'string' },
		companyName: { type: 'string' },
		companyAddress: { type: 'string' },
	},
	required: ['firstname', 'lastname', 'email', 'companyName', 'companyAddress'],
	additionalProperties: false,
}
