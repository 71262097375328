const prodOutputs = require('./config/cfOutput-Production')
const devOutputs = require('./config/cfOutput-Dev')
const stagingOutputs = require('./config/cfOutput-Staging')
const testOutputs = require('./config/cfOutput-Test')

const outputsObj = {
	production: prodOutputs,
	testPerformance: devOutputs,
	dev: devOutputs,
	staging: stagingOutputs,
	test: testOutputs,
}

export default outputsObj[process.env.STAGE || 'dev']
