import { NOT_ENOUGH_FOLLOWERS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import ddLogo from 'root/src/client/public/DDlogoWhiteBigTransparent.png'

export default {
	[NOT_ENOUGH_FOLLOWERS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			image: ddLogo,
			paragraphs: [
				'You need a 100+ followers to view drops.',
			],
		},
	},
}
