import {
	CREATE_PROJECT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

import createProjectPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/createProjectPayloadSchema'
import getProjectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getProjectResponseSchema'
import { VALIDATE_IP_ADDRESS } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = createProjectPayloadSchema
export const responseSchema = getProjectResponseSchema

export default {
	[CREATE_PROJECT]: {
		authentication: authenticationTypes.optional,
		endpointType: recordEndpointType,
		recordType: project,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_IP_ADDRESS],
	},
}
