import { ADD_PAID_OFF_PLATFORM_POINTS } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
const payloadSchema = {
    type: 'object',
    properties: {
        userId: {
            type: 'string',
        },
        campaignId: {
            type: 'string',
        },
        modificationValue: {
            type: 'number',
        },
    },
    required: ['userId', 'campaignId', 'modificationValue'],
    additionalProperties: false,
};
const responseSchema = {
    type: 'object',
    properties: {
        userId: {
            type: 'string',
        },
        campaignId: {
            type: 'string',
        },
        modificationValue: {
            type: 'number',
        },
    },
    required: ['userId', 'campaignId', 'modificationValue'],
    additionalProperties: false,
};
export const addPaidOffPlatformPointsEndpoint = {
    [ADD_PAID_OFF_PLATFORM_POINTS]: {
        authentication: authenticationTypes.authenticated,
        payloadSchema,
        responseSchema,
        authenticationRole: userGroups.admin,
    },
};
