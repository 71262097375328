import { REQUEST_INVOICE_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import requestInvoiceSchema from 'root/src/shared/descriptions/formSchemas/requestInvoiceSchema'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[REQUEST_INVOICE_FORM_MODULE_ID]: {
		moduleType: 'form',
		title: 'Invoice form',
		schema: requestInvoiceSchema,
		fields: [
			{
				labelFieldText: [{
					text: 'Payment contact first name',
					required: true,
				}],
				fieldId: 'firstname',
				inputType: 'text',
			},
			{
				fieldId: 'lastname',
				labelFieldText: [{
					text: 'Payment contact last name',
					required: true,
				}],
				inputType: 'text',
			},
			{
				fieldId: 'email',
				labelFieldText: [{
					text: 'Payment contact email',
					required: true,
				}],
				inputType: 'email',
			},
			{
				fieldId: 'companyName',
				labelFieldText: [{
					text: 'Company Name',
					required: true,
				}],
				inputType: 'text',
			},
			{
				fieldId: 'companyAddress',
				labelFieldText: [{
					text: 'Company address',
					required: true,
				}],
				multiline: true,
				multilineRows: 4,
				inputType: 'text',
			},
		],
		submits: [
			{
				label: 'Request invoice',
				buttonType: 'primaryButton',
			},
		],
		links: [
			{
				routeId: routeUrls.addCampaign(),
				label: 'Go Back',
			},
		],
	},
}
