import { FOOTER_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

import {
	FAQ_ROUTE_ID,
	TERMS_OF_SERVICE_ROUTE_ID,
	COOKIE_POLICY_ROUTE_ID,
	PRIVACY_POLICY_ROUTE_ID,
	PUBLISHERS_PAGE_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { EMAIL } from 'root/src/shared/constants/pageData'

import facebookFooter from 'root/src/client/public/icons/ic-facebook'
import twitterFooter from 'root/src/client/public/icons/ic-twitter'
import instagramFooter from 'root/src/client/public/icons/ic-instagram'
import youtubeFooter from 'root/src/client/public/icons/ic-youtube'
import DareDropLogo from 'root/src/client/public/logo-daredrop0.png'

export default {
	[FOOTER_MODULE_ID]: {
		logo: {
			image: DareDropLogo,
			alt: 'Dare Drop',
		},
		links: [
			{
				text: 'FAQ',
				routeId: FAQ_ROUTE_ID,
			},
			{
				text: 'Contact us',
				emailAddress: EMAIL,
			},
			{
				text: 'Terms of Service',
				routeId: TERMS_OF_SERVICE_ROUTE_ID,
			},
			{
				text: 'Cookie Policy',
				routeId: COOKIE_POLICY_ROUTE_ID,
			},
			{
				text: 'Privacy Policy',
				routeId: PRIVACY_POLICY_ROUTE_ID,
			},
			{
				text: 'Brands',
				routeId: PUBLISHERS_PAGE_ROUTE_ID,
			},
		],
		socialLinks: [
			{
				href: 'https://www.facebook.com/daredrops',
				image: facebookFooter,
				alt: 'facebook',
			},
			{
				href: 'https://www.instagram.com/dare.drops/',
				image: instagramFooter,
				alt: 'instagram',
			},
			{
				href: 'https://www.youtube.com/channel/UC9HaIflzLYyMTWaoMyok3VQ',
				image: youtubeFooter,
				alt: 'youtube',
			},
			{
				href: 'https://twitter.com/daredrops',
				image: twitterFooter,
				alt: 'twitter',
			},
		],
		discord: {
			href: 'https://discord.gg/xmECwRu',
		},
	},
}
