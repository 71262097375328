import view from 'ramda/src/view'
import lensProp from 'ramda/src/lensProp'

import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'

import { variableSchemaKey } from 'root/src/shared/constants/keys'

const formSchema = {
	type: 'object',
	properties: {
		form: {
			type: 'object',
			patternProperties: {
				[variableSchemaKey]: {
					type: 'object',
					properties: {
						formError: {
							type: 'object',
							properties: {
								formSubmitError: { type: 'object' },
							},
						},
						formData: {
							type: 'object',
							properties: {
								formSubmitting: { type: 'boolean' },
								formSubmitted: { type: 'boolean' },
							},
						},
						formInputs: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: { type: 'object' },
							},
						},
						fieldErrors: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: { type: 'object' },
							},
						},
						formToggles: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: { type: 'object' },
							},
						},
						fieldInfos: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: { type: 'object' },
							},
						},
						fieldLoading: {
							type: 'boolean',
						},
						buttonErrors: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: { type: 'object' },
							},
						},
						fieldData: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										dirty: { type: 'boolean' },
										valid: { type: 'boolean' },
									},
								},
							},
						},
						stepId: {
							type: 'string',
						},
						stepIdHistory: {
							type: 'array',
							items: {
								type: 'string',
							},
						},
						dropdownAsyncOptions: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'array',
								},
							},
						},
						submitsBlock: { type: 'boolean' },
						submitsLoading: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: { type: 'boolean' },
							},
						},
						loadingBlockVisible: { type: 'boolean' },
						formOutput: {},
					},
				},
			},
		},
	},
}

export const formStoreLenses = lensesFromSchema(formSchema)

const formModuleCommon = {
	title: { type: 'string' },
	fieldSections: {
		type: 'array',
		items: {
			type: 'string',
		},
	},
	fieldSectionsHeaders: { type: 'object' },
	fieldSectionsToggles: { type: 'object' },
	subTitle: { type: 'string' },
	hideSubmitError: { type: 'boolean' },
	submitsFullWidth: { type: 'boolean' },
	submitsRow: { type: 'boolean' },
	doubleColumn: { type: 'boolean' },
	fieldTheme: { type: 'string' },
	formFullWidth: { type: 'boolean' },
	preSubmitText: { type: 'string' },
	postSubmitText: { type: 'string' },
	preSubmitCaption: { type: 'string' },
	postSubmitCaption: { type: 'string' },
	schema: {
		type: 'object',
		properties: {},
	},
	prePopulateForm: {
		type: 'function',
	},
	prePopulateFormOn: {
		type: 'string',
	},
	noBackButton: { type: 'boolean' },
	fields: {
		type: 'array',
		items: {
			type: 'object',
			properties: {
				fieldId: { type: 'string' },
				inputType: {
					type: 'string',
					enum: [
						'text', 'password', 'email', 'registerEmail', 'number',
					],
				},
				label: { type: 'string' },
				fieldOptions: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							label: 'string',
							id: 'number',
							value: 'string',
						},
					},
				},
			},
		},
	},
	formOutput: { type: 'boolean' },
}

export const formModuleSchema = {
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: {
			type: 'object',
			properties: {
				title: { type: 'string' },
				subTitle: { type: 'string' },
				headerType: { type: 'string' },
				preSubmitText: { type: 'string' },
				postSubmitText: { type: 'string' },
				preSubmitCaption: { type: 'string' },
				postSubmitCaption: { type: 'string' },
				formType: { type: 'string' },
				schema: {
					type: 'object',
					properties: {},
				},
				fields: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							fieldId: { type: 'string' },
							inputType: {
								type: 'string',
								enum: [
									'text', 'password', 'email', 'number',
								],
							},
							label: { type: 'string' },
							subTextLabel: { type: 'string' },
							iconButton: { type: 'object',
								properties: {
									icon: { type: 'string' },
									route: { type: 'function' },
								} },
						},
					},
				},
				...formModuleCommon,
				submits: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							action: { type: 'function' },
							additionalThunk: { type: 'function' },
							onSuccess: { type: 'function' },
							label: { type: 'string' },
							buttonType: { type: 'string' },
						},
					},
				},
				postFormSubmits: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							action: { type: 'function' },
							additionalThunk: { type: 'function' },
							onSuccess: { type: 'function' },
							label: { type: 'string' },
							buttonType: { type: 'string' },
						},
					},
				},
				customSubmits: {
					type: 'array',
					items: {
						type: 'object',
					},
				},
				customSubmitsData: {
					type: 'object',
				},
				links: {
					type: ['array'],
					items: {
						type: 'object',
						properties: {
							label: { type: 'string' },
							routeId: { type: 'string' },
							routeParams: {
								type: 'object',
								properties: {
									recordId: { type: 'string' },
								},
							},
						},
					},
				},
				cancelButton: {
					type: 'object',
					properties: {
						label: { type: 'string' },
						routeId: { type: 'string' },
					},
				},
				handlers: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							action: { type: 'function' },
							label: { type: 'string' },
							buttonType: { type: 'string' },
						},
					},
				},
				steps: {
					type: 'object',
					properties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {
								title: { type: 'string' },
								hasNextStep: { type: 'boolean' },
							},
						},
					},
				},
				rootStepSelector: { type: 'function' },
				nextStepIdSelectorFn: { type: 'function' },
				onInputFn: { type: 'function' },
			},
		},
	},
}
export const formModuleLenses = lensesFromSchema(formModuleSchema)

export const stepFormModuleSchema = {
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: {
			type: 'object',
			properties: {
				forms: {
					type: 'array',
					items: {
						type: 'object',
						properties: formModuleCommon,
					},
				},
				submits: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							action: { type: 'function' },
							onSuccess: { type: 'function' },
							label: { type: 'string' },
						},
					},
				},
			},
		},
	},
}
export const stepFormModuleLenses = lensesFromSchema(stepFormModuleSchema)

// action payload lenses
export const viewPayloadFormHash = view(lensProp('formHash'))

export const viewPayloadInputId = view(lensProp('inputId'))
export const viewPayloadInputValue = view(lensProp('inputValue'))

export const viewPayloadErrorObj = view(lensProp('errorObj'))
