import prop from 'ramda/src/prop'
import has from 'ramda/src/has'
import isNil from 'ramda/src/isNil'
import assoc from 'ramda/src/assoc'
import { SUBMIT_FORM_ERROR } from 'root/src/client/logic/form/actionIds'
import reduce from 'ramda/src/reduce'
import path from 'ramda/src/path'
import is from 'ramda/src/is'

const commonErrorPaths = [
	['schemaErrors', 'type'],
	['message', 'code'],
	['message'],
	['error'],
	['error', 'error'],
	['error', 'message'],
	['error', 'message', 'message'],
	['message', 'message'],
]

export const lookupErrorMessage = (error, paths) => reduce((acc, curr) => (
	is(String, path(curr, error)) ? path(curr, error) : acc
), '', paths)

const getErrorMessage = (error) => {
	if (typeof error !== 'object') {
		// Unhandled error
		if (error && typeof error !== 'string') {
			console.error(error)
			return 'Something went wrong, please try again'
		}
		return error
	}

	if (Array.isArray(error)) {
		// We can display it with new line but with many errors it would look bad
		// return error.map(generateErrorMessage).join('\n')
		return getErrorMessage(error[0])
	}

	const errorFound = lookupErrorMessage(error, commonErrorPaths)

	if (errorFound) {
		return errorFound
	}

	// Same as above
	return getErrorMessage(Object.values(error)[0])
}

export default (moduleKey, submitIndex, error) => {
	let errorString = getErrorMessage(error)
	if (has('generalErrors', error)) {
		errorString = prop('generalErrors', error)
	}
	return {
		type: SUBMIT_FORM_ERROR,
		payload: {
			moduleKey,
			submitIndex,
			error:
				has('error', error) && isNil(prop('error', error))
					? {}
					: assoc('errorString', errorString, error),
		},
	}
}
