import classNames from 'classnames'
import TooltipWraper from 'root/src/client/web/base/TooltipWraper'
import { boxShadow, colorsNew, textShadow } from 'root/src/client/web/commonStyles'
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography'
import Icon, { iconParentStyle } from 'root/src/client/web/base/Icon'
import linkConnector from 'root/src/client/logic/app/connectors/linkConnector'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import withStyles from '@material-ui/core/styles/withStyles'

const transition = 0.3

const styles = {
	root: {
		padding: 2,
		borderRadius: 8,
		'&, & *': {
			cursor: 'pointer',
			transition: `${transition}s`,
		},
		'& path': {
			transition: `${transition / 3}s`,
		},
		'&:hover *': {
			'& *': {
				boxShadow: 'none',
				textShadow: 'none',
			},
			'& .contentPrimary': {
				transform: 'translateY(1px)',
			},
		},
		...iconParentStyle,
	},
	button: {
		height: '100%',
		minHeight: 40,
		minWidth: 89,
		padding: [[0, 10]],
		width: '100%',
		borderRadius: 5,
		position: 'relative',
		border: 'none',
	},
	contentPrimary: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonContainerPrimary: {
		background: colorsNew.primaryGradient,
		'&:hover': {
			background: colorsNew.secondaryGradient,
		},
	},
	buttonContainerSecondary: {
		border: '2px solid white',
		boxShadow: boxShadow(colorsNew.primary),
		'&:hover': {
			border: `2px solid ${colorsNew.primary}`,
			boxShadow: 'none',
			'& *': {
				color: colorsNew.primary,
				fill: colorsNew.primary,
			},
		},
	},
	buttonContainerTertiary: {
		'&:hover': {
			'& *': {
				boxShadow: 'none',
			},
		},
	},
	buttonContainerCancel: {
		background: colorsNew.pink,
	},
	buttonContainerIcon: {
		transition: '0s',
		width: '100%',
		height: '100%',
		background: colorsNew.primary,
		borderRadius: '.3125rem',
		padding: 0,
		boxShadow: '0 0 8px 4px rgba(0,0,0,.16)',
	},
	iconContainerLeft: {
		margin: [[0, 6, 0, 0]],
	},
	iconContainerRight: {
		transition: `${transition}s`,
		margin: [[0, 0, 0, 6]],
	},
	iconContainerCenter: {
		margin: 0,
	},
	primary: {
		background: colorsNew.primary,
		'&:hover': {
			background: colorsNew.secondary,
		},
	},
	cancel: {
		background: colorsNew.pink,
		'&:hover': {
			background: colorsNew.red,
		},
	},
	secondary: {
		backgroundColor: 'transparent',
	},
	tertiary: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: `3px solid ${colorsNew.light3}`,
		backgroundColor: 'transparent',
		textShadow: textShadow(colorsNew.primary),
		boxShadow: boxShadow(colorsNew.primary),
		color: 'white',
		padding: [[9, 20]],
		borderRadius: 5,
		whiteSpace: 'nowrap',
	},
	icon: {
		minWidth: '100%',
		minHeight: '100%',
		padding: 0,
		background: colorsNew.primary,
		transition: '0s',
		display: 'flex',
		'& .contentPrimary': {
			margin: 'auto',
		},
		'&:hover': {
			background: colorsNew.secondary,
			'& .contentPrimary': {
				transform: 'translateY(0)',
			},
		},
	},
	social: {
		border: `1px solid ${colorsNew.white}`,
		backgroundColor: colorsNew.dark2,
	},
	text: {
		transition: `${transition}s`,
	},
	textPrimary: {},
	textSecondary: {
		textShadow: textShadow(colorsNew.primary),
	},
	buttonContainerDisabled: {
		'&, & *': {
			cursor: 'not-allowed',
		},
		background: colorsNew.gray3,
		'&:hover': {
			background: colorsNew.gray3,
		},
	},
	buttonDisabled: {
		cursor: 'not-allowed',
		border: 'none',
		color: colorsNew.gray3,
		'&:hover': {
			color: colorsNew.gray3,
		},
	},
	buttonPrimaryDisabled: {
		background: colorsNew.gray3,
		'&:hover': {
			background: colorsNew.gray3,
		},
	},
	buttonSecondaryDisabled: {
		background: 'transparent',
		borderColor: colorsNew.gray3,
		boxShadow: 'none',
		color: colorsNew.gray3,
		'&:hover': {
			background: 'transparent',
			borderColor: colorsNew.gray3,
			boxShadow: 'none',
		},
	},
	textSecondaryDisabled: {
		color: `${colorsNew.gray3} !important`,
		transform: 'translateY(0) !important',
		textShadow: 'none',
		'& *': {
			color: `${colorsNew.gray3} !important`,
		},
	},
}

const ButtonIcon = ({ classes, iconPosition, iconSize, icon, type }) => (
	<div className={classNames(
		iconPosition === 'left' && classes.iconContainerLeft,
		iconPosition === 'right' && classes.iconContainerRight,
		iconPosition === 'center' && classes.iconContainerCenter,
	)}
	>
		<Icon
			icon={icon}
			size={iconSize}
			glow={!['primary', 'social'].includes(type) && colorsNew.primary}
		/>
	</div>
)
const Wrapper = ({ children, tooltipText }) => {
	if (tooltipText) {
		return (
			<TooltipWraper
				tooltipText={tooltipText}
			>{children}
			</TooltipWraper>
		)
	}
	return children
}

const Button = ({
	classes, type = 'primary', iconPosition = type === 'icon' ? 'center' : 'right',
	onClick = () => { }, className, children, disabled,
	tooltipText, iconSize = 16, icon, routeId, routeParams, isLoading, htmlType = 'button',
	style, typography, color, border,
}) => {
	const buttonIconProps = { classes, iconPosition, iconSize, icon, type }

	return (
		<Wrapper tooltipText={tooltipText}>
			<div
				className={classNames(
					classes.root,
					type === 'primary' && classes.buttonContainerPrimary,
					type === 'secondary' && classes.buttonContainerSecondary,
					type === 'tertiary' && classes.buttonContainerTertiary,
					type === 'cancel' && classes.buttonContainerCancel,
					type === 'icon' && classes.buttonContainerIcon,
					disabled && classes.buttonContainerDisabled,
					{
						[classes.buttonPrimaryDisabled]: disabled && type === 'primary',
						[classes.buttonSecondaryDisabled]: disabled && type === 'secondary',
					},
					className,
				)}
				style={style}
			>
				<button
					/* eslint-disable-next-line react/button-has-type */
					type={htmlType}
					onClick={(e) => {
						if (routeId) {
							return pushRouteClient({ routeId, routeParams })
						}
						return onClick(e)
					}}
					disabled={disabled || isLoading}
					className={classNames(
						classes.button,
						classes[type],
						disabled && classes.buttonDisabled,
					)}
					style={{ ...style, border }}
					aria-disabled={disabled || isLoading}
				>
					<div className={classNames(classes.contentPrimary, { contentPrimary: !disabled })}>
						{icon && iconPosition === 'left' && <ButtonIcon {...buttonIconProps} />}
						{type === 'icon'
							? <ButtonIcon {...buttonIconProps} />
							: (
								<Typography
									className={classNames(
										classes.text,
										type === 'primary' && classes.textPrimary,
										type === 'secondary' && classes.textSecondary,
										{ [classes.textSecondaryDisabled]: type === 'secondary' && disabled },
									)}
									variant={typography || (type === 'tertiary'
										? typographyVariants.microBold
										: typographyVariants.button)
									}
									color={color}
								>
									{children}
								</Typography>
							)}
						{icon && iconPosition === 'right' && <ButtonIcon {...buttonIconProps} />}
					</div>
				</button>
			</div>
		</Wrapper>
	)
}

export default linkConnector(withStyles(styles, { name: 'MuiButtonNew' })(Button))
