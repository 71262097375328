export default {
	type: 'object',
	properties: {
		userId: { type: 'string' },
		projectId: { type: 'string' },
		brandId: { type: 'string' },
		isProfane: { type: 'boolean' },
		isSelfDare: { type: 'boolean' },
		paymentInfo: { type: 'object' },
		creatorData: {
			type: 'object',
			properties: {
				twitchId: { type: 'string' },
				displayName: { type: 'string' },
				img: { type: 'string' },
			},
		},
		chosenInitialProductIndex: { type: 'number' },
		isUserFirstEventDare: { type: 'boolean' },
		userHasGame: { type: 'boolean' },
		status: { type: 'string' },
	},
	required: ['projectId', 'isProfane', 'isSelfDare'],
	additionalProperties: false,
}
