import {	BRAND_SETTINGS_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import setBrandSettingsSchema from 'root/src/shared/descriptions/formSchemas/setBrandSettingsSchema'
import brandSettingsSelector from 'root/src/client/logic/form/selectors/brandSettingsSelector'

export const brandSettingsFields = [
	{
		fieldId: 'brandName',
		inputType: 'text',
		labelFieldText: [
			{
				text: 'Publisher Name',
			},
		],
		required: true,
	},
	{
		fieldId: 'brandWebsite',
		inputType: 'text',
		labelFieldText: [
			{
				text: 'Website',
			},
		],
		required: true,
	},
	{
		fieldId: 'brandTwitter',
		inputType: 'text',
		labelFieldText: [
			{
				text: 'Twitter',
			},
		],
		placeholder: 'Optional',
		required: false,
	},
]

export default {
	[BRAND_SETTINGS_FORM_MODULE_ID]: {
		moduleType: 'form',
		schema: setBrandSettingsSchema,
		title: 'Publisher Settings',
		prePopulateForm: brandSettingsSelector,
		prePopulateFormOn: 'brandSettings',
		fields: brandSettingsFields,
		submits: [
			{
				label: 'Save',
				buttonType: 'primaryButton',
			},
		],
		links: [
			{
				routeId: ACCOUNT_SETTINGS_ROUTE_ID,
				label: 'Go Back',
			},
		],
	},
}
