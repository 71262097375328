import { GET_UPLOAD_SIGNED_URL } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import getUploadSignedUrlPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getUploadSignedUrlPayloadSchema'
import getUploadSignedUrlResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getUploadSignedUrlResponseSchema'

export const payloadSchema = getUploadSignedUrlPayloadSchema
export const responseSchema = getUploadSignedUrlResponseSchema

export default {
	[GET_UPLOAD_SIGNED_URL]: {
		authentication: authenticationTypes.authenticated,
		endpointType: otherEndpointType,
		payloadSchema,
		responseSchema,
	},
}
