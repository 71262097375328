import {
	AGENCY_ACCOUNT_MANAGERS_LIST_MODULE_ID, DELETE_ACCOUNT_MANAGER_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import always from 'ramda/src/always'
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants'

export default {
	[AGENCY_ACCOUNT_MANAGERS_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		tableColumns: [
			['Email', 'email'],
			['ID', 'userId'],
		],
		tableItemActions: [
			{
				header: 'Delete Account manager',
				name: 'Delete',
				highlighted: always(true),
				fn: item => (dispatch) => {
					const { userId } = item
					return dispatch(displayModal(
						DELETE_ACCOUNT_MANAGER_MODAL_MODULE_ID, { accountManagerUserId: userId },
					))
				},
			},
		],
		listTitle: 'Account Managers',
		recordType: dropEvent,
		endpointId: [agencyEndpointIds.GET_AGENCY_ACCOUNT_MANAGERS],
	},
}
