import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
const providers = {
    youtube: 'youtube',
    google: 'google',
    twitch: 'twitch',
    facebook: 'facebook',
    tiktok: 'tiktok',
    any: 'any',
};
export const providersLabelMap = {
    [providers.twitch]: 'Twitch',
    [providers.youtube]: 'YouTube',
    [providers.google]: 'Google',
    [providers.facebook]: 'Facebook',
    [providers.tiktok]: 'TikTok',
};
export const platformsLabelMap = {
    [providers.twitch]: 'Twitch',
    [providers.youtube]: 'YouTube',
    [providers.google]: 'Google',
    [providers.facebook]: 'Facebook',
    [providers.tiktok]: 'TikTok',
};
export const providersIconMap = {
    [providers.twitch]: faTwitch,
    [providers.youtube]: faYoutube,
    [providers.google]: faGoogle,
    [providers.facebook]: faFacebook,
    [providers.tiktok]: faTiktok,
};
export const darePlatformsMap = [
    providers.twitch,
    providers.youtube,
    providers.tiktok,
];
export const darePlatformIcons = {
    [providers.twitch]: faTwitch,
    [providers.youtube]: faYoutube,
    [providers.tiktok]: faTiktok,
};
export default providers;
