import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'

export default routeRestriction => (dispatch, getState) => {
	const state = getState()
	const { fn, redirectRoute } = routeRestriction
	const condition = fn(state)
	if (!condition) {
		pushRouteClient(redirectRoute)
	}
	return condition
}
