import {
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'
// this ourUrl was designed for backend so it works only from lambda
export const ourUrl = process.env.RECORD_SET || 'localhost:8585'
export const urlProtocol = process.env.RECORD_SET ? 'https' : 'http'

export const profileRouteMap = {
	twitch: VIEW_CREATOR_DATA_ROUTE_ID,
	youtube: VIEW_YT_CREATOR_DATA_ROUTE_ID,
	tiktok: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	facebook: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
}

export const profilePathnameMap = {
	twitch: routeUrls.creatorsTwitch,
	youtube: routeUrls.creatorsYoutube,
	tiktok: routeUrls.creatorsTiktok,
	facebook: routeUrls.creatorsFacebook,
}
