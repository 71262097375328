var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export var HTTPMethod;
(function (HTTPMethod) {
    HTTPMethod["GET"] = "GET";
    HTTPMethod["POST"] = "POST";
    HTTPMethod["PUT"] = "PUT";
    HTTPMethod["DELETE"] = "DELETE";
    HTTPMethod["PATCH"] = "PATCH";
    HTTPMethod["HEAD"] = "HEAD";
})(HTTPMethod || (HTTPMethod = {}));
export const fetcher = (config) => __awaiter(void 0, void 0, void 0, function* () {
    const url = new URL(config.url);
    if (config.query) {
        Object.entries(config.query).forEach(([key, value]) => {
            url.searchParams.append(key, value);
        });
    }
    const response = yield fetch(url.toString(), Object.assign(Object.assign({}, config), { method: config.method }));
    if (!response.ok) {
        console.error(response.status, yield response.text(), config);
        throw new Error('Something went wrong', { cause: response });
    }
    return response.json();
});
