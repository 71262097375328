import { SET_PREFERRED_PLATFORM } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import setPreferredPlatformPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setPreferredPlatformPayloadSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

const payloadSchema = setPreferredPlatformPayloadSchema

export default {
	[SET_PREFERRED_PLATFORM]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.creator,
		endpointType: otherEndpointType,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
