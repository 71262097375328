import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { dropKey } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { dropKeyRewardsSchema } from 'root/src/shared/rewards/schemas/dropKeyRewards.schema'
import { getDropKeyRewardsSchema } from 'root/src/shared/rewards/schemas/getDropKeyRewards.schema'

// TODO: apply typing
export const getDropKeyRewardsEndpoint = {
	authentication: authenticationTypes.authenticated,
	authenticationRole: userGroups.brandUser,
	endpointType: listEndpointType,
	recordType: dropKey,
	payloadSchema: getDropKeyRewardsSchema,
	responseSchema: dropKeyRewardsSchema,
}
