import {
	RENFIELD_RULES_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

import {
	RENFIELD_RULES_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[RENFIELD_RULES_ROUTE_ID]: {
		url: '/renfield-rules',
		modules: [
			RENFIELD_RULES_MODULE_ID,
		],
	},
}
