import view from 'ramda/src/view'
import stringify from 'qs/lib/stringify'
import routes from 'root/src/shared/descriptions/routes'
import createRouteUrlRegexes from 'root/src/client/logic/route/util/createRouteUrlRegexes'
import {
	routeIdToPathLens,
} from 'root/src/client/logic/route/lenses'

/**
 * @param allRoutes
 * @returns {(function({routeId: string, routeParams: object, query?: object, hash?: string}): (string|undefined))}
 */
export const urlFromRouteObjHof = (allRoutes) => {
	const routeRegexes = createRouteUrlRegexes(allRoutes)
	return ({ routeId, routeParams, query, hash }) => {
		if (!routeId) return '/' // TODO @next

		try {
			const url = view(
				routeIdToPathLens(routeId),
				routeRegexes,
			)(routeParams)
			const queryParams = stringify(query)
			const hashParams = stringify(hash)
			return url + (queryParams ? `?${queryParams}` : '') + (hashParams ? `#${hashParams}` : '')
		} catch (e) {
			console.error(JSON.stringify({ routeId, routeParams, query, hash }, null, 2))
			throw e
		}
	}
}

export default urlFromRouteObjHof(routes)
