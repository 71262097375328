import { ADMIN_GET_TOKEN } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import adminGetTokenPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/adminGetTokenPayloadSchema'
import adminGetTokenResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/adminGetTokenResponseSchema'

const payloadSchema = adminGetTokenPayloadSchema
const responseSchema = adminGetTokenResponseSchema

export default {
	[ADMIN_GET_TOKEN]: {
		payloadSchema,
		responseSchema,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: gatewayEndpointType,
	},
}
