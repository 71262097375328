var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import isEmpty from 'ramda/src/isEmpty';
import pick from 'ramda/src/pick';
import getExpirationMinutes from 'root/src/client/logic/auth/util/getExpirationMinutes';
import { buildCookie, getCookies } from 'root/src/client/v2/browser/helpers/cookies';
import authDetermined from 'root/src/client/logic/app/actions/authDetermined';
import refreshTokenFn from 'root/src/client/logic/auth/thunks/refreshToken';
export class ServerSideAuthService {
    constructor(context, store) {
        this.context = context;
        this.store = store;
        this.cookies = this.getCookies();
        this.session = this.getAuthSession();
    }
    getCookies() {
        const cookies = this.context.ctx.req.cookies || {};
        return isEmpty(cookies) ? getCookies(this.context.ctx.req.headers.Cookie) : cookies;
    }
    getAuthSession() {
        return pick(['accessToken', 'email', 'refreshToken', 'userId', 'groups', 'parentUserId'], this.cookies || {});
    }
    isStaleAuth() {
        var _a, _b;
        const isExpiring = (!((_a = this.session) === null || _a === void 0 ? void 0 : _a.accessToken) || getExpirationMinutes(this.session.accessToken) <= 10);
        return Boolean(((_b = this.session) === null || _b === void 0 ? void 0 : _b.refreshToken) && isExpiring);
    }
    isLogOut() {
        const query = this.context.ctx.query;
        return query.logout === 'true';
    }
    handleSession() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.isLogOut()) {
                    yield this.handleLogOut();
                    return null;
                }
                if (this.isStaleAuth()) {
                    return yield this.handleStaleAuth();
                }
                return this.handleCurrentAuth();
            }
            catch (err) {
                console.error(err);
                yield this.handleLogOut();
                return null;
            }
        });
    }
    updateSession(session) {
        this.session = session;
        this.context.ctx.req.session = session;
    }
    handleAuth() {
        return __awaiter(this, void 0, void 0, function* () {
            const session = yield this.handleSession();
            this.updateSession(session);
        });
    }
    handleLogOut() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            (_a = this.context.ctx.res) === null || _a === void 0 ? void 0 : _a.setHeader('Set-Cookie', [
                buildCookie('email', '', -1),
                buildCookie('refreshToken', '', -1),
                buildCookie('accessToken', '', -1),
                buildCookie('userId', '', -1),
                buildCookie('groups', '', -1),
                buildCookie('parentUserId', '', -1),
            ]);
            this.store.dispatch(authDetermined());
        });
    }
    handleStaleAuth() {
        return __awaiter(this, void 0, void 0, function* () {
            // @ts-ignore
            const session = yield this.store.dispatch(refreshTokenFn(this.session, () => {
                //
            }, ({ accessToken, refreshToken, email, userId, accessTokenExp, refreshTokenExp, groups, parentUserId, }) => {
                var _a;
                (_a = this.context.ctx.res) === null || _a === void 0 ? void 0 : _a.setHeader('Set-Cookie', [
                    buildCookie('email', email, refreshTokenExp),
                    buildCookie('refreshToken', refreshToken, refreshTokenExp),
                    buildCookie('accessToken', accessToken, accessTokenExp),
                    buildCookie('userId', userId, accessTokenExp),
                    buildCookie('groups', groups, accessTokenExp),
                    buildCookie('parentUserId', parentUserId, accessTokenExp),
                ]);
            }));
            return session;
        });
    }
    handleCurrentAuth() {
        this.store.dispatch(authDetermined(this.session));
        return this.session;
    }
}
