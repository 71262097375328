import { GET_TOP_CONTRIBUTORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { topContributors } from 'root/src/shared/descriptions/endpoints/recordTypes'
import getTopContributorsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getTopContributorsResponseSchema'
import getTopContributorsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getTopContributorsPayloadSchema'

export const responseSchema = getTopContributorsResponseSchema
export const payloadSchema = getTopContributorsPayloadSchema

export default {
	[GET_TOP_CONTRIBUTORS]: {
		endpointType: recordEndpointType,
		recordType: topContributors,
		responseSchema,
		payloadSchema,
	},
}
