import { createTheme } from '@mui/material';
export const muiDarkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    // it fixes time picker spacing
                    width: '100% !important',
                },
            },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        MuiPickersDay: {
            styleOverrides: {
                dayWithMargin: {
                    // this disables weird spaces for hidden days
                    margin: '0px !important',
                },
            },
        },
    },
});
