import reduxConnector from 'root/src/shared/util/reduxConnector'

import currentRouteModuleTypes from 'root/src/client/logic/route/selectors/currentRouteModuleTypes'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import isMobileResSelector from 'root/src/client/logic/app/selectors/isMobileResSelector'
import oauthAuthenticate from 'root/src/client/logic/auth/thunks/oauthAuthenticate'
import variantRouteIndexSelector from 'root/src/client/logic/route/selectors/variantRouteIndexSelector'
import variantModulesSelector from 'root/src/client/logic/route/selectors/variantModulesSelector'
import changeVariantRouteIndex from 'root/src/client/logic/route/thunks/changeVariantRouteIndex'
import chatWidgetSelector from 'root/src/client/logic/route/selectors/chatWidgetSelector'
import discordWidgetSelector from 'root/src/client/logic/route/selectors/discordWidgetSelector'
import getUserDataSelector from 'root/src/client/logic/api/selectors/getUserDataSelector'
import removeAuthToken from 'root/src/client/logic/userData/thunks/removeAuthToken'
import isTokenInvalidSelector from 'root/src/client/logic/creatorData/selectors/isTokenInvalidSelector'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import tiktokViewEventSelector from 'root/src/client/logic/route/selectors/tiktokViewEventSelector'
import modalModuleIdSelector from 'root/src/client/logic/modal/selectors/modalModuleIdSelector'
import userGroupsSelector from 'root/src/client/logic/auth/selectors/userGroupsSelector'

// TODO *priority* might be worth to add query and routeParams somewhere here in global context with
//  moduleProps at src/client/web/base/RenderModules.js:19
export default reduxConnector(
	[
		['currentRouteId', currentRouteId],
		['currentRouteModuleTypes', currentRouteModuleTypes],
		['isMobileRes', isMobileResSelector],
		['variantRouteIndex', variantRouteIndexSelector],
		['variantModules', variantModulesSelector],
		['chatWidget', chatWidgetSelector],
		['discordWidget', discordWidgetSelector],
		['getUserData', getUserDataSelector],
		['isTokenInvalid', isTokenInvalidSelector],
		['tiktokViewEvent', tiktokViewEventSelector],
		['modalModuleId', modalModuleIdSelector],
		['userGroups', userGroupsSelector],
	],
	[
		['oauthAuthenticate', oauthAuthenticate],
		['changeVariantRouteIndex', changeVariantRouteIndex],
		['removeAuthToken', removeAuthToken],
		['displayModal', displayModal],
	],
)
