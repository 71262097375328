export var PointsTransactionType;
(function (PointsTransactionType) {
    PointsTransactionType["POINTS_AWARD"] = "pointsAward";
    PointsTransactionType["ADMIN_POINTS_MODIFICATION"] = "adminPointsModification";
    PointsTransactionType["PAYPAL_POINTS_PAYOUT_PENDING"] = "paypalPointsPayoutPending";
    PointsTransactionType["PAYPAL_POINTS_PAYOUT_DONE"] = "paypalPointsPayoutDone";
    PointsTransactionType["PAID_OFF_PLATFORM"] = "paidOffPlatform";
})(PointsTransactionType || (PointsTransactionType = {}));
export var PointsApplicationState;
(function (PointsApplicationState) {
    PointsApplicationState["APPROVED"] = "APPROVED";
    PointsApplicationState["PENDING"] = "PENDING";
    PointsApplicationState["REJECTED"] = "REJECTED";
})(PointsApplicationState || (PointsApplicationState = {}));
