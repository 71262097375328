/* eslint-disable no-alert */
import { modalContextImperative } from 'root/src/client/v2/common/contexts/modal.context'
import { CampaignReportModal } from 'root/src/client/v2/_pages/campaign-creators/components/CampaignReportModal/CampaignReportModal'

export default (drop, options) => async () => {
	modalContextImperative.show(
		<CampaignReportModal
			reportOptions={{
				dropEventId: drop.id,
				to: getToDate(drop),
				from: drop.liveDate,
				...options,
			}}
		/>,
	)
}

function getToDate(drop) {
	const { expirationDate } = drop
	if (!expirationDate) {
		return new Date().toISOString()
	}
	const isExpired = new Date(expirationDate) < new Date()
	return isExpired ? expirationDate : new Date().toISOString()
}
