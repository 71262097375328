import { RewardType } from 'root/src/shared/rewards/constants'

export const getRewardPayloadSchema = {
	type: 'object',
	properties: {
		rewardId: { type: 'string' },
		brandId: { type: 'string' },
		rewardType: {
			type: 'string',
			enum: [
				RewardType.KEY,
				RewardType.CODE,
				RewardType.CUSTOM,
				RewardType.BUNDLE,
			],
		},
	},
	required: ['rewardId', 'rewardType', 'brandId'],
	additionalProperties: false,
}
