var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest';
import { GET_UPLOAD_SIGNED_URL } from 'root/src/shared/descriptions/endpoints/endpointIds';
import ajax from 'root/src/shared/util/ajax';
import outputs from 'root/cfOutput';
const { domainName } = outputs;
/**
 * Uploads a file to S3 and returns the URL
 * @param file
 * @param dispatch
 * @param minutesTtl
 */
export default function uploadFile(file, dispatch, minutesTtl) {
    return __awaiter(this, void 0, void 0, function* () {
        const payload = {
            fileName: file.name,
            minutesTtl,
        };
        const { body } = yield dispatch(apiRequest(GET_UPLOAD_SIGNED_URL, payload));
        const { signedUrl, contentType, fileName, expires } = body;
        yield ajax({
            url: signedUrl,
            method: 'PUT',
            file,
            headers: Object.assign({ 'Content-Type': contentType }, (minutesTtl ? {
                'x-amz-tagging': 'removeAfterExpiration=true',
                expires,
            } : {})),
        });
        return `${domainName}/files/${fileName}`;
    });
}
