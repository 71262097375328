import { memo, useMemo } from 'react'
import { ModuleContextProvider } from 'root/src/client/util/withModuleContext'
import moduleConnector from 'root/src/client/logic/module/connectors/moduleConnector'
import { HELPER_WIDGET_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import ErrorBoundary from 'root/src/client/web/base/ErrorBoundary'
import LoadingError from 'root/src/client/web/base/LoadingError'
import dynamic from 'next/dynamic'
import { comparePropsFn } from 'root/src/shared/util/stateCompare'
import { DynamicImportPlaceholder } from 'root/src/client/web/base/DynamicImportPlaceholder'

const BackgroundBlob = dynamic(() => import('./BackgroundBlob'))

/*
	These modules are dynamic, because we don't want to import all of these component
	when they're not used
 */
const moduleMap = {
	form: dynamic(() => import('../form/FormModule'), { loading: () => <DynamicImportPlaceholder /> }),
	stepForm: dynamic(() => import('../stepForm/StepFormModule'), { loading: () => <DynamicImportPlaceholder /> }),
	list: dynamic(() => import('../list/ListModule'), { loading: () => <DynamicImportPlaceholder /> }),
	record: dynamic(() => import('../record/RecordModule'), { loading: () => <DynamicImportPlaceholder /> }),
	userData: dynamic(() => import('../userData/UserDataModule'), { loading: () => <DynamicImportPlaceholder /> }),
	static: dynamic(() => import('../static/StaticModule'), { loading: () => <DynamicImportPlaceholder /> }),
	bannerHeader: dynamic(() => import('../header/BannerHeader'), { loading: () => <DynamicImportPlaceholder /> }),
	misc: dynamic(() => import('../misc/MiscModule'), { loading: () => <DynamicImportPlaceholder /> }),
}

export const RenderModuleUnconnected = memo(({
	moduleType, routeId, moduleId, moduleIndex = 0, renderSidebar,
	moduleKey, backgroundData, omitRouteId,
}) => {
	if (!routeId && !omitRouteId) return null
	const Module = useMemo(() => moduleMap[moduleType], [moduleType])

	const moduleProps = {
		key: moduleId,
		value: {
			moduleId,
			moduleIndex,
			routeId,
			moduleKey,
			renderSidebar,
		},
	}

	if (!Module) {
		return null
	}

	return 	(
		<ErrorBoundary FallbackComponent={LoadingError}>
			<ModuleContextProvider {...moduleProps}>
				<div
					key={moduleId}
					style={
						// order is set to -1 here in order to make sure HelperWidget module always
						// renders on top just below the navbar (Keys and Products of brand user was the issue)
						moduleId === HELPER_WIDGET_MODULE_ID ? { order: -1 } : {}
					}
				>
					{backgroundData?.map(data => (
						<BackgroundBlob
							key={data?.image.src}
							backgroundData={data}
						/>
					))}
					<Module />
				</div>
			</ModuleContextProvider>
		</ErrorBoundary>
	)
}, comparePropsFn([
	'moduleType', 'routeId', 'moduleId', 'moduleIndex', 'renderSidebar',
	'moduleKey', 'backgroundData',
]))

export default moduleConnector(RenderModuleUnconnected)
