import {	GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/projectResponseSchema'
import getProjectsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getProjectsPayloadSchema'

export const responseSchema = projectResponseSchema
export const payloadSchema = getProjectsPayloadSchema

export default {
	[GET_PROJECTS]: {
		authentication: authenticationTypes.optional,
		endpointType: listEndpointType,
		recordType: project,
		responseSchema,
		payloadSchema,
	},
}
