import reduce from 'ramda/src/reduce'
import keys from 'ramda/src/keys'
import prop from 'ramda/src/prop'

import { embeddedStoreLenses } from 'root/src/client/logic/embedded/lenses'
import fieldsDefaultSelector from 'root/src/client/logic/embedded/selectors/fieldsDefaultSelector'

const { viewFieldData } = embeddedStoreLenses

export default (state, { moduleId }) => {
	// eslint-disable-next-line max-len
	const fieldData = { ...fieldsDefaultSelector(state, { moduleId }), ...viewFieldData(moduleId, state) }
	return reduce((acc, field) => {
		const fieldDataValue = prop(field, fieldData)
		if (!fieldDataValue) {
			return acc
		}
		return { ...acc, [field]: fieldDataValue }
	}, {}, keys(fieldData))
}
