export default {
	type: 'object',
	properties: {
		id: { type: 'string' },
		paypalSubscriptionId: { type: 'string' },
		method: { type: 'string' },
	},
	required: ['id', 'method'],
	additionalProperties: false,
}
