import { GET_CAMPAIGN_TRANSACTIONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import getCampaingTransactionsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getCampaingTransactionsResponseSchema'
import campaignIdPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/campaignIdPayloadSchema'

export default {
	[GET_CAMPAIGN_TRANSACTIONS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: gatewayEndpointType,
		responseSchema: getCampaingTransactionsResponseSchema,
		payloadSchema: campaignIdPayloadSchema,
	},
}
