import React from 'react'

export default ({ className, alt }) => (
	<svg
		/* eslint-disable */
		className={className}
		alt={alt}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="-2 -2 24 24"
	>
		<g fillRule="evenodd">
			<path d="M18.182 9.169v.836a8.181 8.181 0 1 1-4.852-7.478.91.91 0 0 0 .74-1.66A10 10 0 1 0 20 10.005v-.837a.91.91 0 0 0-1.818 0z" />
			<path d="M7.593 8.689a.946.946 0 0 0-1.32 0 .904.904 0 0 0 0 1.295l2.8 2.748a.946.946 0 0 0 1.32 0l9.334-9.169a.904.904 0 0 0 0-1.295.946.946 0 0 0-1.32 0l-8.674 8.52-2.14-2.1z" />
		</g>
	</svg>
)
