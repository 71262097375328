import {
	VERIFICATION_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import {
	LOGIN_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[VERIFICATION_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'verificationResult',
		pageContent: {
			title: 'Welcome to Dare Drop!',
			paragraphs: [
				['Your account has been verified. Please sign in to use your account.'],
			],
			link: LOGIN_ROUTE_ID,
			linkLabel: 'Sign in to continue',
		},

	},
}
