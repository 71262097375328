import {
	CLEAR_PARTIAL_FORM_KEYS,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import payloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/clearPartialFormKeysPayloadSchema'

export default {
	[CLEAR_PARTIAL_FORM_KEYS]: {
		authentication: authenticationTypes.optional,
		payloadSchema,
	},
}
