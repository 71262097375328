import {
	CREATORS_SECTION_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { GET_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	getCreatorsKey,
	getProjectsInfoKey,
} from 'root/src/shared/constants/keys'
import { isProdEnv } from 'root/src/shared/util/envSelect'

const scrollItemBreakpoints = (width) => {
	if (width < 780) return 1
	if (width < 1200) return 2
	return 3
}

const sliderWidth = (width) => {
	if (width < 1820) return 80
	if (width < 2000) return 75
	if (width < 2250) return 70
	if (width < 2400) return 65
	if (width < 2600) return 60
	return 55
}

export default {
	[CREATORS_SECTION_MODULE_ID]: {
		moduleType: 'list',
		listType: 'sliderCardsListType',
		cardListType: 'creatorCard',
		endpointId: [GET_CREATORS],
		recordType: 'creator',
		scrollItemBreakpoints,
		sliderWidth,
		listPayload: {
			section: getCreatorsKey,
			subSection: getProjectsInfoKey,
			pageItemLength: 6,
			value:
				isProdEnv ? [
					{ platform: 'twitch', platformId: '510167672' }, // senk3tsu
					{ platform: 'twitch', platformId: '541129770' }, // ChilPhil
					{ platform: 'twitch', platformId: '65329572' }, // Delirya
					{ platform: 'twitch', platformId: '123330600' }, // DudemanOG
					{ platform: 'twitch', platformId: '749969' }, // Hean
					{ platform: 'twitch', platformId: '210181681' }, // Koazy
					{ platform: 'twitch', platformId: '178087953' }, // Mike_and_Chill
					{ platform: 'twitch', platformId: '39903942' }, // SakiSakura
					{ platform: 'twitch', platformId: '114080187' }, // SilkyC
					{ platform: 'twitch', platformId: '161708122' }, // SinSchism
					{ platform: 'twitch', platformId: '12424403' }, // Softwerker
					{ platform: 'twitch', platformId: '160650262' }, // CoCoConfession
					{ platform: 'twitch', platformId: '208789603' }, // ThePandaleo
					{ platform: 'twitch', platformId: '420201357' }, // xchilphilx
					{ platform: 'twitch', platformId: '15666801' }, // mdee14
					{ platform: 'twitch', platformId: '562963244' }, // princess__ar
					{ platform: 'twitch', platformId: '566394634' }, // amateurarchaeologist
					{ platform: 'twitch', platformId: '151007220' }, // theorderofdylan
					{ platform: 'twitch', platformId: '414493232' }, // RADdoesGames
					{ platform: 'twitch', platformId: '15860741' }, // radgryd
					{ platform: 'twitch', platformId: '1581126534' }, // deadpiratebrandon
					{ platform: 'twitch', platformId: '149749983' }, // thegreenchkrta
					{ platform: 'twitch', platformId: '102469743' }, // amunedal
					{ platform: 'twitch', platformId: '418292736' }, // zepxp
					{ platform: 'twitch', platformId: '45290199' }, // BlaiseMcBaine
					{ platform: 'twitch', platformId: '66178008' }, // beacan_
					{ platform: 'twitch', platformId: '41716562' }, // flexy5
					{ platform: 'twitch', platformId: '554832700' }, // voldaz__
					{ platform: 'twitch', platformId: '105519567' }, // penclavegames
					{ platform: 'twitch', platformId: '271291417' }, // kalesdad
					{ platform: 'twitch', platformId: '47337337' }, // pxterz
					{ platform: 'twitch', platformId: '174702125' }, // rhariane
				] : [
					{ platform: 'twitch', platformId: '492628980' },
					{ platform: 'twitch', platformId: '489374197' },
					{ platform: 'twitch', platformId: '419843502' },
					{ platform: 'twitch', platformId: '498349293' },
					{ platform: 'twitch', platformId: '545113998' },
					{ platform: 'twitch', platformId: '148541266' },
				],
		},
	},
}
