export default {
	type: 'object',
	properties: {
		fileUrl: { type: 'string' },
		urlsData: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					url: { type: 'string' },
					shortUrl: { type: 'string' },
					clicks: { type: 'integer' },
					additionalInfo: { type: 'string' },
				},
			},
		},
	},
}
