import includes from 'ramda/src/includes'
import equals from 'ramda/src/equals'
import matchPath from 'root/src/client/logic/route/util/matchPath'
import { routeDescriptionLenses } from 'root/src/client/logic/route/lenses'
import hasProperAuthSelector from 'root/src/client/logic/auth/selectors/hasProperAuthSelector'
import routeDescriptions from 'root/src/shared/descriptions/routes'
import {
	LOGIN_ROUTE_ID,
	SIGN_UP_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import {
	GET_STARTED_MODULE_ID,
	SIGN_IN_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import defaultRoute from 'root/src/client/logic/route/util/defaultRoute'

const { viewAuthentication, viewAuthenticationRole } = routeDescriptionLenses

const authRoutes = [LOGIN_ROUTE_ID, SIGN_UP_ROUTE_ID]
const authModalsMap = {
	LOGIN_ROUTE_ID: SIGN_IN_MODAL_MODULE_ID,
	SIGN_UP_ROUTE_ID: GET_STARTED_MODULE_ID,
}

export default ({ linkHref, onClick }) => (dispatch, getState) => {
	const state = getState()
	const { routeId: currentRouteId } = currentRouteObj(state)
	const matchedPath = matchPath(linkHref)

	if (!matchedPath) return { onClickMod: onClick }

	const { routeId } = matchedPath

	if (includes(routeId, authRoutes) && !includes(currentRouteId, authRoutes)) {
		return {
			onClickMod: (e) => {
				e.preventDefault()
				dispatch(displayModal(authModalsMap[routeId]))
			},
		}
	}

	const reqAuth = viewAuthentication(routeId, routeDescriptions)
	const reqAuthRoles = viewAuthenticationRole(routeId, routeDescriptions)
	const authValid = hasProperAuthSelector(state, { reqAuth, reqAuthRoles })
	const { routeId: redirectRouteId } = defaultRoute(state)

	if (!authValid && equals(redirectRouteId, LOGIN_ROUTE_ID)) {
		return {
			onClickMod: (e) => {
				e.preventDefault()
				dispatch(displayModal(authModalsMap[LOGIN_ROUTE_ID]))
			},
		}
	}
	return { onClickMod: onClick }
}
