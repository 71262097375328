import { GET_PUBLISHER_BANNED_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { publisherBan } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { PublisherBanListType } from 'root/src/shared/@types/publisherBanList';
export const getPublisherBannedCreatorsEndpoint = {
    [GET_PUBLISHER_BANNED_CREATORS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brand,
        endpointType: listEndpointType,
        recordType: publisherBan,
        payloadSchema: {
            type: 'object',
            properties: {
                dropEventId: {
                    type: 'string',
                },
                banListType: {
                    type: 'string',
                    enum: Object.values(PublisherBanListType),
                },
            },
        },
    },
};
