import { REMOVE_PUBLISHER_BANNED_CREATOR } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { publisherBan } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { Platform } from 'root/src/shared/@types/platforms';
import { PublisherBanListType } from 'root/src/shared/@types/publisherBanList';
export const removePublisherBannedCreatorEndpoint = {
    [REMOVE_PUBLISHER_BANNED_CREATOR]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brand,
        endpointType: listEndpointType,
        recordType: publisherBan,
        // responseSchema,
        payloadSchema: {
            type: 'object',
            properties: {
                platform: {
                    type: 'string',
                    enum: Object.values(Platform),
                },
                platformId: {
                    type: 'string',
                },
                banListType: {
                    type: 'string',
                    enum: Object.values(PublisherBanListType),
                },
                dropEventId: {
                    type: 'string',
                },
            },
            required: ['banListType', 'platform', 'platformId'],
        },
    },
};
