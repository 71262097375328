import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import addIndex from 'ramda/src/addIndex'

import routeDescriptions from 'root/src/shared/descriptions/routes'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import {
	currentRouteModuleObjectsHof,
} from 'root/src/client/logic/route/selectors/currentRouteModuleObjects'
import renderSidebarSelector from 'root/src/client/logic/route/selectors/renderSidebarSelector'
import { moduleDescriptionLenses } from 'root/src/client/logic/route/lenses'

const { viewModuleType, viewBackgroundData } = moduleDescriptionLenses

export const currentRouteModuleTypesHof = (
	routeDescriptionObj, moduleDescriptionObj,
) => (state, props) => pipe(
	() => currentRouteModuleObjectsHof(
		routeDescriptionObj, moduleDescriptionObj,
	)(state, props),
	addIndex(map)(
		([moduleId], index) => [
			moduleId,
			viewModuleType(moduleId, moduleDescriptionObj),
			renderSidebarSelector(state, { moduleId }),
			index,
			viewBackgroundData(moduleId, moduleDescriptionObj),
		],
	),
)(state)

export default currentRouteModuleTypesHof(
	routeDescriptions, moduleDescriptions,
)
