import {
	PREVIEW_BRAND_REWARDS_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[PREVIEW_BRAND_REWARDS_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'Review rewards',
	},
}
