import { DROP_SEND_PLATINUM_EMAIL } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import dropSendPlatinumEmailPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/dropSendPlatinumEmailPayloadSchema'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = dropSendPlatinumEmailPayloadSchema

export default {
	[DROP_SEND_PLATINUM_EMAIL]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: otherEndpointType,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
