import join from 'ramda/src/join'

import { SIGN_UP_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import signUpSchema from 'root/src/shared/descriptions/formSchemas/signUpSchema'
import { LOGIN_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { userGroups } from 'root/src/shared/constants/auth'

export default {
	[SIGN_UP_FORM_MODULE_ID]: {
		moduleType: 'form',
		schema: signUpSchema,
		title: 'Sign Up',
		subTitle: [
			'Have an account?',
			{ text: 'Sign In', routeId: LOGIN_ROUTE_ID },
		],
		postSubmitCaption: join('', [
			'By signing up, you agree to our terms of use, privacy policy, and ',
			'cookie policy. Our policies explain how we use your data to ',
			'deliver, improve, and promote our service and our site, and how ',
			'you can exercise your rights to control that use.',
		]),
		fields: [
			{
				fieldId: 'email',
				label: 'Email',
				inputType: 'registerEmail',
			},
			{
				fieldId: 'password',
				label: 'Password',
				inputType: 'password',
				fieldHint: 'Must have a lower & uppercase letter, number and symbol',
			},
			{
				fieldId: 'confirmPassword',
				label: 'Confirm password',
				inputType: 'password',
			},
			// radio
			{
				fieldId: 'userGroup',
				inputType: 'radio',
				labelFieldText: [
					{
						text: 'I\'m a',
					},
				],
				options: [
					{
						label: 'Viewer',
						value: userGroups.viewer,
					},
					{
						label: 'Creator',
						value: userGroups.creator,
					},
					{
						label: 'Brand',
						value: userGroups.brand,
					},
				],
			},
			// Function returns newsletter checkbox if user selects publisher
			({ form }, { moduleKey }) => (form?.[moduleKey]?.formInputs.userGroup === userGroups.brand ? {
				fieldId: 'newsletterSub',
				inputType: 'checkbox',
				label: 'Subscribe to our newsletter',
				value: 'false',
			} : { inputType: null }),
		],
		submits: [
			{ label: 'Sign Up' },
		],
	},
}
