import { BRAND_CUSTOM_PRODUCTS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { brandProduct } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { RewardEndpoint } from 'root/src/shared/rewards/constants'
import identity from 'ramda/src/identity'

export default {
	[BRAND_CUSTOM_PRODUCTS_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		tableColumns: [
			['Name', 'name'],
			['Call to Action', 'cta'],
			['Url', 'url'],
			['Restrictions', 'restrictions', identity, true],
		],
		listTitle: 'Asset Products',
		recordType: brandProduct,
		endpointId: [RewardEndpoint.GET_BRAND_CUSTOM_REWARDS],
	},
}
