import {
	GET_PAYOUT_METHOD,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { payoutMethod } from 'root/src/shared/descriptions/endpoints/recordTypes'
import payoutMethodResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/payoutMethodResponseSchema'

export const responseSchema = payoutMethodResponseSchema

export default {
	[GET_PAYOUT_METHOD]: {
		authentication: authenticationTypes.authenticated,
		endpointType: userDataEndpointType,
		recordType: payoutMethod,
		responseSchema,
	},
}
