import { UPDATE_EVENT } from 'root/src/client/logic/dropEvent/actionIds'
import { DROP_EVENTS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import { listModuleLenses } from 'root/src/client/logic/list/lenses'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'
import __ from 'ramda/src/__'
import merge from 'ramda/src/merge'
import pipe from 'ramda/src/pipe'
import isNil from 'ramda/src/isNil'
import prepend from 'ramda/src/prepend'

const { viewDropEventChild, setDropEventChild } = apiStoreLenses
const { viewListPayload } = listModuleLenses

const updateEvent = (state, payload) => {
	const { id, listStoreKey } = payload

	const eventExists = !pipe(
		viewDropEventChild(id),
		isNil,
	)(state)

	const routeObj = currentRouteObj(state)

	const listStoreKeyMod = listStoreKey || createListStoreKey(
		routeObj, GET_DROP_EVENTS,
		DROP_EVENTS_LIST_MODULE_ID,
		viewListPayload(DROP_EVENTS_LIST_MODULE_ID, moduleDescriptions),
	)

	const eventLens = lensPath(['api', 'records', 'dropEvent', id])
	const listLens = lensPath(['api', 'lists', listStoreKeyMod, 'items'])

	return eventExists
		? over(eventLens, merge(__, payload), state)
		: pipe(
			setDropEventChild(id, payload),
			over(listLens, prepend(id)),
		)(state)
}

export default {
	[UPDATE_EVENT]: updateEvent,
}
