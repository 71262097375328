import {
	UPDATE_PROFILE,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import profilePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/profilePayloadSchema'
import profileResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/profileResponseSchema'

import { profile } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = profilePayloadSchema
export const responseSchema = profileResponseSchema

export default {
	[UPDATE_PROFILE]: {
		authentication: authenticationTypes.authenticated,
		endpointType: recordEndpointType,
		recordType: profile,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
