import moment from 'moment-mini';
import { Platform } from 'root/src/shared/@types/platforms';
import { DashboardSortTypes, CreatorsTableTab } from 'root/src/shared/@types/dashboard';
import { isDropComingSoon } from 'root/src/shared/drops/helpers/isDropComingSoon.helper';
export const defaultDashboardFilters = {
    from: moment()
        .subtract(7, 'days')
        .format(),
    to: moment().format(),
};
export const defaultCreatorsTableFilters = {
    ambassadorsOnly: false,
    platforms: [Platform.Twitch],
    currentPage: 1,
    sortBy: 'minutesViewed',
    sort: DashboardSortTypes.DESC,
};
export const defaultCreatorsTableTab = (dropEvent) => {
    if (!dropEvent) {
        return CreatorsTableTab.Participating;
    }
    if (isDropComingSoon(dropEvent)) {
        return CreatorsTableTab.Waitlist;
    }
    return CreatorsTableTab.Participating;
};
export const defaultCreatorsTableSortBy = (tab) => {
    if (tab === CreatorsTableTab.Waitlist) {
        return 'followerCount';
    }
    return 'minutesViewed';
};
