export default {
	type: 'object',
	properties: {
		creatorData: {
			type: 'object',
			properties: {
				displayName: { type: 'string' },
				image: { type: 'string' },
				username: { type: 'string' },
				creatorGamePlatforms: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
				mnemonicId: { type: 'string' },
				tier: { type: 'string' },
				platformId: { type: 'string' },
				ccv: { type: 'number' },
			},
		},
	},
	required: [],
	additionalProperties: false,
}
