import path from 'ramda/src/path'
import replace from 'ramda/src/replace'
import outputs from 'root/cfOutput'
import routeDescription from 'root/src/shared/descriptions/routes'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'

const { domainName } = outputs

export default (state) => {
	const { routeId, routeParams = {} } = currentRouteObj(state)
	const { recordId } = routeParams
	const route = path([routeId], routeDescription)
	const canonical = `https://${domainName}`
	if (route) {
		const { url } = route
		return canonical + replace(/:\w*/, recordId, url)
	}
	return undefined
}
