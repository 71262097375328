export default {
	type: 'object',
	properties: {
		userId: { type: 'string' },
		project: {
			type: 'object',
			properties: {
				projectUrl: { type: 'string' },
				title: { type: 'string' },
			},
			required: ['projectUrl'],
		},
		clip: {
			type: 'object',
			properties: {
				id: { type: 'string' },
				edit_url: { type: 'string' },
			},
		},
		deliveryIssuerPlatformId: { type: 'string' },
	},
	required: ['userId', 'project', 'clip'],
	additionalProperties: false,
}
