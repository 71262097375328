import assoc from 'ramda/src/assoc'
import clamp from 'ramda/src/clamp'
import compose from 'ramda/src/compose'
import dissocPath from 'ramda/src/dissocPath'
import includes from 'ramda/src/includes'
import lensProp from 'ramda/src/lensProp'
import path from 'ramda/src/path'
import prepend from 'ramda/src/prepend'
import prop from 'ramda/src/prop'
import view from 'ramda/src/view'
import without from 'ramda/src/without'
import isAuthenticatedSelector from 'root/src/client/logic/auth/selectors/isAuthenticated'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import validatePromotionCode from 'root/src/client/logic/project/thunks/validatePromotionCode'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import isBrandUserSelector from 'root/src/client/logic/static/selectors/isBrandUserSelector'
import { primaryButton } from 'root/src/client/web/componentTypes'
import { monetaryKey } from 'root/src/shared/constants/keys'
import paymentTypes from 'root/src/shared/constants/paymentTypes'
import {
	CHECK_BRAND_ACTIVE_CAMPAIGN,
	GET_PROJECT, PLEDGE_PROJECT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { paymentMethod } from 'root/src/shared/descriptions/endpoints/recordTypes'
import pledgeProjectPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/pledgeProjectPayloadSchema'
import {
	NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID,
	NO_CAMPAIGN_MODAL_MODULE_ID, PLEDGE_PROJECT_FORM_MODULE_ID,
	PLEDGE_SUCCESS_PAGE_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import {
	LOGIN_ROUTE_ID,
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_PROJECT_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
	VIEWERS_PAGE_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { RewardEndpoint, RewardType } from 'root/src/shared/rewards/constants'

const brandRewardNames = {
	[RewardType.KEY]: 'key',
	[RewardType.CODE]: 'code',
}

export const formatBrandRewardName = (type, plural) => `${brandRewardNames[type]}${plural ? 's' : ''}`
	.trim()

export const pledgeProjectFormStepKeys = {
	payment: 'payment',
	productDares: 'productDares',
	paymentBrand: 'paymentBrand',
}

const { payment, paymentBrand, productDares } = pledgeProjectFormStepKeys

export const multiWayField = {
	fieldId: 'paymentType',
	inputType: 'multiWay',
	variantOptions: [
		{
			id: monetaryKey,
			stepId: paymentBrand,
			label: 'Money',
		},
		{
			id: paymentTypes.brandProduct,
			stepId: productDares,
			label: 'Product',
			onSelectAction: CHECK_BRAND_ACTIVE_CAMPAIGN,
			onSelectProps: ['games'],
			onSelectFailure: displayModal(NO_CAMPAIGN_MODAL_MODULE_ID),
		},
	],
}

export const productCommonFields = [
	multiWayField,
	{
		fieldId: 'products',
		inputType: 'dropdown',
		placeholder: 'Choose Product',
		uniqueItems: true,
		labelFieldText: [
			{
				text: 'Add product',
			},
		],
		required: true,
		isMulti: true,
		asyncOptionsStoreKey: RewardEndpoint.GET_BRAND_REWARDS,
	},
	(state, props = {}) => {
		const { moduleKey } = props
		const brandProductType = path(['form', moduleKey, 'formInputs', 'products', 'type'], state)
		const showField = includes(
			brandProductType,
			[RewardType.KEY, RewardType.CODE],
		)
		if (!showField) return { inputType: null }

		const keys = path(['form', moduleKey, 'formInputs', 'products'], state)
		const assigneesLength = path(['form', moduleKey, 'formInputs', 'assignees', 'length'], state)
				|| path(['api', 'records', 'project', currentRouteParamsRecordId(state), 'assignees', 'length'], state)
				|| 1
		const maxKeys = clamp(1, 99, keys.available - assigneesLength)
		return {
			fieldId: 'amount',
			inputType: 'nonNegativeNumber',
			labelFieldText: [
				{
					text: `Number of ${formatBrandRewardName(brandProductType, true)}`,
				},
			],
			subFieldText: `1-${maxKeys}`,
			required: true,
		}
	},
]

export const formCommon = {
	schema: compose(
		dissocPath(['properties', 'projectUrl']),
		dissocPath(['properties', 'paymentInfo', 'paymentType']),
		dissocPath(['properties', 'paymentInfo', 'paymentData']),
		dissocPath(['additionalProperties']),
		assoc('required',
			without(
				['paymentInfo', 'projectUrl'],
				view(lensProp('required'), pledgeProjectPayloadSchema),
			)),
	)(pledgeProjectPayloadSchema),
	title: 'Payment Information',
	fields: [
		{
			fieldId: 'pledgeAmount',
			inputType: 'amountNumber',
			placeholder: '5',
			labelFieldText: [
				{
					text: 'Amount to Contribute (min. $1)',
				},
			],
		},
		{
			fieldId: 'promotionCode',
			inputType: 'text',
			fieldAuthentication: true,
			fieldUnauthText: [
				{ text: 'Sign In', routeId: LOGIN_ROUTE_ID },
				'to use a code',
			],
			fieldFullWidth: false,
			labelFieldText: [
				{
					text: 'Have a Coupon Code',
				},
			],
			fieldActionButton: {
				label: 'Apply Coupon',
				action: validatePromotionCode,
			},
		},
	],
}

const defaultRoute = {
	routeId: VIEWERS_PAGE_ROUTE_ID,
	query: { modal: PLEDGE_SUCCESS_PAGE_MODULE_ID },
}
const routeMap = {
	authenticated: {
		default: defaultRoute,
		[VIEW_PROJECT_ROUTE_ID]: {
			routeId: VIEW_PROJECT_ROUTE_ID,
			query: { modal: PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
		[VIEW_CREATOR_DATA_ROUTE_ID]: {
			routeId: VIEW_CREATOR_DATA_ROUTE_ID,
			query: { modal: PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
		[VIEW_YT_CREATOR_DATA_ROUTE_ID]: {
			routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
			query: { modal: PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
		[VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID]: {
			routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
			query: { modal: PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
		[VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID]: {
			routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
			query: { modal: PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
	},
	unauthenticated: {
		default: defaultRoute,
		[VIEW_PROJECT_ROUTE_ID]: {
			routeId: VIEW_PROJECT_ROUTE_ID,
			query: { modal: NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
		[VIEW_CREATOR_DATA_ROUTE_ID]: {
			routeId: VIEW_CREATOR_DATA_ROUTE_ID,
			query: { modal: NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
		[VIEW_YT_CREATOR_DATA_ROUTE_ID]: {
			routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
			query: { modal: NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
		[VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID]: {
			routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
			query: { modal: NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
		[VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID]: {
			routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
			query: { modal: NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID },
		},
	},
}

const onSuccessRedirectSelector = (state, { previousRouteId, previousRecordId }) => {
	const isAuthenticated = isAuthenticatedSelector(state)
	const authMap = routeMap[isAuthenticated ? 'authenticated' : 'unauthenticated']
	const { routeId, query } = authMap[previousRouteId] || authMap.default
	return ({
		routeId,
		routeParams: {
			recordId: previousRecordId || currentRouteParamsRecordId(state),
		},
		query,
	})
}

export default {
	[PLEDGE_PROJECT_FORM_MODULE_ID]: {
		moduleType: 'stepForm',
		endpointId: GET_PROJECT,
		recordPayloadMap: [
			['projectUrl', { $sub: ['recordId'] }],
		],
		recordType: paymentMethod,
		dropdownAsyncOptions: [{ endpointId: RewardEndpoint.GET_BRAND_REWARDS }],
		rootStepSelector: state => (isBrandUserSelector(state)
			? paymentBrand
			: payment),
		steps: {
			[payment]: {
				...assoc('isSingleStep', true, formCommon),
				successPage: {
					routeId: VIEWERS_PAGE_ROUTE_ID,
					qury: { modal: PLEDGE_SUCCESS_PAGE_MODULE_ID },
				},
				submits: [{
					customSubmitType: 'paypalButton',
					endpointId: PLEDGE_PROJECT,
					onSuccessRedirectSelector,
				}],
			},
			[paymentBrand]: {
				...formCommon,
				fields: prepend(multiWayField, prop('fields', formCommon)),
				isSingleStep: true,
				submits: [
					{
						customSubmitType: 'paypalButton',
						endpointId: PLEDGE_PROJECT,
						onSuccessRedirectSelector,
					},
				],
			},
			[productDares]: {
				title: 'Payment Information',
				fields: productCommonFields,
				isSingleStep: true,
				submits: [
					{
						label: 'Add Product',
						endpointId: PLEDGE_PROJECT,
						buttonType: primaryButton,
						onSuccessRedirectSelector,
					},
				],
			},
		},
	},
}
