import { GET_COUPONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { COUPON_LIST_ADMIN_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { coupon } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { couponListType } from 'root/src/shared/constants/moduleTypes'

export default {
	[COUPON_LIST_ADMIN_MODULE_ID]: {
		moduleType: 'list',
		listType: couponListType,
		listTitle: 'Coupons',
		recordType: coupon,
		endpointId: [GET_COUPONS],
	},
}
