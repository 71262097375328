export default {
	type: 'object',
	properties: {
		name: {
			type: 'string',
			maxLength: 55,
		},
		cta: {
			type: 'string',
			maxLength: 25,
			errorMessage: {
				required: 'CTA is required',
				maxLength: 'max: 25 characters',
			},
		},
		id: { type: 'string' },
	},
	required: ['name'],
	additionalProperties: false,
}
