import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'
import { setDropDeliveredRewardsUsedPayloadSchema } from 'root/src/shared/rewards/schemas/setDropDeliveredRewardsUsed.payload.schema'

export const setDropDeliveredRewardsUsedEndpoint = {
	authentication: authenticationTypes.authenticated,
	endpointType: otherEndpointType,
	payloadSchema: setDropDeliveredRewardsUsedPayloadSchema,
	middlewares: [VALIDATE_TOKEN_ACCOUNT],
}
