import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { getRewardPayloadSchema } from 'root/src/shared/rewards/schemas/getReward.payload.schema'
import { getRewardResponseSchema } from 'root/src/shared/rewards/schemas/getReward.response.schema'

export const getRewardEndpoint = {
	authentication: authenticationTypes.authenticated,
	authenticationRole: userGroups.admin,
	endpointType: otherEndpointType,
	responseSchema: getRewardResponseSchema,
	payloadSchema: getRewardPayloadSchema,
}
