import { ADD_COMMENT } from 'root/src/client/logic/comment/actionIds'

import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import compose from 'ramda/src/compose'
import prepend from 'ramda/src/prepend'
import add from 'ramda/src/add'
import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'

import { GET_COMMENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import { PROJECT_COMMENTS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

const {	setCommentChild } = apiStoreLenses

export const addComment = (state, { body }) => {
	const { messageId, projectUrl } = body
	const routeObj = currentRouteObj(state)

	const listStoreKey = createListStoreKey(
		routeObj, GET_COMMENTS,
		PROJECT_COMMENTS_LIST_MODULE_ID,
		{ projectUrl },
	)

	const listLens = lensPath(['api', 'lists', listStoreKey, 'items'])
	const commentAmountLens = lensPath(['api', 'records', 'project', projectUrl, 'commentAmount'])

	return compose(
		over(listLens, prepend(messageId)),
		over(commentAmountLens, add(1)),
		setCommentChild(messageId, { ...body, listStoreKey }),
	)(state)
}

export default {
	[ADD_COMMENT]: addComment,
}
