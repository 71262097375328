import { HOMEPAGE_ROLES_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import { PUBLISHERS_PAGE_ROUTE_ID, CREATORS_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import Home1Blob from 'root/src/client/public/backgroundBlobs/home-1.svg'

export default {
	[HOMEPAGE_ROLES_SECTION]: {
		moduleType: 'static',
		staticPageType: 'section',
		backgroundData: [
			{ position: 'right',
				image: Home1Blob,
				style: {
					transform: 'translate(0%, -120px)',
				},
			},
		],
		pageContent: {
			heading: 'Where creators complete dares, to reward their fans.',
			text: 'Join a community of gamers who love a challenge.',
			buttons: [
				{
					label: 'For Creators',
					routeId: CREATORS_PAGE_ROUTE_ID,
					icon: faChevronRight,
				},
				{
					label: 'For Publishers',
					routeId: PUBLISHERS_PAGE_ROUTE_ID,
					icon: faChevronRight,
					type: 'secondary',
				},
			],
		},
	},
}
