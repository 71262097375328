export function downloadFile(url) {
    const urlNameArr = url.split('/');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const name = urlNameArr[urlNameArr.length - 1];
    const link = document.createElement('a');
    link.setAttribute('download', name);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
}
