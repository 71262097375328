import { brandEndpointIds } from 'root/src/shared/brand/brand.constants';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { GetBrandUsersFilter } from 'root/src/shared/brand/brand.types';
export const getBrandUsersEndpoint = {
    [brandEndpointIds.GET_BRAND_USERS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: [AuthGroup.Brand, AuthGroup.BrandAdmin, AuthGroup.BrandUser],
        payloadSchema: {
            type: 'object',
            properties: {
                filter: {
                    type: 'string',
                    enum: Object.values(GetBrandUsersFilter),
                },
            },
        },
        endpointType: otherEndpointType,
    },
};
