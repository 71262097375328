import compose from 'ramda/src/compose'
import { TABLE_ROW_ON_CLICK } from 'root/src/client/logic/coupon/actionIds'

import {
	formStoreLenses,
} from 'root/src/client/logic/form/lenses'

const { setFormInputsChild } = formStoreLenses

export default {
	// eslint-disable-next-line consistent-return
	[TABLE_ROW_ON_CLICK]: (state, { type, payload }) => {
		const { moduleKey, rowData } = payload
		const { id, name, daysValid, amount } = rowData

		switch (type) {
		case 'UPDATE':
			return compose(
				setFormInputsChild(moduleKey, 'id', id),
				setFormInputsChild(moduleKey, 'name', name),
				setFormInputsChild(moduleKey, 'daysValid', daysValid),
				setFormInputsChild(moduleKey, 'amount', amount),
			)(state)
		default:
			break
		}
	},
}
