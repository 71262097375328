import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import getDropEventsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDropEventsPayloadSchema'

export default {
	[GET_DROP_EVENTS]: {
		authentication: authenticationTypes.optional,
		endpointType: listEndpointType,
		recordType: dropEvent,
		payloadSchema: getDropEventsPayloadSchema,
	},
}
