import { NO_CAMPAIGN_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[NO_CAMPAIGN_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'Contact Us',
		modalContent: {
			routeId: routeUrls.addCampaign(),
		},
	},
}
