import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { gatewayEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
const createGiveawayEntryCallbackPayloadSchema = {
    type: 'object',
    properties: {
        state: {
            type: 'string',
        },
        idToken: {
            type: 'string',
        },
    },
    additionalProperties: true,
};
/**
 * GET request callback
 */
export const createGiveawayEntryCallback = {
    [giveawayEndpointIds.CREATE_GIVEAWAY_ENTRY_CALLBACK]: {
        endpointType: gatewayEndpointType,
        payloadSchema: createGiveawayEntryCallbackPayloadSchema,
    },
};
