import { DELIVERY_DARE } from 'root/src/shared/descriptions/endpoints/endpointIds'

import { authenticationTypes } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

import deliveryDarePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/deliveryDarePayloadSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const deliverEndpoint = {
	[DELIVERY_DARE]: {
		authentication: authenticationTypes.authenticated,
		endpointType: recordEndpointType,
		recordType: project,
		payloadSchema: deliveryDarePayloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
