export default {
	type: 'object',
	properties: {
		brandName: { type: 'string' },
		brandWebsite: { type: 'string' },
		brandTwitter: { type: 'string' },
	},
	additionalProperties: false,
	required: ['brandName', 'brandWebsite'],
}
