import { SET_ALERTS_CONFIG } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import setAlertsConfigPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setAlertsConfigPayloadSchema'
import alertsConfigResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/alertsConfigResponseSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = setAlertsConfigPayloadSchema
export const responseSchema = alertsConfigResponseSchema

export default {
	[SET_ALERTS_CONFIG]: {
		authentication: authenticationTypes.authenticated,
		endpointType: userDataEndpointType,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
