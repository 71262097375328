import { GET_DARES_TWITCH } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import getDaresTwitchResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getDaresTwitchResponseSchema'
import getDaresTwitchPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDaresTwitchPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[GET_DARES_TWITCH]: {
		authentication: authenticationTypes.optional,
		endpointType: recordEndpointType,
		recordType: project,
		responseSchema: getDaresTwitchResponseSchema,
		payloadSchema: getDaresTwitchPayloadSchema,
	},
}
