import pipe from 'ramda/src/pipe'
import toPairs from 'ramda/src/toPairs'
import reduce from 'ramda/src/reduce'

export default pipe(
	toPairs,
	reduce((acc, [key, value]) => {
		if (typeof value === 'undefined') {
			return acc
		}
		return {
			...acc,
			[key]: value,
		}
	}, {}),
)
