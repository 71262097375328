import { SET_LIST_DATA_LENGTH } from 'root/src/client/logic/list/actionIds'

import { listStoreLenses } from 'root/src/client/logic/list/lenses'

const { setListDataLength } = listStoreLenses

const setListDataLengthFn = (
	state, { listDataLength, moduleId },
) => setListDataLength(moduleId, listDataLength, state)

export default {
	[SET_LIST_DATA_LENGTH]: setListDataLengthFn,
}
