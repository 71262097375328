import moment from 'moment-mini';
// Converts date string to date with rounded minutes (0/30)
export function roundDateTimeMinutes(dateStr) {
    const momentDate = moment.utc(dateStr);
    const minutes = Math.floor(momentDate.minute() / 30) * 30;
    return momentDate
        .startOf('hour')
        .add(minutes, 'minutes')
        .format();
}
