export const obsCloudfrontDistributionArn = 'E1537ZJRCVWZWW'
export const mixpanelProxyApiId = 'gy3vfmc8j9'
export const apiLambdaLowSpecArn = 'arn:aws:lambda:us-east-1:427581285010:function:dareMeddstagingstaging-dareMeddstagingStagingCloud-Wn48lf8JBr5k'
export const cloudWatchLogsReportErrorGroupName = 'dareMeddstagingstaging-dareMeddstagingStagingReportErrorGroup-6HBCVJqaZLVE'
export const nextOriginFunctionName = 'dareMeddstagingstaging-dareMeddstagingStagingNextO-7U7HoUXf0FDf'
export const performanceTestDynamoDBTableArn = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingPerformanceTestDynamoDbTable-1N3707PX64995'
export const lambdaSqsFifoQueueDlqUrl = 'https://sqs.us-east-1.amazonaws.com/427581285010/dareMeddstagingstaging-dareMeddstagingStagingLambdaSqsFifoQueu-R2YqU1l9okoq.fifo'
export const deliveryUploadsQueueUrl = 'https://sqs.us-east-1.amazonaws.com/427581285010/PythonTasksStackStaging-DeliveryUloadsQueue02BA1BE6-CZ7jSw1fZnV9'
export const cloudWatchLogsInfoGroupName = 'dareMeddstagingstaging-dareMeddstagingStagingInfoLogGroup-20BAdsckx6tq'
export const apiCloudwatchFunctionArn = 'arn:aws:lambda:us-east-1:427581285010:function:dareMeddstagingstaging-dareMeddstagingStagingApiLa-72F3AZyeeCN2'
export const dataBucket = 'daremeddstagingstaging-daremeddstagingstagingdatab-2uuoxwo72ct2'
export const dynamoLockTableName = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingLockTable-E3Z2SJ5X4DHZ'
export const staticBucket = 'daremeddstagingstaging-daremeddstagingstagingstati-tbra0mu36sbt'
export const cloudfrontDistributionArn = 'E2WZA2R09AGZ12'
export const performanceTestDynamoDBDataTableArn = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingPerformanceTestDynamoDbDataTable-4OV4UEQV9DIC'
export const cloudWatchLogsEsGroupName = 'dareMeddstagingstaging-dareMeddstagingStagingEsLogGroup-XQIV546Em0JA'
export const clientId = '2aoh55c9vfiu3cprhrpsqblblt'
export const elasticsearchDomainEndpoint = 'search-daremed-dareme-1r51ex1qf6lal-jfkmffshjigjelxo2zbe4j3ssa.us-east-1.es.amazonaws.com'
export const apiDynamoWebsocketsConnectionsTable = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingApiDynamoDbWebsocketConnectionsTable-11J1REUAU16O1'
export const cloudwatchLogsOutput = 'daremeddstagingstaging-daremeddstagingstagingcloud-jy6qrvlaljj1'
export const apiDynamoArchivalTableName = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingApiDynamoDbArchivalTable-1JX33P89S1WO3'
export const gatewayWebsocketApiId = 'd6nv8yp9q1'
export const performanceTestArchivalDynamoDBDataTableArn = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingPerformanceTestArchivalDynamoDbDataTable-VZOZ8G8HBSFG'
export const apiDynamoScanTableName = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingApiDynamoDbScanTable-1AYKI4995RHRV'
export const lambdaSqsStandardQueueUrl = 'https://sqs.us-east-1.amazonaws.com/427581285010/dareMeddstagingstaging-dareMeddstagingStagingLambdaSqsStandardQueue-Cvqmt5erWD3Z'
export const lambdaSqsStandardQueueDlqUrl = 'https://sqs.us-east-1.amazonaws.com/427581285010/dareMeddstagingstaging-dareMeddstagingStagingLambdaSqsStandardQueue-QVZvQXrN25h9'
export const sesConfigurationSetEventBasedEmailsArn = 'dareMeddstagingStagingSESConfigurationSetEventBased-d5p1p6uLi1Fd'
export const apiDynamoTableName = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingApiDynamoDbTable-QNZ9EIZ7WS17'
export const gatewaySentryTunnelApiId = '0x3utbk1u7'
export const cloudWatchLogsEmailGroupName = 'dareMeddstagingstaging-dareMeddstagingStagingEmailLogGroup-TWj2NFo0zxxF'
export const apiFunctionArn = 'arn:aws:lambda:us-east-1:427581285010:function:dareMeddstagingstaging-dareMeddstagingStagingTrigg-t1OOYwxaUarK'
export const fileUploadBucket = 'daremeddstagingstaging-daremeddstagingstagingfileu-slnvnd4lsr83'
export const domainName = 'staging.ddstaging.link'
export const seedDbBucket = 'daremeddstagingstaging-daremeddstagingstagingseedd-a7nuklizayjv'
export const kmsCustomerMasterKeyArn = 'arn:aws:kms:us-east-1:427581285010:key/6d63de43-ff61-40e9-a2f8-267d25ba68be'
export const apiDynamoStatisticsTableName = 'arn:aws:dynamodb:us-east-1:427581285010:table/dareMeddstagingstaging-dareMeddstagingStagingApiDynamoDbStatisticsTable-1NHMJ88UBLYY2'
export const gatewayLambdaArn = 'arn:aws:lambda:us-east-1:427581285010:function:dareMeddstagingstaging-dareMeddstagingStagingGatew-6cmaZcfc4MWq'
export const lambdaSqsFifoQueueUrl = 'https://sqs.us-east-1.amazonaws.com/427581285010/dareMeddstagingstaging-dareMeddstagingStagingLambdaSqsFifoQueu-R6rTFosktrYw.fifo'
export const userPoolId = 'us-east-1_Vq2FlKRUZ'
export const identityPoolId = 'us-east-1:44e44996-f22e-4667-a5d3-4925dffd7133'
export const cloudWatchEventsIamRole = 'arn:aws:iam::427581285010:role/dareMeddstagingstaging-dareMeddstagingStagingCloudw-df3imcPf9uaX'