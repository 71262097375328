export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		messageId: { type: 'string' },
		message: {	type: 'string'	},
	},
	required: ['projectUrl', 'messageId', 'message'],
	additionalProperties: false,
}
