import { REPORT_CTA_CLICK } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import reportCtaClickPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/reportCtaClickPayloadSchema'

export default {
	[REPORT_CTA_CLICK]: {
		authentication: authenticationTypes.optional,
		endpointType: otherEndpointType,
		payloadSchema: reportCtaClickPayloadSchema,
	},
}
