import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const transferRewardsEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.admin,
    endpointType: otherEndpointType,
    payloadSchema: {
        type: 'object',
        properties: {
            reward: {
                type: 'object',
                properties: {
                    id: {
                        type: 'string',
                    },
                    type: {
                        type: 'string',
                    },
                },
                required: ['id', 'type'],
            },
            transferTo: {
                type: 'string',
            },
            transferFrom: {
                type: 'string',
            },
            amount: {
                type: 'integer',
            },
        },
        required: ['reward', 'transferTo', 'transferFrom', 'amount'],
    },
};
