import { GET_SHORT_URL_REPORT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { SHORT_URL_REPORT_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[SHORT_URL_REPORT_MODULE_ID]: {
		moduleType: 'form',
		title: 'Short Urls Report',
		subTitle: "Report contains data from past 30 days. If there isn't data for some short urls, they won't be in the report.",
		fields: [],
		endpointId: GET_SHORT_URL_REPORT,
		submits: [
			{
				label: 'Download report',
				buttonType: 'primaryButton',
			},
		],
	},
}
