import { SET_DARE_PINNED } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import setDarePinnedPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setDarePinnedPayloadSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

const payloadSchema = setDarePinnedPayloadSchema

export default {
	[SET_DARE_PINNED]: {
		authentication: authenticationTypes.authenticated,
		endpointType: otherEndpointType,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
