/* eslint-disable import/prefer-default-export */

export const mixpanelEvents = {
	publisherDetails: 'addPublisherDetails',
	login: 'successfulLogin',
	signUp: 'successfulSignup',
	channelConnection: 'connectedChannel',
	obsExtension: 'obsExtensionsSet',
	twitchExtension: 'twitchExtensionsSet',
	campaignCreated: 'campaignCreated',
	campaignSaved: 'campaignSaved',
	dareCreated: 'createDare',
	projectCreated: 'createProject',
	pledgeMade: 'pledgeMade',
	pledgeSkip: 'skipPledge',
	campaignPayment: 'campaignPaymentSuccess',
	addPayoutEmail: 'addPayoutEmail',
	profileEdit: 'editProfile',
	dareClaim: 'claimDare',
	dropClaim: 'claimSponsoredProject',
	dareDelivery: 'dareDelivery',
	brandProducts: 'addBrandProducts',
	videoStart: 'videoStartsPlaying',
	videoStop: 'videoStopsPlaying',
	subscriptionRenewal: 'subscriptionRenewed',
	experimentStarted: 'experimentStarted',
}
