import equals from 'ramda/src/equals'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import { CHECK_FOR_NOTIFICATIONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import { NOTIFICATIONS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import afterLoginRouteSelector from 'root/src/client/logic/auth/util/afterLoginRouteSelector'
import { LOGIN_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import asyncOrUndefined from 'root/src/shared/util/asyncOrUndefined'
import { isNilOrEmpty } from 'root/src/shared/util/ramdaPlus'

export default () => async (dispatch, getState) => {
	const { body } = await dispatch(apiRequest(CHECK_FOR_NOTIFICATIONS, {}))

	if (!isNilOrEmpty(body)) {
		const currentRoute = currentRouteId(getState())

		const afterLoginRoute = await asyncOrUndefined(
			equals(currentRoute, LOGIN_ROUTE_ID),
			() => afterLoginRouteSelector({
				dispatch,
				getState,
			}),
		)

		dispatch(
			displayModal(
				NOTIFICATIONS_MODAL_MODULE_ID,
				{ notifications: body },
				afterLoginRoute?.routeId || currentRoute,
			),
		)
	}
}
