import { SIGN_IN_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import loginSchema from 'root/src/shared/descriptions/formSchemas/loginSchema'
import { registerTerms } from 'root/src/shared/descriptions/modules/signUpModal'

export default {
	[SIGN_IN_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		schema: loginSchema,
		modalContent: {
			title: 'Sign In',
			registerTerms,
		},
	},
}
