import {
	GET_CUSTOM_SOUNDS,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import customSoundsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/customSoundsResponseSchema'
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import { customSounds } from 'root/src/shared/descriptions/endpoints/recordTypes'

export const payloadSchema = emptyPayloadSchema
export const responseSchema = customSoundsResponseSchema

export default {
	[GET_CUSTOM_SOUNDS]: {
		endpointType: userDataEndpointType,
		recordType: customSounds,
		payloadSchema,
		responseSchema,
		authentication: authenticationTypes.authenticated,
	},
}
