export default {
	type: 'object',
	properties: {
		pledgeSounds: { type: 'array' },
		dareSounds: { type: 'array' },
		soundVolume: { type: 'number' },
		pledgeOptions: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					bitsId: { type: 'string' },
					label: { type: 'string' },
					soundId: { type: 'string' },
					soundLabel: { type: 'string' },
					audioSrc: { type: 'string' },
					amount: { type: 'integer' },
				},
				required: ['bitsId', 'label', 'amount'],
			},
		},
		aligned: { type: 'string' },
	},
	required: [],
	additionalProperties: false,
}
