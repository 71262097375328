import { DROPS_BANNER_HEADER_MODULE_ID, DROPS_LIST_MODULE_ID, } from 'root/src/shared/descriptions/modules/moduleIds';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import CheckCircle from 'root/src/client/public/ic-check-circle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { liveEventsKey, getComingSoonEventsKey, getPrivateLiveEventsKey, sectionKey, getCommunityDropEventsKey, getSponsoredDropEventsKey, } from 'root/src/shared/constants/keys';
export const dropsBannerHeader = {
    [DROPS_BANNER_HEADER_MODULE_ID]: {
        moduleType: 'bannerHeader',
        bannerImageText: 'Key Drops',
        bannerImageSubText: 'Earn Keys for your Fans by Completing Dares for these Games',
        listModuleId: DROPS_LIST_MODULE_ID,
        fullWidth: true,
        withBanner: true,
        modifyPayload: ({ state, payload }) => {
            const { dataFilter } = getBannerHeaderEmbeddedFilters(state);
            return Object.assign(Object.assign({}, payload), { dataFilter });
        },
        bannerTabs: [
            {
                id: 0,
                tabLabel: 'Live',
                endpointId: [GET_DROP_EVENTS],
                fixedPayload: {
                    section: liveEventsKey,
                    pageItemLength: 20,
                },
            },
            {
                id: 1,
                tabLabel: 'Coming soon',
                endpointId: [GET_DROP_EVENTS],
                fixedPayload: {
                    section: getComingSoonEventsKey,
                    pageItemLength: 8,
                },
            },
            {
                id: 2,
                tabLabel: 'Private',
                endpointId: [GET_DROP_EVENTS],
                fixedPayload: {
                    section: getPrivateLiveEventsKey,
                    pageItemLength: 8,
                },
            },
        ],
        bannerBoxImages: [
            {
                text: 'Find Games to Play on Stream',
                image: SportsEsportsIcon,
            },
            {
                text: 'Accept Dares for Your Key',
                image: CheckCircle,
            },
            {
                text: 'Deliver and Giveaway Keys',
                image: VpnKeyIcon,
            },
        ],
        embeddedContent: {
            moduleType: 'embeddedForm',
            withBanner: true,
            fields: [
                {
                    fieldId: sectionKey,
                    fieldCaption: 'Filter By:',
                    fixedLargeWidth: true,
                    inputType: 'dropdownEmbedded',
                    options: [
                        {
                            label: 'All',
                            payload: {},
                        },
                        {
                            label: 'Sponsored',
                            payload: { dataFilter: getSponsoredDropEventsKey },
                        },
                        {
                            label: 'Community',
                            payload: { dataFilter: getCommunityDropEventsKey },
                        },
                    ],
                    endpointId: [GET_DROP_EVENTS],
                },
            ],
        },
    },
};
export function getBannerHeaderEmbeddedFilters(state) {
    var _a, _b, _c, _d, _e;
    return {
        dataFilter: (_e = (_d = (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.embedded) === null || _a === void 0 ? void 0 : _a[DROPS_BANNER_HEADER_MODULE_ID]) === null || _b === void 0 ? void 0 : _b.fieldData) === null || _c === void 0 ? void 0 : _c.section) === null || _d === void 0 ? void 0 : _d.payload) === null || _e === void 0 ? void 0 : _e.dataFilter,
    };
}
