import lensPath from 'ramda/src/lensPath'
import set from 'ramda/src/set'
import { SET_DROPDOWN_ASYNC_OPTIONS } from 'root/src/client/logic/form/actionIds'

export const reducer = (state, { moduleKey, items: { asyncOptionsStoreKey, result } }) => set(
	lensPath(['form', moduleKey, 'dropdownAsyncOptions', asyncOptionsStoreKey]),
	result,
	state,
)

export default {
	[SET_DROPDOWN_ASYNC_OPTIONS]: reducer,
}
