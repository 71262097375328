import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { useIsCreator } from 'root/src/client/v2/auth/hooks/useIsCreator';
import { EngageViewersModal } from 'root/src/client/v2/creatorOnboarding/features/EngageViewersModal';
import { useGetFreeKeysButton } from 'root/src/client/v2/creatorOnboarding/features/CreatorOnboardingButtons/hooks/useGetFreeKeysButton';
import { useApplicationState } from 'root/src/client/v2/userData/hooks/useApplicationState';
export function useEngageViewersButton() {
    const modal = useModal();
    const { displayGetFreeKeysButton } = useGetFreeKeysButton();
    const { applicationState } = useApplicationState();
    const showEngageViewersModal = () => modal.show(<EngageViewersModal />);
    const { isCreator } = useIsCreator();
    if (!isCreator || !applicationState) {
        return {
            showEngageViewersModal,
            displayEngageViewersButton: false,
        };
    }
    const displayEngageViewersButton = applicationState.isDisplayEngageViewersMessage && !displayGetFreeKeysButton;
    return {
        showEngageViewersModal,
        displayEngageViewersButton,
    };
}
