import React, { memo } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import classNames from 'classnames'
import { gtSmMediaQuery } from 'root/src/client/web/commonStyles'
import { comparePropsFn } from 'root/src/shared/util/stateCompare'

const styles = {
	container: {
		minHeight: 'initial !important',
		maxHeight: 'initial !important',
		margin: [[0, 'auto']],
		padding: [[0, 24]],
		[gtSmMediaQuery]: {
			maxWidth: '1680px !important',
		},
		'@media (max-width: 420px)': {
			margin: 0,
		},
	},
}

export const MaxWidthContainerUnstyled = memo(({ classes, children, isNavigation, className }) => (
	<div
		className={classNames(
			'flex layout-row layout-align-center',
			{ 'flex-90': !isNavigation },
			classes.container,
			className,
		)}
	>
		{children}
	</div>
), comparePropsFn(['classes', 'children', 'isNavigation', 'className']))

export default withStyles(styles, { name: 'MuiMaxWidthContainer' })(memo(MaxWidthContainerUnstyled))
