import { SET_ALL_PAGE } from 'root/src/client/logic/list/actionIds'

import { listStoreLenses } from 'root/src/client/logic/list/lenses'

const { setAllPage } = listStoreLenses

const setAllPageFn = (state, { allPage, moduleId }) => setAllPage(moduleId, allPage, state)

export default {
	[SET_ALL_PAGE]: setAllPageFn,
}
