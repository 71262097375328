/**
 * Generates module key by routeId and moduleId
 * @param {string} routeId
 * @param {string} moduleId
 * @return {`${string}-${string}`}
 */
export const generateModuleKey = ({ routeId, moduleId }) => `${routeId}-${moduleId}`

/**
 * Generates unique module key
 * @param {string} routeId
 * @param {string} moduleId
 * @param {number} index
 * @param {[string][]} modules
 * @return {string}
 */
export const generateUniqueModuleKey = ({ routeId, moduleId, index }, modules) => {
	const moduleKey = generateModuleKey({ routeId, moduleId })

	const matchedModules = modules.filter(module => generateModuleKey({
		moduleId: module[0], routeId,
	}) === moduleKey)

	if (matchedModules.length > 1) {
		return `${moduleKey}-${index}`
	}

	return moduleKey
}
