import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import addAgencyPublisherPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addAgencyPublisherPayloadSchema';
import addAgencyPublisherResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/addAgencyPublisherResponseSchema';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants';
export const addAgencyPublisherEndpoint = {
    [agencyEndpointIds.ADD_AGENCY_PUBLISHER]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.agencyAccountManager,
        endpointType: otherEndpointType,
        payloadSchema: addAgencyPublisherPayloadSchema,
        responseSchema: addAgencyPublisherResponseSchema,
        middlewares: [VALIDATE_TOKEN_ACCOUNT],
    },
};
