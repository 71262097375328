import { HOMEPAGE_PUBLISHERS_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import { PUBLISHERS_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import Home2Blob from 'root/src/client/public/backgroundBlobs/home-2.svg'
import { ltGtMediaQuery, ltMdMediaQuery } from 'root/src/client/web/commonStyles'

export default {
	[HOMEPAGE_PUBLISHERS_SECTION]: {
		moduleType: 'static',
		staticPageType: 'section',
		backgroundData: [
			{ position: 'left',
				image: Home2Blob,
				style: {
					transform: 'translate(0%, -40%)',
					[ltMdMediaQuery]: {
						transform: 'translate(0%, -18%)',
					},
					[ltGtMediaQuery]: {
						transform: 'translate(0%, -12%)',
					},
				},
			},
		],
		pageContent: {
			heading: 'Game Publishers—',
			subHeading: 'Challenge creators, reward fans',
			text: 'Grow your community on Twitch, Youtube and Facebook with the power of the dare.',
			position: 'right',
			buttons: [
				{
					label: 'For Publishers',
					routeId: PUBLISHERS_PAGE_ROUTE_ID,
					icon: faChevronRight,
				},
			],
		},
	},
}
