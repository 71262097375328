import reduce from 'ramda/src/reduce'
import contains from 'ramda/src/contains'
import path from 'ramda/src/path'
import is from 'ramda/src/is'
import map from 'ramda/src/map'
import prop from 'ramda/src/prop'

import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import moduleRecordPayloadMapSelector from 'root/src/client/logic/api/selectors/moduleRecordPayloadMapSelector'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import fetchDropdownAsyncOption from 'root/src/shared/util/fetchDropdownAsyncOption'
import onEnterThunkSelector from 'root/src/client/logic/api/selectors/onEnterThunkSelector'
import onEnterActionsSelector from 'root/src/client/logic/api/selectors/onEnterActionsSelector'

export default ({ moduleId, nextRouteObj }) => async (dispatch, getState) => {
	const moduleDesc = moduleDescriptions[moduleId]
	const state = getState()
	const endpointId = moduleEndpointIdSelector({ /* state */ }, { moduleId })
	const payloadSubs = {
		recordId: decodeURIComponent(path(['routeParams', 'recordId'], nextRouteObj)),
	}
	const payloadItems = moduleRecordPayloadMapSelector(
		{ /* state */ }, { moduleId },
	)

	const onEnterThunk = onEnterThunkSelector(state, { moduleId })
	const onEnterActions = onEnterActionsSelector(state, { moduleId })

	if (onEnterThunk && onEnterActions) {
		const actions = typeof onEnterActions === 'function'
			? onEnterActions(state)
			: onEnterActions
		await dispatch(onEnterThunk(actions))
	}

	// @TODO replace this with util/subObj (also in recordClickAction)
	if (moduleDesc?.dropdownAsyncOptions) {
		await fetchDropdownAsyncOption(dispatch, getState, moduleId, moduleDesc.dropdownAsyncOptions)
	}
	if (payloadItems) {
		let payload
		if (is(Array, payloadItems)) {
			payload = reduce(
				(result, [key, value]) => {
					if (contains(':', value)) {
						return { ...result, [key]: payloadSubs[value.substr(1)] }
					}
					return { ...result, [key]: value }
				},
				{},
				payloadItems,
			)
		} else {
			payload = payloadItems
		}
		if (is(Array, endpointId)) {
			return Promise.all(
				map((endpoint) => {
					const subPayload = prop(endpoint, payload) || payload
					const payloadMod = is(Function, subPayload) ? subPayload(state) : subPayload
					dispatch(apiRequest(endpoint, payloadMod))
				}, endpointId),
			)
		}
		return dispatch(apiRequest(endpointId, payload))
	}
	return dispatch(apiRequest(endpointId))
}
