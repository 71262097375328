import { GET_WHITELISTED_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import whitelistedCreatorsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/whitelistedCreatorsResponseSchema'
import getWhitelistedCreatorsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getWhitelistedCreatorsPayloadSchema'
import { whitelist } from 'root/src/shared/descriptions/endpoints/recordTypes'

export default {
	[GET_WHITELISTED_CREATORS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: listEndpointType,
		payloadSchems: getWhitelistedCreatorsPayloadSchema,
		responseSchema: whitelistedCreatorsResponseSchema,
		recordType: whitelist,
	},
}
