export const reduce = (reducer, initialValue, arr) => {
	let accumulator = initialValue
	for (let i = 0; i < arr.length; i += 1) {
		accumulator = reducer(accumulator, arr[i])
	}
	return accumulator
}

export const reduceObj = (reducer, initialValue, obj = {}) => {
	let accumulator = initialValue
	for (let i = 0; i < Object.keys(obj).length; i += 1) {
		accumulator = reducer(accumulator, obj[i])
	}
	return accumulator
}

export const prop = (name, obj) => obj && obj[name]

export const propOr = (or, name, obj) => prop(name, obj) || or

export const spreadObj = objArr => reduce((acc, item) => {
	const keys = Object.keys(item)
	for (let i = 0; i < keys.length; i += 1) {
		acc[keys[i]] = item[keys[i]]
	}
	return acc
}, {}, objArr)

export const toPairs = (obj) => {
	if (obj === undefined) {
		return []
	}
	const result = []
	const keys = Object.keys(obj)
	const values = Object.values(obj)
	for (let i = 0; i < keys.length; i += 1) {
		result.push([keys[i], values[i]])
	}
	return result
}

export const push = (arr1, ...elements) => {
	const arr = []
	// eslint-disable-next-line prefer-destructuring
	const length = arr1.length
	// eslint-disable-next-line prefer-destructuring
	const elemLength = elements.length
	arr.length = length + elemLength
	for (let i = 0; i < length; i += 1) {
		arr[i] = arr1[i]
	}
	for (let i = 0; i < elemLength; i += 1) {
		arr[length + i] = elements[i]
	}
	return arr
}
