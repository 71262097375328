import { projectApprovedKey, projectRejectedKey } from 'root/src/shared/constants/keys'

export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		audit: {
			type: 'string',
			enum: [
				projectApprovedKey,
				projectRejectedKey,
			],
		},
	},
	required: ['projectUrl', 'audit'],
	additionalProperties: false,
}
