import { GET_POINTS_MARKETPLACE_ITEMS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { marketplaceItem } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[GET_POINTS_MARKETPLACE_ITEMS]: {
		authentication: authenticationTypes.authenticated,
		endpointType: listEndpointType,
		recordType: marketplaceItem,
	},
}
