import { SET_OBS_TOP_DARES_CONFIG } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { obsTopDaresConfig } from 'root/src/shared/descriptions/endpoints/recordTypes'
import obsTopDaresConfigSchema from 'root/src/shared/descriptions/endpoints/schemas/obsTopDaresConfigSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[SET_OBS_TOP_DARES_CONFIG]: {
		authentication: authenticationTypes.optional,
		endpointType: recordEndpointType,
		recordType: obsTopDaresConfig,
		responseSchema: obsTopDaresConfigSchema,
		payloadSchema: obsTopDaresConfigSchema,
	},
}
