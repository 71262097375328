import dynamic from 'next/dynamic'
import { Fragment, memo, useCallback, useEffect } from 'react'
import { RenderModuleUnconnected as RenderModule } from 'root/src/client/web/base/RenderModule'
import routeRenderConnector from 'root/src/client/logic/route/connectors/routeRenderConnector'
import { SUCCESS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { generateUniqueModuleKey } from 'root/src/client/util/moduleKeys'
import { comparePropsFn } from 'root/src/shared/util/stateCompare'
import tiktokViewContentEvent from 'root/src/client/logic/analytics/events/tiktokViewContentEvent'
import TiktokPixelScript from 'root/src/client/web/scripts/TiktokPixelScript'
import ChatWidget from 'root/src/client/web/base/ChatWidget'
import DiscordWidget from 'root/src/client/web/base/DiscordWidget'
import { DynamicImportPlaceholder } from 'root/src/client/web/base/DynamicImportPlaceholder'

const ModalModule = dynamic(() => import('root/src/client/web/modal/ModalModule'))
const VariantRouteSwitch = dynamic(() => import('root/src/client/web/form/VariantRouteSwitch'), { loading: () => <DynamicImportPlaceholder /> })

// TODO @next
const RenderModules = memo(({
	currentRouteModuleTypes, currentRouteId: routeId, isMobileRes, variantModules,
	oauthAuthenticate, chatWidget, isTokenInvalid, discordWidget,
	displayModal, removeAuthToken, getUserData, tiktokViewEvent, modalModuleId,
}) => {
	const relogTwitchUser = useCallback(async () => {
		removeAuthToken(getUserData.creatorData.platform, getUserData.creatorData.platformId)
		oauthAuthenticate('twitch')()
	}, [removeAuthToken, oauthAuthenticate, getUserData])

	useEffect(() => {
		if (isTokenInvalid) {
			displayModal(SUCCESS_MODAL_MODULE_ID, {
				header: 'You are seeing this for one of the following reasons:',
				errorText: 'Your token has expired. \n You have changed your twitch acc password. \n Twitch has revoked auth token. \n You have disconnected Dare Drop app in twitch settings',
				infoText: 'Please reconnect your channel.',
				buttonLabel: 'Re-connect',
				buttonOnClick: () => relogTwitchUser(),
			})
		}
	}, [isTokenInvalid])

	return (
		<div
			className="flex layout-column layout-align-start-stretch"
		>
			{variantModules && <VariantRouteSwitch variantModules={variantModules} />}
			{discordWidget && <DiscordWidget />}
			{chatWidget && <ChatWidget />}
			{tiktokViewEvent && <TiktokPixelScript /> && tiktokViewContentEvent(1, 'Page view', routeId)}
			{modalModuleId && (
				<ModalModule
					moduleKey={generateUniqueModuleKey({
						routeId,
						moduleId: modalModuleId,
					}, currentRouteModuleTypes)}
					moduleId={modalModuleId}
					routeId={routeId}
				/>
			)}
			{currentRouteModuleTypes.map((
				[moduleId, moduleType, renderSidebar, moduleIndex, backgroundData],
			) => {
				if ((renderSidebar !== undefined && !isMobileRes) || renderSidebar === false) {
					return null
				}
				return (
					<Fragment key={moduleId + moduleIndex}>
						<RenderModule
							key={moduleId}
							{...{
								moduleType,
								routeId,
								moduleId,
								moduleIndex,
								renderSidebar,
								moduleKey: generateUniqueModuleKey({
									routeId,
									moduleId,
									index: moduleIndex,
								}, currentRouteModuleTypes),
								backgroundData,
							}}
						/>
					</Fragment>
				)
			}) }
		</div>
	)
}, comparePropsFn([
	'currentRouteModuleTypes', 'currentRouteId', 'isMobileRes', 'variantModules',
	'oauthAuthenticate', 'chatWidget', 'isTokenInvalid', 'discordWidget',
	'displayModal, removeAuthToken, getUserData', 'modalModuleId',
]))

export default routeRenderConnector(RenderModules)
