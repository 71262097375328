import { CHECK_BRAND_ACTIVE_CAMPAIGN } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import checkBrandActiveCampaignPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/checkBrandActiveCampaignPayloadSchema'
import checkBrandActiveCampaignResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/checkBrandActiveCampaignResponseSchema'

export const payloadSchema = checkBrandActiveCampaignPayloadSchema
export const responseSchema = checkBrandActiveCampaignResponseSchema

export default {
	[CHECK_BRAND_ACTIVE_CAMPAIGN]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: otherEndpointType,
		payloadSchema,
		responseSchema,
	},
}
