import { BRANDS_PARTNERSHIPS_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import Publishers2Blob from 'root/src/client/public/backgroundBlobs/for-publishers-2.svg'
import { ltGtMediaQuery, ltMdMediaQuery } from 'root/src/client/web/commonStyles'
import { ourSalesEmail } from 'root/src/shared/constants/mail'

export default {
	[BRANDS_PARTNERSHIPS_SECTION]: {
		moduleType: 'static',
		staticPageType: 'section',
		backgroundData: [
			{ position: 'right',
				image: Publishers2Blob,
				style: {
					transform: 'translate(0, -36%)',
					[ltMdMediaQuery]: {
						transform: 'translate(0%, -22%)',
					},
					[ltGtMediaQuery]: {
						transform: 'translate(0%, -14%)',
					},
				} },
		],
		pageContent: {
			heading: 'Make partnerships,',
			subHeading: 'Not transactions',
			text: 'The more a creator loves your game, the more dares they complete, the more rewards they earn for the fans. It’s a win, win—and 100% verified.',
			position: 'left',
			buttons: [
				{
					label: 'Add Your Game to Dare Drop',
					// TEMPORARY FIX UNTIL BRAND ONBOARDING MVP
					href: `mailto:${ourSalesEmail}`,
					// routeId: (state) => {
					// 	const isAuthenticated = path(['app', 'authentication'], state)
					// 	return isAuthenticated ? CAMPAIGNS_PAGE_ROUTE_ID : SIGN_UP_ROUTE_ID
					// },
					icon: faChevronRight,
				},
			],
		},
	},
}
