export default {
	properties: {
		signedUrl: { type: 'string' },
		contentType: { type: 'string' },
		fileName: { type: 'string' },
		expires: { type: 'string' },
	},
	required: ['fileName', 'signedUrl', 'contentType'],
	additionalProperties: false,
}
