import prop from 'ramda/src/prop'
import path from 'ramda/src/path'
import pathOr from 'ramda/src/pathOr'

import clientEndpoints from 'root/src/shared/descriptions/endpoints'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import pathEq from 'ramda/src/pathEq'

export const testEndpointExists = endpointId => prop(endpointId, clientEndpoints)

export const getPayloadLenses = endpointId => path(
	[endpointId, 'payloadLenses'],
	clientEndpoints,
)

export const getResponseLenses = endpointId => path(
	[endpointId, 'responseLenses'],
	clientEndpoints,
)

export const getPayloadSchema = endpointId => path(
	[endpointId, 'payloadSchema'],
	clientEndpoints,
)

export const getResultSchema = endpointId => path(
	[endpointId, 'responseSchema'],
	clientEndpoints,
)

export const getAuthentication = endpointId => pathOr(
	authenticationTypes.unauthenticated,
	[endpointId, 'authentication'],
	clientEndpoints,
)

export const getAuthenticationRole = endpointId => pathOr(
	userGroups.user,
	[endpointId, 'authenticationRole'],
	clientEndpoints,
)

export const getIsBetaEndpoint = endpointId => path(
	[endpointId, 'isBeta'],
	clientEndpoints,
)

export const getUseRequestUserId = endpointId => path(
	[endpointId, 'useRequestUserId'],
	clientEndpoints,
)

export const getIsInternalEndpoint = endpointId => pathEq(
	[endpointId, 'isInternal'],
	true,
	clientEndpoints,
)

export const getTwitchAuthentication = endpointId => path(
	[endpointId, 'twitchAuthentication'],
	clientEndpoints,
)

export const getIsInvokedInternal = endpointId => path(
	[endpointId, 'isInvokedInternal'],
	clientEndpoints,
)

export const getIsLowSpec = endpointId => path(
	[endpointId, 'isLowSpec'],
	clientEndpoints,
)

export const getAction = endpointId => prop(endpointId)

export const getEndpointMiddlewares = endpointId => pathOr(
	[],
	[endpointId, 'middlewares'],
	clientEndpoints,
)
