import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'
import prepend from 'ramda/src/prepend'
import { DROP_UPLOAD_CODES_ON_SUCCESS } from 'root/src/client/logic/tableItem/actionIds'
import { GET_BRAND_CODES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { BRAND_PRODUCTS_AND_KEYS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { BRAND_CODE_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'

export default {
	[DROP_UPLOAD_CODES_ON_SUCCESS]: (state, payload) => {
		const [item] = payload
		const { id } = item
		const listStoreKey = createListStoreKey(
			{ routeId: BRAND_PRODUCTS_AND_KEYS_ROUTE_ID },
			GET_BRAND_CODES,
			BRAND_CODE_LIST_MODULE_ID,
		)

		return over(lensPath(['api', 'lists', listStoreKey, 'items']), prepend(id), state)
	},
}
