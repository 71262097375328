import { runModuleMountsHof, moduleTypeAction } from 'root/src/client/logic/route/util/runModuleMounts'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import routeDescriptions from 'root/src/shared/descriptions/routes'
import moduleDescriptions from 'root/src/shared/descriptions/modules'

const runModuleMounts = runModuleMountsHof(
	routeDescriptions, moduleDescriptions, moduleTypeAction, false,
)

export default () => (dispatch, getState) => {
	const state = getState()
	const nextRouteObj = currentRouteObj(state)
	return dispatch(runModuleMounts(nextRouteObj, state))
}
