import {
	CAMPAIGN_CLIPS_LIST_MODULE,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { campaignClipsListType } from 'root/src/shared/constants/moduleTypes'
import { GET_DASHBOARD_DELIVERIES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import onEnterActionCalls from 'root/src/client/logic/api/thunks/onEnterActionCalls'
import setModuleDataFilter from 'root/src/client/logic/api/actions/setModuleDataFilter'
import { sectionKey } from 'root/src/shared/constants/keys'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import { clip } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { getDashboardEmbeddedFilters, getDashboardFilters } from 'root/src/client/web/static/GraphsDashboard/helpers'
import currentPageSelector from 'root/src/client/logic/list/selectors/currentPageSelector'

export default {
	[CAMPAIGN_CLIPS_LIST_MODULE]: {
		moduleType: 'list',
		listType: campaignClipsListType,
		listModuleId: CAMPAIGN_CLIPS_LIST_MODULE,
		recordType: clip,
		pageItemsCount: 9,
		endpointId: [GET_DASHBOARD_DELIVERIES],
		// Be aware when changing this method, because it could affect on src/shared/descriptions/modules/demoCampaignClips.ts:24
		modifyPayload: ({ payload, state, moduleId }) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)
			const { mnemonicIds, sort, sortBy, limit = 9 } = payload

			const { from, to } = getDashboardFilters({ state, recordId, moduleId: moduleId ?? CAMPAIGN_CLIPS_LIST_MODULE })
			return {
				mnemonicIds: recordId ? [recordId] : mnemonicIds,
				to,
				from,
				sort,
				sortBy,
				limit,
				currentPage: 1,
			}
		},
		listPayload: (state) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)
			const currentPage = currentPageSelector(state, { moduleId: CAMPAIGN_CLIPS_LIST_MODULE }) ?? 1

			const { limit = 9, sort, sortBy } = getDashboardEmbeddedFilters({
				state, moduleId: CAMPAIGN_CLIPS_LIST_MODULE,
			})
			const { from, to } = getDashboardFilters({
				state, recordId, moduleId: CAMPAIGN_CLIPS_LIST_MODULE,
			})
			const mnemonicIds = recordId ? [recordId] : undefined
			return {
				mnemonicIds,
				limit,
				sort,
				sortBy,
				from,
				to,
				currentPage,
			}
		},
		onEnterThunk: onEnterActionCalls,
		onEnterActions: (state) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)

			const { from, to } = getDashboardFilters({ state, recordId, moduleId: CAMPAIGN_CLIPS_LIST_MODULE })

			return [
				{
					action: setModuleDataFilter,
					payloads: [
						{
							moduleId: CAMPAIGN_CLIPS_LIST_MODULE,
							filter: 'payloadFilter',
							id: 'from',
							value: from,
						},
						{
							moduleId: CAMPAIGN_CLIPS_LIST_MODULE,
							filter: 'payloadFilter',
							id: 'to',
							value: to,
						},
					],
				},
			]
		},
		embeddedContent: {
			noCurrentPageData: true,
			moduleType: 'embeddedForm',
			fields: [
				{
					fieldId: sectionKey,
					fieldCaption: 'Sort by',
					fixedLargeWidth: true,
					inputType: 'dropdownEmbedded',
					options: (state) => {
						const {
							routeParams: { recordId },
						} = currentRouteObj(state)

						return [
							{
								label: 'Views (Hi to Lo)',
								payload: {
									mnemonicId: recordId,
									limit: 9,
									sortBy: 'viewCount',
									sort: 'DESC',
								},
							},
							{
								label: 'Views (Lo to Hi)',
								payload: {
									mnemonicId: recordId,
									limit: 9,
									sortBy: 'viewCount',
									sort: 'ASC',
								},
							},
							{
								label: 'Comments (Hi to Lo)',
								payload: {
									mnemonicId: recordId,
									limit: 9,
									sortBy: 'commentCount',
									sort: 'DESC',
								},
							},
							{
								label: 'Comments (Lo to Hi)',
								payload: {
									mnemonicId: recordId,
									limit: 9,
									sortBy: 'commentCount',
									sort: 'ASC',
								},
							},
							{
								label: 'Likes (Hi to Lo)',
								payload: {
									mnemonicId: recordId,
									limit: 9,
									sortBy: 'likeCount',
									sort: 'DESC',
								},
							},
							{
								label: 'Likes (Lo to Hi)',
								payload: {
									mnemonicId: recordId,
									limit: 9,
									sortBy: 'likeCount',
									sort: 'ASC',
								},
							},
							{
								label: 'Shares (Hi to Lo)',
								payload: {
									mnemonicId: recordId,
									limit: 9,
									sortBy: 'shareCount',
									sort: 'DESC',
								},
							},
							{
								label: 'Shares (Lo to Hi)',
								payload: {
									mnemonicId: recordId,
									limit: 9,
									sortBy: 'shareCount',
									sort: 'ASC',
								},
							},
						]
					},
					endpointId: GET_DASHBOARD_DELIVERIES,
				},
			],
		},
	},
}
