export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		amountRequested: { type: 'number' },
		daysToExpire: { type: 'number' },
		userHasGame: { type: 'boolean' },
		chosenInitialProductIndex: { type: 'number' },
	},
	required: ['projectUrl'],
	additionalProperties: false,
}
