import { GET_PRIVATE_DROP_EVENT_REPORT_PAYLOAD } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import {
	authenticationTypes,
	userGroups,
} from 'root/src/shared/constants/auth'

export default {
	[GET_PRIVATE_DROP_EVENT_REPORT_PAYLOAD]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		payloadSchema: {
			type: 'object',
			properties: {
				dropEventId: { type: 'string' },
			},
			required: ['dropEventId'],
		},
	},
}
