import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { surveyEndpointIds } from 'root/src/shared/survey/constants';
export const saveSurveyEndpoint = {
    [surveyEndpointIds.SAVE_SURVEY]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: gatewayEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                id: { type: 'string' },
                dropId: { type: 'string' },
                url: { type: 'string', format: 'uri' },
                surveyDate: { type: 'string' },
            },
            required: ['dropId', 'url', 'surveyDate'],
        },
    },
};
