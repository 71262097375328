import { REMOVE_DARE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import removeDarePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/removeDarePayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[REMOVE_DARE]: {
		authentication: authenticationTypes.authenticated,
		endpointType: otherEndpointType,
		payloadSchema: removeDarePayloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
