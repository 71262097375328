import { isProdEnv } from 'root/src/shared/util/envSelect'
import stackConfig from 'root/config/stackConfig'

const getName = () => {
	const domainName = stackConfig.AWS_STACK_DOMAIN ?? process.env.AWS_STACK_DOMAIN
	if (isProdEnv || domainName === 'watt.tv') {
		return 'dareMe'
	}
	return `dareMe${domainName ? domainName.split('.')[0] : ''}`
}

export default {
	name: getName(),
	awsAdminProfile: 'dareMeAdmin',
	awsRegion: 'us-east-1',
}
