import {
	CREATE_TRANSACTION_PAID_OFF_PLATFORM,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[CREATE_TRANSACTION_PAID_OFF_PLATFORM]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
