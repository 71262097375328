import moduleDataFilterSelector from 'root/src/client/logic/api/selectors/moduleDataFilterSelector';
import { GRAPHS_DASHBOARD_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds';
import moment from 'moment-mini';
import { roundDateTimeMinutes } from 'root/src/client/v2/common/helpers/roundDateTimeMinutes';
import { defaultCreatorsTableTab, defaultDashboardFilters, } from 'root/src/client/v2/brandDashboard/fixtures/defualtDashboardFilters';
export const getDashboardFilters = ({ state, recordId, moduleId = GRAPHS_DASHBOARD_MODULE_ID, defaultFilters = defaultDashboardFilters }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const dropEventRecord = (_d = (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.app) === null || _a === void 0 ? void 0 : _a.userData) === null || _b === void 0 ? void 0 : _b.dropEvents) === null || _c === void 0 ? void 0 : _c.find(dropEvent => dropEvent.mnemonicId === recordId)) !== null && _d !== void 0 ? _d : (_g = (_f = (_e = state === null || state === void 0 ? void 0 : state.api) === null || _e === void 0 ? void 0 : _e.records) === null || _f === void 0 ? void 0 : _f.dropEvent) === null || _g === void 0 ? void 0 : _g[recordId];
    const { payloadFilter, ambassadorsOnly, } = ((_h = moduleDataFilterSelector(state)) === null || _h === void 0 ? void 0 : _h[moduleId]) || {};
    const fromMod = moment.utc((_k = (_j = payloadFilter === null || payloadFilter === void 0 ? void 0 : payloadFilter.from) !== null && _j !== void 0 ? _j : dropEventRecord === null || dropEventRecord === void 0 ? void 0 : dropEventRecord.liveDate) !== null && _k !== void 0 ? _k : defaultFilters.from).format();
    const toMod = moment.utc((_l = payloadFilter === null || payloadFilter === void 0 ? void 0 : payloadFilter.to) !== null && _l !== void 0 ? _l : Math.min(Number(moment.utc(dropEventRecord === null || dropEventRecord === void 0 ? void 0 : dropEventRecord.expirationDate)), Number(moment.utc(defaultFilters.to)))).format();
    return {
        ambassadorsOnly,
        from: roundDateTimeMinutes(fromMod),
        to: roundDateTimeMinutes(toMod),
        creatorsTab: defaultCreatorsTableTab(dropEventRecord),
    };
};
export const getDashboardEmbeddedFilters = ({ state, moduleId }) => {
    var _a, _b, _c, _d;
    const payload = (_d = (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.embedded) === null || _a === void 0 ? void 0 : _a[moduleId]) === null || _b === void 0 ? void 0 : _b.fieldData) === null || _c === void 0 ? void 0 : _c.section) === null || _d === void 0 ? void 0 : _d.payload;
    return {
        limit: payload === null || payload === void 0 ? void 0 : payload.limit,
        sort: payload === null || payload === void 0 ? void 0 : payload.sort,
        sortBy: payload === null || payload === void 0 ? void 0 : payload.sortBy,
    };
};
