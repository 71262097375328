export default {
	type: 'object',
	properties: {
		email: { type: 'string' },
		userId: { type: 'string' },
		sk: { type: 'string' },
		pk: { type: 'string' },
		created: { type: 'string' },
		modified: { type: 'string' },
	},
	additionalProperties: false,
}
