import { routeDescriptionLenses } from 'root/src/client/logic/route/lenses'
import routeDescriptions from 'root/src/shared/descriptions/routes'
import addIndex from 'ramda/src/addIndex'
import __ from 'ramda/src/__'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import assoc from 'ramda/src/assoc'
import { whenTruthy } from 'root/src/shared/util/ramdaPlus'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'

const { viewVariantModules } = routeDescriptionLenses

export default pipe(
	currentRouteId,
	viewVariantModules(__, routeDescriptions),
	whenTruthy(addIndex(map)((item, index) => assoc('index', index, item))),
)
