import { memo } from 'react';
import * as HotToast from 'react-hot-toast';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { TOAST_Z_INDEX } from 'root/src/client/v2/layout/fixtures/layout.fixtures';
export const Toaster = memo(() => {
    return (<HotToast.Toaster containerStyle={{
            zIndex: TOAST_Z_INDEX,
        }} toastOptions={{
            success: {
                style: {
                    backgroundColor: colorsNew.primary,
                    color: '#fff',
                },
            },
            error: {
                style: {
                    color: '#ddd',
                    backgroundColor: colorsNew.red,
                },
            },
        }} position="bottom-right"/>);
});
