import moment from 'moment'
import { buildCookie, getCookies } from 'root/src/client/v2/browser/helpers/cookies'
import outputs from 'root/cfOutput'

const { domainName } = outputs

const cookiesClearedName = 'cookiesCleared'

export default () => {
	if (!global.window) return

	const cookieNames = Object.keys(getCookies())

	if (cookieNames.includes(cookiesClearedName)) return

	const domainNameWithoutSubdomain = domainName.split('.').slice(-2).join('.')
	cookieNames.forEach((cookieName) => {
		[undefined, domainNameWithoutSubdomain, `.${domainNameWithoutSubdomain}`, domainName, `.${domainName}`].forEach((domain) => {
			const domainStr = domain ? `; domain=${domain};` : ''
			document.cookie = `${cookieName}= ; expires=Thu, 01 Jan 1970 00:00:00 UTC;${domainStr}`
		})
	})
	document.cookie = buildCookie(cookiesClearedName, '', moment().add(1, 'years').unix())
}
