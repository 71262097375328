import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
import { groupsPrecedence } from 'root/src/shared/constants/auth';
export const getInheritedGroups = (groups) => {
    const baseGroups = [...(groups || [AuthGroup.User])];
    const groupsInherited = [];
    while (baseGroups.length > 0) {
        const currentKey = baseGroups.pop();
        groupsInherited.push(currentKey);
        const children = groupsPrecedence[currentKey];
        if (children) {
            children.forEach((child) => {
                baseGroups.push(child);
            });
        }
    }
    return [...new Set(groupsInherited)];
};
