import { CREATE_POINTS_PAYOUT } from 'root/src/shared/descriptions/endpoints/endpointIds';
export const createPointsPayoutEndpoint = {
    [CREATE_POINTS_PAYOUT]: {
        payloadSchema: {
            type: 'object',
            properties: {
                userId: { type: 'string' },
                payoutCurrency: { type: 'string' },
            },
            required: ['userId'],
            additionalProperties: false,
        },
    },
};
