import {
	INVOICE_SENT_CHECK_EMAIL,
} from 'root/src/shared/descriptions/modules/moduleIds'
import ddLogo from 'root/src/client/public/DDlogoWhiteBigTransparent.png'

export default {
	[INVOICE_SENT_CHECK_EMAIL]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			image: ddLogo,
			paragraphs: [
				'Invoice was sent, please check your email. Campaign will be activated on receipt of payment.',
			],
		},
	},
}
