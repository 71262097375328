import { GET_DROP_EVENT_DETAILS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import getDropEventDetailsPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDropEventDetailsPayloadSchema'
import getDropEventDetailsResponseSchema
	from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getDropEventDetailsResponseSchema'

const payloadSchema = getDropEventDetailsPayloadSchema
const responseSchema = getDropEventDetailsResponseSchema

export default {
	[GET_DROP_EVENT_DETAILS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: otherEndpointType,
		payloadSchema,
		responseSchema,
	},
}
