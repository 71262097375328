import { REJECT_PROJECT_MODAL_MODULE_ID, REJECT_DARE_SUCCESS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import {
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import myProfileOnSuccessRedirectSelector from 'root/src/client/logic/profile/selectors/myProfileOnSuccessRedirectSelector'

export default {
	[REJECT_PROJECT_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'Reject Dare',
		modalText: 'This Dare will be gone forever. This may disappoint fans who already pledged. Let them know why.',
		submits: [
			{
				onSuccessRedirectSelector: (state) => {
					const routeMap = {
						twitch: {
							routeId: VIEW_CREATOR_DATA_ROUTE_ID,
							query: { modal: REJECT_DARE_SUCCESS_MODULE_ID },
						},
						youtube: {
							routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
							query: { modal: REJECT_DARE_SUCCESS_MODULE_ID },
						},
						tiktok: {
							routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
							query: { modal: REJECT_DARE_SUCCESS_MODULE_ID },
						},
					}
					return myProfileOnSuccessRedirectSelector(state, routeMap)
				},
			},
		],
	},
}
