import path from 'ramda/src/path'
import lt from 'ramda/src/lt'
import length from 'ramda/src/length'
import pipe from 'ramda/src/pipe'
import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { COMPLETED_DARES_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VIEW_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { sidebarDaresListType } from 'root/src/shared/constants/moduleTypes'
import { sidebarPosition } from 'root/src/shared/constants/enums'
import { completedProjectsLikeThisKey } from 'root/src/shared/constants/keys'
import currentRouteParamsRecordId	from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'

const hasSidebarSelector = (state) => {
	const records = path(['api', 'records'], state)
	const paramsRecordId = currentRouteParamsRecordId(state)
	return pipe(
		path(['project', paramsRecordId, 'deliveries']),
		length,
		lt(0),
	)(records)
}

export default {
	[COMPLETED_DARES_LIST_MODULE_ID]: {
		moduleType: 'list',
		hasSidebarSelector,
		sidebarPosition: sidebarPosition.RIGHT,
		listType: sidebarDaresListType,
		fixedFilter: [
			[
				'games:projectUrl',
			],
			[
				'assignees:projectUrl',
			],
		],
		recordType: project,
		endpointId: [GET_PROJECTS],
		listPayload: {
			pageItemLength: 8,
			section: completedProjectsLikeThisKey,
		},
		listRouteId: VIEW_PROJECT_ROUTE_ID,
	},
}
