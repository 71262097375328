import pathToRegexp from 'path-to-regexp'
import assoc from 'ramda/src/assoc'
import toPairs from 'ramda/src/toPairs'
import reduce from 'ramda/src/reduce'
import view from 'ramda/src/view'
import path from 'ramda/src/path'
import compose from 'ramda/src/compose'
import memoizeWith from 'ramda/src/memoizeWith'

import {
	regexKey, regexKeysKey, toPathKey, routeUrlLens,
	mainRouteIdLens, mainRouteIdKey,
} from 'root/src/client/logic/route/lenses'

export const createRouteUrlRegexesHof = memoizeWith(
	compose(path([0, 0]), toPairs),
	pathToRegexpFn => allRoutes => reduce(
		(results, [routeId, routeDesc]) => {
			const url = view(routeUrlLens, routeDesc)
			const mainRouteId = view(mainRouteIdLens, routeDesc)
			const keys = []
			const regex = pathToRegexpFn(url, keys, { strict: true })
			return assoc(
				routeId,
				{
					[regexKey]: regex,
					[regexKeysKey]: keys,
					[toPathKey]: pathToRegexpFn.compile(url),
					[mainRouteIdKey]: mainRouteId,
				},
				results,
			)
		},
		{},
		toPairs(allRoutes),
	),
)

export default createRouteUrlRegexesHof(pathToRegexp)
