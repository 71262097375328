export default {
	type: 'object',
	properties: {
		following: { type: 'boolean' },
		image: { type: 'string' },
		mnemonicId: { type: 'string' },
		twitchId: { type: 'string' },
		username: { type: 'string' },
	},
}
