import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { getRewardsResponseSchema } from 'root/src/shared/rewards/schemas/getRewards.response.schema'

export const getRewardsEndpoint = {
	authentication: authenticationTypes.authenticated,
	authenticationRole: userGroups.admin,
	endpointType: otherEndpointType,
	responseSchema: getRewardsResponseSchema,
}
