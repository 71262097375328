export const searchTwitchAdminPayloadSchema = {
    type: 'object',
    properties: {
        input: {
            type: 'string',
        },
        first: {
            type: 'integer',
            min: 1,
            max: 10,
        },
        timeRange: {
            type: 'integer',
            enum: [7, 14, 30, 60, 90, 180, 365],
        },
    },
    required: ['input', 'first', 'timeRange'],
    additionalProperties: false,
};
