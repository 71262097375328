import {
	SET_EMAIL_FAILURE_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import image from 'root/src/client/web/TechIssueComponent'

export default {
	[SET_EMAIL_FAILURE_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Oops!',
			image,
			paragraphs: {
				default: [
					[`Something broke!
				Give it another shot, or contact us for help at support@daredrop.com`],
				],
			},
			withoutSocialIcons: true,
		},
	},
}
