import { GET_CREATOR_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { creatorData } from 'root/src/shared/descriptions/endpoints/recordTypes'
import creatorDataResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/creatorDataResponseSchema'
import getCreatorDataPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getCreatorDataPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export const responseSchema = creatorDataResponseSchema
export const payloadSchema = getCreatorDataPayloadSchema

export default {
	[GET_CREATOR_DATA]: {
		authentication: authenticationTypes.optional,
		endpointType: recordEndpointType,
		recordType: creatorData,
		responseSchema,
		payloadSchema,
	},
}
