import {	FILTER_DARES_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { SORT_BY_BOUNTY, SORT_BY_NEWEST, SORT_BY_TIME_LEFT } from 'root/src/shared/constants/sortTypes'
import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { BRAND_DARES_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { youtubeSearchDebounce } from 'root/src/shared/constants/youtube'

export default {
	[FILTER_DARES_FORM_MODULE_ID]: {
		moduleType: 'form',
		altNavigation: {
			backRoute: BRAND_DARES_ROUTE_ID,
			title: 'Filter Dares',
		},
		fields: [
			{
				fieldId: 'sort',
				labelFieldText: [
					{
						text: 'Sort by',
					},
				],
				inputType: 'dropdown',
				placeholder: 'Newest',
				options: [
					{
						label: 'Newest',
						id: 0,
						value: SORT_BY_NEWEST,
					},
					{
						label: 'Bounty Amount',
						id: 1,
						value: SORT_BY_BOUNTY,
					},
					{
						label: 'Time Left',
						id: 2,
						value: SORT_BY_TIME_LEFT,
					},
				],
				default: {
					label: 'Newest',
					id: 0,
					value: SORT_BY_NEWEST,
				},
			},
			{
				fieldId: 'game',
				labelFieldText: [
					{
						text: 'Filter by',
					},
				],
				optionsPromiseType: 'twitchGames',
				inputType: 'autoComplete',
				placeholder: 'Game',
				isMulti: false,
			},
			{
				inputType: 'variantField',
				optionsPromiseType: 'twitchChannels',
				fieldId: 'assignee',
				placeholder: 'Name',
				labelFieldText: [
					{
						text: 'Creator',
					},
				],
				isMulti: false,
				variantOptions: [
					{
						name: 'Twitch',
						id: 'twitch',
						inputType: 'autoComplete',
						optionsPromiseType: 'twitchChannels',
					},
					{
						name: 'YouTube',
						id: 'youtube',
						debounceDelay: youtubeSearchDebounce,
						inputType: 'autoComplete',
						optionsPromiseType: 'youtubeChannels',
					},
				],
			},
		],
		submits: [
			{
				label: 'Apply Filters',
				endpointId: GET_PROJECTS,
				onSuccessRedirectSelector: () => ({ routeId: BRAND_DARES_ROUTE_ID }),
			},
		],
	},
}
