import { z } from 'zod';
export const giveawayEndpointIds = {
    CREATE_GIVEAWAY: 'CREATE_GIVEAWAY',
    CREATE_GIVEAWAY_ENTRY: 'CREATE_GIVEAWAY_ENTRY',
    CREATE_GIVEAWAY_ENTRY_CALLBACK: 'CREATE_GIVEAWAY_ENTRY_CALLBACK',
    GIVEAWAYS_RESOLVE: 'GIVEAWAYS_RESOLVE',
    REDEEM_REWARD: 'REDEEM_REWARD',
    ENTER_GIVEAWAY: 'ENTER_GIVEAWAY',
    GET_USER_GIVEAWAYS: 'GET_USER_GIVEAWAYS',
    GET_USER_GIVEAWAY_REWARDS: 'GET_USER_GIVEAWAY_REWARDS',
    GET_GIVEAWAY: 'GET_GIVEAWAY',
    IS_GIVEAWAY_ACTION_DONE: 'IS_GIVEAWAY_ACTION_DONE',
    SEND_GIVEAWAY_SUMMARIES: 'SEND_GIVEAWAY_SUMMARIES',
    SAVE_GIVEAWAY_TERMS: 'SAVE_GIVEAWAY_TERMS',
    GET_USER_GIVEAWAY_TERMS: 'GET_USER_GIVEAWAY_TERMS',
    GET_USER_VOUCHERS: 'GET_USER_VOUCHERS',
    GET_USER_GIVEAWAY_STATISTICS: 'GET_USER_GIVEAWAY_STATISTICS',
    GET_USER_ACTIVE_GIVEAWAY_SCHEDULER: 'GET_USER_ACTIVE_GIVEAWAY_SCHEDULER',
    UPDATE_USER_GIVEAWAY_SCHEDULER: 'UPDATE_USER_GIVEAWAY_SCHEDULER',
    GET_USER_AVAILABLE_ACTIONS: 'GET_USER_AVAILABLE_ACTIONS',
    CREATE_GIVEAWAY_SCHEDULE: 'CREATE_GIVEAWAY_SCHEDULE',
    GET_VOUCHERS_COUNT: 'GET_VOUCHERS_COUNT',
};
export var VerifySteamStage;
(function (VerifySteamStage) {
    VerifySteamStage["SIGN_IN"] = "SIGN_IN";
    VerifySteamStage["SUCCESS"] = "SUCCESS";
    VerifySteamStage["ERROR"] = "ERROR";
})(VerifySteamStage || (VerifySteamStage = {}));
export const giveawayAnalyticsEvents = {
    CREATE_GIVEAWAY: 'create_giveaway',
    EDIT_GIVEAWAY_SCHEDULE: 'edit_giveaway_schedule',
    CREATE_GIVEAWAY_SCHEDULE: 'create_giveaway_schedule',
    CANCEL_GIVEAWAY_SCHEDULE: 'cancel_giveaway_schedule',
    SUBMIT_STEAM_ENTRY: 'submit_steam_entry',
    SUBMIT_STEAM_ENTRY_SUCCESS: 'submit_steam_entry_success',
    SUBMIT_ENTER_GIVEAWAY: 'submit_enter_giveaway',
    SUBMIT_ENTRY_TWITCH: 'submit_entry_twitch',
    SUBMIT_ENTRY_YOUTUBE: 'submit_entry_youtube',
    SUBMIT_ENTRY_TWITCH_SUCCESS: 'submit_entry_twitch_success',
    SUBMIT_ENTRY_YOUTUBE_SUCCESS: 'submit_entry_youtube_success',
    REDEEM_VOUCHER: 'redeem_voucher',
};
export var EntryFailureCode;
(function (EntryFailureCode) {
    EntryFailureCode["NOT_CONNECTED_ACCOUNT"] = "NOT_CONNECTED_ACCOUNT";
    EntryFailureCode["NOT_FOLLOWING"] = "NOT_FOLLOWING";
    EntryFailureCode["EXTERNAL_ERROR"] = "EXTERNAL_ERROR";
})(EntryFailureCode || (EntryFailureCode = {}));
export var SteamErrorCode;
(function (SteamErrorCode) {
    SteamErrorCode["ProfileNotPublic"] = "ProfileNotPublic";
})(SteamErrorCode || (SteamErrorCode = {}));
export const GIVEAWAY_DEFAULT_POOLING_TIME_MS = 10000;
export const DEFAULT_REPEAT_TIME_PRESETS = [
    ...Array.from({ length: 9 }, (_, i) => {
        const n = i + 1;
        return {
            label: n.toString(),
            value: n,
        };
    }),
];
export const DEFAULT_TIME_PRESETS = [
    {
        label: 'days',
        options: Array.from({ length: 9 }, (_, i) => ({
            label: i.toString(),
            value: i,
        })),
    },
    {
        label: 'hours',
        options: Array.from({ length: 24 }, (_, i) => ({
            label: `${i}:00`,
            value: i,
        })),
    },
    {
        label: 'minutes',
        options: Array.from({ length: 12 }, (_, i) => {
            const minute = i * 5;
            return {
                label: `00:${minute.toString().padStart(2, '0')}`,
                value: minute,
            };
        }),
    },
];
export const DEFAULT_DURATION_PRESETS = [
    {
        label: '20 minutes',
        value: 1000 * 60 * 20,
    },
    {
        label: '1 hour',
        value: 1000 * 60 * 60,
    },
    {
        label: '1 day',
        value: 1000 * 60 * 60 * 24,
    },
    {
        label: '3 days',
        value: 1000 * 60 * 60 * 24 * 3,
    },
];
export const MIN_REPEAT_TIMES = 1;
export const MAX_REPEAT_TIMES = 9;
export const MIN_DURATION_TIME = 1000 * 60 * 5;
export const MIN_DURATION_TIME_MESSAGE = 'Duration must be at least 5 minutes';
export const createGiveawayFormSchema = z.object({
    durationTime: z.number().min(5000 * 60, { message: MIN_DURATION_TIME_MESSAGE }),
    repeatTimes: z.union([z.null(), z.undefined(), z.number().min(1, { message: 'Repeat times must be at least 1' })]),
    isRepeatingEnabled: z.boolean().optional(),
});
export function isValidDuration(durationObj) {
    var _a, _b, _c;
    const daysMs = ((_a = durationObj.days) !== null && _a !== void 0 ? _a : 0) * 1000 * 60 * 60 * 24;
    const hoursMs = ((_b = durationObj.hours) !== null && _b !== void 0 ? _b : 0) * 1000 * 60 * 60;
    const minutesMs = ((_c = durationObj.minutes) !== null && _c !== void 0 ? _c : 0) * 1000 * 60;
    const totalMs = daysMs + hoursMs + minutesMs;
    return totalMs >= MIN_DURATION_TIME;
}
export const timeDurationFormSchema = z.object({
    days: z.number()
        .min(0)
        .optional(),
    hours: z.number()
        .min(0)
        .optional(),
    minutes: z.number()
        .min(0)
        .optional(),
}).refine((data) => {
    return isValidDuration(data);
}, { message: MIN_DURATION_TIME_MESSAGE });
export const RESOLVE_GIVEAWAY_MARGIN_MS = 2500;
