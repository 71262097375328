import { GET_POINTS_APPLICATIONS } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
export const getPointsApplicationsEndpoint = {
    [GET_POINTS_APPLICATIONS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: {
                    type: 'string',
                },
                states: {
                    type: 'array',
                    items: {
                        type: 'string',
                    },
                },
                page: {
                    type: 'number',
                },
                limit: {
                    type: 'number',
                },
            },
        },
    },
};
