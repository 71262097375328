import { WindowBreakpoint } from 'root/src/client/v2/browser/types';
export const resolutionBreakpoints = {
    [WindowBreakpoint.Xs]: '(max-width: 599px)',
    [WindowBreakpoint.GtXs]: '(min-width: 600px)',
    [WindowBreakpoint.Sm]: '(min-width: 600px) and (max-width: 959px)',
    [WindowBreakpoint.GtSm]: '(min-width: 960px)',
    [WindowBreakpoint.Md]: '(min-width: 960px) and (max-width: 1279px)',
    [WindowBreakpoint.GtMd]: '(min-width: 1280px)',
    [WindowBreakpoint.Lg]: '(min-width: 1280px) and (max-width: 1919px)',
    [WindowBreakpoint.GtLg]: '(min-width: 1920px)',
    [WindowBreakpoint.Xl]: '(min-width: 1920px)',
};
