import {
	DELETE_CUSTOM_SOUND,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import customSoundsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/customSoundsPayloadSchema'
import customSoundsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/customSoundsResponseSchema'

import { customSounds } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = customSoundsPayloadSchema
export const responseSchema = customSoundsResponseSchema

export default {
	[DELETE_CUSTOM_SOUND]: {
		authentication: authenticationTypes.authenticated,
		endpointType: userDataEndpointType,
		recordType: customSounds,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
