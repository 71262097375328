import { SET_EXPERIMENT_VARIANT } from 'root/src/client/logic/app/actionIds'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { setExperimentVariant } = appStoreLenses

export default {
	[SET_EXPERIMENT_VARIANT]: (state, experimentVariant) => setExperimentVariant(
		experimentVariant, state,
	),
}
