import map from 'ramda/src/map'
import prop from 'ramda/src/prop'
import nextAjax from 'root/src/client/util/nextAjax'
import { youtubeApiV3Url } from 'root/src/shared/constants/youtube'

// eslint-disable-next-line import/prefer-default-export
export const getYoutubeChannels = async (input) => {
	try {
		const searchResults = await nextAjax({
			url: `${youtubeApiV3Url}search`,
			queryParams: {
				part: 'snippet',
				maxResults: 50,
				q: input,
				type: 'channel',
				// eslint-disable-next-line no-undef
				key: YOUTUBE_API_KEY_CLIENT,
			},
		})
		return map(
			({ snippet, id }) => ({
				label: snippet.title,
				id: id.channelId,
				value: id.channelId, // mnemonicId
				image: snippet.thumbnails.default.url,
				platform: 'youtube',
			}),
			prop('items', searchResults),
		)
	} catch (e) {
		console.error(e)
		return []
	}
}
