import { giveawayEndpointIds, MAX_REPEAT_TIMES, MIN_DURATION_TIME, MIN_REPEAT_TIMES, } from 'root/src/shared/giveaways/giveaways.constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { recordEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { giveawayScheduler } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { GiveawaySchedulerConfigStatus } from '@daredrop-com/rewards-api-contract';
export const updateUserGiveawaySchedulerEndpoint = {
    [giveawayEndpointIds.UPDATE_USER_GIVEAWAY_SCHEDULER]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.betaTester,
        endpointType: recordEndpointType,
        recordType: giveawayScheduler,
        payloadSchema: {
            type: 'object',
            properties: {
                id: {
                    type: 'string',
                },
                durationTime: {
                    type: 'integer',
                    minimum: MIN_DURATION_TIME,
                },
                repeatTimes: {
                    type: ['integer', 'null'],
                    minimum: MIN_REPEAT_TIMES,
                    maximum: MAX_REPEAT_TIMES,
                    errorMessage: {
                        minimum: `Repeat times must be at least ${MIN_REPEAT_TIMES}`,
                        maximum: `Repeat times must be at most ${MAX_REPEAT_TIMES}`,
                    },
                },
                status: {
                    type: 'string',
                    enum: Object.values(GiveawaySchedulerConfigStatus),
                },
            },
            required: ['id'],
        },
    },
};
