import currentRouteIdSelector from 'root/src/client/logic/route/selectors/currentRouteId'

export default (getState, href, initRoute) => {
	const state = getState()
	const currentRouteId = currentRouteIdSelector(state)
	window.location.href = href
	if (currentRouteId === initRoute) {
		window.location.reload()
	}
}
