import defaultTo from 'ramda/src/defaultTo'
import prepend from 'ramda/src/prepend'
import over from 'ramda/src/over'
import compose from 'ramda/src/compose'
import lensPath from 'ramda/src/lensPath'
import { PUSH_STEP_ID } from 'root/src/client/logic/form/actionIds'
import { createProjectFormStepKeys } from 'root/src/shared/descriptions/modules/createProjectForm'

const { root } = createProjectFormStepKeys

export default {
	[PUSH_STEP_ID]: (
		state, { moduleKey, stepId },
	) => over(
		lensPath(['form', moduleKey, 'stepIdHistory']),
		compose(prepend(stepId), defaultTo([root])),
		state,
	),
}
