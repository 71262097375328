import { CREATOR_ACTIVITY_DETAILS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { GET_CREATOR_POINTS_TRANSACTIONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { creatorActivityDetails } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { sortTimeEnum } from 'root/src/shared/constants/enums'
import {
	paypalPointsPayoutPendingKey,
	paypalPointsPayoutDoneKey,
	sectionKey,
	paidOffPlatformKey,
} from 'root/src/shared/constants/keys'
import moment from 'moment'
import { dropEventKey } from 'root/src/shared/constants/dropEvent'

export default {
	[CREATOR_ACTIVITY_DETAILS_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		toggleVisibilityProp: [3],
		headerType: 'backgroundDesign',
		tableColumns: [
			['Event type', 'type', type => ({
				[paypalPointsPayoutDoneKey]: 'PayPal Points Share(Done)',
				[paypalPointsPayoutPendingKey]: 'PayPal Points Share(Pending)',
				[dropEventKey]: 'Drop Event',
				[paidOffPlatformKey]: 'Off platform Points Share',
			}[type])],
			['Campaign Name', 'campaignName'],
			['Date Created', 'created', date => moment(date).local().format('YYYY-MM-DDTHH:mm:ss')],
			['Initial Product', 'initialProduct'],
			['CTA url', 'landingUrl'],
			['Clicks', 'clicks'],
			['Watch time awarded', 'watchTimeMinutes', (watchTimeMinutes) => {
				if (!watchTimeMinutes) return undefined
				const hours = Math.round((watchTimeMinutes / 60) * 100) / 100
				return `${hours} Hours`
			}],
			['Points', 'points'],
		],
		recordType: creatorActivityDetails,
		listModuleId: CREATOR_ACTIVITY_DETAILS_MODULE_ID,
		embeddedContent: {
			moduleType: 'embeddedForm',
			fields: [
				{
					fieldId: sectionKey,
					inputType: 'dropdownEmbedded',
					options: [
						{
							label: 'Last 3 Days',
							payload: {
								section: sortTimeEnum.THREEDAYS,
							},
						},
						{
							label: 'Last 7 Days',
							payload: {
								section: sortTimeEnum.SEVENDAYS,
							},
						},
						{
							label: 'Last Month',
							payload: {
								section: sortTimeEnum.LASTMONTH,
							},
						},
						{
							label: 'Lifetime',
							payload: {
								section: sortTimeEnum.LIFETIME,
							},
						},
					],
					endpointId: GET_CREATOR_POINTS_TRANSACTIONS,
				},
			],
		},
		listTitle: 'Insights',
		endpointId: [
			GET_CREATOR_POINTS_TRANSACTIONS,
		],
	},
}
