import {
	CAMPAIGN_LIST_BANNER_HEADER_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[CAMPAIGN_LIST_BANNER_HEADER_MODULE_ID]: {
		moduleType: 'bannerHeader',
		bannerImageText: 'Campaign Reports',
		bannerSubText: 'Check to see the status of your campaign and how \nwell it’s performing.',
		bannerNewStyle: true,
	},
}
