export default {
	type: 'object',
	properties: {
		dropEventId: { type: 'string' },
		userId: { type: 'string' },
		projectId: { type: 'string' },
		chosenInitialProductIndex: { type: 'number' },
		userHasGame: { type: 'boolean' },
	},
	required: ['dropEventId', 'userId'],
	additionalProperties: false,
}
