import { listStoreLenses } from 'root/src/client/logic/list/lenses'
import { SET_LOADING_BLOCK_LIST } from 'root/src/client/logic/list/actionIds'
import	add from 'ramda/src/add'

const { setLoadingBlockVisible, viewLoadingBlockVisible } = listStoreLenses

const setVisibleLoadingBlockFunction = (state, { payload, moduleId }) => {
	const originValue = viewLoadingBlockVisible(moduleId, state) || 0
	return setLoadingBlockVisible(moduleId, add(originValue, payload ? 1 : -1), state)
}

export default {
	[SET_LOADING_BLOCK_LIST]: setVisibleLoadingBlockFunction,
}
