import { MODIFY_GAME_PLATFORMS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import modifyGamePlatformsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/modifyGamePlatformsResponseSchema'
import modifyGamePlatformsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/modifyGamePlatformsPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = modifyGamePlatformsPayloadSchema
export const responseSchema = modifyGamePlatformsResponseSchema

export default {
	[MODIFY_GAME_PLATFORMS]: {
		endpointType: userDataEndpointType,
		payloadSchema,
		responseSchema,
		authentication: authenticationTypes.authenticated,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
