import { NEW_EMAIL_VERIFICATION_SUCCESS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import myProfileSelector from 'root/src/client/logic/route/selectors/myProfileSelector'

export default {
	[NEW_EMAIL_VERIFICATION_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'verificationResult',
		pageContent: {
			title: 'Your new email has been verified.',
			paragraphs: [],
		},
		link: state => ({
			label: 'Continue to Your Profile',
			route: myProfileSelector(state),
		}),
	},
}
