import prop from 'ramda/src/prop'

import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'

import {
	userCampaignDataKey,
	variableSchemaKey,
} from 'root/src/shared/constants/keys'

import {
	project,
	projectList,
	projectAccepted,
	projectPledged,
	paymentMethod,
	payoutMethod,
	creatorData,
	coupon,
	comment,
	topContributors,
	profile,
	payoutInfo,
	liveAssignees,
	liveAssigneesRequests,
	brandProduct,
	pendingBrandUser,
	creator,
	dropKey,
	dropEvent,
	shortUrlAnalytics,
	dropCode,
	initialProduct,
	publisher,
	agencyAccountManager,
	marketplaceItem,
	creatorActivityDetails,
	whitelist,
	graphsData,
	clip,
	dropDare,
	giveaway,
	giveawayReward,
	creatorReward,
	viewerReward,
	publisherBan,
	giveawayVouchers,
	giveawayScheduler,
} from 'root/src/shared/descriptions/endpoints/recordTypes'

const apiStoreSchema = {
	type: 'object',
	properties: {
		api: {
			type: 'object',
			properties: {
				lists: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: { // listStoreKey
							type: 'object',
							properties: {
								next: { type: 'string' },
								items: { type: 'array' },
								totals: { type: 'array' },
								lastKey: { type: 'string' },
							},
						},
					},
				},
				listErrors: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: { // listStoreKey
							// type: 'string', this is really a string
							type: 'object',
							properties: {},
						},
					},
				},
				listProcessing: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: { // listStoreKey
							// type: 'boolean', this is really a boolean
							type: 'object',
							properties: {},
						},
					},
				},
				records: {
					type: 'object',
					properties: {
						[project]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[creator]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[projectList]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[projectAccepted]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[payoutInfo]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[projectPledged]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[paymentMethod]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[payoutMethod]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[brandProduct]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[coupon]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[pendingBrandUser]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										email: { type: 'string' },
										code: { type: 'string' },
										created: { type: 'string' },
										userId: { type: 'string' },
									},
								},
							},
						},
						[comment]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										replies: {
											type: 'array',
										},
										repliesAmount: {
											type: 'integer',
										},
										likes: {
											type: 'array',
										},
										dislikes: {
											type: 'array',
										},
									},
								},
							},
						},
						[publisher]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										brandTwitter: { type: 'string' },
										brandName: { type: 'string' },
										id: { type: 'string' },
										brandWebsite: { type: 'string' },
										brandLogo: { type: 'string' },
									},
								},
							},
						},
						[agencyAccountManager]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										userId: { type: 'string' },
										email: { type: 'string' },
									},
								},
							},
						},
						[liveAssignees]: {
							type: 'array',
						},
						[liveAssigneesRequests]: {
							type: 'array',
						},
						[creatorData]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[topContributors]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[giveaway]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[giveawayReward]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[giveawayVouchers]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[giveawayScheduler]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[profile]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[dropKey]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[userCampaignDataKey]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
								},
							},
						},
						[creatorReward]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[viewerReward]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[dropCode]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[initialProduct]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[dropEvent]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										id: { type: 'string' },
										mnemonicId: { type: 'string' },
										brand: {
											type: 'object',
											properties: {
												brandLogo: { type: 'object' },
												brandName: { type: 'string' },
											},
										},
										link: { type: 'string' },
										campaignName: { type: 'string' },
										gameDescriptionBody: { type: 'string' },
										gameObj: {
											type: 'object',
											properties: {
												image: { type: 'string' },
												title: { type: 'string' },
												description: { type: 'string' },
											},
										},
										assets: {
											type: 'array',
										},
									},
								},
							},
						},
						[creatorActivityDetails]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										campaignName: { type: 'string' },
										clicks: { type: 'integer' },
										viewerHours: { type: 'integer' },
										points: { type: 'integer' },
									},
								},
							},
						},
						[shortUrlAnalytics]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										url: { type: 'string' },
										shortUrl: { type: 'string' },
										clicks: { type: 'integer' },
										additionalInfo: { type: 'string' },
									},
								},
							},
						},
						[marketplaceItem]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										id: 'string',
										itemName: 'string',
									},
								},
							},
						},
						[graphsData]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
								},
							},
						},
						[clip]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[dropDare]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {},
								},
							},
						},
						[whitelist]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										whitelistType: 'string',
										campaignId: 'string',
										platform: 'string',
										platformId: 'string',
										displayName: 'string',
										campaignName: 'string',
									},
								},
							},
						},
						[publisherBan]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
								},
							},
						},
					},
				},
				recordErrors: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: { // recordTypes-recordId
							// type: 'string', this is really a string
							type: 'object',
							properties: {},
						},
					},
				},
				recordProcessing: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: { // recordTypes-recordId
							// type: 'boolean', this is really a boolean
							type: 'object',
							properties: {},
						},
					},
				},
				recordClickActionProcessing: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: { // recordTypes-recordId
							// type: 'boolean', this is really a boolean
							type: 'object',
							properties: {},
						},
					},
				},

				recordClickActionErrors: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: { // recordTypes-recordId
							// type: 'boolean', this is really a boolean
							type: 'object',
							properties: {},
						},
					},
				},
				favoritesProcessing: { type: 'boolean' },
				followProcessing: { type: 'boolean' },
				externals: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {

							},
						},
					},
				},
				pageContent: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
					},
				},
				moduleDataFilters: {
					type: 'object',
					patternProperties: {
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
					},
				},
			},
		},
	},
}

export const apiStoreLenses = lensesFromSchema(apiStoreSchema)

export const generalApiModuleDescriptionSchema = {
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: {
			type: 'object',
			properties: {
				endpointId: { type: 'string' },
				redirectOn404: { type: 'boolean' },
				websocketsEndpointId: { type: 'string' },
				recordType: { type: 'string' },
				userDataType: { type: 'string' },
				recordPayloadMap: { type: 'array' },
				listPayload: { type: 'object' },
				listPayloadMap: { type: 'array' },
				formType: { type: 'string' },
				formPayload: { type: 'object' },
				onEnterThunk: { type: 'function' },
				onEnterActions: { type: 'array' },
			},
		},
	},
}

export const generalApiModuleDescriptionLenses = lensesFromSchema(
	generalApiModuleDescriptionSchema,
)

export const nextKeyProp = prop('next')
export const itemsProp = prop('items')
export const totalsProp = prop('totals')
export const dataProp = prop('data')
export const idProp = prop('id')
export const projectIdProp = prop('projectId')
export const mnemonicIdProp = prop('mnemonicId')
export const projectUrlProp = prop('projectUrl')
export const nameProp = prop('name')
export const messageIdProp = prop('messageId')
export const requestedRecordIdProp = prop('requestedRecordId')
export const linkProp = prop('url')
export const rewardDataProp = prop('rewardData')
export const landingUrlProp = prop('landingUrl')
export const platformIdProp = prop('platformId')
export const campaignIdProp = prop('campaignId')
export const whitelistTypeProp = prop('whitelistType')
