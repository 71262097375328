import React from 'react'

export default ({ gradient: Gradient }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
		<Gradient />
		<path
			fill={Gradient ? 'url(#hover-gradient)' : '#fff'}
			fillRule="evenodd"
			d="M15.117 0H.883A.883.883 0 0 0 0 .883v14.234c0 .488.395.883.883.883h7.669V9.812H6.469V7.39h2.083V5.608c0-2.066 1.263-3.191 3.106-3.191a17.71 17.71 0 0 1 1.86.093v2.16h-1.27c-1.004 0-1.2.475-1.2 1.176v1.542h2.4l-.311 2.422h-2.103V16h4.083a.883.883 0 0 0 .883-.883V.883A.883.883 0 0 0 15.117 0z"
		/>
	</svg>
)
