import has from 'ramda/src/has'
import includes from 'ramda/src/includes'
import tryCatch from 'ramda/src/tryCatch'
import reportError from 'root/src/shared/util/reportError'

const createReducer = (reducerObj, initialState) => (state = initialState, action) => tryCatch(
	() => {
		const { type, payload } = action
		if (includes('@@redux', type)) {
			return state
		}
		if (!has(type, reducerObj)) {
			throw new Error(`Action ${type} not found`)
		}
		return reducerObj[type](state, payload)
	}, (e) => {
		reportError(e)
		return state
	},
)()

export default createReducer
