import assocPath from 'ramda/src/assocPath'
import map from 'ramda/src/map'
import splitEvery from 'ramda/src/splitEvery'
import isEmpty from 'ramda/src/isEmpty'
import compose from 'ramda/src/compose'
import { ADD_WHITELISTED_CREATORS_ON_SUCCESS } from 'root/src/client/logic/tableItem/actionIds'
import { GET_WHITELISTED_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { WHITELISTED_CREATORS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { WHITELIST_CREATORS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'
import { listStoreLenses } from 'root/src/client/logic/list/lenses'

const {
	setListIds,
	viewCurrentPage,
	viewListDataLength,
	setPagesCount,
} = listStoreLenses

export default {
	[ADD_WHITELISTED_CREATORS_ON_SUCCESS]: (state, payload) => {
		const ids = map((creator) => {
			const { platformId, campaignId, whitelistType } = creator
			return `${platformId}-${campaignId || whitelistType}`
		}, payload.items)

		const listDataLength = viewListDataLength(
			WHITELIST_CREATORS_LIST_MODULE_ID,
			state,
		)
		const paginateData = () => {
			const currentPage = viewCurrentPage(
				WHITELIST_CREATORS_LIST_MODULE_ID,
				state,
			)
			const paginated = splitEvery(listDataLength, ids)
			return isEmpty(paginated[currentPage - 1])
				? paginated[currentPage - 2]
				: paginated[currentPage - 1]
		}

		const listStoreKey = createListStoreKey(
			{ routeId: WHITELISTED_CREATORS_ROUTE_ID },
			GET_WHITELISTED_CREATORS,
			WHITELIST_CREATORS_LIST_MODULE_ID,
		)
		const modifiedList = !listDataLength ? ids : paginateData()

		return compose(
			assocPath(
				['api', 'lists', listStoreKey, 'items'],
				modifiedList,
			),
			setListIds(WHITELIST_CREATORS_LIST_MODULE_ID, ids),
			setPagesCount(
				WHITELIST_CREATORS_LIST_MODULE_ID,
				splitEvery(listDataLength, ids).length,
			),
		)(state)
	},
}
