import {
	adminKey,
	approvedDropEventsKey,
	awardPointsEventsKey,
	getComingSoonEventsKey,
	getPrivateLiveEventsKey,
	getUserEventsWithInitialProductIdKey,
	liveEventsKey,
	notPendingDropEventsKey,
	userEventsKey,
	userLiveEventsKey,
} from 'root/src/shared/constants/keys'
import { DropSerializer } from 'root/src/shared/drops/drops.constants'

export default {
	type: 'object',
	properties: {
		section: {
			type: 'string',
			enum: [
				adminKey,
				userEventsKey,
				liveEventsKey,
				approvedDropEventsKey,
				notPendingDropEventsKey,
				getComingSoonEventsKey,
				userLiveEventsKey,
				awardPointsEventsKey,
				getUserEventsWithInitialProductIdKey,
				getPrivateLiveEventsKey,
			],
		},
		dataFilter: { type: 'string' },
		value: { type: 'string' },
		currentPage: { type: 'integer' },
		pageItemLength: {
			type: 'integer', maximum: 100,
		},
		all: { type: 'boolean' },
		serializer: {
			type: 'string',
			enum: [DropSerializer.LIST_VIEW],
		},
	},
	required: ['section'],
	additionalProperties: false,
}
