import { GET_PENDING_BRAND_USERS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { pendingBrandUser } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import emptyPayloadSchema	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'
import getPendingBrandUsersResponseSchema	from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getPendingBrandUsersResponseSchema'

export default {
	[GET_PENDING_BRAND_USERS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: listEndpointType,
		recordType: pendingBrandUser,
		payloadSchema: emptyPayloadSchema,
		responseSchema: getPendingBrandUsersResponseSchema,
	},
}
