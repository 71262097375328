export default {
	type: 'object',
	properties: {
		brandName: {
			type: 'string',
		},
		brandWebsite: {
			type: 'string',
			errorMessage: {
				required: 'Website is required',
			},
		},
		brandTwitter: {
			type: 'string',
		},
	},
	required: ['brandName', 'brandWebsite'],
	additionalProperties: false,
}
