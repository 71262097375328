import {
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import providers from 'root/src/shared/constants/providers'

const routeMap = {
	twitch: { routeId: VIEW_CREATOR_DATA_ROUTE_ID },
	youtube: { routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID },
	tiktok: { routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID },
	facebook: { routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID },
}

export default (assignee, rMap = routeMap) => {
	const { platform, mnemonicId, displayName } = assignee
	// for twitch displayName also works
	const recordId = (platform === providers.twitch ? displayName : mnemonicId) || mnemonicId

	return { ...rMap[platform], routeParams: { recordId } }
}
