import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import getProfile from 'root/src/client/logic/app/selectors/getProfile'
import { userGroups } from 'root/src/shared/constants/auth'
import { userEventsKey } from 'root/src/shared/constants/keys'
import { profileRouteMap } from 'root/src/shared/constants/route'
import {
	GET_DROP_EVENTS, GET_PROFILE,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { CAMPAIGNS_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { RewardEndpoint } from 'root/src/shared/rewards/constants'
import userRoleSelector from 'root/src/client/logic/auth/selectors/userRoleSelector'
import prop from 'ramda/src/prop'
import { groupDefaultRouteIdMap } from 'root/src/shared/util/groupDefaultRouteIdMap'

const routeMap = {
	[userGroups.user]: () => ({ routeId: groupDefaultRouteIdMap[userGroups.user] }),
	[userGroups.brand]: async ({ dispatch }) => {
		const [
			productsRes, dropEventsRes,
		] = await Promise.all([
			dispatch(apiRequest(RewardEndpoint.GET_BRAND_REWARDS, { limit: 1 })),
			dispatch(apiRequest(GET_DROP_EVENTS, {
				section: userEventsKey, pageItemLength: 1, // we only need to know if user has any event
			})),
		])

		if (!productsRes.body?.length) {
			return { routeId: groupDefaultRouteIdMap[userGroups.brand] }
		}

		if (!dropEventsRes.body?.items?.length) {
			return { routeId: groupDefaultRouteIdMap[userGroups.brand] }
		}

		return { routeId: CAMPAIGNS_PAGE_ROUTE_ID }
	},
	[userGroups.viewer]: () => ({ routeId: groupDefaultRouteIdMap[userGroups.viewer] }),
	[userGroups.creator]: async ({
		dispatch, getState,
	}) => {
		const state = getState()
		const profile = getProfile(state)

		// that's only a server side case where we won't have profile in state
		if (!profile) {
			const res = await dispatch(apiRequest(GET_PROFILE))
			if (res.body?.mnemonicId) {
				const { mnemonicId, platform } = res.body

				return {
					routeId: profileRouteMap[platform],
					routeParams: { recordId: mnemonicId },
				}
			}

			return { routeId: groupDefaultRouteIdMap[userGroups.creator] }
		}

		const { mnemonicId, platform } = profile

		return {
			routeId: profileRouteMap[platform],
			routeParams: { recordId: mnemonicId },
		}
	},
	[userGroups.admin]: () => ({ routeId: groupDefaultRouteIdMap[userGroups.admin] }),
	[userGroups.agency]: () => ({ routeId: groupDefaultRouteIdMap[userGroups.agency] }),
	[userGroups.agencyAccountManager]: () => ({ routeId: groupDefaultRouteIdMap[userGroups.agencyAccountManager] }),
	[userGroups.brandUser]: () => ({ routeId: groupDefaultRouteIdMap[userGroups.brandUser] }),
	[userGroups.brandAdmin]: () => ({ routeId: groupDefaultRouteIdMap[userGroups.brandAdmin] }),
}

export const getDefaultRoute = ({ dispatch, getState }) => {
	const state = getState()
	const userRole = userRoleSelector(state)
	const roleSpecificHandling = prop(userRole, routeMap)
	return roleSpecificHandling({
		dispatch,
		getState,
	})
}
