import { DROP_EVENT_PAGE_ROUTE_ID, } from 'root/src/shared/descriptions/routes/routeIds';
import { profileRouteMap, profilePathnameMap } from 'root/src/shared/constants/route';
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls';
export const PERSISTENT_ROUTE_IDS = new Set([
    DROP_EVENT_PAGE_ROUTE_ID,
    ...Object.values(profileRouteMap),
]);
export const PERSISTENT_PATHNAMES = [
    routeUrls.creatorsTwitch(''),
    routeUrls.creatorsYoutube(''),
    routeUrls.creatorsTiktok(''),
    routeUrls.drop(''),
];
// determines if user should stay on the same route or be redirected to
export const getCreatorRedirectRouteId = ({ routeId, platform }) => {
    if (!PERSISTENT_ROUTE_IDS.has(routeId)) {
        return profileRouteMap[platform];
    }
    return routeId;
};
export const getCreatorRedirectPathname = ({ pathname, platform, mnemonicId }) => {
    if (!PERSISTENT_PATHNAMES.find(path => pathname === null || pathname === void 0 ? void 0 : pathname.startsWith(path))) {
        return `${profilePathnameMap[platform](mnemonicId)}`;
    }
    return pathname;
};
