import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { listEndpointType, userDataEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { publisher } from 'root/src/shared/descriptions/endpoints/recordTypes';
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema';
import getAgencyPublishersResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getAgencyPublishersResponseSchema';
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants';
const endpointBase = {
    authentication: authenticationTypes.authenticated,
    recordType: publisher,
    authenticationRole: [
        userGroups.agencyAccountManager,
        userGroups.brand,
    ],
    payloadSchema: emptyPayloadSchema,
    responseSchema: getAgencyPublishersResponseSchema,
};
export const getAgencyPublishersEndpoints = {
    [agencyEndpointIds.GET_AGENCY_PUBLISHERS]: Object.assign(Object.assign({}, endpointBase), { endpointType: userDataEndpointType }),
    [agencyEndpointIds.GET_AGENCY_PUBLISHERS_LIST]: Object.assign(Object.assign({}, endpointBase), { endpointType: listEndpointType }),
};
