import nickPCurve from 'root/src/client/public/nick-p-curve.png'
import charleyVUberStrategist from 'root/src/client/public/charley-v-uberStrategist.png'
import { BRANDS_TESTIMONIALS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { colorsNew } from 'root/src/client/web/commonStyles'

export default {
	[BRANDS_TESTIMONIALS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'Testimonials',
		pageContent: {
			testimonialItems: [
				{
					description: 'Dare Drop allowed UberStrategist to provide Bossa Studios a unique approach to promoting their game launch while working with influencers. Showing funny gameplay and challenges through dares improved our engagement with the influencers, as well as their own gameplay experience.',
					name: 'Charley V.',
					title: 'Influencer Manager @ UberStrategist',
					image: charleyVUberStrategist,
					color: colorsNew.teal,
				},
				{
					description: 'It was great working with Dare Drop. Getting our campaign running with their portal was easy, and streamlined the process of mustering engagement with creators.',
					name: 'Nick P.',
					title: 'Product Manager @ Curve Games',
					image: nickPCurve,
					color: colorsNew.blue,
				},
			],
		},
	},
}
