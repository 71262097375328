import md5 from 'blueimp-md5';
export default function getFileHash(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            var _a;
            const result = (_a = event.target) === null || _a === void 0 ? void 0 : _a.result;
            if (!result) {
                reject(new Error('Failed to read file'));
            }
            if (typeof result === 'string') {
                const hash = md5(result);
                resolve(hash.toString());
            }
            reject(new Error('Cannot read file'));
        };
        reader.readAsBinaryString(file);
    });
}
