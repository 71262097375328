import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import {
	GET_DARES_REPORT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'

export default item => async (dispatch, getState) => {
	const state = getState()
	const payload = {
		id: item.id,
	}
	const { body, error } = await invokeApiRequest(GET_DARES_REPORT, payload, state)

	if (error) {
		// eslint-disable-next-line no-alert
		alert(error.message)
		return
	}

	window.open(body.fileUrl, '_parent')
}
