import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
export const creatorApiProxyEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.admin,
    endpointType: otherEndpointType,
    payloadSchema: {
        type: 'object',
        properties: {
            url: { type: 'string' },
            body: { type: ['object', 'array'] },
            method: { type: 'string' },
        },
        required: ['url', 'body', 'method'],
        additionalProperties: false,
    },
};
