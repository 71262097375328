import {
	MY_PROJECT_BANNER_HEADER_MODULE_ID,
	MY_PROJECTS_LIST_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { CREATE_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import {
	sectionKey, myDaresKey, allKey, daresForMeKey,
	completedKey, myFavoritesKey, myPledgesKey,	iCreatedKey,
} from 'root/src/shared/constants/keys'

export default {
	[MY_PROJECT_BANNER_HEADER_MODULE_ID]: {
		moduleType: 'bannerHeader',
		listModuleId: MY_PROJECTS_LIST_MODULE_ID,
		bannerImageText: 'Your Dares',
		bannerImageSubText: 'Keep track of your Dares, Pledges, and Videos',
		textWithBg: true,
		bannerSubText: 'Select a Dare',
		link: {
			routeId: CREATE_PROJECT_ROUTE_ID,
			label: 'Create a new Dare +',
		},
		createNewDareActive: true,
		embeddedContent: {
			moduleType: 'embeddedForm',
			fields: [
				{
					fieldId: sectionKey,
					inputType: 'dropdownEmbedded',
					options: [
						{
							label: 'All',
							payload: {
								section: myDaresKey,
								subSection: allKey,
							},
						},
						{
							label: 'Dares for Me',
							payload: {
								section: myDaresKey,
								subSection: daresForMeKey,
							},
						},
						{
							label: 'Completed',
							payload: {
								section: myDaresKey,
								subSection: completedKey,
							},
						},
						{
							label: 'I Created',
							payload: {
								section: myDaresKey,
								subSection: iCreatedKey,
							},
						},
						{
							label: 'My Favorites',
							payload: {
								section: myDaresKey,
								subSection: myFavoritesKey,
							},
						},
						{
							label: 'My Pledges',
							payload: {
								section: myDaresKey,
								subSection: myPledgesKey,
							},
						},
					],
					endpointId: GET_PROJECTS,
				},
			],
		},
	},
}
