import join from 'ramda/src/join'
import pipe from 'ramda/src/pipe'
import dissocPath from 'ramda/src/dissocPath'
import assoc from 'ramda/src/assoc'

import { SIGN_UP_TOKEN_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import signUpSchema from 'root/src/shared/descriptions/formSchemas/signUpSchema'
import { LOGIN_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[SIGN_UP_TOKEN_FORM_MODULE_ID]: {
		moduleType: 'form',
		schema: pipe(
			dissocPath(['properties', 'userGroup']),
			assoc('required', ['password', 'confirmPassword']),
		)(signUpSchema),
		title: 'Set password',
		subTitle: [
			'Already have an account?',
			{ text: 'Sign In', routeId: LOGIN_ROUTE_ID },
		],
		postSubmitCaption: join('', [
			'By signing up, you agree to our terms of use, privacy policy, and ',
			'cookie policy. Our policies explain how we use your data to ',
			'deliver, improve, and promote our service and our site, and how ',
			'you can exercise your rights to control that use.',
		]),
		fields: [
			{
				fieldId: 'email',
				label: 'Email',
				inputType: 'email',
				fieldHint: 'Used for login',
			},
			{
				fieldId: 'password',
				label: 'Password',
				inputType: 'password',
				fieldHint: 'Must have a lower & uppercase letter, number and symbol',
			},
			{
				fieldId: 'confirmPassword',
				label: 'Confirm password',
				inputType: 'password',
			},
		],
		submits: [
			{ label: 'Sign Up' },
		],
	},
}
