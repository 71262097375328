export default {
	type: 'array',
	items: {
		type: 'object',
		properties: {
			email: { type: 'string' },
			userId: { type: 'string' },
			code: { type: 'string' },
			created: { type: 'string' },
		},
		required: ['email', 'userId', 'code', 'created'],
		additionalProperties: false,
	},
}
