import converge from 'ramda/src/converge'
import prop from 'ramda/src/prop'
import pipe from 'ramda/src/pipe'
import keys from 'ramda/src/keys'
import head from 'ramda/src/head'
import identity from 'ramda/src/identity'
import reduce from 'ramda/src/reduce'
import append from 'ramda/src/append'
import {
	GET_ALERTS_CONFIG, GET_ALERTS_URL, GET_CUSTOM_SOUNDS,
	GET_OBS_TOP_DARES_CONFIG,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import {	ALERTS_AUDIO_PLAYER_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { creatorAlertsAudioPanel } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { fixedDareSounds, fixedPledgeSounds } from 'root/src/shared/constants/sounds'
import outputs from 'root/cfOutput'

const { domainName } = outputs

export const dareSoundDefault = converge(prop, [
	pipe(keys, head),
	identity,
])(fixedDareSounds)

export const pledgeSoundsDefault = converge(
	reduce((acc, key) => append(prop(key, fixedPledgeSounds), acc), []),
	[keys, identity],
)(fixedPledgeSounds)

export const pledgeOptionsDefault = [
	{
		soundId: 'chicken',
		bitsId: 'bits50',
		label: '50',
		amount: 50,
		audioSrc: `https://${domainName}/defaultFiles/chicken.mp3`,
		soundLabel: 'Chicken',
	},
	{
		soundId: 'karateChop',
		bitsId: 'bits100',
		label: '100',
		amount: 100,
		audioSrc: `https://${domainName}/defaultFiles/karateChop.mp3`,
		soundLabel: 'Karate Chop',
	},
	{
		soundId: 'rimShot',
		bitsId: 'bits500',
		label: '500',
		amount: 500,
		audioSrc: `https://${domainName}/defaultFiles/rimShot.mp3`,
		soundLabel: 'Rim Shot',
	},
	{
		soundId: 'ah',
		bitsId: 'bits1000',
		label: '1000',
		amount: 1000,
		audioSrc: `https://${domainName}/defaultFiles/ah.mp3`,
		soundLabel: 'Ah',
	},
]

export default {
	[ALERTS_AUDIO_PLAYER_MODULE_ID]: {
		moduleType: 'record',
		recordPageType: creatorAlertsAudioPanel,
		endpointId: [
			GET_ALERTS_CONFIG,
			GET_CUSTOM_SOUNDS,
			GET_ALERTS_URL,
			GET_OBS_TOP_DARES_CONFIG,
		],
	},
}
