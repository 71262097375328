import { TITLE_HEADER_MARKETPLACE_MODULE_ID, ACTIVE_PROJECTS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { SORT_BY_BOUNTY, SORT_BY_NEWEST, SORT_BY_TIME_LEFT } from 'root/src/shared/constants/sortTypes'
import { CREATE_PROJECT_ROUTE_ID, FILTER_DARES_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { marketplaceKey, activeKey, completedKey, yourInterestsKey } from 'root/src/shared/constants/keys'
import { youtubeSearchDebounce } from 'root/src/shared/constants/youtube'

export default {
	[TITLE_HEADER_MARKETPLACE_MODULE_ID]: {
		moduleType: 'bannerHeader',
		listModuleId: ACTIVE_PROJECTS_LIST_MODULE_ID,
		bannerTabs: [
			{
				id: 0,
				tabLabel: 'Your Interests',
				endpointId: [GET_PROJECTS],
				fixedPayload: {
					section: marketplaceKey,
					subSection: yourInterestsKey,
				},
				needsTwitchAuth: true,
			},
			{
				id: 1,
				tabLabel: 'Active',
				endpointId: [GET_PROJECTS],
				fixedPayload: {
					section: marketplaceKey,
					subSection: activeKey,
				},
				needsTwitchAuth: false,
			},
			{
				id: 2,
				tabLabel: 'Completed',
				endpointId: [GET_PROJECTS],
				fixedPayload: {
					section: marketplaceKey,
					subSection: completedKey,
				},
				needsTwitchAuth: false,
			},
		],
		createNewDareActive: true,
		link: {
			routeId: CREATE_PROJECT_ROUTE_ID,
			label: 'Create a New Dare +',
		},
		embeddedContent: {
			moduleType: 'embeddedForm',
			filtersRoute: FILTER_DARES_ROUTE_ID,
			fields: [
				{
					fieldId: 'sort',
					fieldCaption: 'Sort By:',
					fixedLargeWidth: true,
					inputType: 'dropdownEmbedded',
					options: [
						{
							label: 'Newest',
							id: 0,
							value: SORT_BY_NEWEST,
						},
						{
							label: 'Bounty Amount',
							id: 1,
							value: SORT_BY_BOUNTY,
						},
						{
							label: 'Time Left',
							id: 2,
							value: SORT_BY_TIME_LEFT,
						},
					],
					endpointId: GET_PROJECTS,
				},
				{
					fieldId: 'game',
					fieldCaption: 'Filter By:',
					fixedSmallWidth: true,
					inputType: 'autoCompleteEmbedded',
					optionsPromiseType: 'twitchGames',
					placeholder: 'Game',
					endpointId: GET_PROJECTS,
				},
				{
					inputType: 'variantFieldEmbedded',
					fieldId: 'assignee',
					fixedSmallWidth: true,
					placeholder: 'Creator',
					endpointId: GET_PROJECTS,
					variantOptions: [
						{
							id: 'twitch',
							name: 'Twitch',
							optionsPromiseType: 'twitchChannels',
							inputType: 'autoCompleteEmbedded',
						},
						{
							id: 'youtube',
							name: 'YouTube',
							debounceDelay: youtubeSearchDebounce,
							optionsPromiseType: 'youtubeChannels',
							inputType: 'autoCompleteEmbedded',
						},
					],
				},
			],
		},
	},
}
