import {
	DARE_CREATE_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[DARE_CREATE_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Daring!',
			paragraphs: [
				'Your new dare is live. Don\'t forget to tell your friends!',
				'This is just a pledge. You’ll only be charged when the creator delivers.',
			],
			socialSharing: true,
		},
	},
}
