var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { useMutation } from '@tanstack/react-query';
import { useDareDropApi } from 'root/src/client/v2/common/hooks/useDareDropApi';
import { usersEndpointIds } from 'root/src/shared/users/users.constants';
import { useApplicationState } from 'root/src/client/v2/userData/hooks/useApplicationState';
export const useHideEngageViewersModal = () => {
    const [hideEngageViewersMessageSelected, setHideEngageViewersMessageSelected] = useState(false);
    const { fetcher } = useDareDropApi();
    const { invalidateApplicationState } = useApplicationState();
    const { hide } = useModal();
    const { mutateAsync: hideEngageViewersMutation } = useMutation({
        mutationKey: [],
        mutationFn: () => __awaiter(void 0, void 0, void 0, function* () {
            return fetcher({
                endpointId: usersEndpointIds.HIDE_ENGAGE_VIEWERS_MESSAGE,
                payload: {},
            }, true);
        }),
    });
    const toggleHideEngageViewersMessageSelected = () => setHideEngageViewersMessageSelected(!hideEngageViewersMessageSelected);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (hideEngageViewersMessageSelected) {
            yield hideEngageViewersMutation();
            yield invalidateApplicationState();
        }
        hide();
    });
    return {
        hideEngageViewersMessageSelected,
        toggleHideEngageViewersMessageSelected,
        onSubmit,
    };
};
