var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { httpBatchLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import outputs from 'root/cfOutput';
import { getCookie } from 'root/src/client/v2/browser/helpers/cookies';
import { isLocalEnv, isLocalhost } from 'root/src/shared/util/envSelect';
import superjson from 'superjson';
import { ssrPrepass } from '@trpc/next/ssrPrepass';
export function getTRPCUrl() {
    if (isLocalEnv || isLocalhost) {
        return 'http://localhost:9898';
    }
    return `https://api.${outputs.domainName}/trpc`;
}
export const trpc = createTRPCNext({
    ssr: true,
    ssrPrepass,
    transformer: superjson,
    config() {
        return {
            links: [
                httpBatchLink({
                    transformer: superjson,
                    /**
                     * If you want to use SSR, you need to use the server's full URL
                     * @link https://trpc.io/docs/v11/ssr
                     * */
                    url: getTRPCUrl(),
                    // You can pass any HTTP headers you wish here
                    headers() {
                        return __awaiter(this, void 0, void 0, function* () {
                            return {
                                Authorization: `Bearer ${getCookie('accessToken')}`,
                            };
                        });
                    },
                }),
            ],
        };
    },
});
