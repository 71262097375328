export class Encode {
    static string(value) {
        return String(value);
    }
    static json(value) {
        return JSON.stringify(value);
    }
}
export class Decode {
    static json(value) {
        return JSON.parse(value);
    }
    static boolean(value) {
        return (value === 'true');
    }
    static number(value) {
        return Number(value);
    }
    static string(value) {
        return String(value);
    }
}
