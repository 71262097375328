import { SignInWithProviderButton } from 'root/src/client/v2/auth/components/SignInWithProviderButton';
import { Provider } from 'root/src/shared/@types/providers';
import { useUserData } from 'root/src/client/v2/userData/hooks/useUserData';
export const SignInWithProviderButtons = (params) => {
    var _a, _b, _c;
    const { userData, isLoading } = useUserData();
    return (<div className={params.className}>
			<SignInWithProviderButton provider={Provider.Twitch} isLinking={params.isLinking} disabled={!!((_a = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _a === void 0 ? void 0 : _a.twitchData) || isLoading}>
				Sign in with Twitch
			</SignInWithProviderButton>
			<SignInWithProviderButton provider={Provider.Google} isLinking={params.isLinking} disabled={!!((_b = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _b === void 0 ? void 0 : _b.youtubeData) || isLoading}>
				Sign in with YouTube
			</SignInWithProviderButton>
			<SignInWithProviderButton provider={Provider.TikTok} isLinking={params.isLinking} disabled={!!((_c = userData === null || userData === void 0 ? void 0 : userData.creatorData) === null || _c === void 0 ? void 0 : _c.tiktokData) || isLoading}>
				Sign in with TikTok
			</SignInWithProviderButton>
		</div>);
};
