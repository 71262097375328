export const INIT_API_LIST_REQUEST = 'INIT_API_LIST_REQUEST'
export const API_LIST_REQUEST_SUCCESS = 'API_LIST_REQUEST_SUCCESS'
export const CURRENT_LIST_PAYLOAD = 'CURRENT_LIST_PAYLOAD'
export const API_LIST_REQUEST_ERROR = 'API_LIST_REQUEST_ERROR'

export const INIT_API_RECORD_REQUEST = 'INIT_API_RECORD_REQUEST'
export const API_RECORD_REQUEST_SUCCESS = 'API_RECORD_REQUEST_SUCCESS'
export const API_RECORD_REQUEST_ERROR = 'API_RECORD_REQUEST_ERROR'

export const INIT_API_RECORD_CLICK_ACTION_REQUEST = 'INIT_API_RECORD_CLICK_ACTION_REQUEST'
export const API_RECORD_CLICK_ACTION_REQUEST_SUCCESS = 'API_RECORD_CLICK_ACTION_REQUEST_SUCCESS'
export const API_RECORD_CLICK_ACTION_REQUEST_ERROR = 'API_RECORD_CLICK_ACTION_REQUEST_ERROR'

export const API_EXTERNAL_REQUEST_SUCCESS = 'API_EXTERNAL_REQUEST_SUCCESS'
export const API_FETCH_USER_DATA_SUCCESS = 'API_FETCH_USER_DATA_SUCCESS'

export const GENERAL_RECORD_MODIFICATION = 'GENERAL_RECORD_MODIFICATION'

export const CLEAR_LIST_PROCESSING = 'CLEAR_LIST_PROCESING'
export const CLEAR_LIST = 'CLEAR_LIST'
export const SET_WEBSOCKETS_CLIENT = 'SET_WEBSOCKETS_CLIENT'
export const MANAGE_LIST_ITEM = 'MANAGE_LIST_ITEM'
export const SET_MODULE_DATA_FILTER = 'SET_MODULE_DATA_FILTER'
