import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { brandProduct } from 'root/src/shared/descriptions/endpoints/recordTypes'

import { addBrandCustomRewardPayloadSchema } from 'root/src/shared/rewards/schemas/addBrandCustomReward.payload.schema'
import { addBrandCustomRewardResponseSchema } from 'root/src/shared/rewards/schemas/addBrandCustomReward.response.schema'

export const addBrandCustomRewardEndpoint = {
	authentication: authenticationTypes.authenticated,
	authenticationRole: userGroups.brandUser,
	endpointType: recordEndpointType,
	recordType: brandProduct,
	payloadSchema: addBrandCustomRewardPayloadSchema,
	responseSchema: addBrandCustomRewardResponseSchema,
}
