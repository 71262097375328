import { OBS_AUDIO_ALERT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { alertsUrlTokenKey } from 'root/src/shared/constants/keys'
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'

const payloadSchema = emptyPayloadSchema

export default {
	[OBS_AUDIO_ALERT]: {
		payloadSchema,
		endpointType: gatewayEndpointType,
		tokenKey: alertsUrlTokenKey,
	},
}
