import {
	COMPLETE_WIDGET_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[COMPLETE_WIDGET_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			noImage: true,
			modalSize: 'small',
			title: 'Share Dare Drop with your fans!',
			showLink: true,
			socialSharing: true,
			sharingMessage: 'daredrops Have a challenge for me? Dare me',
		},
		showModal: true,
	},
}
