import { GET_PANEL_SETTINGS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[GET_PANEL_SETTINGS]: {
		authentication: authenticationTypes.optional,
		endpointType: otherEndpointType,
	},
}
