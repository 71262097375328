import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import managePublisherAccountPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/managePublisherAccountPayloadSchema';
import addAgencyPublisherResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/addAgencyPublisherResponseSchema';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants';
const payloadSchema = managePublisherAccountPayloadSchema;
const responseSchema = addAgencyPublisherResponseSchema;
export const managePublisherAccountEndpoint = {
    [agencyEndpointIds.MANAGE_PUBLISHER_ACCOUNT]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.agencyAccountManager,
        endpointType: otherEndpointType,
        payloadSchema,
        responseSchema,
        middlewares: [VALIDATE_TOKEN_ACCOUNT],
    },
};
