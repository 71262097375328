export const LOGIN_ROUTE_ID = 'LOGIN_ROUTE_ID'
export const SIGN_UP_ROUTE_ID = 'SIGN_UP_ROUTE_ID'
export const VERIFY_NEW_EMAIL_ROUTE_ID = 'VERIFY_NEW_EMAIL_ROUTE_ID'
export const VERIFICATION_SUCCESS_ROUTE_ID = 'VERIFICATION_SUCCESS_ROUTE_ID'
export const VERIFICATION_FAILURE_ROUTE_ID = 'VERIFICATION_FAILURE_ROUTE_ID'
export const VERIFICATION_FINISHED_ROUTE_ID = 'VERIFICATION_FINISHED_ROUTE_ID'
export const NEW_EMAIL_VERIFICATION_SUCCESS_ROUTE_ID = 'NEW_EMAIL_VERIFICATION_SUCCESS_ROUTE_ID'
export const NEW_EMAIL_VERIFICATION_FAILURE_ROUTE_ID = 'NEW_EMAIL_VERIFICATION_FAILURE_ROUTE_ID'
export const FORGOT_PASSWORD_ROUTE_ID = 'FORGOT_PASSWORD_ROUTE_ID'
export const CONFIRM_FORGET_PASSWORD_ROUTE_ID = 'CONFIRM_FORGET_PASSWORD_ROUTE_ID'
export const TRANSFER_ACCOUNT_ROUTE_ID = 'TRANSFER_ACCOUNT_ROUTE_ID'
export const RESET_PASSWORD_ROUTE_ID = 'RESET_PASSWORD_ROUTE_ID'
export const ACCOUNT_SETTINGS_ROUTE_ID = 'ACCOUNT_SETTINGS_ROUTE_ID'

export const CHANGE_PASSWORD_FORM_ROUTE_ID = 'CHANGE_PASSWORD_FORM_ROUTE_ID'
export const SET_EMAIL_FORM_ROUTE_ID = 'SET_EMAIL_FORM_ROUTE_ID'
export const BRAND_SETTINGS_ROUTE_ID = 'BRAND_SETTINGS_ROUTE_ID'
export const BRAND_DARES_ROUTE_ID = 'BRAND_DARES_ROUTE_ID'
export const BRAND_PRODUCTS_AND_KEYS_ROUTE_ID = 'BRAND_PRODUCTS_AND_KEYS_ROUTE_ID'
export const CONNECTED_CHANNELS_ROUTE_ID = 'CONNECTED_CHANNELS_ROUTE_ID'
export const MANAGE_PAYOUT_ROUTE_ID = 'MANAGE_PAYOUT_ROUTE_ID'
export const FAQ_ROUTE_ID = 'FAQ_ROUTE_ID'
export const TERMS_OF_SERVICE_ROUTE_ID = 'TERMS_OF_SERVICE_ROUTE_ID'
export const PRIVACY_POLICY_ROUTE_ID = 'PRIVACY_POLICY_ROUTE_ID'
export const COPYRIGHT_POLICY_ROUTE_ID = 'COPYRIGHT_POLICY_ROUTE_ID'
export const COOKIE_POLICY_ROUTE_ID = 'COOKIE_POLICY_ROUTE_ID'
export const RULES_OF_USE_ROUTE_ID = 'RULES_OF_USE_ROUTE_ID'
export const CREATE_PROJECT_ROUTE_ID = 'CREATE_PROJECT_ROUTE_ID'
export const CREATE_BRAND_PROJECT_ROUTE_ID = 'CREATE_BRAND_PROJECT_ROUTE_ID'
export const VIEW_PROJECT_ROUTE_ID = 'VIEW_PROJECT_ROUTE_ID'
export const CLAIM_PROJECT_ROUTE_ID = 'CLAIM_PROJECT_ROUTE_ID'

export const MY_PROJECTS_ROUTE_ID = 'MY_PROJECTS_ROUTE_ID'
export const BRAND_PENDING_DELIVERIES_ROUTE_ID = 'BRAND_PENDING_DELIVERIES_ROUTE_ID'
export const PENDING_DELIVERIES_ROUTE_ID = 'PENDING_DELIVERIES_ROUTE_ID'
export const DARE_DELIVERY_DETAIL_ROUTE_ID = 'DARE_DELIVERY_DETAIL_ROUTE_ID'
export const BRAND_DARE_DELIVERY_DETAIL_ROUTE_ID = 'BRAND_DARE_DELIVERY_DETAIL_ROUTE_ID'
export const CREATORS_PAGE_ROUTE_ID = 'CREATORS_PAGE_ROUTE_ID'
export const PLEDGE_PROJECT_ROUTE_ID = 'PLEDGE_PROJECT_ROUTE_ID'
export const PUBLISHERS_PAGE_ROUTE_ID = 'PUBLISHERS_PAGE_ROUTE_ID'

export const DELIVERY_DARE_FORM_ROUTE_ID = 'DELIVERY_DARE_FORM_ROUTE_ID'
export const PAYOUT_ADMIN_LIST_ROUTE_ID = 'PAYOUT_ADMIN_LIST_ROUTE_ID'
export const COUPON_ADMIN_ROUTE_ID = 'COUPON_ADMIN_ROUTE_ID'
export const ADMIN_BAN_IP_FORM_ROUTE_ID = 'ADMIN_BAN_IP_FORM_ROUTE_ID'

export const VIEW_CREATOR_DATA_ROUTE_ID = 'VIEW_CREATOR_DATA_ROUTE_ID'
export const VIEW_YT_CREATOR_DATA_ROUTE_ID = 'VIEW_YT_CREATOR_DATA_ROUTE_ID'
export const VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID = 'VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID'
export const VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID = 'VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID'
export const FILTER_DARES_ROUTE_ID = 'FILTER_DARES_ROUTE_ID'
export const FEATURED_DARES_ADMIN_ROUTE_ID = 'FEATURED_DARES_ADMIN_ROUTE_ID'
export const CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID = 'CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID'
export const AMBASSADOR_PROGRAM_ROUTE_ID = 'AMBASSADOR_PROGRAM_ROUTE_ID'
export const CREATORS_LEADERBOARD_ROUTE_ID = 'CREATORS_LEADERBOARD_ROUTE_ID'

export const EDIT_PROFILE_ROUTE_ID = 'EDIT_PROFILE_ROUTE_ID'

export const PENDING_BRANDS_USERS_ADMIN_ROUTE_ID = 'PENDING_BRANDS_USERS_ADMIN_ROUTE_ID'

export const SHORT_URL_REPORT_ROUTE_ID = 'SHORT_URL_REPORT_ROUTE_ID'

export const DROP_EVENT_PAGE_ROUTE_ID = 'DROP_EVENT_PAGE_ROUTE_ID'
export const CAMPAIGNS_PAGE_ROUTE_ID = 'CAMPAIGNS_PAGE_ROUTE_ID'

export const DROP_EVENTS_ADMIN_PANEL_ROUTE_ID = 'DROP_EVENTS_ADMIN_PANEL_ROUTE_ID'
export const BRAND_ALTERNATE_PAYMENT_ROUTE_ID = 'BRAND_ALTERNATE_PAYMENT_ROUTE_ID'
export const DROP_PREVIEW_EVENT_PAGE_ROUTE_ID = 'DROP_PREVIEW_EVENT_PAGE_ROUTE_ID'
export const CREATOR_KEYS_ROUTE_ID = 'CREATOR_KEYS_ROUTE_ID'
export const VIEWERS_KEYS_ROUTE_ID = 'VIEWERS_KEYS_ROUTE_ID'
export const SET_EMAIL_FAILURE_ROUTE_ID = 'SET_EMAIL_FAILURE_ROUTE_ID'

export const VIEWERS_PAGE_ROUTE_ID = 'VIEWERS_PAGE_ROUTE_ID'
export const BRANDS_REPORTS_ROUTE_ID = 'BRANDS_REPORTS_ROUTE_ID'

export const REQUEST_INVOICE_FORM_ROUTE_ID = 'REQUEST_INVOICE_FORM_ROUTE_ID'
export const AGENCY_PUBLISHERS_LIST_ROUTE_ID = 'AGENCY_PUBLISHERS_LIST_ROUTE_ID'
export const AGENCY_ACCOUNT_MANAGERS_LIST_ROUTE_ID = 'AGENCY_ACCOUNT_MANAGERS_LIST_ROUTE_ID'
export const SIGN_UP_TOKEN_ROUTE_ID = 'SIGN_UP_TOKEN_ROUTE_ID'
export const PUBLISHER_NOTIFICATION_SETTINGS_ROUTE_ID = 'PUBLISHER_NOTIFICATION_SETTINGS_ROUTE_ID'
export const POINTS_MARKETPLACE_ROUTE_ID = 'POINTS_MARKETPLACE_ROUTE_ID'
export const WHITELISTED_CREATORS_ROUTE_ID = 'WHITELISTED_CREATORS_ROUTE_ID'
export const CAMPAIGNS_DASHBOARD_ROUTE_ID = 'CAMPAIGNS_DASHBOARD_ROUTE_ID'
export const CAMPAIGN_DASHBOARD_ROUTE_ID = 'CAMPAIGN_DASHBOARD_ROUTE_ID'
export const CAMPAIGN_CREATORS_ROUTE_ID = 'CAMPAIGN_CREATORS_ROUTE_ID'
export const CAMPAIGNS_CREATORS_ROUTE_ID = 'CAMPAIGNS_CREATORS_ROUTE_ID'
export const CAMPAIGN_CLIPS_ROUTE_ID = 'CAMPAIGN_CLIPS_ROUTE_ID'
export const CAMPAIGNS_CLIPS_ROUTE_ID = 'CAMPAIGNS_CLIPS_ROUTE_ID'
export const CAMPAIGN_DARES_ROUTE_ID = 'CAMPAIGN_DARES_ROUTE_ID'
export const CREATOR_CAMPAIGN_DARES_ROUTE_ID = 'CREATOR_CAMPAIGN_DARES_ROUTE_ID'
export const SMITE_GIVEAWAY_ROUTE_ID = 'SMITE_GIVEAWAY_ROUTE_ID'
export const RENFIELD_RULES_ROUTE_ID = 'RENFIELD_RULES_ROUTE_ID'
export const REDIRECT_ROUTE_ID = 'REDIRECT_ROUTE_ID'
export const PUBLISHER_BAN_LIST_ROUTE_ID = 'PUBLISHER_BAN_LIST_ROUTE_ID'

// DEMO
export const DEMO_CAMPAIGN_DASHBOARD_ROUTE_ID = 'DEMO_CAMPAIGN_DASHBOARD_ROUTE_ID'
export const DEMO_CAMPAIGN_CREATORS_ROUTE_ID = 'DEMO_CAMPAIGN_CREATORS_ROUTE_ID'
export const DEMO_CAMPAIGN_CLIPS_ROUTE_ID = 'DEMO_CAMPAIGN_CLIPS_ROUTE_ID'
