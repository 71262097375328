import { appStoreLenses } from 'root/src/client/logic/app/lenses'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'

const { viewAccountData } = appStoreLenses

export default (state) => {
	const brandLogo = pipe(
		viewAccountData,
		prop('brandLogo'),
	)(state) || {}
	return { brandLogoUrl: brandLogo?.big }
}
