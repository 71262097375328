import { ADD_COMMENT_REPLIES } from 'root/src/client/logic/comment/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'

const { setReplies, viewCommentChild } = apiStoreLenses

export const addCommentReplies = (state, payload) => {
	const { parentId, items } = payload
	const { replies } = viewCommentChild(parentId, state)
	return setReplies(parentId, [...replies, ...items], state)
}

export default {
	[ADD_COMMENT_REPLIES]: addCommentReplies,
}
