import { CREATORS_AUTOCOMPLETE_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import viewersBlob2 from 'root/src/client/public/backgroundBlobs/viewers2.svg'

export default {
	[CREATORS_AUTOCOMPLETE_FORM_MODULE_ID]: {
		moduleType: 'form',
		title: 'Watch Daring Creators',
		headerType: 'newDesign',
		subTitle: 'Watch creators complete dares, and get rewards like game keys, loot, and swag!',
		backgroundData: [
			{
				position: 'left',
				image: viewersBlob2,
				style: {
					position: 'absolute',
					top: '50%',
				},
			},
		],
		fields: [
			{
				fieldId: 'games',
				maxItems: 0,
				inputType: 'autoComplete',
				optionsPromiseType: 'creators',
				placeholder: 'Search',
				fieldIcon: faSearch,
				uniqueItems: true,
				required: true,
				isMulti: true,
			},
		],
	},
}
