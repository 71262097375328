import prop from 'ramda/src/prop'
import pipe from 'ramda/src/pipe'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { viewStepsCompleted } = appStoreLenses

export default pipe(
	viewStepsCompleted,
	prop('hasDropEvent'),
)
