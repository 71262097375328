import { GET_DASHBOARD_DELIVERIES } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { clip } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { getDashboardPayloadSchema } from 'root/src/shared/dashboard/schemas/getDashboardPayload.schema';
const responseSchema = {
    type: 'object',
    properties: {
        data: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    projectTitle: { type: 'string' },
                    projectUrl: { type: 'string' },
                    videoURL: { type: 'string' },
                    viewCount: { type: 'integer' },
                    likeCount: { type: 'integer' },
                    commentCount: { type: 'integer' },
                    shareCount: { type: 'integer' },
                    thumbnail: { type: 'string' },
                    creatorDisplayName: { type: 'string' },
                },
            },
        },
        totals: {
            total: {
                type: 'integer',
            },
        },
        currentPage: {
            type: 'integer',
        },
        allPage: {
            type: 'integer',
        },
    },
    required: ['data', 'totals'],
};
export const getDashboardDeliveriesEndpoint = {
    [GET_DASHBOARD_DELIVERIES]: {
        endpointType: listEndpointType,
        recordType: clip,
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brandUser,
        payloadSchema: getDashboardPayloadSchema,
        responseSchema,
    },
};
