import { GET_PENDING_BRAND_USERS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { PENDING_BRAND_USERS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { pendingBrandUser } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { pendingBrandListType } from 'root/src/shared/constants/moduleTypes'

export default {
	[PENDING_BRAND_USERS_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: pendingBrandListType,
		recordType: pendingBrandUser,
		endpointId: [GET_PENDING_BRAND_USERS],
	},
}
