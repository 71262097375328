/* eslint-disable no-useless-escape */
const parseCharInsensitive = char => `[${char.toLowerCase()}|${char.toUpperCase()}]`
const parseWordInsensitive = word => `(${word.split('').map(parseCharInsensitive).join('')})`

const beginning = '^'
const protocol = `(?:(${parseWordInsensitive('http')}[s|S]?):\/\/)?`
const www = `(${parseWordInsensitive('www')}.)?`
const comInsensitive = parseWordInsensitive('com')
const emptyRe = '(^$)'
const start = `${beginning}${protocol}${www}`
const ending = '[a-zA-Z0-9_]{3,}'

const createUrlReBeginning = url => `${emptyRe}|${start}${parseWordInsensitive(url)}`

export default {
	type: 'object',
	acceptEmptyString: true,
	properties: {
		image: {
			type: ['object', 'string'],
			properties: {
				url: { type: 'string' },
				x: { type: 'number' },
				y: { type: 'number' },
				zoom: { type: 'number' },
				xPixels: { type: 'number' },
				yPixels: { type: 'number' },
				height: { type: 'number' },
				width: { type: 'number' },
			},
		},
		description: {
			type: 'string',
		},
		youtube: {
			type: 'string',
			pattern: `${createUrlReBeginning('youtube')}\.${comInsensitive}/(${parseWordInsensitive('channel')}\/|${parseWordInsensitive('user')}\/)${ending}`,
			errorMessage: {
				pattern: 'URL must be from YouTube',
			},
		},
		facebook: {
			type: 'string',
			pattern: `${createUrlReBeginning('facebook')}\.${comInsensitive}\/(?:(?:\w)*#!\/)?(?:${parseWordInsensitive('pages')}\/)?([\w\-]*)?`,
			errorMessage: {
				pattern: 'URL must be from Facebook',
			},
		},
		instagram: {
			type: 'string',
			pattern: `${createUrlReBeginning('instagram')}\.${comInsensitive}/${ending}`,
			errorMessage: {
				pattern: 'URL must be from Instagram',
			},
		},
		twitter: {
			type: 'string',
			pattern: `${createUrlReBeginning('twitter')}\.${comInsensitive}/${ending}`,
			errorMessage: {
				pattern: 'URL must be from Twitter',
			},
		},
		discord: {
			type: 'string',
			pattern: `${createUrlReBeginning('discord')}\.(?:${comInsensitive}|${parseWordInsensitive('gg')})/${ending}`,
			errorMessage: {
				pattern: 'URL must be from Discord',
			},
		},
		vkontakte: {
			type: 'string',
			pattern: `${createUrlReBeginning('vk')}\.${comInsensitive}/${ending}`,
			errorMessage: {
				pattern: 'URL must be from Vkontakte',
			},
		},
		tiktok: {
			type: 'string',
			pattern: `${createUrlReBeginning('tiktok')}\.${comInsensitive}/@${ending}`,
			errorMessage: {
				pattern: 'URL must be from TikTok',
			},
		},
		other: {
			type: 'string',
		},
		completedWidget: {
			type: 'boolean',
		},
		introProjectCreated: {
			type: 'boolean',
		},
	},
	required: [],
	additionalProperties: false,
}
