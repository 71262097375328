import assoc from 'ramda/src/assoc'
import compose from 'ramda/src/compose'
import path from 'ramda/src/path'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import moduleListPayloadSelector from 'root/src/client/logic/api/selectors/moduleListPayloadSelector'
import clearListProcessing from 'root/src/client/logic/api/actions/clearListProcessing'
import clearList from 'root/src/client/logic/api/actions/clearList'
import setFirstPage from 'root/src/client/logic/list/actions/setFirstPage'
import sortFilterModuleSelector from 'root/src/client/logic/api/selectors/sortFilterModuleSelector'
import fixedFilterSelector from 'root/src/client/logic/api/selectors/fixedFilterSelector'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import addSortFilterParams from 'root/src/client/logic/header/actions/addSortFilterParams'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import processFixedFilter from 'root/src/client/logic/sortFilter/util/processFixedFilter'
import moduleListPayloadMapSelector from 'root/src/client/logic/api/selectors/moduleListPayloadMapSelector'
import subObj from 'root/src/shared/util/subObj'
import { sectionKey, subSectionKey } from 'root/src/shared/constants/keys'
import allFieldsValuesSelector from 'root/src/client/logic/embedded/selectors/allFieldsValuesSelector'
import getRecordIdMod from 'root/src/client/logic/route/util/getRecordIdMod'
import { mapP } from 'root/src/shared/util/ramdaPlus'
import hideEmptyTabsSelector from 'root/src/client/logic/tab/selectors/hideEmptyTabsSelector'
import initialListPaginationSetup from 'root/src/client/logic/list/thunks/initialListPaginationSetup'
import onEnterThunkSelector from 'root/src/client/logic/api/selectors/onEnterThunkSelector'
import onEnterActionsSelector from 'root/src/client/logic/api/selectors/onEnterActionsSelector'
import moduleDescriptions from 'root/src/shared/descriptions/modules'

export default ({ moduleId, nextRouteObj }, state) => async (dispatch) => {
	if (hideEmptyTabsSelector(state, { moduleId })) return undefined

	const endpointId = moduleEndpointIdSelector(state, { moduleId })

	const onEnterThunk = onEnterThunkSelector(state, { moduleId })
	const onEnterActions = onEnterActionsSelector(state, { moduleId })
	if (onEnterThunk && onEnterActions) {
		const actions = typeof onEnterActions === 'function'
			? onEnterActions(state)
			: onEnterActions
		dispatch(onEnterThunk(actions))
	}

	dispatch(setFirstPage(moduleId))
	let payload = moduleListPayloadSelector(state, { moduleId })

	const payloadItems = moduleListPayloadMapSelector({}, { moduleId })
	if (payloadItems) {
		const { routeId, routeParams } = nextRouteObj
		const { recordId } = routeParams
		const payloadSubs = {
			recordId: getRecordIdMod({ routeId, recordId }),
		}
		payload = { ...payload, ...subObj(payloadSubs, payloadItems) }
	}
	dispatch(clearListProcessing(currentRouteObj(state)))
	dispatch(clearList())
	dispatch(initialListPaginationSetup(moduleId))

	const sortFilterModule = sortFilterModuleSelector(moduleId)
	if (sortFilterModule) {
		const { sortFilter } = state
		const fieldsValues = allFieldsValuesSelector(state, { moduleId: sortFilterModule })

		payload = compose(
			assoc('filter', path([sortFilterModule, 'filterParams'], sortFilter)),
			assoc('sortType', path(['sort', 'value'], fieldsValues)),
			assoc(sectionKey, path(['tab', 'fixedPayload', sectionKey], fieldsValues)
				|| path([sectionKey, 'payload', sectionKey], fieldsValues)),
			assoc(subSectionKey, path(['tab', 'fixedPayload', subSectionKey], fieldsValues)
				|| path([sectionKey, 'payload', subSectionKey], fieldsValues)),
		)(payload)
	}

	const fixedFilter = fixedFilterSelector({}, { moduleId })
	if (fixedFilter) {
		const currentRouteRecordId = currentRouteParamsRecordId(state)
		const filter = processFixedFilter(fixedFilter, currentRouteRecordId)
		payload = assoc('filter', filter, payload)
		dispatch(addSortFilterParams(sortFilterModule || moduleId, payload))
	}
	if (typeof endpointId === 'string') {
		return [await dispatch(apiRequest(endpointId, payload, false, moduleId))]
	}

	return mapP(endpoint => dispatch(apiRequest(
		endpoint, payload, false, moduleId, moduleDescriptions,
	)), endpointId)
}
