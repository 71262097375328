import {
	GET_PENDING_DELIVERIES,
} from 'root/src/shared/descriptions/endpoints/endpointIds'

import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/projectResponseSchema'
import { projectKey } from 'root/src/shared/constants/keys'

export const responseSchema = projectResponseSchema

export default {
	[GET_PENDING_DELIVERIES]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: listEndpointType,
		recordType: projectKey,
		responseSchema,
	},
}
