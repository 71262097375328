import {	GET_ALERTS_URL } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { alertsUrl } from 'root/src/shared/descriptions/endpoints/recordTypes'

export const payloadSchema = emptyPayloadSchema

export default {
	[GET_ALERTS_URL]: {
		authentication: authenticationTypes.authenticated,
		endpointType: userDataEndpointType,
		recordType: alertsUrl,
		payloadSchema,
	},
}
