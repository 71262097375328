var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { forwardRef, memo } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { twMerge } from 'tailwind-merge';
export const ModalLayout = memo(forwardRef((_a, ref) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (<div className={twMerge('flex relative motion-safe:animate-pop-up w-[95vw] sm:w-auto flex-col h-fit m-auto self-center overflow-auto justify-center items-center py-10 sm:px-20 px-5 rounded-lg border-4 border-dark-1 bg-dark-3 gap-4', className)} ref={ref} {...props}>
			<CloseModal />
			{children}
		</div>);
}));
const CloseModal = memo(() => {
    const { hide, options } = useModal();
    if (!options.isCloseable)
        return null;
    return (<button title="Close modal" type="button" onClick={hide} className="absolute right-0 top-0 p-4 cursor-pointer">
			<CloseIcon />
		</button>);
});
