import outputs from 'root/cfOutput';
const { domainName } = outputs;
const getDomainCookieString = () => {
    // handling for localhost
    if (process.env.NODE_ENV === 'development') {
        return '';
    }
    // handling for subdomains
    const hostnameParts = domainName.split('.');
    return hostnameParts.length > 1 ? `; domain=.${hostnameParts.slice(-2).join('.')}` : '';
};
export const buildCookie = (name, value, exp) => {
    const cookieValue = typeof value === 'string' ? value : JSON.stringify(value);
    const expires = exp ? `; expires=${new Date(Number(exp) * 1000).toUTCString()}` : '';
    const domain = getDomainCookieString();
    const path = '; path=/';
    return `${name}=${cookieValue}${expires}${domain}${path};`;
};
export const setCookie = (name, value, exp) => {
    document.cookie = buildCookie(name, value, exp);
};
export const getCookies = (cookieString) => {
    let cookie = (global.window ? document.cookie : cookieString)
        || cookieString;
    if (cookie === '')
        return {};
    if (!cookie)
        return undefined;
    try {
        // Note: Weglot proxy encodes all cookies as urlEncoded string
        cookie = decodeURIComponent(cookie);
    }
    catch (e) {
        console.error('Could not decode cookie string:', e);
    }
    return Object.fromEntries(cookie.split('; ').map(str => str.split('=')));
};
export const getCookie = (name) => {
    if (!global.window) {
        return undefined;
    }
    const cookies = getCookies();
    return cookies === null || cookies === void 0 ? void 0 : cookies[name];
};
