import reduxConnector from 'root/src/shared/util/reduxConnector'

import currentYearSelector from 'root/src/client/logic/app/selectors/currentYearSelector'
import newFooterDataSelector from 'root/src/client/logic/app/selectors/newFooterDataSelector'
import isMobileResSelector from 'root/src/client/logic/app/selectors/isMobileResSelector'

export default reduxConnector(
	[
		['currentYear', currentYearSelector],
		['footerData', newFooterDataSelector],
		['isMobileRes', isMobileResSelector],
	],
	[],
)
