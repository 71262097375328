import mediaMimeTypes from 'root/src/shared/constants/mediaMimeTypes'
import test from 'ramda/src/test'
import pipe from 'ramda/src/pipe'
import { splitAndGetHead, splitAndGetLast } from 'root/src/shared/util/ramdaPlus'

export const getFileExtension = fileName => pipe(
	splitAndGetHead('?'),
	splitAndGetLast('.'),
)(fileName || '')

export const isVideoType = (media) => {
	const ext = getFileExtension(media)
	const type = mediaMimeTypes[ext]
	return test(/video\/mp4/g, type || media)
}

const youtubeRe = /((http:\/\/|https:\/\/|)((www|m).|)((youtube.com|youtu.be))(\/watch\?v=)(.*))/gi

export const isYoutubeVideo = media => test(youtubeRe, media)
