import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'

import { variableSchemaKey } from 'root/src/shared/constants/keys'

const sortFilterSchema = {
	type: 'object',
	properties: {
		sortFilter: {
			type: 'object',
			patternProperties: {
				[variableSchemaKey]: {
					type: 'object',
					properties: {
						filterParams: {
							type: 'array',
							items: {
								type: 'object',
								properties: {
									param: { type: 'string' },
									value: { type: 'string' },
								},
							},
						},
						sortValue: {
							type: 'object',
							properties: {
								label: { type: 'string' },
								id: { type: 'string' },
								value: { type: 'string' },
							},
						},
						sortType: { type: 'string' },
						section: { type: 'string' },
						subSection: { type: 'string' },
					},
				},
			},
		},
	},
}

// eslint-disable-next-line import/prefer-default-export
export const sortFilterStoreLenses = lensesFromSchema(sortFilterSchema)
