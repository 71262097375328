/* eslint-disable import/prefer-default-export */
import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'

import { variableSchemaKey } from 'root/src/shared/constants/keys'

const tabModuleLenses = {
	type: 'object',
	properties: {
		tabs: {
			type: 'object',
			patternProperties: {
				[variableSchemaKey]: {
					type: 'object',
					properties: {
						fixedFilters: {
							type: 'object',
							items: {
								type: 'object',
								properties: {
									params: 'string',
									value: 'string',
								},
							},
						},
						fixedPayload: {
							type: 'object',
						},
						endpointId: {
							type: 'string',
						},
					},
				},
			},
		},
	},
}

export const tabDescriptionLenses = lensesFromSchema(tabModuleLenses)
