import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { boxShadow, colorsNew } from 'root/src/client/web/commonStyles'

const style = {
	root: {
		width: '100%',
		position: 'relative',
		height: 0,
		borderRadius: 10,
		border: '2px solid white',
		boxShadow: boxShadow(colorsNew.primary),
		transitionDuration: '.15s',
		transitionTimingFunction: 'ease-out',
		background: 'white',
	},
	secondary: {
		borderColor: colorsNew.primary,
		boxShadow: 'none',
		background: colorsNew.primary,
	},
}

const HorizontalLine = ({ classes, dimmed }) => (
	<div
		className={classNames({
			[classes.root]: true,
			[classes.secondary]: dimmed,
		})}
	/>
)

export default withStyles(style, { name: 'MuiHorizontalLine' })(HorizontalLine)
