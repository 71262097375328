export const GamingPlatform = {
    STEAM: 'steam',
    SWITCH: 'switch',
    PS_4: 'ps4',
    PS_5: 'ps5',
    IOS: 'ios',
    ANDROID: 'android',
    PLAYSTATION_VR: 'playstationVR',
    OCULUS_QUEST: 'oculusQuest',
    OCULUS_RIFT: 'oculusRift',
    EPIC_GAMES_STORE: 'epicGamesStore',
    XBOX: 'xbox',
    XBOX_360: 'xbox360',
    XBOX_ONE: 'xboxOne',
    GOG: 'gog',
    INDEX: 'index',
    ITCH_IO: 'itch.io',
    VIVE: 'vive',
};
export const RewardType = {
    KEY: 'dropKey',
    DELIVERED_KEY: 'dropDeliveredKey',
    CODE: 'dropCode',
    DELIVERED_CODE: 'dropDeliveredCode',
    CUSTOM: 'customProduct',
    GIFTED_KEY: 'giftedKey',
    GIFTED_CODE: 'giftedCode',
    PLACEHOLDER: 'placeholder',
    BUNDLE: 'bundle',
    DELIVERED_BUNDLE: 'deliveredBundle',
    INITIAL_PRODUCT: 'initialProduct',
};
export const KeyType = {
    BETA: 'betaKey',
    EARLY: 'earlyKey',
    COMPLETE_EDITION: 'completeEditionKey',
    RETAIL: 'retailKey',
    SOUNDTRACK: 'soundtrackKey',
    SEASON_PASS: 'seasonPassKey',
    SPECIAL: 'specialKey',
    DLC: 'dlc',
    DEMO: 'demoKey',
};
export const KeyRegion = {
    WORLDWIDE: 'worldwide',
    NORTH_AMERICA: 'northAmerica',
    EUROPE: 'europe',
    CHINA: 'china',
    ASIA: 'asia',
    RUSSIA: 'russia',
    SOUTH_AMERICA: 'southAmerica',
    OCEANIA: 'oceania',
    MIDDLE_EAST: 'middleEast',
    AFRICA: 'africa',
    CENTRAL_AMERICA: 'centralAmerica',
    JAPAN: 'japan',
    AMERICAS: 'americas',
    EUROPE_AND_AMERICAS: 'europeAndAmericas',
    SIEA: 'northAmerica/SouthAmerica',
    SIEE: 'europe/Africa/MiddleEast/Oceania',
    NORTH_AMERICAN: 'northAmerican',
    LATIN_AMERICAN: 'latinAmerican',
    KOREA: 'korea',
    AUSTRALIA: 'australia',
    AUSTRIA: 'austria',
    BELGIUM: 'belgium',
    CANADA: 'canada',
    DENMARK: 'denmark',
    FINLAND: 'finland',
    FRANCE: 'france',
    GERMANY: 'germany',
    ICELAND: 'iceland',
    IRELAND: 'ireland',
    ITALY: 'italy',
    NETHERLANDS: 'netherlands',
    NEW_ZEALAND: 'newZealand',
    NORWAY: 'norway',
    POLAND: 'poland',
    SPAIN: 'spain',
    SWEDEN: 'sweden',
    SWITZERLAND: 'switzerland',
    TAIWAN: 'taiwan',
    GREAT_BRITAIN: 'greatBritain',
    UNITED_STATES: 'unitedStates',
};
export const RewardStatus = {
    AVAILABLE: 'available',
    USED: 'used',
    BUNDLED: 'bundled',
};
export const RewardEndpoint = {
    GET_REWARDS: 'GET_REWARDS',
    GET_CREATOR_INITIAL_REWARDS: 'GET_CREATOR_INITIAL_REWARDS',
    GET_BRAND_REWARDS: 'GET_BRAND_REWARDS',
    GET_BRAND_CUSTOM_REWARDS: 'GET_BRAND_CUSTOM_REWARDS',
    EDIT_BRAND_REWARDS: 'EDIT_BRAND_REWARDS',
    ADD_BRAND_CUSTOM_REWARD: 'ADD_BRAND_CUSTOM_REWARD',
    GET_REWARDS_FOR_CREATOR: 'GET_REWARDS_FOR_CREATOR',
    GET_REWARDS_FOR_VIEWERS: 'GET_REWARDS_FOR_VIEWERS',
    GET_DROP_KEY_REWARDS: 'GET_DROP_KEY_REWARDS',
    GET_REWARD: 'GET_REWARD',
    USE_REWARDS: 'USE_REWARDS',
    SAVE_CODE_REWARDS: 'SAVE_CODE_REWARDS',
    SAVE_KEY_REWARDS: 'SAVE_KEY_REWARDS',
    SET_DROP_DELIVERED_REWARDS_USED: 'SET_DROP_DELIVERED_REWARDS_USED',
    GET_DECRYPTED_REWARD: 'GET_DECRYPTED_REWARD',
    TRANSFER_REWARDS: 'TRANSFER_REWARDS',
    SAVE_REWARDS_BULK: 'SAVE_REWARDS_BULK',
    CREATE_BUNDLE: 'CREATE_BUNDLE',
    GET_BRAND_BUNDLES: 'GET_BRAND_BUNDLES',
};
export const UsedRewardMap = {
    [RewardType.KEY]: RewardType.DELIVERED_KEY,
    [RewardType.CODE]: RewardType.DELIVERED_CODE,
    [RewardType.BUNDLE]: RewardType.DELIVERED_BUNDLE,
};
export const readableRewardType = {
    [RewardType.KEY]: 'Key',
    [RewardType.CODE]: 'Code',
    [RewardType.CUSTOM]: 'Asset',
    [RewardType.BUNDLE]: 'Bundle',
};
export const BULK_UPLOAD_TEMPLATE_FILE_URL = 'https://docs.google.com/spreadsheets/d/1m9JgBhPRpYFQ958u0z6Lzhy8flMeFYsVAFJykezktZE/edit#gid=0';
