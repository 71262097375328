import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { userDataEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { giveawayTerms } from 'root/src/shared/descriptions/endpoints/recordTypes';
export const saveGiveawayTermsEndpoint = {
    [giveawayEndpointIds.SAVE_GIVEAWAY_TERMS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.viewer,
        endpointType: userDataEndpointType,
        recordType: giveawayTerms,
    },
};
