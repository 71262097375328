import split from 'ramda/src/split'
import { CREATOR_CAMPAIGN_DARES_LIST_MODULE } from 'root/src/shared/descriptions/modules/moduleIds'
import { dashboardTableListType } from 'root/src/shared/constants/moduleTypes'
import { GET_DASHBOARD_CREATOR_DARES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { idKey, projectTitleKey } from 'root/src/shared/constants/keys'
import onEnterActionCalls from 'root/src/client/logic/api/thunks/onEnterActionCalls'
import setModuleDataFilter from 'root/src/client/logic/api/actions/setModuleDataFilter'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

export default {
	[CREATOR_CAMPAIGN_DARES_LIST_MODULE]: {
		moduleType: 'list',
		listType: dashboardTableListType,
		pageItemsCount: 50,
		recordType: project,
		endpointId: [GET_DASHBOARD_CREATOR_DARES],
		listPayload: (state) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)
			const [campaignMnemonicId, creatorPlatformId] = split('-', recordId)
			return { campaignMnemonicId, creatorPlatformId, limit: 50 }
		},
		tableColumns: () => [
			{ label: 'Mock Column', id: 'mockData' },
		],
		dataIdentificator: idKey,
		recordDisplayProp: projectTitleKey,
		onEnterThunk: onEnterActionCalls,
		onEnterActions: [
			{
				action: setModuleDataFilter,
				payloads: [
					{
						moduleId: CREATOR_CAMPAIGN_DARES_LIST_MODULE,
						filter: 'timeRange',
						id: 'value',
						value: 'ALL',
					},
				],
			},
		],
	},
}
