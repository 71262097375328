import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import { MANAGE_PUBLISHER_ACCOUNT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import updatePublisherRecord from 'root/src/client/logic/agency/actions/updatePublisherRecord'

export default ({ id } = {}) => async (dispatch, getState) => {
	if (!id) return
	const state = getState()
	const { body, statusCode } = await invokeApiRequest(MANAGE_PUBLISHER_ACCOUNT, { id }, state)
	const success = statusCode === 200
	if (!success) return

	dispatch(updatePublisherRecord(body))
}
