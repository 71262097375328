import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { recordEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { giveaway, } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { GiveawayProjection } from '@daredrop-com/rewards-api-contract';
const getUserGiveawaysPayloadSchema = {
    type: 'object',
    properties: {
        userId: {
            type: 'string',
        },
        mnemonicId: {
            type: 'string',
        },
        limit: {
            type: 'integer',
        },
        offset: {
            type: 'integer',
        },
        projection: {
            type: 'string',
            enum: [
                GiveawayProjection.Creator,
                GiveawayProjection.Viewer,
            ],
        },
    },
};
export const getUserGiveawaysEndpoint = {
    [giveawayEndpointIds.GET_USER_GIVEAWAYS]: {
        authentication: authenticationTypes.optional,
        recordType: giveaway,
        endpointType: recordEndpointType,
        payloadSchema: getUserGiveawaysPayloadSchema,
    },
};
