import { useModal } from 'root/src/client/v2/common/contexts/modal.context';
import { GetFreeKeysModal } from 'root/src/client/v2/creatorOnboarding/features/GetFreeKeysModal';
import { useApplicationState } from 'root/src/client/v2/userData/hooks/useApplicationState';
import { useGetStartedButton, } from 'root/src/client/v2/creatorOnboarding/features/CreatorOnboardingButtons/hooks/useGetStartedButton';
export function useGetFreeKeysButton() {
    const modal = useModal();
    const { displayGetStartedButton } = useGetStartedButton();
    const showGetFreeKeysModal = () => modal.show(<GetFreeKeysModal />);
    const { applicationState } = useApplicationState();
    const displayGetFreeKeysButton = !displayGetStartedButton && (applicationState === null || applicationState === void 0 ? void 0 : applicationState.isDisplayGetFreeKeysMessage);
    return {
        showGetFreeKeysModal,
        displayGetFreeKeysButton,
    };
}
