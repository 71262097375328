import { PAYOUT_DELAYED_PAYOUTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import emptyPayloadSchema	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'

export const payloadSchema = emptyPayloadSchema

export default {
	[PAYOUT_DELAYED_PAYOUTS]: {
		endpointType: recordEndpointType,
		recordType: project,
		payloadSchema,
	},
}
