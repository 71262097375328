import { GET_CAMPAIGN_STREAMS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import getCampaignStreamsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getCampaignStreamsResponseSchema'
import campaignIdPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/campaignIdPayloadSchema'

export default {
	[GET_CAMPAIGN_STREAMS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: gatewayEndpointType,
		responseSchema: getCampaignStreamsResponseSchema,
		payloadSchema: campaignIdPayloadSchema,
	},
}
