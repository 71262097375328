import { AGENCY_PUBLISHERS_SELECT_MISC_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants'

export default {
	[AGENCY_PUBLISHERS_SELECT_MISC_MODULE_ID]: {
		moduleType: 'misc',
		miscModuleType: 'agencyPublishersSelect',
		endpointId: [agencyEndpointIds.GET_AGENCY_PUBLISHERS],
	},
}
