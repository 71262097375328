import { LAMBDA_HEALTH_CHECK } from 'root/src/shared/descriptions/endpoints/endpointIds'

import emptyPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'

export default {
	[LAMBDA_HEALTH_CHECK]: {
		payloadSchema: emptyPayloadSchema,
	},
}
