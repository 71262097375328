export default {
	type: 'array',
	items: {
		additionalProperties: false,
		properties: {
			index: { type: 'number' },
			value: { type: 'number' },
			platformId: { type: 'string' },
			id: { type: 'string' },
		},
	},
}
