export const verificationStates = {
    /**
     * Verification is pending
     */
    VERIFICATION_PENDING: 'verificationPending',
    /**
     * Verified successfully
     */
    VERIFICATION_VERIFIED: 'verificationVerified',
    /**
     * Verification rejected
     */
    VERIFICATION_REJECTED: 'verificationRejected',
};
export var VerificationState;
(function (VerificationState) {
    VerificationState["VERIFICATION_PENDING"] = "verificationPending";
    VerificationState["VERIFICATION_VERIFIED"] = "verificationVerified";
    VerificationState["VERIFICATION_REJECTED"] = "verificationRejected";
})(VerificationState || (VerificationState = {}));
