import { GET_ACTIVE_GIVEAWAY } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import getActiveGiveawayPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getActiveGiveawayPayloadSchema'

export default {
	[GET_ACTIVE_GIVEAWAY]: {
		payloadSchema: getActiveGiveawayPayloadSchema,
		endpointType: otherEndpointType,
	},
}
