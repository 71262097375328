import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { otherEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
const enterGiveawayPayloadSchema = {
    type: 'object',
    properties: {
        giveawayId: {
            type: 'string',
        },
    },
    required: ['giveawayId'],
    additionalProperties: true,
};
export const enterGiveawayEndpoint = {
    [giveawayEndpointIds.ENTER_GIVEAWAY]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.viewer,
        endpointType: otherEndpointType,
        payloadSchema: enterGiveawayPayloadSchema,
    },
};
