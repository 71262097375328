import { GET_TWITCH_USER_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import getTwitchUserDataResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getTwitchUserDataResponseSchema'
import getTwitchUserDataPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getTwitchUserDataPayloadSchema'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[GET_TWITCH_USER_DATA]: {
		authentication: authenticationTypes.unauthenticated,
		endpointType: otherEndpointType,
		payloadSchema: getTwitchUserDataPayloadSchema,
		responseSchema: getTwitchUserDataResponseSchema,
	},
}
