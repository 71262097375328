export default function (eventName, description, quantity = 1) {
	/* eslint-disable no-undef */
	if (!TIKTOK_PIXEL_ID) return
	if (typeof ttq !== 'undefined') {
		/* eslint-disable no-undef */
		ttq.track(eventName, {
			quantity,
			description,
		})
	}
}
