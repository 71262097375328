import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import React, { memo } from 'react'

const styles = {
	overlay: {
		display: 'none',
		position: 'fixed',
		overflow: 'visible',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
	overlayOpen: {
		display: 'block',
		zIndex: 1500,
	},
}

const Overlay = ({ classes, open, setOpenFn, onClose }) => (
	<div
		onClick={(e) => {
			e.preventDefault()
			onClose()
			setOpenFn(false)
		}}
		className={classNames(classes.overlay, { [classes.overlayOpen]: open })}
	/>
)

export default withStyles(styles, { name: 'MuiOverlay' })(memo(Overlay))
