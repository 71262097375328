import {
	otherEndpointType,
} from 'root/src/shared/descriptions/endpoints/lenses'
import { SIGN_UP_TOKEN } from 'root/src/shared/descriptions/endpoints/endpointIds'

export default {
	[SIGN_UP_TOKEN]: {
		endpointType: otherEndpointType,
	},
}
