var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCookie } from 'root/src/client/v2/browser/helpers/cookies';
import outputs from 'root/cfOutput';
import { fetcher, HTTPMethod } from 'root/src/client/v2/common/helpers/fetcher';
const { domainName } = outputs;
export function dareDropClient(config) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const res = yield fetcher({
            url: `https://api.${domainName}`,
            body: JSON.stringify({
                payload: config.payload,
                endpointId: config.endpointId,
                authentication: (_a = config === null || config === void 0 ? void 0 : config.authentication) !== null && _a !== void 0 ? _a : getCookie('accessToken'),
            }),
            method: HTTPMethod.POST,
        });
        const { error, statusCode, body } = res;
        if (statusCode < 200 || statusCode >= 300) {
            throw new Error(typeof error === 'string' ? error : error.message, { cause: error });
        }
        return body;
    });
}
