import reduxConnector from 'root/src/shared/util/reduxConnector'

import setDeviceId from 'root/src/client/logic/app/thunks/setDeviceId'
import setExperimentVariant from 'root/src/client/logic/app/thunks/setExperimentVariant'
import deviceIdSelector from 'root/src/client/logic/app/selectors/deviceIdSelector'

export default reduxConnector(
	[
		['deviceId', deviceIdSelector],
	],
	[
		['setDeviceId', setDeviceId],
		['setExperimentVariant', setExperimentVariant],
	],
)
