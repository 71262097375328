import { REQUEST_DROP_EVENT_INVOICE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[REQUEST_DROP_EVENT_INVOICE]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: otherEndpointType,
	},
}
