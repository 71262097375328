export default {
	type: 'object',
	properties: {
		itemName: { type: 'string' },
		cost: { type: 'number' },
		itemDescription: { type: 'string' },
		quantity: { type: 'number' },
		image: { type: 'string' },
		id: { type: 'string' },
		category: { type: 'string' },
		deleteItems: {
			type: 'array',
			items: {
				type: 'string',
			},
		},
	},
	required: [],
	additionalProperties: false,
}
