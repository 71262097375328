import path from 'ramda/src/path'
import values from 'ramda/src/values'
import { RewardEndpoint } from 'root/src/shared/rewards/constants'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'

export default moduleRecordType => async (dispatch, getState) => {
	const state = getState()
	const records = values(path(['api', 'records', moduleRecordType], state))
	await invokeApiRequest(RewardEndpoint.SET_DROP_DELIVERED_REWARDS_USED, { records }, state)
}
