export const getRewardResponseSchema = {
	type: 'object',
	properties: {
		id: { type: 'string' },
		available: { type: 'number' },
		type: { type: 'string' },
		brandId: { type: 'string' },
	},
	required: ['id', 'available', 'type', 'brandId'],
	additionalProperties: false,
}
