export const emptyRe = '(^$)'
/* eslint-disable no-useless-escape */
export const youtubeUrlPattern = '^(http(s)?:\/\/)?(w{3}\.)?youtu(\.be|be\.com)\/.+'
export const urlPattern = '(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-z]{2,11}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)'
export const imgExtensions = ['png', 'jpg', 'jpeg', 'svg']

export const budgetOptions = {
	max: 100000,
	min: 500,
}

export default {
	type: 'object',
	acceptEmptyString: true,
	properties: {
		campaignName: {
			type: 'string',
			isNotEmpty: true,
			errorMessage: {
				required: 'Campaign Title is required',
				isNotEmpty: 'Campaign Title is required',
			},
		},
		gameObj: {
			type: 'object',
			items: {
				type: 'object',
				properties: {
					id: { type: 'integer' },
					label: { type: 'string' },
					value: { type: 'integer' },
					image: { type: 'string' },
				},
				required: ['id'],
			},
			errorMessage: {
				type: 'Game is required',
				required: 'Game is required',
			},
		},
		liveDate: {
			type: 'string',
		},
		messageToCreator: {
			type: 'string',
		},
		initialProduct: {
			type: ['array', 'null'],
		},
		similarGames: {
			type: 'array',
			minItems: 0,
			maxItems: 10,
			uniqueItems: true,
			items: {
				type: 'object',
				properties: {
					id: { type: 'integer' },
				},
				required: ['id'],
			},
		},
		assets: {
			type: 'array',
			minItems: 0,
			maxItems: 10,
		},
		youtubeURL: {
			type: 'string',
			pattern: `${emptyRe}|${youtubeUrlPattern}`,
			errorMessage: {
				pattern: 'URL must be from YouTube',
			},
		},
		gameDescriptionBody: {
			type: 'string',
		},
		cta: {
			type: 'string',
			maxLength: 25,
			errorMessage: {
				required: 'CTA is required',
				maxLength: 'max: 25 characters',
			},
		},
		isGameFree: {
			type: 'boolean',
		},
		url: {
			type: 'object',
			properites: {
				value: {
					type: 'string',
					pattern: `${emptyRe}|${urlPattern}`,
					maxLength: 150,
					errorMessage: {
						pattern: 'Must be proper url',
						maxLength: 'max: 150 characters',
					},
				},
				custom: 'boolean',
			},

		},
		dares: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					description: { type: 'string', maxLength: 110 },
				},
			},
		},
		budget: {
			type: 'object',
			properties: {
				amount: {
					type: 'number',
					minimum: budgetOptions.min,
					maximum: budgetOptions.max,
				},
			},
		},
		campaignThumbnail: {
			type: ['string', 'object'],
		},
		campaignProduct: {
			type: 'string',
		},
	},
	required: [
		'campaignName', 'liveDate', // 'similarGames', TODO *drop* ?
		'assets', 'gameDescriptionBody', 'dares', 'cta', 'url',
	],
	additionalProperties: true,
}
