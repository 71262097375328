import lensPath from 'ramda/src/lensPath'
import path from 'ramda/src/path'
import set from 'ramda/src/set'
import pipe from 'ramda/src/pipe'
import values from 'ramda/src/values'
import unnest from 'ramda/src/unnest'
import pluck from 'ramda/src/pluck'
import map from 'ramda/src/map'
import reduce from 'ramda/src/reduce'
import over from 'ramda/src/over'
import mergeLeft from 'ramda/src/mergeLeft'
import anchors from 'root/src/shared/constants/anchors'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import moduleIdFromKey from 'root/src/client/logic/route/util/moduleIdFromKey'

import { PRE_POPULATE_FORM } from 'root/src/client/logic/form/actionIds'

export default {
	[PRE_POPULATE_FORM]: (state, props) => {
		const { moduleKey, formData, scrollTop, overwrite = true } = props
		const moduleId = moduleIdFromKey(moduleKey)

		if (scrollTop) {
			window.scrollTo(anchors.TOP, anchors.TOP)
		}
		const fieldSectionsToggles = path(
			[moduleId, 'fieldSectionsToggles'],
			moduleDescriptions,
		)

		const modifiedState = overwrite
			? set(lensPath(['form', moduleKey, 'formInputs']), formData)(state)
			: over(lensPath(['form', moduleKey, 'formInputs']), mergeLeft(formData))(state)

		const togglesValues = pipe(
			values,
			unnest,
			pluck('prePopulateValue'),
			map(fn => fn(modifiedState, moduleKey, formData)),
			reduce((acc, val) => ({
				...acc,
				...val,
			}), {}),
		)(fieldSectionsToggles)

		return pipe(
			set(lensPath(['form', moduleKey, 'formToggles']), togglesValues),
		)(modifiedState)
	},
}
