import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'
import { variableSchemaKey } from 'root/src/shared/constants/keys'

const endpointDescriptionSchema = {
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: { // endpointId
			type: 'object',
			properties: {
				authentication: { type: 'string' },
				authenticationRole: { type: 'string' },
				endpointType: { type: 'string', enum: ['list', 'record', 'userData'] },
				recordType: {
					type: 'string',
					enum: [
						'project',
						'projectList',
						'paymentMethod',
						'payoutMethod',
					],
				},
				tokenKey: { type: 'string' },
				storeKey: { type: 'string' },
			},
		},
	},
}

export const endpointDescriptionLenses = lensesFromSchema(
	endpointDescriptionSchema,
)

export const listEndpointType = 'list'
export const recordEndpointType = 'record'
export const userDataEndpointType = 'userData'
export const gatewayEndpointType = 'gateway'
export const otherEndpointType = 'other'
