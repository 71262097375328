import { TEST_ALERT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[TEST_ALERT]: {
		authentication: authenticationTypes.authenticated,
		endpointType: userDataEndpointType,
	},
}
