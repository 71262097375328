import dissocPath from 'ramda/src/dissocPath'
import prop from 'ramda/src/prop'
import compose from 'ramda/src/compose'
import keys from 'ramda/src/keys'
import reduce from 'ramda/src/reduce'
import { CLEAR_ALL_FORMS } from 'root/src/client/logic/form/actionIds'
import moduleIdFromKey from 'root/src/client/logic/route/util/moduleIdFromKey'
import moduleDescriptions from 'root/src/shared/descriptions/modules'

export const reducer = (state) => {
	const formsInState = compose(keys, prop('form'))(state)
	return reduce((acc, item) => {
		const moduleId = moduleIdFromKey(item)
		if (!moduleId) {
			return acc
		}
		const clear = prop('clear', moduleDescriptions[moduleId])
		return clear ? dissocPath(['form', item], acc) : acc
	}, state, formsInState)
}

export default {
	[CLEAR_ALL_FORMS]: reducer,
}
