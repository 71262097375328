import {	GET_USER_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import userDataResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/userDataResponseSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export const responseSchema = userDataResponseSchema

export default {
	[GET_USER_DATA]: {
		endpointType: userDataEndpointType,
		payloadSchema: {},
		responseSchema,
		authentication: authenticationTypes.authenticated,
	},
}
