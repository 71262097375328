export default {
	type: 'object',
	properties: {
		mnemonicId: {	type: 'string' },
		items: {
			type: 'array',
			items: {
				properties: {
					title: { type: 'string' },
					totalPledge: { type: 'number' },
					projectId: { type: 'string' },
					description: { type: 'string' },
					assignees: {
						type: 'array',
						items: {
							properties: {
								name: { type: 'string' },
								image: { type: 'string' },
							},
						},
					},
					brandProduct: {
						type: 'object',
						properties: {
							productName: { type: 'string' },
							url: { type: 'string' },
							cta: { type: 'string' },
						},
					},
					sponsor: {
						type: 'object',
						properties: {
							brandName: { type: 'string' },
							brandWebsite: { type: 'string' },
							brandLogo: { type: 'object' },
						},
					},
					goal: { type: 'number' },
					favoritesAmount: { type: 'number' },
					myFavorites: { type: 'integer' },
					created: { type: 'string' },
					topPledgers: {
						type: 'array',
						items: {
							properties: {
								name: { type: 'string' },
								pledgeAmount: { type: 'number' },
							},
						},
					},
				},
			},
			required: [
				'title', 'totalPledge', 'projectId', 'description',
				'assignees', 'goal', 'created', 'topPledgers',
			],
			additionalProperties: false,
		},
	},
	required: ['items'],
	additionalProperties: true,
}
