import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'
import {
	alertsConfigKey, tokensKey, variableSchemaKey, profileKey, alertsUrlKey,
	creatorDataKey, accountDataKey, customSoundsKey, obsTopDaresConfigKey,
	gamePlatformsKey, notificationSettingsKey, termsAcceptedCampaignsKey,
	creatorPointsKey, dropEventsKey,
} from 'root/src/shared/constants/keys'
import { getPlatformsScheme } from 'root/src/shared/util/getPlatformsScheme'
import { giveawayTerms } from 'root/src/shared/descriptions/endpoints/recordTypes'

const appSchema = {
	type: 'object',
	properties: {
		app: {
			type: 'object',
			properties: {
				isTraySlidedOut: { type: 'boolean' },
				authentication: {
					type: 'object',
					properties: {
						accessToken: { type: 'string' },
						groups: { type: 'string' },
						email: { type: 'string' },
						refreshToken: { type: 'string' },
						userId: { type: 'string' },
						parentUserId: { type: 'string' },
					},
				},
				userData: {
					type: 'object',
					patternProperties: {
						[alertsConfigKey]: {
							type: 'object',
							properties: {
								pledgeSounds: 'array',
								dareSounds: 'array',
								soundVolume: 'string',
							},
						},
						[obsTopDaresConfigKey]: {
							type: 'object',
							properties: {
								flairColor: 'string',
								overlayText: 'string',
							},
						},
						[alertsUrlKey]: { type: 'string' },
						[tokensKey]: {
							type: 'array',
							items: {
								type: 'object',
								properties: {
									mnemonicId: { type: 'string' },
									displayName: { type: 'string' },
								},
							},
						},
						[accountDataKey]: {
							type: 'object',
							properties: {
								obsExtensionAdded: { type: 'boolean' },
								brandName: { type: 'string' },
								isConfirmEmailPromptEnabled: { type: 'boolean' },
							},
						},
						[profileKey]: {
							type: 'object',
						},
						[creatorDataKey]: {
							type: 'object',
							properties: {
								tier: { type: 'string' },
								followerCount: { type: 'number' },
								ccv: { type: 'number' },
								creatorCampaignPointsLimit: { type: 'number' },
								platformId: { type: 'string' },
								platform: { type: 'string' },
								creatorGamePlatforms: { type: 'array' },
								[creatorPointsKey]: { type: 'number' },
								...getPlatformsScheme(),
							},
						},
						[variableSchemaKey]: {
							type: 'object',
							properties: {},
						},
						[customSoundsKey]: {
							type: 'object',
							patternProperties: {
								[variableSchemaKey]: {
									type: 'object',
									properties: {
										loadingBlockVisible: 'boolean',
									},
								},
							},
						},
						[gamePlatformsKey]: {
							type: 'array',
						},
						[notificationSettingsKey]: {
							type: 'object',
							properties: {
								emailAlert: { type: 'string' },
							},
						},
						[termsAcceptedCampaignsKey]: {
							type: 'array',
							items: {
								type: 'string',
							},
						},
						[dropEventsKey]: {
							type: 'array',
							items: {
								type: 'object',
							},
						},
					},
				},
				mediaBreakpoints: {
					type: 'object',
					properties: {
						xs: { type: 'boolean' },
						gtXs: { type: 'boolean' },
						sm: { type: 'boolean' },
						gtSm: { type: 'boolean' },
						md: { type: 'boolean' },
						gtMd: { type: 'boolean' },
						lg: { type: 'boolean' },
						gtLg: { type: 'boolean' },
						xl: { type: 'boolean' },
					},
				},
				successMessage: {
					type: 'object',
					properties: {
						show: { type: 'boolean' },
						position: {
							type: 'object',
							properties: {
								x: { type: 'integer' },
								y: { type: 'integer' },
							},
						},
						text: { type: 'string' },
					},
				},
				stepsCompleted: {
					type: 'object',
					properties: {
						hasBrandDetails: { type: 'boolean' },
						hasKeysAndProducts: { type: 'boolean' },
						hasDropEvent: { type: 'boolean' },
					},
				},
				clientMetadata: { type: 'object' },
				deviceId: { type: 'string' },
				experimentVariant: { type: 'string' },
				[giveawayTerms]: {
					type: 'object',
					properties: {
						pk: { type: 'string' },
						sk: { type: 'string' },
					},
				},
			},
		},
	},
}

export const generalApiModuleDescriptionSchema = {
	type: 'object',
	patternProperties: {
		[variableSchemaKey]: {
			type: 'object',
			properties: {
				pageContent: {
					title: 'string',
					text: 'string',
					link: 'string',
					linkLabel: 'string',
				},
				sliderWidth: { type: 'number' },
				sliderShowItems: { type: 'number' },
				sounds: {
					type: 'array',
				},
			},
		},
	},
}

export const appDescriptionLenses = lensesFromSchema(
	generalApiModuleDescriptionSchema,
)

export const appStoreLenses = lensesFromSchema(appSchema)
