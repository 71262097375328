import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import dropEventBase from 'root/src/client/logic/dropEvent/util/dropEventBase'
import updateEvent from 'root/src/client/logic/dropEvent/actions/updateEvent'
import updateStepsCompleted from 'root/src/client/util/updateStepsCompleted'
import changeInput from 'root/src/client/logic/form/actions/changeInput'
import setSubmitsBlock from 'root/src/client/logic/form/actions/setSubmitsBlock'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import setSubmitsLoading from 'root/src/client/logic/form/actions/setSubmitsLoading'
import mixpanel from 'root/src/client/logic/analytics/mixpanel'
import { mixpanelEvents } from 'root/src/shared/constants/mixpanel'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import { formStoreLenses } from 'root/src/client/logic/form/lenses'
import wait from 'root/src/shared/util/wait'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import { NO_PUBLISHER_SETTINGS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { hasBrandSettings } from 'root/src/shared/util/brandUtils'
import isAdminSelector from 'root/src/client/logic/auth/selectors/isAdminSelector'
import submitFormError from 'root/src/client/logic/form/actions/submitFormError'

const { viewSubmitsLoading } = formStoreLenses
export default (formData, { moduleKey, moduleId }) => async (dispatch, getState) => {
	const state = getState()
	const saveSubmitIdx = 0
	const isAdmin = isAdminSelector(state)
	const isInvoked = pipe(
		viewSubmitsLoading(moduleId),
		prop(saveSubmitIdx),
	)(state)
	if (isInvoked) return undefined

	dispatch(setSubmitsBlock({ moduleId, isVisible: true }))
	dispatch(submitFormError(moduleKey, 0, { error: undefined }))
	dispatch(setSubmitsLoading({ moduleId, [saveSubmitIdx]: true }))
	const { endpointId, dropEventPayload } = await dropEventBase({
		formData,
		dispatch,
		getState,
		moduleId,
		submitIndex: saveSubmitIdx,
		moduleKey,
	})
	const { listStoreKey, ...payload } = dropEventPayload

	try {
		const { body, error } = await invokeApiRequest(
			endpointId,
			{
				dropEventPayload: payload,
			},
			state,
		)

		if (!isAdmin && !hasBrandSettings(body?.brand) && !error) {
			dispatch(displayModal(NO_PUBLISHER_SETTINGS_MODAL_MODULE_ID))
		}

		if (error) {
			throw error
		}
		const { id, href, assets } = body
		dispatch(changeInput(moduleKey, ['id'], id))
		dispatch(changeInput(moduleKey, ['assets'], assets))
		dispatch(updateEvent({ ...body, listStoreKey }))
		updateStepsCompleted('hasDropEvent', dispatch, getState)
		mixpanel.track(mixpanelEvents.campaignSaved)
		if (href) {
			await pushRouteClient({ href })
		}
		await wait(1000)
		return body
	} catch (err) {
		dispatch(submitFormError(moduleKey, 0, err))
		throw err
	} finally {
		dispatch(setSubmitsBlock({ moduleId, isVisible: false }))
		dispatch(setSubmitsLoading({ moduleId, [saveSubmitIdx]: false }))
	}
}
