import {
	PLEDGE_PROJECT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

import pledgeProjectPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/pledgeProjectPayloadSchema'
import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/projectResponseSchema'

export const payloadSchema = pledgeProjectPayloadSchema
export const responseSchema = projectResponseSchema

export default {
	[PLEDGE_PROJECT]: {
		authentication: authenticationTypes.optional,
		endpointType: recordEndpointType,
		recordType: project,
		payloadSchema,
		responseSchema,
	},
}
