export default {
	type: 'array',
	items: {
		properties: {
			url: { type: 'string' },
			displayName: { type: 'string' },
			mnemonicId: { type: 'string' },
			image: { type: 'string' },
			platformId: { type: 'string' },
			platform: { type: 'string' },
			followerCount: { type: 'integer' },
			completedProjects: { type: 'number' },
		},
		required: ['url', 'displayName', 'mnemonicId', 'image', 'platformId', 'platform'],
		additionalProperties: false,
	},
}
