import decode from 'jwt-decode'

export default ({
	accessToken,
	refreshToken,
}) => {
	if (!accessToken || !refreshToken) { return {} }
	const {
		userId,
		email,
		groups,
		exp: accessTokenExp,
		parentUserId,
	} = decode(accessToken)
	const { exp: refreshTokenExp } = decode(refreshToken)
	return { accessToken, refreshToken, email, userId, accessTokenExp, refreshTokenExp, groups, parentUserId }
}
