import setCurrentPage from 'root/src/client/logic/list/actions/setCurrentPage'
import setListDataLength from 'root/src/client/logic/list/actions/setListDataLength'
import defaultListDataLengthSelector from 'root/src/client/logic/list/selectors/defaultListDataLengthSelector'
import isClientPaginatedSelector from 'root/src/client/logic/list/selectors/isClientPaginatedSelector'

export default moduleId => async (dispatch, getState) => {
	const state = getState()

	const isClientPaginated = isClientPaginatedSelector(state, {
		moduleId,
	})
	if (!isClientPaginated) {
		return
	}

	const defaultListDataLength = defaultListDataLengthSelector(state, {
		moduleId,
	})
	dispatch(setCurrentPage(1, moduleId))
	dispatch(setListDataLength(defaultListDataLength, moduleId))
}
