import { whitelistPlatforms, whitelistTypes } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addWhitelistedCreatorPayloadSchema'

export default {
	type: 'object',
	properties: {
		whitelistType: {
			type: 'string',
			enum: whitelistTypes,
		},
		platformId: { type: 'string' },
		platform: {
			type: 'string',
			enum: whitelistPlatforms,
		},
		campaignId: { type: 'string' },
		returnDataPaylaod: {
			type: 'object',
			properties: {
				whitelistType: { type: 'string' },
				campaignId: { type: 'string' },
			},
		},
	},
	required: ['whitelistType', 'platform', 'platformId'],
	additionalProperties: false,
}
