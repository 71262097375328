export default {
	type: 'object',
	properties: {
		games: {
			type: 'array',
			minItems: 1,
			maxItems: 1,
			uniqueItems: true,
			items: {
				type: 'object',
				properties: {
					id: { type: 'integer' },
				},
				required: ['id'],
			},
		},
	},
	required: ['games'],
	additionalProperties: false,
}
