import isEmpty from 'ramda/src/isEmpty'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'
import assigneeRouteSelector from 'root/src/client/logic/project/util/assigneeRouteSelector'

const { viewCreatorData } = appStoreLenses

export default (state) => {
	const creatorData = viewCreatorData(state)
	if (!creatorData || isEmpty(creatorData)) return undefined
	return assigneeRouteSelector(creatorData)
}
