import { UPDATE_CAMPAIGN_DEAL_ID } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import updateCampaignDealIdPayloadSchama from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/updateCampaignDealIdPayloadSchama'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[UPDATE_CAMPAIGN_DEAL_ID]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		payloadSchema: updateCampaignDealIdPayloadSchama,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
