import {
	AUDIT_PROJECT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'

import auditProjectPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/auditProjectPayloadSchema'
import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/projectResponseSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = auditProjectPayloadSchema
export const responseSchema = projectResponseSchema

export default {
	[AUDIT_PROJECT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: recordEndpointType,
		recordType: project,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
