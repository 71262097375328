import { ADD_WHITELISTED_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import addWhitelistedCreatorPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addWhitelistedCreatorPayloadSchema'
import whitelistedCreatorsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/whitelistedCreatorsResponseSchema'
import { whitelist } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[ADD_WHITELISTED_CREATORS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: listEndpointType,
		payloadSchema: addWhitelistedCreatorPayloadSchema,
		responseSchema: whitelistedCreatorsResponseSchema,
		recordType: whitelist,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
