import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { recordEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { giveawayScheduler } from 'root/src/shared/descriptions/endpoints/recordTypes';
export const getUserActiveGiveawaySchedulerEndpoint = {
    [giveawayEndpointIds.GET_USER_ACTIVE_GIVEAWAY_SCHEDULER]: {
        authentication: authenticationTypes.optional,
        payloadSchema: {
            type: 'object',
            properties: {
                userId: {
                    type: 'string',
                },
                mnemonicId: {
                    type: 'string',
                },
            },
        },
        endpointType: recordEndpointType,
        recordType: giveawayScheduler,
    },
};
