import { paypalPointsPayoutDoneKey, paypalPointsPayoutPendingKey, adminPointsModificationKey, paidOffPlatformKey, pointsAwardKey, } from 'root/src/shared/constants/keys';
export const pointsMinCcv = 50;
export const pointsReservationDays = 5;
export const transactionTypes = [
    pointsAwardKey,
    paypalPointsPayoutPendingKey,
    paypalPointsPayoutDoneKey,
    adminPointsModificationKey,
    paidOffPlatformKey,
];
