import {
	GET_PAYOUT_ADMIN,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { payoutInfo } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

export default {
	[GET_PAYOUT_ADMIN]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: listEndpointType,
		recordType: payoutInfo,
	},
}
