export default {
	type: 'object',
	properties: {
		messageId: { type: 'string' },
		messageType: { type: 'string' },
		projectId: { type: 'string' },
		projectUrl: { type: 'string' },
		parentId: { type: 'string' },
	},
	required: ['messageId', 'messageType', 'projectId', 'projectUrl', 'parentId'],
	additionalProperties: false,
}
