import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import getPublisherTokenPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getPublisherTokenPayloadSchema';
import getPublisherTokenResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getPublisherTokenResponseSchema';
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants';
export const getPublisherTokenEndpoint = {
    [agencyEndpointIds.GET_PUBLISHER_TOKEN]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: [userGroups.agencyAccountManager, userGroups.brand],
        endpointType: otherEndpointType,
        payloadSchema: getPublisherTokenPayloadSchema,
        responseSchema: getPublisherTokenResponseSchema,
    },
};
