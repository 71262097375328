import {
	SET_SLIDEOUT_TRAY_VISIBLE,
} from 'root/src/client/logic/app/actionIds'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { setIsTraySlidedOut } = appStoreLenses

export default {
	[SET_SLIDEOUT_TRAY_VISIBLE]: (state, value) => setIsTraySlidedOut(value, state),
}
