// Privacy Policy
const CHANGE_POLICY = 'changes-policy'
const WHO_WE_ARE = 'who-we-are'
const HOW_APPLIES = 'how-applies'
const COLLECT_AND_RECIVE = 'collect-and-recive'
const HOW_USE_INFO = 'how-use-info'
const UNION_USER = 'union-users'
const HOW_SHARE = 'how-share'
const SHARED_PUBLICY = 'shared-publicy'
const NOT_SHARED_PUBLICY = 'not-shared-publicy'
const SHARED_WITH_TRUSTED = 'shared-with-trusted'
const SHARED_WITH_CREATORS = 'shared-with-creators'
const SHARED_WITH_DARE_DROP = 'shared-dare-drop'
const LINKS_TO_SERVICE = 'links-to-services'
const RETENTION = 'retention'
const DATA_TRANSFER = 'data-transfer'
const RIGHTS = 'your-rights'
const NOTIFICATION = 'notification'
const SECURE = 'secure'
const DATA_PROTECT_OFFICER = 'data-protect-officer'
const DATA_PROTECT_AUTHORITY = 'data-protect-authority'
const CHILDREN = 'children'

// FAQ
const FANS = 'fans'
const WHAT_IS_FANS = 'what-is-fans'
const FANS_ACCEPTED = 'fans-accepted'
const FANS_COMPLETED = 'fans-completed'
const FANS_NOT_COMPLETED = 'fans-not-completed'
const FAN_NO_CREATOR = 'fan-no-creator'
const FAN_CREATOR_CUT = 'fan-creator-cut'
const CREATORS = 'creators'
const CREATOR_WHAT_IS = 'creator-what-is'
const CREATOR_JOIN = 'creator-join'
const CREATOR_WHATS_NEXT = 'creator-whats-next'
const CREATOR_COMPLETED = 'creator-completed'
const HOW_PAID = 'how-paid'
const DD_FEE = 'dd-fee'
const CREATOR_ACCEPT_REJECT = 'creator-accept-reject'
const SET_GOAL = 'set-goal'
const HOW_LONG = 'how-long'
const CREATOR_MODERATE = 'creator-moderate'
const CREATOR_UNDER_GOAL = 'creator-under-goal'
const MUST_STREAM = 'must-stream'
const BROADCASTING_SOFTWARE = 'broadcasting-software'
const AUDIO_TROUBLESHOOTING = 'audio-troubleshooting'
const DROPS = 'drops'
const DROPS_WHAT = 'drops-what'
const DROPS_PARTICIPATE = 'drops-participate'
const BUY_GAME = 'buy-game'
const DROPS_HOW_MANY = 'drops-how-many'
const CANT_ACCESS = 'cant-access'
const DROP_COMPLETE = 'drop-complete'
const DROP_REWARDS = 'drop-rewards'
const DROP_REGIONS = 'drop-regions'
const BRANDS = 'brands'
const BRANDS_USE = 'brands-use'
const BRANDS_TYPE = 'brands-type'
const BRANDS_HOW = 'brands-how'
const BRANDS_WHY = 'brands-why'
const BRANDS_BILLING = 'brands-billing'
const BRANDS_MANAGED = 'brands-managed'
// dropEvent page
const DROP_FAQ_SECTION = 'drop-faq-section'
const DROP_ALL_DARES = 'drop-all-dares'
// lists
const TOP = 0

export default {
	// Privacy Policy
	CHANGE_POLICY,
	WHO_WE_ARE,
	HOW_APPLIES,
	COLLECT_AND_RECIVE,
	HOW_USE_INFO,
	UNION_USER,
	HOW_SHARE,
	SHARED_PUBLICY,
	NOT_SHARED_PUBLICY,
	SHARED_WITH_CREATORS,
	SHARED_WITH_TRUSTED,
	SHARED_WITH_DARE_DROP,
	LINKS_TO_SERVICE,
	RETENTION,
	DATA_TRANSFER,
	NOTIFICATION,
	RIGHTS,
	SECURE,
	DATA_PROTECT_OFFICER,
	DATA_PROTECT_AUTHORITY,
	CHILDREN,
	// FAQ
	FANS,
	WHAT_IS_FANS,
	FANS_ACCEPTED,
	FANS_COMPLETED,
	FANS_NOT_COMPLETED,
	FAN_NO_CREATOR,
	FAN_CREATOR_CUT,
	CREATORS,
	CREATOR_WHAT_IS,
	CREATOR_JOIN,
	CREATOR_WHATS_NEXT,
	CREATOR_COMPLETED,
	HOW_PAID,
	DD_FEE,
	CREATOR_ACCEPT_REJECT,
	SET_GOAL,
	HOW_LONG,
	CREATOR_MODERATE,
	CREATOR_UNDER_GOAL,
	MUST_STREAM,
	BROADCASTING_SOFTWARE,
	AUDIO_TROUBLESHOOTING,
	DROPS,
	DROPS_WHAT,
	DROPS_PARTICIPATE,
	BUY_GAME,
	DROPS_HOW_MANY,
	CANT_ACCESS,
	DROP_COMPLETE,
	DROP_REWARDS,
	DROP_REGIONS,
	BRANDS,
	BRANDS_USE,
	BRANDS_TYPE,
	BRANDS_HOW,
	BRANDS_WHY,
	BRANDS_BILLING,
	BRANDS_MANAGED,
	// dropEvent page
	DROP_FAQ_SECTION,
	DROP_ALL_DARES,
	// Lists
	TOP,
}
