var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { GET_USER_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { dareDropClient } from 'root/src/client/v2/common/clients/dareDropClient';
import { useAuthentication } from 'root/src/client/v2/auth/hooks/useAuthentication';
import { globalQueryClient } from 'root/src/client/v2/common/clients/queryClient';
// TODO this is repeated in useApplicationState too - please find some sort of generic solution in next occasion
// This is made so we don't have to manually invalidate userData on auth change.
// If anobody ever has better idea of doing it, please make a suggestion
let authentication;
let isInit = true;
export function useUserData() {
    const { authentication: auth } = useAuthentication();
    const authChanged = !isInit && JSON.stringify(authentication) !== JSON.stringify(auth);
    isInit = false;
    authentication = auth;
    const queryClient = useQueryClient(globalQueryClient);
    const invalidateUserData = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        yield queryClient.resetQueries({ queryKey: [GET_USER_DATA] });
    }), []);
    if (authChanged) {
        void invalidateUserData();
    }
    const query = useQuery({
        queryKey: [GET_USER_DATA],
        queryFn: () => __awaiter(this, void 0, void 0, function* () {
            if (!authentication) {
                return undefined;
            }
            return dareDropClient({ endpointId: GET_USER_DATA });
        }),
        enabled: !!authentication,
    }, queryClient);
    return {
        userData: query.data,
        invalidateUserData,
        isLoading: query.isLoading,
    };
}
