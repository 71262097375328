import React from 'react'
import { LiveChatLoaderProvider, Drift } from 'react-live-chat-loader'

export default () => (
	<LiveChatLoaderProvider
		idlePeriod="1000"
		providerKey="uvceibmepai6"
		provider="drift"
	>
		<Drift color="#5FC49A" />
	</LiveChatLoaderProvider>
)
