import {
	projectPendingKey,
	projectApprovedKey,
	projectRejectedKey,
	projectDeliveryRejectedKey,
	projectAcceptedKey,
	projectCreatorRejectedKey,
	projectAllCreatorsRejectedKey,
	projectDeliveryPendingKey,
	projectDeliveredKey,
} from 'root/src/shared/constants/keys'
import { rewardForOptions } from 'root/src/shared/constants/dropEvent'

export default {
	type: 'object',
	properties: {
		id: { type: 'string' },
		projectUrl: { type: 'string' },
		shareUrl: { type: 'string' },
		title: { type: 'string' },
		image: { type: 'string' },
		description: { type: 'string' },
		favoritesAmount: { type: 'integer' },
		pledgers: { type: 'integer' },
		myPledge: { type: 'number' },
		myFavorites: { type: 'integer' },
		approved: { type: 'string' },
		brandProduct: {
			type: 'object',
			properties: {
				productName: { type: 'string' },
				url: { type: 'string' },
				cta: { type: 'string' },
			},
		},
		sponsor: {
			type: 'object',
			properties: {
				brandName: { type: 'string' },
				brandWebsite: { type: 'string' },
				brandLogo: { type: 'object' },
			},
		},
		created: { type: 'string' },
		pledgeAmount: { type: 'number' },
		assignees: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					platform: {
						type: 'string',
						enum: ['twitch', 'youtube', 'tiktok'],
					},
					image: { type: 'string' },
					description: { type: 'string' },
					platformId: { type: 'string' },
					displayName: { type: 'string' },
					amountRequested: { type: 'number' },
					username: { type: 'string' },
				},
			},
		},
		games: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					name: { type: 'string' },
					boxArtTemplateUrl: { type: 'string' },
					mobile: { type: 'string' },
					desktop: { type: 'string' },
				},
			},
		},
		deliveries: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					videoURL: { type: 'string' },
					timeStamp: { type: 'string' },
					notesForReviewer: { type: 'string' },
				},
			},
		},
		userRejectedDare: { type: 'boolean' },
		creator: { type: 'string' },
		creatorData: {
			type: 'object',
			properties: {
				twitchId: { type: 'string' },
				displayName: { type: 'string' },
				img: { type: 'string' },
			},
		},
		status: {
			type: 'string',
			enum: [
				projectPendingKey,
				projectApprovedKey,
				projectRejectedKey,
				projectDeliveryRejectedKey,
				projectAcceptedKey,
				projectCreatorRejectedKey,
				projectAllCreatorsRejectedKey,
				projectDeliveryPendingKey,
				projectDeliveredKey,
			],
		},
		isPledged: { type: 'boolean' },
		isAccepted: { type: 'boolean' },
		isPinnedDare: { type: 'boolean' },
		expirationDate: { type: 'string' },
		isFeatured: { type: 'boolean' },
		campaignId: { type: 'string' },
		capacity: {
			type: 'object',
			properties: {
				available: { type: 'integer' },
				used: { type: 'integer' },
			},
		},
		campaignName: { type: 'string' },
		isPrivate: { type: 'boolean' },
		rewardFor: {
			type: 'string',
			enum: rewardForOptions.map(option => option.value),
		},
		hint: { type: 'string' },
		landingShortUrl: { type: 'string' },
		streamMinutes: { type: 'number' },
		level: { type: 'string' },
	},
}
