import {
	SMITE_GIVEAWAY_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[SMITE_GIVEAWAY_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'smiteGiveaway',
	},
}
