import {
	DELETE_COMMENT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { comment } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import payloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/deleteCommentPayloadSchema'
import responseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/deleteCommentResponseSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[DELETE_COMMENT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: recordEndpointType,
		recordType: comment,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
