import { POINTS_MARKETPLACE_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { marketplaceItem } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { pointsMarketplaceListType } from 'root/src/shared/constants/moduleTypes'
import { GET_POINTS_MARKETPLACE_ITEMS, GET_USER_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'

const scrollItemBreakpoints = (width) => {
	if (width < 900) return 1
	if (width < 1300) return 2
	if (width < 1800) return 3
	return 4
}

export default {
	[POINTS_MARKETPLACE_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: pointsMarketplaceListType,
		recordType: marketplaceItem,
		endpointId: [GET_POINTS_MARKETPLACE_ITEMS, GET_USER_DATA],
		listTitle: 'Points Marketplace',
		listInfoText: 'Ready to get swagged up? Cruise through Dare Drops catalog of items and redeem your hard earned points for cool stuff!',
		scrollItemBreakpoints,
	},
}
