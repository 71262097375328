import reduce from 'ramda/src/reduce'
import has from 'ramda/src/has'
import is from 'ramda/src/is'
import path from 'ramda/src/path'
import prop from 'ramda/src/prop'

const subKey = '$sub'

export default (substitutes, mapArray, startPayload = {}) => reduce(
	(result, [key, value]) => {
		if (is(Object, value) && has(subKey, value)) {
			const subLookup = prop(subKey, value)
			const viewFn = is(Array, subLookup) ? path : prop
			return { ...result, [key]: viewFn(subLookup, substitutes) }
		}
		return { ...result, [key]: value }
	},
	startPayload,
	mapArray,
)
