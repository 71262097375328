import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const getDecryptedRewardEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.viewer,
    endpointType: otherEndpointType,
    payloadSchema: {
        type: 'object',
        properties: {
            rewardId: {
                type: 'string',
            },
        },
        required: ['rewardId'],
    },
    responseSchema: {
        type: 'string',
    },
};
