import React from 'react'
import Head from 'next/head'
import appleTouchIcon from 'root/src/client/public/favicons/apple-touch-icon.jpg'
import favicon32 from 'root/src/client/public/favicons/favicon-32x32.jpg'
import favicon16 from 'root/src/client/public/favicons/favicon-16x16.jpg'
import safariPinnedTab from 'root/src/client/public/favicons/safari-pinned-tab.svg'
import webmanifest from 'root/src/client/public/site.webmanifest'

export default ({ metaData: { description, title, canonical } }) => (
	<Head>
		<title>{title}</title>
		<meta name="description" content={description} key="metaDescription" />
		{canonical && <link rel="canonical" href={canonical} />}
		<link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon.src} />
		<link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
		<link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />
		<link rel="mask-icon" href={safariPinnedTab.src} color="#000000" />
		<link
			rel="manifest"
			href={webmanifest}
			crossOrigin="use-credentials"
		/>
	</Head>
)
