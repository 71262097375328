import { routeStoreLenses } from 'root/src/client/logic/route/lenses'

const { viewRouteId } = routeStoreLenses

export default (state, { moduleId, routeId, omitRouteId = false }) => {
	const routeIdMod = routeId || viewRouteId(state)
	if (routeIdMod) {
		return `${routeIdMod}-${moduleId}`
	}

	if (omitRouteId) {
		// 'DEFAULT_ROUTE' - doesn't mean anything, it could be any string.
		// Needed only for correct behavior when we omit routeId prop
		return `DEFAULT_ROUTE-${moduleId}`
	}

	return undefined
}
