import compose from 'ramda/src/compose'
import { ADD_SORT_FILTER_PARAMS } from 'root/src/client/logic/header/actionsIds'

import { sortFilterStoreLenses } from 'root/src/client/logic/sortFilter/lenses'

const { setFilterParams, setSortType, setSection, setSubSection } = sortFilterStoreLenses

export default {
	[ADD_SORT_FILTER_PARAMS]: (state, { moduleId, params }) => {
		const { filter, sortType, section, subSection } = params
		return compose(
			setFilterParams(moduleId, filter),
			setSortType(moduleId, sortType),
			setSection(moduleId, section),
			setSubSection(moduleId, subSection),
		)(state)
	},
}
