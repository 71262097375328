import compose from 'ramda/src/compose'
import path from 'ramda/src/path'
import keys from 'ramda/src/keys'
import findIndex from 'ramda/src/findIndex'
import startsWith from 'ramda/src/startsWith'
import without from 'ramda/src/without'
import dissocPath from 'ramda/src/dissocPath'

import { GET_COUPONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { DELETE_COUPON_ON_SUCCESS } from 'root/src/client/logic/coupon/actionIds'
import { COUPON_LIST_ADMIN_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'

const { setListsChild, setListProcessingChild, viewListProcessing } = apiStoreLenses

export default {
	[DELETE_COUPON_ON_SUCCESS]: (state, payload) => {
		const { id } = payload
		const lists = path(['api', 'lists'], state)
		const listKeys = keys(viewListProcessing(state))
		const key = listKeys[findIndex(s => startsWith(GET_COUPONS, s))(listKeys)]
		const prevList = lists[key]

		const newList = { items: without([id], prevList.items), next: undefined }
		const listStoreKey = createListStoreKey(
			{ routeId: GET_COUPONS }, newList, COUPON_LIST_ADMIN_MODULE_ID,
		)

		return compose(
			dissocPath(['api', 'lists', key]),
			dissocPath(['api', 'listProcessing', key]),
			setListProcessingChild(listStoreKey, false),
			setListsChild(listStoreKey, newList),
		)(state)
	},
}
