export const languageCodes = {
    en: 'en',
    es: 'es',
    br: 'pt-br',
    fr: 'fr',
    de: 'de',
};
export const reversedLanguageCodes = {
    [languageCodes.en]: 'en',
    [languageCodes.es]: 'es',
    [languageCodes.br]: 'pt',
    [languageCodes.fr]: 'fr',
    [languageCodes.de]: 'de',
};
