export default {
	type: 'array',
	items: {
		properties: {
			userId: { type: 'string' },
			email: { type: 'string' },
		},
		required: ['userId', 'email'],
		additionalProperties: false,
	},
}
