import { RewardType } from 'root/src/shared/rewards/constants'

export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		audit: { type: 'string' },
		message: { type: 'string' },
		noPayout: { type: 'boolean' },
		moreRewardsExpected: { type: 'boolean' },
		replacementReward: {
			type: 'object',
			properties: {
				reason: {
					type: 'string',
				},
				product: {
					type: 'object',
					properties: {
						id: { type: 'string' },
						amount: { type: 'integer' },
						type: {
							type: 'string',
							enum: [
								RewardType.KEY,
								RewardType.CODE,
								RewardType.CUSTOM,
								RewardType.BUNDLE,
							],
						},
					},
				},
			},
		},
	},
	required: ['projectUrl', 'audit'],
	additionalProperties: false,
}
