import { MANAGE_MARKETPLACE_ITEM } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { marketplaceItem } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import manageMarketplaceItemPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/manageMarketplaceItemPayloadSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[MANAGE_MARKETPLACE_ITEM]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: listEndpointType,
		recordType: marketplaceItem,
		payloadSchema: manageMarketplaceItemPayloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
