import compose from 'ramda/src/compose'
import lensPath from 'ramda/src/lensPath'
import over from 'ramda/src/over'
import add from 'ramda/src/add'
import { ADD_COMMENT_REPLY } from 'root/src/client/logic/comment/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import getIdFromKey from 'root/src/shared/util/getIdFromKey'

const { setReplies, viewCommentChild, setRepliesAmount } = apiStoreLenses

export const addCommentReply = (state, payload) => {
	const { parentId, projectUrl } = payload

	const updateId = getIdFromKey(parentId)
	const { replies, repliesAmount } = viewCommentChild(updateId, state)
	const commentAmountLens = lensPath(['api', 'records', 'project', projectUrl, 'commentAmount'])

	return compose(
		setRepliesAmount(updateId, repliesAmount + 1),
		setReplies(updateId, [...replies, payload]),
		over(commentAmountLens, add(1)),
	)(state)
}

export default {
	[ADD_COMMENT_REPLY]: addCommentReply,
}
