import withStyles from '@material-ui/core/styles/withStyles'
import { colorsNew } from 'root/src/client/web/commonStyles'

const style = {
	root: {
		margin: [[0, 'auto']],
		maxWidth: 1750,
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	reloadButton: {
		cursor: 'pointer',
		color: colorsNew.teal
		,
	},
}

const LoadingError = ({ classes }) => (
	<div className={classes.root}>
		<p>
			There was an error in loading this page.{' '}
			<span
				className={classes.reloadButton}
				onClick={() => {
					window.location.reload()
				}}
			>
				Reload this page
			</span>
		</p>
	</div>
)

export default withStyles(style, { name: 'MuiLoadingError' })(LoadingError)
