import {
	CREATE_INTRO_DARE_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[CREATE_INTRO_DARE_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Do your First Dare!',
			paragraphs: [
				'You can also reject this one and make your own.',
			],
		},
	},
}
