export var Platform;
(function (Platform) {
    Platform["Twitch"] = "twitch";
    Platform["TikTok"] = "tiktok";
    Platform["YouTube"] = "youtube";
})(Platform || (Platform = {}));
export const DarePlatform = {
    Twitch: Platform.Twitch,
    TikTok: Platform.TikTok,
    YouTube: Platform.YouTube,
    Any: 'any',
};
