import { NEW_FOOTER_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

import {
	FAQ_ROUTE_ID,
	TERMS_OF_SERVICE_ROUTE_ID,
	COOKIE_POLICY_ROUTE_ID,
	PRIVACY_POLICY_ROUTE_ID,
	PUBLISHERS_PAGE_ROUTE_ID,
	CREATORS_PAGE_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { EMAIL } from 'root/src/shared/constants/pageData'

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import DareDropLogoGray from 'root/src/client/public/DareDropLogoGray.svg'

export default {
	[NEW_FOOTER_MODULE_ID]: {
		logo: {
			image: DareDropLogoGray,
			alt: 'Dare Drop',
		},
		links: [
			{
				text: 'For Creators',
				routeId: CREATORS_PAGE_ROUTE_ID,
			},
			{
				text: 'For Publishers',
				routeId: PUBLISHERS_PAGE_ROUTE_ID,
			},
			{
				text: 'About us',
				href: '/about-us', // webflow
			},
			{
				text: 'FAQ',
				routeId: FAQ_ROUTE_ID,
			},
			{
				text: 'Contact us',
				emailAddress: EMAIL,
			},
			{
				text: 'Terms of Service',
				routeId: TERMS_OF_SERVICE_ROUTE_ID,
			},
			{
				text: 'Cookie Policy',
				routeId: COOKIE_POLICY_ROUTE_ID,
			},
			{
				text: 'Privacy Policy',
				routeId: PRIVACY_POLICY_ROUTE_ID,
			},
			{
				text: 'Get Press Kit',
				downloadPath: '/files/Dare_Drop_Brand_Kit.pdf',
			},
		],
		socialLinks: [
			{
				href: 'https://www.facebook.com/daredrops',
				icon: faFacebook,
				alt: 'facebook',
			},
			{
				href: 'https://www.instagram.com/dare.drops/',
				icon: faInstagram,
				alt: 'instagram',
			},
			{
				href: 'https://www.youtube.com/channel/UC9HaIflzLYyMTWaoMyok3VQ',
				icon: faYoutube,
				alt: 'youtube',
			},
			{
				href: 'https://twitter.com/daredrops',
				icon: faTwitter,
				alt: 'twitter',
			},
		],
		discord: {
			href: 'https://discord.gg/xmECwRu',
		},
	},
}
