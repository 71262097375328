import {
	REJECT_PROJECT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { authenticationTypes } from 'root/src/shared/constants/auth'

import rejectProjectPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/rejectProjectPayloadSchema'
import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getProjectResponseSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = rejectProjectPayloadSchema
export const responseSchema = projectResponseSchema

export default {
	[REJECT_PROJECT]: {
		endpointType: recordEndpointType,
		recordType: project,
		authentication: authenticationTypes.authenticated,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
