import { GET_STREAMS_STATS_REPORT_PAYLOAD } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import getStreamsStatsReportPayloadResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getStreamsStatsReportPayloadResponseSchema'

export default {
	[GET_STREAMS_STATS_REPORT_PAYLOAD]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		responseSchema: getStreamsStatsReportPayloadResponseSchema,
		payloadSchema: {
			type: 'object',
			properties: {
				dateTo: { type: 'string' },
				dateFrom: { type: 'string' },
			},
			required: [],
			additionalProperties: false,
		},
	},
}
