import { DISPLAY_MODAL } from 'root/src/client/logic/modal/actionIds'

export default (modalModuleId, props, modalRouteId) => ({
	type: DISPLAY_MODAL,
	payload: {
		modalModuleId,
		props,
		modalRouteId,
	},
})
