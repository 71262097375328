import { GET_SHORT_URL_REPORT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { shortUrlAnalytics } from 'root/src/shared/descriptions/endpoints/recordTypes'
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'
import getShortUrlReportResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getShortUrlReportResponseSchema'

export default {
	[GET_SHORT_URL_REPORT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		recordType: shortUrlAnalytics,
		payloadSchema: emptyPayloadSchema,
		responseSchema: getShortUrlReportResponseSchema,
	},
}
