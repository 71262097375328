export default {
	type: 'array',
	items: {
		type: 'object',
		properties: {
			url: { type: 'string' },
			displayName: { type: 'string' },
			mnemonicId: { type: 'string' },
			image: { type: 'string' },
			platformId: { type: 'string' },
			followerCount: { type: 'number' },
			userId: { type: 'string' },
			platform: { type: 'string' },
		},
	},
}
