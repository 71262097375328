import { DELETE_PROFILE_BACKGROUND } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { profile } from 'root/src/shared/descriptions/endpoints/recordTypes';
export const deleteProfileBackground = {
    [DELETE_PROFILE_BACKGROUND]: {
        authentication: authenticationTypes.authenticated,
        middlewares: [VALIDATE_TOKEN_ACCOUNT],
        endpointType: recordEndpointType,
        recordType: profile,
    },
};
