export default {
	type: 'object',
	properties: {
		pledgeSounds: { type: 'array' },
		dareSounds: { type: 'array' },
		soundVolume: { type: 'number' },
		pledgeOptions: { type: 'array' },
		aligned: { type: 'string' },
	},
	required: [],
	additionalProperties: false,
}
