import reduxConnector from 'root/src/shared/util/reduxConnector'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import moduleTypeSelector from 'root/src/client/logic/module/selectors/moduleTypeSelector'
import moduleKeySelector from 'root/src/client/logic/module/selectors/moduleKeySelector'

export default reduxConnector(
	[
		['routeId', currentRouteId],
		['moduleType', moduleTypeSelector],
		['moduleKey', moduleKeySelector],
	],
	[],
)
