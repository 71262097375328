export default {
	type: 'array',
	items: {
		type: 'object',
		properties: {
			userNotification: { type: 'string' },
			notificationData: {
				type: 'object',
				properties: {
					displayName: { type: 'string' },
					platform: { type: 'string' },
					projectUrl: { type: 'string' },
				},
				additionalProperties: false,
			},
		},
		additionalProperties: false,
	},
}
