import { brandEndpointIds } from 'root/src/shared/brand/brand.constants';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const inviteBrandUserEndpoint = {
    [brandEndpointIds.INVITE_BRAND_USER]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: [AuthGroup.Brand, AuthGroup.BrandAdmin],
        payloadSchema: {
            type: 'object',
            properties: {
                email: { type: 'string' },
                role: {
                    type: 'string',
                    enum: [AuthGroup.BrandUser, AuthGroup.BrandAdmin],
                },
            },
            required: ['email', 'role'],
        },
        endpointType: otherEndpointType,
        useRequestUserId: true,
    },
};
