import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { RewardStatus } from 'root/src/shared/rewards/constants';
export const getBrandBundlesEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.brandUser,
    endpointType: recordEndpointType,
    middlewares: [VALIDATE_TOKEN_ACCOUNT],
    payloadSchema: {
        type: 'object',
        properties: {
            status: {
                type: 'string',
                enum: [
                    RewardStatus.AVAILABLE,
                    RewardStatus.USED,
                ],
            },
        },
    },
};
