import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const saveRewardsBulkEndpoint = {
    authentication: authenticationTypes.authenticated,
    authenticationRole: userGroups.brandUser,
    endpointType: otherEndpointType,
    payloadSchema: {
        type: 'object',
        properties: {
            fileName: {
                type: 'string',
            },
        },
        required: ['fileName'],
    },
};
