import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
export const getUserAvailableActionsEndpoint = {
    [giveawayEndpointIds.GET_USER_AVAILABLE_ACTIONS]: {
        isInternal: true,
        payloadSchema: {
            type: 'object',
            properties: {
                userId: {
                    type: 'string',
                },
                mnemonicId: {
                    type: 'string',
                },
            },
        },
    },
};
