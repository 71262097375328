import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import { SEARCH_TWITCH_GAMES, SEARCH_TWITCH_USERS } from 'root/src/shared/descriptions/endpoints/endpointIds'

export const getTwitchGames = async (input, after = null, first = 10) => {
	try {
		const { body: { data, pagination: { cursor } } } = await invokeApiRequest(
			SEARCH_TWITCH_GAMES, { input, after, first },
		)
		// eslint-disable-next-line camelcase
		const result = data?.map(({ name, id, box_art_url }) => ({
			label: name,
			id: Number(id),
			value: Number(id),
			image: box_art_url,
			cursor,
		}))
		return result
	} catch (e) {
		return []
	}
}

export const getTwitchChannels = async (input, after = null, first = 10) => {
	try {
		const { body: { data } } = await invokeApiRequest(
			SEARCH_TWITCH_USERS, { input, after, first },
		)
		// eslint-disable-next-line camelcase
		return data.map(({ display_name, id, thumbnail_url, broadcaster_login }) => ({
			label: display_name,
			id: String(id),
			value: broadcaster_login, // mnemonicId
			image: thumbnail_url,
			platform: 'twitch',
		}))
	} catch (e) {
		return []
	}
}
