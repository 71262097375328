import {
	CHANGE_PASSWORD_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { LOGIN_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[CHANGE_PASSWORD_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Password Changed',
			paragraphs: [
				'Your password has been changed.',
			],
			link: LOGIN_ROUTE_ID,
			linkLabel: 'Sign In to continue',
			socialSharing: false,
		},
	},
}
