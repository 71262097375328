import isNil from 'ramda/src/isNil'
import isEmpty from 'ramda/src/isEmpty'
import ifElse from 'ramda/src/ifElse'
import includes from 'ramda/src/includes'
import always from 'ramda/src/always'
import when from 'ramda/src/when'
import assoc from 'ramda/src/assoc'
import { isObj, isFunction } from 'root/src/shared/util/ramdaPlus'

const parseFunction = fn => `Function[${fn.name}]`

export default (keys, replacer, item) => {
	const replaceKeyInner = (itemI) => {
		if (isNil(itemI) || isEmpty(itemI)) {
			return itemI
		}
		if (isObj(itemI)) {
			return Object.entries(itemI).reduce((prev, [key, value]) => {
				const valParsed = ifElse(
					() => includes(key, keys),
					always(replacer),
					when(
						isFunction,
						parseFunction,
					),
				)(value)
				if (isObj(valParsed) || Array.isArray(valParsed)) {
					return assoc(key, replaceKeyInner(valParsed), prev)
				}
				return assoc(key, valParsed, prev)
			}, {})
		}
		if (Array.isArray(itemI))	return itemI.map(replaceKeyInner)
		if (isFunction(itemI)) return parseFunction(itemI)
		return itemI
	}

	return replaceKeyInner(item)
}
