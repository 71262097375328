import { getCookie } from 'root/src/client/v2/browser/helpers/cookies';
export const useAuthentication = () => {
    const accessToken = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');
    const userId = getCookie('userId');
    const email = getCookie('email');
    const groups = getCookie('groups');
    if (typeof accessToken !== 'string'
        || typeof email !== 'string'
        || typeof userId !== 'string'
        || typeof groups !== 'string') {
        return { authentication: undefined };
    }
    const groupsParsed = JSON.parse(groups);
    return {
        authentication: {
            accessToken,
            email,
            userId,
            groups: groupsParsed,
            refreshToken,
        },
    };
};
