var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import NextApp from 'next/app';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import '../public/css/react-material-class-layout.css';
import '../public/css/app.css';
import '../public/css/nprogress.css';
import '../public/css/twitter-styles.css';
import sentry from 'root/src/client/util/sentry';
import classNames from 'classnames';
import wrapper from 'root/src/client/logic/web';
import Navigation from 'root/src/client/web/base/Navigation';
import RenderModules from 'root/src/client/web/base/RenderModules';
import Footer from 'root/src/client/web/base/Footer';
import appConnector from 'root/src/client/logic/app/connectors/appConnector';
import cleanState from 'root/src/client/logic/app/actions/cleanState';
import setClientMetadata from 'root/src/client/logic/app/actions/setClientMetadata';
import { getInitialPropsRedirect } from 'root/src/client/util/redirects';
import RouteGuard from 'root/src/client/web/base/RouteGuard';
import HeadClient from 'root/src/client/web/base/HeadClient';
import TechIssueComponent from 'root/src/client/web/TechIssueComponent';
import Analytics from 'root/src/client/web/base/Analytics';
import FacebookPixelScript from 'root/src/client/web/scripts/FacebookPixelScript';
import ZohoLeadTrackingScript from 'root/src/client/web/scripts/ZohoLeadTrackingScript';
import TiktokPixelScript from 'root/src/client/web/scripts/TiktokPixelScript';
import clearCookies from 'root/src/client/util/clearCookies';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material';
import { muiDarkTheme } from 'root/src/client/v2/common/layouts/themes';
// prevents icon rendering before CSS is loaded
import '@fortawesome/fontawesome-svg-core/styles.css';
import { ModalContextProvider } from 'root/src/client/v2/common/contexts/modal.context';
import { globalQueryClient, queryClient } from 'root/src/client/v2/common/clients/queryClient';
import { SlideoutTrayContextProvider } from 'root/src/client/v2/common/contexts/slideoutTray.context';
import { GlobalDataProvider } from 'root/src/client/v2/common/providers/GlobalDataProvider';
import { CreatorOnboardingButtons } from 'root/src/client/v2/creatorOnboarding/features/CreatorOnboardingButtons';
import ErrorBoundary from 'root/src/client/web/base/ErrorBoundary';
import { Toaster } from 'root/src/client/v2/layout/components/Toaster';
import { ServerSideAuthService } from 'root/src/client/v2/auth/services/ServerSideAuth.service';
import { QueryParamProvider } from 'use-query-params';
import { WindowSSRAdapter } from 'root/src/client/v2/queryParams/adapters/window.adapter';
import { trpc } from 'root/src/client/v2/trpc/trpc';
const styles = {
    content: {
        WebkitFlex: [[1, 0, 'auto']],
        flex: [[1, 0, 'auto']],
    },
    overflowContent: {
        overflow: 'hidden',
    },
};
NProgress.configure({ showSpinner: false });
// TODO @next. make some cleanup here after all. both server side and client side
// it's important to keep here TRPC, to avoid overwriting `getInitialProps`
const App = wrapper.withRedux(trpc.withTRPC(({ metaData, testMediaBreakpoints, determineAuth, pageProps, Component, router: appRouter, classes, restrictionRedirect, routeRestriction, runModuleMountClient, }) => {
    const [isInitRequestsDone, setIsInitRequestsDone] = useState(false);
    const router = useRouter();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const jssStyles = document.querySelector('#jss-server-side');
            if (jssStyles === null || jssStyles === void 0 ? void 0 : jssStyles.parentElement) {
                jssStyles.parentElement.removeChild(jssStyles);
            }
            testMediaBreakpoints();
            sentry();
            clearCookies();
            yield determineAuth();
            setIsInitRequestsDone(true);
            const determineAuthLoop = () => {
                setTimeout(() => {
                    determineAuth();
                    determineAuthLoop();
                }, 1000 * 60 * 5); // we check auth every 5 minutes
            };
            determineAuthLoop();
        }))();
    }, []);
    const handleStart = useCallback(() => {
        NProgress.start();
    }, []);
    const handleStop = useCallback(() => {
        NProgress.done();
    }, []);
    useEffect(() => {
        if (isInitRequestsDone) {
            runModuleMountClient();
        }
        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleStop);
        router.events.on('routeChangeError', handleStop);
        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleStop);
            router.events.off('routeChangeError', handleStop);
        };
    }, [router, isInitRequestsDone]);
    if (appRouter.route === '/500') {
        return (<Component {...pageProps}/>);
    }
    // !!! all ignores are for JS components. change that in the future when refactored to ts
    return (
    /* @ts-ignore */
    <ErrorBoundary FallbackComponent={TechIssueComponent}>
			<QueryClientProvider client={globalQueryClient}>
				<QueryClientProvider client={queryClient}>
					<QueryParamProvider adapter={WindowSSRAdapter} options={{
            includeAllParams: true,
            enableBatching: true,
        }}>
						<GlobalDataProvider>
							<ThemeProvider theme={muiDarkTheme}>
								<ModalContextProvider>
									<SlideoutTrayContextProvider>
										<CreatorOnboardingButtons />
										<FacebookPixelScript />
										<ZohoLeadTrackingScript />
										<TiktokPixelScript />
										<RouteGuard {...{ isInitRequestsDone, routeRestriction, restrictionRedirect, NProgress }}>
											<Analytics router={router}/>
											<HeadClient metaData={metaData}/>
											<div className={classNames(classes.content, classes.overflowContent, 'layout-column')}>
												<HydrationBoundary state={pageProps.dehydratedState}>
													{/* @ts-ignore */}
													<Navigation />
													{/* @ts-ignore */}
													<ErrorBoundary FallbackComponent={TechIssueComponent}>
														<Component {...pageProps}/>
													</ErrorBoundary>
													{/* @ts-ignore */}
													<RenderModules />
													<Footer />
												</HydrationBoundary>
											</div>
										</RouteGuard>
									</SlideoutTrayContextProvider>
								</ModalContextProvider>
								<Toaster />
							</ThemeProvider>
						</GlobalDataProvider>
					</QueryParamProvider>
				</QueryClientProvider>
			</QueryClientProvider>
		</ErrorBoundary>);
}));
App.getInitialProps = wrapper.getInitialAppProps(store => (appContext) => __awaiter(void 0, void 0, void 0, function* () {
    // this happens on page changes - it's ran on client(it's how next works)
    if (!appContext.ctx.req) {
        return { pageProps: {} };
    }
    getInitialPropsRedirect(appContext.ctx.res, appContext.router.asPath);
    // cleaning state is important step - otherwise we can leak
    // data between requests depending on lambda container state
    // probably worth to take a look at some point but nothing pressing atm
    store.dispatch(cleanState());
    const initialProps = yield NextApp.getInitialProps(appContext);
    const { ctx: { req: { headers: { 'X-Forwarded-For': clientIp, 'sec-ch-ua': userAgent, 'sec-ch-ua-mobile': userAgentMobile, 'sec-ch-ua-platform': platform, }, }, }, } = appContext;
    // appContext.ctx.req.cookies works only on localhost
    store.dispatch(setClientMetadata({
        clientIp, userAgent, userAgentMobile, platform,
    }));
    const serverSideAuth = new ServerSideAuthService(appContext, store);
    yield serverSideAuth.handleAuth();
    return Object.assign({}, initialProps);
}));
export default wrapper.withRedux(appConnector(App, styles, 'MuiApp'));
