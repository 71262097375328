import { GET_DASHBOARD_CHARTS_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes, userGroups, } from 'root/src/shared/constants/auth';
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { graphsData } from 'root/src/shared/descriptions/endpoints/recordTypes';
const payloadSchema = {
    type: 'object',
    properties: {
        from: { type: 'string' },
        to: { type: 'string' },
        mnemonicIds: {
            type: 'array',
            items: { type: 'string' },
        },
    },
    additionalProperties: false,
};
const responseSchema = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            data: {
                type: 'object',
                properties: {
                    followerCount: { type: 'integer' },
                    daresCompletedCount: { type: 'integer' },
                    streamsPostsCount: { type: 'integer' },
                    tiktokViews: { type: 'integer' },
                    youtubeViews: { type: 'integer' },
                    viewMinutes: { type: 'number' },
                    creatorsCount: { type: 'integer' },
                },
            },
            timestamp: { type: 'string' },
        },
    },
};
export const getDashboardChartsDataEndpoint = {
    [GET_DASHBOARD_CHARTS_DATA]: {
        endpointType: recordEndpointType,
        recordType: graphsData,
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brandUser,
        payloadSchema,
        responseSchema,
    },
};
