export const events = {
    signUpWithEmail: 'sign_up_with_email',
    channelConnected: {
        google: 'channel_connected_google',
        tiktok: 'channel_connected_tiktok',
        twitch: 'channel_connected_twitch',
    },
    profileEdit: 'profile_edit',
    acceptedViewerDare: 'accepted_viewer_dare',
    acceptedDropDare: 'accepted_drop_dare',
    deliveredDare: 'delivered_dare',
    pointsCashOut: 'points_cash_out',
};
