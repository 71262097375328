import is from 'ramda/src/is'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import { generalApiModuleDescriptionLenses } from 'root/src/client/logic/api/lenses'

const { viewListPayload } = generalApiModuleDescriptionLenses

export const moduleEndpointIdSelectorHof =	moduleDescriptionsObj => (state, { moduleId }) => {
	const payload = viewListPayload(moduleId, moduleDescriptionsObj)
	return is(Function, payload) ? payload(state) : payload
}

export default moduleEndpointIdSelectorHof(moduleDescriptions)
