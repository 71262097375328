import { NO_ACTIVE_CAMPAIGN_PAGE_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import ddLogo from 'root/src/client/public/DDlogoWhiteBigTransparent.png'

export default {
	[NO_ACTIVE_CAMPAIGN_PAGE_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			image: ddLogo,
			paragraphs: [
				'Only publishers with active campaign can access this page.',
			],
		},
	},
}
