import DareDropLogo from 'root/src/client/public/logo-daredrop0.png';
import Link from 'next/link';
import { useSlideoutTrayContext } from 'root/src/client/v2/common/contexts/slideoutTray.context';
import { twMerge } from 'tailwind-merge';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
export function SlideoutTray() {
    const { content, isVisible, slideoutTrayRef } = useSlideoutTrayContext();
    return (<AnimatePresence>
			{isVisible && (<motion.div ref={slideoutTrayRef} initial={{ left: '-570px' }} animate={{ left: '0px' }} exit={{ left: '-570px' }} className={twMerge('fixed top-0 w-[570px] bg-dark-4 h-[100vh] z-[9999]')}>
					<div className="flex h-full w-full pb-4 pt-2 flex-col justify-start">
						<HeaderSlideoutTray />
						<div className="max-h-[90%]">
							{content}
						</div>
					</div>
				</motion.div>)}
		</AnimatePresence>);
}
function HeaderSlideoutTray() {
    const { hide } = useSlideoutTrayContext();
    return (<div className="flex items-center justify-center max-h-[10%]">
			<Link href="/drops">
				<img className="cursor-pointer max-w-full mb-2" src={DareDropLogo.src} height="54" width="197" alt="DareDropLogo"/>
			</Link>
			<div className="absolute flex items-center justify-center w-[50px] h-[50px] right-4 cursor-pointer rounded-full transition hover:bg-dark-2" onClick={hide}>
				<FontAwesomeIcon size="lg" icon={faChevronLeft}/>
			</div>
		</div>);
}
