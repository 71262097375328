import { SET_USER_NOTIFICATION_SETTINGS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import setUserNotificationSettingsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/setUserNotificationSettingsResponseSchema'
import setUserNotificationSettingsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setUserNotificationSettingsPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

const responseSchema = setUserNotificationSettingsResponseSchema
const payloadSchema = setUserNotificationSettingsPayloadSchema

export default {
	[SET_USER_NOTIFICATION_SETTINGS]: {
		endpointType: userDataEndpointType,
		payloadSchema,
		responseSchema,
		authentication: authenticationTypes.authenticated,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
