import { GET_COUPONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { coupon } from 'root/src/shared/descriptions/endpoints/recordTypes'

import getCouponsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/getCouponsResponseSchema'

export const payloadSchema = {}
export const responseSchema = getCouponsResponseSchema

export default {
	[GET_COUPONS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: listEndpointType,
		recordType: coupon,
		payloadSchema,
		responseSchema,
	},
}
