import { ADD_COMMENT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import payloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addCommentPayloadSchema'
import responseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/commentResponseSchema'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { comment } from 'root/src/shared/descriptions/endpoints/recordTypes'

import { authenticationTypes } from 'root/src/shared/constants/auth'
import {
	VALIDATE_IP_ADDRESS,
	VALIDATE_TOKEN_ACCOUNT,
} from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[ADD_COMMENT]: {
		authentication: authenticationTypes.authenticated,
		endpointType: recordEndpointType,
		recordType: comment,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT, VALIDATE_IP_ADDRESS],
	},
}
