import React from 'react'

export default ({ gradient: Gradient }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
		<Gradient />
		<path
			fill={Gradient ? 'url(#hover-gradient)' : '#fff'}
			fillRule="evenodd"
			d="M14 16H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2zm-7.856-3.936c3.772 0 5.836-3.128 5.836-5.836 0-.088 0-.176-.004-.264.4-.288.748-.652 1.024-1.064a4.157 4.157 0 0 1-1.18.324c.424-.252.748-.656.904-1.136a4.08 4.08 0 0 1-1.304.496 2.054 2.054 0 0 0-3.496 1.872 5.825 5.825 0 0 1-4.228-2.144 2.057 2.057 0 0 0 .636 2.74 2.075 2.075 0 0 1-.928-.256v.028c0 .992.708 1.824 1.644 2.012a2 2 0 0 1-.54.072c-.132 0-.26-.012-.384-.036a2.051 2.051 0 0 0 1.916 1.424 4.114 4.114 0 0 1-3.036.852 5.834 5.834 0 0 0 3.14.916z"
		/>
	</svg>
)
