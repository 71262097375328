import {
	ACCOUNT_SETTINGS_MODULE_ID,
	GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import {
	CHANGE_PASSWORD_FORM_ROUTE_ID,
	CONNECTED_CHANNELS_ROUTE_ID,
	MANAGE_PAYOUT_ROUTE_ID,
	CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID,
	BRAND_SETTINGS_ROUTE_ID,
	BRAND_PRODUCTS_AND_KEYS_ROUTE_ID,
	SET_EMAIL_FORM_ROUTE_ID,
	AGENCY_PUBLISHERS_LIST_ROUTE_ID,
	AGENCY_ACCOUNT_MANAGERS_LIST_ROUTE_ID,
	PUBLISHER_NOTIFICATION_SETTINGS_ROUTE_ID,
	WHITELISTED_CREATORS_ROUTE_ID,
	PUBLISHER_BAN_LIST_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { userGroups } from 'root/src/shared/constants/auth'
import isOidcAccountSelector from 'root/src/client/logic/auth/selectors/isOidcAccountSelector'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[ACCOUNT_SETTINGS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'accountSettings',
		pageContent: {
			buttons: [
				{
					text: 'Publisher Settings',
					authenticationRole: userGroups.brandUser,
					routeId: BRAND_SETTINGS_ROUTE_ID,
				},
				{
					text: 'Keys & Products',
					authenticationRole: userGroups.brandUser,
					routeId: BRAND_PRODUCTS_AND_KEYS_ROUTE_ID,
				},
				{
					text: 'Add Campaign',
					authenticationRole: userGroups.brandUser,
					routeId: routeUrls.addCampaign(),
				},
				{
					text: 'Connected Channels',
					authenticationRole: userGroups.viewer,
					routeId: CONNECTED_CHANNELS_ROUTE_ID,
				},
				{
					text: 'OBS Integrations',
					authenticationRole: userGroups.viewer,
					routeId: CREATOR_DATA_AUDIO_ALERTS_OUTPUT_ROUTE_ID,
				},
				{
					text: 'Payout Method',
					authenticationRole: userGroups.viewer,
					routeId: MANAGE_PAYOUT_ROUTE_ID,
				},
				{
					text: 'Email Settings',
					buttonSelector: isOidcAccountSelector,
					routeId: SET_EMAIL_FORM_ROUTE_ID,
				},
				{
					text: 'Publisher list',
					authenticationRole: userGroups.agencyAccountManager,
					routeId: AGENCY_PUBLISHERS_LIST_ROUTE_ID,
				},
				{
					text: 'Account managers list',
					authenticationRole: userGroups.agency,
					routeId: AGENCY_ACCOUNT_MANAGERS_LIST_ROUTE_ID,
				},
				{
					text: 'Whitelisted Creators',
					authenticationRole: userGroups.brandUser,
					routeId: WHITELISTED_CREATORS_ROUTE_ID,
				},
				{
					text: 'Banned Creators',
					authenticationRole: userGroups.brand,
					routeId: PUBLISHER_BAN_LIST_ROUTE_ID,
				},
				{
					text: 'Change Password',
					routeId: CHANGE_PASSWORD_FORM_ROUTE_ID,
				},
				{
					text: 'Game Platforms',
					authenticationRole: userGroups.creator,
					modalId: GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID,
				},
				{
					text: 'Notification Settings',
					authenticationRole: userGroups.brandUser,
					routeId: PUBLISHER_NOTIFICATION_SETTINGS_ROUTE_ID,
				},
			],
		},
	},
}
