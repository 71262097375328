import pipe from 'ramda/src/pipe'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'
import { groupsPrecedence, userGroups } from 'root/src/shared/constants/auth'
import getPrecedence from 'root/src/shared/util/getPrecedence'

const { pathOrGroups } = appStoreLenses

export default pipe(
	pathOrGroups([userGroups.user]),
	getPrecedence(groupsPrecedence),
)
