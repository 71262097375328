import ReactGA from 'react-ga4'

const init = () => {
	// eslint-disable-next-line no-undef
	ReactGA.initialize(GOOGLE_TAG, {
		gaOptions: {
			cookieFlags: 'SameSite=None;Secure',
		},
	})

	return {
		reactGa: ReactGA,
		ga: ReactGA.ga,
	}
}

export const googleAnalyticsHof = initFn => async () => {
	/* eslint-disable no-undef */
	const { ga } = initFn()
	if (OPTIMIZE_TAG) {
		ga('create', GOOGLE_TAG, 'auto', 'optimize')
		ga('optimize.require', OPTIMIZE_TAG)
	}
	/* eslint-enable no-undef */
}

export const gaPageviewHof = initFn => (url, cb) => new Promise(async (resolve) => {
	const { ga } = initFn()
	ga('send', {
		hitType: 'pageview',
		page: url,
		hitCallback: () => {
			if (cb) {
				cb()
			}
			resolve()
		},
	})
})

export const gaSendEventHof = initFn => (val, eventName, label) => {
	const { reactGa } = initFn()
	// this weird operation is made because google analytics events can only handle integers
	// with this op we'll have real value convergent with expected value
	const amount = val || 0
	const floor = Math.floor(amount)
	const rest = amount - floor
	const randomNum = Math.random()
	const value = randomNum < rest ? floor + 1 : floor
	reactGa.event({
		category: 'Site',
		action: eventName,
		value,
		label,
	})
}

export const gaPageview = gaPageviewHof(init)

export const gaSendEvent = gaSendEventHof(init)
export default googleAnalyticsHof(init)
