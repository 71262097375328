import find from 'ramda/src/find'
import view from 'ramda/src/view'
import toPairs from 'ramda/src/toPairs'
import reduce from 'ramda/src/reduce'
import prop from 'ramda/src/prop'
import assoc from 'ramda/src/assoc'
import addIndex from 'ramda/src/addIndex'
import isEmpty from 'ramda/src/isEmpty'
import parse from 'qs/lib/parse'

import routes from 'root/src/shared/descriptions/routes'
import createRouteUrlRegexes from 'root/src/client/logic/route/util/createRouteUrlRegexes'
import {
	regexLens, regexKeysLens, routeIdKey, routeParamsKey, routeQueryKey, routeHashKey,
} from 'root/src/client/logic/route/lenses'

const matchQuery = string => string.match(/\?(.*?)(#|$)/) || []
const matchHash = string => string.match(/#(.*?)(\?|$)/) || []
const matchPath = string => string.match(/(\/.*?)(\?|#|$)/) || []

export const findRouteProps = (routeRegexes, urlPath) => {
	const path = matchPath(urlPath)[1]
	let foundRouteParams = []
	const foundRoutePair = find(
		(pathRegex) => {
			// Don't try to collapse this fn
			foundRouteParams = view(
				regexLens, prop(1, pathRegex),
			).exec(path)
			return foundRouteParams
		},
		toPairs(routeRegexes),
	)
	if (!foundRoutePair) {
		return undefined
	}

	const routeParams = addIndex(reduce)(
		(result, paramKey, i) => {
			const value = prop(i + 1, foundRouteParams)
			return value
				? assoc(prop('name', paramKey), value, result) : result
		},
		{},
		view(regexKeysLens, prop(1, foundRoutePair)),
	)

	return { foundRoutePair, routeParams }
}

export const matchPathHof = (allRoutes) => {
	const routeRegexes = createRouteUrlRegexes(allRoutes)

	return (urlPath) => {
		if (!urlPath) return undefined

		const routeProps = findRouteProps(routeRegexes, urlPath)

		if (!routeProps) {
			return undefined
		}

		const { foundRoutePair, routeParams } = routeProps
		const query = matchQuery(urlPath)[1]
		const hash = matchHash(urlPath)[1]
		const parsedQuery = parse(query)
		return {
			[routeIdKey]: prop(0, foundRoutePair),
			[routeParamsKey]: routeParams,
			...(isEmpty(parsedQuery) ? {} : { [routeQueryKey]: parsedQuery }),
			...(isEmpty(hash) ? {} : { [routeHashKey]: hash }),
		}
	}
}

export default matchPathHof(routes)
