import { isDateValid } from 'root/src/shared/util/isDateValid';
import { Encode, Decode } from 'root/src/client/v2/queryParams/helpers/serialize.helper';
export function JsonQueryParam(params) {
    return {
        encode: Encode.json,
        decode: decodeWithDefault(params, Decode.json),
        default: params === null || params === void 0 ? void 0 : params.default,
    };
}
export function StringQueryParam(params) {
    return {
        encode: Encode.string,
        decode: decodeWithDefault(params, Decode.string),
        default: params === null || params === void 0 ? void 0 : params.default,
    };
}
export function NumberQueryParam(params) {
    return {
        encode: Encode.string,
        decode: decodeWithDefault(params, Decode.number),
        default: params === null || params === void 0 ? void 0 : params.default,
    };
}
export function BooleanQueryParam(params) {
    return {
        encode: Encode.string,
        decode: decodeWithDefault(params, Decode.boolean),
        default: params === null || params === void 0 ? void 0 : params.default,
    };
}
export function DateQueryParam(params) {
    const dateValidator = withDefaultValidator(isDateValid, params === null || params === void 0 ? void 0 : params.validator);
    return {
        encode: Encode.string,
        decode: decodeWithDefault({ default: params === null || params === void 0 ? void 0 : params.default, validator: dateValidator }, Decode.string),
        default: params === null || params === void 0 ? void 0 : params.default,
    };
}
const withDefaultValidator = (defaultValidator, validator) => {
    return (value) => {
        if (!validate(defaultValidator, value)) {
            return false;
        }
        if (!validator) {
            return true;
        }
        return validate(validator, value);
    };
};
const decodeWithDefault = (params = {}, decoder) => {
    return (value) => {
        try {
            if (!value) {
                return params.default;
            }
            const decodedValue = decoder(value);
            if (!params.validator) {
                return decodedValue;
            }
            if (validate(params.validator, decodedValue)) {
                return decodedValue;
            }
            return params.default;
        }
        catch (e) {
            console.error(e);
            return params.default;
        }
    };
};
export const validate = (validator, value) => {
    if (!isZodSchema(validator)) {
        return validator(value);
    }
    try {
        validator.parse(value);
        return true;
    }
    catch (e) {
        return false;
    }
};
const isZodSchema = (validator) => {
    return 'parse' in validator;
};
