import { CREATORS_PAGE_HEAD_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import { SIGN_UP_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { ltGtMediaQuery } from 'root/src/client/web/commonStyles'
import Creators1Blob from 'root/src/client/public/backgroundBlobs/for-creators-1.svg'
import path from 'ramda/src/path'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[CREATORS_PAGE_HEAD_SECTION]: {
		moduleType: 'static',
		staticPageType: 'section',
		backgroundData: [
			{
				position: 'left',
				image: Creators1Blob,
				style: {
					transform: 'translate(0, -120px)',
					[ltGtMediaQuery]: {
						transform: 'translate(0%, -46%)',
					},
				},
			},
		],
		pageContent: {
			heading: 'Complete Dares, Earn Rewards for Your Community',
			text: 'Take on dares and challenges to earn game keys, swag, and in-game content for you and your audience.',
			position: 'right',
			buttons: [
				{
					label: 'Claim Your First Dare',
					routeId: (state) => {
						const isAuthenticated = path(['app', 'authentication'], state)
						return isAuthenticated ? routeUrls.drops() : SIGN_UP_ROUTE_ID
					},
					icon: faChevronRight,
				},
			],
		},
	},
}
