import { PAYOUT_ADMIN_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { listType, payoutListType } from 'root/src/shared/constants/moduleTypes'
import { GET_PAYOUT_ADMIN } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VIEW_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[PAYOUT_ADMIN_LIST_MODULE_ID]: {
		moduleType: listType,
		listType: payoutListType,
		listTitle: 'Payouts list',
		listPayload: { currentPage: 1 },
		recordType: project,
		endpointId: [GET_PAYOUT_ADMIN],
		listRouteId: VIEW_PROJECT_ROUTE_ID,
		pageItemLength: 8,
		goTopButton: true,
	},
}
