export default {
	type: 'object',
	properties: {
		limit: {
			type: 'number',
		},
		startAt: {
			type: 'string',
		},
		filterName: {
			type: 'string',
		},
		filterValue: {
			type: 'string',
		},
	},
}
