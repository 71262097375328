import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { GET_BRAND_CODES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { dropCode } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { getBrandRewardsPayloadSchema } from 'root/src/shared/rewards/schemas/getBrandRewards.payload.schema'
import { getBrandRewardsResponseSchema } from 'root/src/shared/rewards/schemas/getBrandRewards.response.schema'

export default {
	[GET_BRAND_CODES]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: listEndpointType,
		recordType: dropCode,
		payloadSchema: getBrandRewardsPayloadSchema,
		responseSchema: getBrandRewardsResponseSchema,
	},
}
