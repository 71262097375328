import reduxConnector from 'root/src/shared/util/reduxConnector'

import navigationSelector from 'root/src/client/logic/app/selectors/navigationSelector'
import dispatcher from 'root/src/client/logic/app/thunks/dispatcher'

export default reduxConnector(
	[
		['navigation', navigationSelector],
	],
	[['dispatch', dispatcher]],
)
