import moduleDescriptions from 'root/src/shared/descriptions/modules'
import {
	generalApiModuleDescriptionLenses,
} from 'root/src/client/logic/api/lenses'
import path from 'ramda/src/path'

const { viewEndpointId } = generalApiModuleDescriptionLenses

export default (state, { moduleId }) => (
	path(['tabs', moduleId, 'endpointId'], state)
	|| viewEndpointId(moduleId, moduleDescriptions)
)
