import { GET_TWITCH_BANNED_USERS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { topContributors } from 'root/src/shared/descriptions/endpoints/recordTypes'
import getTwitchBannedUsersPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getTwitchBannedUsersPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export const responseSchema = 'schema'
export const payloadSchema = getTwitchBannedUsersPayloadSchema

export default {
	[GET_TWITCH_BANNED_USERS]: {
		endpointType: recordEndpointType,
		authentication: authenticationTypes.authenticated,
		recordType: topContributors,
		responseSchema,
		payloadSchema,
	},
}
