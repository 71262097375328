export default {
	type: 'object',
	properties: {
		lastKey: { type: 'string' },
		limit: { type: 'integer' },
		index: { type: 'integer' },
		created: { type: 'string' },
	},
	additionalProperties: false,
}
