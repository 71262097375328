import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { dropReportsEndpointIds } from 'root/src/shared/dropReports/dropReports.constants';
const generateDropReportPayloadSchema = {
    type: 'object',
    properties: {
        dropId: { type: 'string' },
        to: { type: 'string' },
        from: { type: 'string' },
        excludeWhitelistedCreators: { type: 'boolean' },
        onlyEarnedPointsCreators: { type: 'boolean' },
        isReportByGame: { type: 'boolean' },
    },
    required: ['dropId'],
    additionalProperties: false,
};
const generateDropReportResponseSchema = {
    type: 'array',
    items: { type: 'string' },
    required: [],
    additionalProperties: false,
};
export const generateDropReportEndpoint = {
    [dropReportsEndpointIds.GENERATE_DROP_REPORT]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: [userGroups.admin, userGroups.brand],
        endpointType: otherEndpointType,
        payloadSchema: generateDropReportPayloadSchema,
        responseSchema: generateDropReportResponseSchema,
    },
};
