import {
	darePlatformOptions,
	dropDareDifficultyLevelEnum,
	dropDareTypeEnum,
	pointsCalculationMethodEnum,
	rewardForOptions,
} from 'root/src/shared/constants/dropEvent'
import {
	urlPattern,
	youtubeUrlPattern,
	emptyRe } from 'root/src/shared/descriptions/formSchemas/addDropEventSchema'
import { RewardType } from 'root/src/shared/rewards/constants'

export const GAME_DESCRIPTION_MAX_LENGTH = 1000

export const DARE_HINT_MAX_LENGTH = 110
export const DARE_DESCRIPTION_MAX_LENGTH = 110

export default {
	type: 'object',
	properties: {
		dropEventPayload: {
			type: 'object',
			properties: {
				dropTitle: {
					type: 'string',
					errorMessage: {
						required: 'Please enter drop title',
					},
				},
				campaignName: {
					type: 'string',
					errorMessage: {
						required: 'Please enter your campaign name',
					},
				},
				gameObj: {
					type: 'object',
					items: {
						type: 'object',
						properties: {
							id: { type: 'integer' },
							label: { type: 'string' },
							value: { type: 'integer' },
							image: { type: 'string' },
						},
						required: ['id'],
					},
					errorMessage: {
						required: 'Please select game',
					},
				},
				liveDate: {
					type: 'string',
					errorMessage: {
						required: 'Please select a campaign start date',
					},
				},
				messageToCreator: { type: 'string' },
				initialProduct: { type: ['array', 'null'] },
				isGameFree: { type: 'boolean' },
				similarGames: {
					type: 'array',
					minItems: 0,
					maxItems: 10,
					uniqueItems: true,
					items: {
						type: 'object',
						properties: {
							id: { type: 'number' },
							value: { type: 'number' },
							label: { type: 'string' },
							image: { type: 'string' },
						},
						required: ['id'],
					},
					errorMessage: {
						required: 'Please select similar games',
					},
				},
				brandLogoUrl: {
					type: ['string', 'object'],
				},
				assets: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							fileHash: { type: 'string' },
							fileName: { type: 'string' },
							url: { type: 'string' },
						},
					},
					minItems: 0,
					maxItems: 10,
					required: ['fileHash', 'fileName', 'url'],
				},
				youtubeURL: {
					type: 'string',
					pattern: youtubeUrlPattern,
					errorMessage: {
						pattern: 'Please enter valid YouTube video URL.',
						required: 'Please enter YouTube video URL.',
					},
				},
				gameDescriptionBody: {
					type: 'string',
					maxLength: GAME_DESCRIPTION_MAX_LENGTH,
					errorMessage: {
						maxLength: `Game description shouldn't be longer than ${GAME_DESCRIPTION_MAX_LENGTH} characters.`,
						required: 'Please enter game description.',
					},
				},
				cta: {
					type: 'string',
					errorMessage: {
						required: 'Please enter CTA.',
					},
				},
				url: {
					type: 'object',
					properites: {
						value: {
							type: 'string',
							pattern: `${emptyRe}|${urlPattern}`,
							maxLength: 150,
							errorMessage: {
								pattern: 'Must be proper url',
								maxLength: 'max: 150 characters',
							},
						},
						custom: 'boolean',
					},
				},
				shortUrl: {
					type: 'string',
					pattern: urlPattern,
					errorMessage: {
						pattern: 'Please enter valid URL.',
					},
				},
				followersBreakpoints: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							platform: { type: 'string', enum: ['youtube', 'tiktok'] },
							followerCount: { type: 'integer' },
							maxDares: { type: 'integer' },
						},
						required: ['platform', 'followerCount', 'maxDares'],
					},
				},
				ccvBreakpoints: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							ccv: { type: 'integer' },
							maxDares: { type: 'integer' },
						},
						required: ['ccv', 'maxDares'],
					},
				},
				dares: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							title: { type: 'string' },
							description: { type: 'string', maxLength: 110 },
							type: {
								type: 'string',
								enum: Object.values(dropDareTypeEnum),
							},
							level: {
								type: 'string',
								enum: Object.values(dropDareDifficultyLevelEnum),
							},
							product: {
								type: 'object',
								properties: {
									id: { type: 'string' },
									name: { type: 'string' },
									type: {
										type: 'string',
										enum: [
											RewardType.KEY,
											RewardType.CODE,
											RewardType.CUSTOM,
											RewardType.BUNDLE,
											RewardType.PLACEHOLDER,
										],
									},
								},
								required: ['id'],
							},
							game: {
								type: 'object',
								properties: {
									label: { type: 'string' },
									id: { type: 'integer' },
								},
								required: ['id'],
							},
							amount: { type: 'integer' },
							rewardsReserved: { type: 'integer' },
							hint: {
								type: 'string',
								maxLength: 110,
							},
							rewardFor: {
								type: 'string',
								enum: rewardForOptions.map(option => option.value),
							},
							estimatedTime: {
								type: 'string',
							},
						},
						platform: {
							type: 'string',
							enum: darePlatformOptions.map(option => option.value),
						},
						expirationDate: { type: 'string' },
						required: ['title', 'description', 'product', 'amount', 'type', 'level'],
					},
				},
				campaignThumbnail: {
					type: ['string', 'object'],
				},
				campaignProduct: {
					type: 'string',
					errorMessage: {
						required: 'Please enter your product name.',
					},
				},
				isMultiGame: { type: 'boolean' },
				awardPoints: { type: 'boolean' },
				pointsLimit: { type: 'number' },
				pointsAwarded: { type: 'number' },
				isPrivate: { type: 'boolean' },
				pointsCalculationMethod: {
					type: 'string',
					enum: [pointsCalculationMethodEnum.DEFAULT, pointsCalculationMethodEnum.CPVH],
				},
				costPerViewHourPoints: { type: 'number', min: 0, max: 500 },
			},
			required: ['campaignName'],
			additionalProperties: false,
		},
		publish: { type: 'boolean' },
	},
}
