import { UPDATE_COUPON } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { coupon } from 'root/src/shared/descriptions/endpoints/recordTypes'

import updateCouponPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/addCouponResponseSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = updateCouponPayloadSchema
export const responseSchema = updateCouponPayloadSchema

export default {
	[UPDATE_COUPON]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: recordEndpointType,
		recordType: coupon,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
