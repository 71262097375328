import compose from 'ramda/src/compose'
import dissocPath from 'ramda/src/dissocPath'
import filter from 'ramda/src/filter'
import equals from 'ramda/src/equals'
import lensPath from 'ramda/src/lensPath'
import not from 'ramda/src/not'
import pathOr from 'ramda/src/pathOr'
import length from 'ramda/src/length'
import add from 'ramda/src/add'
import over from 'ramda/src/over'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import propEq from 'ramda/src/propEq'
import { DELETE_COMMENT } from 'root/src/client/logic/comment/actionIds'
import { commentKey, commentAmountKey, messageIdKey } from 'root/src/shared/constants/keys'
import getIdFromKey from 'root/src/shared/util/getIdFromKey'

const {	viewCommentChild } = apiStoreLenses

export const deleteComment = (state, payload) => {
	const { messageId, listStoreKey, projectUrl, messageType, parentId } = payload
	const commentAmountLens = lensPath(['api', 'records', 'project', projectUrl, commentAmountKey])
	const commentsPath = ['api', 'records', commentKey]
	if (messageType === commentKey) {
		const repliesLength = length(pathOr([], [...commentsPath, messageId, 'replies'], state))
		const listLens = lensPath(['api', 'lists', listStoreKey, 'items'])
		return compose(
			over(listLens, filter(compose(not, equals(messageId)))),
			over(commentAmountLens, add(-1 - repliesLength)),
			dissocPath([...commentsPath, messageId]),
		)(state)
	}
	const commentId = getIdFromKey(parentId)
	const { replies } = viewCommentChild(commentId, state)
	const replyIndex = replies.findIndex(propEq(messageIdKey, messageId))
	const repliesAmountLens = lensPath([...commentsPath, commentId, 'repliesAmount'])

	return compose(
		over(commentAmountLens, add(-1)),
		over(repliesAmountLens, add(-1)),
		dissocPath([...commentsPath, commentId, 'replies', replyIndex]),
	)(state)
}

export default {
	[DELETE_COMMENT]: deleteComment,
}
