import { memo, useEffect } from 'react'
import { addTrack } from 'root/src/client/logic/analytics/pixel/facebookPixel'
import googleAnalytics, { gaPageview } from 'root/src/client/logic/analytics/googleAnalytics'
import twitterPixel from 'root/src/client/logic/analytics/pixel/twitterPixel'
import googlePixel from 'root/src/client/logic/analytics/pixel/googlePixel'
import isNil from 'ramda/src/isNil'
import path from 'ramda/src/path'
import { mixpanelInit } from 'root/src/client/logic/analytics/mixpanel'
import analyticsConnector from 'root/src/client/logic/app/connectors/analyticsConnector'
import routeDescriptions from 'root/src/shared/descriptions/routes'
import { VIEWERS_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

const Analytics = memo(({ router, setDeviceId, setExperimentVariant, deviceId }) => {
	useEffect(() => {
		twitterPixel()
		googlePixel()
		googleAnalytics()
		mixpanelInit(setDeviceId)
	}, [])

	useEffect(() => {
		if (!isNil(deviceId)
		&& router.pathname === path([VIEWERS_PAGE_ROUTE_ID, 'url'], routeDescriptions)) {
			(async () => {
				const runExperiment = await import('root/src/client/logic/analytics/growthBook')
				runExperiment.default(deviceId, setExperimentVariant)
			})()
		}
	}, [deviceId, router])

	useEffect(() => {
		const handleRouteChange = (url) => {
			const cb = router.query.fromPanel === 'true'
				? window.close
				: null
			addTrack('PageView')
			gaPageview(url, cb)
		}
		handleRouteChange(router.asPath)
		router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router.events])

	return null
})

export default analyticsConnector(Analytics)
