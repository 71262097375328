import { useUserData } from 'root/src/client/v2/userData/hooks/useUserData';
export function useHasTokens() {
    const { userData } = useUserData();
    if (!Array.isArray(userData === null || userData === void 0 ? void 0 : userData.tokens)) {
        return { hasTokens: false };
    }
    // TODO maybe add loading from user data here?
    const hasTokens = userData.tokens.length > 0;
    return {
        hasTokens,
    };
}
