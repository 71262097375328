export const sidebarPosition = Object.freeze({
	LEFT: 'LEFT',
	RIGHT: 'RIGHT',
})

export const likeEnum = Object.freeze({
	LIKE: 'LIKE',
	DISLIKE: 'DISLIKE',
})

export const likeStatusEnum = Object.freeze({
	LIKED: 'LIKED',
	DISLIKED: 'DISLIKED',
	NEUTRAL: 'NEUTRAL',
})

export const auditEnum = Object.freeze({
	ACTIVATE: 'ACTIVATE',
	DEACTIVATE: 'DEACTIVATE',
})

export const creatorTiersEnum = {
	CREATOR: 'Creator',
	AFFILIATE: 'Affiliate',
	PARTNER: 'Partner',
	ELITE: 'Elite',
}

export const statusEnum = Object.freeze({
	ENABLE: 'ENABLE',
	DISABLE: 'DISABLE',
})

export const sortTimeEnum = {
	THREEDAYS: 3,
	SEVENDAYS: 7,
	LASTMONTH: 31,
	LIFETIME: 9999,
}
