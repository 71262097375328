import {
	AUDIT_FOLLOW_CREATOR,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { creatorData } from 'root/src/shared/descriptions/endpoints/recordTypes'

import auditFollowCreatorPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/auditFollowCreatorPayloadSchema'

export const payloadSchema = auditFollowCreatorPayloadSchema

export default {
	[AUDIT_FOLLOW_CREATOR]: {
		authentication: authenticationTypes.optional,
		endpointType: recordEndpointType,
		recordType: creatorData,
		payloadSchema,
	},
}
