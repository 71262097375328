import { UPDATE_PREFERRED_PLATFORM } from 'root/src/client/logic/userData/actionIds'
import compose from 'ramda/src/compose'
import over from 'ramda/src/over'
import lensPath from 'ramda/src/lensPath'
import tokensSelector from 'root/src/client/logic/api/selectors/tokensSelector'
import map from 'ramda/src/map'
import pipe from 'ramda/src/pipe'
import assoc from 'ramda/src/assoc'

export default {
	[UPDATE_PREFERRED_PLATFORM]: (state, payload) => {
		const { platform: selectedPlatform } = payload
		const tokens = pipe(
			tokensSelector,
			map(({ platform, ...token }) => ({
				...token,
				platform,
				isPreferred: selectedPlatform === platform,
			})),
		)(state)

		return compose(
			over(lensPath(['app', 'userData']), assoc('tokens', tokens)),
		)(state)
	},
}
