import pipe from 'ramda/src/pipe'
import assocPath from 'ramda/src/assocPath'
import dropAddEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/dropAddEventPayloadSchema'
import {
	Platform,
} from 'root/src/shared/@types/platforms'
import { BATTLE_PASS_MAX_TIER, BATTLE_PASS_MIN_TIER } from 'root/src/shared/battlePass/fixtures/battlePass.schema'

export default pipe(
	assocPath(['properties', 'dropEventPayload', 'properties', 'id'], { type: 'string' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'budget'], { type: 'object' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'mnemonicId'], { type: 'string' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'isGameFree'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'isMultiGame'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'isPrivate'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'isUserActivated'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'awardPoints'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'pointsApplicationsEnabled'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'pointsReservationHours'], { type: 'number' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'isCommunityDrop'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'daresCountResetDate'], { type: 'string' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'expirationDate'], { type: 'string' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'disableUserHasGame'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'pointsLimit'], { type: 'number' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'enableAutoApprove'], { type: 'boolean' }),
	assocPath(['properties', 'dropEventPayload', 'properties', 'featuredCreator'], {
		type: ['object', 'null'],
		properties: {
			platform: { type: 'string' },
			platformId: { type: 'string' },
			mnemonicId: { type: 'string' },
			displayName: { type: 'string' },
		},
		required: ['platform', 'platformId', 'mnemonicId', 'displayName'],
	}),
	assocPath(['properties', 'dropEventPayload', 'properties', 'expirationReminderDate'], {
		type: 'string',
	}),
	assocPath(['properties', 'dropEventPayload', 'properties', 'rewardScalability'], {
		type: 'object',
		properties: {
			enabled: { type: 'boolean' },
			rewards: {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						rewardId: { type: 'string' },
						increments: {
							type: 'array',
							items: {
								type: 'object',
								properties: {
									value: { type: 'number' },
									platform: { type: 'string', enum: Object.values(Platform) },
								},
								required: ['value', 'platform'],
							},
						},
					},
					required: ['rewardId', 'increments'],
				},
			},
		},
	}),
	assocPath(['properties', 'dropEventPayload', 'required'], ['id']),
	assocPath(['properties', 'dropEventPayload', 'properties', 'dares', 'items', 'properties', 'battlePass'], {
		type: 'object',
		properties: {
			id: {
				type: 'string',
			},
			tier: {
				type: 'integer',
				min: BATTLE_PASS_MIN_TIER,
				max: BATTLE_PASS_MAX_TIER,
			},
		},
		required: ['id', 'tier'],
	}),
)(dropAddEventPayloadSchema)
