import stepsCompletedSelector from 'root/src/client/logic/static/selectors/stepsCompletedSelector'
import setStepsCompleted from 'root/src/client/logic/app/actions/setStepsCompleted'
import prop from 'ramda/src/prop'

export default (step, dispatch, getState) => {
	const stepsCompleted = stepsCompletedSelector(getState())
	if (!prop(step, stepsCompleted)) {
		dispatch(setStepsCompleted({ ...stepsCompleted, [step]: true }))
	}
}
