import { AGENCY_PUBLISHERS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import switchAgencyPublisher from 'root/src/client/logic/brandSettings/thunks/switchAgencyPublisher'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'
import managePublisher from 'root/src/client/logic/agency/thunks/managePublisher'
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants'

const { viewEmail } = appStoreLenses

const hideManageButton = ({ email } = {}, state) => {
	const userEmail = viewEmail(state)
	return email === userEmail
}

export default {
	[AGENCY_PUBLISHERS_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		tableColumns: [
			['Name', 'brandName'],
			['Site', 'brandWebsite'],
			['Twitter', 'brandTwitter'],
			['Manager', 'email'],
		],
		tableItemActions: [
			{
				header: 'Manage',
				name: 'Manage',
				fn: managePublisher,
				hide: hideManageButton,
			},
			{
				header: 'Switch Publisher',
				name: 'Switch',
				fn: switchAgencyPublisher,
			},
		],
		listTitle: 'Publishers',
		recordType: dropEvent,
		endpointId: [agencyEndpointIds.GET_AGENCY_PUBLISHERS_LIST],
	},
}
