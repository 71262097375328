import paymentTypes from 'root/src/shared/constants/paymentTypes'

export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		pledgeAmount: {
			type: 'integer',
			minimum: 1,
			maximum: 999999,
			errorMessage: {
				minimum: 'Pledge amount must be at least $1.',
			},
		},
		promotionAmount: { type: 'integer' },
		promotionCode: { type: 'string' },
		paymentInfo: {
			type: 'object',
			properties: {
				paymentType: {
					type: 'string',
					enum: [paymentTypes.paypalAuthorize, paymentTypes.coupon, paymentTypes.brandProduct],
				},
				paymentId: { type: 'string' },
				paymentAmount: { type: 'number' },
				paymentData: { type: 'object' },
				withCoupon: { type: 'boolean' },
				couponAmount: { type: 'integer' },
				brandProductId: { type: 'string' },
				productType: { type: 'string' },
				amount: {
					type: 'integer',
					minimum: 1,
					maximum: 99,
					errorMessage: {
						maximum: 'Cannot pledge more than 99 keys.',
					},
				},
			},
			required: ['paymentType'],
			additionalProperties: false,
		},
		paymentType: { type: 'string' },
	},
	required: ['projectUrl', 'paymentInfo'],
	additionalProperties: false,
}
