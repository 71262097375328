import { ADD_AGENCY_PUBLISHER_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { brandSettingsFields } from 'root/src/shared/descriptions/modules/brandSettingsForm'
import setBrandSettingsSchema from 'root/src/shared/descriptions/formSchemas/setBrandSettingsSchema'

export default {
	[ADD_AGENCY_PUBLISHER_FORM_MODULE_ID]: {
		moduleType: 'form',
		schema: setBrandSettingsSchema,
		title: 'Publisher Settings',
		fields: brandSettingsFields,
		submits: [
			{
				label: 'Add publisher',
				buttonType: 'primaryButton',
			},
		],
	},
}
