import mergeDeepRight from 'ramda/src/mergeDeepRight'
import { HYDRATE } from 'next-redux-wrapper'

export default {
	[HYDRATE]: (state, payload) => {
		const newState = mergeDeepRight(state, payload)
		// we're always overewriting route
		return { ...newState, route: payload.route, api: payload.api }
	},
}
