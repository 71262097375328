import { DISPLAY_MODAL } from 'root/src/client/logic/modal/actionIds'
import { modalStoreLenses } from 'root/src/client/logic/modal/lenses'
import compose from 'ramda/src/compose'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'

const { setModalModuleId, setModalProps } = modalStoreLenses

export default {
	[DISPLAY_MODAL]: (state, { props, modalModuleId, modalRouteId }) => {
		const currentRoute = currentRouteId(state)
		const modalRoute = modalRouteId || currentRoute
		return compose(
			setModalModuleId(modalRoute, modalModuleId),
			setModalProps(modalRoute, props),
		)(state)
	},
}
