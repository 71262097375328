import {	FEATURED_DARES_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import {	CREATE_PROJECT_ROUTE_ID, VIEW_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { videoCardListType } from 'root/src/shared/constants/moduleTypes'
import { GET_PROJECTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import viewersBlob1 from 'root/src/client/public/backgroundBlobs/viewers1.svg'
import { featuredDaresKey } from 'root/src/shared/constants/keys'
import { ltMdMediaQuery, ltSmMediaQuery, lgMediaQuery } from 'root/src/client/web/commonStyles'

export default {
	[FEATURED_DARES_LIST_MODULE_ID]: {
		moduleType: 'list',
		listTitle: 'Create Dares For Your Favorite Creators',
		listSubtitle: 'Be a part of your favorite creators content with your own dares and challenges. ',
		headerType: 'newDesign',
		listType: videoCardListType,
		recordType: project,
		endpointId: [GET_PROJECTS],
		backgroundData: [
			{
				position: 'right',
				image: viewersBlob1,
				style: {
					position: 'absolute',
					top: 0,
					right: '-35%',
					[ltSmMediaQuery]: {
						right: '-25%',
					},
					[ltMdMediaQuery]: {
						right: '-50%',
					},
					[lgMediaQuery]: {
						right: '-45%',
					},
				},
			},
		],
		listPayload: {
			section: featuredDaresKey,
		},
		listRouteId: VIEW_PROJECT_ROUTE_ID,
		sliderShowItems: 3,
		listControls: [
			{
				title: '+ CREATE A DARE',
				routeId: CREATE_PROJECT_ROUTE_ID,
			},
		],
	},
}
