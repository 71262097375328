import { GET_STARTED_MODULE_ID, SIGN_UP_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import { userGroups } from 'root/src/shared/constants/auth'
import { loginWith } from 'root/src/client/v2/auth/helpers/loginWith'
import providers from 'root/src/shared/constants/providers'
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok'
import { registerTerms } from 'root/src/shared/descriptions/modules/signUpModal'
import { Provider } from 'root/src/shared/@types/providers'

const validateSocialLogin = userGroup => userGroup !== userGroups.creator

export default {
	[GET_STARTED_MODULE_ID]: {
		moduleType: 'modal',
		modalContent: {
			title: 'Get started with',
			text: "Ready to experience the full power of Dare Drop?\nLet's do this!",
			buttons: [
				{
					label: 'Sign up with Twitch',
					icon: faTwitch,
					type: 'secondaryButton',
					provider: Provider.Twitch,
					onClick: loginWith(providers.twitch),
					validateFn: validateSocialLogin,
				},
				{
					label: 'Sign up with Google',
					icon: faGoogle,
					type: 'secondaryButton',
					provider: Provider.Google,
					onClick: loginWith(providers.google),
					validateFn: validateSocialLogin,
				},
				{
					label: 'Sign up with TikTok',
					icon: faTiktok,
					type: 'secondaryButton',
					provider: Provider.TikTok,
					onClick: loginWith(providers.tiktok),
					validateFn: validateSocialLogin,
				},
				{
					label: 'Sign up with email',
					icon: faEnvelope,
					iconPadding: 10,
					type: 'primary',
					onClick: ({ displayModal, checkedOption }) => displayModal(SIGN_UP_MODAL_MODULE_ID, { checkedOption }),
				},
			],
			options: [
				{
					label: 'VIEWER',
					value: userGroups.viewer,
				},
				{
					label: 'CREATOR',
					value: userGroups.creator,
				},
				{
					label: 'BRAND',
					value: userGroups.brand,
				},
			],
			fields: [
				{
					fieldId: 'email',
					label: 'Email',
					inputType: 'registerEmail',
				},
				{
					fieldId: 'password',
					label: 'Password',
					inputType: 'password',
					fieldHint: 'Must have a lower & uppercase letter, number and symbol',
				},
				{
					fieldId: 'confirmPassword',
					label: 'Confirm password',
					inputType: 'password',
				},
			],
			registerTerms,
		},
	},
}
