import { REMOVE_AUTH_TOKEN } from 'root/src/shared/descriptions/endpoints/endpointIds'

// import { userData } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = {
	type: 'object',
	properties: {
		platform: { type: 'string' },
		platformId: { type: 'string' },
	},
	required: ['platform', 'platformId'],
	additionalProperties: false,
}

export default {
	[REMOVE_AUTH_TOKEN]: {
		authentication: authenticationTypes.authenticated,
		endpointType: userDataEndpointType,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
