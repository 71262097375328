import { SUCCESS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import modalSuccessImg from 'root/src/client/public/modalSuccessImg.png'

export default {
	[SUCCESS_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalContent: {
			imgSrc: modalSuccessImg.src,
			defaultHeader: 'WELCOME TO DARE DROP!',
			defaultInfoText: 'The realm of dares awaits you!',
			defaultButtonLabel: 'CONTINUE',
		},
	},
}
