import pluck from 'ramda/src/pluck'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import { brandProductTypesEnum } from 'root/src/shared/constants/dropEvent'
import editKeysPayloadSchema from 'root/src/shared/descriptions/formSchemas/editBrandKeysSchema'
import { dropUploadKeysFields } from 'root/src/shared/descriptions/modules/dropUploadKeysForm'
import {
	DROP_KEYS_LIST_MODULE_ID,
	EDIT_BRAND_KEYS_MODAL_MODULE_ID,
	SUCCESS_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { RewardEndpoint } from 'root/src/shared/rewards/constants'

export default {
	[EDIT_BRAND_KEYS_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		schema: editKeysPayloadSchema,
		modalContent: {
			title: 'Edit keys',
			fields: dropUploadKeysFields,
			submits: [
				{
					label: 'Save',
					buttonType: 'primaryButton',
					onClick: item => async (dispatch) => {
						const { error } = await dispatch(apiRequest(RewardEndpoint.EDIT_BRAND_REWARDS, {
							rewardData: {
								...pluck('value', item),
								dlcName: item.dlcName,
								id: item.id,
								restrictions: item.restrictions,
							},
							rewardType: brandProductTypesEnum.dropKey,
						}))
						if (error) {
							dispatch(displayModal(SUCCESS_MODAL_MODULE_ID, {
								header: error.code,
								errorText: error.message,
								infoText: '',
							}))
						} else {
							dispatch(displayModal(undefined))
						}
						return dispatch(
							apiRequest(RewardEndpoint.GET_DROP_KEY_REWARDS, {}, true, DROP_KEYS_LIST_MODULE_ID),
						)
					},
					htmlType: 'submit',
				},
			],
		},
	},
}
