import { BRANDS_FEATURES_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { calendlyUrl } from 'root/src/shared/constants/pageData'
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie'
import { faFingerprint } from '@fortawesome/pro-light-svg-icons/faFingerprint'
import { faKeySkeleton } from '@fortawesome/pro-light-svg-icons/faKeySkeleton'
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode'
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons/faBullseyeArrow'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import { ourSalesEmail } from 'root/src/shared/constants/mail'

export default {
	[BRANDS_FEATURES_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'Features',
		pageContent: {
			title: 'Just as easy as it is effective',
			subtitle: 'Find out for yourself, we triple dog dare ya.',
		},
		featureItems: [
			{
				header: 'Verified Creators',
				icons: [faFingerprint],
			},
			{
				header: 'No Wasted Keys',
				icons: [faKeySkeleton],
			},
			{
				header: '6x Watch Time',
				icons: [faEye],
			},
			{
				header: 'Customer Support',
				icons: [faQuestionCircle],
			},
			{
				header: 'No Dev Work',
				icons: [faCode],
			},
			{
				header: 'Custom Targeting',
				icons: [faBullseyeArrow],
			},
			{
				header: 'Reporting',
				icons: [faChartPie],
			},
			{
				header: 'Twitch, Youtube & Facebook',
				icons: [faTwitch, faYoutube, faFacebook],
			},
		],
		buttons: [
			{
				text: 'Add Your Game to Dare Drop',
				type: 'primary',
				// TEMPORARY FIX UNTIL BRAND ONBOARDING MVP
				href: `mailto:${ourSalesEmail}`,
				// routeId: SIGN_UP_ROUTE_ID,
			},
			{
				text: 'Schedule a Demo',
				type: 'secondary',
				href: calendlyUrl,
			},
		],
	},
}
