import { VIEW_CREATOR_DATA_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { GET_CREATOR_DATA, GET_TOP_CONTRIBUTORS, GET_PROFILE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { creatorData } from 'root/src/shared/descriptions/endpoints/recordTypes'
import prop from 'ramda/src/prop'
import ifElse from 'ramda/src/ifElse'
import pipe from 'ramda/src/pipe'
import {
	VIEW_CREATOR_DATA_ROUTE_ID, VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import isAlphanumeric from 'root/src/shared/util/isAlphanumeric'
import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants'

export const creatorDataRouteMap = {
	twitch: {
		recordIdProp: ifElse(
			pipe(prop('displayName'),	isAlphanumeric),
			prop('displayName'),
			prop('mnemonicId'),
		),
		routeId: VIEW_CREATOR_DATA_ROUTE_ID,
	},
	youtube: {
		recordIdProp: prop('mnemonicId'),
		routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
	},
	tiktok: {
		recordIdProp: prop('mnemonicId'),
		routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	},
	facebook: {
		recordIdProp: prop('mnemonicId'),
		routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	},
}

export default {
	[VIEW_CREATOR_DATA_MODULE_ID]: {
		moduleType: 'record',
		recordPageType: creatorData,
		endpointId: [
			GET_CREATOR_DATA,
			GET_TOP_CONTRIBUTORS,
			GET_PROFILE,
			giveawayEndpointIds.GET_USER_GIVEAWAY_REWARDS,
			giveawayEndpointIds.GET_USER_GIVEAWAY_TERMS,
			giveawayEndpointIds.GET_USER_VOUCHERS,
		],
		recordPayloadMap: [
			['recordId', { $sub: ['recordId'] }],
		],
		redirectOn404: true,
	},
}
