import classNames from 'classnames';
import { colorsNew } from 'root/src/client/web/commonStyles';
import orEmpty from 'root/src/shared/util/orEmpty';
import Typography, { typographyVariants } from 'root/src/client/web/typography/Typography';
import { withStylesHof } from 'root/src/client/util/withStylesHof';
import React from 'react';
const style = {
    root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover > div': {
            visibility: 'visible',
            opacity: 1,
        },
    },
    tooltip: {
        visibility: 'hidden',
        backgroundColor: colorsNew.dark2,
        position: 'absolute',
        transform: 'translateY(50%)',
        minWidth: 200,
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        boxShadow: '5px 5px 10px black',
        zIndex: 999,
        margin: 10,
        padding: [[10, 5]],
    },
    visible: {
        visibility: 'visible',
        opacity: 1,
    },
};
export const tooltipPlacement = {
    left: 'left',
    right: 'right',
};
const TooltipWrapper = ({ children, tooltipText, tooltipHeader, classes, visible, displayInTable, placement, className, }) => (<div className={classNames(classes.root, className)} style={{ justifyContent: displayInTable ? 'flex-start' : 'center' }}>
		{tooltipText && (<div style={Object.assign(Object.assign({}, orEmpty(placement, { [tooltipPlacement[placement]]: 0 })), orEmpty(displayInTable, { left: 0 }))} className={classNames(classes.tooltip, 'tooltipText', visible && classes.visible)}>
				{tooltipHeader && (<Typography variant={typographyVariants.miniBold} color={colorsNew.light4}>
						{tooltipHeader}
					</Typography>)}
				<Typography variant={typographyVariants.paragraphBold}>
					{tooltipText}
				</Typography>
			</div>)}
		{children}
	</div>);
export default withStylesHof(style, { name: 'MuiTooltipWrapper' }, TooltipWrapper);
