import { ADMIN_BAN_IP_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import adminBanUserIpPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/adminBanUserIpPayloadSchema'

export default {
	[ADMIN_BAN_IP_FORM_MODULE_ID]: {
		moduleType: 'form',
		formType: 'form',
		schema: adminBanUserIpPayloadSchema,
		title: 'Ban/unban user ip',
		fields: [
			{
				fieldId: 'ip',
				inputType: 'text',
				labelFieldText: [
					{
						text: 'User IP',
						required: true,
					},
				],
			},
		],
		submits: [
			{
				label: 'Ban',
				buttonType: 'primaryButton',
				successMessage: 'Banned',
			},
			{
				label: 'Unban',
				buttonType: 'secondaryButton',
				successMessage: 'Unbanned',
			},
		],
	},
	required: ['ip'],
}
