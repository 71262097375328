import { AMBASSADOR_PROGRAM_HOW_TO_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import { CREATORS_LEADERBOARD_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import icPodium from 'root/src/client/public/icons/ic-podium'
import joinDiscord from 'root/src/client/public/join-discord.png'
import referFriends from 'root/src/client/public/refer-friends.png'
import doDares from 'root/src/client/public/do-dares.png'
import doDrops from 'root/src/client/public/do-drops.png'

export default {
	[AMBASSADOR_PROGRAM_HOW_TO_SECTION]: {
		moduleType: 'static',
		staticPageType: 'ambassadorProgram',
		pageContent: {
			sectionType: 'howTo',
			headerText: 'Earn points for more rewards',
			paragraphText: 'Complete dares, drops, and engage in the community to win rewards for you and your viewers.',
			buttons: [
				{
					label: 'View Leaderboard',
					routeId: CREATORS_LEADERBOARD_ROUTE_ID,
					type: 'primary',
					iconPosition: 'left',
					icon: icPodium,
				},
			],
			images: [
				{
					row: 1,
					src: joinDiscord.src,
					caption: 'Join Our Discord Community',
				},
				{
					row: 1,
					src: referFriends.src,
					caption: 'Refer Friends',
				},
				{
					row: 2,
					src: doDares.src,
					caption: 'Do Dares For Your Viewers',
				},
				{
					row: 2,
					src: doDrops.src,
					caption: 'Do Drops From Our Partners',
				},
			],
		},
	},
}
