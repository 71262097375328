import tail from 'ramda/src/tail'
import over from 'ramda/src/over'
import lensPath from 'ramda/src/lensPath'
import { POP_STEP_ID } from 'root/src/client/logic/form/actionIds'

export default {
	[POP_STEP_ID]: (
		state, { moduleKey },
	) => over(lensPath(['form', moduleKey, 'stepIdHistory']), tail, state),
}
