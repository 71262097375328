import {
	FILTER_DARES_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

import {
	FILTER_DARES_FORM_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[FILTER_DARES_ROUTE_ID]: {
		url: '/filter-dares',
		noSitemap: true,
		modules: [
			FILTER_DARES_FORM_MODULE_ID,
		],
	},
}
