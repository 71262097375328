export const agencyEndpointIds = {
    INVITE_AGENCY_ACCOUNT_MANAGER: 'INVITE_AGENCY_ACCOUNT_MANAGER',
    GET_AGENCY_ACCOUNT_MANAGERS: 'GET_AGENCY_ACCOUNT_MANAGERS',
    DELETE_AGENCY_ACCOUNT_MANAGER: 'DELETE_AGENCY_ACCOUNT_MANAGER',
    ADD_AGENCY_PUBLISHER: 'ADD_AGENCY_PUBLISHER',
    GET_AGENCY_PUBLISHERS: 'GET_AGENCY_PUBLISHERS',
    GET_AGENCY_PUBLISHERS_LIST: 'GET_AGENCY_PUBLISHERS_LIST',
    MANAGE_PUBLISHER_ACCOUNT: 'MANAGE_PUBLISHER_ACCOUNT',
    GET_PUBLISHER_TOKEN: 'GET_PUBLISHER_TOKEN',
};
