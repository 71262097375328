import { SEARCH_TWITCH_USERS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import searchTwitchPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/searchTwitchPayloadSchema'
import searchTwitchResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/searchTwitchResponseSchema'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[SEARCH_TWITCH_USERS]: {
		authentication: authenticationTypes.unauthenticated,
		endpointType: otherEndpointType,
		payloadSchema: searchTwitchPayloadSchema,
		responseSchema: searchTwitchResponseSchema,
	},
}
