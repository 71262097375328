import outputs from 'root/cfOutput'
import providers from 'root/src/shared/constants/providers'
import { authApiDomain } from 'root/src/shared/constants/authApi'
import { apiIdentifier } from 'root/src/shared/constants/aws'
import { POST_SIGN_UP } from 'root/src/shared/descriptions/endpoints/endpointIds'

const { userPoolId, domainName } = outputs

export const refreshTokenValidity = 365

export const authApiBase = `https://${authApiDomain}`

export const POST_SIGN_UP_ENDPOINT_URL = `https://${apiIdentifier}.${domainName}?endpointId=${POST_SIGN_UP}`

// user can have multiple user groups
export const userGroups = {
	user: 'user',
	admin: 'admin',
	superAdmin: 'superAdmin',
	agency: 'agency',
	agencyAccountManager: 'agencyAccountManager',
	viewer: 'viewer',
	creator: 'creator',
	brand: 'brand',
	brandUser: 'brandUser',
	brandAdmin: 'brandAdmin',
	twitch: `${userPoolId}_${providers.twitch}`,
	youtube: `${userPoolId}_${providers.youtube}`,
	betaTester: 'betaTester',
	dareDevil: 'dareDevil',
}

// user can have only one role. it's calculated from user's groups
export const userRoles = [
	userGroups.user,
	userGroups.admin,
	userGroups.viewer,
	userGroups.creator,
	userGroups.brand,
	userGroups.brandAdmin,
	userGroups.brandUser,
	userGroups.agency,
	userGroups.agencyAccountManager,
]

export const authenticationTypes = {
	unauthenticated: 'unauthenticated',
	optional: 'optional',
	// has auth token
	// user has permission to endpoint
	// we check that by verifying token with public key
	authenticated: 'authenticated',
}

// We can interpret these as tree nodes
// - when one node has another in an
// array, it means that it's its parent
export const authenticationPrecedence = {
	[authenticationTypes.optional]: [
		authenticationTypes.unauthenticated,
	],
	[authenticationTypes.authenticated]: [
		authenticationTypes.optional,
	],
	[authenticationTypes.unauthenticated]: [],
}
// ^^^^^^^^ same
export const groupsPrecedence = {
	[userGroups.superAdmin]: [
		userGroups.admin,
	],
	[userGroups.admin]: [
		userGroups.creator,
		userGroups.brand,
		userGroups.twitch,
		userGroups.youtube,
		userGroups.agency,
	],
	[userGroups.creator]: [
		userGroups.viewer,
	],
	[userGroups.twitch]: [
		userGroups.viewer,
	],
	[userGroups.youtube]: [
		userGroups.viewer,
	],
	[userGroups.brand]: [
		userGroups.brandAdmin,
	],
	[userGroups.brandAdmin]: [
		userGroups.brandUser,
	],
	[userGroups.brandUser]: [
		userGroups.user,
	],
	[userGroups.viewer]: [
		userGroups.user,
	],
	[userGroups.agency]: [
		userGroups.agencyAccountManager,
	],
	[userGroups.agencyAccountManager]: [
		userGroups.user,
	],
	[userGroups.user]: [],
}
