import {
	DROP_ADD_EVENT,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import dropAddEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/dropAddEventPayloadSchema'
import dropEventResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/dropEventResponseSchema'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[DROP_ADD_EVENT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: recordEndpointType,
		recordType: dropEvent,
		payloadSchema: dropAddEventPayloadSchema,
		responseSchema: dropEventResponseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
