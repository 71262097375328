import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { useRewardsPayloadSchema } from 'root/src/shared/rewards/schemas/useRewards.payload.schema'
import { useRewardsResponseSchema } from 'root/src/shared/rewards/schemas/useRewards.response.schema'

export const useRewardsEndpoint = {
	authentication: authenticationTypes.authenticated,
	authenticationRole: userGroups.admin,
	endpointType: otherEndpointType,
	responseSchema: useRewardsResponseSchema,
	payloadSchema: useRewardsPayloadSchema,
}
