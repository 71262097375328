import {
	REJECT_DARE_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[REJECT_DARE_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Dare Rejected',
			paragraphs: [
				'This Dare has been removed from the marketplace.',
				'The Dare author and all pledgers will be notified.',
			],
			socialSharing: true,
		},
	},
}
