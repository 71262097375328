import {
	ACCEPT_DARE_SUCCESS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[ACCEPT_DARE_SUCCESS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'successPage',
		pageContent: {
			title: 'Success!',
			paragraphs: [
				`You’ve accepted the dare! Hype it up & tell your fans about it!
				We’ll email you when your goal has been reached. If time runs out, the project will expire - but you can give it another shot anytime.
				Once you submit your video, we’ll verify your completion & you’ll get paid.`,
			],
			socialSharing: true,
		},
	},
}
