import { likeEnum } from 'root/src/shared/constants/enums'

export default {
	type: 'object',
	properties: {
		parentId: { type: 'string' },
		messageId: { type: 'string' },
		audit: {
			type: 'string',
			enum: [likeEnum.LIKE, likeEnum.DISLIKE],
		},
		messageType: { type: 'string' },
	},
	required: ['parentId', 'messageId', 'audit'],
	additionalProperties: false,
}
