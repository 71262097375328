import providers from 'root/src/shared/constants/providers'

export default {
	type: 'object',
	properties: {
		platform: {
			type: 'string',
			enum: [
				providers.twitch,
				providers.youtube,
				providers.tiktok,
				providers.facebook,
			],
		},
	},
	additionalProperties: false,
	required: ['platform'],
}
