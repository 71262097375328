import { getDefaultRoute } from 'root/src/client/logic/auth/util/getDefaultRoute'
import userRoleSelector from 'root/src/client/logic/auth/selectors/userRoleSelector'
import { userGroups } from 'root/src/shared/constants/auth'
import currentRouteIdSelector from 'root/src/client/logic/route/selectors/currentRouteId'
import currentRouteParamsSelector from 'root/src/client/logic/route/selectors/currentRouteParams'
import { PERSISTENT_ROUTE_IDS } from 'root/src/shared/util/creatorRoutes'

export default async ({ dispatch, getState }) => {
	const state = getState()
	const userRole = userRoleSelector(state)

	// creator specific handling. when current route is drop event, we stay there
	const currentRouteId = currentRouteIdSelector(state)
	if (userRole === userGroups.creator && PERSISTENT_ROUTE_IDS.has(currentRouteId)) {
		const currentRouteParams = currentRouteParamsSelector(state)
		return { routeId: currentRouteId, routeParams: currentRouteParams }
	}

	return getDefaultRoute({ dispatch, getState })
}
