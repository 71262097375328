import assocPath from 'ramda/src/assocPath'
import assoc from 'ramda/src/assoc'
import pipe from 'ramda/src/pipe'
import {	ADD_BRAND_PRODUCT_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { addBrandCustomRewardPayloadSchema } from 'root/src/shared/rewards/schemas/addBrandCustomReward.payload.schema'
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[ADD_BRAND_PRODUCT_FORM_MODULE_ID]: {
		moduleType: 'form',
		formType: 'brandProduct',
		schema: pipe(
			assocPath(['properties', 'asset'], {}),
			assoc('required', ['name', 'cta', 'asset']),
		)(addBrandCustomRewardPayloadSchema),
		title: 'Upload Asset',
		fields: [
			{
				fieldId: 'name',
				inputType: 'text',
				// placeholder: 'Super Cable Boy Soundtrack',
				labelFieldText: [
					{
						text: 'Product Name',
						required: true,
					},
				],
			},
			{
				fieldId: 'cta',
				inputType: 'text',
				// placeholder: 'Buy on Steam',
				labelFieldText: [
					{
						text: 'Call to Action',
						required: true,
					},
				],
			},
			{
				fieldId: 'asset',
				inputType: 'attachmentInput',
				labelFieldText: [
					{
						text: 'Asset',
						required: true,
					},
				],
				subFieldText: 'Upload an image, .zip file, movie etc.',
			},
			{
				labelFieldText: [
					{
						text: 'Restrictions',
					},
				],
				fieldId: 'restrictions',
				inputType: 'text',
				inputMaxLength: 80,
				required: false,
			},
		],
		submits: [
			{
				label: 'Add Product',
				buttonType: 'primaryButton',
			},
		],
		links: [
			{
				routeId: ACCOUNT_SETTINGS_ROUTE_ID,
				label: 'Go Back',
			},
		],
	},
}
