export var DashboardSortTypes;
(function (DashboardSortTypes) {
    DashboardSortTypes["ASC"] = "ASC";
    DashboardSortTypes["DESC"] = "DESC";
})(DashboardSortTypes || (DashboardSortTypes = {}));
export var CreatorsTableTab;
(function (CreatorsTableTab) {
    CreatorsTableTab["Participating"] = "participating";
    CreatorsTableTab["Waitlist"] = "waitlist";
})(CreatorsTableTab || (CreatorsTableTab = {}));
