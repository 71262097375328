import Script from 'next/script'

const ZohoLeadTrackingScript = () => (
	<Script
		strategy="afterInteractive"
		id="zohoLeadTrackingScript"
		dangerouslySetInnerHTML={{
			__html: `
            var $zoho = $zoho || {};
			$zoho.salesiq = $zoho.salesiq || {
				widgetcode:
					"9d3bde37110ea521a7cb8c67397ea954f6883111273e4f512ad37e9527ad36251a2010ab7b6727677d37b27582c0e9c4",
				values: {},
				ready: function () {
					$zoho.salesiq.floatbutton.visible("hide");
				},
			};
			var d = document;
			s = d.createElement("script");
			s.type = "text/javascript";
			s.id = "zsiqscript";
			s.defer = true;
			s.src = "https://salesiq.zoho.com/widget";
			t = d.getElementsByTagName("script")[0];
			t.parentNode.insertBefore(s, t);
			`,
		}}
	/>
)

export default ZohoLeadTrackingScript
