/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import includes from 'ramda/src/includes'
import classNames from 'classnames'

import orEmpty from 'root/src/shared/util/orEmpty'
import {
	ltMdMediaQuery, ltGtMediaQuery, ltSmMediaQuery, colorsNew,
} from 'root/src/client/web/commonStyles'
import { withStylesHof } from 'root/src/client/util/withStylesHof'

export const typographyVariants = {
	heading1: 'heading1',
	heading2: 'heading2',
	heading3: 'heading3',
	heading4: 'heading4',
	paragraphBold: 'paragraphBold',
	paragraph: 'paragraph',
	xsmall: 'xsmall',
	small: 'small',
	smallSpan: 'smallSpan',
	button: 'button',
	smallBold: 'smallBold',
	smallBoldSpan: 'smallBoldSpan',
	mini: 'mini',
	miniBold: 'miniBold',
	micro: 'micro',
	microBold: 'microBold',
	providerSignIn: 'providerSignIn',
}

const variantsMapping = {
	[typographyVariants.heading1]: 'h1',
	[typographyVariants.heading2]: 'h2',
	[typographyVariants.heading3]: 'h3',
	[typographyVariants.heading4]: 'h4',
	[typographyVariants.paragraph]: 'p',
	[typographyVariants.paragraphBold]: 'p',
	[typographyVariants.small]: 'p',
	[typographyVariants.smallSpan]: 'span',
	[typographyVariants.button]: 'span',
	[typographyVariants.smallBold]: 'p',
	[typographyVariants.smallBoldSpan]: 'span',
	[typographyVariants.mini]: 'span',
	[typographyVariants.miniBold]: 'span',
	[typographyVariants.micro]: 'span',
	[typographyVariants.microBold]: 'span',
	[typographyVariants.providerSignIn]: 'span',
}

const headings = [
	typographyVariants.heading1,
	typographyVariants.heading2,
	typographyVariants.heading3,
	typographyVariants.heading4,
]

const paragraphs = [
	typographyVariants.paragraphs,
	typographyVariants.paragraphBold,
]

const smalls = [
	typographyVariants.small,
	typographyVariants.smallBold,
	typographyVariants.smallSpan,
	typographyVariants.smallBoldSpan,
]

const minis = [
	typographyVariants.mini,
	typographyVariants.miniBold,
]

const styles = {
	root: {
		fontFamily: 'Roboto, sans-serif',
		lineHeight: '150%',
		letterSpacing: '2%',
		margin: 0,
	},
	heading: {
		fontFamily: 'Roboto Mono, monospace',
		fontWeight: 700,
	},
	[typographyVariants.heading1]: {
		lineHeight: '140%',
		fontSize: 38,
		[ltMdMediaQuery]: {
			fontSize: 34,
		},
		[ltGtMediaQuery]: {
			fontSize: 30,
		},
		[ltSmMediaQuery]: {
			fontSize: 26,
		},
	},
	[typographyVariants.heading2]: {
		fontSize: 32,
		[ltMdMediaQuery]: {
			fontSize: 28,
		},
		[ltGtMediaQuery]: {
			fontSize: 26,
		},
		[ltSmMediaQuery]: {
			fontSize: 22,
		},
	},
	[typographyVariants.heading3]: {
		fontWeight: 500,
		lineHeight: '100%',
		fontSize: 22,
		[ltGtMediaQuery]: {
			fontSize: 20,
		},
		[ltSmMediaQuery]: {
			fontSize: 18,
		},
	},
	[typographyVariants.heading4]: {
		fontSize: 20,
		[ltGtMediaQuery]: {
			fontSize: 18,
		},
		[ltSmMediaQuery]: {
			fontSize: 16,
		},
	},
	[typographyVariants.paragraph]: {
		letterSpacing: 0,
		fontSize: 20,
		[ltMdMediaQuery]: {
			fontSize: 18,
		},
		[ltGtMediaQuery]: {
			fontSize: 16,
		},
		[ltSmMediaQuery]: {
			fontSize: 14,
		},
	},
	[typographyVariants.paragraphBold]: {
		letterSpacing: '4%',
		fontWeight: 500,
	},
	[typographyVariants.button]: {
		letterSpacing: '4%',
		fontSize: 18,
		[ltMdMediaQuery]: {
			fontSize: 16,
		},
		[ltGtMediaQuery]: {
			fontSize: 14,
		},
		[ltSmMediaQuery]: {
			fontSize: 12,
		},
		fontWeight: 500,
	},
	[typographyVariants.small]: {
		letterSpacing: 0,
		fontSize: 15,
		[ltMdMediaQuery]: {
			fontSize: 14,
		},
		[ltGtMediaQuery]: {
			fontSize: 12,
		},
		[ltSmMediaQuery]: {
			fontSize: 10,
		},
	},
	[typographyVariants.smallSpan]: {
		lineHeight: '100%',
	},
	[typographyVariants.smallBold]: {
		letterSpacing: '2%',
		fontWeight: 500,
	},
	[typographyVariants.smallBoldSpan]: {
		fontWeight: 500,
		lineHeight: '100%',
	},
	[typographyVariants.mini]: {
		fontSize: 12,
		[ltMdMediaQuery]: {
			fontSize: 11,
		},
		[ltGtMediaQuery]: {
			fontSize: 10,
		},
		[ltSmMediaQuery]: {
			fontSize: 9,
		},
	},
	[typographyVariants.miniBold]: {
		fontWeight: 500,
	},
	[typographyVariants.micro]: {
		fontSize: 9,
		textTransform: 'uppercase',
		letterSpacing: '4%',
	},
	[typographyVariants.microBold]: {
		fontSize: 10,
		[ltSmMediaQuery]: {
			fontSize: 9,
		},
		fontWeight: 500,
	},
	[typographyVariants.providerSignIn]: {
		fontSize: 14,
		fontWeight: 500,
	},
}

const Typography = ({
	variant = typographyVariants.paragraph,
	color = colorsNew.white,
	children,
	className,
	classes,
	style,
	...props
}) => {
	const Component = variantsMapping[variant]
	const isHeading = includes(variant, headings)
	const isParagraph = includes(variant, paragraphs)
	const isSmall = includes(variant, smalls)
	const isMini = includes(variant, minis)

	return (
		<Component
			className={classNames(
				classes.root,
				isHeading && classes.heading,
				isParagraph && classes.paragraph,
				isSmall && classes.small,
				isMini && classes.mini,
				classes[variant],
				className,
			)}
			style={{ ...style, color: `!important ${color}` }}
			css={orEmpty(color, { color })}
			{...props}
		>
			{children}
		</Component>
	)
}

export default withStylesHof(styles, { name: 'MuiTypography' }, Typography)
