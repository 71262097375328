import { userGroups } from 'root/src/shared/constants/auth'
import {
	ACCOUNT_SETTINGS_ROUTE_ID,
	AGENCY_PUBLISHERS_LIST_ROUTE_ID,
	LOGIN_ROUTE_ID,
	PENDING_DELIVERIES_ROUTE_ID,
	VIEWERS_PAGE_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export const groupDefaultRouteIdMap = {
	[userGroups.user]: LOGIN_ROUTE_ID,
	[userGroups.brand]: ACCOUNT_SETTINGS_ROUTE_ID,
	[userGroups.viewer]: VIEWERS_PAGE_ROUTE_ID,
	[userGroups.creator]: routeUrls.drops(),
	[userGroups.admin]: PENDING_DELIVERIES_ROUTE_ID,
	[userGroups.agency]: AGENCY_PUBLISHERS_LIST_ROUTE_ID,
	[userGroups.agencyAccountManager]: AGENCY_PUBLISHERS_LIST_ROUTE_ID,
	[userGroups.brandAdmin]: ACCOUNT_SETTINGS_ROUTE_ID,
	[userGroups.brandUser]: ACCOUNT_SETTINGS_ROUTE_ID,
}
