import { GITHUB_WEBHOOK_CALLBACK } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	gatewayEndpointType,
} from 'root/src/shared/descriptions/endpoints/lenses'

export default {
	[GITHUB_WEBHOOK_CALLBACK]: {
		endpointType: gatewayEndpointType,
	},
}
