import identity from 'ramda/src/identity'
import setDropdownAsyncOptions from 'root/src/client/logic/form/actions/setDropdownAsyncOptions'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import { mapP } from 'root/src/shared/util/ramdaPlus'

export default async (dispatch, getState, moduleId, dropdownAsyncOptions) => {
	const state = getState()

	return mapP(async (option) => {
		const { endpointId, payload, modifyFn = identity } = option
		const { statusCode, body } = await invokeApiRequest(endpointId, payload, state)

		if (statusCode !== 200) return

		const result = modifyFn(body)
		dispatch(setDropdownAsyncOptions(moduleId, { asyncOptionsStoreKey: endpointId, result }))
	}, dropdownAsyncOptions)
}
