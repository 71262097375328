export default {
	type: 'object',
	properties: {
		termsAccepted: { type: 'object' },
		whitelistData: { type: 'object' },
		creatorAcceptedDares: { type: 'number' },
		creatorAcceptedDaresByPlatform: {
			type: 'object',
			properties: {
				youtube: { type: 'number' },
				tiktok: { type: 'number' },
				twitch: { type: 'number' },
			},
		},
		pointsEarned: { type: 'number' },
	},
	required: ['whitelistData', 'pointsEarned'],
	additionalProperties: false,
}
