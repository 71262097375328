import { formStoreLenses } from 'root/src/client/logic/form/lenses'
import { SET_FIELD_LOADING } from 'root/src/client/logic/form/actionIds'

const { setFieldLoading } = formStoreLenses

export default {
	[SET_FIELD_LOADING]: (
		state, { fieldId, loading },
	) => setFieldLoading(fieldId, loading, state),
}
