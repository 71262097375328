import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { agencyAccountManager } from 'root/src/shared/descriptions/endpoints/recordTypes';
import inviteAgencyAccountManagerPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/inviteAgencyAccountManagerPayloadSchema';
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds';
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants';
export const inviteAgencyAccountManagerEndpoint = {
    [agencyEndpointIds.INVITE_AGENCY_ACCOUNT_MANAGER]: {
        authentication: authenticationTypes.authenticated,
        recordType: agencyAccountManager,
        authenticationRole: userGroups.agency,
        endpointType: otherEndpointType,
        payloadSchema: inviteAgencyAccountManagerPayloadSchema,
        middlewares: [VALIDATE_TOKEN_ACCOUNT],
    },
};
