import { SELECT_PREFERRED_PLATFORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[SELECT_PREFERRED_PLATFORM_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'selectPreferredPlatform',
		pageContent: {
			title: 'Select platform',
			subTitle: 'This platform will be on drops by default',
			modalSize: 'medium',
		},
	},
}
