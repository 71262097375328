import BR from 'country-flag-icons/react/3x2/BR';
import US from 'country-flag-icons/react/3x2/US';
import ES from 'country-flag-icons/react/3x2/ES';
import FR from 'country-flag-icons/react/3x2/FR';
import DE from 'country-flag-icons/react/3x2/DE';
import outputs from 'root/cfOutput';
import { isProdEnv } from 'root/src/shared/util/envSelect';
import { languageCodes } from 'root/src/shared/constants/languageCodes';
const { domainName } = outputs;
// eslint-disable-next-line import/prefer-default-export
export const Weglot = {
    scriptUrl: 'https://cdn.weglot.com/weglot.min.js',
    // Note: see the full list of options: https://developers.weglot.com/javascript/options
    initScript: (key) => `Weglot.initialize({
		"api_key": "${key}",
		"cache": true,
		"hide_switcher": true
	})`,
    languageCodes: [
        languageCodes.en,
        languageCodes.es,
        languageCodes.br,
        languageCodes.fr,
        languageCodes.de,
    ],
    origins: [
        `https://${domainName}`,
        `https://es.${domainName}`,
        `https://pt-br.${domainName}`,
        `https://fr.${domainName}`,
        `https://de.${domainName}`,
    ],
    dnsSettings: {
        type: 'CNAME',
        value: isProdEnv ? '123d9afc.translate-cf.weglot.io.' : `${domainName}.`,
        names: [
            `es.${domainName}`,
            `pt-br.${domainName}`,
            `fr.${domainName}`,
            `de.${domainName}`,
        ],
    },
    flagComponents: {
        [languageCodes.en]: US,
        [languageCodes.es]: ES,
        [languageCodes.br]: BR,
        [languageCodes.fr]: FR,
        [languageCodes.de]: DE,
    },
    languageNames: {
        [languageCodes.en]: 'English',
        [languageCodes.es]: 'Español',
        [languageCodes.br]: 'Português',
        [languageCodes.fr]: 'Français',
        [languageCodes.de]: 'Deutsch',
    },
};
