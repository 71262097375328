import { auditEnum } from 'root/src/shared/constants/enums'

export default {
	type: 'object',
	properties: {
		id: { type: 'string' },
		audit: {
			type: 'string',
			enum: [auditEnum.ACTIVATE, auditEnum.DEACTIVATE],
		},
	},
}
