import assocPath from 'ramda/src/assocPath'
import { UPDATE_TERMS_ACCEPTED } from 'root/src/client/logic/dropEvent/actionIds'
import currentRouteParamsRecordId
	from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'

const updateTermsAccepted = (state, payload) => {
	const recordId = currentRouteParamsRecordId(state)
	return assocPath(
		['api', 'records', 'userCampaignData', recordId, 'termsAccepted'],
		payload,
		state,
	)
}
export default {
	[UPDATE_TERMS_ACCEPTED]: updateTermsAccepted,
}
