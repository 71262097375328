import pipe from 'ramda/src/pipe'
import __ from 'ramda/src/__'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import { routeDescriptionLenses } from 'root/src/client/logic/route/lenses'
import routeDescriptions from 'root/src/shared/descriptions/routes'

const { viewChatWidget } = routeDescriptionLenses

export default pipe(
	currentRouteId,
	viewChatWidget(__, routeDescriptions),
)
