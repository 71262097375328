import { SET_EMAIL } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

import setEmailPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setEmailPayloadSchema'

export const payloadSchema = setEmailPayloadSchema

export default {
	[SET_EMAIL]: {
		endpointType: gatewayEndpointType,
		payloadSchema,
	},
}
