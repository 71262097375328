import { surveyEndpointIds } from 'root/src/shared/survey/constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const deleteSurveyEndpoint = {
    [surveyEndpointIds.DELETE_SURVEY]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: gatewayEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                id: { type: 'string' },
            },
            required: ['id'],
        },
    },
};
