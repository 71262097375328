export default {
	type: 'object',
	properties: {
		userId: { type: 'string' },
		campaignId: { type: 'string' },
		pointsAwarded: { type: 'number' },
		totalCreatorPoints: { type: 'number' },
	},
	required: ['totalCreatorPoints', 'userId', 'campaignId', 'pointsAwarded'],
	additionalProperties: false,
}
