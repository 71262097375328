import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'

import { variableSchemaKey } from 'root/src/shared/constants/keys'

const modalSchema = {
	type: 'object',
	properties: {
		app: {
			type: 'object',
			patternProperties: {
				[variableSchemaKey]: {
					type: 'object',
					properties: {
						modalRecordId: {
							type: 'string',
						},
						modalModuleId: {
							type: 'string',
						},
						modalProps: {
							type: 'object',
						},
					},
				},
			},
		},
	},
}

// eslint-disable-next-line import/prefer-default-export
export const modalStoreLenses = lensesFromSchema(modalSchema)
