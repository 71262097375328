import { ADD_TERMS_ACCEPTED_CAMPAIGN } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import addTermsAcceptedCampaignResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/addTermsAcceptedCampaignResponseSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

const payloadSchema = {
	type: 'object',
	properties: {
		campaignId: { type: 'string' },
		reservedPointsProjected: { type: 'number' },
	},
	required: ['campaignId', 'reservedPointsProjected'],
}

export default {
	[ADD_TERMS_ACCEPTED_CAMPAIGN]: {
		endpointType: otherEndpointType,
		payloadSchema,
		responseSchema: addTermsAcceptedCampaignResponseSchema,
		authentication: authenticationTypes.authenticated,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
