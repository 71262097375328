import { FEATURED_DARES_ADMIN_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import addFeaturedDarePayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addOrDeleteFeaturedDarePayloadSchema'

export default {
	[FEATURED_DARES_ADMIN_FORM_MODULE_ID]: {
		moduleType: 'form',
		title: 'Add featured dare',
		schema: addFeaturedDarePayloadSchema,
		fields: [
			{
				fieldId: 'projectUrl',
				inputType: 'text',
				placeholder: 'projectTitleUrlEncoded-projectId',
				labelFieldText: [
					{
						text: 'Project URL',
						required: true,
					},
				],
			},
		],
		submits: [
			{
				label: 'Add',
			},
			{
				label: 'Remove',
			},
		],
	},
}
