import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import outputs from 'root/cfOutput'
import auditEndpoint from 'root/src/client/logic/api/util/auditEndpoint'
import authenticationSelector from 'root/src/client/logic/auth/selectors/authenticationSelector'
import { apiIdentifier } from 'root/src/shared/constants/aws'
import nextAjax from 'root/src/client/util/nextAjax'

const { domainName } = outputs

export default async (endpointId, payload, state) => {
	if (!auditEndpoint(endpointId, state)) return {}
	const accessToken = pipe(
		authenticationSelector,
		prop('accessToken'),
	)(state)
	const requestPayload = {
		endpointId,
		payload,
		...(accessToken ? { authentication: accessToken } : {}),
	}

	return nextAjax({
		method: 'POST',
		url: `https://${apiIdentifier}.${domainName}`,
		body: requestPayload,
	}, false)
}
