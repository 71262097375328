import { GET_TWITCH_PROFILES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userData } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { userDataEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { tokensKey } from 'root/src/shared/constants/keys'

export default {
	[GET_TWITCH_PROFILES]: {
		authentication: authenticationTypes.authenticated,
		endpointType: userDataEndpointType,
		recordType: userData,
		storeKey: tokensKey,
	},
}
