import { DROP_ACTIVATE_EVENT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import dropEventResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/dropEventResponseSchema'
import dropActivateEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/dropActivateEventPayloadSchema'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const responseSchema = dropEventResponseSchema
export const payloadSchema = dropActivateEventPayloadSchema

export default {
	[DROP_ACTIVATE_EVENT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		endpointType: otherEndpointType,
		responseSchema,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
