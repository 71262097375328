export default {
	type: 'object',
	properties: {
		projectId: { type: 'string' },
		pledgeAmount: { type: 'number' },
		platform: { type: 'string' },
		context: {
			type: 'object',
			properties: {
				transactionId: { type: 'string' },
				sku: { type: 'string' },
			},
		},
	},
	required: ['projectId', 'context', 'pledgeAmount'],
	additionalProperties: false,
}
