import { GRAPHS_DASHBOARD_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import {
	GET_DASHBOARD_CREATORS,
	GET_DASHBOARD_CHARTS_DATA,
	GET_DASHBOARD_DELIVERIES,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import onEnterActionCalls from 'root/src/client/logic/api/thunks/onEnterActionCalls'
import setModuleDataFilter from 'root/src/client/logic/api/actions/setModuleDataFilter'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import { defaultCreatorsTableFilters } from 'root/src/client/v2/brandDashboard/fixtures/defualtDashboardFilters'
import { getDashboardFilters } from 'root/src/client/web/static/GraphsDashboard/helpers'

export default {
	[GRAPHS_DASHBOARD_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'graphsDashboard',
		endpointId: [
			GET_DASHBOARD_CREATORS,
			GET_DASHBOARD_CHARTS_DATA,
			GET_DASHBOARD_DELIVERIES,
		],
		recordPayloadMap: {
			[GET_DASHBOARD_CREATORS]: (state) => {
				const {
					routeParams: { recordId },
				} = currentRouteObj(state)

				const { ambassadorsOnly, to, from, creatorsTab } = getDashboardFilters({ state, recordId })

				const mnemonicIds = recordId ? [recordId] : undefined

				return {
					filters: {
						ambassadorsOnly,
					},
					from,
					to,
					mnemonicIds,
					limit: 8,
					sortBy: 'hoursViewed',
					sort: 'DESC',
					tab: creatorsTab,
				}
			},
			[GET_DASHBOARD_CHARTS_DATA]: (state) => {
				const {
					routeParams: { recordId },
				} = currentRouteObj(state)

				const mnemonicIds = recordId ? [recordId] : undefined

				const { ambassadorsOnly, from, to } = getDashboardFilters({ state, recordId })

				return {
					mnemonicIds,
					from,
					to,
					filters: {
						ambassadorsOnly,
					},
				}
			},
			[GET_DASHBOARD_DELIVERIES]: (state) => {
				const {
					routeParams: { recordId },
				} = currentRouteObj(state)

				const { ambassadorsOnly, from, to } = getDashboardFilters({ state, recordId })

				const mnemonicIds = recordId ? [recordId]
					: state?.app?.userData?.dropEvents?.map(({ mnemonicId }) => mnemonicId) || []

				return {
					from,
					to,
					mnemonicIds,
					limit: 10,
					filters: {
						ambassadorsOnly,
						isFeatured: true,
					},
				}
			},
		},
		graphDescriptions: {
			viewMinutes: {
				label: 'View Minutes',
				tooltipText:
					'Total number of minutes that Fans have spent watching our Creators do Dares in this Campaign.',
			},
			tiktokViews: {
				label: 'TikTok Views',
				tooltipText:
						'Total number of times Fans have viewed a Creator on YouTube or TikTok completing a Dare in this Campaign.',
			},
			youtubeViews: {
				label: 'YouTube Views',
				tooltipText:
					'Total number of times Fans have viewed a Creator on YouTube or TikTok completing a Dare in this Campaign.',
			},
			daresCompletedCount: {
				label: 'Dares Completed',
				tooltipText:
					'Total number of Dares that Creators have completed in this Campaign.',
			},
			streamsPostsCount: {
				label: 'Content Created',
				tooltipText:
					'Total number of Clips, Posts, and Streams shared by Creators in this Campaign.',
			},
			followerCount: {
				label: 'Followers',
				tooltipText:
					'Total number of Followers in Twitch, YouTube, and TikTok for Creators who participated in this Campaign.',
			},
			creatorsCount: {
				label: 'Creators',
				tooltipText:
					'Total number of Creators who participated in this Campaign.',
			},
			topCreators: {
				label: 'Top Creators',
				tooltipText:
					'The best performing Creators who participated in this Campaign.',
			},
			topClips: {
				label: 'Top Clips, Posts',
				tooltipText:
					'The most-viewed streams, videos, and clips made by Creators for this Campaign.',
			},
		},
		onEnterThunk: onEnterActionCalls,
		onEnterActions: (state) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)

			const { from, to } = getDashboardFilters({ state, recordId })

			return [
				{
					action: setModuleDataFilter,
					payloads: [
						{
							moduleId: GRAPHS_DASHBOARD_MODULE_ID,
							filter: 'payloadFilter',
							id: 'from',
							value: from,
						},
						{
							moduleId: GRAPHS_DASHBOARD_MODULE_ID,
							filter: 'payloadFilter',
							id: 'to',
							value: to,
						},
						{
							moduleId: GRAPHS_DASHBOARD_MODULE_ID,
							filter: 'ambassadorsOnly',
							id: 'value',
							value: defaultCreatorsTableFilters.ambassadorsOnly,
						},
					],
				},
			]
		},
	},
}
