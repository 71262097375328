import {
	VERIFICATION_FAILURE_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import {
	SIGN_UP_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[VERIFICATION_FAILURE_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'verificationResult',
		pageContent: {
			title: 'Please use correct link to verify account!',
			paragraphs: [
				['There was an issue verifying your account. Please contact support@daredrop.com or signup again.'],
			],
			link: SIGN_UP_ROUTE_ID,
			linkLabel: 'Sign-up again',
		},

	},
}
