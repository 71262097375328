import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls';
export const newRoutes = {
    [routeUrls.giveawayHistory()]: {
        url: routeUrls.giveawayHistory(),
        modules: [],
    },
    [routeUrls.drops()]: {
        url: routeUrls.drops(),
        modules: [],
    },
    [routeUrls.audienceTargeting()]: {
        url: routeUrls.audienceTargeting(),
        modules: [],
    },
    [routeUrls.brandUsers()]: {
        url: routeUrls.brandUsers(),
        modules: [],
    },
    '/creators/:id': {
        url: '/creators/:id',
        modules: [],
    },
    '/creators/yt/:id': {
        url: '/creators/yt/:id',
        modules: [],
    },
    '/creators/tiktok/:id': {
        url: '/creators/tiktok/:id',
        modules: [],
    },
    [routeUrls.addCampaign()]: {
        url: routeUrls.addCampaign(),
        modules: [],
    },
    [routeUrls.keysAndProducts()]: {
        url: routeUrls.keysAndProducts(),
        modules: [],
    },
    '/battle-passes/:id': {
        url: '/battle-passes/:id',
    },
    [routeUrls.battlePasses()]: {
        url: routeUrls.battlePasses(),
        modules: [],
    },
};
