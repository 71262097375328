import { resolutionBreakpoints } from 'root/src/client/v2/browser/fixtures/resolutionBreakpoints';
import { useEffect, useState } from 'react';
export function useResolutionBreakpointsMap() {
    const [resolutionBreakpointsMap, setResolutionBreakpointsMap] = useState(getResolutionBreakpointsMap());
    useEffect(() => {
        const windowResizeListener = () => {
            setResolutionBreakpointsMap(getResolutionBreakpointsMap());
        };
        window.addEventListener('resize', windowResizeListener);
        return () => {
            window.removeEventListener('resize', windowResizeListener);
        };
    }, []);
    return { resolutionBreakpointsMap };
}
function getResolutionBreakpointsMap() {
    return Object.entries(resolutionBreakpoints).reduce((acc, item) => {
        if (!global.window) {
            return Object.assign(Object.assign({}, acc), { [item[0]]: false });
        }
        return Object.assign(Object.assign({}, acc), { [item[0]]: window.matchMedia(item[1]).matches });
    }, {});
}
