import { GET_COMMENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { PROJECT_COMMENTS_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[PROJECT_COMMENTS_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: 'projectCommentsList',
		endpointId: [GET_COMMENTS],
		listPayloadMap: [
			['projectUrl', { $sub: ['recordId'] }],
		],
	},
}
