import { appStoreLenses } from 'root/src/client/logic/app/lenses'
import { pipeNotNil } from 'root/src/shared/util/ramdaPlus'
import omitUndefinedValues from 'root/src/shared/util/omitUndefinedValues'

const { viewAccountData } = appStoreLenses

export default pipeNotNil(
	viewAccountData,
	({ brandName, brandWebsite, brandLogo, brandTwitter }) => ({
		brandName, brandWebsite, brandTwitter, brandLogoUrl: brandLogo ? brandLogo.big : undefined,
	}),
	omitUndefinedValues,
)
