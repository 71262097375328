export const isProdEnv = process.env.STAGE === 'production'
export const isTestEnv = process.env.STAGE === 'test'
// not a perfect solution, but with current stack setup process.env.STAGE === 'dev' is not possible
export const isDevEnv = !isProdEnv && !isTestEnv
export const isStagingEnv = process.env.STAGE === 'staging'
// env isolated from sending emails/messages to users
export const isIsolatedEnv = isStagingEnv
export const isLocalhost = typeof window !== 'undefined'
	&& (
		window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
	)
export const isLocalEnv = process.env.IS_LOCAL_ENV
