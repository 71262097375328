import { WELCOME_TO_DARE_DROP_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[WELCOME_TO_DARE_DROP_MODULE_ID]: {
		moduleType: 'modal',
		modalContent: {
			title: 'What Is Dare Drop all about?',
			subTitle: 'Take a look at the cool dares and drops that are going on!',
			videoId: 'Nv17Rl4ZuI8',
			modalSize: 'medium',
			button: {
				label: 'OK, GOT IT!',
				type: 'primary',
			},
		},
	},
}
