import __ from 'ramda/src/__'
import path from 'ramda/src/path'
import map from 'ramda/src/map'
import prop from 'ramda/src/prop'
import propOr from 'ramda/src/propOr'
import pipe from 'ramda/src/pipe'
import has from 'ramda/src/has'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import reject from 'ramda/src/reject'
import isNil from 'ramda/src/isNil'
import ifElse from 'ramda/src/ifElse'
import routeDescriptions from 'root/src/shared/descriptions/routes'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import variantRouteIndexSelector from 'root/src/client/logic/route/selectors/variantRouteIndexSelector'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import { routeDescriptionLenses } from 'root/src/client/logic/route/lenses'
import experimentVariantSelector from 'root/src/client/logic/app/selectors/experimentVariantSelector'

const { pathOrModules, pathOrVariantModules, viewExperimentVariants } = routeDescriptionLenses
export const currentRouteModuleObjectsHof = (
	routeDescriptionObj, moduleDescriptionsObj,
) => (state, props) => {
	const currentRoute = currentRouteObj(state)
	const experimentVariantKey = experimentVariantSelector(state)
	const { routeId, query } = currentRoute
	const { modal } = query

	const modules = ifElse(
		pipe(
			prop(__, routeDescriptionObj),
			has('variantModules'),
		),
		pipe(
			pathOrVariantModules(__, [], routeDescriptionObj),
			path([variantRouteIndexSelector(state), 'modules']),
		),
		ifElse(
			pipe(
				prop(__, routeDescriptionObj),
				has('experimentVariants'),
			),
			pipe(
				viewExperimentVariants(__, routeDescriptionObj),
				(variants) => {
					const selectedVariant = find(propEq('value', experimentVariantKey))(variants)

					if (selectedVariant) {
						return selectedVariant
					}

					return variants?.[0]
				},
				propOr([], 'modules'),
			),
			pathOrModules(__, [], routeDescriptionObj),
		),
	)(routeId)

	const modulesMod = modal ? [modal, ...modules] : modules

	return pipe(
		map(
			(moduleItem) => {
				if (typeof moduleItem === 'object') {
					const { moduleId, condition } = moduleItem
					if (condition(state, props)) {
						return [
							moduleId,
							prop(moduleId, moduleDescriptionsObj),
						]
					}
					return undefined
				}
				return [
					moduleItem,
					prop(moduleItem, moduleDescriptionsObj),
				]
			},
		),
		reject(isNil),
	)(modulesMod)
}

export default currentRouteModuleObjectsHof(
	routeDescriptions, moduleDescriptions,
)
