import reduxConnector from 'root/src/shared/util/reduxConnector'

import linkTargetSelector from 'root/src/client/logic/app/selectors/linkTargetSelector'
import linkHrefSelector from 'root/src/client/logic/app/selectors/linkHrefSelector'
import userProfileLinkSelector from 'root/src/client/logic/creatorData/selectors/userProfileLinkSelector'

import setPreferredPlatform from 'root/src/client/logic/static/thunks/setPreferredPlatform'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'
import handleAuthenticationRouting from 'root/src/client/logic/route/thunks/handleAuthenticationRouting'

export default reduxConnector(
	[
		['linkHref', linkHrefSelector],
		['linkTarget', linkTargetSelector],
		['userProfileLink', userProfileLinkSelector],
	],
	[
		['setPreferredPlatform', setPreferredPlatform],
		['displayModal', displayModal],
		['handleAuthenticationRouting', handleAuthenticationRouting],
	],
)
