import pipe from 'ramda/src/pipe'
import includes from 'ramda/src/includes'
import { userGroups } from 'root/src/shared/constants/auth'
import userGroupsSelector from 'root/src/client/logic/auth/selectors/userGroupsSelector'

export default pipe(
	userGroupsSelector,
	(groups) => {
		const includeBrand = includes(userGroups.brand, groups)
		const includeAdmin = includes(userGroups.admin, groups)
		return includeBrand && !includeAdmin
	},
)
