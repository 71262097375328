import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { agencyAccountManager } from 'root/src/shared/descriptions/endpoints/recordTypes';
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema';
import getAgencyAccountManagersResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getAgencyAccountManagersResponseSchema';
import { agencyEndpointIds } from 'root/src/shared/agency/agency.constants';
export const getAgencyAccountManagersEndpoint = {
    [agencyEndpointIds.GET_AGENCY_ACCOUNT_MANAGERS]: {
        authentication: authenticationTypes.authenticated,
        recordType: agencyAccountManager,
        authenticationRole: userGroups.agency,
        endpointType: listEndpointType,
        payloadSchema: emptyPayloadSchema,
        responseSchema: getAgencyAccountManagersResponseSchema,
    },
};
