import reduce from 'ramda/src/reduce'
import prop from 'ramda/src/prop'
import keys from 'ramda/src/keys'
import assoc from 'ramda/src/assoc'

import { CHANGE_ROUTE } from 'root/src/client/logic/route/actionIds'

export default {
	[CHANGE_ROUTE]: (state, action) => {
		const routeParams = prop('routeParams', action)
		const query = prop('query', action)
		const languageCode = prop('languageCode', action)
		const paramsUriDecoded = reduce((acc, paramKey) => ({
			...acc, [paramKey]: decodeURIComponent(prop(paramKey, routeParams)),
		}), {}, keys(routeParams))
		const newAction = {
			...action,
			routeParams: paramsUriDecoded,
			query,
			languageCode,
		}
		return assoc('route', newAction, state)
	},
}
