import path from 'ramda/src/path'
import { DELIVER_DARE_SUCCESS_MODULE_ID, DELIVERY_DARE_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import deliveryDareVideoSchema from 'root/src/shared/descriptions/formSchemas/deliveryDareVideoSchema'
import myProfileOnSuccessRedirectSelector from 'root/src/client/logic/profile/selectors/myProfileOnSuccessRedirectSelector'
import {
	VIEW_CREATOR_DATA_ROUTE_ID,
	VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
	VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
	VIEW_YT_CREATOR_DATA_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import getProjectDataFromRoute from 'root/src/client/logic/form/thunks/getProjectDataFromRoute'
import platformSelector from 'root/src/client/logic/project/selectors/platformSelector'
import tokensSelector from 'root/src/client/logic/api/selectors/tokensSelector'
import providers, { platformsLabelMap } from 'root/src/shared/constants/providers'

export const deliveryDareCommon = {
	// Function returns title from data loaded in onEnterThunk
	title: (state, { recordId }) => {
		// TODO this doesn't works on /claim-project/ page.
		const projectTitle = path(['api', 'records', 'project', recordId, 'title'], state)
		return projectTitle ? `${projectTitle} - Deliver Dare Video` : 'Deliver Dare Video'
	},
	onEnterThunk: getProjectDataFromRoute,
	fields: [
		(state, props) => {
			const platform = platformSelector(state)
			const userTokens = tokensSelector(state, props)
			return {
				fieldId: 'videoURL',
				subTextLabel: {
					text: 'Link to the time your Dare begins in a highlight, clip, or YouTube video.',
					linkLabel: 'highlight',
					href: 'https://help.twitch.tv/s/article/creating-highlights-and-stream-markers',
				},
				inputType: 'text',
				placeholder: 'Enter link',
				labelFieldText: [
					{
						// eslint-disable-next-line no-use-before-define
						text: getLabelTextForDeliverDare(platform, userTokens),
						required: true,
					},
				],
			}
		},
		{
			fieldId: 'notesForReviewer',
			inputType: 'textarea',
			subTextLabel: {
				text: 'Notes for reviewers:',
			},
			placeholder: 'Enter notes...',
		},
	],
	preSubmitText: (state) => {
		const recordId = currentRouteParamsRecordId(state)
		const campaignId = path(['api', 'records', 'project', recordId, 'campaignId'], state)
		return campaignId
			? 'The quality of your clip matters to publishers - please avoid submitting long videos unless necessary to demonstrate dare completion.'
			: ''
	},
	submits: [
		{
			label: 'Confirm',
			schema: deliveryDareVideoSchema,
			onSuccessRedirectSelector: (state) => {
				const routeMap = {
					twitch: {
						routeId: VIEW_CREATOR_DATA_ROUTE_ID,
						query: { modal: DELIVER_DARE_SUCCESS_MODULE_ID },
					},
					youtube: {
						routeId: VIEW_YT_CREATOR_DATA_ROUTE_ID,
						query: { modal: DELIVER_DARE_SUCCESS_MODULE_ID },
					},
					tiktok: {
						routeId: VIEW_TIKTOK_CREATOR_DATA_ROUTE_ID,
						query: { modal: DELIVER_DARE_SUCCESS_MODULE_ID },
					},
					facebook: {
						routeId: VIEW_FACEBOOK_CREATOR_DATA_ROUTE_ID,
						query: { modal: DELIVER_DARE_SUCCESS_MODULE_ID },
					},
				}
				return myProfileOnSuccessRedirectSelector(state, routeMap)
			},
		},
	],
}

function getLabelTextForDeliverDare(platform, userTokens) {
	if (platform === providers.any || platform === undefined) {
		return 'Twitch Clip/Highlight, YouTube Video or TikTok'
	}
	const { displayName = '' } = userTokens.find(token => token.platform === platform) || {}
	return `${displayName} (${platformsLabelMap[platform]}) video:`
}

export default {
	[DELIVERY_DARE_FORM_MODULE_ID]: {
		moduleType: 'form',
		...deliveryDareCommon,
	},
}
