import {
	memo,
	createContext,
	useContext,
} from 'react'

export const moduleContext = createContext({})

export const useModuleContext = () => {
	if (!moduleContext) {
		throw new Error('useModuleContext must be used within a ModuleProvider')
	}
	return useContext(moduleContext)
}

const { Provider, Consumer } = moduleContext

export const ModuleContextProvider = memo(Provider)
export const ModuleContextConsumer = memo(Consumer)

export default Component => memo(props => (
	<Consumer>
		{moduleObj => (
			<Component
				{...props}
				{...moduleObj}
			/>
		)}
	</Consumer>
))
