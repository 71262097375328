import { useLocation } from 'root/src/client/v2/browser/hooks/useLocation';
import { useRouter } from 'next/router';
export const WindowSSRAdapter = ({ children }) => {
    const nextAdapter = useNextAdapter();
    if (typeof window === 'undefined') {
        return children(nextAdapter);
    }
    const windowAdapter = useWindowAdapter();
    return children(windowAdapter);
};
function useWindowAdapter() {
    const { search } = useLocation();
    return {
        push: updateLocation,
        replace: updateLocation,
        get location() {
            return { search };
        },
    };
}
function updateLocation(location) {
    const searchParams = withoutUndefined(location.search);
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    window.dispatchEvent(new PopStateEvent('popstate'));
}
function withoutUndefined(search) {
    const params = new URLSearchParams(search);
    params.forEach((value, key) => {
        if (value === 'undefined' || value === 'null'
            || value === null || value === undefined || value === '') {
            params.delete(key);
        }
    });
    return params.toString();
}
function useNextAdapter() {
    const { query } = useRouter();
    return {
        replace() {
            return undefined;
        },
        push() {
            return undefined;
        },
        get location() {
            const params = new URLSearchParams(query);
            const search = params.toString();
            return { search };
        },
    };
}
