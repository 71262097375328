import prop from 'ramda/src/prop'
import path from 'ramda/src/path'
import equals from 'ramda/src/equals'
import __ from 'ramda/src/__'
import { DROP_EVENTS_LIST_MODULE_ID,
	BRAND_PAYMENT_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import {
	DROP_ACTIVATION_AUDIT,
	DROP_SUBSCRIPTION_AUDIT,
	GET_DROP_EVENTS,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { userEventsKey } from 'root/src/shared/constants/keys'
import updateEvent from 'root/src/client/logic/dropEvent/actions/updateEvent'
import {
	CAMPAIGNS_PAGE_ROUTE_ID,
	DROP_EVENT_PAGE_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import { auditEnum } from 'root/src/shared/constants/enums'
import {
	dropEventStatusEnum,
	dropEventStatusNames,
	dropEventSubscriptionStatusEnum,
} from 'root/src/shared/constants/dropEvent'
import displayModal from 'root/src/client/logic/modal/actions/displayModal'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import editDropEvent from 'root/src/client/logic/dropEvent/thunks/editDropEvent'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[DROP_EVENTS_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		listPayload: {
			section: userEventsKey,
			pageItemLength: 20,
		},
		noMaxHeight: true,
		tableColumns: [
			['Go-Live Date', 'liveDateFormatted'],
			['Status', 'status', prop(__, dropEventStatusNames)],
		],
		tableItemActions: [
			{
				name: item => (prop('dropTitle', item) || prop('campaignName', item)),
				fn: item => () => pushRouteClient({
					routeId: DROP_EVENT_PAGE_ROUTE_ID, routeParams: {	recordId: item.mnemonicId },
				}),
				header: 'Campaign Name',
				order: 1,
			},
			{
				name: 'Edit',
				fn: editDropEvent,
			},
			{
				name: item => (prop('isUserActivated', item) ? 'Unpublish' : 'Publish'),
				highlighted: prop('isUserActivated'),
				fn: (item, { apiRequest, publishDropEvent }) => async (dispatch, getState) => {
					const { id, isUserActivated, status, subscriptionData } = item
					if (
						((status === dropEventStatusEnum.ACTIVE
						|| status === dropEventStatusEnum.PENDING_PUBLISH) && !isUserActivated)
						|| (subscriptionData && !isUserActivated)
						|| isUserActivated
					) {
						await apiRequest(
							DROP_ACTIVATION_AUDIT,
							{
								id,
								audit: isUserActivated ? auditEnum.DEACTIVATE : auditEnum.ACTIVATE,
							},
						)(dispatch, getState)
						dispatch(updateEvent({ ...item, isUserActivated: !isUserActivated }))
					} else {
						await dispatch(publishDropEvent(item, { moduleKey: '' }))
					}
				},
			},
			{
				name: (item) => {
					const subscriptionData = prop('subscriptionData', item)
					if (!subscriptionData) return ''
					if (item.subscriptionStatus !== dropEventSubscriptionStatusEnum.ACTIVE) return 'Re-subscribe'
					return prop('active', subscriptionData) ? 'Stop Subscription' : 'Start Subscription'
				},
				highlighted: path(['subscriptionData', 'active']),
				fn: (item, { apiRequest }) => async (dispatch, getState) => {
					const { id, subscriptionData } = item
					if (!subscriptionData) return undefined
					if (item.subscriptionStatus !== dropEventSubscriptionStatusEnum.ACTIVE) {
						return dispatch(displayModal(BRAND_PAYMENT_MODAL_MODULE_ID, item))
					}
					await apiRequest(
						DROP_SUBSCRIPTION_AUDIT,
						{
							id,
							audit: subscriptionData.active ? auditEnum.DEACTIVATE : auditEnum.ACTIVATE,
						},
					)(dispatch, getState)
					return dispatch(updateEvent({
						...item,
						subscriptionData: { ...subscriptionData, active: !subscriptionData.active },
					}))
				},
			},
		],
		listTitle: 'Your Campaigns',
		recordPageType: dropEvent,
		endpointId: [GET_DROP_EVENTS],
		buttons: [{
			label: 'Add New Campaign',
			routeId: routeUrls.addCampaign(),
			condition: state => equals(CAMPAIGNS_PAGE_ROUTE_ID, currentRouteId(state)),
		}],
	},
}
