import { SET_MODULE_DATA_FILTER } from 'root/src/client/logic/api/actionIds'
import assocPath from 'ramda/src/assocPath'

export const setModuleDataFilter = (state, { moduleId, filter, id, value }) => {
	const filterPath = id
		? ['api', 'moduleDataFilters', moduleId, filter, id]
		: ['api', 'moduleDataFilters', moduleId, filter]
	return assocPath(filterPath, value, state)
}

export default {
	[SET_MODULE_DATA_FILTER]: setModuleDataFilter,
}
