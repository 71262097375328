import omit from 'ramda/src/omit'
import pipe from 'ramda/src/pipe'
import keys from 'ramda/src/keys'
import is from 'ramda/src/is'
import includes from 'ramda/src/includes'
import { CLEAR_LIST } from 'root/src/client/logic/api/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import filter from 'ramda/src/filter'

const { setLists, viewLists } = apiStoreLenses

const getClearedList = (listKeyPropsObj, state) => {
	const { moduleId, routeId, endpointId } = listKeyPropsObj
	const currentLists = viewLists(state)
	const keysToOmit = pipe(
		keys,
		filter(
			listKey => includes(moduleId, listKey)
				&& includes(routeId, listKey)
				&& includes(endpointId, listKey),
		),
	)(currentLists)
	return omit(keysToOmit, currentLists)
}

const clearList = (state, { clearBefore, listKeyPropsObj } = {}) => {
	if (!is(Object, clearBefore)) return setLists({}, state)
	if (clearBefore.selfClear) {
		return setLists(getClearedList(listKeyPropsObj, state), state)
	}
	return setLists({}, state)
}

export default {
	[CLEAR_LIST]: clearList,
}
