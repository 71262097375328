import reduce from 'ramda/src/reduce'
import isNil from 'ramda/src/isNil'
import keys from 'ramda/src/keys'

export default obj => reduce((acc, item) => {
	if (isNil(obj[item])) {
		return acc
	}
	return { ...acc, [item]: obj[item] }
}, {}, keys(obj))
