import { VERIFY_ACCOUNT_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { EMAIL } from 'root/src/shared/constants/pageData'

export default {
	[VERIFY_ACCOUNT_FORM_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'verificationResult',
		pageContent: {
			title: 'Please verify your account!',
			paragraphs: [
				['We sent you an email with instructions to verify your account, it may take a moment to arrive.'],
			],
			anchor: {
				text1: 'Contact us at ',
				link: {
					label: EMAIL,
					href: `mailto:${EMAIL}`,
				},
				text2: ' if you didn\'t receive it.',
			},
		},
	},
}
