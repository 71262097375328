import compose from 'ramda/src/compose'
import prepend from 'ramda/src/prepend'
import dissocPath from 'ramda/src/dissocPath'
import keys from 'ramda/src/keys'
import findIndex from 'ramda/src/findIndex'
import path from 'ramda/src/path'
import indexOf from 'ramda/src/indexOf'

import { GET_COUPONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { ADD_COUPON_ON_SUCCESS } from 'root/src/client/logic/coupon/actionIds'
import { apiStoreLenses, idProp } from 'root/src/client/logic/api/lenses'
import { coupon } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { COUPON_LIST_ADMIN_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import getLensFromType from 'root/src/client/logic/api/util/getLensFromType'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import removeNilFields from 'root/src/shared/util/removeNilFields'

const { setListsChild, setListProcessingChild, viewListProcessing } = apiStoreLenses

export default {
	[ADD_COUPON_ON_SUCCESS]: (state, { body }) => {
		const lists = path(['api', 'lists'], state)

		const listKeys = keys(viewListProcessing(state))
		const key = listKeys[findIndex(s => ((indexOf(GET_COUPONS, s.split('---')) > 0)))(listKeys)]

		const prevList = lists[key]
		const recordId = idProp(body)

		const setRecordsChild = getLensFromType('set', 'child', coupon, apiStoreLenses)
		const newList = { items: prepend(recordId, prevList.items), next: undefined }
		const routeObj = currentRouteObj(state)
		const listStoreKey = createListStoreKey(
			routeObj, GET_COUPONS, COUPON_LIST_ADMIN_MODULE_ID, removeNilFields(newList),
		)

		return compose(
			dissocPath(['api', 'lists', key]),
			dissocPath(['api', 'listProcessing', key]),
			setListProcessingChild(listStoreKey, false),
			setListsChild(listStoreKey, newList),
			setRecordsChild(recordId, body),
		)(state)
	},
}
