import { DELETE_FEATURED_DARE } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import deleteFeaturedDarePayloadSchema from
	'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addOrDeleteFeaturedDarePayloadSchema'
import projectResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/projectResponseSchema'

import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export const payloadSchema = deleteFeaturedDarePayloadSchema
export const responseSchema = projectResponseSchema

export default {
	[DELETE_FEATURED_DARE]: {
		endpointType: recordEndpointType,
		recordType: project,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		payloadSchema,
		responseSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
