import { SET_TWITCH_OVERLAY_CONFIG } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { twitchOverlayConfig } from 'root/src/shared/descriptions/endpoints/recordTypes'
import twitchOverlayConfigSchema from 'root/src/shared/descriptions/endpoints/schemas/twitchOverlayConfigSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

export default {
	[SET_TWITCH_OVERLAY_CONFIG]: {
		authentication: authenticationTypes.authenticated,
		endpointType: recordEndpointType,
		recordType: twitchOverlayConfig,
		responseSchema: twitchOverlayConfigSchema,
		payloadSchema: twitchOverlayConfigSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
