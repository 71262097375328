export const getPlatformsScheme = () => ['twitchData', 'youtubeData', 'tiktokData'].reduce((accum, key) => ({
	...accum,
	[key]: {
		type: 'object',
		properties: {
			url: { type: 'string' },
			displayName: { type: 'string' },
			mnemonicId: { type: 'string' },
			image: { type: 'string' },
			platformId: { type: 'string' },
			followerCount: { type: 'number' },
		},
	},
}), {})
