import { formStoreLenses } from 'root/src/client/logic/form/lenses'
import { SET_SUBMITS_LOADING } from 'root/src/client/logic/form/actionIds'

const { setSubmitsLoading } = formStoreLenses
const { viewSubmitsLoading } = formStoreLenses

export default {
	[SET_SUBMITS_LOADING]: (
		state, { moduleId, ...rest },
	) => setSubmitsLoading(
		moduleId,
		{ ...viewSubmitsLoading(moduleId, state), ...rest },
		state,
	),
}
