import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import moduleEndpointIdSelector from 'root/src/client/logic/api/selectors/moduleEndpointIdSelector'
import moduleFormPayloadMapSelector from 'root/src/client/logic/api/selectors/moduleFormPayloadMapSelector'
import clearAllForms from 'root/src/client/logic/form/actions/clearAllForms'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import moduleRecordPayloadMapSelector from 'root/src/client/logic/api/selectors/moduleRecordPayloadMapSelector'
import subObj from 'root/src/shared/util/subObj'
import getRecordIdMod from 'root/src/client/logic/route/util/getRecordIdMod'
import { mapP } from 'root/src/shared/util/ramdaPlus'
import isEmpty from 'ramda/src/isEmpty'
import fetchDropdownAsyncOption from 'root/src/shared/util/fetchDropdownAsyncOption'

export default ({ moduleId, nextRouteObj, moduleKey }) => async (dispatch, getState) => {
	const { clear, dropdownAsyncOptions, onEnterThunk, steps } = moduleDescriptions[moduleId]
	const { routeId, routeParams = {} } = nextRouteObj
	const { recordId } = routeParams

	if (clear) {
		dispatch(clearAllForms())
	}
	const payloadItems = moduleRecordPayloadMapSelector(
		{ /* state */ }, { moduleId },
	)

	const endpointId = moduleEndpointIdSelector({ /* state */ }, { moduleId })
	const payload = moduleFormPayloadMapSelector({}, { moduleId })

	if (dropdownAsyncOptions) {
		await fetchDropdownAsyncOption(dispatch, getState, moduleId, dropdownAsyncOptions)
	}
	if (steps) {
		const stepsArray = Object.values(steps)
		const stepsWithThunk = stepsArray.filter(step => step.onEnterThunk)
		if (!isEmpty(stepsWithThunk)) {
			await mapP(step => dispatch(step.onEnterThunk({ moduleId, moduleKey })))(stepsWithThunk)
		}
	}

	if (onEnterThunk) {
		await dispatch(onEnterThunk({ moduleId, moduleKey }))
	}

	if (payloadItems) {
		const payloadSubs = {
			recordId: getRecordIdMod({ routeId, recordId }),
		}

		const payloadMod = subObj(payloadSubs, payloadItems)
		if (!endpointId) {
			return null
		}

		return Array.isArray(endpointId)
			? mapP(
				id => dispatch(apiRequest(id, payloadMod)),
				endpointId,
			)
			: dispatch(apiRequest(endpointId, payloadMod))
	}

	if (endpointId) {
		return Array.isArray(endpointId)
			? mapP(
				id => dispatch(apiRequest(id, payload)),
				endpointId,
			)
			: dispatch(apiRequest(endpointId, payload))
	}

	return null
}
