import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const getVouchersCountEndpoint = {
    [giveawayEndpointIds.GET_VOUCHERS_COUNT]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.creator,
        payloadSchema: {
            type: 'object',
            properties: {
                giveawayOwnerId: {
                    type: 'string',
                },
            },
        },
        endpointType: otherEndpointType,
    },
};
