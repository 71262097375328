import {
	JOIN_GIVEAWAY_FROM_PANEL,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import joinGiveawayFromPanelPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/joinGiveawayFromPanelPayloadSchema'

export default {
	[JOIN_GIVEAWAY_FROM_PANEL]: {
		endpointType: gatewayEndpointType,
		payloadSchema: joinGiveawayFromPanelPayloadSchema,
	},
}
