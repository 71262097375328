import Router from 'next/router'
import pathToRegexp from 'path-to-regexp'
import prop from 'ramda/src/prop'
import filter from 'ramda/src/filter'
import keys from 'ramda/src/keys'
import when from 'ramda/src/when'
import assoc from 'ramda/src/assoc'
import map from 'ramda/src/map'
import not from 'ramda/src/not'
import pipe from 'ramda/src/pipe'
import has from 'ramda/src/has'
import flatten from 'ramda/src/flatten'
import __ from 'ramda/src/__'
import lensProp from 'ramda/src/lensProp'
import reduce from 'ramda/src/reduce'
import view from 'ramda/src/view'
import append from 'ramda/src/append'
import routeDescriptions from 'root/src/shared/descriptions/routes'
import {
	regexKey, regexKeysKey, toPathKey, routeDescriptionLenses, routeStoreLenses,
} from 'root/src/client/logic/route/lenses'
import matchPath, { findRouteProps } from 'root/src/client/logic/route/util/matchPath'
import hasProperAuthSelector from 'root/src/client/logic/auth/selectors/hasProperAuthSelector'
import setRedirectRoute from 'root/src/client/logic/route/actions/setRedirectRoute'
import linkHrefSelector from 'root/src/client/logic/app/selectors/linkHrefSelector'
import { getDefaultRoute } from 'root/src/client/logic/auth/util/getDefaultRoute'

const { viewAuthentication, viewAuthenticationRole } = routeDescriptionLenses
const { viewRedirectRoute } = routeStoreLenses

export const redirect = (res, destination, isPermanent = false) => {
	if (prop('writeHead', res)) {
		// 308 - Permanent, 307 - Temporary
		// Redirects from descriptions get 308
		const statusCode = isPermanent ? 308 : 307
		res.writeHead(statusCode, { Location: destination })
		res.end()
	}
	if (typeof window !== 'undefined') {
		Router.replace(destination)
	}
}

export function getInitialPropsRedirect(res, resolvedUrl) {
	if (!resolvedUrl) return undefined

	const createRouteUrlRegexes = allRoutes => reduce(
		(results, routeDesc) => {
			const source = view(lensProp('source'), routeDesc)
			const destination = view(lensProp('destination'), routeDesc)
			const regexKeys = []
			const regex = pathToRegexp(source, regexKeys, { strict: true })
			return append(
				{
					[regexKey]: regex,
					[regexKeysKey]: regexKeys,
					[toPathKey]: pathToRegexp.compile(destination),
				},
				results,
			)
		},
		[],
		allRoutes,
	)

	const matchRedirect = () => {
		const allRoutes = pipe(
			keys,
			map(prop(__, routeDescriptions)),
		)(routeDescriptions)
		const allRedirects = pipe(
			filter(has('redirects')),
			map(({ url, redirects }) => map(
				when(
					pipe(has('destination'), not),
					assoc('destination', url),
				),
				redirects,
			)),
			flatten,
		)(allRoutes)

		const redirectRegexes = createRouteUrlRegexes(allRedirects)
		const redirectRouteProps = findRouteProps(redirectRegexes, resolvedUrl)

		if (!redirectRouteProps) {
			return undefined
		}

		const { foundRoutePair, routeParams } = redirectRouteProps
		const { toPath } = prop(1, foundRoutePair)
		const redirectResolvedPath = toPath(routeParams)
		return redirectResolvedPath
	}

	const matchedPath = matchPath(resolvedUrl)
	if (!matchedPath) {
		const matchedRedirect = matchRedirect()
		if (matchedRedirect) {
			return redirect(res, matchedRedirect, true)
		}
		return redirect(res, '/')
	}

	return matchedPath
}

const convertRouteIdAndRedirect = (redirectRoute) => {
	const route = linkHrefSelector({}, redirectRoute)

	return {
		redirect: {
			permanent: true,
			destination: route,
		},
		props: {},
	}
}

async function defaultRedirect(dispatch, getState, res) {
	const route = await getDefaultRoute({ dispatch, getState })
	const redirectRoute = linkHrefSelector({}, route)
	return redirect(res, redirectRoute, true)
}

export function checkAuthRedirect(dispatch, getState, res, routeId) {
	const state = getState()
	const reqAuth = viewAuthentication(routeId, routeDescriptions)
	const reqAuthRoles = viewAuthenticationRole(routeId, routeDescriptions)
	const authValid = hasProperAuthSelector(state, { reqAuth, reqAuthRoles })
	if (!authValid) {
		return defaultRedirect(dispatch, getState, res)
	}

	return undefined
}

export function checkRedirect(dispatch, getState) {
	const state = getState()
	const redirectRoute = viewRedirectRoute(state)
	if (redirectRoute) {
		dispatch(setRedirectRoute(null))
		return convertRouteIdAndRedirect(redirectRoute)
	}

	return undefined
}

export async function redirectFromOutside(dispatch, getState, res) {
	return defaultRedirect(dispatch, getState, res)
}
