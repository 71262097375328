import { Provider } from 'root/src/shared/@types/providers';
export function getReadableProviderName(provider) {
    switch (provider) {
        case Provider.Twitch:
            return 'Twitch';
        case Provider.Google:
            return 'YouTube';
        case Provider.TikTok:
            return 'TikTok';
        default:
            throw new Error(`Unknown provider ${provider}`);
    }
}
