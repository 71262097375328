import { usersEndpointIds } from 'root/src/shared/users/users.constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const transformToCreatorEndpoint = {
    [usersEndpointIds.TRANSFORM_TO_CREATOR]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.viewer,
        endpointType: otherEndpointType,
    },
};
