import { GET_PREVIOUS_DARES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { dropDare } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export default {
	[GET_PREVIOUS_DARES]: {
		authentication: authenticationTypes.optional,
		endpointType: listEndpointType,
		recordType: dropDare,
	},
}
