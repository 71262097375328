export default {
	type: 'object',
	properties: {
		email: { type: 'string' },
		redirectRoute: {
			type: 'object',
			properties: {
				href: { type: 'string' },
				routeId: { type: 'string' },
				routeParams: {
					type: 'object',
					properties: {
						recordId: { type: 'string' },
					},
				},
			},
		},
	},
	additionalProperties: false,
}
