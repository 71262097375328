import { INIT_SIGNED_UP_CREATORS_REPORT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import getSignedUpCreatorsPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getSignedUpCreatorsPayloadSchema'

export default {
	[INIT_SIGNED_UP_CREATORS_REPORT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		requestSchema: getSignedUpCreatorsPayloadSchema,
	},
}
