import { FOLLOW_CREATOR_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[FOLLOW_CREATOR_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'Follow',
		// eslint-disable-next-line no-template-curly-in-string
		modalText: 'Never miss a Dare from ${username}!',
	},
}
