export default {
	type: 'object',
	properties: {
		email: {
			type: 'string',
			pattern:
				'^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
			errorMessage: {
				pattern: 'Must be an email.',
			},
		},
	},
	required: ['email'],
	additionalProperties: false,
}
