import pipe from 'ramda/src/pipe'
import omit from 'ramda/src/omit'
import { EDIT_PROFILE_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

import editProfileSchema from 'root/src/shared/descriptions/formSchemas/editProfileSchema'

import IcFacebook from 'root/src/client/public/icons/ic-facebook.svg'
import IcInstagram from 'root/src/client/public/icons/ic-instagram.svg'
import IcTwitter from 'root/src/client/public/icons/ic-twitter.svg'
import IcDiscord from 'root/src/client/public/icons/ic-discord-logo.svg'
import IcVk from 'root/src/client/public/icons/ic-vk.svg'
import IcTikTok from 'root/src/client/public/icons/ic-tiktok.svg'

import profileSelector from 'root/src/client/logic/app/selectors/profileSelector'
import userProfileLinkSelector from 'root/src/client/logic/creatorData/selectors/userProfileLinkSelector'

export default {
	[EDIT_PROFILE_FORM_MODULE_ID]: {
		moduleType: 'form',
		title: 'Edit Profile',
		schema: editProfileSchema,
		prePopulateForm: pipe(profileSelector, omit([
			'extensions',
			'mnemonicId',
			'obsExtensionAdded',
			'completedWidget',
			'recordId',
			'username',
			'followerCount',
			'displayName',
			'image',
			'hasCompletedDropProject',
			'hasCompletedProject',
			'discordServerName',
		])),
		prePopulateFormOn: 'creatorData',
		fields: [
			{
				fieldId: 'image',
				inputType: 'imageInput',
				attachmentText: 'Upload Photo +',
				attachmentFormats: ['jpg', 'jpeg', 'png', 'svg'],
				crop: { aspect: 4.286, buttonText: 'Crop Banner' },
				required: false,
				subFieldText: 'Recommended ratio: 1200 x 280',
				labelFieldText: [
					{
						text: 'Banner Image',
					},
				],
			},
			{
				fieldId: 'description',
				inputType: 'text',
				placeholder: 'Welcome to my Dare Drop Profile! Feel free to dare me to do something difficult, funny, or cover some topic!',
				multiline: true,
				multilineRows: '4',
				labelFieldText: [
					{
						text: 'Description',
					},
				],
				required: false,
			},
			{
				fieldId: 'facebook',
				inputType: 'text',
				defaultStaticText: 'facebook.com/',
				fieldHalfWidth: true,
				labelFieldText: [
					{
						text: 'Facebook',
						icon: IcFacebook,
					},
				],
				required: false,
			},
			{
				fieldId: 'instagram',
				inputType: 'text',
				defaultStaticText: 'instagram.com/',
				fieldHalfWidth: true,
				labelFieldText: [
					{
						text: 'Instagram',
						icon: IcInstagram,
					},
				],
				required: false,
			},
			{
				fieldId: 'twitter',
				inputType: 'text',
				defaultStaticText: 'twitter.com/',
				fieldHalfWidth: true,
				labelFieldText: [
					{
						text: 'Twitter',
						icon: IcTwitter,
					},
				],
				required: false,
			},
			{
				fieldId: 'discord',
				inputType: 'text',
				defaultStaticText: 'discord.gg/',
				fieldHalfWidth: true,
				labelFieldText: [
					{
						text: 'Discord',
						icon: IcDiscord,
					},
				],
				required: false,
			},
			{
				fieldId: 'vkontakte',
				inputType: 'text',
				defaultStaticText: 'vk.com/',
				fieldHalfWidth: true,
				labelFieldText: [
					{
						text: 'Vkontakte',
						icon: IcVk,
					},
				],
				required: false,
			},
			{
				fieldId: 'tiktok',
				inputType: 'text',
				defaultStaticText: 'tiktok.com/@',
				fieldHalfWidth: true,
				labelFieldText: [
					{
						text: 'TikTok',
						icon: IcTikTok,
					},
				],
				required: false,
			},
			{
				fieldId: 'other',
				inputType: 'text',
				fieldHalfWidth: true,
				labelFieldText: [
					{
						text: 'Other',
					},
				],
				required: false,
			},
		],
		submits: [
			{
				label: 'Save',
			},
		],
		cancelButton: {
			route: userProfileLinkSelector,
			label: 'Cancel',
		},
	},
}
