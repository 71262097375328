import { AMBASSADOR_PROGRAM_BADGES_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import creatorBadge from 'root/src/client/public/creator-badge.png'
import affiliateBadge from 'root/src/client/public/affiliate-badge.png'
import partnerBadge from 'root/src/client/public/partner-badge.png'
import eliteBadge from 'root/src/client/public/elite-badge.png'

export default {
	[AMBASSADOR_PROGRAM_BADGES_SECTION]: {
		moduleType: 'static',
		staticPageType: 'ambassadorProgram',
		pageContent: {
			sectionType: 'badgesSection',
			headerText: 'Earn Exclusive Opportunities',
			paragraphText: 'Ready to join the Elite creators on Dare Drop?',
			badges: [
				{
					src: creatorBadge.src,
					name: 'Creator',
					text: 'Complete dares for your viewers.',
				},
				{
					src: affiliateBadge.src,
					name: 'Affiliate',
					text: 'Access to new games and rewards for you and your followers with Drops.',
				},
				{
					src: partnerBadge.src,
					name: 'Partner',
					text: 'Get paid for completing Drops from our game partners.',
				},
				{
					src: eliteBadge.src,
					name: 'Elite',
					text: "Access to special opportunities with Dare Drop and it's partners.",
				},
			],
		},
	},
}
