export default {
	type: 'object',
	properties: {
		recordId: { type: 'string' },
		isBitsEnabled: { type: 'boolean' },
		isNextPanelVersion: { type: 'boolean' },
		currentPage: { type: 'number' },
		// TODO legacy
		username: { type: 'string' },
	},
	required: [],
	additionalProperties: false,
}
