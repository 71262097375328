export default {
	type: 'array',
	items: {
		properties: {
			gameId: { type: 'string' },
			expectedPoints: { type: 'number' },
			durationMinutes: { type: 'number' },
			averageViewers: { type: 'number' },
			userId: { type: 'string' },
			id: { type: 'string' },
			estimatedPoints: { type: 'number' },
		},
	},
}
