export const saveRewardCodesPayloadSchema = {
	type: 'object',
	properties: {
		filename: { type: 'string' },
		name: {
			type: 'string',
			maxLength: 55,
		},
		id: { type: 'string' },
		restrictions: {
			type: 'string',
			maxLength: 80,
		},
		image: {
			type: 'string',
		},
	},
	required: ['filename', 'name'],
	additionalProperties: false,
}
