/* eslint-disable import/no-cycle */
import reduce from 'ramda/src/reduce'
import pipe from 'ramda/src/pipe'
import unnest from 'ramda/src/unnest'
import pluck from 'ramda/src/pluck'
import addIndex from 'ramda/src/addIndex'
import either from 'ramda/src/either'
import prop from 'ramda/src/prop'
import path from 'ramda/src/path'
import routeDescriptions from 'root/src/shared/descriptions/routes'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import currentRouteObjSelector from 'root/src/client/logic/route/selectors/currentRouteObj'
import { routeDescriptionLenses, moduleDescriptionLenses } from 'root/src/client/logic/route/lenses'
import listModuleOnEnter from 'root/src/client/logic/api/thunks/listModuleOnEnter'
import recordModuleOnEnter from 'root/src/client/logic/api/thunks/recordModuleOnEnter'
import formModuleOnEnter from 'root/src/client/logic/api/thunks/formModuleOnEnter'
import otherModuleOnEnter from 'root/src/client/logic/api/thunks/otherModuleOnEnter'
import { generateUniqueModuleKey } from 'root/src/client/util/moduleKeys'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'

const { viewModules, viewVariantModules } = routeDescriptionLenses
const { viewModuleType } = moduleDescriptionLenses

export const moduleTypeAction = (moduleType) => {
	switch (moduleType) {
	case 'list':
		return listModuleOnEnter
	case 'record':
		return recordModuleOnEnter
	case 'form':
		return formModuleOnEnter
	case 'stepForm':
		return formModuleOnEnter
	default:
		return otherModuleOnEnter
	}
}

export const runModuleMountsHof = (
	routeDescriptionsObj, moduleDescriptionsObj, moduleTypeActionFn, isServerSide,
) => (
	(nextRouteObj, state) => async (dispatch) => {
		const { routeId, query } = nextRouteObj
		const serverSideRequests = path([routeId, 'serverSideRequests'], routeDescriptionsObj)

		if (serverSideRequests && isServerSide) {
			await Promise.all(
				serverSideRequests.map(async ({ endpointId, payload }) => {
					const payloadMod = typeof payload === 'function' ? payload(state) : payload
					await dispatch(apiRequest(endpointId, payloadMod))
				}),
			)
		}

		const makeRequestsOnClient = path([routeId, 'makeRequestsOnClient'], routeDescriptionsObj)
		if (
			(!makeRequestsOnClient && !isServerSide)
			|| (makeRequestsOnClient && isServerSide)) {
			return null
		}
		const currentRouteObj = currentRouteObjSelector(state)
		// TODO *after drop* this will need some refactor to refresh
		//   variant module mount actions on every variant route change
		//   doesn't plays big role unless we'll have more other this kind of routes
		const modal = prop('modal', query)
		const enterModuleIds = pipe(
			either(
				viewModules(routeId),
				pipe(
					viewVariantModules(routeId),
					pluck('modules'),
					unnest,
				),
			),
			modules => (modal ? [modal, ...modules] : modules),
		)(routeDescriptionsObj)

		const modules = enterModuleIds || []

		return Promise.all(
			addIndex(reduce)(
				(result, moduleId, idx) => {
					const moduleType = viewModuleType(
						moduleId, moduleDescriptionsObj,
					)
					const action = moduleTypeActionFn(moduleType, state)
					if (action) {
						const moduleKey = generateUniqueModuleKey({
							routeId,
							moduleId,
							index: idx,
						}, modules)
						const args = { currentRouteObj, nextRouteObj, moduleId, moduleKey }
						return result.concat(dispatch(action(args, state)))
					}
					return result
				},
				[],
				modules,
			),
		)
	}
)

export default runModuleMountsHof(
	routeDescriptions, moduleDescriptions, moduleTypeAction, true,
)
