import { CAMPAIGN_CREATORS_MODULE_ID, DEMO_CAMPAIGN_CREATORS_MODULE_ID, } from 'root/src/shared/descriptions/modules/moduleIds';
import { DEMO_DASHBOARD_RECORD_ID, demoDashboardEndpointIds } from 'root/src/shared/demoDashboard/demoDashboard.constants';
import campaignCreators from 'root/src/shared/descriptions/modules/campaignCreators';
import setModuleDataFilter from 'root/src/client/logic/api/actions/setModuleDataFilter';
import providers from 'root/src/shared/constants/providers';
import { payloadFiltersMock } from 'root/src/shared/demoDashboard/demoDashboard.mocks';
import changeRoute from 'root/src/client/logic/route/actions/changeRoute';
import { DEMO_CAMPAIGN_CREATORS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds';
export const demoCampaignCreators = {
    [DEMO_CAMPAIGN_CREATORS_MODULE_ID]: Object.assign(Object.assign({}, campaignCreators[CAMPAIGN_CREATORS_MODULE_ID]), { endpointId: [
            demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CREATORS,
        ], listPayload: () => {
            return {
                from: payloadFiltersMock.from,
                to: payloadFiltersMock.to,
                limit: 50,
                currentPage: 1,
                sortBy: 'minutesViewed',
                sort: 'DESC',
            };
        }, onEnterActions: () => [
            {
                action: changeRoute,
                payloads: [
                    {
                        routeId: DEMO_CAMPAIGN_CREATORS_ROUTE_ID,
                        routeParams: { recordId: DEMO_DASHBOARD_RECORD_ID },
                    },
                ],
            },
            {
                action: setModuleDataFilter,
                payloads: [
                    {
                        moduleId: CAMPAIGN_CREATORS_MODULE_ID,
                        filter: 'payloadFilter',
                        id: 'from',
                        value: payloadFiltersMock.from,
                    },
                    {
                        moduleId: CAMPAIGN_CREATORS_MODULE_ID,
                        filter: 'payloadFilter',
                        id: 'to',
                        value: payloadFiltersMock.to,
                    },
                    {
                        moduleId: CAMPAIGN_CREATORS_MODULE_ID,
                        filter: 'platform',
                        id: providers.twitch,
                        value: true,
                    },
                    {
                        moduleId: CAMPAIGN_CREATORS_MODULE_ID,
                        filter: 'ambassadorsOnly',
                        id: 'value',
                        value: false,
                        toggleable: true,
                    },
                ],
            },
            {
                action: setModuleDataFilter,
                payloads: [
                    {
                        moduleId: DEMO_CAMPAIGN_CREATORS_MODULE_ID,
                        filter: 'payloadFilter',
                        id: 'from',
                        value: payloadFiltersMock.from,
                    },
                    {
                        moduleId: DEMO_CAMPAIGN_CREATORS_MODULE_ID,
                        filter: 'payloadFilter',
                        id: 'to',
                        value: payloadFiltersMock.to,
                    },
                    {
                        moduleId: DEMO_CAMPAIGN_CREATORS_MODULE_ID,
                        filter: 'platform',
                        id: providers.twitch,
                        value: true,
                    },
                    {
                        moduleId: DEMO_CAMPAIGN_CREATORS_MODULE_ID,
                        filter: 'ambassadorsOnly',
                        id: 'value',
                        value: false,
                        toggleable: true,
                    },
                ],
            },
        ] }),
};
