import tokensSelector from 'root/src/client/logic/api/selectors/tokensSelector'
import head from 'ramda/src/head'
import isNil from 'ramda/src/isNil'
import { profileRouteMap } from 'root/src/shared/constants/route'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export const getProfileRoute = (tokens) => {
	if (isNil(tokens) || !tokens.length) return { routeId: routeUrls.drops(), routeParams: {} }
	const preferredPlatform = tokens.find(token => token.isPreferred)
	const { platform, mnemonicId } = preferredPlatform || head(tokens)

	return { routeId: profileRouteMap[platform], routeParams: { recordId: mnemonicId } }
}

export default (state) => {
	const tokens = tokensSelector(state)
	return getProfileRoute(tokens)
}
