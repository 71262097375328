export default {
	type: 'object',
	properties: {
		parentId: { type: 'string' },
		projectId: { type: 'string' },
		messageId: { type: 'string' },
		messageType: { type: 'string' },
	},
	required: ['parentId', 'projectId', 'messageId', 'messageType'],
	additionalProperties: false,
}
