var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { dareDropClient } from 'root/src/client/v2/common/clients/dareDropClient';
import { globalQueryClient } from 'root/src/client/v2/common/clients/queryClient';
import { usersEndpointIds } from 'root/src/shared/users/users.constants';
import { useAuthentication } from 'root/src/client/v2/auth/hooks/useAuthentication';
const queryKey = ['useApplicationState'];
// TODO this is repeated in useUserData too - please find some sort of generic solution in next occasion
// This is made so we don't have to manually invalidate userData on auth change.
// If anobody ever has better idea of doing it, please make a suggestion
let authentication;
let isInit = true;
export const useApplicationState = () => {
    const { authentication: auth } = useAuthentication();
    const authChanged = !isInit && JSON.stringify(authentication) !== JSON.stringify(auth);
    isInit = false;
    authentication = auth;
    const queryClient = useQueryClient(globalQueryClient);
    const query = useQuery({
        queryKey,
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            return dareDropClient({
                endpointId: usersEndpointIds.GET_APPLICATION_STATE,
            });
        }),
        enabled: !!authentication,
    }, queryClient);
    const invalidateApplicationState = () => __awaiter(void 0, void 0, void 0, function* () {
        yield queryClient.resetQueries({ queryKey });
    });
    if (authChanged) {
        void invalidateApplicationState();
    }
    return {
        applicationState: query.data,
        invalidateApplicationState,
    };
};
