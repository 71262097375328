export default {
	type: 'object',
	properties: {
		dropDareId: { type: 'string' },
		assignees: {
			type: 'array',
			minItems: 1,
			maxItems: 1,
			uniqueItems: true,
			items: {
				type: 'object',
				properties: {
					id: { type: 'string' },
					platform: { type: 'string' },
				},
				required: ['id', 'platform'],
			},
		},
		campaignId: { type: 'string' },
		userHasGame: { type: 'boolean' },
		chosenInitialProductIndex: { type: 'integer' },
		withoutReward: { type: 'boolean' },
	},
	required: ['dropDareId', 'assignees', 'campaignId'],
	additionalProperties: false,
}
