import { ADD_TO_SEND_GRID_LIST } from 'root/src/shared/descriptions/endpoints/endpointIds'
import addToSendGridListPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/addToSendGridListPayloadSchema'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'

export const payloadSchema = addToSendGridListPayloadSchema

export default {
	[ADD_TO_SEND_GRID_LIST]: {
		payloadSchema,
		endpointType: otherEndpointType,
	},
}
