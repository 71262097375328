import React from 'react'

export default ({ gradient: Gradient }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 16">
		<Gradient />
		<path
			fill={Gradient ? 'url(#hover-gradient)' : '#fff'}
			fillRule="evenodd"
			d="M20.79 3.453s.21 1.84.21 3.682V8.86c0 1.841-.21 3.682-.21 3.682s-.206 1.568-.834 2.257c-.8.906-1.694.911-2.104.964-2.94.229-7.352.237-7.352.237s-5.46-.054-7.14-.229c-.468-.094-1.516-.067-2.316-.972-.63-.69-.834-2.258-.834-2.258S0 10.701 0 8.86V7.134c0-1.84.21-3.681.21-3.681s.206-1.569.834-2.26c.8-.905 1.694-.91 2.104-.962C6.087 0 10.495 0 10.495 0h.01s4.408 0 7.347.231c.41.052 1.305.057 2.104.962.63.691.834 2.26.834 2.26zM7.875 4v8l6.563-4-6.563-4z"
		/>
	</svg>
)
