import reduce from 'ramda/src/reduce'
import map from 'ramda/src/map'
import contains from 'ramda/src/contains'
import lensPath from 'ramda/src/lensPath'
import set from 'ramda/src/set'
import prop from 'ramda/src/prop'
import tail from 'ramda/src/tail'
import { GENERAL_RECORD_MODIFICATION } from 'root/src/client/logic/api/actionIds'
import { isNilOrEmpty } from 'root/src/shared/util/ramdaPlus'

const subMethods = {
	set,
}

export const generalRecordModification = (
	state, { subs, updates },
) => reduce(
	(result, { modification, path, value }) => {
		const finalPath = [
			'api',
			'records',
			...map((pathItem) => {
				if (contains(':', pathItem) && !isNilOrEmpty(subs)) {
					const subKey = tail(pathItem)
					return prop(subKey, subs)
				}
				return pathItem
			}, path),
		]
		return prop(modification, subMethods)(
			lensPath(finalPath), value, result,
		)
	},
	state,
	updates,
)

export default {
	[GENERAL_RECORD_MODIFICATION]: generalRecordModification,
}
