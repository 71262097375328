export default {
	type: 'object',
	properties: {
		notificationSettings: {
			type: 'object',
			properties: {
				emailAlerts: { type: 'string' },
			},
		},
	},
	additionalProperties: false,
}
