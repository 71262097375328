import keys from 'ramda/src/keys'
import not from 'ramda/src/not'
import compose from 'ramda/src/compose'
import split from 'ramda/src/split'
import equals from 'ramda/src/equals'
import head from 'ramda/src/head'
import dissocPath from 'ramda/src/dissocPath'
import filter from 'ramda/src/filter'
import { CLEAR_LIST_PROCESSING } from 'root/src/client/logic/api/actionIds'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import { reduce } from 'root/src/shared/util/nativeFns'
import createRouteStoreId from 'root/src/client/logic/route/util/createRouteStoreId'

const { setListProcessing, viewListProcessing } = apiStoreLenses

const clearListProcessing = (state, { currentRouteObj }) => {
	const currentListProcessing = viewListProcessing(state)
	if (!currentListProcessing) {
		return setListProcessing({}, state)
	}
	const currentListProcessingKeys = keys(currentListProcessing)

	const listsProcessingToClear = filter(compose(
		compose(not, equals(createRouteStoreId(currentRouteObj))),
		compose(head, split('---')),
	), currentListProcessingKeys)

	return reduce(
		(acc, item) => dissocPath(['api', 'listProcessing', item], acc),
		state, listsProcessingToClear,
	)
}

export default {
	[CLEAR_LIST_PROCESSING]: clearListProcessing,
}
