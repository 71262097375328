import routeDescriptionsObj from 'root/src/shared/descriptions/routes'
import getWindowRouteId from 'root/src/client/logic/route/util/getWindowRouteId'
import prop from 'ramda/src/prop'
import path from 'ramda/src/path'
import length from 'ramda/src/length'
import head from 'ramda/src/head'
import equals from 'ramda/src/equals'

export default () => {
	const modules = path([getWindowRouteId(), 'modules'], routeDescriptionsObj)

	return equals(length(modules), 1)
		? head(modules)
		: prop('mainModule', routeDescriptionsObj[getWindowRouteId()])
}
