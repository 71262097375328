import { BRANDS_REPORTS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { approvedDropEventsKey } from 'root/src/shared/constants/keys'
import downloadCampaignReportHandler from 'root/src/client/logic/dropEvent/handlers/downloadCampaignReportHandler'
import downloadDaresReport from 'root/src/client/logic/dropEvent/handlers/downloadDaresReport'
import { downloadLinkMappingReport } from 'root/src/client/logic/dropEvent/handlers/downloadLinkMappingReport'

export default {
	[BRANDS_REPORTS_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		tableColumns: [
			['Brand', ['brand', 'brandName']],
			['Campaign name', 'campaignName'],
		],
		tableItemActions: [
			{
				header: 'Campaign report',
				name: 'Download',
				fn: drop => downloadCampaignReportHandler(drop),
			},
			{
				header: 'Whitelist excluded campaign report',
				name: 'Download',
				fn: drop => downloadCampaignReportHandler(drop, {
					excludeWhitelistedCreators: true,
				}),
			},
			{
				header: 'Earned points creators \ncampaign report',
				name: 'Download',
				fn: drop => downloadCampaignReportHandler(drop, {
					onlyEarnedPointsCreators: true,
				}),
			},
			{
				header: 'Dares report',
				name: 'Download',
				fn: downloadDaresReport,
			},
			{
				header: 'Link Mapping',
				name: 'Download',
				fn: downloadLinkMappingReport,
			},
		],
		listTitle: 'Brands reports',
		recordType: dropEvent,
		endpointId: [GET_DROP_EVENTS],
		listPayload: {
			section: approvedDropEventsKey,
			pageItemLength: 20,
		},
	},
}
