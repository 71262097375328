import { SET_STEPS_COMPLETED } from 'root/src/client/logic/app/actionIds'

import {
	appStoreLenses,
} from 'root/src/client/logic/app/lenses'

const { setStepsCompleted } = appStoreLenses

export default {
	[SET_STEPS_COMPLETED]: (state, { stepsCompleted }) => setStepsCompleted(
		stepsCompleted,
		state,
	),
}
