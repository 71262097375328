/* eslint-disable max-len */
import { compareObjectProps } from 'root/src/shared/util/ramdaPlus'
import all from 'ramda/src/all'
import isNil from 'ramda/src/isNil'
import T from 'ramda/src/T'
import equals from 'ramda/src/equals'
import { debugProps } from 'root/src/shared/util/debugFunctions'

export default T

export const comparePropsFn = (propNames, comparePropsLocal = null, isDebug) => (prevState, nextState) => {
	const isTrue = equals(true)
	const checkEquals = compareObjectProps(prevState, nextState, propNames)
	if (isDebug) debugProps(prevState, nextState, propNames, comparePropsLocal)
	return all(isTrue, checkEquals) && (isNil(comparePropsLocal) || comparePropsLocal(prevState, nextState))
}
