import { GET_DARES_REPORT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import getDaresReportPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDaresReportPayloadSchema'

const payloadSchema = getDaresReportPayloadSchema

export default {
	[GET_DARES_REPORT]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		recordType: otherEndpointType,
		payloadSchema,
	},
}
