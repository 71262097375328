import { ADD_AGENCY_ACCOUNT_MANAGER_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'

export default {
	[ADD_AGENCY_ACCOUNT_MANAGER_FORM_MODULE_ID]: {
		moduleType: 'form',
		schema: {
			type: 'object',
			properties: {
				email: {
					type: 'string',
					format: 'email',
				},
			},
		},
		title: 'Invite account manager',
		fields: [
			{
				fieldId: 'email',
				label: 'Email',
				inputType: 'registerEmail',
			},
		],
		submits: [
			{
				label: 'Invite',
				buttonType: 'primaryButton',
				// TODO add redirect
			},
		],
	},
}
