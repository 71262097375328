/* eslint-disable no-useless-escape */
export const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const urlRe = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,11}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
export const twiterRe = /((https:\/\/)?(www\.)?twitter\.com\/)([A-Za-z0-9_]{1,15})/
export const httpsWwwRe = /^((https?:\/\/)?(www\.)?|)/
export const nonEmptyString = /^.+$/
export const twitchTimestamp = /(\d{1,2}h)?(\d{1,2}m)?(\d{1,2}s)/
export const tiktokRe = /^(https?:\/\/)?(www\.)?tiktok\.com\/[^t]/i
export const dateRe = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{3})?Z/
export const NEW_LINE_REGEXP = /\r?\n/
export const SPECIAL_CHARACTERS = /[^a-zA-Z0-9. -]/g
