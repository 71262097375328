import pick from 'ramda/src/pick'
import authDetermined from 'root/src/client/logic/app/actions/authDetermined'
import authSelector from 'root/src/client/logic/auth/util/authSelector'
import { authApi } from 'root/src/shared/util/authApi'

export default (
	{ refreshToken },
	logout,
	setSession,
) => async (dispatch) => {
	try {
		const { error, accessToken } = await authApi.refreshToken(refreshToken)
		if (error || !accessToken) {
			logout()
			console.error(error)
		}
		const sessionData = authSelector({ accessToken, refreshToken })
		setSession(sessionData)
		const auth = pick(['accessToken', 'email', 'refreshToken', 'userId', 'groups', 'parentUserId'], sessionData)
		dispatch(authDetermined(auth))
		return auth
	} catch (err) {
		logout()
		console.error(err)
		return null
	}
}
