export default {
	type: 'object',
	properties: {
		id: { type: 'string' },
		name: { type: 'string' },
		amount: { type: 'number' },
		daysValid: { type: 'integer' },
	},
	required: ['name', 'amount', 'daysValid'],
	additionalProperties: false,
}
