import identity from 'ramda/src/identity'
import { GET_BRAND_CODES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	DROP_ADD_MORE_MODAL_MODULE_ID,
	BRAND_CODE_LIST_MODULE_ID,
	EDIT_BRAND_CODES_MODAL_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { dropCode } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import displayModal from 'root/src/client/logic/modal/actions/displayModal'
import { sectionKey } from 'root/src/shared/constants/keys'
import { RewardStatus } from 'root/src/shared/rewards/constants'

export default {
	[BRAND_CODE_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		listModuleId: BRAND_CODE_LIST_MODULE_ID,
		tableColumns: [
			['Name', 'name'],
			['Url', 'url'],
			['Available', 'available'],
			['Used', 'used'],
			['Creators Waiting', 'waiting', identity, true],
			['Restrictions', 'restrictions', identity, true],
		],
		tableItemActions: [
			{
				name: 'Add more',
				fn: item => displayModal(DROP_ADD_MORE_MODAL_MODULE_ID, item),
			},
			{
				name: 'Edit',
				fn: item => displayModal(EDIT_BRAND_CODES_MODAL_MODULE_ID, item),
			},
		],
		listTitle: 'Coupons/Codes',
		listPayload: {
			section: RewardStatus.AVAILABLE,
		},
		recordType: dropCode,
		endpointId: [GET_BRAND_CODES],
		embeddedContent: {
			moduleType: 'embeddedForm',
			fields: [
				{
					fieldId: sectionKey,
					fieldCaption: 'Status',
					fixedLargeWidth: true,
					inputType: 'dropdownEmbedded',
					options: [
						{
							label: 'Available',
							payload: {
								section: RewardStatus.AVAILABLE,
							},
						},
						{
							label: 'All',
						},
					],
					endpointId: GET_BRAND_CODES,
				},
			],
		},
	},
}
