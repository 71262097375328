import { statusEnum } from 'root/src/shared/constants/enums'

export default {
	type: 'object',
	properties: {
		dropEventId: { type: 'string' },
		action: {
			type: 'string',
			enum: [statusEnum.ENABLE, statusEnum.DISABLE],
		},
		brandId: { type: 'string' },
	},
}
