import { BRANDS_CONTACTS_SECTION_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { ourEmail } from 'root/src/shared/constants/mail'

export default {
	[BRANDS_CONTACTS_SECTION_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'contacts',
		pageContent: {
			title: 'Have Questions?',
			text: [
				['Email us at ', {
					label: ourEmail,
					href: `mailto:${ourEmail}`,
				}],
				[' or message us on ', {
					label: 'Discord',
					href: 'https://discord.gg/xmECwRu',
				}],
			],
		},
	},
}
