import { authenticationTypes } from 'root/src/shared/constants/auth'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { initialProduct } from 'root/src/shared/descriptions/endpoints/recordTypes'

export const getCreatorInitialRewardsEndpoint = {
	authentication: authenticationTypes.authenticated,
	endpointType: listEndpointType,
	recordType: initialProduct,
	payloadSchema: {
		type: 'object',
		properties: {
			campaignId: {
				type: 'string',
			},
		},
		required: [],
	},
}
