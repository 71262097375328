export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		message: {
			type: 'string',
			maxLength: 1000,
			errorMessage: {
				maxLength: 'Comment should not exceed 1000 characters',
			},
		},
	},
	required: ['projectUrl', 'message'],
	additionalProperties: false,
}
