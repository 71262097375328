import { GET_TRANSACTIONS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import getTransactionsPayloadSchema
	from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getTransactionsPayloadSchema'

export default {
	[GET_TRANSACTIONS]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		endpointType: otherEndpointType,
		payloadSchema: getTransactionsPayloadSchema,
	},
}
