export default {
	type: 'object',
	properties: {
		messageId: { type: 'string' },
		message: { type: 'string' },
		messageType: { type: 'string' },
		parentId: { type: 'string' },
		projectId: { type: 'string' },
		projectUrl: { type: 'string' },
		userId: { type: 'string' },
		created: { type: 'string' },
		displayName: { type: 'string' },
		repliesAmount: { type: 'number' },
		image: { type: 'string' },
		likes: {
			type: 'array',
			items: {
				type: 'string',
			},
		},
		dislikes: {
			type: 'array',
			items: {
				type: 'string',
			},
		},
		replies: {
			type: 'array',
			items: {
				type: 'object',
				properties: {},
			},
		},
	},
	required: [
		'messageId', 'message', 'messageType', 'parentId', 'userId',
		'likes', 'dislikes', 'projectId',
	],
	additionalProperties: false,
}
