import { ModalTitle } from 'root/src/client/v2/common/components/modal/ModalTitle';
import { BulletItem } from 'root/src/client/v2/common/components/list/BulletItem';
import { BulletList } from 'root/src/client/v2/common/components/list/BulletList';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { BottomText } from 'root/src/client/v2/creatorOnboarding/ModalBottomText';
import { useHideEngageViewersModal, } from 'root/src/client/v2/creatorOnboarding/features/EngageViewersModal/useHideEngageViewersModal';
import { ButtonNew } from 'root/src/client/v2/common/components/form/ButtonNew';
import { ModalLayout } from 'root/src/client/v2/common/layouts/ModalLayout';
import { VimeoVideo } from 'root/src/client/v2/video/components/VimeoVideo';
import { memo } from 'react';
import { ENGAGE_VIEWERS_START_TIME, ENGAGE_VIEWERS_VIDEO, } from 'root/src/client/v2/creatorOnboarding/constants';
export function EngageViewersModal() {
    return (<ModalLayout className="sm:w-[700px]">
			<ModalTitle>Let Your Audience Dare You</ModalTitle>
			<Video />
			<Instructions />
			<BottomText />
			<HideEngageViewersModal />
		</ModalLayout>);
}
const Video = memo(() => {
    return (<VimeoVideo video={ENGAGE_VIEWERS_VIDEO} start={ENGAGE_VIEWERS_START_TIME}/>);
});
function Instructions() {
    return (<BulletList>
			<BulletItem color={colorsNew.teal}>
				To engage with your audience, make a dare or ask them to make a dare for you.
			</BulletItem>
			<BulletItem color={colorsNew.primary}>
				Accept the dare to set a Funding Goal & tell your audience about it to crowdfund to the goal.
			</BulletItem>
			<BulletItem color={colorsNew.yellow}>
				Complete the dare for your community on your connected channel (Twitch, YouTube, or TikTok).
			</BulletItem>
			<BulletItem color={colorsNew.orange}>
				On your Dare Drop profile Deliver a clip or video of you completing the dare to get the money.
			</BulletItem>
		</BulletList>);
}
const HideEngageViewersModal = () => {
    const { toggleHideEngageViewersMessageSelected, hideEngageViewersMessageSelected, onSubmit } = useHideEngageViewersModal();
    return (<div className="flex flex-col gap-4">
			<HideEngageViewersCheckbox hideEngageViewersMessageSelected={hideEngageViewersMessageSelected} toggleHideEngageViewersMessageSelected={toggleHideEngageViewersMessageSelected}/>
			<div className="flex justify-center items-center">
				<ButtonNew onClick={onSubmit}>
					Submit
				</ButtonNew>
			</div>
		</div>);
};
const HideEngageViewersCheckbox = ({ toggleHideEngageViewersMessageSelected, hideEngageViewersMessageSelected, }) => {
    return (<div onClick={toggleHideEngageViewersMessageSelected} className="flex justify-center items-center gap-2 cursor-pointer">
			<input id="hideEngageViewers" type="checkbox" className="h-4 w-4" checked={hideEngageViewersMessageSelected} onChange={toggleHideEngageViewersMessageSelected}/>
			<span>Stop showing "Engage Viewers" button.</span>
		</div>);
};
