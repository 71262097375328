export default {
	type: 'object',
	properties: {
		id: { type: 'string' },
		mnemonicId: { type: 'string' },
		paymentInfo: { type: 'object' },
	},
	required: ['id', 'mnemonicId', 'paymentInfo'],
	additionalProperties: false,
}
