import { FAILURE_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import modalFailureImg from 'root/src/client/public/failureModalImg.png'

export default {
	[FAILURE_MODAL_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'failureModal',
		pageContent: {
			modalSize: 'medium',
			imgSrc: modalFailureImg.src,
			title: 'OH NO!',
			errorText: 'Looks like an error occurred',
			infoText: 'Please check your internet connection and try again later',
			buttonLabel: 'CONTINUE',
		},
	},
}
