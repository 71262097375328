import { PROJECT_COMMENTS_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import addCommentSchema from 'root/src/shared/descriptions/formSchemas/addCommentSchema'
import { commentButton } from 'root/src/client/web/componentTypes'

export default {
	[PROJECT_COMMENTS_FORM_MODULE_ID]: {
		moduleType: 'form',
		formType: 'commentForm',
		schema: addCommentSchema,
		fields: [
			{
				fieldId: 'message',
				inputType: 'comment',
				placeholder: 'Add comment...',
			},
		],
		submits: [
			{
				label: 'Comment',
				buttonType: commentButton,
			},
		],
	},
}
