import { authenticationTypes } from 'root/src/shared/constants/auth'
import {
	LOGIN_ROUTE_ID,
	SIGN_UP_ROUTE_ID,
	VERIFY_NEW_EMAIL_ROUTE_ID,
	FORGOT_PASSWORD_ROUTE_ID,
	RESET_PASSWORD_ROUTE_ID,
	CONFIRM_FORGET_PASSWORD_ROUTE_ID,
	VERIFICATION_SUCCESS_ROUTE_ID,
	VERIFICATION_FAILURE_ROUTE_ID,
	VERIFICATION_FINISHED_ROUTE_ID,
	NEW_EMAIL_VERIFICATION_SUCCESS_ROUTE_ID,
	NEW_EMAIL_VERIFICATION_FAILURE_ROUTE_ID,
	SET_EMAIL_FAILURE_ROUTE_ID,
	TRANSFER_ACCOUNT_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

import {
	LOGIN_FORM_MODULE_ID,
	SIGN_UP_FORM_MODULE_ID,
	VERIFY_ACCOUNT_FORM_MODULE_ID,
	FORGOT_PASSWORD_FORM_MODULE_ID,
	RESET_PASSWORD_FORM_MODULE_ID,
	CONFIRM_FORGET_PASSWORD_MODULE_ID,
	VERIFICATION_SUCCESS_MODULE_ID,
	VERIFICATION_FAILURE_MODULE_ID,
	VERIFICATION_FINISHED_MODULE_ID,
	VERIFY_NEW_EMAIL_MODULE_ID,
	NEW_EMAIL_VERIFICATION_SUCCESS_MODULE_ID,
	NEW_EMAIL_VERIFICATION_FAILURE_MODULE_ID,
	SET_EMAIL_FAILURE_MODULE_ID,
	TRANSFER_ACCOUNT_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'
import { homepageModules } from 'root/src/shared/descriptions/routes/projects'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[LOGIN_ROUTE_ID]: {
		url: '/sign-in',
		noLoginRedirect: true,
		authentication: authenticationTypes.unauthenticated,
		modules: [LOGIN_FORM_MODULE_ID],
	},
	[SIGN_UP_ROUTE_ID]: {
		url: '/sign-up',
		authentication: authenticationTypes.unauthenticated,
		defaultRouteId: SIGN_UP_ROUTE_ID,
		modules: [SIGN_UP_FORM_MODULE_ID],
	},
	[routeUrls.signUpToken()]: {
		url: routeUrls.signUpToken(),
		modules: [],
	},
	[routeUrls.verifyAccount()]: {
		url: routeUrls.verifyAccount(),
		noSitemap: true,
		modules: [VERIFY_ACCOUNT_FORM_MODULE_ID],
	},
	[VERIFY_NEW_EMAIL_ROUTE_ID]: {
		url: '/verify-new-email',
		noSitemap: true,
		modules: [VERIFY_NEW_EMAIL_MODULE_ID],
	},
	[SET_EMAIL_FAILURE_ROUTE_ID]: {
		url: '/set-email-failure',
		noSitemap: true,
		modules: [
			SET_EMAIL_FAILURE_MODULE_ID,
			...homepageModules,
		],
	},
	[VERIFICATION_SUCCESS_ROUTE_ID]: {
		url: '/account-verified',
		noSitemap: true,
		authentication: authenticationTypes.unauthenticated,
		modules: [VERIFICATION_SUCCESS_MODULE_ID],
	},
	[VERIFICATION_FINISHED_ROUTE_ID]: {
		url: '/verify-account-finished',
		noSitemap: true,
		authentication: authenticationTypes.unauthenticated,
		modules: [VERIFICATION_FINISHED_MODULE_ID],
	},
	[NEW_EMAIL_VERIFICATION_SUCCESS_ROUTE_ID]: {
		url: '/email-verified',
		noSitemap: true,
		modules: [NEW_EMAIL_VERIFICATION_SUCCESS_MODULE_ID],
		authentication: authenticationTypes.authenticated,
	},
	[NEW_EMAIL_VERIFICATION_FAILURE_ROUTE_ID]: {
		url: '/email-verification-failure',
		noSitemap: true,
		modules: [NEW_EMAIL_VERIFICATION_FAILURE_MODULE_ID],
	},
	[VERIFICATION_FAILURE_ROUTE_ID]: {
		url: '/verify-account-failure',
		noSitemap: true,
		authentication: authenticationTypes.unauthenticated,
		modules: [VERIFICATION_FAILURE_MODULE_ID],
	},
	[FORGOT_PASSWORD_ROUTE_ID]: {
		url: '/forgot-password',
		noSitemap: true,
		authentication: authenticationTypes.unauthenticated,
		modules: [FORGOT_PASSWORD_FORM_MODULE_ID],
	},
	[RESET_PASSWORD_ROUTE_ID]: {
		url: '/reset-password',
		noSitemap: true,
		noLoginRedirect: true,
		authentication: authenticationTypes.unauthenticated,
		modules: [RESET_PASSWORD_FORM_MODULE_ID],
	},
	[CONFIRM_FORGET_PASSWORD_ROUTE_ID]: {
		url: '/confirm-reset-password',
		noSitemap: true,
		authentication: authenticationTypes.unauthenticated,
		modules: [CONFIRM_FORGET_PASSWORD_MODULE_ID],
	},
	[TRANSFER_ACCOUNT_ROUTE_ID]: {
		url: '/transfer-account',
		noSitemap: true,
		authentication: authenticationTypes.unauthenticated,
		modules: [TRANSFER_ACCOUNT_MODULE_ID],
	},
}
