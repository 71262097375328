import curryN from 'ramda/src/curryN'
import lensPath from 'ramda/src/lensPath'
import unapply from 'ramda/src/unapply'

export const argSubstitute = '__'

export const filterSubstitute = (path) => {
	const base = []
	for (let i = 0; i < path.length; i += 1) {
		if (path[i] !== argSubstitute) {
			base.push(path[i])
		}
	}
	return base
}

const insertArgsIntoPath = (schemaPath, args) => {
	let remainingArgs = args
	const result = []

	for (let i = 0; i < schemaPath.length; i += 1) {
		if (schemaPath[i] === argSubstitute) {
			const res = remainingArgs[0]
			remainingArgs = remainingArgs.slice(1)
			result.push(res)
		} else {
			result.push(schemaPath[i])
		}
	}

	return [result, remainingArgs]
}

export const insertedArgFnLensNoDiff = (fn, schemaPath) => fn(lensPath(schemaPath))
export const insertedArgFnNoDiff = (fn, schemaPath) => fn(schemaPath)

export const insertedArgFn = (fn, schemaPath, additionalArgCount, diffLength) => curryN(
	diffLength + additionalArgCount,
	unapply((args) => {
		const [insertedArgs, remainingArgs] = insertArgsIntoPath(
			schemaPath, args,
		)
		return fn(insertedArgs,	...remainingArgs)
	}),
)

export const insertedArgFnLens = (fn, schemaPath, additionalArgCount, diffLength) => curryN(
	diffLength + additionalArgCount,
	unapply((args) => {
		const [insertedArgs, remainingArgs] = insertArgsIntoPath(
			schemaPath, args,
		)
		return fn(lensPath(insertedArgs),	...remainingArgs)
	}),
)
