import pipe from 'ramda/src/pipe'
import identity from 'ramda/src/identity'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import { authenticationTypes } from 'root/src/shared/constants/auth'
import { appStoreLenses } from 'root/src/client/logic/app/lenses'

const { authenticated, unauthenticated } = authenticationTypes
const { viewAuthentication } = appStoreLenses

export default pipe(
	viewAuthentication,
	ifElse(identity, always(authenticated), always(unauthenticated)),
)
