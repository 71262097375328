import { linkParametrizedKey, linkSplitRegex } from 'root/src/client/v2/drop/components/SponsorshipTerms/constants';
export function getTermTextMappedWithLinks(text, links) {
    if (!links || links.length === 0) {
        return [{ textPart: text }];
    }
    const textParts = text.split(linkSplitRegex);
    return textParts.map((textPart) => {
        var _a;
        if (!textPart.startsWith(linkParametrizedKey)) {
            return { textPart };
        }
        const linkIndex = getLinkParametrizedIndex(textPart);
        const { text: linkText, href } = (_a = links[linkIndex]) !== null && _a !== void 0 ? _a : {};
        return {
            textPart: linkText,
            href,
        };
    });
}
export function replaceLinkTypesToHref(links, linkTypes) {
    return links.map((link) => {
        if ('linkType' in link) {
            return { text: link.text, href: linkTypes[link.linkType] };
        }
        return { text: link.text, href: link.href };
    });
}
export function createLinkParametrizedIndex(index = 0) {
    return `${linkParametrizedKey}${index}`;
}
export function getLinkParametrizedIndex(str) {
    return Number(str.split(linkParametrizedKey).at(-1));
}
