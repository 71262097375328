import { CAMPAIGN_DARES_LIST_MODULE } from 'root/src/shared/descriptions/modules/moduleIds'
import { dashboardTableListType } from 'root/src/shared/constants/moduleTypes'
import { GET_DASHBOARD_DARES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { idKey, projectTitleKey } from 'root/src/shared/constants/keys'
import onEnterActionCalls from 'root/src/client/logic/api/thunks/onEnterActionCalls'
import setModuleDataFilter from 'root/src/client/logic/api/actions/setModuleDataFilter'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import { dropDare } from 'root/src/shared/descriptions/endpoints/recordTypes'

export default {
	[CAMPAIGN_DARES_LIST_MODULE]: {
		moduleType: 'list',
		listType: dashboardTableListType,
		pageItemsCount: 50,
		recordType: dropDare,
		endpointId: [GET_DASHBOARD_DARES],
		listPayload: (state) => {
			const {
				routeParams: { recordId },
			} = currentRouteObj(state)
			return { mnemonicId: recordId, limit: 50 }
		},
		tableColumns: () => [
			{ label: 'Creators', id: 'creatorCount' },
			{ label: 'Views', id: 'viewCount' },
			{ label: 'Hours\nViewed', id: 'hoursViewedCount' },
			{ label: 'Hours\nStreamed', id: 'hoursStreamedCount' },
			{ label: 'Keys\nClaimed', id: 'claimedKeysCount' },
			{ label: 'Type', id: 'type' },
			{ label: 'Difficulty', id: 'difficulty' },
			{ label: 'Loot', id: 'loot', width: 300 },
		],
		dataIdentificator: idKey,
		recordDisplayProp: projectTitleKey,
		onEnterThunk: onEnterActionCalls,
		onEnterActions: [
			{
				action: setModuleDataFilter,
				payloads: [
					{
						moduleId: CAMPAIGN_DARES_LIST_MODULE,
						filter: 'timeRange',
						id: 'value',
						value: 'ALL',
					},
				],
			},
		],
	},
}
