import { usersEndpointIds } from 'root/src/shared/users/users.constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const hideEngageViewersMessageEndpoint = {
    [usersEndpointIds.HIDE_ENGAGE_VIEWERS_MESSAGE]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.creator,
        endpointType: otherEndpointType,
    },
};
