export default {
	type: 'object',
	properties: {
		value: { type: ['string', 'array', 'object'] },
		section: { type: 'string' },
		subSection: { type: 'string' },
		pageItemLength: { type: 'integer', maximum: 20 },
		currentPage: { type: 'integer' },
	},
	required: ['section', 'value'],
	additionalProperties: false,
}
