import tokensSelector from 'root/src/client/logic/api/selectors/tokensSelector'
import head from 'ramda/src/head'
import isNil from 'ramda/src/isNil'
import getAssigneeRoute from 'root/src/shared/util/getAssigneeRoute'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default (state, routeMap) => {
	const tokens = tokensSelector(state)

	if (isNil(tokens) || !tokens.length) return { routeId: routeUrls.drops(), routeParams: {} }

	return getAssigneeRoute(head(tokens), routeMap)
}
