import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import map from 'ramda/src/map'
import { PUBLISHER_BAN_LIST_FORM_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { dropBanListKey, publisherBanListKey, userEventsKey } from 'root/src/shared/constants/keys'
import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import setFormErrors from 'root/src/client/logic/form/actions/setFormErrors'
import pathOr from 'ramda/src/pathOr'
import { publisherAddCreatorToBanListSubmitHandler } from 'root/src/client/logic/form/thunks/publisherAddCreatorToBanListSubmitHandler'
import { Platform } from 'root/src/shared/@types/platforms'
import clearEmbeddedData from 'root/src/client/logic/embedded/actions/clearEmbeddedData'

export const publisherBanListFormModal = {
	[PUBLISHER_BAN_LIST_FORM_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		dropdownAsyncOptions: [
			{
				endpointId: GET_DROP_EVENTS,
				payload: {
					section: userEventsKey,
					pageItemLength: 100,
				},
				modifyFn: pipe(
					prop('items'),
					map((campaign) => {
						const { id, campaignName, dropTitle } = campaign
						return { ...campaign, label: dropTitle || campaignName, value: id }
					}),
				),
			},
		],
		fields: [
			{
				fieldId: 'mnemonicIds',
				inputType: 'textarea',
				subFieldText: [
					'Enter platform names separated by\n (new lines/commas/semicolons)',
				],
				required: true,
			},
			{
				fieldId: 'platform',
				inputType: 'dropdown',
				labelFieldText: [
					{
						text: "Select users' platform",
					},
				],
				options: [
					{ label: 'Twitch', value: Platform.Twitch },
					{ label: 'YouTube', value: Platform.YouTube },
					{ label: 'TikTok', value: Platform.TikTok },
				],
				required: true,
			},
			{
				fieldId: 'banListType',
				inputType: 'dropdown',
				labelFieldText: [
					{
						text: 'Select ban list type',
					},
				],
				options: [
					{ label: 'All Campaigns', value: publisherBanListKey },
					{ label: 'A Campaign', value: dropBanListKey },
				],
				required: true,
			},
			{
				fieldId: 'dropEvent',
				inputType: 'dropdown',
				uniqueItems: true,
				labelFieldText: [
					{
						text: 'Select campaign',
					},
				],
				hideInput: (state, { moduleId, routeId }) => {
					const listType = pathOr(
						publisherBanListKey,
						[
							'form',
							`${routeId}-${moduleId}`,
							'formInputs',
							'banListType',
							'value',
						],
						state,
					)
					return listType === publisherBanListKey
				},
				asyncOptionsStoreKey: GET_DROP_EVENTS,
				required: false,
			},
		],
		// TODO!
		modalContent: {
			submits: [
				{
					label: 'Submit',
					onClickFn: async ({
						dispatch,
						formData,
						moduleKey,
						moduleId,
						clearForm,
					}) => {
						try {
							await dispatch(publisherAddCreatorToBanListSubmitHandler(formData))
							dispatch(clearEmbeddedData(PUBLISHER_BAN_LIST_FORM_MODAL_MODULE_ID))
							clearForm(
								{
									/* state */
								},
								{ moduleKey, moduleId },
							)
						} catch (errors) {
							dispatch(setFormErrors(moduleKey, errors))
							console.error(errors)
						}
					},
				},
			],
		},
	},
}
