import compose from 'ramda/src/compose'
import mergeLeft from 'ramda/src/mergeLeft'
import over from 'ramda/src/over'
import lensPath from 'ramda/src/lensPath'
import { apiStoreLenses } from 'root/src/client/logic/api/lenses'
import { UPDATE_PUBLISHER_RECORD } from 'root/src/client/logic/agency/actionIds'

const { setRecordProcessingChild } = apiStoreLenses

export default {
	[UPDATE_PUBLISHER_RECORD]: (state, record) => {
		const { id } = record

		return compose(
			over(lensPath(['api', 'records', 'publisher', id]), mergeLeft(record)),
			setRecordProcessingChild(id, false),
		)(state)
	},
}
