import { demoDashboardEndpointIds } from 'root/src/shared/demoDashboard/demoDashboard.constants';
import { getDashboardCreatorsEndpoint } from 'root/src/shared/dashboard/endpoints/getDashboardCreators.endpoint';
import { getDashboardDeliveriesEndpoint } from 'root/src/shared/dashboard/endpoints/getDashboardDeliveries.endpoint';
import { getDashboardChartsDataEndpoint } from 'root/src/shared/dashboard/endpoints/getDashboardChartsData.endpoint';
import { DROP_GET_EVENT, GET_DASHBOARD_CHARTS_DATA, GET_DASHBOARD_CREATORS, GET_DASHBOARD_DELIVERIES, } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { dropGetEventEndpoint } from 'root/src/shared/drops/endpoints/dropGetEvent.endpoint';
export const demoDashboardEndpoints = {
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CREATORS]: getDashboardCreatorsEndpoint[GET_DASHBOARD_CREATORS],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DELIVERIES]: getDashboardDeliveriesEndpoint[GET_DASHBOARD_DELIVERIES],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CHARTS_DATA]: getDashboardChartsDataEndpoint[GET_DASHBOARD_CHARTS_DATA],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DROP_EVENT]: dropGetEventEndpoint[DROP_GET_EVENT],
};
