const tokenSchema = {
	userId: { type: 'string' },
	email: { type: 'string' },
	groups: {
		type: 'array',
		items: { type: 'string' },
	},
	idToken: { type: 'string' },
	refreshToken: { type: 'string' },
}

export default {
	type: 'object',
	properties: {
		publisherToken: tokenSchema,
		backToken: tokenSchema,
	},
	required: ['publisherToken'],
	additionalProperties: false,
}
