import React from 'react'

export default ({ style: { textColor } }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0.04 0 799.98 705.8"
	>
		<path
			fill={textColor || '#FFF'}
			d="m791.79 590.97-52.02 88.8c-9.18 16.84-26.01 26.03-44.37 26.03h-592.08c-18.36 0-35.19-9.19-44.37-26.03l-52.02-88.8c-9.18-15.31-9.18-35.21 0-52.05l298.34-512.89c9.18-16.84 26.01-26.03 44.37-26.03h102.5c18.36 0 35.19 9.19 44.37 26.03l296.81 512.89c9.18 15.31 9.18 35.21-1.53 52.05zm-175.94-125.54c-21.42-97.99-71.91-185.25-142.29-249.56-41.31-38.27-104.03-38.27-145.34 0-71.91 65.84-122.4 151.57-142.29 249.56-10.7 53.59 21.42 105.64 73.44 122.48 44.37 13.78 91.8 21.44 140.76 21.44 48.95 0 96.38-7.66 140.75-21.44 53.55-15.31 84.15-68.89 74.97-122.48z"
		/>
	</svg>
)
