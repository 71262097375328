export default {
	type: 'object',
	properties: {
		data: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					broadcaster_id: { type: 'string' },
				},
			},
		},
	},
	additionalProperties: false,
}
