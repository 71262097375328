import { pipeNotNil, splitAndGetTail } from 'root/src/shared/util/ramdaPlus'
import userIdSelector from 'root/src/client/logic/auth/selectors/userIdSelector'
import reduce from 'ramda/src/reduce'
import startsWith from 'ramda/src/startsWith'
import providers from 'root/src/shared/constants/providers'

export default (state) => {
	const username = pipeNotNil(userIdSelector, splitAndGetTail('-'))(state)
	if (!username) {
		return false
	}
	return reduce(
		(acc, item) => (startsWith(item, username) ? true : acc),
		false, Object.values(providers),
	)
}
