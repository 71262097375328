import { NOTIFICATIONS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { reconnectChannelKey } from 'root/src/shared/constants/keys'
import { ourName } from 'root/src/shared/constants/mail'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import { CONNECTED_CHANNELS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'

export default {
	[NOTIFICATIONS_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalContent: {
			notificationsContentMap: {
				[reconnectChannelKey]: (notificationData) => {
					const { platform } = notificationData
					return {
						notificationMessage: `Your ${platform} channel has been disconencted from ${ourName} account`,
						actionButtonText: 'Reconnect Channel',
						buttonAction: () => {
							pushRouteClient({
								routeId: CONNECTED_CHANNELS_ROUTE_ID,
							})
						},
					}
				},
			},
		},
	},
}
