import {
	ACTIVE_PROJECTS_LIST_MODULE_ID, MY_PROJECTS_LIST_MODULE_ID,
	CREATOR_PROJECTS_LIST_MODULE_ID, PENDING_DELIVERIES_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

export default [
	ACTIVE_PROJECTS_LIST_MODULE_ID,
	CREATOR_PROJECTS_LIST_MODULE_ID,
	MY_PROJECTS_LIST_MODULE_ID,
	PENDING_DELIVERIES_MODULE_ID,
]
