import { GET_COUPON_BY_NAME, GET_USER_COUPON_BY_NAME } from 'root/src/shared/descriptions/endpoints/endpointIds'
import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import setFormErrors from 'root/src/client/logic/form/actions/setFormErrors'
import setFormInfos from 'root/src/client/logic/form/actions/setFormInfos'
import clearFormErrors from 'root/src/client/logic/form/actions/clearFormErrors'
import clearButtonErrors from 'root/src/client/logic/form/actions/clearButtonErrors'
import setPromotionCodeAmount from 'root/src/client/logic/project/actions/setPromotionCodeAmount'

// eslint-disable-next-line no-unused-vars
export default ({ moduleId, moduleKey, value }) => async (dispatch, getState) => {
	if (value) {
		dispatch(clearButtonErrors(moduleKey))
		dispatch(clearFormErrors(moduleKey))
		dispatch(setFormInfos(moduleKey, { promotionCode: null }))
		const payload = {
			name: value,
		}

		const responses = await Promise.all([
			dispatch(apiRequest(GET_COUPON_BY_NAME, payload)),
			dispatch(apiRequest(GET_USER_COUPON_BY_NAME, payload)),
		])

		const { statusCode: couponStatus } = responses[0]
		const { statusCode: userCouponStatus } = responses[1]

		if (couponStatus !== 200) {
			dispatch(setFormErrors(moduleKey, { promotionCode: 'Code expired or invalid' }))
		} else if (userCouponStatus !== 400) {
			dispatch(setFormErrors(moduleKey, { promotionCode: 'You have already used this promotion code' }))
		} else {
			const { body } = responses[0]
			const { amount } = body
			dispatch(clearFormErrors(moduleKey))
			dispatch(setFormInfos(moduleKey, { promotionCode: `Your pledge will be $${amount} off` }))
			dispatch(setPromotionCodeAmount({ moduleKey, amount, moduleId }))
		}
	} else {
		dispatch(setPromotionCodeAmount({ moduleKey, amount: 0 }))
		dispatch(setFormInfos(moduleKey, { promotionCode: '' }))
	}
}
