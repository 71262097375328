import { SUBMIT_EMAIL_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import submitEmailSchema from 'root/src/shared/descriptions/formSchemas/submitEmailSchema'

export default {
	[SUBMIT_EMAIL_FORM_MODULE_ID]: {
		moduleType: 'form',
		title: 'JOIN OUR MAILING LIST',
		hideSubmitError: true,
		fields: [
			{
				fieldId: 'email',
				placeholder: 'Enter your email....',
				inputType: 'email',
			},
		],
		submits: [
			{
				label: 'SUBMIT',
				buttonType: 'neonGlow',
				successMessage: 'Success',
				schema: submitEmailSchema,
			},
		],
	},
}
