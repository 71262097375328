export default {
	type: 'object',
	properties: {
		offset: {
			type: 'integer',
			minimum: 0,
		},
		limit: {
			type: 'integer',
			minimum: 0,
			maximum: 100,
		},
		sort: {
			type: 'string',
			enum: [
				'ASC',
				'DESC',
			],
		},
		sortBy: { type: 'string' },
		from: { type: 'string' },
		to: { type: 'string' },
		campaignMnemonicId: { type: 'string' },
		creatorPlatformId: { type: 'string' },
	},
	required: ['campaignMnemonicId', 'creatorPlatformId'],
}
