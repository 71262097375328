import { giveawayEndpointIds } from 'root/src/shared/giveaways/giveaways.constants';
import { recordEndpointType, } from 'root/src/shared/descriptions/endpoints/lenses';
import { giveawayReward, } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { authenticationTypes } from 'root/src/shared/constants/auth';
const getUserGiveawayRewardsPayloadSchema = {
    type: 'object',
    properties: {
        userId: {
            type: 'string',
        },
        recordId: {
            type: 'string',
        },
    },
};
export const getUserGiveawayRewardsEndpoint = {
    [giveawayEndpointIds.GET_USER_GIVEAWAY_REWARDS]: {
        authentication: authenticationTypes.optional,
        recordType: giveawayReward,
        endpointType: recordEndpointType,
        payloadSchema: getUserGiveawayRewardsPayloadSchema,
    },
};
